// MainComponent.js
import { React } from 'react';

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    TableCaption,
    Text

} from '@chakra-ui/react';


import { useSelector } from 'react-redux'

const TableComponent = (params) => {
    const compsetCalc = useSelector((state) => state.calculation.compset)
    const myAssetsCalc = useSelector((state) => state.calculation.myAssets)
    const myAssetsKey = useSelector((state) => state.calculation.myAssetsKey)
    const compsetCalcsKey = useSelector((state) => state.calculation.compsetCalcsKey)
    const myAssetsAggregate = useSelector((state) => state.calculation.myAssetsAggregate)
    const compsetAggregate = useSelector((state) => state.calculation.compsetAggregate)
    const renderHeader = () => {
        if (params.aggregation) {
            return (
                <>
                    <Th style={{ borderWidth: '1px', width: '300px', backgroundColor: "#fff9e5" }}>My Assets </Th>
                    {Object.keys(compsetAggregate).length !== 0 &&
                        <Th style={{ borderWidth: '1px', width: '300px', backgroundColor: "#fff9e5" }}>Compset Assets </Th>}
                </>

            )
        } else {
            return (
                <>
                    {myAssetsKey.map((asset, index) => (
                        <Th key={index} style={{ borderWidth: '1px', width: '300px', backgroundColor: "#fff9e5" }}>{asset.replace(/_/g, ' ')}</Th>
                    ))}
                    {compsetCalcsKey.map((asset, index) => (
                        <Th key={index} style={{ borderWidth: '1px', width: '300px', backgroundColor: "#fff9e5" }}>{asset.replace(/_/g, ' ')}</Th>
                    ))}

                </>
            )
        }
    }
    // #f2f1ec
    const renderBody = (op, rowIndex) => {
        if (params.aggregation) {
            return (
                op.key !== 'positioning' &&
                <Tr key={rowIndex}>
                    <Td style={{ borderWidth: '1px', backgroundColor: op.key ? '' : "#f2f1ec", borderColor: '#f2f1ec' }}>{op.label}
                        <p style={{ fontSize: "12px", color: '#888787', display: "inline" }}>{op.unit ? `(${op.unit})` : ''}</p> </Td>
                    <Td style={{ borderWidth: '1px', backgroundColor: op.key ? '#E9967A' : '#f2f1ec', borderColor: op.key ? "#E9967A" : "#f2f1ec" }}>{op.key == 'capex' || op.key == 'capexPerKey' ? myAssetsAggregate[op.key].toLocaleString('en-US', { maximumFractionDigits: 0 }) : myAssetsAggregate[op.key]}{op.unit == '%' ? op.unit : ''}</Td>

                    {Object.keys(compsetAggregate).length !== 0 && <Td style={{ borderWidth: '1px', backgroundColor: op.key ? '#7CB9E8' : '#f2f1ec', borderColor: op.key ? "#7CB9E8" : '#f2f1ec' }}>{op.key == 'capex' || op.key == 'capexPerKey' ? compsetAggregate[op.key].toLocaleString('en-US', { maximumFractionDigits: 0 }) : compsetAggregate[op.key]}{op.unit == '%' ? op.unit : ''}</Td>}
                </Tr>
            )
        } else {
            return (
                op.key !== 'avgKeys' &&
                <Tr key={rowIndex}>
                    <Td style={{ borderWidth: '1px', backgroundColor: op.key ? '' : "#f2f1ec", borderColor: '#f2f1ec' }}>{op.label}  <p style={{ fontSize: "12px", color: '#888787', display: "inline" }} >{op.unit ? `(${op.unit})` : ''}</p> </Td>
                    {myAssetsKey.map((asset, colIndex) => (
                        <Td key={colIndex} style={{ borderWidth: '1px', backgroundColor: op.key ? '#E9967A' : '#f2f1ec', borderColor: op.key ? "#E9967A" : "#f2f1ec" }}>{op.key == 'capexPerKey' || op.key == 'capex' ? myAssetsCalc[asset].calculationValues[op.key].toLocaleString("en") : myAssetsCalc[asset].calculationValues[op.key]}</Td>
                    ))}
                    {compsetCalcsKey.map((asset, colIndex) => (
                        <Td key={colIndex} style={{ borderWidth: '1px', backgroundColor: op.key ? '#7CB9E8' : '#f2f1ec', borderColor: op.key ? "#7CB9E8" : '#f2f1ec' }}>{op.key == 'capexPerKey' || op.key == 'capex' ? compsetCalc[asset].calculationValues[op.key].toLocaleString("en") : compsetCalc[asset].calculationValues[op.key]}</Td>
                    ))}
                </Tr>
            )
        }
    }

    return (
        <TableContainer variant='simple' style={{ borderWidth: '1px', borderRadius: '12px' }}>
            <Table size='sm'>
                <TableCaption>
                    <Text fontSize="sm" color="gray.500" mt={3}>
                        * All figures are Year 5 (stabilized year) figures for the respective properties.</Text>
                    <Text fontSize="sm" color="gray.500" mt={3}>
                        * NHD uses the latest board-approved key counts and top-line figures.</Text>
                    <Text fontSize="sm" color="gray.500" mt={3}>
                        * Note that any other data in this dashboard (for example, bottom-line and return figures) is based on NHD projections and </Text>
                    <Text fontSize="sm" color="gray.500" mt={3}>
                        might slightly differ from the official board-approved figures due to differences in modeling approaches.</Text>
                    <Text fontSize="sm" color="gray.500" mt={3}>
                        * Do not communicate the figures in this dashboard at any time with any other party; the use of this dashboard is for informational purposes only.</Text>
                    <Text fontSize="sm" color="gray.500" mt={3}>
                        * The staff related cost are indicative and require full validation of the staff numbers. Detailed manning report can only be provided shortly before the hotel opening.</Text>
                    <Text fontSize="sm" color="gray.500" mt={3}>
                        * CapEx breakdowns may differ based on the best practices used by the respective region or asset. This might lead to individual cost items not being comparable across regions or assets.</Text>
                    <Text fontSize="sm" color="gray.500" mt={3}>
                        * Capex is provided by the regional proponents, and its accuracy is their responsibility; IRRs and returns are dependent on Capex accuracy.</Text>
                </TableCaption>
                <Thead>

                    <Tr>
                        <Th style={{ borderWidth: '1px', width: '300px', backgroundColor: "#fff9e5" }}>{params.title} (Unit)</Th>

                        {renderHeader()}


                    </Tr>
                </Thead>
                <Tbody>
                    {params.rows.map((op, rowIndex) => (
                        renderBody(op, rowIndex)
                    ))}
                </Tbody>
            </Table>
        </TableContainer>


    );

}
export default TableComponent;
