import React from "react";

import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
} from "@chakra-ui/react";

export const ModalBrowserWidth = ({ isVisible = false }) => {
  return (
    <div className={"modal_disableClose"}>
      <Modal isOpen={isVisible}>
        <ModalOverlay
          background="rgba(29, 31, 34, 0.5)"
          backdropFilter="blur(7px)"
        />

        <ModalContent
          py={12}
          px={4}
          alignSelf="center"
          maxWidth="400px"
          className="modal_content"
        >
          <Center flexDirection="column">
            <ModalHeader
              textAlign="center"
              color="#1D1F22"
              fontSize="20px"
              fontWeight="400"
              letterSpacing="-0.02em"
              px={5}
              mt={2}
              className="modal_header"
            >
              This page is not designed to be accessed with this browser size
            </ModalHeader>
          </Center>

          <ModalBody textAlign="center">
            <Center>
              <Text fontSize="15px">
                Please try accessing this page from a full-size browser
              </Text>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
