let baseFee = Array(5).fill("");
let incentiveFee = Array(6).fill({
  ifGOPLessThanPercent: "",
  feePercentOfGOP: "",
});

let newRoomRate = [
  {
    type: "roomRate[ind]?.type",
    id: "",
    year: Array(6).fill(0),
  },
];

export const assetsModellingInitialValues = {
  //links: [linksGroup],
  weightedAverageDailyRate: [0, 0, 0, 0, 0, 0],
  weightedRoomRate: [0, 0, 0, 0, 0, 0],
  expenseLevel: "",
  averageDailyRate: [
    {
      roomTypeLookup: "",
      rates: Array(6).fill(0),
    },
  ],
  baseFee: baseFee,
  percentageOfBaseCost: "",
  economiesOfScaleApplied: "",
  franchiseFee: [{fixedCharge:0,percentage:0},{fixedCharge:0,percentage:0},{fixedCharge:0,percentage:0},{fixedCharge:0,percentage:0},{fixedCharge:0,percentage:0}],
  includeFranchiseFee: "yes",
  revenueType: "total",
  percentageCap: "",
  franchiseFeeLabel:"franshise fee",

  roomRate: newRoomRate,
  incentiveFee: incentiveFee,

  numberOfKeys: "",
  occupancyIncreaseRate: [0],
  developmentCostPerKey_SAR: "",
  contingencyCost: "",
  NEOMDevelopmentFeeCost: "",
  preOpeningCost: "",
  annualInterestRate: "",
  equity: "",
  ebidtaMultiple: "",
  brandedResidenceInclusion: "",
  staffToKeyRatio: Array(5).fill(""),
  staffHousingCostPerStaff: Array(5).fill(""),
  reservationContribution: [{ fixedCharge: 0, percentage: 0 }, { fixedCharge: 0, percentage: 0 }, { fixedCharge: 0, percentage: 0 }, { fixedCharge: 0, percentage: 0 }, { fixedCharge: 0, percentage: 0 }],
  reservationContributionRevenueType: 'total',
  reservationContributionLabel: "RESERVATION CONTRIBUTION",
  GFAEfficiency: "",
  otherExpensesRoom: {
    value: "",
    fixed: "",
  },
};
