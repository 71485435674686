import * as Yup from "yup";

export const validationResortBenchmarksForm = [
  Yup.object().shape({
    departmentalExpenses: Yup.object().shape({
      rooms: Yup.object().shape({
        payroll: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        other: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
      foodAndBeverage: Yup.object().shape({
        payroll: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        costOfSales: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        other: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
      recreation: Yup.object().shape({
        payroll: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        other: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
      spa: Yup.object().shape({
        payroll: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        costOfSales: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        other: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
      otherProfitDepartments: Yup.object().shape({
        payroll: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        other: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
      retail: Yup.object().shape({
        payroll: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        costOfSales: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        other: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
      other1: Yup.object().shape({
        payroll: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        other: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
      other2: Yup.object().shape({
        payroll: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        other: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
    }),
  }),
  Yup.object().shape({
    comment: Yup.string().required("This field is required"),

    undistributedExpenses: Yup.object().shape({
      adminGeneral: Yup.object().shape({
        payroll: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        other: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
      informationTelecom: Yup.object().shape({
        payroll: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        other: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
      salesMarketing: Yup.object().shape({
        payroll: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        other: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
      propertyOperations: Yup.object().shape({
        payroll: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        other: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
      utilities: Yup.object().shape({
        otherPerKey: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
      fixedCharges: Yup.object().shape({
        income: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        operationalInsurance: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
        propertyAndOtherTaxes: Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        }),
      }),
    }),
  }),
];
