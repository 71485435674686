import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Text,
  Center,
  useDisclosure,
  Spinner,
  HStack,
  Spacer,
} from "@chakra-ui/react";

import {
  DeleteServicedApartmentsDraftAPI,
  GetServicedApartmentsAPI,
  GetServicedApartmentsCommentsAPI,
} from "../../../api/BenchmarksAPI";
import { GetProductionPositioningWithIsActive } from "../../../api/MasterDataAPI";

import { activeMenuItem } from "../../../utils/General";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { MainButton } from "../../../components/button/MainButton";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";
import { ServicedApartmentsTable } from "./ServicedApartmentsTable";
import { BenchMarkModal } from "../../../components/benchmarkModal/BenchMarkModal";
import Benchmarks_Template from "../../../templates/Template.xlsx";

import doc from "../../../assets/images/icons/doc.svg";
import plus from "../../../assets/images/icons/plus.svg";
import infoCircle from "../../../assets/images/icons/infoCircle.svg";
import leftArrow3 from "../../../assets/images/icons/leftArrow3.svg";
import download from "../../../assets/images/icons/download.svg";

export const ServicedApartmentsHistory = () => {
  const activeMenu = activeMenuItem();
  let { type } = useParams();
  const [servicedData, setServicedData] = useState([]);
  const [comments, setComments] = useState([]);
  const [selectedID, setSelectedID] = useState("-1");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [activePos, setActivePos] = useState("");

  let typeSmall = activePos.toLowerCase();

  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose,
  } = useDisclosure();

  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    GetServicedApartmentsAPI(type)
      .then((res) => {
        setServicedData(res.data);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);
  useEffect(() => {
    GetProductionPositioningWithIsActive()
      .then((res) => {
        if (res.data.length > 0) {
          const filterPos = res.data[0].lookups.filter((el) =>
            el.associatedWith.includes("Serviced Apartments")
          );

          const findActive = filterPos.find((el) => {
            return el.value === type;
          });

          setActivePos(findActive.label);
        }
      })
      .catch((err) => {
        setErrorMessage("Something went wrong");
        onErrorOpen();
      });

    GetServicedApartmentsCommentsAPI(type)
      .then((res) => {
        setComments(res.data);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const renderRowsAfterDeletion = (id) => {
    if (!id.startsWith("draft-")) {
      return;
    }

    DeleteServicedApartmentsDraftAPI(type, id.split("-")[1], id.split("-")[2])
      .then((res) => {
        setErrorMessage("");
        setIsLoading(true);
        if (res.success === true) {
          loadTableData();
        }
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "Something went wrong");
        onErrorOpen();
      });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Box pb={4}>
            <MainButton
              title="Back"
              btnStyle="transparent"
              fontColor="dark"
              marginRight="1"
              navLink={true}
              icon={leftArrow3}
              path={"/ServicedApartmentsType"}
            />
          </Box>

          <MainTitle title={`Serviced Apartments - ${activePos}`} />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {servicedData?.pageRows?.length <= 0 ? (
            <>
              <Box pb={4} display="inline-block">
                <MainButton
                  title="Back"
                  btnStyle="transparent"
                  fontColor="dark"
                  marginRight="1"
                  navLink={true}
                  icon={leftArrow3}
                  path={"/ServicedApartmentsType"}
                />
              </Box>
              <HStack pb={4}>
                <MainTitle
                  pb={0}
                  title={`Serviced Apartments - ${activePos}`}
                />
                <Spacer />
                <Box>
                  <Link
                    to={Benchmarks_Template}
                    target="_blank"
                    download="Serviced apartments Template"
                  >
                    <MainButton
                      title="Download template"
                      btnStyle="transparent"
                      fontColor="dark"
                      marginRight="1"
                      icon={download}
                    />
                  </Link>
                </Box>
              </HStack>
              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">
                        Serviced Apartments - {typeSmall} not created yet
                      </Text>
                    </Box>
                    <MainButton
                      title="Create New"
                      marginRight="1"
                      icon={plus}
                      // navLink={true}
                      onPress={onCreateModalOpen}
                    />
                    {/* <MainButton
                      title="Create New"
                      marginRight="1"
                      icon={plus}
                      navLink={true}
                      path="/HotelKpis"
                    /> */}
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <ServicedApartmentsTable
                servicedData={servicedData}
                comments={comments}
                setSelectedID={setSelectedID}
                onDelModalOpen={onDelModalOpen}
                GetDataAPI={GetServicedApartmentsAPI}
                activePos={activePos}
              />
            </>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isDelModalOpen}
        onClose={onDelModalClose}
        title="Are you sure you want to delete this version?"
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={true}
        haveSaveDraft={true}
        isDelete={true}
        selectedID={selectedID}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        renderRowsAfterDeletion={renderRowsAfterDeletion}
      />

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
      <BenchMarkModal
        isOpen={isCreateModalOpen}
        onClose={onCreateModalClose}
        title="How would you like to proceed?"
        disableClose={false}
        path={`/ServicedApartments/${type}`}
      />
    </>
  );
};
