// MainComponent.js
import { React, useState, useEffect } from 'react';

import {
    Box, Flex,
    Center,
    Spinner,
    Divider,
    Image,
    Button,
    IconButton,
    Tooltip,
    Text

} from '@chakra-ui/react';


// import FileModal from './createModal';
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";

import { useSelector, useDispatch } from 'react-redux'
import { submitSelectedAssets, setAggregation } from "../../../redux/actions/calculation.js" // Import the thunk action

import cards from "../../../assets/images/icons/cards.svg";
import graph from "../../../assets/images/icons/graph.svg";
import history from '../../../history';
import aggergatinIcon from '../../../assets/images/icons/aggergatinIcon.svg'
import compine from '../../../assets/images/icons/compine.svg'
import TableComponent from './comonTable';
import { operations, sarPerKey, por, perSQm, percentage, allValues } from '../rowsKey';
const Calculation = () => {

    const compset = useSelector((state) => state.card.compset)
    const myAssets = useSelector((state) => state.card.myAssets)
    const isAggregation = useSelector((state) => state.calculation.isAggregation)

    let myAssetsIds = myAssets.filter(asset => asset.selected).map(asset => asset.id);
    let compAssetsIds = compset.filter(asset => asset.selected).map(asset => asset.id);
    const [type, setType] = useState(isAggregation ? 'operationsAggregation' : 'operations')
    const [title, setTitle] = useState('Summary')
    const isLoading = useSelector((state) => state.calculation.loading)
    const [activeButton, setActiveButton] = useState(1);
    const dispatch = useDispatch()

    const changeColor = (buttonId, type, title, aggregationMode) => {
        dispatch(setAggregation(aggregationMode));
        setType(type);
        setTitle(title);
        setActiveButton(buttonId === activeButton ? null : buttonId);

    };

    // Function to load state from local storage
    const loadStateFromLocalStorage = () => {
        try {
              myAssetsIds = JSON.parse(localStorage.getItem('myAssetsIds'));
              compAssetsIds = JSON.parse(localStorage.getItem('compAssetsIds'));
            // Dispatch action to set loaded state into Redux store
            dispatch(submitSelectedAssets(myAssetsIds, compAssetsIds));
            dispatch(setAggregation(isAggregation||myAssetsIds.length + compAssetsIds.length > 5));
            return myAssetsIds.length + compAssetsIds.length > 5

        } catch (err) {
            console.error('Error loading state from local storage :', err);
        }
    };

    useEffect(() => {
        // Load state from local storage when the component mounts
        if (myAssetsIds.length === 0 && compAssetsIds.length === 0) {
            loadStateFromLocalStorage();
        }
        else{
            dispatch(submitSelectedAssets(myAssetsIds, compAssetsIds));
            dispatch(setAggregation(isAggregation||myAssetsIds.length + compAssetsIds.length > 5));

        }
    }, [dispatch]);

    const checkIds =()=>{

        myAssetsIds = JSON.parse(localStorage.getItem('myAssetsIds'));
        compAssetsIds = JSON.parse(localStorage.getItem('compAssetsIds'));
        return myAssetsIds.length + compAssetsIds.length > 5   
    }



    const renderTable = (type) => {
        switch (type) {
            case 'operations':
                // setTitle('Operations')
                return <TableComponent rows={operations} aggregation={isAggregation} title={title} disclaimer='Summry Disclaimer: Your custom disclaimer text here.'/>
           
            case 'perSqm':
                // setTitle('Per SQM')
                return <TableComponent rows={perSQm} aggregation={isAggregation} title={title} disclaimer='Per SQM Disclaimer: Your custom disclaimer text here.'/>
         
            case 'percentage':
                // setTitle('%View')
                return <TableComponent rows={percentage} aggregation={isAggregation} title={title} disclaimer='% View Disclaimer: Your custom disclaimer text here.'/>


            case 'feasibility':
                // setTitle('Feasibility')
                return <TableComponent rows={sarPerKey} aggregation={isAggregation} title={title} disclaimer='PAR/PER key Disclaimer: Your custom disclaimer text here.'/>
          
            case 'por':
                // setTitle('POR')
                return <TableComponent rows={por} aggregation={isAggregation} title={title} disclaimer='POR Disclaimer: Your custom disclaimer text here.'/>
          
            case 'allMetrics':
                // setTitle('Aggregation')
                return <TableComponent rows={allValues} title={title} aggregation={isAggregation} disclaimer='All Metrics Disclaimer: Your custom disclaimer text here.'/>
            default:
                return <TableComponent rows={operations} title={title} aggregation={isAggregation} />

        }
    }

    const aggregationClicked = () => {
        dispatch(setAggregation(!isAggregation));
        // setAggregation(!aggregation);
    }

    return (
        <>


            <Box display="flex" justifyContent="space-between">
                <MainTitle title="Asset Comparison - Table View" />
                {myAssetsIds.length + compAssetsIds.length > 5 ? <Box><Text>* You selected more than 5 assets and will only be able to see an aggregate comparison</Text></Box> : ""}


            </Box>

            <MainBox>

               <Flex width="100%" justifyContent="space-between" gap="20px">
                <Tooltip label='Go Back to Selection'>

                    <Image cursor="pointer" src={cards} onClick={() => history.push(`/AssetComp/review`)} />
                    </Tooltip>
                    <Flex justifyContent="center" gap="20px">
                        <Tooltip label= {checkIds()?'More than 5 assets selected. Select 5 or less assets to allow individual comparison': isAggregation?'Show Individual Assets':'Aggregate Assets'}>

                            <IconButton isDisabled={checkIds()}
                                aria-label="Button 1"
                                icon={<Image src={aggergatinIcon} />}
                                style={{ backgroundColor: isAggregation ? '#caa122' : 'white' }}
                                onClick={() => aggregationClicked()}
                            />
                        </Tooltip>
                        <Button lineHeight="24px" fontWeight="400px" borderRadius="5px" width="120px" 
                            // onClick={() => { setType('operations'); setTitle('Operations') }}
                            style={{ backgroundColor: activeButton === 1 ? '#caa122' : '#f2d98c' }}
                            onClick={() => changeColor(1, 'operations', 'Summary',isAggregation)}
                        >
                            Summary
                        </Button>
                        <Button width="120px" 
                            // onClick={() => { setType('feasibility'); setTitle('Feasibility') }} 
                            style={{ backgroundColor: activeButton === 2 ? '#caa122' : '#f2d98c' }}
                            onClick={() => changeColor(2, 'feasibility', 'PAR/Per Key',isAggregation)}
                            lineHeight="24px" fontWeight="400px" borderRadius="5px">
                            PAR/Per Key
                        </Button>
                        <Button width="120px" 
                            style={{ backgroundColor: activeButton === 3 ? '#caa122' : '#f2d98c' }}
                            onClick={() => changeColor(3, 'por', 'POR',isAggregation)}
                            lineHeight="24px" fontWeight="400px" borderRadius="5px">
                            POR
                        </Button>
                        <Button width="120px" 
                            style={{ backgroundColor: activeButton === 4 ? '#caa122' : '#f2d98c' }}
                            onClick={() => changeColor(4, 'percentage', '%View',isAggregation)} lineHeight="24px" fontWeight="400px" borderRadius="5px">
                            %View
                        </Button>
                        <Button width="120px" 
                            style={{ backgroundColor: activeButton === 5 ? '#caa122' : '#f2d98c' }}

                            onClick={() => changeColor(5, 'perSqm', 'Per SQM',isAggregation)}
                            lineHeight="24px" fontWeight="400px" borderRadius="5px">
                            Per SQM
                        </Button>
                        <Button width="120px" 
                            style={{ backgroundColor: activeButton === 7 ? '#caa122' : '#f2d98c' }}

                            onClick={() => changeColor(7, 'allMetrics', 'All Metrics',isAggregation)}
                            lineHeight="24px" fontWeight="400px" borderRadius="5px">
                            All Metrics
                        </Button>
                    </Flex>
                    <Tooltip label='Graph View'>

                    <Image cursor="pointer" onClick={() => history.push('/AssetComp/graph')} src={graph} />
                    </Tooltip>
                </Flex>
                <Box mt="50px">

                    {/* <TableComponent type={type} operations={operations} /> */}
                    {
                        isLoading ? (<Box width="100%" paddingY={10}>
                            <Center>
                                <Spinner />
                            </Center>
                        </Box>) : type && renderTable(type)
                    }


                </Box>
            </MainBox>

        </>

    );
};

export default Calculation;
