import React, { useState, useEffect, useCallback } from "react";

import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
  Badge,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";

import {
  DeleteHotelKpisDraftAPI,
  GetHotelKpisAPI,
  GetHotelKpisCommentsAPI,
} from "../../../api/BenchmarksAPI";

import { MainBox } from "../../../components/mainBox/MainBox";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainButton } from "../../../components/button/MainButton";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";
import { HotelKpisTable } from "./HotelKpisTable";

import plus from "../../../assets/images/icons/plus.svg";
import noAction from "../../../assets/images/icons/noAction.svg";
import infoCircle from "../../../assets/images/icons/infoCircle.svg";
import doc from "../../../assets/images/icons/doc.svg";

export const HotelKpisHistory = () => {
  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  //const { succ } = useParams();
  const [hotelData, setDevData] = useState([]);
  const [comments, setComments] = useState([]);
  const [selectedID, setSelectedID] = useState("-1");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    GetHotelKpisAPI()
      .then((res) => {
        setDevData(res.data);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  useEffect(() => {
    document.getElementById("main_content").scrollTo(0, 0);
    GetHotelKpisCommentsAPI()
      .then((res) => {
        setComments(res.data);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const renderRowsAfterDeletion = (id) => {
    if (!id.startsWith("draft-")) {
      return;
    }

    DeleteHotelKpisDraftAPI(id.split("-")[1], id.split("-")[2])
      .then((res) => {
        setErrorMessage("");
        setIsLoading(true);
        if (res.success === true) {
          loadTableData();
        }
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "Something went wrong");
        onErrorOpen();
      });
  };

  return (
    <>
      {isLoading ? (
        <>
          <MainTitle title="HOTEL KPIs" transform="unset" />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {hotelData?.pageRows?.length <= 0 ? (
            <>
              <MainTitle title="HOTEL KPIs" transform="unset" />
              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">
                        No Hotel kpis created yet
                      </Text>
                    </Box>
                    <MainButton
                      title="Create New"
                      marginRight="1"
                      icon={plus}
                      navLink={true}
                      path="/HotelKpis"
                    />
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <HotelKpisTable
                hotelData={hotelData}
                comments={comments}
                setSelectedID={setSelectedID}
                onDelModalOpen={onDelModalOpen}
                GetDataAPI={GetHotelKpisAPI}
              />
            </>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isDelModalOpen}
        onClose={onDelModalClose}
        title="Are you sure you want to delete this version?"
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={true}
        haveSaveDraft={true}
        isDelete={true}
        selectedID={selectedID}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        renderRowsAfterDeletion={renderRowsAfterDeletion}
      />

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
    </>
  );
};
