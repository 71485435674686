import axiosClient from "./index";

export const GetVisitorNumAssumptionsAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/VisitorAssumptions?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetVisitorNumAssumptionsCommentsAPI = async () => {
  const URL = "/v1/visitorAssumptions/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetVisitorNumAssumptionsByIdAPI = async (id) => {
  const URL = `/v1/visitorAssumptions/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AddVisitorNumAssumptionsAPI = async (payload) => {
  const URL = "/v1/VisitorAssumptions";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};

export const UpdateVisitorNumAssumptionsAPI = async (payload, id) => {
  const URL = `/v1/VisitorAssumptions/${id}`;
  try {
    const response = await axiosClient.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteVisitorNumAssumptionsByIdAPI = async (id) => {
  const URL = `/v1/VisitorAssumptions/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetVisitorNumAssumptionsDraftByIdAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/VisitorAssumptions/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteVisitorNumAssumptionsDraftAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/VisitorAssumptions/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
