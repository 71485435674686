import React, { useEffect, useRef } from "react";

import {
  Box,
  Text,
  Flex,
  Divider,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";

import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import percent from "../../assets/images/icons/filled.svg";
import { TooltipElement } from "../../components/tooltip/TooltipElement";
import { CalculationButton } from "../../components/calculationModal/calculationButton"

export const AssetsListForm3 = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  setStepWarning,
  setStepperUpdate,
  unsetStepWarning,
  stepperUpdate,
  validateStep,
  setValidateStep,
  id,
  user,
  modelWarningOpen,
  isModelWarningOpen,
  setInitFormVal,
  closeModalPath,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
}) => {
  let form3Data = [];
  const dataFetchedRef = useRef(false);

  form3Data.push(formikValues.useCalculation); //string
  form3Data.push(formikValues.taxRate); //string
  form3Data.push(formikValues.equity); //string
  form3Data.push(formikValues.annualInterestRate); //string
  form3Data.push(formikValues.debtProcFees); //string
  form3Data.push(formikValues.securityFees); //string
  form3Data.push(formikValues.commitmentFees); //string
  form3Data.push(formikValues.sellingCosts); //string
  form3Data.push(formikValues.TaxSaleVal); //string
  form3Data.push(formikValues.riskFreeRate); //string
  form3Data.push(formikValues.equityRiskPrem); //string
  form3Data.push(formikValues.countryRiskPrem); //string
  form3Data.push(formikValues.unleveredBeta); //string
  form3Data.push(formikValues.manualInput); //string
  form3Data.push(formikValues.buffer); //string

  form3Data.push(formikValues.openCashBalance); //string
  form3Data.push(formikValues.debtTerm); //string
  form3Data.push(formikValues.gracePeriod); //string
  form3Data.push(formikValues.ebitdaMulti); //string

  let emptyform3Data = [];
  form3Data.forEach((item, ind) => {
    if (ind === 0) {
      if (item != "") {
        emptyform3Data.push(item);
      }
    } else if (ind >= 1 && ind <= 14) {
      if (item !== "" && item >= "0" && item <= "100") {
        emptyform3Data.push(item);
      }
    } else {
      if (item !== "" && item >= "0") {
        emptyform3Data.push(item);
      }
    }
  });

  let isDisabledForm = false;
  if (emptyform3Data.length !== form3Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  const handleChangeRadio = (event, name) => {
    if (setIsFormDirty && !isFormDirty) {
      setIsFormDirty(true);
    }
    let tempArr2 = [];
    if (name === "useCalculation" && event == "2") {
      let tempArr = [
        "riskFreeRate",
        "equityRiskPrem",
        "countryRiskPrem",
        "unleveredBeta",
        // "manualInput",
        // "buffer",
      ];
      tempArr.forEach((el) => {
        if (formikValues[el] === "" || formikValues[el] > 100)
          tempArr2.push(el);
      });
    }
    if (name === "useCalculation" && event == "1") {
      let tempArr = ["manualInput", "buffer"];
      tempArr.forEach((el) => {
        if (formikValues[el] === "" || formikValues[el] > 100)
          tempArr2.push(el);
      });
    }
    const result = tempArr2.reduce((obj, cur) => ({ ...obj, [cur]: 0 }), {});

    setInitFormVal({ ...formikValues, ...result, useCalculation: event });
  };

  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);

  return (
    <>
      <Box alignItems="flex-start" width="100%" justifyContent="space-between" mb={2} display="flex">
        <MainTitle title="Financing Assumptions" />
        <CalculationButton  formikValue={formikValues} />
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Box
          maxW={["100%", "100%", "65%"]}
          width={["100%", "100%", "65%"]}
          alignSelf="flex-start"
        >
          <Text className="title_md" display="flex" mb={5} component="div">
            General
          </Text>

          <InputFieldValidation
            label="Tax Rate"
            inputType="number"
            inputId="taxRate"
            inputName="taxRate"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            validateField={validateStep}
          />

          <InputFieldValidation
            label="Opening Cash Balance"
            inputType="number"
            inputId="openCashBalance"
            inputName="openCashBalance"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />

          <InputFieldValidation
            label="Equity Financing"
            inputType="number"
            inputId="equity"
            inputName="equity"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            validateField={validateStep}
          />

          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={7}
            component="div"
          >
            Debt Terms&nbsp;
            {id === undefined && (
              <Box className="tooltip_field5" mt="1.5">
                <TooltipElement label="Prepopulated from Benchmark data." />
              </Box>
            )}
          </Text>

          <InputFieldValidation
            label="Annual Interest Rate"
            inputType="number"
            inputId="annualInterestRate"
            inputName="annualInterestRate"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            validateField={validateStep}
          />

          <InputFieldValidation
            label="Debt Term (in Years)"
            inputType="number"
            inputId="debtTerm"
            inputName="debtTerm"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />

          <InputFieldValidation
            label="Grace Period (in Years)"
            inputType="number"
            inputId="gracePeriod"
            inputName="gracePeriod"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />

          <InputFieldValidation
            label="Debt Processing Fees"
            inputType="number"
            inputId="debtProcFees"
            inputName="debtProcFees"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            validateField={validateStep}
          />

          <InputFieldValidation
            label="Security Fees"
            inputType="number"
            inputId="securityFees"
            inputName="securityFees"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            validateField={validateStep}
          />

          <InputFieldValidation
            label="Commitment Fees (on undrawn amounts)"
            inputType="number"
            inputId="commitmentFees"
            inputName="commitmentFees"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            validateField={validateStep}
          />

          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={7}
            component="div"
          >
            Exit Assumptions
          </Text>

          <InputFieldValidation
            label="Selling Costs"
            inputType="number"
            inputId="sellingCosts"
            inputName="sellingCosts"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            validateField={validateStep}
          />

          <InputFieldValidation
            label="Tax on Sales Value"
            inputType="number"
            inputId="TaxSaleVal"
            inputName="TaxSaleVal"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            validateField={validateStep}
          />

          <Text
            className="title_md"
            display="flex"
            mb={3}
            mt={7}
            component="div"
          >
            Equity Risk Calculations
          </Text>
          <Text
            className="title_sm"
            display="flex"
            mb={2}
            mt={0}
            component="div"
            textTransform="uppercase"
            fontSize="12px"
          >
            Use Calculations?
          </Text>
          <RadioGroup
            //onChange={handleChangeRadio}
            //value={useCalc}
            onChange={(event) => handleChangeRadio(event, "useCalculation")}
            value={`${formikValues?.useCalculation}`}
            className="radio_group_custom"
            mb={6}
            name="useCalculation"
            isDisabled={isRestore}
          >
            <Stack direction="row">
              <Radio
                color="#003865 !important"
                bg="none !important"
                borderColor="#6F767E !important"
                value="1"
              >
                Yes
              </Radio>
              <Radio
                color="#003865 !important"
                bg="none !important"
                borderColor="#6F767E !important"
                value="2"
              >
                No
              </Radio>
            </Stack>
            {!formikValues.useCalculation && validateStep && (
              <Box
                color="#e14642"
                fontWeight="400"
                fontSize="10px"
                lineHeight="17px"
                letterSpacing="0.04em"
                marginTop="5px"
              >
                Required
              </Box>
            )}
          </RadioGroup>

          <InputFieldValidation
            label="Risk Free Rate"
            labelInfo={id === undefined ? "Benchmark Data" : ""}
            inputType="number"
            inputId="riskFreeRate"
            inputName="riskFreeRate"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore || formikValues?.useCalculation == "2"}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />

          <InputFieldValidation
            label="Equity Risk Premium"
            labelInfo={id === undefined ? "Benchmark Data" : ""}
            inputType="number"
            inputId="equityRiskPrem"
            inputName="equityRiskPrem"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore || formikValues?.useCalculation == "2"}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />

          <InputFieldValidation
            label="Country Risk Premium"
            labelInfo={id === undefined ? "Benchmark Data" : ""}
            inputType="number"
            inputId="countryRiskPrem"
            inputName="countryRiskPrem"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore || formikValues?.useCalculation == "2"}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />

          <InputFieldValidation
            label="Unlevered Beta"
            labelInfo={id === undefined ? "Benchmark Data" : ""}
            inputType="number"
            inputId="unleveredBeta"
            inputName="unleveredBeta"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore || formikValues?.useCalculation == "2"}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />

          <InputFieldValidation
            label="Buffer"
            inputType="number"
            inputId="buffer"
            inputName="buffer"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore || formikValues?.useCalculation == "1"}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />

          <InputFieldValidation
            label="Manual Input (cost of equity)"
            labelInfo={id === undefined ? "Benchmark Data" : ""}
            inputType="number"
            inputId="manualInput"
            inputName="manualInput"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore || formikValues?.useCalculation == "1"}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />

          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={7}
            component="div"
          >
            Terminal Value
          </Text>

          <InputFieldValidation
            label="EBITDA Multiple"
            inputType="number"
            inputId="ebitdaMulti"
            inputName="ebitdaMulti"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            //step="0.01"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />
        </Box>
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex justifyContent={user == "manager" ? "end" : "space-between"} alignItems="center" width="100%">
        {
          user !== "manager" ?
            <MainButton
              title="Save Draft"
              btnStyle="transparent"
              disabledBtn={isRestore}
              fontColor="dark"
              onPress={() => {
                setDismissSave(false);
                onDraftOpen();
              }}
            /> : <></>
        }
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {/* <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm ? true : false}
              onPress={() => {
                nextPage();
                setStepCompleted(page);
                if (percentage < 37) {
                  setPercentage(37);
                }
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  nextPage();
                  setStepCompleted(page);
                  if (percentage < 37) {
                    setPercentage(37);
                  }
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
