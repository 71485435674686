import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Divider,
  Flex,
  Text,
  GridItem,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Heading,
  Badge,
} from "@chakra-ui/react";
import { FieldArray } from "formik";

import { InputFieldAverage } from "../../../components/inputField/InputFieldAverage";
import { TextArea } from "../../../components/textArea/TextArea";
import { MainButton } from "../../../components/button/MainButton";
import { MainTitle } from "../../../components/mainTitle/MainTitle";

import leftArrow from "../../../assets/images/icons/Arrow-Left.svg";
import percent from "../../../assets/images/icons/filled.svg";

export const HotelKpisStep5 = ({
  onSubmitOpen,
  handleChange,
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  onChange,
  setFieldValue,
  handleBlur,
  formikValues,
  handleOnSubmit,
  onDraftOpen,
  disableSave,
  setDismissSave,
  hotelKpis,
  dirty,
  positioning,
  isRestore,
  validateStep,
  setValidateStep,
  isModelWarningOpen,
  handleAccordErrors,
}) => {
  const [arrayKeys, setArrayKeys] = useState([]);
  const inputRef = useRef(null);

  let form5Data = [];

  if (
    formikValues?.doubleOccupancyRateValues?.cityHotels &&
    formikValues?.doubleOccupancyRateValues?.resorts
  ) {
    formikValues.doubleOccupancyRateValues.cityHotels.forEach((item) => {
      form5Data.push(item);
    });
    formikValues.doubleOccupancyRateValues.resorts.forEach((item) => {
      form5Data.push(item);
    });
  }
  let emptyform5Data = [];
  form5Data.forEach((item) => {
    if (
      item.low !== "" &&
      !isNaN(`${item.low}`) &&
      parseFloat(item.low) >= 0 &&
      item.high !== "" &&
      !isNaN(`${item.high}`) &&
      parseFloat(item.high) >= 0 &&
      item.average !== "" &&
      !isNaN(`${item.average}`) &&
      parseFloat(item.average) >= 0 &&
      parseFloat(item.low) <= parseFloat(item.high)
    ) {
      emptyform5Data.push(item);
    }
  });

  /*  let isDisabledForm = false;

  if (emptyform5Data.length !== form5Data.length) {
    isDisabledForm = true;
  } */
  let isDisabledForm = false;
  let isDisabledFormProd = false;
  let status = hotelKpis?.status === "Production";

  if (hotelKpis?.status === "Production") {
    if (emptyform5Data.length !== form5Data.length) {
      isDisabledForm = true;
    }
    isDisabledFormProd =
      isDisabledForm === false && dirty === true ? false : true;
    if (validateStep && !isDisabledFormProd) {
      setValidateStep(false);
    }
  } else {
    if (emptyform5Data.length !== form5Data.length) {
      isDisabledForm = true;
    }
    if (validateStep && !isDisabledForm) {
      setValidateStep(false);
    }
  }

  /*  let form5Data = [];

  form5Data.push({
    fieldName: "comments",
    value: formikValues.comments,
  });

  if (formikValues?.form5) {
    formikValues?.form5.forEach((el) => {
      el.posFields.forEach((item) => {
        form5Data.push({
          parentName: item.parentName,
          fieldName: "low",
          value: item.low,
          title: item.title,
        });
        form5Data.push({
          parentName: item.parentName,
          fieldName: "high",
          value: item.high,
          title: item.title,
        });
      });
    });
  }

  let emptyform5Data = [];
  form5Data.forEach((item) => {
    if (item.fieldName === "comments") {
      if (item.value !== "") {
        emptyform5Data.push(item);
      }
    } else {
      if (item.value !== "" && !isNaN(`${item.value}`) && item.value >= 1) {
        emptyform5Data.push(item);
      }
    }
  });

  let isDisabledForm = false;

  if (emptyform5Data.length !== form5Data.length) {
    isDisabledForm = true;
  } */

  let accordTitle = ["City hotels", "resorts"];
  let arrKeys = [];
  if (formikValues?.doubleOccupancyRateValues) {
    arrKeys = Object.keys(formikValues.doubleOccupancyRateValues);
    if (JSON.stringify(arrayKeys) !== JSON.stringify(arrKeys)) {
      setArrayKeys(arrKeys);
    }
  }
  let renderAccrodionFields = (mData, pos, name) => {
    let counterMas = 0;

    counterMas = counterMas + 1;
    if (counterMas <= mData.length) {
      //let counterComment = 0;

      //counterComment = counterComment + 1;
      return (
        <>
          <AccordionItem mb="4" borderBottom="none">
            <Heading
              borderLeft="1px"
              borderBottom="1px"
              borderRight="1px"
              borderColor="#E6E6E6"
            >
              <AccordionButton
                bg="#FCFCFC"
                className={arrayKeys[pos]}
                onClick={() => {
                  setTimeout(() => {
                    document
                      .querySelector(`.accordionList`)
                      .scrollIntoView({ block: "start" });
                  }, 500);
                }}
              >
                <Box flex="1" textAlign="left">
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                    textTransform="uppercase"
                  >
                    {accordTitle[pos]}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Heading>

            <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
              <FieldArray
                name={`${arrayKeys[pos]}`}
                render={(arrayHelper) => (
                  <>
                    {formikValues.doubleOccupancyRateValues[
                      `${arrayKeys[pos]}`
                    ] !== undefined &&
                      formikValues.doubleOccupancyRateValues[
                        `${arrayKeys[pos]}`
                      ].map((formArr, index) => {
                        let posObj = positioning.find((el) => {
                          if (el?.id) {
                            return formArr.positioningId === el.id;
                          } else {
                            return formArr.positioningId === el.positioningId;
                          }
                        });
                        return (
                          <Box key={index}>
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                            >
                              <Text
                                fontSize="12px"
                                color="black"
                                fontWeight="400"
                                letterSpacing="0.04em"
                                textAlign="left"
                                textTransform="uppercase"
                                mb={4}
                                mt={3}
                              >
                                {posObj?.posName
                                  ? posObj.posName
                                  : `${formArr.positioningId}`
                                      .split("_")
                                      .join(" ")}
                              </Text>
                            </Box>

                            <GridItem colSpan={2}>
                              <SimpleGrid
                                columns={{ sm: 3, "2xl": 3 }}
                                spacing={{ sm: "25px", "2xl": "40px" }}
                                width={{ sm: "50%", "2xl": "40%" }}
                              >
                                <GridItem
                                  w={{
                                    sm: "170px",
                                    md: "170px",
                                    xl: "170px",
                                    "2xl": "180px",
                                  }}
                                >
                                  <InputFieldAverage
                                    placeholder="0.00"
                                    label="Low"
                                    inputType="number"
                                    inputId={`doubleOccupancyRateValues.${arrayKeys[pos]}.${index}.low`}
                                    inputName={`doubleOccupancyRateValues.${arrayKeys[pos]}.${index}.low`}
                                    errors={errors}
                                    touched={touched}
                                    isRequired
                                    values={
                                      formikValues?.doubleOccupancyRateValues[
                                        arrayKeys[pos]
                                      ][index]?.low
                                    }
                                    labelCenter={true}
                                    uppercase={false}
                                    onChange={onChange}
                                    inputRef={inputRef}
                                    // icon={percent}
                                    disable={isRestore}
                                    validateField={validateStep}
                                    directVal={true}
                                  />
                                </GridItem>
                                <GridItem
                                  w={{
                                    sm: "170px",
                                    md: "170px",
                                    xl: "170px",
                                    "2xl": "180px",
                                  }}
                                >
                                  <InputFieldAverage
                                    placeholder="0.00"
                                    label="High"
                                    inputType="number"
                                    inputId={`doubleOccupancyRateValues.${arrayKeys[pos]}.${index}.high`}
                                    inputName={`doubleOccupancyRateValues.${arrayKeys[pos]}.${index}.high`}
                                    errors={errors}
                                    touched={touched}
                                    isRequired
                                    values={
                                      formikValues?.doubleOccupancyRateValues[
                                        arrayKeys[pos]
                                      ][index]?.high
                                    }
                                    labelCenter={true}
                                    uppercase={false}
                                    onChange={onChange}
                                    inputRef={inputRef}
                                    // icon={percent}
                                    disable={isRestore}
                                    validateField={validateStep}
                                    directVal={true}
                                  />
                                </GridItem>
                                <GridItem
                                  w={{
                                    sm: "170px",
                                    md: "170px",
                                    lg: "170px",
                                    "2xl": "180px",
                                  }}
                                >
                                  <InputFieldAverage
                                    placeholder="0.00"
                                    label="Average"
                                    inputType="number"
                                    inputId={`doubleOccupancyRateValues.${arrayKeys[pos]}.${index}.average`}
                                    inputName={`doubleOccupancyRateValues.${arrayKeys[pos]}.${index}.average`}
                                    errors={errors}
                                    touched={touched}
                                    isRequired
                                    values={
                                      formikValues?.doubleOccupancyRateValues[
                                        arrayKeys[pos]
                                      ][index]?.average
                                    }
                                    labelCenter={true}
                                    uppercase={false}
                                    //
                                    onChange={onChange}
                                    inputRef={inputRef}
                                    disable={true}
                                    avg={true}
                                    // icon={percent}
                                    noBg={true}
                                    validateField={false}
                                    directVal={true}
                                  />
                                </GridItem>
                              </SimpleGrid>
                              <Box>
                                {parseFloat(
                                  formikValues?.doubleOccupancyRateValues[
                                    arrayKeys[pos]
                                  ][index]?.low
                                ) >
                                  parseFloat(
                                    formikValues?.doubleOccupancyRateValues[
                                      arrayKeys[pos]
                                    ][index]?.high
                                  ) && (
                                  <Box
                                    color="#e14642"
                                    fontWeight="400"
                                    fontSize="10px"
                                    lineHeight="17px"
                                    letterSpacing="0.04em"
                                    marginTop="-5px"
                                  >
                                    Low value should be less than high value
                                  </Box>
                                )}
                              </Box>
                            </GridItem>
                          </Box>
                        );
                      })}
                  </>
                )}
              />
            </AccordionPanel>
          </AccordionItem>
        </>
      );
    }
  };
  return (
    <>
      <Box alignItems="flex-start" width="100%" mb={5}>
        <MainTitle title="HOTEL KPIS" />
        <Badge
          fontSize="16px"
          borderRadius="5px"
          bg=" rgba(235, 192, 63, 0.15);"
          color="#1D1F22"
          colorScheme="blackAlpha"
          fontWeight="400"
          lineHeight="24px"
          letterSpacing="0.06em"
          padding="10px 24px"
          mb="3"
        >
          Double Occupancy Rate
        </Badge>
      </Box>

      <Accordion
        width="100%"
        allowToggle
        color="black"
        ref={inputRef}
        defaultIndex={[0]}
        className="accordionList"
      >
        {formikValues?.doubleOccupancyRateValues?.cityHotels &&
          renderAccrodionFields(
            formikValues?.doubleOccupancyRateValues?.cityHotels,
            0,
            "cityHotels"
          )}
        {formikValues?.doubleOccupancyRateValues?.resorts &&
          renderAccrodionFields(
            formikValues?.doubleOccupancyRateValues?.resorts,
            1,
            "resorts"
          )}
      </Accordion>

      <Divider orientation="horizontal" mt="30px" mb="30px" />
      <Box
        maxW={["100%", "100%", "40%"]}
        width={["100%", "100%", "40%"]}
        alignSelf="flex-start"
      >
        {/* <SelectListMulti
          label="Benchmark Level Chosen*"
          placeholder="Select"
          inputId="level"
          inputName="level"
          errors={errors}
          touched={touched}
          isRequired 
          values={formikValues.level}
          onChange={setFieldValue}
          
          onBlur={handleBlur}
          uppercase={true}
          dropdownItems={[
            { value: "", label: "Select..." },
            { value: "medium", label: "Medium" },
          ]}
        /> */}
        {/* <SelectList
          label="Benchmark Level Chosen*"
          placeholder="Select"
          inputId="Level"
          inputName="Level"
          errors={errors}
          touched={touched}
          isRequired
          values={values}
          onChange={handleChange}
        /> */}

        <TextArea
          label="ADD COMMENT"
          inputId="comment"
          inputName="comments"
          placeholder="Add description"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
          // isDisabled={isRestore}
        />
      </Box>

      <Divider orientation="horizontal" mt="40px" mb="30px" />
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          btnStyle="transparent"
          disabledBtn={isRestore}
          fontColor="dark"
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/HotelKpisHistory`}
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {/* <MainButton
              title={isRestore ? "Restore" : "Submit"}
              classname="btn_primary "
              disabledBtn={status ? isDisabledFormProd : isDisabledForm}
              onPress={() => {
                handleOnSubmit(formikValues);
                //onSubmitOpen();
              }}
            /> */}
            {status ? (
              isDisabledFormProd ? (
                <MainButton
                  title={isRestore ? "Restore" : "Submit"}
                  disabledBtn={!dirty}
                  onPress={() => {
                    handleAccordErrors(errors);
                    setValidateStep(true);
                    isModelWarningOpen(true);
                    document.getElementById("main_content").scrollTo(0, 0);
                  }}
                />
              ) : (
                <MainButton
                  title={isRestore ? "Restore" : "Submit"}
                  onPress={() => {
                    handleOnSubmit(formikValues, isRestore);
                  }}
                />
              )
            ) : isDisabledForm ? (
              <MainButton
                title={isRestore ? "Restore" : "Submit"}
                onPress={() => {
                  handleAccordErrors(errors);
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title={isRestore ? "Restore" : "Submit"}
                onPress={() => {
                  handleOnSubmit(formikValues, isRestore);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
