import React, { useState } from "react";

import {
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Textarea,
    Button
} from "@chakra-ui/react";
import { set } from "date-fns";

export const CommentModal = ({ isOpen,
    onClose, saveComment, comment, pageTitle, region }) => {
        console.log("commeeowjowwont", comment)
    const [commentData, setCommentData] = useState(comment || "");

    const handleComment = (e) => {
        e.preventDefault();
        if (commentData !== comment) {
            saveComment(commentData);

        }
        onClose();

    }

    const handleInputChange = (e) => {

        setCommentData(e.target.value);
    }
    
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay
                background="rgba(29, 31, 34, 0.5)"
                backdropFilter="blur(7px)"
            />

            <ModalContent

                className="modal_content"
            >


                <ModalHeader>{region} - {pageTitle}</ModalHeader>


                <ModalCloseButton />
                <ModalBody textAlign="center">
                    <Center><Textarea onChange={handleInputChange} // Enabled onChange event
                        value={commentData} placeholder=" placeholder" />
                    </Center>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={handleComment}>
                        Save
                    </Button>
                </ModalFooter>

            </ModalContent>
        </Modal>

    );
}
