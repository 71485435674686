import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Text,
  Flex,
  Divider,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Heading,
  useToast,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { TooltipElement } from "../../components/tooltip/TooltipElement";
import { DatePickerField } from "../../components/datePickerField/DatePickerField";
import { useParams } from "react-router-dom";
import { ToastAlert } from "../../components/toastAlert/ToastAlert";

import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import m2 from "../../assets/images/icons/m2.svg";
import calendar from "../../assets/images/icons/calendar.svg";
import errIcon from "../../assets/images/icons/errsm.svg";

export const AssetsListForm1 = ({
  dropdownObj,
  nextPage,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  onCancelOpen,
  formikValues,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  devStartDate,
  setDevStartDate,
  opStartDate,
  setOpStartDate,
  setWarningIcon,
  warningIcon,
  setStepWarning,
  isPreview,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  operatorLookup,
  // brandDropdown,
  // setBrandDropdown,
  unsetStepWarning,
  setStepperUpdate,
  stepperUpdate,
  validateStep,
  setValidateStep,
  user,
  modelWarningOpen,
  isModelWarningOpen,
  closeModalPath,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
  // setAssetBaseYear,
}) => {
  const toast = useToast();
  const closeToast = () => {
    toast.closeAll();
  };
  let form1Data = [];
  const dataFetchedRef = useRef(false);
  let form1Counter = 0;
  form1Data.push(formikValues.developmentTimeDurationMonths);
  form1Data.push(formikValues.operationTimeDurationMonths);
  form1Data.push(formikValues.projectName); //string
  form1Data.push(formikValues.assetType); //string
  form1Data.push(formikValues.regionLookup); //string
  form1Data.push(formikValues.operatorLookup); //string
  form1Data.push(formikValues.brandLookup); //string
  form1Data.push(formikValues.managementContractLookup); //string
  form1Data.push(formikValues.positioningLookup); //string
  form1Data.push(formikValues.projectStatusLookup); //string
  form1Data.push(formikValues.projectStageLookup); //string
  form1Data.push(formikValues.assetBaseYear); //string
  form1Data.push(formikValues.startDateDevTime); //string
  form1Data.push(formikValues.developmentTimeCompletionDate); //string
  form1Data.push(formikValues.startDateOpTime); //string
  form1Data.push(formikValues.operationTimeEndDate); //string

  form1Data.push(formikValues.plotSize);
  form1Data.push(formikValues.grossFloorArea);
  form1Data.push(formikValues.parkingArea);
  form1Data.push(formikValues.numberOfKeys);
  let { type, id, draftId } = useParams();

  let emptyform1Data = [];
  form1Data.forEach((item, ind) => {
    if (ind <= 1) {
      if (ind === 1) {
        if (item <= 600 && item >= 60) {
          emptyform1Data.push(item);
        }
      } else {
        if (item <= 60) {
          emptyform1Data.push(item);
        }
      }
    } else {
      if (item !== "" && item >= "0") {
        emptyform1Data.push(item);
      }
    }
  });

  let isDisabledForm = false;
  // console.log(emptyform1Data, form1Data);
  if (emptyform1Data.length !== form1Data.length) {
    isDisabledForm = true;
    form1Counter++;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  Date.isLeapYear = function (year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  Date.getDaysInMonth = function (year, month) {
    return [
      31,
      Date.isLeapYear(year) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ][month];
  };

  Date.prototype.isLeapYear = function () {
    return Date.isLeapYear(this.getFullYear());
  };

  Date.prototype.getDaysInMonth = function () {
    return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
  };

  Date.prototype.addMonths = function (value) {
    var n = this.getDate();
    this.setDate(1);
    this.setMonth(this.getMonth() + value);
    this.setDate(Math.min(n, this.getDaysInMonth()));
    return this;
  };

  useEffect(() => {
    var myDate = new Date(formikValues.startDateDevTime);
    myDate.addMonths(formikValues.developmentTimeDurationMonths);
    setFieldValue(
      "developmentTimeCompletionDate",

      myDate.getMonth() + 1 + "/" + myDate.getFullYear()
    );
  }, [
    formikValues.startDateDevTime,
    formikValues.developmentTimeDurationMonths,
  ]);

  useEffect(() => {
    var myDate2 = new Date(formikValues.startDateOpTime);
    myDate2.addMonths(formikValues.operationTimeDurationMonths);

    setFieldValue(
      "operationTimeEndDate",

      myDate2.getMonth() + 1 + "/" + myDate2.getFullYear()
    );
  }, [formikValues.operationTimeDurationMonths, formikValues.startDateOpTime]);
  useEffect(() => {
    if (
      formikValues.developmentTimeCompletionDate == "" ||
      formikValues.developmentTimeCompletionDate == "NaN/NaN"
    ) {
      setFieldValue("developmentTimeCompletionDate", "-");
    }
    if (
      formikValues.operationTimeEndDate == "" ||
      formikValues.operationTimeEndDate == "NaN/NaN"
    ) {
      setFieldValue("operationTimeEndDate", "-");
    }
  }, [
    formikValues.developmentTimeCompletionDate,
    formikValues.operationTimeEndDate,
  ]);
  // if preview, then get start dates before changing so we can compare in step 2
  useEffect(() => {
    var tempDate = new Date(formikValues.startDateDevTime);
    var tempDate2 = new Date(formikValues.startDateOpTime);

    setDevStartDate(tempDate.getFullYear());
    setOpStartDate(tempDate2.getFullYear());

    // setAssetBaseYear(formikValues.assetBaseYear);
  }, []);

  let arr = type === "Standard" ? [3, 4, 7, 8, 9] : [3, 4, 5, 8, 9, 10];

  // useEffect(() => {
  //   if (user !== "proponent") {
  //     handleChange2(null, operatorLookup);
  //   }
  // }, [operatorLookup]);

  const handleDevChange = (e, e2) => {
    var year = new Date(e2);
    var tempDate1 = new Date(e2);
    var opStart = new Date(formikValues.startDateOpTime);
    tempDate1.addMonths(formikValues.developmentTimeDurationMonths);
    if (formikValues?.assetBaseYear !== "") {
      let assetBaseYear = new Date(formikValues?.assetBaseYear);
      if (year.getFullYear() < assetBaseYear.getFullYear()) {
        toast({
          position: "top-right",
          isClosable: true,
          duration: 5000,
          render: () => (
            <ToastAlert
              title="Development start date has to be after Asset base year."
              icon={errIcon}
              closeToast={closeToast}
              type="Error"
            />
          ),
        });
      } else {
        if (tempDate1 <= opStart || formikValues.startDateOpTime === "") {
          setFieldValue(e, year);
          closeToast();
        } else {
          toast({
            position: "top-right",
            isClosable: true,
            duration: 5000,
            render: () => (
              <ToastAlert
                title="Development end date has to be before Operation start Date."
                icon={errIcon}
                closeToast={closeToast}
                type="Error"
              />
            ),
          });
        }
      }
    } else {
      setFieldValue(e, year);
    }
    if (!isNaN(devStartDate)) {
      var difference = year - devStartDate;

      // if (difference !== 0) {
      //   setStepWarning(page + 1);
      // }
    }
  };
  const handleOpChange = (e, e2) => {
    var year = new Date(e2);
    if (formikValues?.assetBaseYear !== "") {
      let devCompleteDate =
        formikValues.developmentTimeCompletionDate.split("/");
      var devCompDate = new Date(
        parseInt(devCompleteDate[1]),
        parseInt(devCompleteDate[0] - 1),
        1
      );

      if (
        (year.getDay() > devCompDate.getDay() &&
          year.getFullYear() > devCompDate.getFullYear() &&
          year.getMonth() + 1 > devCompDate.getMonth()) ||
        year >= devCompDate
      ) {
        setFieldValue(e, year);
        closeToast();
      } else {
        toast({
          position: "top-right",
          isClosable: true,
          duration: 5000,
          render: () => (
            <ToastAlert
              title="Operation start date has to be after the Development end date."
              icon={errIcon}
              closeToast={closeToast}
              type="Error"
            />
          ),
        });
      }
    } else {
      setFieldValue(e, year);
    }

    if (!isNaN(opStartDate)) {
      var year = new Date(e2).getFullYear();
      var difference = year - opStartDate;

      // if (difference !== 0) {
      //   arr.forEach((element) => setStepWarning(page + element));
      // }
    }
  };

  const handleDurationChange = (e, e2) => {
    // console.log("e2", e2);
    let tempDate = new Date(formikValues?.startDateDevTime);
    let opStartDate2 = new Date(formikValues?.startDateOpTime);
    tempDate.addMonths(e2);
    if (tempDate > opStartDate2) {
      toast({
        position: "top-right",
        isClosable: true,
        duration: 5000,
        render: () => (
          <ToastAlert
            title="Development end date has to be before Operation start Date."
            icon={errIcon}
            closeToast={closeToast}
            type="Error"
          />
        ),
      });
    } else {
      {
        setFieldValue(e, e2);
      }
    }
  };
  const handleBaseYearChange = (e, e2) => {
    let assetYear = new Date(e2);
    if (formikValues?.startDateDevTime !== "") {
      let devDate = new Date(formikValues?.startDateDevTime);
      if (assetYear > devDate) {
        toast({
          position: "top-right",
          isClosable: true,
          duration: 5000,
          render: () => (
            <ToastAlert
              title="Asset base year has to be eariler than the Development start date."
              icon={errIcon}
              closeToast={closeToast}
              type="Error"
            />
          ),
        });
      } else {
        setFieldValue(e, assetYear);
        closeToast();
      }
    } else {
      setFieldValue(e, assetYear);
    }
  };

  useEffect(() => {
    if (emptyform1Data.length > 0) {
      if (isDisabledForm) {
        setStepWarning(page);
        setStepperUpdate(true);
      }
      return () => {
        if (isDisabledForm && !stepperUpdate) {
          setStepWarning(page);
          setStepperUpdate(true);
        } else if (!isDisabledForm && stepperUpdate) {
          setStepCompleted(page);
          unsetStepWarning(page);
          setStepperUpdate(false);
        }
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        form1Counter = 0;
      };
    }
  }, [isDisabledForm]);

  // console.log('errrrr', errors)
  return (
    <>
      <Box alignItems="flex-start" width="100%" mb={5}>
        <MainTitle title="Asset overview" />
        {/* <Text className="title_md" mb={5} mt={4}>
          Asset general details
        </Text> */}
      </Box>

      <Accordion width="100%" allowToggle color="black" defaultIndex={[0]}>
        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  General
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={6} border="1px" borderColor="#E6E6E6">
            <Box
              maxW={["100%", "100%", "65%"]}
              width={["100%", "100%", "65%"]}
              alignSelf="flex-start"
            >
              <InputFieldValidation
                label="Project Name"
                inputType="text"
                inputId="projectName"
                inputName="projectName"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                isRequired
                onChange={setFieldValue}
                values={formikValues}
                isDisabled={isRestore || user === "proponent"}
                validateField={validateStep}
              />

              <SelectListMulti
                dropdownItems={dropdownObj.region}
                label="Region"
                inputId="regionLookup"
                inputName="regionLookup"
                placeholder="Select"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                isRequired
                values={formikValues.regionLookup}
                onChange={setFieldValue}
                onBlur={handleBlur}
                uppercase={true}
                isDisabled={isRestore || user === "proponent"}
                validateField={validateStep}
              />

              <SelectListMulti
                dropdownItems={dropdownObj.operator}
                key={`operatorLookups_${formikValues?.operatorLookup}`}
                label="Operator"
                inputId="operatorLookup"
                inputName="operatorLookup"
                placeholder="Select"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                isRequired
                values={formikValues.operatorLookup}
                onChange={setFieldValue}
                // onChange2={(e, e2) => handleChange2(e, e2)}
                // isDynamic={true}
                onBlur={handleBlur}
                uppercase={true}
                isDisabled={isRestore || user === "proponent"}
                validateField={validateStep}
              />
              <SelectListMulti
                dropdownItems={
                  // user === "proponent"
                  //   ? dropdownObj.brand
                  //   : formikValues.operatorLookup === ""
                  //   ? []
                  // : brandDropdown
                  dropdownObj.brand
                }
                key={`brandLookUps_${formikValues?.brandLookup}`}
                label="Brand"
                inputId="brandLookup"
                inputName="brandLookup"
                placeholder="Select"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                isRequired
                values={formikValues.brandLookup}
                onChange={setFieldValue}
                onBlur={handleBlur}
                uppercase={true}
                isDisabled={isRestore || user === "proponent"}
                validateField={validateStep}
              />
              <SelectListMulti
                dropdownItems={dropdownObj.positioning}
                label="Positioning"
                inputId="positioningLookup"
                inputName="positioningLookup"
                placeholder="Select"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                isRequired
                values={formikValues.positioningLookup}
                onChange={setFieldValue}
                onBlur={handleBlur}
                uppercase={true}
                isDisabled={isRestore || user === "proponent"}
                validateField={validateStep}
              />
              <SelectListMulti
                dropdownItems={dropdownObj.managementContract}
                label="Operating Model"
                inputId="managementContractLookup"
                inputName="managementContractLookup"
                placeholder="Select"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                isRequired
                values={formikValues.managementContractLookup}
                onChange={setFieldValue}
                onBlur={handleBlur}
                uppercase={true}
                isDisabled={isRestore || user === "proponent"}
                validateField={validateStep}
              />
              <SelectListMulti
                dropdownItems={dropdownObj.projectStatus}
                label="Project Status"
                inputId="projectStatusLookup"
                inputName="projectStatusLookup"
                placeholder="Select"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                isRequired
                values={formikValues.projectStatusLookup}
                onChange={setFieldValue}
                onBlur={handleBlur}
                uppercase={true}
                isDisabled={isRestore || user === "proponent"}
                validateField={validateStep}
              />
              <SelectListMulti
                dropdownItems={dropdownObj.projectStage}
                label="Project Stage"
                inputId="projectStageLookup"
                inputName="projectStageLookup"
                placeholder="Select"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                isRequired
                values={formikValues.projectStageLookup}
                onChange={setFieldValue}
                onBlur={handleBlur}
                uppercase={true}
                isDisabled={isRestore || user === "proponent"}
                validateField={validateStep}
              />
              <InputFieldValidation
                label="Number of Keys (EXCL. branded residences)"
                inputType="number"
                inputId="numberOfKeys"
                inputName="numberOfKeys"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                isRequired
                onChange={setFieldValue}
                values={formikValues}
                isDisabled={isRestore || user === "proponent"}
                validateField={validateStep}
                isThousand={true}
                onBlur={handleBlur}
                setFieldTouched={setFieldTouched}
              />

              <InputFieldValidation
                label="Plot size (SqM)"
                inputType="number"
                inputId="plotSize"
                inputName="plotSize"
                placeholder="0"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                minNum={0}
                //step="0.01"
                isRequired
                onChange={setFieldValue}
                values={formikValues}
                icon={m2}
                isDisabled={isRestore || user === "proponent"}
                validateField={validateStep}
                isThousand={true}
                onBlur={handleBlur}
                setFieldTouched={setFieldTouched}
              />

              <InputFieldValidation
                label="Gross Floor Area (sqm)"
                inputType="number"
                inputId="grossFloorArea"
                inputName="grossFloorArea"
                placeholder="0"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                minNum={0}
                //step="0.01"
                isRequired
                onChange={setFieldValue}
                values={formikValues}
                icon={m2}
                isDisabled={isRestore || user === "proponent"}
                validateField={validateStep}
                isThousand={true}
                onBlur={handleBlur}
                setFieldTouched={setFieldTouched}
              />

              <InputFieldValidation
                label="Parking Area (sqm)"
                inputType="number"
                inputId="parkingArea"
                inputName="parkingArea"
                placeholder="0"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                minNum={0}
                //step="0.01"
                isRequired
                onChange={setFieldValue}
                values={formikValues}
                icon={m2}
                isDisabled={isRestore || user === "proponent"}
                validateField={validateStep}
                isThousand={true}
                onBlur={handleBlur}
                setFieldTouched={setFieldTouched}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  TIMELINES
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={6} border="1px" borderColor="#E6E6E6">
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              position="relative"
            >
              <Box maxW="65%" width="65%" alignSelf="flex-start">
                <DatePickerField
                  label="Asset Base Year"
                  inputType="date"
                  inputId="assetBaseYear"
                  inputName="assetBaseYear"
                  errors={errors}
                  setIsFormDirty={setIsFormDirty}
                  isFormDirty={isFormDirty}
                  touched={touched}
                  isRequired
                  // values={`${formikValues.assetBaseYear}`}
                  values={formikValues.assetBaseYear}
                  onChange={(e, e2) => handleBaseYearChange(e, e2)}
                  onBlur={handleBlur}
                  // minDate={new Date(`01/01/${new Date().getFullYear()}`)}
                  // maxDate={new Date("01/01/2050")}
                  minDate={new Date("01/01/2000")}
                  // hintText="Select a year between 2022 - 2050"
                  icon={calendar}
                  isDisable={isRestore || user === "proponent"}
                  validateField={validateStep}
                  //isDisable={true}
                />
              </Box>
              <Box className="tooltip_field">
                <TooltipElement label="The Base Year is utilized to calculate price and cost inflation across the analysis period." />
              </Box>
            </Box>

            <Text
              className="title_md"
              display="flex"
              mb={5}
              mt={3}
              component="div"
            >
              Development time
            </Text>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              position="relative"
              gap="15px"
            >
              <Box maxW="32%" width="32%" alignSelf="flex-start">
                <DatePickerField
                  label="Start Date"
                  inputType="date"
                  inputId="startDateDevTime"
                  inputName="startDateDevTime"
                  errors={errors}
                  setIsFormDirty={setIsFormDirty}
                  isFormDirty={isFormDirty}
                  touched={touched}
                  isRequired
                  values={formikValues.startDateDevTime}
                  onChange={(e, e2) => handleDevChange(e, e2)}
                  onBlur={handleBlur}
                  minDate={new Date("01/01/2022")}
                  maxDate={new Date("01/01/2050")}
                  hintText="Select a year between 2022 - 2050"
                  icon={calendar}
                  // handleClose={handleClose2}
                  // isAssetField={true}
                  // onChange2={(e, e2) => handleDevChange(e, e2)}
                  format="yearMonth"
                  isDisable={isRestore || user === "proponent"}
                  validateField={validateStep}
                />
              </Box>
              <Box maxW="32%" width="32%" alignSelf="flex-start">
                <InputFieldValidation
                  label="Duration (months)"
                  inputType="number"
                  inputId="developmentTimeDurationMonths"
                  inputName="developmentTimeDurationMonths"
                  placeholder="0"
                  errors={errors}
                  setIsFormDirty={setIsFormDirty}
                  isFormDirty={isFormDirty}
                  touched={touched}
                  minNum={0}
                  maxNum={100}
                  hintText="Between 0 - 60"
                  //step="1"
                  isRequired
                  onChange={(e, e2) => handleDurationChange(e, e2)}
                  values={formikValues}
                  isDisabled={isRestore || user === "proponent"}
                  validateField={validateStep}
                  setFieldTouched={setFieldTouched}
                />
              </Box>
              <Box maxW="32%" width="32%" alignSelf="flex-start">
                <InputFieldValidation
                  label="Completion date"
                  placeholder={"-"}
                  inputType="text"
                  inputId="developmentTimeCompletionDate"
                  inputName="developmentTimeCompletionDate"
                  errors={errors}
                  setIsFormDirty={setIsFormDirty}
                  isFormDirty={isFormDirty}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                  noBg={true}
                  validateField={validateStep}
                  setFieldTouched={setFieldTouched}
                />
              </Box>
            </Box>

            <Text
              className="title_md"
              display="flex"
              mb={5}
              mt={3}
              component="div"
            >
              operation time
            </Text>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              position="relative"
              gap="15px"
            >
              <Box maxW="32%" width="32%" alignSelf="flex-start">
                <DatePickerField
                  label="Start Date"
                  inputType="date"
                  inputId="startDateOpTime"
                  inputName="startDateOpTime"
                  errors={errors}
                  setIsFormDirty={setIsFormDirty}
                  isFormDirty={isFormDirty}
                  touched={touched}
                  isRequired
                  values={formikValues.startDateOpTime}
                  // onChange={setFieldValue}
                  onBlur={handleBlur}
                  minDate={new Date(`01/01/${new Date().getFullYear()}`)}
                  maxDate={new Date("01/01/2050")}
                  hintText={`Select a year between ${new Date().getFullYear()} - 2050`}
                  icon={calendar}
                  format="yearMonth"
                  // handleClose={handleClose}
                  // isAssetField={true}
                  onChange={(e, e2) => handleOpChange(e, e2)}
                  isDisable={isRestore || user === "proponent"}
                  validateField={validateStep}
                />
              </Box>
              <Box maxW="32%" width="32%" alignSelf="flex-start">
                <InputFieldValidation
                  label="Months of Operations"
                  inputType="number"
                  inputId="operationTimeDurationMonths"
                  inputName="operationTimeDurationMonths"
                  placeholder="0"
                  errors={errors}
                  setIsFormDirty={setIsFormDirty}
                  isFormDirty={isFormDirty}
                  touched={touched}
                  minNum={60}
                  maxNum={600}
                  //step="1"
                  isRequired
                  hintText="Between 60 - 600"
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={isRestore || user === "proponent"}
                  validateField={validateStep}
                  setFieldTouched={setFieldTouched}
                />
              </Box>
              <Box maxW="32%" width="32%" alignSelf="flex-start">
                <InputFieldValidation
                  label="Operations End Date"
                  placeholder="-"
                  inputType="text"
                  inputId="operationTimeEndDate"
                  inputName="operationTimeEndDate"
                  errors={errors}
                  setIsFormDirty={setIsFormDirty}
                  isFormDirty={isFormDirty}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                  noBg={true}
                  validateField={validateStep}
                  setFieldTouched={setFieldTouched}
                />
              </Box>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent={user == "manager"? "end": "space-between"} alignItems="center" width="100%">
        {
            user !== "manager" ? 
            <MainButton
            title="Save Draft"
            btnStyle="transparent"
            disabledBtn={isRestore || user === "proponent"}
            fontColor="dark"
            onPress={() => {
              setDismissSave(false);
              onDraftOpen();
            }}
          /> : <></>
        }

        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}

          {isDisabledForm ? (
            <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              onPress={() => {
                setValidateStep(true);
                isModelWarningOpen(true);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
          ) : (
            <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              onPress={() => {
                nextPage();
                if (user === "admin") {
                  if (percentage < 19) {
                    setPercentage(19);
                  }
                } else if (user === "proponent") {
                  if (percentage < 33) {
                    setPercentage(33);
                  }
                }

                setStepCompleted(page);
                unsetStepWarning(page);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
          )}
          {/* <MainButton
            title="Next"
            reverseRow={true}
            marginLeft="2"
            icon={rightArrow}
            disabledBtn={isDisabledForm ? true : false}
            onPress={() => {
              nextPage();

              if (percentage < 19) {
                setPercentage(19);
              }
              setStepCompleted(page);
              document.getElementById("main_content").scrollTo(0, 0);
            }}
          /> */}
        </Box>
      </Flex>
    </>
  );
};
