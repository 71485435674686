import React from "react";
import { ErrorMessage, Field } from "formik";
import { Text, Box } from "@chakra-ui/react";
import { NumericFormat } from "react-number-format";

import styles from "../forms.module.scss";

import view from "../../assets/images/icons/eye.svg";
import eyeSlash from "../../assets/images/icons/eye-slash.svg";

// - You can hide the label throw putting labelVisibility to false
export const InputFieldCalc = ({
  label,
  inputType,
  inputId,
  inputName,
  isRequired = true,
  labelVisibility = true,
  placeholder,
  errors,
  touched,
  dColumn = false,
  minNum = "0",
  // maxNum = "10000000",
  maxNum,
  fullWidth = "",
  password = false,
  showPassHandler,
  icon,
  hintText,
  onChange,
  values,
  step,
  uppercase = true,
  labelCenter,
  mbBox,
  errorHeight0,
  leftIcon,
  leftIconText,
  currency,
  currencyConvertor,
  isDisabled,
  noBg,
  smallIcon,
  hideLabel,
  fs15,
  fs14,
  fs12,
  calculatedOcc = false,
  onChangeCalc,
  currConvVal = 0,
  directVal = false,
  setNameVal,
  cancelFuncCalc = false,
  validateField = false,
  smallLeftIcon = "",
  smallerIcon = false,
  onChange2,
  isDynamic,
  seperatedFieldCalc = false,
  setIsFormDirty,
  isFormDirty,
  isThousand,
  setFieldTouched,
  onBlur,
}) => {
  let inputProps;
  if (values) {
    if (directVal) {
      inputProps = {
        value: values,
      };
    } else {
      inputProps = {
        value: values[inputName],
      };
    }
  }

  let boxProps;
  let labelUppercaseClassname = uppercase ? `${styles.uppercase}` : "";

  labelUppercaseClassname += labelCenter ? ` ${styles.labelCenter} ` : "";

  if (mbBox) {
    boxProps = {
      mb: mbBox,
    };
  }

  let validateFieldLogic;
  if (
    (validateField && directVal) ||
    (validateField && `${inputName}`.includes("performaceAssitList2"))
  ) {
    if (`${inputName}`.includes("developmentAbsorptionLand")) {
      validateFieldLogic = validateField ? values === "" : false;
    } else {
      validateFieldLogic = validateField ? values === "" || values < 0 : false;
    }
  } else if (validateField) {
    validateFieldLogic = validateField ? inputProps.value === "" : false;
  }
  if (
    `${inputName}`.includes("weightedADR") ||
    `${inputName}`.includes("occupancyIncRateYear.4")
  ) {
    validateFieldLogic = false;
  }
  const errorInputClassname =
    (errors[inputName] && touched[inputName] && errors[inputName]) ||
    validateFieldLogic
      ? `${styles.form_control} ${styles.form_control_error} ${
          dColumn ? "flex_column" : ""
        } `
      : `${styles.form_control} ${dColumn ? "flex_column" : ""}`;

  let formInputClassname = labelVisibility
    ? `${styles.form_control_input} ${fullWidth}`
    : `${styles.form_control_input} ${styles.form_control_full}  ${fullWidth}`;

  formInputClassname += labelCenter ? ` ${styles.textAlignCenter} ` : "";

  formInputClassname += noBg ? ` ${styles.fieldNoBg} ` : "";

  formInputClassname += leftIcon ? ` ${styles.haveLeftIcon} ` : "";

  formInputClassname += fs15 ? ` ${styles.fs15} ` : "";

  formInputClassname += fs14 ? ` ${styles.fs14} ` : "";

  formInputClassname += fs12 ? ` ${styles.fs12} ` : "";

  formInputClassname += smallerIcon ? ` ${styles.pr5} ` : "";

  formInputClassname +=
    smallLeftIcon !== "" ? ` ${styles.haveSmallIconLeft} ` : "";
  formInputClassname += !icon && smallLeftIcon !== "" ? ` ${styles.pr5} ` : "";

  formInputClassname += smallerIcon ? ` ${styles.haveSmallerIconLeft} ` : "";

  const haveOnChangeMethod = onChange ? (
    isThousand ? (
      <NumericFormat
        placeholder={placeholder}
        {...inputProps}
        name={inputName}
        id={inputName}
        onBlur={onBlur}
        valueIsNumericString={true}
        thousandSeparator={true}
        // decimalSeparator={"."}
        disabled={isDisabled}
        isAllowed={(values) => {
          const { floatValue } = values;
          // console.log("floatValue", floatValue);
          if (!!maxNum) {
            return (
              (floatValue >= parseInt(minNum) &&
                floatValue <= parseInt(maxNum)) ||
              floatValue === undefined
            );
          } else {
            return floatValue >= parseInt(minNum) || floatValue === undefined;
          }
        }}
        onChange={(val) => {
          let value;
          // console.log("value", val.target.value);
          if (setFieldTouched) {
            setFieldTouched(inputName, true);
          }
          if (inputType == "number") {
            if (!isNaN(parseFloat(val.target.value.replaceAll(",", "")))) {
              value = parseFloat(val.target.value.replaceAll(",", ""));
            } else {
              value = val.target.value;
            }
          } else {
            // value = val.target.value;
            value = "";
          }

          // console.log("value second", value);

          onChange(inputName, value);
          // onChange(inputName, value);
          if (setIsFormDirty && !isFormDirty) {
            setIsFormDirty(true);
          }
          if (isDynamic) {
            onChange2(inputName, value);
          }
          if (calculatedOcc) {
            /* setTimeout(() => { */
            if (cancelFuncCalc) {
              setNameVal({
                name: inputName,
                val: value,
              });
            } else {
              onChangeCalc(inputName, value);
              if (isDynamic) {
                onChange2(inputName, value);
              }
            }
          }
        }}
      />
    ) : (
      <Field
        disabled={isDisabled}
        type={inputType}
        name={inputName}
        id={inputName}
        placeholder={placeholder}
        min={minNum}
        max={maxNum}
        step={step}
        {...inputProps}
        onWheel={(e) => e.target.blur()}
        onChange={(val) => {
          let value;
          if (inputType == "number") {
            if (!isNaN(parseFloat(val.target.value))) {
              value = parseFloat(val.target.value);
            } else {
              value = val.target.value;
            }
          } else {
            value = val.target.value;
          }

          onChange(inputName, value);
          if (setIsFormDirty && !isFormDirty) {
            setIsFormDirty(true);
          }
          if (isDynamic) {
            onChange2(inputName, value);
          }
          if (calculatedOcc) {
            /* setTimeout(() => { */
            if (cancelFuncCalc) {
              setNameVal({
                name: inputName,
                val: value,
              });
            } else {
              onChangeCalc(inputName, value);
              if (isDynamic) {
                onChange2(inputName, value);
              }
            }
          }
        }}
      />
    )
  ) : (
    // <Field
    //   disabled={isDisabled}
    //   type={inputType}
    //   name={inputName}
    //   id={inputName}
    //   placeholder={placeholder}
    //   min={minNum}
    //   max={maxNum}
    //   step={step}
    //   {...inputProps}
    //   onWheel={(e) => e.target.blur()}
    //   onChange={(val) => {
    //     let value;
    //     if (inputType == "number") {
    //       if (!isNaN(parseFloat(val.target.value))) {
    //         value = parseFloat(val.target.value);
    //       } else {
    //         value = val.target.value;
    //       }
    //     } else {
    //       value = val.target.value;
    //     }

    //     onChange(inputName, value);
    //     if (setIsFormDirty && !isFormDirty) {
    //       setIsFormDirty(true);
    //     }
    //     if (isDynamic) {
    //       onChange2(inputName, value);
    //     }
    //     if (calculatedOcc) {
    //       /* setTimeout(() => { */
    //       if (cancelFuncCalc) {
    //         setNameVal({
    //           name: inputName,
    //           val: value,
    //         });
    //       } else {
    //         onChangeCalc(inputName, value);
    //         if (isDynamic) {
    //           onChange2(inputName, value);
    //         }
    //       }
    //     }
    //   }}
    // />
    <Field
      disabled={isDisabled}
      type={inputType}
      name={inputName}
      id={inputName}
      placeholder={placeholder}
      min={minNum}
      max={maxNum}
      step={step}
      onWheel={(e) => e.target.blur()}
    />
  );

  return (
    <Box className={errorInputClassname} {...boxProps}>
      {labelVisibility && (
        <label
          style={hideLabel === true ? { visibility: "hidden" } : {}}
          htmlFor={inputId}
          className={`${fullWidth} ${labelUppercaseClassname}`}
        >
          {label}
        </label>
      )}

      <div className={formInputClassname}>
        {haveOnChangeMethod}
        {smallLeftIcon !== "" && (
          <div
            className={
              smallerIcon
                ? styles.input_left_icon_smaller
                : styles.input_left_icon_small
            }
          >
            <span>{smallLeftIcon}</span>
          </div>
        )}
        {leftIcon && (
          <div className={styles.input_left_icon}>
            <span>{leftIconText}</span>
          </div>
        )}
        {password && (
          <div className="showPassword" onClick={() => showPassHandler()}>
            <img
              src={inputType === "password" ? view : eyeSlash}
              alt="eye icon"
            />
          </div>
        )}
        {icon && (
          <div className="iconic_input">
            <img src={icon} alt="icon" />
          </div>
        )}
        {smallIcon && (
          <div className=" iconic_input_small ">
            <img src={smallIcon} alt="icon" />
          </div>
        )}
        {isRequired && (
          <ErrorMessage
            name={inputName}
            component="div"
            style={{position:"absolute" }}
            className={`${styles.error} ${errorHeight0 ? styles.h_0 : ""} ${
              labelCenter ? styles.textAlignCenter : ""
            }`}
          />
        )}
        {currencyConvertor && !seperatedFieldCalc && (
          <Box>
            <Text className="hint_sm" textAlign="center">
              <span className="currVal">{currConvVal}</span>
              <span className="currType"> {currency}</span>
            </Text>
          </Box>
        )}
        {currencyConvertor && seperatedFieldCalc && (
          <>
            <Text
              fontSize="12px"
              color="black"
              fontWeight="400"
              letterSpacing="0.04em"
              textAlign="left"
              textTransform="uppercase"
              style={{marginTop:"37px"}}
              mt={3}
              mb={4}
              height="20px"
              width={`${inputName}`.includes("0") ? `150px` : `auto`}   
            >
              {`${inputName}`.includes("0") && "Actual occupancy"}
            </Text>

            <Box
              mt={2}
              border="1px solid #efefef"
              borderRadius="5px"
              background="#fcfcfc"
            >
              <Field
                disabled={true}
                type={inputType}
                name={`${inputName}_calc`}
                value={currConvVal}
                id={`${inputName}_calc`}
                placeholder="0"
              />
            </Box>
          </>
        )}
        {hintText && <Text className="hint_sm">{hintText}</Text>}
      </div>
    </Box>
  );
};
