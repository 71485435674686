import axiosClient from "./index";

// MASTER DATA - REGION

export const GetMasterDataRegionCommentsAPI = async () => {
  const URL = "/v1/masterData/regions/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataRegionAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/masterData/regions?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = `/v1/masterData/regions `;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataRegionByIdAPI = async (id) => {
  const URL = `/v1/masterData/regions/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataRegionDraftByIdAPI = async (parentId, draftId) => {
  const URL = `/v1/masterData/regions/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddMasterDataRegionAPI = async (payload) => {
  const URL = "/v1/masterData/regions";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteMasterDataRegionDraftAPI = async (parentId, draftId) => {
  const URL = `/v1/masterData/regions/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// MASTER DATA - ASSET CATEGORY
export const GetMasterDataAssetCategoryCommentsAPI = async () => {
  const URL = "/v1/masterData/assetCategory/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataAssetCategoryAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/masterData/assetCategory?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = "/v1/masterData/assetCategory";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataAssetCategoryByIdAPI = async (id) => {
  const URL = `/v1/masterData/assetCategory/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataAssetCategoryDraftByIdAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/masterData/assetCategory/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddMasterDataAssetCategoryAPI = async (payload) => {
  const URL = "/v1/masterData/assetCategory";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};
export const DeleteMasterDataAssetCategoryDraftAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/masterData/assetCategory/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// MASTER DATA - ROOM CATEGORY GetMasterDataRoomCategoryAPI
export const GetMasterDataRoomCategoryCommentsAPI = async () => {
  const URL = "/v1/masterData/roomCategory/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataRoomCategoryAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/masterData/roomCategory?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = "/v1/masterData/roomCategory";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataRoomCategoryByIdAPI = async (id) => {
  const URL = `/v1/masterData/roomCategory/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataRoomCategoryDraftByIdAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/masterData/roomCategory/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddMasterDataRoomCategoryAPI = async (payload) => {
  const URL = "/v1/masterData/roomCategory";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};
export const DeleteMasterDataRoomCategoryDraftAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/masterData/roomCategory/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// MASTER DATA - OPERATOR
export const GetMasterDataOperatorCommentsAPI = async () => {
  const URL = "/v1/masterData/operator/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataOperatorAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/masterData/operator?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = "/v1/masterData/operator";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataOperatorByIdAPI = async (id) => {
  const URL = `/v1/masterData/operator/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataOperatorDraftByIdAPI = async (parentId, draftId) => {
  const URL = `/v1/masterData/operator/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddMasterDataOperatorAPI = async (payload) => {
  const URL = "/v1/masterData/operator";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};
export const DeleteMasterDataOperatorDraftAPI = async (parentId, draftId) => {
  const URL = `/v1/masterData/operator/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// MASTER DATA - PACKAGE TYPE
export const GetMasterDataPackageTypeCommentsAPI = async () => {
  const URL = "/v1/masterData/packageType/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataPackageTypeAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/masterData/packageType?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = "/v1/masterData/packageType";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataPackageTypeByIdAPI = async (id) => {
  const URL = `/v1/masterData/packageType/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataPackageTypeDraftByIdAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/masterData/packageType/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddMasterDataPackageTypeAPI = async (payload) => {
  const URL = "/v1/masterData/packageType";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};
export const DeleteMasterDataPackageTypeDraftAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/masterData/packageType/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// MASTER DATA - CONTRACT
export const GetMasterDataContractCommentsAPI = async () => {
  const URL = "/v1/masterData/contract/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataContractAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/masterData/contract?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = "/v1/masterData/contract";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataContractByIdAPI = async (id) => {
  const URL = `/v1/masterData/contract/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataContractDraftByIdAPI = async (parentId, draftId) => {
  const URL = `/v1/masterData/contract/getDraft/${parentId}/${draftId}`;

  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddMasterDataContractAPI = async (payload) => {
  const URL = "/v1/masterData/contract";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};
export const DeleteMasterDataContractDraftAPI = async (parentId, draftId) => {
  const URL = `/v1/masterData/contract/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// MASTER DATA - PROJECT STATUS
export const GetMasterDataProjectStatusCommentsAPI = async () => {
  const URL = "/v1/masterData/projectStatus/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataProjectStatusAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/masterData/projectStatus?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = "/v1/masterData/projectStatus";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataProjectStatusByIdAPI = async (id) => {
  const URL = `/v1/masterData/projectStatus/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataProjectStatusDraftByIdAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/masterData/projectStatus/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddMasterDataProjectStatusAPI = async (payload) => {
  const URL = "/v1/masterData/projectStatus";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};
export const DeleteMasterDataProjectStatusDraftAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/masterData/projectStatus/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// MASTER DATA - PROJECT STAGE
export const GetMasterDataProjectStageCommentsAPI = async () => {
  const URL = "/v1/masterData/projectStage/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataProjectStageAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/masterData/projectStage?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = "/v1/masterData/projectStage";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataProjectStageByIdAPI = async (id) => {
  const URL = `/v1/masterData/projectStage/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataProjectStageDraftByIdAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/masterData/projectStage/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddMasterDataProjectStageAPI = async (payload) => {
  const URL = "/v1/masterData/projectStage";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};
export const DeleteMasterDataProjectStageDraftAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/masterData/projectStage/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// MASTER DATA - Positioning
export const GetMasterDataPositioningCommentsAPI = async () => {
  const URL = "/v1/masterData/positioning/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataPositioningAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/masterData/positioning?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = "/v1/masterData/positioning";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataPositioningByIdAPI = async (id) => {
  const URL = `/v1/masterData/positioning/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataPositioningDraftByIdAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/masterData/positioning/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddMasterDataPositioningAPI = async (payload) => {
  const URL = "/v1/masterData/positioning";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};
export const DeleteMasterDataPositioningDraftAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/masterData/positioning/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// MASTER DATA - Room Type
export const GetMasterDataRoomTypeCommentsAPI = async () => {
  const URL = "/v1/masterData/roomType/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataRoomTypeAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/masterData/roomType?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = "/v1/masterData/roomType";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataRoomTypeByIdAPI = async (id) => {
  const URL = `/v1/masterData/roomType/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataRoomTypeDraftByIdAPI = async (parentId, draftId) => {
  const URL = `/v1/masterData/roomType/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddMasterDataRoomTypeAPI = async (payload) => {
  const URL = "/v1/masterData/roomType";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};
export const DeleteMasterDataRoomTypeDraftAPI = async (parentId, draftId) => {
  const URL = `/v1/masterData/roomType/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// MASTER DATA - Brand
export const GetMasterDataBrandCommentsAPI = async () => {
  const URL = "/v1/masterData/brand/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataBrandAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/masterData/brand?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = "/v1/masterData/brand";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataBrandByIdAPI = async (id) => {
  const URL = `/v1/masterData/brand/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMasterDataBrandDraftByIdAPI = async (parentId, draftId) => {
  const URL = `/v1/masterData/brand/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddMasterDataBrandAPI = async (payload) => {
  const URL = "/v1/masterData/brand";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};
export const DeleteMasterDataBrandDraftAPI = async (parentId, draftId) => {
  const URL = `/v1/masterData/brand/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// GET PRODUCTION REGION
export const GetProductionMasterDataWithIsActive = async () => {
  const URL = "/v1/masterData/productionRegions";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// GET PRODUCTION ROOM CATEGORY
export const GetProductionRoomCategoryWithIsActive = async () => {
  const URL = "/v1/masterData/roomCategoryProduction";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// GET PRODUCTION OPERATOR
export const GetProductionOperatorWithIsActive = async () => {
  const URL = "/v1/masterData/operatorProduction";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// GET PRODUCTION POSITIONING
export const GetProductionPositioningWithIsActive = async () => {
  const URL = "/v1/masterData/positioningProduction";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// GET PRODUCTION BRAND
export const GetProductionBrand = async () => {
  const URL = "/v1/masterData/brandProduction";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// GET PRODUCTION CONTRACT
export const GetProductionContract = async () => {
  const URL = "/v1/masterData/contractProduction";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// GET PRODUCTION STATUS
export const GetProductionStatus = async () => {
  const URL = "/v1/masterData/projectStatusProduction";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// GET PRODUCTION STAGE
export const GetProductionStage = async () => {
  const URL = "/v1/masterData/projectStageProduction";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// GET ASSETS USERS
export const GetAssetsUsers = async () => {
  const URL = "/v1/assets/users";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// GET ROOM TYPES
export const GetProductionRoomType = async () => {
  const URL = "/v1/masterData/roomTypeProduction";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// GET PACKAGE TYPES
export const GetProductionPackageType = async () => {
  const URL = "/v1/masterData/packageTypeProduction";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// GET GIS REGIONS
export const GetGIS = async () => {
  const URL = "/v1/masterData/gis";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// GET Region Comment 
export const GetRegionComment = async (region, pageName) => {
  // {{localBaseUrl}}/masterData/regionComments?regionValue=gulf_of_aqaba&pageName=top_line_kpis
  const URL = `/v1/masterData/regionComments?regionValue=${region}&pageName=${pageName}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data.data.comment;
  } catch (error) {
    throw error;
  }
};

// SET Region Comment
export const SetRegionComment = async (payload) => {
  const URL = "/v1/masterData/regionComments";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
