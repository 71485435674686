import React from "react";

import { Box, Image } from "@chakra-ui/react";

import close from "../../assets/images/icons/UnionClose.svg";

export const ToastAlert = ({
  title,
  desc,
  icon,
  closeToast,
  type = "Success",
}) => {
  let borderLeftColor;
  let imgCircleBG;
  switch (type) {
    case "Success":
      borderLeftColor = "#006B44";
      imgCircleBG = "rgba(0, 107, 68, 0.1)";
      break;
    case "Warning":
      borderLeftColor = "#F18825";
      imgCircleBG = "rgba(241, 136, 37, 0.1)";
      break;
    case "Error":
      borderLeftColor = "#E0403F";
      imgCircleBG = "rgba(224, 64, 63, 0.1)";
      break;
  }

  return (
    <Box
      color="#000000"
      bg="white"
      borderLeftWidth="8px"
      borderLeftColor={borderLeftColor}
      borderLeftStyle="solid"
      borderRadius={5}
      fontSize="15px"
      display="flex"
      alignItems="center"
      boxShadow="0px 4px 23px rgba(0, 0, 0, 0.15)"
    >
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        position="relative"
        py={6}
        px={3}
      >
        <Box
          bg={imgCircleBG}
          borderRadius={50}
          width="39px"
          height="39px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          mr={2}
        >
          <Image src={icon} width="21px" height="21px" />
        </Box>
        <Box flex="1">{title}</Box>

        <Box
          position="absolute"
          top="15px"
          right="15px"
          onClick={() => closeToast()}
        >
          <Image src={close} width="10px" />
        </Box>
      </Box>
    </Box>
  );
};
