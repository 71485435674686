import React, { useEffect, useRef, useCallback, useState } from "react";

import {
  Box,
  Text,
  Flex,
  Divider,
  SimpleGrid,
  GridItem,
  RadioGroup,
  Stack,
  Radio,
  useEditableControls,
  EditableInput,
  Editable,
  EditablePreview,
  Input,
  IconButton,
  Icon,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import { FieldArray } from "formik";

import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import percent from "../../assets/images/icons/filled.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import { nullifyYearsField } from "../../utils/General";
import { TooltipElement } from "../../components/tooltip/TooltipElement";
import editRow from "../../assets/images/icons/editRow.svg";
import removeRow from "../../assets/images/icons/removeRow.svg";
import pen from "../../assets/images/icons/penBlue.svg";
import {CalculationButton} from"../../components/calculationModal/calculationButton"

export const AssetsListForm8 = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  opStartDate,
  unsetStepWarning,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  step,
  setStepWarning,
  stepperUpdate,
  setStepperUpdate,
  validateStep,
  setValidateStep,
  id,
  modelWarningOpen,
  isModelWarningOpen,
  closeModalPath,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
  user
}) => {
  let currDate = new Date(formikValues?.startDateOpTime).getFullYear() - 1;
  //console.log("s", step);
  const dataFetchedRef = useRef(false);
  const [name, setName] = useState(formikValues.franchiseFeeLabel);
  const [nameReservationContribution, setNameReservationContribution] =
    useState(formikValues.reservationContributionLabel);
  const [nameAdminAndGeneral, setNameAdminAndGeneral] = useState(
    formikValues.adminAndGeneralLabel
  );

  let form8Data = [];

  form8Data.push(formikValues?.ffeReserveAddBack); //radio
  form8Data.push(formikValues.marketingContributionField2); //string
  form8Data.push(formikValues.technicalFeeToOperator); //string
  form8Data.push(formikValues.leaseFixed); //string
  // form8Data.push(formikValues.staffPerKey); //string
  // form8Data.push(formikValues.staffHousing); //string
  form8Data.push(formikValues.staffPhasing); //string
  formikValues?.staffPerKey?.forEach((item) => {
    form8Data.push(item);
  });
  formikValues?.staffHousing?.forEach((item) => {
    form8Data.push(item);
  });
  formikValues?.managmentIncentiveFee?.forEach((item) => {
    form8Data.push(item.ifGOPLessThanPercent);
    form8Data.push(item.feePercentOfGOP);
  });
  form8Data.push(formikValues.marketingContribution); //string
  // form8Data.push(formikValues.franchiseFee); //string
  formikValues?.franchiseFee?.forEach((item) => {
    form8Data.push(item.fixedCharge);
    form8Data.push(item.percentage);
  });
  form8Data.push(formikValues.revenueType);
  form8Data.push(formikValues.percentageCap);
  form8Data.push(formikValues.includeFranchiseFee);
  form8Data.push(formikValues.franchiseFeeLabel);

  // form8Data.push(formikValues.reservationContribution); //string
  formikValues?.reservationContribution?.forEach((item) => {
    form8Data.push(item.fixedCharge);
    form8Data.push(item.percentage);
  });
  formikValues?.adminAndGeneral?.forEach((item) => {
    form8Data.push(item.fixedCharge);
    form8Data.push(item.percentage);
  });

  form8Data.push(formikValues.rewardProgram); //string
  form8Data.push(formikValues.roomBookings); //string
  form8Data.push(formikValues.operatorOtherProgramCost); //string
  form8Data.push(formikValues.leaseVariable); //string
  form8Data.push(formikValues.workingCapital); //string

  let emptyform8Data = [];
  formikValues?.managmentBaseFee?.forEach((item) => {
    form8Data.push(item);
  });
  formikValues?.managmentFFEReserveFee?.forEach((item) => {
    form8Data.push(item);
  });
  form8Data.push(formikValues.reservationContributionRevenueType); //string
  form8Data.push(formikValues.reservationContributionLabel); //string

  form8Data.push(formikValues.adminAndGeneralRevenueType); //string
  form8Data.push(formikValues.adminAndGeneralLabel); //string

  let ifGopLessThan = false;
  form8Data.forEach((item, ind) => {
    if (ind > 14 && ind <= 20) {
      let countGreaterDesc = 0;
      formikValues.managmentIncentiveFee.forEach((item2, ind2) => {
        if (formikValues.managmentIncentiveFee.length - 2 >= ind2) {
          if (
            formikValues.managmentIncentiveFee[ind2 + 1].ifGOPLessThanPercent >=
            item2.ifGOPLessThanPercent
          ) {
            countGreaterDesc = countGreaterDesc + 1;
          }
        }
      });
      if (countGreaterDesc === 5 && parseInt(item) <= "100") {
        ifGopLessThan = false;
        emptyform8Data.push(item);
      } else {
        ifGopLessThan = true;
      }
    } else if (ind === 0) {
      if (item != "") {
        emptyform8Data.push(item);
      }
    } else if (ind === 27) {
      if (item !== "" && item >= "0") {
        emptyform8Data.push(item);
      }
    } else if (
      [
        5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 28, 30, 32, 34, 36, 42, 44, 46, 48,
        50, 52, 54, 56, 58, 60,
      ].includes(ind)
    ) {
      if (item !== "" && parseInt(item) >= 0) {
        emptyform8Data.push(item);
      }
    } else if ([38, 40, 77, 79].includes(ind)) {
      emptyform8Data.push(item);
    } else if ([41, 78, 80].includes(ind)) {
      if (item !== "") emptyform8Data.push(item);
    } else if (
      (ind > 13 && ind !== 19) ||
      [29, 31, 33, 35, 37, 43, 45, 47, 49, 51, 53, 55, 57, 59, 61].includes(ind)
    ) {
      if (item !== "" && parseInt(item) >= 0 && parseInt(item) <= 100) {
        emptyform8Data.push(item);
      }
    } else {
      if (item !== "" && item >= "0") {
        emptyform8Data.push(item);
      }
    }
  });

  let isDisabledForm = false;

  if (emptyform8Data.length !== form8Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  const resetDate = () => {
    currDate = new Date(formikValues?.startDateOpTime).getFullYear() - 1;
  };

  const handleChangeRadio = (event, name) => {
    if (setIsFormDirty && !isFormDirty) {
      setIsFormDirty(true);
    }
    setFieldValue(name, event);
  };
  const renderYearsFields = (type) => {
    return (
      <FieldArray
        name="managmentBaseFee"
        render={(arrayHelper) => (
          <Box display="flex" width="100%" gap="2.5%">
            {formikValues?.[`${type}`] !== undefined &&
              formikValues?.[`${type}`]?.map((_, index) => {
                currDate = currDate + 1;
                return (
                  <Box width={["100%", "100%", "40%"]} key={`${type}.${index}`}>
                    <InputFieldValidation
                      label={`Year ${currDate}`}
                      placeholder="0"
                      smallIcon={percent}
                      inputType="number"
                      inputId={`${type}.${index}`}
                      inputName={`${type}.${index}`}
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      setFieldTouched={setFieldTouched}
                      touched={touched}
                      isRequired
                      onChange={setFieldValue}
                      values={formikValues?.[`${type}`][index]}
                      labelCenter={true}
                      errorHeight0={true}
                      uppercase={false}
                      directVal={true}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                    />
                  </Box>
                );
              })}
            {resetDate()}
          </Box>
        )}
      />
    );
  };

  const renderIncentiveFeeFields = () => {
    return (
      <FieldArray
        name="incentiveFee"
        render={(arrayHelper) =>
          formikValues.managmentIncentiveFee !== undefined &&
          formikValues.managmentIncentiveFee.map((_, index) => {
            return (
              <Box
                display="flex"
                width="100%"
                gap="4%"
                flexWrap="wrap"
                key={`incentiveFee_${index}`}
              >
                <Box width={["100%", "100%", "48%"]}>
                  <InputFieldValidation
                    label="if GOP <"
                    placeholder="0"
                    inputType="number"
                    inputId={`managmentIncentiveFee.${index}.ifGOPLessThanPercent`}
                    inputName={`managmentIncentiveFee.${index}.ifGOPLessThanPercent`}
                    errors={errors}
                    setIsFormDirty={setIsFormDirty}
                    isFormDirty={isFormDirty}
                    setFieldTouched={setFieldTouched}
                    touched={touched}
                    isRequired
                    onChange={setFieldValue}
                    values={
                      formikValues?.managmentIncentiveFee[index]
                        .ifGOPLessThanPercent
                    }
                    //errorHeight0={true}
                    icon={percent}
                    labelVisibility={index === 0 ? true : false}
                    isDisabled={isRestore}
                    validateField={validateStep}
                    directVal={true}
                    errorShape={true}
                    isThousand={true}
                  />
                </Box>
                <Box width={["100%", "100%", "48%"]}>
                  <InputFieldValidation
                    label={`Incentive fee % of AGOP`}
                    placeholder="0"
                    icon={percent}
                    inputType="number"
                    inputId={`managmentIncentiveFee.${index}.feePercentOfGOP`}
                    inputName={`managmentIncentiveFee.${index}.feePercentOfGOP`}
                    errors={errors}
                    setIsFormDirty={setIsFormDirty}
                    isFormDirty={isFormDirty}
                    setFieldTouched={setFieldTouched}
                    touched={touched}
                    isRequired
                    onChange={setFieldValue}
                    values={
                      formikValues?.managmentIncentiveFee[index].feePercentOfGOP
                    }
                    //errorHeight0={true}
                    labelVisibility={index === 0 ? true : false}
                    isDisabled={isRestore}
                    validateField={validateStep}
                    directVal={true}
                    isThousand={true}
                  />
                </Box>
              </Box>
            );
          })
        }
      />
    );
  };

  const renderFranchiseFeeEYearField = () => {
    return (
      <FieldArray
        name="franchiseFeeEYear"
        render={(arrayHelper) => (
          <SimpleGrid columns={{ sm: 5, md: 5 }} spacing="15px" width="100%">
            {formikValues.franchiseFee !== undefined &&
              formikValues.franchiseFee.map((_, index) => {
                currDate = currDate + 1;
                return (
                  <GridItem key={`franchiseFee${index}`}>
                    <InputFieldValidation
                      label={`Year ${currDate}`}
                      placeholder="0"
                      inputType="number"
                      inputId={`franchiseFee.${index}.fixedCharge`}
                      inputName={`franchiseFee.${index}.fixedCharge`}
                      errors={errors}
                      minNum="0"
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      onChange={setFieldValue}
                      values={formikValues?.franchiseFee[index].fixedCharge}
                      labelCenter={true}
                      uppercase={false}
                      directVal={true}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      smallLeftIcon="SAR"
                      smallerIcon="SAR"
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                    <>
                      <Text
                        textTransform="uppercase"
                        fontSize="12px !important"
                        display="flex"
                        mb={4}
                        mt={2}
                        component="div"
                        height="20px"
                        marginBottom="0px"
                        width={`${index}` == 0 ? `250px` : `auto`}
                      >
                        {`${index}` == 0 &&
                          `percentages of ( ${formikValues.revenueType} revenue )`}
                      </Text>
                    </>
                    <InputFieldValidation
                      placeholder="0"
                      inputType="number"
                      inputId={`franchiseFee.${index}.percentage`}
                      inputName={`franchiseFee.${index}.percentage`}
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      // maxNum="100"
                      onChange={setFieldValue}
                      values={formikValues?.franchiseFee[index].percentage}
                      labelCenter={true}
                      uppercase={false}
                      directVal={true}
                      smallIcon={percent}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                );
              })}

            {resetDate()}
          </SimpleGrid>
        )}
      />
    );
  };

  const renderReservationContributionYearField = () => {
    return (
      <FieldArray
        name="franchiseFeeEYear"
        render={(arrayHelper) => (
          <SimpleGrid columns={{ sm: 5, md: 5 }} spacing="15px" width="100%">
            {formikValues.reservationContribution !== undefined &&
              formikValues.reservationContribution.map((_, index) => {
                currDate++;
                return (
                  <GridItem key={`reservationContribution${index}`}>
                    <InputFieldValidation
                      label={`Year ${currDate}`}
                      placeholder="0"
                      inputType="number"
                      inputId={`reservationContribution.${index}.fixedCharge`}
                      inputName={`reservationContribution.${index}.fixedCharge`}
                      errors={errors}
                      minNum="0"
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      onChange={setFieldValue}
                      values={
                        formikValues?.reservationContribution[index].fixedCharge
                      }
                      labelCenter={true}
                      uppercase={false}
                      directVal={true}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      smallLeftIcon="SAR"
                      smallerIcon="SAR"
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                    <>
                      <Text
                        textTransform="uppercase"
                        fontSize="12px !important"
                        display="flex"
                        mb={4}
                        mt={2}
                        component="div"
                        height="20px"
                        marginBottom="0px"
                        width={`${index}` == 0 ? `250px` : `auto`}
                      >
                        {`${index}` == 0 &&
                          `percentages of ( ${formikValues.reservationContributionRevenueType} revenue )`}
                      </Text>
                    </>
                    <InputFieldValidation
                      placeholder="0"
                      inputType="number"
                      inputId={`reservationContribution.${index}.percentage`}
                      inputName={`reservationContribution.${index}.percentage`}
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      // maxNum="100"
                      onChange={setFieldValue}
                      values={
                        formikValues?.reservationContribution[index].percentage
                      }
                      labelCenter={true}
                      uppercase={false}
                      directVal={true}
                      smallIcon={percent}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                );
              })}

            {resetDate()}
          </SimpleGrid>
        )}
      />
    );
  };

  const renderadminGeneralOtherYearField = () => {
    return (
      <FieldArray
        name="franchiseFeeEYear"
        render={(arrayHelper) => (
          <SimpleGrid columns={{ sm: 5, md: 5 }} spacing="15px" width="100%">
            {formikValues.adminAndGeneral !== undefined &&
              formikValues.adminAndGeneral.map((_, index) => {
                currDate++;
                return (
                  <GridItem key={`adminAndGeneral${index}`}>
                    <InputFieldValidation
                      label={`Year ${currDate}`}
                      placeholder="0"
                      inputType="number"
                      inputId={`adminAndGeneral.${index}.fixedCharge`}
                      inputName={`adminAndGeneral.${index}.fixedCharge`}
                      errors={errors}
                      minNum="0"
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      onChange={setFieldValue}
                      values={formikValues?.adminAndGeneral[index].fixedCharge}
                      labelCenter={true}
                      uppercase={false}
                      directVal={true}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      smallLeftIcon="SAR"
                      smallerIcon="SAR"
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                    <>
                      <Text
                        textTransform="uppercase"
                        fontSize="12px !important"
                        display="flex"
                        mb={4}
                        mt={2}
                        component="div"
                        height="20px"
                        marginBottom="0px"
                        width={`${index}` == 0 ? `250px` : `auto`}
                      >
                        {`${index}` == 0 &&
                          `percentages of ( ${formikValues.adminAndGeneralRevenueType} revenue )`}
                      </Text>
                    </>
                    <InputFieldValidation
                      placeholder="0"
                      inputType="number"
                      inputId={`adminAndGeneral.${index}.percentage`}
                      inputName={`adminAndGeneral.${index}.percentage`}
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      // maxNum="100"
                      onChange={setFieldValue}
                      values={formikValues?.adminAndGeneral[index].percentage}
                      labelCenter={true}
                      uppercase={false}
                      directVal={true}
                      smallIcon={percent}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                );
              })}

            {resetDate()}
          </SimpleGrid>
        )}
      />
    );
  };
  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);

  // useEffect(() => {
  //   var difference =
  //     new Date(formikValues?.startDateOpTime).getFullYear() - opStartDate;
  //   if (difference !== 0) {
  //     nullifyYearsField(
  //       step,
  //       opStartDate,
  //       formikValues,
  //       setFieldValue,
  //       formikValues?.startDateOpTime,
  //       ["managmentBaseFee"]
  //     );
  //     nullifyYearsField(
  //       step,
  //       opStartDate,
  //       formikValues,
  //       setFieldValue,
  //       formikValues?.startDateOpTime,
  //       ["managmentFFEReserveFee"]
  //     );
  //   }
  // }, []);
  const onEditModelName = (event, labelName) => {
    let defultData =
      labelName == "reservationContributionLabel"
        ? "Reservation Contribution"
        : "FRANSHISE FEE";
    if (event !== "") {
      setFieldValue(labelName, event);
      if (setIsFormDirty && !isFormDirty) {
        setIsFormDirty(true);
      }
    }
    else {
      switch (labelName) {
        case "reservationContributionLabel":
          defultData = "reservationContributionLabel";
          setNameReservationContribution("Reservation Contribution");
          break;
        case "franchiseFeeLabel":
          defultData = "FRANSHISE FEE";
          setName("FRANSHISE FEE");
          break;
        case "adminAndGeneralLabel":
          defultData = "admin And General";
          setNameAdminAndGeneral("admin & general");
          break;
        default:
          break;
      }
      setFieldValue(labelName, defultData);
      
    }
  };
  const handleEditChange = (newValue, labelName) => {
    switch (labelName) {
      case "reservationContributionLabel":
        setNameReservationContribution(newValue);
        break;
      case "franchiseFeeLabel":
        setName(newValue);
        break;
      case "adminAndGeneralLabel":
        setNameAdminAndGeneral(newValue);
        break;
      default:
        break;
    }
  };
  const EditableControls = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <Box display="flex" justifyContent="space-around" width="80px">
        <button {...getSubmitButtonProps()}>
          <img src={editRow} alt="edit" width="20px" />
        </button>
        <button {...getCancelButtonProps()}>
          <img src={removeRow} alt="edit" width="20px" />
        </button>
      </Box>
    ) : (
      <Box marginLeft="10px">
        <button {...getEditButtonProps()} size="md">
          <img src={pen} width="13px" alt="edit icon" />
        </button>
      </Box>
    );
  };
  return (
    <>
      <Box alignItems="flex-start" justifyContent="space-between" display="flex" width="100%">
        <MainTitle title="OPERATOR TERMS AND STAFFING" />
        <CalculationButton  formikValue={formikValues} />

      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Box
          maxW={["100%", "100%", "100%"]}
          width={["100%", "100%", "100%"]}
          alignSelf="flex-start"
        >
          <Text
            className="title_md"
            display="flex"
            mb={2}
            mt={2}
            component="div"
          >
            MANAGEMENT FEES
          </Text>

          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Text
              className="title_md"
              fontSize="12px !important"
              display="flex"
              mb={4}
              mt={2}
              component="div"
            >
              BASE FEE&nbsp;
              {id === undefined && (
                <Box className="tooltip_field5" pt="5px">
                  <TooltipElement label="Prepopulated from Benchmark data." />
                </Box>
              )}
            </Text>
            <Box maxW="100%" width="100%" alignSelf="flex-start">
              {renderYearsFields("managmentBaseFee")}
            </Box>
          </Box>

          <Text
            className="title_md"
            fontSize="12px !important"
            display="flex"
            mb={4}
            mt={4}
            component="div"
          >
            INCENTIVE FEE&nbsp;
            {id === undefined && (
              <Box className="tooltip_field5" pt="5px">
                <TooltipElement label="Prepopulated from Benchmark data." />
              </Box>
            )}
          </Text>
          {renderIncentiveFeeFields()}
          {ifGopLessThan && (
            <GridItem colSpan={4}>
              <Text
                color="#e14642"
                fontWeight="400"
                fontSize="10px"
                lineHeight="17px"
                letterSpacing="0.04em"
              >
                Each field should be less than or equal the next field of IF GOP
              </Text>
            </GridItem>
          )}
          <Box
            width="100%"
            // display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Editable
              value={name}
              onChange={(value) => {
                handleEditChange(value, "franchiseFeeLabel");
              }}
              fontSize="15px"
              // isPreviewFocusable={false}
              width="100%"
              display="flex"
              alignItems="center"
              className="title_md"
              mb={5}
              mt={3}
              onSubmit={(value) => {
                onEditModelName(value, "franchiseFeeLabel");
              }}
            >
              <EditablePreview />
              {/* Here is the custom input */}
              <Input as={EditableInput} borderRadius={0} textAlign="left" />
              <EditableControls />
            </Editable>
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Text
              className="title_md"
              fontSize="12px !important"
              display="flex"
              marginBottom="0px"
              component="div"
            >
              FIXED CHARGE&nbsp;
            </Text>
            <Box maxW="100%" width="100%" alignSelf="flex-start">
              {renderFranchiseFeeEYearField()}
            </Box>
            <Box mb={5}>
              <>
                <Text
                  textTransform="uppercase"
                  fontSize="12px !important"
                  display="flex"
                  mb={4}
                  mt={2}
                  component="div"
                  marginBottom="0px"
                  height="20px"
                  width={`auto`}
                >
                  Revenue type
                </Text>
              </>
              <SelectListMulti
                key={`myUniqueKey`}
                dropdownItems={dropdownObj.revenueStatus}
                inputId={`revenueType`}
                inputName={`revenueType`}
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                isRequired
                values={formikValues?.revenueType}
                onChange={setFieldValue}
                onBlur={handleBlur}
                uppercase={true}
                iconArrow={true}
                placeH="Select type"
                isDisabled={isRestore}
                validateField={validateStep}
                isDynamic={true}
              />
            </Box>
          </Box>
          <Box>
            <GridItem colSpan={4}>
              <InputFieldValidation
                label="Fee Cap"
                inputType="number"
                inputId="percentageCap"
                inputName="percentageCap"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                setFieldTouched={setFieldTouched}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0"
                onChange={setFieldValue}
                values={formikValues}
                icon={percent}
                isDisabled={isRestore}
                validateField={validateStep}
                isThousand={true}
              />
            </GridItem>

            {/* <Box bgColor="#FCFCFC" py={5} px={5} width="100%" mt={20}>
  
        </Box> */}
            {/* {formikValues?.includeFranchiseFee && ( */}
            <>
              <Text
                className="title_sm"
                display="flex"
                mb={2}
                mt={3}
                component="div"
                letterSpacing="0.06em"
                fontSize="12px"
                textTransform="uppercase"
              >
                Include {formikValues.franchiseFeeLabel} in calculations
              </Text>

              <RadioGroup
                onChange={(event) =>
                  handleChangeRadio(event, "includeFranchiseFee")
                }
                value={`${formikValues?.includeFranchiseFee}`}
                className="radio_group_custom"
                mb={4}
                name="includeFranchiseFee"
                isDisabled={isRestore}
                validateField={validateStep}
                defaultValue="yes"
              >
                <Stack direction="row">
                  <Radio
                    color="#003865 !important"
                    bg="none !important"
                    borderColor="#6F767E !important"
                    value="yes"
                  >
                    Yes
                  </Radio>
                  <Radio
                    color="#003865 !important"
                    bg="none !important"
                    borderColor="#6F767E !important"
                    value="no"
                  >
                    No
                  </Radio>
                </Stack>
              </RadioGroup>
            </>
            {/* )} */}
          </Box>

          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            OTHER FEES
          </Text>
          <Box
            width="100%"
            // display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Editable
              value={nameReservationContribution}
              onChange={(value) => {
                handleEditChange(value, "reservationContributionLabel");
              }}
              fontSize="15px"
              // isPreviewFocusable={false}
              width="100%"
              display="flex"
              alignItems="center"
              className="title_md"
              mb={5}
              mt={3}
              onSubmit={(value) => {
                onEditModelName(value, "reservationContributionLabel");
              }}
            >
              <EditablePreview />
              {/* Here is the custom input */}
              <Input as={EditableInput} borderRadius={0} textAlign="left" />
              <EditableControls />
            </Editable>
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <SimpleGrid columns={{ sm: 2, md: 2 }} spacingX="15px" width="100%">
              <GridItem colSpan={4}>
                <SimpleGrid
                  columns={{ sm: 1, md: 1 }}
                  spacing="15px"
                  width="100%"
                >
                  <GridItem colSpan={4}>
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Text
                        className="title_md"
                        fontSize="12px !important"
                        display="flex"
                        marginBottom="0px"
                        component="div"
                      >
                        FIXED CHARGE&nbsp;
                      </Text>
                      <Box maxW="100%" width="100%" alignSelf="flex-start">
                        {renderReservationContributionYearField()}
                      </Box>
                      <Box mb={5}>
                        <>
                          <Text
                            textTransform="uppercase"
                            fontSize="12px !important"
                            display="flex"
                            mb={4}
                            mt={2}
                            component="div"
                            marginBottom="0px"
                            height="20px"
                            width={`auto`}
                          >
                            Revenue type
                          </Text>
                        </>
                        <SelectListMulti
                          key={`myUniqueKey`}
                          dropdownItems={dropdownObj.revenueStatus}
                          inputId={`reservationContributionRevenueType`}
                          inputName={`reservationContributionRevenueType`}
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          isRequired
                          values={
                            formikValues?.reservationContributionRevenueType
                          }
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          uppercase={true}
                          iconArrow={true}
                          placeH="Select type"
                          isDisabled={isRestore}
                          validateField={validateStep}
                          isDynamic={true}
                        />
                      </Box>
                    </Box>
                  </GridItem>
                  <Box
                    width="100%"
                    // display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Editable
                      value={nameAdminAndGeneral}
                      onChange={(value) => {
                        handleEditChange(value, "adminAndGeneralLabel");
                      }}
                      fontSize="15px"
                      // isPreviewFocusable={false}
                      width="100%"
                      display="flex"
                      alignItems="center"
                      className="title_md"
                      mb={5}
                      mt={3}
                      onSubmit={(value) => {
                        onEditModelName(value, "adminAndGeneralLabel");
                      }}
                    >
                      <EditablePreview />
                      {/* Here is the custom input */}
                      <Input
                        as={EditableInput}
                        borderRadius={0}
                        textAlign="left"
                      />
                      <EditableControls />
                    </Editable>
                  </Box>
                  <GridItem colSpan={4}>
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Text
                        className="title_md"
                        fontSize="12px !important"
                        display="flex"
                        marginBottom="0px"
                        component="div"
                      >
                        FIXED CHARGE&nbsp;
                      </Text>
                      <Box maxW="100%" width="100%" alignSelf="flex-start">
                        {renderadminGeneralOtherYearField()}
                      </Box>
                      <Box mb={5}>
                        <>
                          <Text
                            textTransform="uppercase"
                            fontSize="12px !important"
                            display="flex"
                            mb={4}
                            mt={2}
                            component="div"
                            marginBottom="0px"
                            height="20px"
                            width={`auto`}
                          >
                            Revenue type
                          </Text>
                        </>
                        <SelectListMulti
                          key={`myUniqueKey`}
                          dropdownItems={dropdownObj.revenueStatus}
                          inputId={`adminAndGeneralRevenueType`}
                          inputName={`adminAndGeneralRevenueType`}
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          isRequired
                          values={formikValues?.adminAndGeneralRevenueType}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          uppercase={true}
                          iconArrow={true}
                          placeH="Select type"
                          isDisabled={isRestore}
                          validateField={validateStep}
                          isDynamic={true}
                        />
                      </Box>
                    </Box>
                  </GridItem>
                </SimpleGrid>
                <SimpleGrid
                  columns={{ sm: 4, md: 4 }}
                  spacing="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SelectListMulti
                      dropdownItems={dropdownObj.timelineStatus}
                      label="MARKETING CONTRIBUTION"
                      inputId="marketingContribution"
                      inputName="marketingContribution"
                      placeholder="Select"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      values={formikValues.marketingContribution}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      uppercase={true}
                      isDisabled={isRestore}
                      validateField={validateStep}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      hideLabel={true}
                      label={"blank"}
                      uppercase={false}
                      inputType="number"
                      inputId="marketingContributionField2"
                      inputName="marketingContributionField2"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      setFieldTouched={setFieldTouched}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      icon={percent}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                    />
                  </GridItem>
                  <GridItem colSpan={2} className="lable_fixed_height">
                    <InputFieldValidation
                      label="Reward Program (as a % of Room Revenue)"
                      inputType="number"
                      inputId="rewardProgram"
                      inputName="rewardProgram"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      setFieldTouched={setFieldTouched}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      // fs12={true}
                      icon={percent}
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="% of Room Bookings"
                      inputType="number"
                      inputId="roomBookings"
                      inputName="roomBookings"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      setFieldTouched={setFieldTouched}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      icon={percent}
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                    />
                  </GridItem>

                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="OPERATOR OTHER PROGRAM COST (as a % of Revenue)"
                      inputType="number"
                      inputId="operatorOtherProgramCost"
                      inputName="operatorOtherProgramCost"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      setFieldTouched={setFieldTouched}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      icon={percent}
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                    />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="TECHNICAL FEE TO OPERATOR (SAR per Year)"
                      inputType="number"
                      inputId="technicalFeeToOperator"
                      inputName="technicalFeeToOperator"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      setFieldTouched={setFieldTouched}
                      touched={touched}
                      isRequired
                      minNum="0"
                      leftIcon={true}
                      leftIconText={"SAR"}
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      // icon={percent}
                    />
                  </GridItem>
                </SimpleGrid>

                <Text
                  fontSize="12px"
                  color="black"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textAlign="left"
                  textTransform="uppercase"
                  mt={3}
                  mb={5}
                >
                  FF&E Reserve
                </Text>
                {renderYearsFields("managmentFFEReserveFee")}

                <Box>
                  <Text
                    className="title_sm"
                    display="flex"
                    mb={3}
                    mt={2}
                    component="div"
                    textTransform="uppercase"
                    fontSize="12px"
                  >
                    ffe & Reserve Add Back
                  </Text>
                  <RadioGroup
                    //onChange={handleChangeRadio}
                    //value={useCalc}
                    onChange={(event) =>
                      handleChangeRadio(event, "ffeReserveAddBack")
                    }
                    value={`${formikValues?.ffeReserveAddBack}`}
                    className="radio_group_custom"
                    mb={6}
                    name="ffeReserveAddBack"
                    isDisabled={isRestore}
                  >
                    <Stack direction="row">
                      <Radio
                        color="#003865 !important"
                        bg="none !important"
                        borderColor="#6F767E !important"
                        value="1"
                      >
                        Yes
                      </Radio>
                      <Radio
                        color="#003865 !important"
                        bg="none !important"
                        borderColor="#6F767E !important"
                        value="2"
                      >
                        No
                      </Radio>
                    </Stack>
                    {!formikValues?.ffeReserveAddBack && validateStep && (
                      <Box
                        color="#e14642"
                        fontWeight="400"
                        fontSize="10px"
                        lineHeight="17px"
                        letterSpacing="0.04em"
                        marginTop="5px"
                      >
                        Required
                      </Box>
                    )}
                  </RadioGroup>
                </Box>

                <SimpleGrid
                  columns={{ sm: 4, md: 4 }}
                  mt="5"
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={4}>
                    <SimpleGrid
                      columns={{ sm: 4, md: 4 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem colSpan={2}>
                        <InputFieldValidation
                          label="Lease - Fixed"
                          inputType="number"
                          inputId="leaseFixed"
                          inputName="leaseFixed"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          setFieldTouched={setFieldTouched}
                          touched={touched}
                          isRequired
                          minNum="0"
                          placeholder="0"
                          onChange={setFieldValue}
                          values={formikValues}
                          isDisabled={isRestore}
                          validateField={validateStep}
                          leftIcon={true}
                          leftIconText="SAR"
                          isThousand={true}
                          onBlur={handleBlur}
                        />
                      </GridItem>
                      <GridItem colSpan={2}>
                        <InputFieldValidation
                          label="Lease - Variable (as a % of revenue)"
                          inputType="number"
                          inputId="leaseVariable"
                          inputName="leaseVariable"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          setFieldTouched={setFieldTouched}
                          touched={touched}
                          isRequired
                          minNum="0"
                          placeholder="0"
                          onChange={setFieldValue}
                          values={formikValues}
                          icon={percent}
                          isDisabled={isRestore}
                          isThousand={true}
                          validateField={validateStep}
                        />
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          </Box>

          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            STAFFING
          </Text>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
              <Text
                fontSize="12px"
                color="black"
                fontWeight="400"
                letterSpacing="0.04em"
                textAlign="left"
                textTransform="uppercase"
                mt={3}
                mb={5}
              >
                STAFF PER KEY
              </Text>
              <GridItem colSpan={5}>
                <SimpleGrid
                  columns={{ sm: 5, md: 5 }}
                  spacing="15px"
                  width="100%"
                >
                  {formikValues.staffPerKey !== undefined &&
                    formikValues.staffPerKey.map((_, index) => {
                      currDate++;
                      return (
                        <GridItem key={`staffPerKey${index}`}>
                          <InputFieldValidation
                            label={`Year ${currDate}`}
                            placeholder="0"
                            inputType="number"
                            inputId={`staffPerKey.${index}`}
                            inputName={`staffPerKey.${index}`}
                            errors={errors}
                            minNum="0"
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            isRequired
                            onChange={setFieldValue}
                            values={formikValues?.staffPerKey[index]}
                            labelCenter={true}
                            uppercase={false}
                            directVal={true}
                            isDisabled={isRestore}
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </GridItem>
                      );
                    })}
                  {resetDate()}
                  <Text
                    fontSize="12px"
                    color="black"
                    fontWeight="400"
                    letterSpacing="0.04em"
                    textAlign="left"
                    textTransform="uppercase"
                    mt={3}
                    mb={5}
                  >
                    Staff Housing - Cost per Staff {"(Per Year in SAR)"}
                  </Text>
                </SimpleGrid>
              </GridItem>
              <GridItem colSpan={5}>
                <SimpleGrid
                  columns={{ sm: 5, md: 5 }}
                  spacing="15px"
                  width="100%"
                >
                  {formikValues.staffHousing !== undefined &&
                    formikValues.staffHousing.map((_, index) => {
                      currDate++;
                      return (
                        <GridItem key={`staffHousing${index}`}>
                          <InputFieldValidation
                            label={`Year ${currDate}`}
                            placeholder="0"
                            inputType="number"
                            inputId={`staffHousing.${index}`}
                            inputName={`staffHousing.${index}`}
                            errors={errors}
                            minNum="0"
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            isRequired
                            onChange={setFieldValue}
                            values={formikValues?.staffHousing[index]}
                            labelCenter={true}
                            uppercase={false}
                            directVal={true}
                            isDisabled={isRestore}
                            validateField={validateStep}
                            smallLeftIcon={"SAR"}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </GridItem>
                      );
                    })}
                  {resetDate()}
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="STAFF PHASING (PRE-OPENING, in Months before Opening)"
                      inputType="number"
                      inputId="staffPhasing"
                      inputName="staffPhasing"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      setFieldTouched={setFieldTouched}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                    />
                  </GridItem>
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          </Box>
          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            WORKING CAPITAL
          </Text>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
              <GridItem colSpan={4}>
                <SimpleGrid
                  columns={{ sm: 4, md: 4 }}
                  spacing="15px"
                  width="100%"
                >
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="Working Capital (% of Revenue)"
                      inputType="number"
                      inputId="workingCapital"
                      inputName="workingCapital"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      setFieldTouched={setFieldTouched}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                    />
                  </GridItem>
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          </Box>
        </Box>
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex justifyContent={user == "manager" ? "end" : "space-between"} alignItems="center" width="100%">
        {
          user !== "manager" ?
            <MainButton
              title="Save Draft"
              btnStyle="transparent"
              disabledBtn={isRestore}
              fontColor="dark"
              onPress={() => {
                setDismissSave(false);
                onDraftOpen();
              }}
            /> : <></>
        }
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {/* <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm ? true : false}
              onPress={() => {
                nextPage();
                setStepCompleted(page);
                unsetStepWarning(page);
                if (percentage < 82) {
                  setPercentage(82);
                }
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  nextPage();
                  setStepCompleted(page);
                  unsetStepWarning(page);
                  if (percentage < 82) {
                    setPercentage(82);
                  }
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
