import * as Yup from "yup";

export const validationHotelKpisForm = [
  Yup.object().shape({
    benchmarkLevel: Yup.string().required("This field is required"),
    baseYear: Yup.string().required("This field is required"),

    adrValues: Yup.object().shape({
      adr: Yup.array(
        Yup.object().shape({
          //low: Yup.number().min(0, "Required").required("Required"),
          /* low: Yup.number()
            .min(0, "Required")
            .max(
              parseFloat(Yup.ref("high")) * 1,
              "Maximum tip is 10% of the price."
            ), */
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
      fnbRevenues: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
      otherRevenues: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
    }),
  }),
  Yup.object().shape({
    staffingValues: Yup.object().shape({
      staffAndKey: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          // low: Yup.number().min(0, "Required").required("Required"),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
      adr: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
      staffPerQuarter: Yup.array(
        Yup.object().shape({
          value: Yup.array().of(
            Yup.number().typeError('This must be a number')
              .min(0, "This should be greater than or equal zero")
              .max(100, "This should be less than or equal 100")
              .required("Required"))
        })
      )
    }),
  }),
  Yup.object().shape({
    occupancyRampUpValues: Yup.object().shape({
      year1: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
      year2: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
      year3: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
      year4: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
      year5: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  return true;
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
    }),
  }),
  Yup.object().shape({
    positioningValues: Yup.object().shape({
      standardRoomSize: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
      averageGFAAndKey: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
      costPerKey_SAR: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
      costPerSqMOfGFA_SAR: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
    }),
  }),
  Yup.object().shape({
    // comments: Yup.string().required("This field is required"),

    doubleOccupancyRateValues: Yup.object().shape({
      cityHotels: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
      resorts: Yup.array(
        Yup.object().shape({
          low: Yup.number()
            .min(0, "Should be greater than zero")
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "",
              test: function (value) {
                if (!!this.parent.high) {
                  return value <= parseFloat(this.parent.high * 1);
                } else {
                  if (this.parent.high === 0) {
                    return value <= parseFloat(this.parent.high * 1);
                  } else {
                    return true;
                  }
                }
              },
            }),
          high: Yup.number().min(0, "").required(""),
          average: Yup.number().min(0, "Required").required("Required"),
        })
      ),
    }),
  }),
];
