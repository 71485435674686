import React, { useState, useEffect, useRef } from "react";

import {
  Badge,
  Box,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Button,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";

import { MainButton } from "../../components/button/MainButton";
import { TablePagination2 } from "../../components/tablePagination/TablePagination2";
import { RenderCommentsBox } from "../../components/renderCommentsBox/RenderCommentsBox";
import { MainBox } from "../../components/mainBox/MainBox";

import threeDots from "../../assets/images/icons/threeDots.svg";
import remove from "../../assets/images/icons/deleteRed.svg";
import { CommentsTable } from "./comentDataTable";
export const MasterDataTable = ({
  masterData,
  comments,
  setSelectedID,
  onDelModalOpen,
  GetDataAPI,
}) => {
  const renderRef = useRef(false);
  const tableRef = useRef(null);
  let { type } = useParams();
  const [isTableLoading, setIsTableLoading] = useState(false);

  let typeSmall = type.toLowerCase();
  let [currPagData, setCurrPagData] = useState({
    page: 1,
    sortOrder: "DESC",
    sort: "lastModifiedAt",
  });
  const handleSort = async (column, sortDirection) => {
    tableRef?.current
      .querySelectorAll(".table_content .rdt_TableCol_Sortable")
    [column.id - 1]?.children[1]?.classList.add("active");
    let sortOrder = sortDirection.toUpperCase();
    let sort = column.sortField;
    handleOnPageChange(currPagData.page, sort, sortOrder);
    setCurrPagData({ ...currPagData, sort: sort, sortOrder: sortOrder });
  };
  const [dataArray, setDataArray] = useState([]);

  const columns = [
    {
      name: "VERSION",
      selector: (row) => row.versionNum,
      sortable: true,
      sortField: "versionNumber",
      // cell: (row) => <Box color={`#007BB5`}>{row.versionNum}</Box>,
    },
    {
      name: "LAST MODIFIED",
      selector: (row) => row.modDate,
      sortable: true,
      sortField: "lastModifiedAt",
    },
    {
      name: "MODIFIED BY",
      selector: (row) => row.modBy,
      sortable: true,
      sortField: "lastModifiedBy",
    },

    {
      name: "STATUS",
      selector: (row) => row.status,
      sortable: true,
      sortField: "status",
      // minWidth: "160px",
      // width: "160px",
      cell: (row) => (
        <Badge
          data-tag="allowRowEvents"
          data-action="edit"
          bg={row.statusBg}
          color="#fff"
          fontSize="14px"
          fontWeight="300"
          px={3}
          py="1"
          textTransform="none"
          borderRadius="3px"
        >
          {row.status}
        </Badge>
      ),
    },
    {
      name: "ACTION",
      selector: (row) => row.action,
      //sortable: true,
      cell: (row) => {
        let pathLogic;
        if (row.isDraftVersion || row.isInitialDraftVersion) {
          if (
            typeSmall === "positioning" ||
            type === "Room type" ||
            type === "Brand" ||
            type === "Regions"
          ) {
            pathLogic = `/MasterDataCustom/${type}/${row.parentVersionNumber}/${row.idCustom}`;
          } else {
            pathLogic = `/MasterData/${type}/${row.parentVersionNumber}/${row.idCustom}`;
          }
        } else {
          if (
            typeSmall === "positioning" ||
            type === "Room type" ||
            type === "Brand" ||
            type === "Regions"
          ) {
            pathLogic = `/MasterDataCustom/${type}/${row.versionNumberInt}`;
          } else {
            pathLogic = `/MasterData/${type}/${row.versionNumberInt}`;
          }
        }
        return (
          <Flex justifyContent="space-between" alignItems="center">
            <MainButton
              title="Preview"
              btnStyle="transparent"
              fontColor="dark"
              marginRight="5"
              navLink={true}
              path={pathLogic}
              fSize="sm_x2"
            />
            {(row.status === "Draft" || row.status === "Obsolete") &&
              !row.isInitialDraftVersion && (
                <Menu autoSelect={false}>
                  <MenuButton
                    bgColor="transparent"
                    px={1}
                    ml={1}
                    minWidth="auto"
                    as={Button}
                    _hover={{ backgroundColor: "transparent" }}
                  >
                    <img src={threeDots} alt="dots icon" />
                  </MenuButton>
                  <MenuList px="5px" py="15px" minW="150px" w={"150px"}>
                    <Text fontSize="12px" color="#6F767E" px="10px" mb="7px">
                      More actions
                    </Text>
                    <MenuItem
                      alignItems="center"
                      onClick={() => {
                        setSelectedID(
                          `draft-${row.parentVersionNumber}-${row.idCustom}`
                        );
                        onDelModalOpen();
                      }}
                    >
                      <img src={remove} alt="delete icon" />
                      <Text color="#E0403F" ml="5px">
                        Delete
                      </Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
          </Flex>
        );
      },
    },
  ];

  useEffect(() => {
    if (renderRef.current) return;
    renderRef.current = true;

    let data = [];
    if (masterData.pageRows?.length > 0 && !!masterData.pageRows) {
      masterData.pageRows.forEach((item) => {
        let statusBg;
        switch (item.status) {
          case "Production":
            statusBg = "#006B44";
            break;
          case "Archived":
            statusBg = "#A9A9A9";
            break;
          case "Obsolete":
            statusBg = "#007BB5";
            break;
          default:
            statusBg = "#007BB5";
            break;
        }
        if (!!item.lastModifiedAt === false) return 0;
        let date = dateFnsParseISO(item.lastModifiedAt);

        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.id}`,
          isInitialDraftVersion: item.status === "InitialDraft",
          isDraftVersion: item.status === "Draft" || item.status === "Obsolete",
          versionNum:
            item.status === "Draft" ||
              item.status === "Obsolete" ||
              item.status === "InitialDraft"
              ? `Version ${item.parentVersionNumber}`
              : `Version ${item.versionNumber}`,
          parentVersionNumber: item.parentVersionNumber,
          versionNumberInt: item.versionNumber || 0,
          modDate: dateFnsFormat(date, "dd.MM.yyyy; HH:mm"),
          modBy: `${item.lastModifiedBy}`,
          status: `${item.status === "InitialDraft" ? "Initial Draft" : item.status
            }`,
          statusBg: statusBg,
        };

        data.push(dataObj);
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
      sortOrder: "DESC",
      sort: "lastModifiedAt",
    });
  }, []);

  const handleOnPageChange = (page, sort = "lastModifiedAt", sortOrder) => {
    GetDataAPI(page, sort, sortOrder)
      .then((res) => {
        let data = [];

        if (res.data.pageRows.length > 0) {
          res.data.pageRows.forEach((item) => {
            let statusBg;
            switch (item.status) {
              case "Production":
                statusBg = "#006B44";
                break;
              case "Archived":
                statusBg = "#A9A9A9";
                break;
              case "Obsolete":
                statusBg = "#007BB5";
                break;
              default:
                statusBg = "#007BB5";
                break;
            }

            let date = dateFnsParseISO(item.lastModifiedAt);
            let dataObj = {
              id: `${item.id + Math.floor(Math.random() * Date.now())}`,
              idCustom: `${item.id}`,
              isInitialDraftVersion: item.status === "InitialDraft",
              isDraftVersion:
                item.status === "Draft" || item.status === "Obsolete",
              versionNum:
                item.status === "Draft" ||
                  item.status === "Obsolete" ||
                  item.status === "InitialDraft"
                  ? `Version ${item.parentVersionNumber}`
                  : `Version ${item.versionNumber}`,
              parentVersionNumber: item.parentVersionNumber,
              versionNumberInt: item.versionNumber || 0,
              modDate: dateFnsFormat(date, "dd.MM.yyyy; HH:mm"),
              modBy: `${item.lastModifiedBy}`,
              status: `${item.status === "InitialDraft" ? "Initial Draft" : item.status
                }`,
              statusBg: statusBg,
            };

            data.push(dataObj);
          });

          setDataArray(data);
          setIsTableLoading(false);
        }
      })
      .catch((err) => {
        setIsTableLoading(false);
        console.log(err);
      });
  };

  return (
    <HStack width="100%">
      <Tabs variant="unstyled" width="100%">
        <TabList w="95%" gap="3" mb={1}>
          <Tab
            _selected={{
              bg: "#F1EFE0",
              borderBottom: "2px",
              borderBottomColor: "#EBC03F !important",
              backgroundColor: "#F1EFE0",
            }}
            className="main_tabs"
            border="1px"
          >
            Version History
          </Tab>
          <Tab
            _selected={{
              bg: "#F1EFE0",
              borderBottom: "2px",
              borderBottomColor: "#EBC03F !important",
              backgroundColor: "#F1EFE0",
            }}
            className="main_tabs"
            border="1px"
          >
            Comments History
          </Tab>
          {type === "Regions" && (
            <Tab
              _selected={{
                bg: "#F1EFE0",
                borderBottom: "2px",
                borderBottomColor: "#EBC03F !important",
                backgroundColor: "#F1EFE0",
              }}
              className="main_tabs"
              border="1px"
            >
              Region Comments
            </Tab>
          )}
        </TabList>

        <TabPanels>
          <TabPanel w="98%" px="0" ref={tableRef}>
            <TablePagination2
              data={dataArray}
              columns={columns}
              isSearchable={false}
              title=""
              hasDelete={true}
              totalRows={masterData.totalRows}
              pageSize={masterData.pageSize}
              handleOnPageChange={handleOnPageChange}
              onSort={handleSort}
              setCurrPagData={setCurrPagData}
              currPagData={currPagData}
              isTableLoading={isTableLoading}
              setIsTableLoading={setIsTableLoading}
            />
          </TabPanel>
          <TabPanel paddingX={0} marginX={0}>
            <MainBox>
              <RenderCommentsBox comments={comments} />
            </MainBox>
          </TabPanel>

          {type === "Regions" && (
            <TabPanel w="98%" px="0" ref={tableRef}>
              <MainBox>
                <CommentsTable></CommentsTable>
              </MainBox>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </HStack>
  );
};