import React from "react";

import { Button, Center, Divider, Image, VStack } from "@chakra-ui/react";

import { MainBox } from "../components/mainBox/MainBox";

import loginErrorIcon from "../assets/images/icons/loginErrorIcon.svg";

import Cookies from "js-cookie";
import config from "../config";

export const LoginError = ({ setLoggedIn }) => {
  const authAPI = async () => {
    Cookies.remove("user_info");
    window.location.href = config.api.logoutBaseURL;
  };

  return (
    <div className="login_page">
      <MainBox boxSize="md" maxWidth="580px" borderWidth="5">
        <VStack>
          <Center>
            <Image src={loginErrorIcon}></Image>
          </Center>

          <Center pb={10}>
            {/* <div className="error_title2">401</div> */}
            <div className="error_title"> UNAUTHORIZED ACCESS</div>
          </Center>

          <Divider
            background="#E7E7E7"
            h="1.5"
            w="95%"
            orientation="horizontal"
            mt="10px"
            mb="5px"
          />

          <Center justifyContent="center" textAlign="center" pt={20}>
            <div className="error_desc">
              {/* Sorry, your access is denied. You are not authorized to view
                this site. */}
              You do not have permission to access this website. Verify that you
              are entering the right NEOM credentials and try again, or please
              contact NEOM administrators at{" "}
              <a href="calogero.granatella@neom.com">calogero.granatella@neom.com</a> to get
              access.
            </div>
          </Center>
          {/* <Center w="94%" pt={10} pb={10} textAlign="center">
              <div className="error_desc">
                For more information please contact the NEOM Hotel Development
                team
              </div>
            </Center> */}
          <Center w="100%" pb={20} pt={10}>
            <Button
              type="submit"
              width="40%"
              className="btn_submit"
              onClick={authAPI}
            >
              {/* <img src={windowsIcon} alt="azure login" /> */}
              Try again
            </Button>
          </Center>
        </VStack>
      </MainBox>
    </div>
  );
};
