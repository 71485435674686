import { convertEmpStringsArrToZero } from "./General";
import { addMonths, parse, parseISO, formatISO, format } from "date-fns";

export const assetListStandardFormFormikValues = (formikValue, oid) => {
  let newRoomType = [];
  let averageDailyRate = [];
  let unitType = [];
  let includedRentalPool = [];
  let avgDaysOwner = [];
  let stabilizedOcc = [];
  let adrPerUnit = [];
  let additionalExpenses = [];
  let newWeghitedOccupancyRate = [];
  let newWeightedAverageDailyRate = [];
  // console.log("submit formik", formikValue);
  let brandedResOtherRev = {
    incFoodAndBeverageOutlets: formikValue.brandedResidencesFields.otherRev[0],
    incBanquet: formikValue.brandedResidencesFields.otherRev[1],
    incRecreation: formikValue.brandedResidencesFields.otherRev[2],
    incSpa: formikValue.brandedResidencesFields.otherRev[3],
    incOtherProfitDepartments: formikValue.brandedResidencesFields.otherRev[4],
    incRetail: formikValue.brandedResidencesFields.otherRev[5],
    incMiscellaneousIncome: formikValue.brandedResidencesFields.otherRev[6],
    incRental: formikValue.brandedResidencesFields.otherRev[7],
    incOther1: formikValue.brandedResidencesFields.otherRev[8],
    incOther2: formikValue.brandedResidencesFields.otherRev[9],
  };
  // console.log("02-11-2014", devFullYear, devCompFullYear, opFullEndYear);

  formikValue.roomType.forEach((formValue, index) => {
    newRoomType.push({
      roomTypeLookup: formValue.type,
      numberOfKeys: formValue.numKeys,
      size: formValue.size,
      occupancyRate: formValue.occRate,
    });
  });
  if (!!formikValue?.performaceAssitList2) {
    formikValue.performaceAssitList2.forEach((formValue, index) => {
      averageDailyRate.push({
        roomTypeLookup: formValue.type,
        rates: formValue.year,
      });
    });
  }

  formikValue.unitMix.forEach((formValue, index) => {
    unitType.push({
      unitTypeLookup: formValue.type,
      noOfUnits: formValue.numKeys,
      totalSellable: formValue.totalSellable,
      baseCostPerKey_SAR: formValue.baseCost,
      salesPricePerSqm: formValue.salesPrice,
    });
  });
  if (formikValue.brandedResidencesFields.rentalPool.length > 0) {
    formikValue.unitMix.forEach((formValue, index) => {
      includedRentalPool.push({
        unitTypeLookup: formValue.type,
        value: formikValue.brandedResidencesFields.rentalPool[index].typeField || 0,
      });
      avgDaysOwner.push({
        unitTypeLookup: formValue.type,
        value:
          formikValue.brandedResidencesFields.avgNumberOfDaysPerAnnum[index]
            .typeField || 0,
      });
      stabilizedOcc.push({
        unitTypeLookup: formValue.type,
        value:
          formikValue.brandedResidencesFields.stabilizedOccupancy[index]
            .typeField || 0,
      });
      adrPerUnit.push({
        unitTypeLookup: formValue.type,
        value: formikValue.brandedResidencesFields.adrPerUnit[index].typeField || 0,
      });
    });
  }

  if (formikValue.expenses1 !== "" && formikValue.expenses1 !== undefined) {
    additionalExpenses.push(formikValue.expenses1);
  } else {
    additionalExpenses.push(0);
  }
  if (formikValue.expenses2 !== "" && formikValue.expenses2 !== undefined) {
    additionalExpenses.push(formikValue.expenses2);
  } else {
    additionalExpenses.push(0);
  }
  if (formikValue.expenses3 !== "" && formikValue.expenses3 !== undefined) {
    additionalExpenses.push(formikValue.expenses3);
  } else {
    additionalExpenses.push(0);
  }
  if (formikValue.expenses4 !== "" && formikValue.expenses4 !== undefined) {
    additionalExpenses.push(formikValue.expenses4);
  } else {
    additionalExpenses.push(0);
  }
  if (formikValue.expenses5 !== "" && formikValue.expenses5 !== undefined) {
    additionalExpenses.push(formikValue.expenses5);
  } else {
    additionalExpenses.push(0);
  }

  if (formikValue.calc1 !== "" && formikValue.calc1 !== undefined) {
    newWeghitedOccupancyRate.push(parseFloat(formikValue.calc1));
  } else {
    newWeghitedOccupancyRate.push(0);
  }
  if (formikValue.calc2 !== "" && formikValue.calc2 !== undefined) {
    newWeghitedOccupancyRate.push(parseFloat(formikValue.calc2));
  } else {
    newWeghitedOccupancyRate.push(0);
  }
  if (formikValue.calc3 !== "" && formikValue.calc3 !== undefined) {
    newWeghitedOccupancyRate.push(parseFloat(formikValue.calc3));
  } else {
    newWeghitedOccupancyRate.push(0);
  }
  if (formikValue.calc4 !== "" && formikValue.calc4 !== undefined) {
    newWeghitedOccupancyRate.push(parseFloat(formikValue.calc4));
  } else {
    newWeghitedOccupancyRate.push(0);
  }
  if (formikValue.calc5 !== "" && formikValue.calc5 !== undefined) {
    newWeghitedOccupancyRate.push(parseFloat(formikValue.calc5));
  } else {
    newWeghitedOccupancyRate.push(0);
  }
  if (formikValue.calc6 !== "" && formikValue.calc6 !== undefined) {
    newWeghitedOccupancyRate.push(parseFloat(formikValue.calc6));
  } else {
    newWeghitedOccupancyRate.push(0);
  }

  if (
    formikValue.weightedADR1 !== "" &&
    formikValue.weightedADR1 !== undefined
  ) {
    newWeightedAverageDailyRate.push(parseFloat(formikValue.weightedADR1));
  } else {
    newWeightedAverageDailyRate.push(0);
  }
  if (
    formikValue.weightedADR2 !== "" &&
    formikValue.weightedADR2 !== undefined
  ) {
    newWeightedAverageDailyRate.push(parseFloat(formikValue.weightedADR2));
  } else {
    newWeightedAverageDailyRate.push(0);
  }
  if (
    formikValue.weightedADR3 !== "" &&
    formikValue.weightedADR3 !== undefined
  ) {
    newWeightedAverageDailyRate.push(parseFloat(formikValue.weightedADR3));
  } else {
    newWeightedAverageDailyRate.push(0);
  }
  if (
    formikValue.weightedADR4 !== "" &&
    formikValue.weightedADR4 !== undefined
  ) {
    newWeightedAverageDailyRate.push(parseFloat(formikValue.weightedADR4));
  } else {
    newWeightedAverageDailyRate.push(0);
  }
  if (
    formikValue.weightedADR5 !== "" &&
    formikValue.weightedADR5 !== undefined
  ) {
    newWeightedAverageDailyRate.push(parseFloat(formikValue.weightedADR5));
  } else {
    newWeightedAverageDailyRate.push(0);
  }
  if (
    formikValue.weightedADR6 !== "" &&
    formikValue.weightedADR6 !== undefined
  ) {
    newWeightedAverageDailyRate.push(parseFloat(formikValue.weightedADR6));
  } else {
    newWeightedAverageDailyRate.push(0);
  }

  formikValue.developmentAbsorptionLand = convertEmpStringsArrToZero(
    formikValue.developmentAbsorptionLand
  );

  formikValue.occupancyIncRateYear = convertEmpStringsArrToZero(
    formikValue.occupancyIncRateYear
  );

  formikValue.managmentBaseFee = convertEmpStringsArrToZero(
    formikValue.managmentBaseFee
  );

  formikValue.managmentFFEReserveFee = convertEmpStringsArrToZero(
    formikValue.managmentFFEReserveFee
  );

  formikValue.annualSalesAbsorption = convertEmpStringsArrToZero(
    formikValue.annualSalesAbsorption
  );

  formikValue.brandedBaseFee = convertEmpStringsArrToZero(
    formikValue.brandedBaseFee
  );

  formikValue.brandedIncentiveFee = convertEmpStringsArrToZero(
    formikValue.brandedIncentiveFee
  );

  formikValue.brandedFranchiseFee = convertEmpStringsArrToZero(
    formikValue.brandedFranchiseFee
  );

  return {
    projectName: formikValue.projectName,
    assetType: "Standard",
    projectNameValue: formikValue.projectNameValue,
    comments: formikValue.addComment,
    assignedTo: formikValue.assignTo,
    assignedBy: oid,
    approveAssetInfo: formikValue.approveAssetInfo,
    regionLookup: formikValue.regionLookup,
    operatorLookup: formikValue.operatorLookup,
    brandLookup: formikValue.brandLookup,
    positioningLookup: formikValue.positioningLookup,
    managementContractLookup: formikValue.managementContractLookup,
    projectStatusLookup: formikValue.projectStatusLookup,
    projectStageLookup: formikValue.projectStageLookup,
    plotSize: formikValue.plotSize,
    grossFloorArea: formikValue.grossFloorArea,
    parkingArea: formikValue.parkingArea,
    assetBaseYear: formikValue.assetBaseYear
      ? formikValue.assetBaseYear.getFullYear()
      : "",
    developmentTimeStartYear: formikValue.startDateDevTime
      ? formikValue.startDateDevTime.getFullYear()
      : "",
    developmentTimeStartMonth: formikValue.startDateDevTime
      ? formikValue.startDateDevTime.getMonth()
      : "",
    developmentTimeDurationMonths:
      formikValue.developmentTimeDurationMonths || 0,
    developmentTimeStartDate: formikValue.startDateDevTime
      ? formatISO(formikValue.startDateDevTime)
      : "",
    developmentTimeCompletionDate:
      formikValue.developmentTimeCompletionDate &&
        formikValue.developmentTimeCompletionDate !== "-"
        ? formatISO(
          parse(
            formikValue.developmentTimeCompletionDate,
            "MM/yyyy",
            new Date()
          )
        )
        : "", //"02-11-2014", //
    operationStartYear: formikValue.startDateOpTime
      ? formikValue.startDateOpTime.getFullYear()
      : "",
    operationStartMonth: formikValue.startDateOpTime
      ? formikValue.startDateOpTime.getMonth()
      : "",
    operationStartDate: formikValue.startDateOpTime
      ? formatISO(formikValue.startDateOpTime)
      : "",
    operationTimeDurationMonths: formikValue.operationTimeDurationMonths || 0,
    operationTimeEndDate:
      formikValue.operationTimeEndDate &&
        formikValue.operationTimeEndDate !== "-"
        ? formatISO(
          parse(formikValue.operationTimeEndDate, "MM/yyyy", new Date())
        )
        : "", //"02-11-2014",
    numberOfKeys: formikValue.numberOfKeys,
    developmentCostValues: {
      general: {
        developmentCostPerKey_SAR:
          formikValue.devCostPerKey === "" ? 0 : formikValue.devCostPerKey,
        landCostFreehold_SAR:
          formikValue.landCost === "" ? 0 : formikValue.landCost,
        developmentAbsorptionLand: formikValue.developmentAbsorptionLand,
      },
      infrastructure: {
        cost_SAR: formikValue.infraCost === "" ? 0 : formikValue.infraCost,
        depreciation: formikValue.infraDepreciation,
        developmentStartMonth:
          formikValue.infraDevStartMonth === ""
            ? 0
            : parseFloat(formikValue.infraDevStartMonth),
        developmentDuration: formikValue.infraDevDuration,
      },
      parking: {
        cost_SAR: formikValue.parkingCost === "" ? 0 : formikValue.parkingCost,
        depreciation: formikValue.parkingDepreciation,
      },
      breakdown: {
        construction: {
          cost:
            formikValue.bDownConsCost === "" ? 0 : formikValue.bDownConsCost,
          depreciation: formikValue.bDownDepConsCost,
        },
        FFE_OSE: {
          cost: formikValue.bDownFfeOse === "" ? 0 : formikValue.bDownFfeOse,
          depreciation: formikValue.bDownDepFfeOse,
          developmentDuration: formikValue.ffeDevelopmentDuration,
        },
        softCosts: {
          cost:
            formikValue.bDownSoftCost === "" ? 0 : formikValue.bDownSoftCost,
          depreciation: formikValue.bDownDepSoftCost,
        },
        preOpening: {
          cost:
            formikValue.bDownPreOpenTM === "" ? 0 : formikValue.bDownPreOpenTM,
          depreciation: formikValue.bDownDepPreOpenTM,
          developmentDuration: formikValue.preOpeningDevelopmentDuration,
        },
        TSA: {
          cost: formikValue.bDownTsaFee === "" ? 0 : formikValue.bDownTsaFee,
          depreciation: formikValue.bDownDepTsaFee,
        },
        NEOMDevelopmentFee: {
          cost:
            formikValue.bDownNeomDevFee === ""
              ? 0
              : formikValue.bDownNeomDevFee,
          depreciation: formikValue.bDownDepNeomDevFee,
        },
        contingency: {
          cost:
            formikValue.bDownContCost === "" ? 0 : formikValue.bDownContCost,
          depreciation: formikValue.bDownDepContCost,
        },
        initialWorkingCapital:
          formikValue.bDownIniWorkCapital === ""
            ? 0
            : formikValue.bDownIniWorkCapital,
        percentageOfBaseCost:
          formikValue.baseCost === "" ? 0 : formikValue.baseCost,
      },
      totalDevelopmentCostPerKey_SAR:
        formikValue.calcTotalDevelopmentCostPerKey == undefined
          ? 0
          : formikValue.calcTotalDevelopmentCostPerKey,
      refurbishmentYear:
        formikValue.refurbishmentYear != "" &&
          formikValue.refurbishmentYear != null
          ? formikValue.refurbishmentYear.getFullYear()
          : -1,
      refurbishmentCost:
        formikValue.refurbishmentCost === ""
          ? -1
          : formikValue.refurbishmentCost,
    },
    financingAssumptions: {
      general: {
        taxRate: formikValue.taxRate,
        openingCashBalance: formikValue.openCashBalance,
        equity: formikValue.equity,
      },
      debt: {
        annualInterestRate: formikValue.annualInterestRate,
        debtTerm: formikValue.debtTerm,
        gracePeriod: formikValue.gracePeriod,
        debtProcessingFees: formikValue.debtProcFees,
        securityFees: formikValue.securityFees,
        commitmentFees: formikValue.commitmentFees,
      },
      sale: {
        sellingCosts: formikValue.sellingCosts,
        taxOnSaleValue: formikValue.TaxSaleVal,
      },
      risk: {
        // shouldUseCalculations:
        //   `${formikValue.useCalculation}` === "1" ? true : false,
        shouldUseCalculations:
          `${formikValue.useCalculation}` === "1"
            ? true
            : `${formikValue.useCalculation}` === "2"
              ? false
              : "",
        riskFreeRate: formikValue.riskFreeRate,
        equityRiskPremium: formikValue.equityRiskPrem,
        countryRiskPremium: formikValue.countryRiskPrem,
        unleveredBeta: formikValue.unleveredBeta,
        manualInput: formikValue.manualInput,
        buffer: formikValue.buffer,
      },
      terminalValue: {
        ebidtaMultiple: formikValue.ebitdaMulti,
      },
    },
    topLineAssumptions_Standard: {
      pricingInflator: formikValue.pricingInflator,
      avgLengthOfStay: formikValue.avgLenOfStay,
      guestsPerOccupiedRoom: formikValue.guestPerOccRoom,
      roomTypes: newRoomType,
      occupancyIncreaseRate: formikValue.occupancyIncRateYear,
      weightedOccupancyIncreaseRate: newWeghitedOccupancyRate,
      averageDailyRate: averageDailyRate,
      weightedAverageDailyRate: newWeightedAverageDailyRate,
      otherRevenues: { ...formikValue.otherRevenuesPerformance },
    },

    externalVisitors: formikValue.externalVisitors ,

    fnbPnl: formikValue.fnbPnl ,

    departmentalExpenses: {
      economiesOfScaleApplied: formikValue.economiesOfScale,
      benchmarkType: formikValue.benchmarkType,
      positioningLookup: formikValue.positioning,
      expenseLevel: formikValue.expenseLevel,
      assumptions: { ...formikValue.departmentalExpenses },
      undistributedExpensesCharges: {
        ...formikValue.undistributedExpenses,
        fixedCharges: {
          income: formikValue.fixedIncome,
          operationalInsurance: formikValue.operationalInsurance,
          permitsAndLicenses: formikValue.permitsAndLicenses,
          landLeaseFee: formikValue.landLeaseFee,
          otherFixedChargesLandLease: formikValue.otherFixedCharges1,
          propertyAndOtherTaxes: formikValue.propertyAndOtherTaxes,
          NEOMHotelsDevelopmentFee: formikValue.neomHotelsDevFee,
          assetManagementFee: formikValue.assetManagementFee,
          otherFixedCharges: formikValue.otherFixedCharges2,
        },
      },
    },
    operatorTermsAndStaffing: {
      managementFees: {
        baseFee: formikValue.managmentBaseFee,
        incentiveFeeGOPLessThanPercent1:
          formikValue.managmentIncentiveFee[0].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent2:
          formikValue.managmentIncentiveFee[1].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent3:
          formikValue.managmentIncentiveFee[2].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent4:
          formikValue.managmentIncentiveFee[3].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent5:
          formikValue.managmentIncentiveFee[4].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent6:
          formikValue.managmentIncentiveFee[5].ifGOPLessThanPercent,
        incentiveFeePercentOfGOP1:
          formikValue.managmentIncentiveFee[0].feePercentOfGOP,
        incentiveFeePercentOfGOP2:
          formikValue.managmentIncentiveFee[1].feePercentOfGOP,
        incentiveFeePercentOfGOP3:
          formikValue.managmentIncentiveFee[2].feePercentOfGOP,
        incentiveFeePercentOfGOP4:
          formikValue.managmentIncentiveFee[3].feePercentOfGOP,
        incentiveFeePercentOfGOP5:
          formikValue.managmentIncentiveFee[4].feePercentOfGOP,
        incentiveFeePercentOfGOP6:
          formikValue.managmentIncentiveFee[5].feePercentOfGOP,
        franchiseFee: formikValue.franchiseFee,
        includeFranchiseFee: formikValue.includeFranchiseFee == "" ? "yes" : formikValue.includeFranchiseFee,
        percentageCap: formikValue.percentageCap,
        revenueType: formikValue.revenueType,
        franchiseFeeLabel: formikValue.franchiseFeeLabel == "" ? "franchise Fee" : formikValue.franchiseFeeLabel,
      },
      otherFees: {
        reservationContribution: formikValue.reservationContribution,
        reservationContributionRevenueType: formikValue.reservationContributionRevenueType,
        reservationContributionLabel: formikValue.reservationContributionLabel,
        adminAndGeneral: formikValue.adminAndGeneral,
        adminAndGeneralRevenueType: formikValue.adminAndGeneralRevenueType,
        adminAndGeneralLabel: formikValue.adminAndGeneralLabel,

        marketingContributionType: formikValue.marketingContribution,
        marketingContribution: formikValue.marketingContributionField2,
        rewardProgram: formikValue.rewardProgram,
        percentageOfRoomBookings: formikValue.roomBookings,
        operatorOtherProgramCost: formikValue.operatorOtherProgramCost,
        technicalFeeToOperator: formikValue.technicalFeeToOperator,
        ffeReserve: formikValue.managmentFFEReserveFee,
        // ffeReserveAddBack:
        //   `${formikValue.ffeReserveAddBack}` === "1" ? true : false,
        ffeReserveAddBack:
          `${formikValue.ffeReserveAddBack}` === "1"
            ? true
            : `${formikValue.ffeReserveAddBack}` === "2"
              ? false
              : "",
        leaseFixed: formikValue.leaseFixed,
        leaseVariable: formikValue.leaseVariable,
      },
      staffing: {
        staffToKeyRatio: formikValue.staffPerKey,
        staffHousingCostPerStaff: formikValue.staffHousing,
        staffPhasing: formikValue.staffPhasing,
      },
      workingCapital: formikValue.workingCapital,
    },
    brandedResidencesGeneral: {
      // isOnOff: `${formikValue.brandedResidence}` === "1" ? true : false,
      isOnOff:
        `${formikValue.brandedResidence}` === "1"
          ? true
          : `${formikValue.brandedResidence}` === "2"
            ? false
            : "",
      calcTotalGfaBrandedRes: parseFloat(formikValue.calcTotalGfaBrandedRes),
      calcTotalSellableGla: formikValue.calcTotalSellableGla,
      calcTotalNumberOfKeys: formikValue.calcTotalNumberOfKeys,
      calcTotalSalesPrice: formikValue.calcTotalSalesPrice,
      calcTotalBaseCost: formikValue.calcTotalBaseCost,
      unitTypes: unitType,
      GFAEfficiency: formikValue.Efficiency,
      annualSalesAbsorption: formikValue.annualSalesAbsorption,
      feesAndContributions: {
        operatorContributions: formikValue.opreatorContributions,
        adminExpenses: formikValue.adminExpenses,
        marketingSellingCost: formikValue.marketingSellingCost,
        residentialSaleFee_USD: formikValue.residentialSaleFee,
        residentialConsultancyFee_USD: formikValue.residentialConsultancyFee,
        adminChargesToOwner: formikValue.adminCharges,
        shareOfResidenceOwner: formikValue.shareOfResidence,
        // minimumResidenceOwnerReturn:
        //   `${formikValue.minimumResidenceRadio}` === "1" ? true : false,
        // minimumResidenceOwnerReturnValue: formikValue.minimumResidence,
        // finalAssetSaleValueIncrease: formikValue.finalAssetSale,
        // ffe_ose: `${formikValue.ffeAndOse}` === "1" ? true : false,
        // softCosts: `${formikValue.softCosts}` === "1" ? true : false,
        // preOpeningTrainingMarketing:
        //   `${formikValue.preOpTraining}` === "1" ? true : false,

        minimumResidenceOwnerReturn:
          `${formikValue.minimumResidenceRadio}` === "1"
            ? true
            : `${formikValue.minimumResidenceRadio}` === "2"
              ? false
              : "",
        minimumResidenceOwnerReturnValue: formikValue.minimumResidence,
        finalAssetSaleValueIncrease: formikValue.finalAssetSale,
        ffe_ose:
          `${formikValue.ffeAndOse}` === "1"
            ? true
            : `${formikValue.ffeAndOse}` === "2"
              ? false
              : "",
        softCosts:
          `${formikValue.softCosts}` === "1"
            ? true
            : `${formikValue.softCosts}` === "2"
              ? false
              : "",
        preOpeningTrainingMarketing:
          `${formikValue.preOpTraining}` === "1"
            ? true
            : `${formikValue.preOpTraining}` === "2"
              ? false
              : "",
      },
    },
    brandedResidencesPerformance: {
      includedInRentalPool: includedRentalPool,
      avgDaysOwnerExclusiveUse: avgDaysOwner,
      stabilizedOccupancyPerUnit: stabilizedOcc,
      adrPerUnit: adrPerUnit,
      otherRevenues: brandedResOtherRev,
      additionalExpenses: additionalExpenses,
      managementFees: {
        baseFee: formikValue.brandedBaseFee,
        incentiveFee: formikValue.brandedIncentiveFee,
        franchiseFee: formikValue.brandedFranchiseFee,
      },
    },
  };
};

//PACKAGE
export const assetListPackageFormFormikValues = (formikValue, oid) => {
  let newPackageType = [];
  let newRoomRate = [];
  let newFoodAndBeverage = [];
  let newOther = [];
  let newPackageTypeAcc = [];
  let unitType = [];
  let includedRentalPool = [];
  let avgDaysOwner = [];
  let stabilizedOcc = [];
  let adrPerUnit = [];
  let additionalExpenses = [];
  let newWeghitedOccupancyRate = [];
  let brandedResOtherRev = {
    incFoodAndBeverageOutlets: formikValue.brandedResidencesFields.otherRev[0],
    incBanquet: formikValue.brandedResidencesFields.otherRev[1],
    incRecreation: formikValue.brandedResidencesFields.otherRev[2],
    incSpa: formikValue.brandedResidencesFields.otherRev[3],
    incOtherProfitDepartments: formikValue.brandedResidencesFields.otherRev[4],
    incRetail: formikValue.brandedResidencesFields.otherRev[5],
    incMiscellaneousIncome: formikValue.brandedResidencesFields.otherRev[6],
    incRental: formikValue.brandedResidencesFields.otherRev[7],
    incOther1: formikValue.brandedResidencesFields.otherRev[8],
    incOther2: formikValue.brandedResidencesFields.otherRev[9],
  };

  formikValue.packageType.forEach((formValue, index) => {
    newPackageType.push({
      packageTypeLookup: formValue.type,
      numberOfKeys: formValue.num,
      gpor: formValue.gpor,
      occupancyRate: formValue.occRate,
    });
  });
  if (!!formikValue?.performaceAssitList2) {
    formikValue.performaceAssitList2.roomRate.forEach((formValue, index) => {
      newRoomRate.push({
        packageTypeLookup: formValue.type,
        rates: formValue.year,
      });
    });
    formikValue.performaceAssitList2.foodAndBeverage.forEach(
      (formValue, index) => {
        newFoodAndBeverage.push({
          packageTypeLookup: formValue.type,
          rates: formValue.year,
        });
      }
    );
    formikValue.performaceAssitList2.other.forEach((formValue, index) => {
      newOther.push({
        packageTypeLookup: formValue.type,
        rates: formValue.year,
      });
    });
    formikValue.performaceAssitList2.packageRate.forEach((formValue, index) => {
      newPackageTypeAcc.push({
        packageTypeLookup: formValue.type,
        rates: formValue.year,
      });
    });
  }

  formikValue.unitMix.forEach((formValue, index) => {
    unitType.push({
      unitTypeLookup: formValue.type,
      noOfUnits: formValue.numKeys,
      totalSellable: formValue.totalSellable,
      baseCostPerKey_SAR: formValue.baseCost,
      salesPricePerSqm: formValue.salesPrice,
    });
  });
  if (formikValue.brandedResidencesFields.rentalPool.length > 0) {
    formikValue.unitMix.forEach((formValue, index) => {
      includedRentalPool.push({
        unitTypeLookup: formValue.type,
        value: formikValue.brandedResidencesFields.rentalPool[index].typeField || 0,
      });
      avgDaysOwner.push({
        unitTypeLookup: formValue.type,
        value:
          formikValue.brandedResidencesFields.avgNumberOfDaysPerAnnum[index]
            .typeField || 0,
      });
      stabilizedOcc.push({
        unitTypeLookup: formValue.type,
        value:
          formikValue.brandedResidencesFields.stabilizedOccupancy[index]
            .typeField || 0,
      });
      adrPerUnit.push({
        unitTypeLookup: formValue.type,
        value: formikValue.brandedResidencesFields.adrPerUnit[index].typeField || 0,
      });
    });
  }

  if (formikValue.expenses1 !== "" && formikValue.expenses1 !== undefined) {
    additionalExpenses.push(formikValue.expenses1);
  } else {
    additionalExpenses.push(0);
  }
  if (formikValue.expenses2 !== "" && formikValue.expenses2 !== undefined) {
    additionalExpenses.push(formikValue.expenses2);
  } else {
    additionalExpenses.push(0);
  }
  if (formikValue.expenses3 !== "" && formikValue.expenses3 !== undefined) {
    additionalExpenses.push(formikValue.expenses3);
  } else {
    additionalExpenses.push(0);
  }
  if (formikValue.expenses4 !== "" && formikValue.expenses4 !== undefined) {
    additionalExpenses.push(formikValue.expenses4);
  } else {
    additionalExpenses.push(0);
  }
  if (formikValue.expenses5 !== "" && formikValue.expenses5 !== undefined) {
    additionalExpenses.push(formikValue.expenses5);
  } else {
    additionalExpenses.push(0);
  }

  if (formikValue.calc1 !== "" && formikValue.calc1 !== undefined) {
    newWeghitedOccupancyRate.push(parseFloat(formikValue.calc1));
  } else {
    newWeghitedOccupancyRate.push(0);
  }
  if (formikValue.calc2 !== "" && formikValue.calc2 !== undefined) {
    newWeghitedOccupancyRate.push(parseFloat(formikValue.calc2));
  } else {
    newWeghitedOccupancyRate.push(0);
  }
  if (formikValue.calc3 !== "" && formikValue.calc3 !== undefined) {
    newWeghitedOccupancyRate.push(parseFloat(formikValue.calc3));
  } else {
    newWeghitedOccupancyRate.push(0);
  }
  if (formikValue.calc4 !== "" && formikValue.calc4 !== undefined) {
    newWeghitedOccupancyRate.push(parseFloat(formikValue.calc4));
  } else {
    newWeghitedOccupancyRate.push(0);
  }
  if (formikValue.calc5 !== "" && formikValue.calc5 !== undefined) {
    newWeghitedOccupancyRate.push(parseFloat(formikValue.calc5));
  } else {
    newWeghitedOccupancyRate.push(0);
  }
  if (formikValue.calc6 !== "" && formikValue.calc6 !== undefined) {
    newWeghitedOccupancyRate.push(parseFloat(formikValue.calc6));
  } else {
    newWeghitedOccupancyRate.push(0);
  }
  let newWeightedFoodAndBeverageRate = [];
  if (formikValue.fAndB1 !== "" && formikValue.fAndB1 !== undefined) {
    newWeightedFoodAndBeverageRate.push(parseFloat(formikValue.fAndB1));
  } else {
    newWeightedFoodAndBeverageRate.push(0);
  }
  if (formikValue.fAndB2 !== "" && formikValue.fAndB2 !== undefined) {
    newWeightedFoodAndBeverageRate.push(parseFloat(formikValue.fAndB2));
  } else {
    newWeightedFoodAndBeverageRate.push(0);
  }
  if (formikValue.fAndB3 !== "" && formikValue.fAndB3 !== undefined) {
    newWeightedFoodAndBeverageRate.push(parseFloat(formikValue.fAndB3));
  } else {
    newWeightedFoodAndBeverageRate.push(0);
  }
  if (formikValue.fAndB4 !== "" && formikValue.fAndB4 !== undefined) {
    newWeightedFoodAndBeverageRate.push(parseFloat(formikValue.fAndB4));
  } else {
    newWeightedFoodAndBeverageRate.push(0);
  }
  if (formikValue.fAndB5 !== "" && formikValue.fAndB5 !== undefined) {
    newWeightedFoodAndBeverageRate.push(parseFloat(formikValue.fAndB5));
  } else {
    newWeightedFoodAndBeverageRate.push(0);
  }
  if (formikValue.fAndB6 !== "" && formikValue.fAndB6 !== undefined) {
    newWeightedFoodAndBeverageRate.push(parseFloat(formikValue.fAndB6));
  } else {
    newWeightedFoodAndBeverageRate.push(0);
  }
  let newOtherCalc = [];
  if (formikValue.otherCalc1 !== "" && formikValue.otherCalc1 !== undefined) {
    newOtherCalc.push(parseFloat(formikValue.otherCalc1));
  } else {
    newOtherCalc.push(0);
  }
  if (formikValue.otherCalc2 !== "" && formikValue.otherCalc2 !== undefined) {
    newOtherCalc.push(parseFloat(formikValue.otherCalc2));
  } else {
    newOtherCalc.push(0);
  }
  if (formikValue.otherCalc3 !== "" && formikValue.otherCalc3 !== undefined) {
    newOtherCalc.push(parseFloat(formikValue.otherCalc3));
  } else {
    newOtherCalc.push(0);
  }
  if (formikValue.otherCalc4 !== "" && formikValue.otherCalc4 !== undefined) {
    newOtherCalc.push(parseFloat(formikValue.otherCalc4));
  } else {
    newOtherCalc.push(0);
  }
  if (formikValue.otherCalc5 !== "" && formikValue.otherCalc5 !== undefined) {
    newOtherCalc.push(parseFloat(formikValue.otherCalc5));
  } else {
    newOtherCalc.push(0);
  }
  if (formikValue.otherCalc6 !== "" && formikValue.otherCalc6 !== undefined) {
    newOtherCalc.push(parseFloat(formikValue.otherCalc6));
  } else {
    newOtherCalc.push(0);
  }
  let newRoomRateCalc = [];
  if (
    formikValue.roomRateCalc1 !== "" &&
    formikValue.roomRateCalc1 !== undefined
  ) {
    newRoomRateCalc.push(parseFloat(formikValue.roomRateCalc1));
  } else {
    newRoomRateCalc.push(0);
  }
  if (
    formikValue.roomRateCalc2 !== "" &&
    formikValue.roomRateCalc2 !== undefined
  ) {
    newRoomRateCalc.push(parseFloat(formikValue.roomRateCalc2));
  } else {
    newRoomRateCalc.push(0);
  }
  if (
    formikValue.roomRateCalc3 !== "" &&
    formikValue.roomRateCalc3 !== undefined
  ) {
    newRoomRateCalc.push(parseFloat(formikValue.roomRateCalc3));
  } else {
    newRoomRateCalc.push(0);
  }
  if (
    formikValue.roomRateCalc4 !== "" &&
    formikValue.roomRateCalc4 !== undefined
  ) {
    newRoomRateCalc.push(parseFloat(formikValue.roomRateCalc4));
  } else {
    newRoomRateCalc.push(0);
  }
  if (
    formikValue.roomRateCalc5 !== "" &&
    formikValue.roomRateCalc5 !== undefined
  ) {
    newRoomRateCalc.push(parseFloat(formikValue.roomRateCalc5));
  } else {
    newRoomRateCalc.push(0);
  }
  if (
    formikValue.roomRateCalc6 !== "" &&
    formikValue.roomRateCalc6 !== undefined
  ) {
    newRoomRateCalc.push(parseFloat(formikValue.roomRateCalc6));
  } else {
    newRoomRateCalc.push(0);
  }
  let newPackageRateCalc = [];
  if (
    formikValue.packageRateCalc1 !== "" &&
    formikValue.packageRateCalc1 !== undefined
  ) {
    newPackageRateCalc.push(parseFloat(formikValue.packageRateCalc1));
  } else {
    newPackageRateCalc.push(0);
  }
  if (
    formikValue.packageRateCalc2 !== "" &&
    formikValue.packageRateCalc2 !== undefined
  ) {
    newPackageRateCalc.push(parseFloat(formikValue.packageRateCalc2));
  } else {
    newPackageRateCalc.push(0);
  }
  if (
    formikValue.packageRateCalc3 !== "" &&
    formikValue.packageRateCalc3 !== undefined
  ) {
    newPackageRateCalc.push(parseFloat(formikValue.packageRateCalc3));
  } else {
    newPackageRateCalc.push(0);
  }
  if (
    formikValue.packageRateCalc4 !== "" &&
    formikValue.packageRateCalc4 !== undefined
  ) {
    newPackageRateCalc.push(parseFloat(formikValue.packageRateCalc4));
  } else {
    newPackageRateCalc.push(0);
  }
  if (
    formikValue.packageRateCalc5 !== "" &&
    formikValue.packageRateCalc5 !== undefined
  ) {
    newPackageRateCalc.push(parseFloat(formikValue.packageRateCalc5));
  } else {
    newPackageRateCalc.push(0);
  }
  if (
    formikValue.packageRateCalc6 !== "" &&
    formikValue.packageRateCalc6 !== undefined
  ) {
    newPackageRateCalc.push(parseFloat(formikValue.packageRateCalc6));
  } else {
    newPackageRateCalc.push(0);
  }

  let newWeightedADRCalc = [];
  if (
    formikValue.weightedADRCalc1 !== "" &&
    formikValue.weightedADRCalc1 !== undefined
  ) {
    newWeightedADRCalc.push(parseFloat(formikValue.weightedADRCalc1));
  } else {
    newWeightedADRCalc.push(0);
  }
  if (
    formikValue.weightedADRCalc2 !== "" &&
    formikValue.weightedADRCalc2 !== undefined
  ) {
    newWeightedADRCalc.push(parseFloat(formikValue.weightedADRCalc2));
  } else {
    newWeightedADRCalc.push(0);
  }
  if (
    formikValue.weightedADRCalc3 !== "" &&
    formikValue.weightedADRCalc3 !== undefined
  ) {
    newWeightedADRCalc.push(parseFloat(formikValue.weightedADRCalc3));
  } else {
    newWeightedADRCalc.push(0);
  }
  if (
    formikValue.weightedADRCalc4 !== "" &&
    formikValue.weightedADRCalc4 !== undefined
  ) {
    newWeightedADRCalc.push(parseFloat(formikValue.weightedADRCalc4));
  } else {
    newWeightedADRCalc.push(0);
  }
  if (
    formikValue.weightedADRCalc5 !== "" &&
    formikValue.weightedADRCalc5 !== undefined
  ) {
    newWeightedADRCalc.push(parseFloat(formikValue.weightedADRCalc5));
  } else {
    newWeightedADRCalc.push(0);
  }
  if (
    formikValue.weightedADRCalc6 !== "" &&
    formikValue.weightedADRCalc6 !== undefined
  ) {
    newWeightedADRCalc.push(parseFloat(formikValue.weightedADRCalc6));
  } else {
    newWeightedADRCalc.push(0);
  }

  let newTotalPackageRate_SAR = [];
  if (
    formikValue.totalPackageRateSARCalc1 !== "" &&
    formikValue.totalPackageRateSARCalc1 !== undefined
  ) {
    newTotalPackageRate_SAR.push(
      parseFloat(formikValue.totalPackageRateSARCalc1)
    );
  } else {
    newTotalPackageRate_SAR.push(0);
  }
  if (
    formikValue.totalPackageRateSARCalc2 !== "" &&
    formikValue.totalPackageRateSARCalc2 !== undefined
  ) {
    newTotalPackageRate_SAR.push(
      parseFloat(formikValue.totalPackageRateSARCalc2)
    );
  } else {
    newTotalPackageRate_SAR.push(0);
  }
  if (
    formikValue.totalPackageRateSARCalc3 !== "" &&
    formikValue.totalPackageRateSARCalc3 !== undefined
  ) {
    newTotalPackageRate_SAR.push(
      parseFloat(formikValue.totalPackageRateSARCalc3)
    );
  } else {
    newTotalPackageRate_SAR.push(0);
  }
  if (
    formikValue.totalPackageRateSARCalc4 !== "" &&
    formikValue.totalPackageRateSARCalc4 !== undefined
  ) {
    newTotalPackageRate_SAR.push(
      parseFloat(formikValue.totalPackageRateSARCalc4)
    );
  } else {
    newTotalPackageRate_SAR.push(0);
  }
  if (
    formikValue.totalPackageRateSARCalc5 !== "" &&
    formikValue.totalPackageRateSARCalc5 !== undefined
  ) {
    newTotalPackageRate_SAR.push(
      parseFloat(formikValue.totalPackageRateSARCalc5)
    );
  } else {
    newTotalPackageRate_SAR.push(0);
  }
  if (
    formikValue.totalPackageRateSARCalc6 !== "" &&
    formikValue.totalPackageRateSARCalc6 !== undefined
  ) {
    newTotalPackageRate_SAR.push(
      parseFloat(formikValue.totalPackageRateSARCalc6)
    );
  } else {
    newTotalPackageRate_SAR.push(0);
  }

  let newTotalPackageRate_USD = [];
  if (
    formikValue.totalPackageRateUSDCalc1 !== "" &&
    formikValue.totalPackageRateUSDCalc1 !== undefined
  ) {
    newTotalPackageRate_USD.push(
      parseFloat(formikValue.totalPackageRateUSDCalc1)
    );
  } else {
    newTotalPackageRate_USD.push(0);
  }
  if (
    formikValue.totalPackageRateUSDCalc2 !== "" &&
    formikValue.totalPackageRateUSDCalc2 !== undefined
  ) {
    newTotalPackageRate_USD.push(
      parseFloat(formikValue.totalPackageRateUSDCalc2)
    );
  } else {
    newTotalPackageRate_USD.push(0);
  }
  if (
    formikValue.totalPackageRateUSDCalc3 !== "" &&
    formikValue.totalPackageRateUSDCalc3 !== undefined
  ) {
    newTotalPackageRate_USD.push(
      parseFloat(formikValue.totalPackageRateUSDCalc3)
    );
  } else {
    newTotalPackageRate_USD.push(0);
  }
  if (
    formikValue.totalPackageRateUSDCalc4 !== "" &&
    formikValue.totalPackageRateUSDCalc4 !== undefined
  ) {
    newTotalPackageRate_USD.push(
      parseFloat(formikValue.totalPackageRateUSDCalc4)
    );
  } else {
    newTotalPackageRate_USD.push(0);
  }
  if (
    formikValue.totalPackageRateUSDCalc5 !== "" &&
    formikValue.totalPackageRateUSDCalc5 !== undefined
  ) {
    newTotalPackageRate_USD.push(
      parseFloat(formikValue.totalPackageRateUSDCalc5)
    );
  } else {
    newTotalPackageRate_USD.push(0);
  }
  if (
    formikValue.totalPackageRateUSDCalc6 !== "" &&
    formikValue.totalPackageRateUSDCalc6 !== undefined
  ) {
    newTotalPackageRate_USD.push(
      parseFloat(formikValue.totalPackageRateUSDCalc6)
    );
  } else {
    newTotalPackageRate_USD.push(0);
  }

  let newTotalPackageRate7Days_SAR = [];
  if (
    formikValue.totalPackageRate7DaysSARCalc1 !== "" &&
    formikValue.totalPackageRate7DaysSARCalc1 !== undefined
  ) {
    newTotalPackageRate7Days_SAR.push(
      parseFloat(formikValue.totalPackageRate7DaysSARCalc1)
    );
  } else {
    newTotalPackageRate7Days_SAR.push(0);
  }
  if (
    formikValue.totalPackageRate7DaysSARCalc2 !== "" &&
    formikValue.totalPackageRate7DaysSARCalc2 !== undefined
  ) {
    newTotalPackageRate7Days_SAR.push(
      parseFloat(formikValue.totalPackageRate7DaysSARCalc2)
    );
  } else {
    newTotalPackageRate7Days_SAR.push(0);
  }
  if (
    formikValue.totalPackageRate7DaysSARCalc3 !== "" &&
    formikValue.totalPackageRate7DaysSARCalc3 !== undefined
  ) {
    newTotalPackageRate7Days_SAR.push(
      parseFloat(formikValue.totalPackageRate7DaysSARCalc3)
    );
  } else {
    newTotalPackageRate7Days_SAR.push(0);
  }
  if (
    formikValue.totalPackageRate7DaysSARCalc4 !== "" &&
    formikValue.totalPackageRate7DaysSARCalc4 !== undefined
  ) {
    newTotalPackageRate7Days_SAR.push(
      parseFloat(formikValue.totalPackageRate7DaysSARCalc4)
    );
  } else {
    newTotalPackageRate7Days_SAR.push(0);
  }
  if (
    formikValue.totalPackageRate7DaysSARCalc5 !== "" &&
    formikValue.totalPackageRate7DaysSARCalc5 !== undefined
  ) {
    newTotalPackageRate7Days_SAR.push(
      parseFloat(formikValue.totalPackageRate7DaysSARCalc5)
    );
  } else {
    newTotalPackageRate7Days_SAR.push(0);
  }
  if (
    formikValue.totalPackageRate7DaysSARCalc6 !== "" &&
    formikValue.totalPackageRate7DaysSARCalc6 !== undefined
  ) {
    newTotalPackageRate7Days_SAR.push(
      parseFloat(formikValue.totalPackageRate7DaysSARCalc6)
    );
  } else {
    newTotalPackageRate7Days_SAR.push(0);
  }

  let newTotalPackageRate7Days_USD = [];
  if (
    formikValue.totalPackageRate7DaysUSDCalc1 !== "" &&
    formikValue.totalPackageRate7DaysUSDCalc1 !== undefined
  ) {
    newTotalPackageRate7Days_USD.push(
      parseFloat(formikValue.totalPackageRate7DaysUSDCalc1)
    );
  } else {
    newTotalPackageRate7Days_USD.push(0);
  }
  if (
    formikValue.totalPackageRate7DaysUSDCalc2 !== "" &&
    formikValue.totalPackageRate7DaysUSDCalc2 !== undefined
  ) {
    newTotalPackageRate7Days_USD.push(
      parseFloat(formikValue.totalPackageRate7DaysUSDCalc2)
    );
  } else {
    newTotalPackageRate7Days_USD.push(0);
  }
  if (
    formikValue.totalPackageRate7DaysUSDCalc3 !== "" &&
    formikValue.totalPackageRate7DaysUSDCalc3 !== undefined
  ) {
    newTotalPackageRate7Days_USD.push(
      parseFloat(formikValue.totalPackageRate7DaysUSDCalc3)
    );
  } else {
    newTotalPackageRate7Days_USD.push(0);
  }
  if (
    formikValue.totalPackageRate7DaysUSDCalc4 !== "" &&
    formikValue.totalPackageRate7DaysUSDCalc4 !== undefined
  ) {
    newTotalPackageRate7Days_USD.push(
      parseFloat(formikValue.totalPackageRate7DaysUSDCalc4)
    );
  } else {
    newTotalPackageRate7Days_USD.push(0);
  }
  if (
    formikValue.totalPackageRate7DaysUSDCalc5 !== "" &&
    formikValue.totalPackageRate7DaysUSDCalc5 !== undefined
  ) {
    newTotalPackageRate7Days_USD.push(
      parseFloat(formikValue.totalPackageRate7DaysUSDCalc5)
    );
  } else {
    newTotalPackageRate7Days_USD.push(0);
  }
  if (
    formikValue.totalPackageRate7DaysUSDCalc6 !== "" &&
    formikValue.totalPackageRate7DaysUSDCalc6 !== undefined
  ) {
    newTotalPackageRate7Days_USD.push(
      parseFloat(formikValue.totalPackageRate7DaysUSDCalc6)
    );
  } else {
    newTotalPackageRate7Days_USD.push(0);
  }

  formikValue.developmentAbsorptionLand = convertEmpStringsArrToZero(
    formikValue.developmentAbsorptionLand
  );

  formikValue.occupancyIncRateYear = convertEmpStringsArrToZero(
    formikValue.occupancyIncRateYear
  );

  formikValue.managmentBaseFee = convertEmpStringsArrToZero(
    formikValue.managmentBaseFee
  );

  formikValue.managmentFFEReserveFee = convertEmpStringsArrToZero(
    formikValue.managmentFFEReserveFee
  );

  formikValue.annualSalesAbsorption = convertEmpStringsArrToZero(
    formikValue.annualSalesAbsorption
  );

  formikValue.brandedBaseFee = convertEmpStringsArrToZero(
    formikValue.brandedBaseFee
  );

  formikValue.brandedIncentiveFee = convertEmpStringsArrToZero(
    formikValue.brandedIncentiveFee
  );

  formikValue.brandedFranchiseFee = convertEmpStringsArrToZero(
    formikValue.brandedFranchiseFee
  );

  return {
    projectName: formikValue.projectName,
    assetType: "Package",
    projectNameValue: formikValue.projectNameValue,
    comments: formikValue.addComment,
    assignedTo: formikValue.assignTo,
    assignedBy: oid,
    approveAssetInfo: formikValue.approveAssetInfo,
    regionLookup: formikValue.regionLookup,
    operatorLookup: formikValue.operatorLookup,
    brandLookup: formikValue.brandLookup,
    positioningLookup: formikValue.positioningLookup,
    managementContractLookup: formikValue.managementContractLookup,
    projectStatusLookup: formikValue.projectStatusLookup,
    projectStageLookup: formikValue.projectStageLookup,
    plotSize: formikValue.plotSize,
    grossFloorArea: formikValue.grossFloorArea,
    parkingArea: formikValue.parkingArea,
    assetBaseYear: formikValue.assetBaseYear
      ? formikValue.assetBaseYear.getFullYear()
      : "",
    developmentTimeStartYear: formikValue.startDateDevTime
      ? formikValue.startDateDevTime.getFullYear()
      : "",
    developmentTimeStartMonth: formikValue.startDateDevTime
      ? formikValue.startDateDevTime.getMonth()
      : "",
    developmentTimeDurationMonths:
      formikValue.developmentTimeDurationMonths || 0,
    developmentTimeStartDate: formikValue.startDateDevTime
      ? formatISO(formikValue.startDateDevTime)
      : "",
    developmentTimeCompletionDate:
      formikValue.developmentTimeCompletionDate &&
        formikValue.developmentTimeCompletionDate !== "-"
        ? formatISO(
          parse(
            formikValue.developmentTimeCompletionDate,
            "MM/yyyy",
            new Date()
          )
        )
        : "", //"02-11-2014", //
    operationStartYear: formikValue.startDateOpTime
      ? formikValue.startDateOpTime.getFullYear()
      : "",
    operationStartMonth: formikValue.startDateOpTime
      ? formikValue.startDateOpTime.getMonth()
      : "",
    operationStartDate: formikValue.startDateOpTime
      ? formatISO(formikValue.startDateOpTime)
      : "",
    operationTimeDurationMonths: formikValue.operationTimeDurationMonths || 0,
    operationTimeEndDate:
      formikValue.operationTimeEndDate &&
        formikValue.operationTimeEndDate !== "-"
        ? formatISO(
          parse(formikValue.operationTimeEndDate, "MM/yyyy", new Date())
        )
        : "", //"02-11-2014",
    numberOfKeys: formikValue.numberOfKeys,
    developmentCostValues: {
      general: {
        developmentCostPerKey_SAR:
          formikValue.devCostPerKey === "" ? 0 : formikValue.devCostPerKey,
        landCostFreehold_SAR:
          formikValue.landCost === "" ? 0 : formikValue.landCost,
        developmentAbsorptionLand: formikValue.developmentAbsorptionLand,
      },
      infrastructure: {
        cost_SAR: formikValue.infraCost === "" ? 0 : formikValue.infraCost,
        depreciation: formikValue.infraDepreciation,
        developmentStartMonth:
          formikValue.infraDevStartMonth === ""
            ? 0
            : parseFloat(formikValue.infraDevStartMonth),
        developmentDuration: formikValue.infraDevDuration,
      },
      parking: {
        cost_SAR: formikValue.parkingCost === "" ? 0 : formikValue.parkingCost,
        depreciation: formikValue.parkingDepreciation,
      },
      breakdown: {
        construction: {
          cost:
            formikValue.bDownConsCost === "" ? 0 : formikValue.bDownConsCost,
          depreciation: formikValue.bDownDepConsCost,
        },
        FFE_OSE: {
          cost: formikValue.bDownFfeOse === "" ? 0 : formikValue.bDownFfeOse,
          depreciation: formikValue.bDownDepFfeOse,
          developmentDuration: formikValue.ffeDevelopmentDuration,
        },
        softCosts: {
          cost:
            formikValue.bDownSoftCost === "" ? 0 : formikValue.bDownSoftCost,
          depreciation: formikValue.bDownDepSoftCost,
        },
        preOpening: {
          cost:
            formikValue.bDownPreOpenTM === "" ? 0 : formikValue.bDownPreOpenTM,
          depreciation: formikValue.bDownDepPreOpenTM,
          developmentDuration: formikValue.preOpeningDevelopmentDuration,
        },
        TSA: {
          cost: formikValue.bDownTsaFee === "" ? 0 : formikValue.bDownTsaFee,
          depreciation: formikValue.bDownDepTsaFee,
        },
        NEOMDevelopmentFee: {
          cost:
            formikValue.bDownNeomDevFee === ""
              ? 0
              : formikValue.bDownNeomDevFee,
          depreciation: formikValue.bDownDepNeomDevFee,
        },
        contingency: {
          cost:
            formikValue.bDownContCost === "" ? 0 : formikValue.bDownContCost,
          depreciation: formikValue.bDownDepContCost,
        },
        initialWorkingCapital:
          formikValue.bDownIniWorkCapital === ""
            ? 0
            : formikValue.bDownIniWorkCapital,
        percentageOfBaseCost:
          formikValue.baseCost === "" ? 0 : formikValue.baseCost,
      },
      totalDevelopmentCostPerKey_SAR:
        formikValue.calcTotalDevelopmentCostPerKey == undefined
          ? 0
          : formikValue.calcTotalDevelopmentCostPerKey,
      refurbishmentYear:
        formikValue.refurbishmentYear != "" &&
          formikValue.refurbishmentYear != null
          ? formikValue.refurbishmentYear.getFullYear()
          : -1,
      refurbishmentCost:
        formikValue.refurbishmentCost === ""
          ? -1
          : formikValue.refurbishmentCost,
    },
    financingAssumptions: {
      general: {
        taxRate: formikValue.taxRate,
        openingCashBalance: formikValue.openCashBalance,
        equity: formikValue.equity,
      },
      debt: {
        annualInterestRate: formikValue.annualInterestRate,
        debtTerm: formikValue.debtTerm,
        gracePeriod: formikValue.gracePeriod,
        debtProcessingFees: formikValue.debtProcFees,
        securityFees: formikValue.securityFees,
        commitmentFees: formikValue.commitmentFees,
      },
      sale: {
        sellingCosts: formikValue.sellingCosts,
        taxOnSaleValue: formikValue.TaxSaleVal,
      },
      risk: {
        // shouldUseCalculations:
        //   `${formikValue.useCalculation}` === "1" ? true : false,
        shouldUseCalculations:
          `${formikValue.useCalculation}` === "1"
            ? true
            : `${formikValue.useCalculation}` === "2"
              ? false
              : "",
        riskFreeRate: formikValue.riskFreeRate,
        equityRiskPremium: formikValue.equityRiskPrem,
        countryRiskPremium: formikValue.countryRiskPrem,
        unleveredBeta: formikValue.unleveredBeta,
        manualInput: formikValue.manualInput,
        buffer: formikValue.buffer,
      },
      terminalValue: {
        ebidtaMultiple: formikValue.ebitdaMulti,
      },
    },
    topLineAssumptions_Package: {
      pricingInflator: formikValue.pricingInflator,
      avgLengthOfStay: formikValue.avgLenOfStay,
      guestsPerOccupiedRoom: formikValue.guestPerOccRoom,
      packageTypes: newPackageType,
      occupancyIncreaseRate: formikValue.occupancyIncRateYear,
      weightedOccupancyIncreaseRate: newWeghitedOccupancyRate,
      roomRate: newRoomRate,
      weightedRoomRate: newRoomRateCalc, //STEP 5
      foodAndBeverage: newFoodAndBeverage,
      weightedFoodAndBeverageRate: newWeightedFoodAndBeverageRate, //STEP 5
      otherRates: newOther,
      weightedOtherRates: newOtherCalc, //STEP 5
      packageRate: newPackageTypeAcc,
      weightedPackageRate: newPackageRateCalc, //STEP 5
      totalPackageRate_SAR: newTotalPackageRate_SAR, //STEP 5
      totalPackageRate_USD: newTotalPackageRate_USD, //STEP 5
      totalPackageRateSevenDays_SAR: newTotalPackageRate7Days_SAR, //STEP 5
      totalPackageRateSevenDays_USD: newTotalPackageRate7Days_USD, //STEP 5
      weightedADR: newWeightedADRCalc, //STEP 5
      weightedOccupancy: newWeghitedOccupancyRate, //STEP 5

      otherRevenues: {
        ...formikValue.otherRevenueBreakdown,
      },
    },

    externalVisitors:formikValue.externalVisitors, //STEP 6

    departmentalExpenses: {
      economiesOfScaleApplied: formikValue.economiesOfScale,
      benchmarkType: formikValue.benchmarkType,
      positioningLookup: formikValue.positioning,
      expenseLevel: formikValue.expenseLevel,
      assumptions: { ...formikValue.departmentalExpenses },
      undistributedExpensesCharges: {
        ...formikValue.undistributedExpenses,
        fixedCharges: {
          income: formikValue.fixedIncome,
          operationalInsurance: formikValue.operationalInsurance,
          permitsAndLicenses: formikValue.permitsAndLicenses,
          landLeaseFee: formikValue.landLeaseFee,
          otherFixedChargesLandLease: formikValue.otherFixedCharges1,
          propertyAndOtherTaxes: formikValue.propertyAndOtherTaxes,
          NEOMHotelsDevelopmentFee: formikValue.neomHotelsDevFee,
          assetManagementFee: formikValue.assetManagementFee,
          otherFixedCharges: formikValue.otherFixedCharges2,
        },
      },
    },
    operatorTermsAndStaffing: {
      managementFees: {
        baseFee: formikValue.managmentBaseFee,
        incentiveFeeGOPLessThanPercent1:
          formikValue.managmentIncentiveFee[0].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent2:
          formikValue.managmentIncentiveFee[1].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent3:
          formikValue.managmentIncentiveFee[2].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent4:
          formikValue.managmentIncentiveFee[3].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent5:
          formikValue.managmentIncentiveFee[4].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent6:
          formikValue.managmentIncentiveFee[5].ifGOPLessThanPercent,
        incentiveFeePercentOfGOP1:
          formikValue.managmentIncentiveFee[0].feePercentOfGOP,
        incentiveFeePercentOfGOP2:
          formikValue.managmentIncentiveFee[1].feePercentOfGOP,
        incentiveFeePercentOfGOP3:
          formikValue.managmentIncentiveFee[2].feePercentOfGOP,
        incentiveFeePercentOfGOP4:
          formikValue.managmentIncentiveFee[3].feePercentOfGOP,
        incentiveFeePercentOfGOP5:
          formikValue.managmentIncentiveFee[4].feePercentOfGOP,
        incentiveFeePercentOfGOP6:
          formikValue.managmentIncentiveFee[5].feePercentOfGOP,
          franchiseFee: formikValue.franchiseFee,
          includeFranchiseFee: formikValue.includeFranchiseFee == "" ? "yes" : formikValue.includeFranchiseFee,
          percentageCap: formikValue.percentageCap,
          revenueType: formikValue.revenueType,
          franchiseFeeLabel: formikValue.franchiseFeeLabel == "" ? "franchise Fee" : formikValue.franchiseFeeLabel,
      },
      otherFees: {
        reservationContribution: formikValue.reservationContribution,
        reservationContributionRevenueType: formikValue.reservationContributionRevenueType,
        reservationContributionLabel: formikValue.reservationContributionLabel,
        adminAndGeneral: formikValue.adminAndGeneral,
        adminAndGeneralRevenueType: formikValue.adminAndGeneralRevenueType,
        adminAndGeneralLabel: formikValue.adminAndGeneralLabel,
        marketingContributionType: formikValue.marketingContribution,
        marketingContribution: formikValue.marketingContributionField2,
        rewardProgram: formikValue.rewardProgram,
        percentageOfRoomBookings: formikValue.roomBookings,
        operatorOtherProgramCost: formikValue.operatorOtherProgramCost,
        technicalFeeToOperator: formikValue.technicalFeeToOperator,
        ffeReserve: formikValue.managmentFFEReserveFee,
        // ffeReserveAddBack:
        //   `${formikValue.ffeReserveAddBack}` === "1" ? true : false,
        ffeReserveAddBack:
          `${formikValue.ffeReserveAddBack}` === "1"
            ? true
            : `${formikValue.ffeReserveAddBack}` === "2"
              ? false
              : "",
        leaseFixed: formikValue.leaseFixed,
        leaseVariable: formikValue.leaseVariable,
      },
      staffing: {
        staffToKeyRatio: formikValue.staffPerKey,
        staffHousingCostPerStaff: formikValue.staffHousing,
        staffPhasing: formikValue.staffPhasing,
      },
      workingCapital: formikValue.workingCapital,
    },
    brandedResidencesGeneral: {
      // isOnOff: `${formikValue.brandedResidence}` === "1" ? true : false,
      isOnOff:
        `${formikValue.brandedResidence}` === "1"
          ? true
          : `${formikValue.brandedResidence}` === "2"
            ? false
            : "",
      calcTotalGfaBrandedRes: parseFloat(formikValue.calcTotalGfaBrandedRes),
      calcTotalSellableGla: formikValue.calcTotalSellableGla,
      calcTotalNumberOfKeys: formikValue.calcTotalNumberOfKeys,
      calcTotalSalesPrice: formikValue.calcTotalSalesPrice,
      calcTotalBaseCost: formikValue.calcTotalBaseCost,
      unitTypes: unitType,
      GFAEfficiency: formikValue.Efficiency,
      annualSalesAbsorption: formikValue.annualSalesAbsorption,
      feesAndContributions: {
        operatorContributions: formikValue.opreatorContributions,
        adminExpenses: formikValue.adminExpenses,
        marketingSellingCost: formikValue.marketingSellingCost,
        residentialSaleFee_USD: formikValue.residentialSaleFee,
        residentialConsultancyFee_USD: formikValue.residentialConsultancyFee,
        adminChargesToOwner: formikValue.adminCharges,
        shareOfResidenceOwner: formikValue.shareOfResidence,
        // minimumResidenceOwnerReturn:
        //   `${formikValue.minimumResidenceRadio}` === "1" ? true : false,
        minimumResidenceOwnerReturn:
          `${formikValue.minimumResidenceRadio}` === "1"
            ? true
            : `${formikValue.minimumResidenceRadio}` === "2"
              ? false
              : "",
        minimumResidenceOwnerReturnValue: formikValue.minimumResidence,
        finalAssetSaleValueIncrease: formikValue.finalAssetSale,
        // ffe_ose: `${formikValue.ffeAndOse}` === "1" ? true : false,
        ffe_ose:
          `${formikValue.ffeAndOse}` === "1"
            ? true
            : `${formikValue.ffeAndOse}` === "2"
              ? false
              : "",
        // softCosts: `${formikValue.softCosts}` === "1" ? true : false,
        softCosts:
          `${formikValue.softCosts}` === "1"
            ? true
            : `${formikValue.softCosts}` === "2"
              ? false
              : "",
        // preOpeningTrainingMarketing:
        //   `${formikValue.preOpTraining}` === "1" ? true : false,
        preOpeningTrainingMarketing:
          `${formikValue.preOpTraining}` === "1"
            ? true
            : `${formikValue.preOpTraining}` === "2"
              ? false
              : "",
      },
    },
    brandedResidencesPerformance: {
      includedInRentalPool: includedRentalPool,
      avgDaysOwnerExclusiveUse: avgDaysOwner,
      stabilizedOccupancyPerUnit: stabilizedOcc,
      adrPerUnit: adrPerUnit,
      otherRevenues: brandedResOtherRev,
      additionalExpenses: additionalExpenses,
      managementFees: {
        baseFee: formikValue.brandedBaseFee,
        incentiveFee: formikValue.brandedIncentiveFee,
        franchiseFee: formikValue.brandedFranchiseFee,
      },
    },
  };
};

export const assetListProponentFormFormikValues = (formikValue) => {
  // console.log("submit formik", formikValue);

  return {
    //projectName: formikValue.projectName,
    //assetType: "Standard",
    // projectNameValue: formikValue.projectNameValue,
    comments: formikValue.addComment,

    developmentCostValues: {
      general: {
        developmentCostPerKey_SAR: formikValue.devCostPerKey,
        landCostFreehold_SAR: formikValue.landCost,
      },
      infrastructure: {
        cost_SAR: formikValue.infraCost,
      },
      parking: {
        cost_SAR: formikValue.parkingCost,
      },
      breakdown: {
        construction: {
          cost: formikValue.bDownConsCost,
        },
        FFE_OSE: {
          cost: formikValue.bDownFfeOse,
        },
        softCosts: {
          cost: formikValue.bDownSoftCost,
        },
        preOpening: {
          cost: formikValue.bDownPreOpenTM,
        },
        TSA: {
          cost: formikValue.bDownTsaFee,
        },
        NEOMDevelopmentFee: {
          cost: formikValue.bDownNeomDevFee,
        },
        contingency: {
          cost: formikValue.bDownContCost,
        },
        initialWorkingCapital: formikValue.bDownIniWorkCapital,
        percentageOfBaseCost: formikValue.baseCost,
      },

      refurbishmentYear:
        formikValue.refurbishmentYear != "" &&
          formikValue.refurbishmentYear != null
          ? formikValue.refurbishmentYear.getFullYear()
          : -1,
      refurbishmentCost:
        formikValue.refurbishmentCost === ""
          ? -1
          : formikValue.refurbishmentCost,
    },
  };
};

export const assetListStandardFormPreview = (formField) => {
  // console.log("----1-----", formField);
  let formFields = formField;
  if (!!formField?.formFields) {
      formFields = formField.formFields;
  }
  // console.log("----2-----", formFields);
  let { general, breakdown, parking, infrastructure } =
      formFields.developmentCostValues;
  let { risk, sale, debt } = formFields.financingAssumptions;
  let {
      topLineAssumptions_Standard,
      departmentalExpenses,
      operatorTermsAndStaffing,
      brandedResidencesGeneral,
      brandedResidencesPerformance,
      developmentCostValues,
  } = formFields;
  let { otherRevenues } = formFields.brandedResidencesPerformance;
  let { feesAndContributions } = formFields.brandedResidencesGeneral;
  let { managementFees, otherFees, staffing } =
      formFields.operatorTermsAndStaffing;
  let {
      adminGeneral,
      informationTelecom,
      salesMarketing,
      propertyOperations,
      utilities,
      fixedCharges,
  } = formFields.departmentalExpenses.undistributedExpensesCharges;
  let generalFin = formFields.financingAssumptions.general;

  let managmentIncentiveFee = [
      {
          ifGOPLessThanPercent: managementFees.incentiveFeeGOPLessThanPercent1,
          feePercentOfGOP: managementFees.incentiveFeePercentOfGOP1,
      },
      {
          ifGOPLessThanPercent: managementFees.incentiveFeeGOPLessThanPercent2,
          feePercentOfGOP: managementFees.incentiveFeePercentOfGOP2,
      },
      {
          ifGOPLessThanPercent: managementFees.incentiveFeeGOPLessThanPercent3,
          feePercentOfGOP: managementFees.incentiveFeePercentOfGOP3,
      },
      {
          ifGOPLessThanPercent: managementFees.incentiveFeeGOPLessThanPercent4,
          feePercentOfGOP: managementFees.incentiveFeePercentOfGOP4,
      },
      {
          ifGOPLessThanPercent: managementFees.incentiveFeeGOPLessThanPercent5,
          feePercentOfGOP: managementFees.incentiveFeePercentOfGOP5,
      },
      {
          ifGOPLessThanPercent: managementFees.incentiveFeeGOPLessThanPercent6,
          feePercentOfGOP: managementFees.incentiveFeePercentOfGOP6,
      },
  ];
  let newStartDateDevTime = formFields.developmentTimeStartDate
      ? parseISO(formFields.developmentTimeStartDate)
      : "";
  let newStartDateOpTime = formFields.operationStartDate
      ? parseISO(formFields.operationStartDate)
      : "";

  let newDevelopmentTimeCompletionDate =
      newStartDateDevTime &&
          (formFields.developmentTimeDurationMonths ||
              formFields.developmentTimeDurationMonths === 0)
          ? format(
              addMonths(
                  newStartDateDevTime,
                  formFields.developmentTimeDurationMonths
              ),
              "MM/yyyy"
          )
          : "";
  let newOperationTimeEndDate =
      newStartDateOpTime &&
          (formFields.operationTimeDurationMonths ||
              formFields.operationTimeDurationMonths === 0)
          ? format(
              addMonths(newStartDateOpTime, formFields.operationTimeDurationMonths),
              "MM/yyyy"
          )
          : "";

  let roomTypeArr = formFields.topLineAssumptions_Standard.roomTypes.map(
      (item) => {
          return {
              type: item.roomTypeLookup,
              numKeys: item.numberOfKeys,
              size: item.size,
              occRate: item.occupancyRate,
          };
      }
  );

  let UnitTypesArr = formFields.brandedResidencesGeneral.unitTypes.map(
      (item) => {
          return {
              type: item.unitTypeLookup,
              numKeys: item.noOfUnits,
              totalSellable: item.totalSellable,
              baseCost: item.baseCostPerKey_SAR,
              salesPrice: item.salesPricePerSqm,
          };
      }
  );

  let newOtherRev = [];
  newOtherRev.push(otherRevenues.incFoodAndBeverageOutlets);
  newOtherRev.push(otherRevenues.incBanquet);
  newOtherRev.push(otherRevenues.incRecreation);
  newOtherRev.push(otherRevenues.incSpa);
  newOtherRev.push(otherRevenues.incOtherProfitDepartments);
  newOtherRev.push(otherRevenues.incRetail);
  newOtherRev.push(otherRevenues.incMiscellaneousIncome);
  newOtherRev.push(otherRevenues.incRental);
  newOtherRev.push(otherRevenues.incOther1);
  newOtherRev.push(otherRevenues.incOther2);

  let perfAssitList2 = [];

  topLineAssumptions_Standard.averageDailyRate.forEach((el) => {
      perfAssitList2 = [
          ...perfAssitList2,
          {
              type: el.roomTypeLookup,
              id: el.roomTypeLookup,
              year: el?.rates,
          },
      ];
  });

  let newRentalPool = [];
  let newAvgNumOfDaysPer = [];
  let newStabilizedOccupancy = [];
  let newAdrPerUnit = [];
  let brandedProp = [
      "includedInRentalPool",
      "avgDaysOwnerExclusiveUse",
      "stabilizedOccupancyPerUnit",
      "adrPerUnit",
  ];

  brandedProp.forEach((name, ind) => {
      let newArr = [];

      brandedResidencesPerformance[name].forEach((el) => {
          newArr = [
              ...newArr,
              {
                  type: el.unitTypeLookup,
                  id: el.unitTypeLookup,
                  typeField: el.value,
              },
          ];
      });
      if (ind === 0) {
          newRentalPool = [...newArr];
      } else if (ind === 1) {
          newAvgNumOfDaysPer = [...newArr];
      } else if (ind === 2) {
          newStabilizedOccupancy = [...newArr];
      } else if (ind === 3) {
          newAdrPerUnit = [...newArr];
      }
  });

  return {
      //links: [linksGroup],
      //STEP 1
      projectName: formField.projectName,
      assetType: formFields.assetType,
      projectNameValue: formField.projectNameValue,
      regionLookup: formField.regionLookup,
      operatorLookup: formFields.operatorLookup,
      brandLookup: formFields.brandLookup,
      managementContractLookup: formFields.managementContractLookup,
      positioningLookup: formFields.positioningLookup,
      projectStatusLookup: formFields.projectStatusLookup,
      projectStageLookup: formFields.projectStageLookup,
      plotSize: formFields.plotSize,
      grossFloorArea: formFields.grossFloorArea,
      parkingArea: formFields.parkingArea,
      assetBaseYear: formFields.assetBaseYear
          ? parse(formFields.assetBaseYear, "yyyy", new Date())
          : "",
      numberOfKeys: formFields.numberOfKeys,
      startDateDevTime: newStartDateDevTime,
      developmentTimeDurationMonths: formFields.developmentTimeDurationMonths,
      developmentTimeCompletionDate: newDevelopmentTimeCompletionDate,
      startDateOpTime: newStartDateOpTime,
      operationTimeDurationMonths: formFields.operationTimeDurationMonths,
      operationTimeEndDate: newOperationTimeEndDate,

      //STEP 2
      developmentAbsorptionLand: general.developmentAbsorptionLand,
      devCostPerKey: general.developmentCostPerKey_SAR,
      landCost: general.landCostFreehold_SAR,
      infraCost: infrastructure.cost_SAR,
      infraDepreciation: infrastructure.depreciation,
      infraDevStartMonth: `${infrastructure.developmentStartMonth}`,
      infraDevDuration: infrastructure.developmentDuration,
      parkingCost: parking.cost_SAR,
      parkingDepreciation: parking.depreciation,
      bDownConsCost: breakdown.construction.cost,
      bDownDepConsCost: breakdown.construction.depreciation,
      bDownFfeOse: breakdown.FFE_OSE.cost,
      bDownDepFfeOse: breakdown.FFE_OSE.depreciation,
      ffeDevelopmentDuration: breakdown.FFE_OSE.developmentDuration,
      bDownSoftCost: breakdown.softCosts.cost,
      bDownDepSoftCost: breakdown.softCosts.depreciation,
      bDownPreOpenTM: breakdown.preOpening.cost,
      bDownDepPreOpenTM: breakdown.preOpening.depreciation,
      preOpeningDevelopmentDuration: breakdown.preOpening.developmentDuration,
      bDownTsaFee: breakdown.TSA.cost,
      bDownDepTsaFee: breakdown.TSA.depreciation,
      bDownNeomDevFee: breakdown.NEOMDevelopmentFee.cost,
      bDownDepNeomDevFee: breakdown.NEOMDevelopmentFee.depreciation,
      bDownContCost: breakdown.contingency.cost,
      bDownDepContCost: breakdown.contingency.depreciation,
      bDownIniWorkCapital: breakdown.initialWorkingCapital,
      baseCost: breakdown.percentageOfBaseCost,
      calcTotalDevelopmentCostPerKey:
          developmentCostValues.totalDevelopmentCostPerKey_SAR,

      refurbishmentYear:
          developmentCostValues.refurbishmentYear != "-1"
              ? parse(developmentCostValues.refurbishmentYear, "yyyy", new Date())
              : "",
      refurbishmentCost:
          developmentCostValues.refurbishmentCost != "-1"
              ? developmentCostValues.refurbishmentCost
              : "",

      //STEP 3
      taxRate: generalFin.taxRate,
      openCashBalance: generalFin.openingCashBalance,
      equity: generalFin.equity,
      annualInterestRate: debt.annualInterestRate,
      debtTerm: debt.debtTerm,
      gracePeriod: debt.gracePeriod,
      debtProcFees: debt.debtProcessingFees,
      securityFees: debt.securityFees,
      commitmentFees: debt.commitmentFees,
      sellingCosts: sale.sellingCosts,
      TaxSaleVal: sale.taxOnSaleValue,
      riskFreeRate: risk.riskFreeRate,
      equityRiskPrem: risk.equityRiskPremium,
      countryRiskPrem: risk.countryRiskPremium,
      unleveredBeta: risk.unleveredBeta,
      manualInput: risk.manualInput,
      buffer: risk.buffer,
      ebitdaMulti: formFields.financingAssumptions.terminalValue.ebidtaMultiple,
      // useCalculation: risk.shouldUseCalculations === true ? "1" : "2",
      useCalculation:
          risk.shouldUseCalculations === true
              ? "1"
              : risk.shouldUseCalculations === false
                  ? "2"
                  : "",

      //STEP 4
      roomType: [...roomTypeArr],
      occupancyIncRateYear: topLineAssumptions_Standard.occupancyIncreaseRate,
      pricingInflator: topLineAssumptions_Standard.pricingInflator,
      avgLenOfStay: topLineAssumptions_Standard.avgLengthOfStay,
      guestPerOccRoom: topLineAssumptions_Standard.guestsPerOccupiedRoom,

      //STEP 5
      performaceAssitList2: [...perfAssitList2],
      otherRevenuesPerformance: {
          ...topLineAssumptions_Standard.otherRevenues,
      },

      fnbPnl : formFields.fnbPnl,

      //STEP 6

      externalVisitors: formFields.externalVisitors || topLineAssumptions_Standard.externalVisitors||{
        numberOfVisitors: [0,0,0,0,0,0],
        foodAndBeveragePerVisitor: [0,0,0,0,0,0],
        recreationPerVisitor: [0,0,0,0,0,0],
        spaPerVisitor: [0,0,0,0,0,0],
        otherOnePerVisitor: [0,0,0,0,0,0],
        otherTwoPerVisitor: [0,0,0,0,0,0]
    },

      //STEP 7
      economiesOfScale: departmentalExpenses.economiesOfScaleApplied,
      benchmarkType: departmentalExpenses.benchmarkType,
      positioning: departmentalExpenses.positioningLookup,
      expenseLevel: departmentalExpenses.expenseLevel,

      departmentalExpenses: { ...departmentalExpenses.assumptions },

      //STEP 8
      undistributedExpenses: {
          adminGeneral: {
              ...adminGeneral,
          },
          informationTelecom: {
              ...informationTelecom,
          },
          salesMarketing: {
              ...salesMarketing,
          },
          propertyOperations: {
              ...propertyOperations,
          },
          utilities: {
              ...utilities,
          },
      },

      fixedIncome: fixedCharges.income,
      operationalInsurance: fixedCharges.operationalInsurance,
      permitsAndLicenses: fixedCharges.permitsAndLicenses,
      landLeaseFee: fixedCharges.landLeaseFee,
      otherFixedCharges1: fixedCharges.otherFixedChargesLandLease,
      propertyAndOtherTaxes: fixedCharges.propertyAndOtherTaxes,
      neomHotelsDevFee: fixedCharges.NEOMHotelsDevelopmentFee,
      assetManagementFee: fixedCharges.assetManagementFee,
      otherFixedCharges2: fixedCharges.otherFixedCharges,

      // STEP 9
      managmentBaseFee: managementFees.baseFee,
      managmentIncentiveFee: managmentIncentiveFee,
      franchiseFee: managementFees.franchiseFee,
      revenueType: managementFees.revenueType == "" ? "total" : managementFees.revenueType,
      percentageCap: managementFees.percentageCap,
      includeFranchiseFee: managementFees.includeFranchiseFee == "" ? "yes" : managementFees.includeFranchiseFee,
      franchiseFeeLabel: managementFees.franchiseFeeLabel == "" ? "franchise Fee" : managementFees.franchiseFeeLabel,
      reservationContribution: otherFees.reservationContribution,
      reservationContributionRevenueType: otherFees.reservationContributionRevenueType == "" ? "total" : otherFees.reservationContributionRevenueType,
      reservationContributionLabel: otherFees.reservationContributionLabel == "" ? "Reservation Contribution" : otherFees.reservationContributionLabel,
      adminAndGeneral: otherFees.adminAndGeneral,
      adminAndGeneralRevenueType: otherFees.adminAndGeneralRevenueType == "" ? "total" : otherFees.adminAndGeneralRevenueType,
      adminAndGeneralLabel: otherFees.adminAndGeneralLabel == "" ? "Admin & General" : otherFees.adminAndGeneralLabel,
      marketingContribution: otherFees.marketingContributionType,
      marketingContributionField2: otherFees.marketingContribution,
      rewardProgram: otherFees.rewardProgram,
      roomBookings: otherFees.percentageOfRoomBookings,
      operatorOtherProgramCost: otherFees.operatorOtherProgramCost,
      technicalFeeToOperator: otherFees.technicalFeeToOperator,

      managmentFFEReserveFee: otherFees.ffeReserve,

      // ffeReserveAddBack: otherFees.ffeReserveAddBack === true ? "1" : "2",
      ffeReserveAddBack:
          otherFees.ffeReserveAddBack === true
              ? "1"
              : otherFees.ffeReserveAddBack === false
                  ? "2"
                  : "",
      leaseFixed: otherFees.leaseFixed,
      leaseVariable: otherFees.leaseVariable,
      staffPerKey: staffing.staffToKeyRatio,
      staffHousing: staffing.staffHousingCostPerStaff,
      staffPhasing: staffing.staffPhasing,
      workingCapital: operatorTermsAndStaffing.workingCapital,

      // STEP 10
      unitMix: [...UnitTypesArr],
      annualSalesAbsorption: brandedResidencesGeneral.annualSalesAbsorption,
      Efficiency: brandedResidencesGeneral.GFAEfficiency,
      opreatorContributions: feesAndContributions.operatorContributions,
      adminExpenses: feesAndContributions.adminExpenses,
      marketingSellingCost: feesAndContributions.marketingSellingCost,
      residentialSaleFee: feesAndContributions.residentialSaleFee_USD,
      residentialConsultancyFee:
          feesAndContributions.residentialConsultancyFee_USD,
      adminCharges: feesAndContributions.adminChargesToOwner,
      shareOfResidence: feesAndContributions.shareOfResidenceOwner,
      minimumResidence: feesAndContributions.minimumResidenceOwnerReturnValue,
      finalAssetSale: feesAndContributions.finalAssetSaleValueIncrease,
      calcTotalGfaBrandedRes: brandedResidencesGeneral.calcTotalGfaBrandedRes,
      calcTotalSellableGla: brandedResidencesGeneral.calcTotalSellableGla,
      calcTotalNumberOfKeys: brandedResidencesGeneral.calcTotalNumberOfKeys,
      calcTotalSalesPrice: brandedResidencesGeneral.calcTotalSalesPrice,
      calcTotalBaseCost: brandedResidencesGeneral.calcTotalBaseCost,
      // brandedResidence: brandedResidencesGeneral.isOnOff === true ? "1" : "2",
      // brandedResidence: brandedResidencesGeneral.isOnOff === true ? "1" : "2",
      brandedResidence:
          brandedResidencesGeneral.isOnOff === true
              ? "1"
              : brandedResidencesGeneral.isOnOff === false
                  ? "2"
                  : "",
      // minimumResidenceRadio:
      //   feesAndContributions.minimumResidenceOwnerReturn === true ? "1" : "2",
      // softCosts: feesAndContributions.softCosts === true ? "1" : "2",
      // preOpTraining:
      //   feesAndContributions.preOpeningTrainingMarketing === true ? "1" : "2",
      // ffeAndOse: feesAndContributions.ffe_ose === true ? "1" : "2",
      // minimumResidenceRadio:
      //   feesAndContributions.minimumResidenceOwnerReturn === true ? "1" : "2",
      minimumResidenceRadio:
          feesAndContributions.minimumResidenceOwnerReturn === true
              ? "1"
              : feesAndContributions.minimumResidenceOwnerReturn === false
                  ? "2"
                  : "",
      softCosts:
          feesAndContributions.softCosts === true
              ? "1"
              : feesAndContributions.softCosts === false
                  ? "2"
                  : "",
      preOpTraining:
          feesAndContributions.preOpeningTrainingMarketing === true
              ? "1"
              : feesAndContributions.preOpeningTrainingMarketing === false
                  ? "2"
                  : "",
      ffeAndOse:
          feesAndContributions.ffe_ose === true
              ? "1"
              : feesAndContributions.ffe_ose === false
                  ? "2"
                  : "",

      // STEP 11

      expenses1: brandedResidencesPerformance.additionalExpenses[0],
      expenses2: brandedResidencesPerformance.additionalExpenses[1],
      expenses3: brandedResidencesPerformance.additionalExpenses[2],
      expenses4: brandedResidencesPerformance.additionalExpenses[3],
      expenses5: brandedResidencesPerformance.additionalExpenses[4],
      brandedBaseFee: brandedResidencesPerformance.managementFees.baseFee,
      brandedIncentiveFee:
          brandedResidencesPerformance.managementFees.incentiveFee,
      brandedFranchiseFee:
          brandedResidencesPerformance.managementFees.franchiseFee,

      brandedResidencesFields: {
          rentalPool: [...newRentalPool],
          avgNumberOfDaysPerAnnum: newAvgNumOfDaysPer,
          stabilizedOccupancy: [...newStabilizedOccupancy],
          adrPerUnit: [...newAdrPerUnit],
          otherRev: newOtherRev,
      },

      //Final Step
      assignTo: formField.assignedTo,
      assignedBy: formField.assignedBy,
      addComment: formField.comments,
      approveAssetInfo: formField.approveAssetInfo,

      //-------------CALCULATION-----------
      calc1: topLineAssumptions_Standard.weightedOccupancyIncreaseRate[0],
      calc2: topLineAssumptions_Standard.weightedOccupancyIncreaseRate[1],
      calc3: topLineAssumptions_Standard.weightedOccupancyIncreaseRate[2],
      calc4: topLineAssumptions_Standard.weightedOccupancyIncreaseRate[3],
      calc5: topLineAssumptions_Standard.weightedOccupancyIncreaseRate[4],
      calc6: topLineAssumptions_Standard.weightedOccupancyIncreaseRate[5],

      //calculation number 2
      weightedADR1: topLineAssumptions_Standard.weightedAverageDailyRate[0],
      weightedADR2: topLineAssumptions_Standard.weightedAverageDailyRate[1],
      weightedADR3: topLineAssumptions_Standard.weightedAverageDailyRate[2],
      weightedADR4: topLineAssumptions_Standard.weightedAverageDailyRate[3],
      weightedADR5: topLineAssumptions_Standard.weightedAverageDailyRate[4],
      weightedADR6: topLineAssumptions_Standard.weightedAverageDailyRate[5],
  };
};

export const assetListPackageFormPreview = (formField) => {
  let formFields = formField;
  if (!!formField?.formFields) {
      formFields = formField.formFields;
  }
  let { general, breakdown, parking, infrastructure } =
      formFields.developmentCostValues;
  let { risk, sale, debt } = formFields.financingAssumptions;
  let {
      topLineAssumptions_Package,
      departmentalExpenses,
      operatorTermsAndStaffing,
      brandedResidencesGeneral,
      brandedResidencesPerformance,
      developmentCostValues,
  } = formFields;
  let { otherRevenues } = formFields.brandedResidencesPerformance;
  let { feesAndContributions } = formFields.brandedResidencesGeneral;
  let { managementFees, otherFees, staffing } =
      formFields.operatorTermsAndStaffing;
  let {
      adminGeneral,
      informationTelecom,
      salesMarketing,
      propertyOperations,
      utilities,
      fixedCharges,
  } = formFields.departmentalExpenses.undistributedExpensesCharges;
  let generalFin = formFields.financingAssumptions.general;

  let managmentIncentiveFee = [
      {
          ifGOPLessThanPercent: managementFees.incentiveFeeGOPLessThanPercent1,
          feePercentOfGOP: managementFees.incentiveFeePercentOfGOP1,
      },
      {
          ifGOPLessThanPercent: managementFees.incentiveFeeGOPLessThanPercent2,
          feePercentOfGOP: managementFees.incentiveFeePercentOfGOP2,
      },
      {
          ifGOPLessThanPercent: managementFees.incentiveFeeGOPLessThanPercent3,
          feePercentOfGOP: managementFees.incentiveFeePercentOfGOP3,
      },
      {
          ifGOPLessThanPercent: managementFees.incentiveFeeGOPLessThanPercent4,
          feePercentOfGOP: managementFees.incentiveFeePercentOfGOP4,
      },
      {
          ifGOPLessThanPercent: managementFees.incentiveFeeGOPLessThanPercent5,
          feePercentOfGOP: managementFees.incentiveFeePercentOfGOP5,
      },
      {
          ifGOPLessThanPercent: managementFees.incentiveFeeGOPLessThanPercent6,
          feePercentOfGOP: managementFees.incentiveFeePercentOfGOP6,
      },
  ];

  let newStartDateDevTime = formFields.developmentTimeStartDate
      ? parseISO(formFields.developmentTimeStartDate)
      : "";
  let newStartDateOpTime = formFields.operationStartDate
      ? parseISO(formFields.operationStartDate)
      : "";

  let newDevelopmentTimeCompletionDate =
      newStartDateDevTime &&
          (formFields.developmentTimeDurationMonths ||
              formFields.developmentTimeDurationMonths === 0)
          ? format(
              addMonths(
                  newStartDateDevTime,
                  formFields.developmentTimeDurationMonths
              ),
              "MM/yyyy"
          )
          : "";
  let newOperationTimeEndDate =
      newStartDateOpTime &&
          (formFields.operationTimeDurationMonths ||
              formFields.operationTimeDurationMonths === 0)
          ? format(
              addMonths(newStartDateOpTime, formFields.operationTimeDurationMonths),
              "MM/yyyy"
          )
          : "";

  let UnitTypesArr = formFields.brandedResidencesGeneral.unitTypes.map(
      (item) => {
          return {
              type: item.unitTypeLookup,
              numKeys: item.noOfUnits,
              totalSellable: item.totalSellable,
              baseCost: item.baseCostPerKey_SAR,
              salesPrice: item.salesPricePerSqm,
          };
      }
  );

  let newOtherRev = [];
  newOtherRev.push(otherRevenues.incFoodAndBeverageOutlets);
  newOtherRev.push(otherRevenues.incBanquet);
  newOtherRev.push(otherRevenues.incRecreation);
  newOtherRev.push(otherRevenues.incSpa);
  newOtherRev.push(otherRevenues.incOtherProfitDepartments);
  newOtherRev.push(otherRevenues.incRetail);
  newOtherRev.push(otherRevenues.incMiscellaneousIncome);
  newOtherRev.push(otherRevenues.incRental);
  newOtherRev.push(otherRevenues.incOther1);
  newOtherRev.push(otherRevenues.incOther2);

  let newRentalPool = [];
  let newAvgNumOfDaysPer = [];
  let newStabilizedOccupancy = [];
  let newAdrPerUnit = [];
  let brandedProp = [
      "includedInRentalPool",
      "avgDaysOwnerExclusiveUse",
      "stabilizedOccupancyPerUnit",
      "adrPerUnit",
  ];

  brandedProp.forEach((name, ind) => {
      let newArr = [];

      brandedResidencesPerformance[name].forEach((el) => {
          newArr = [
              ...newArr,
              {
                  type: el.unitTypeLookup,
                  id: el.unitTypeLookup,
                  typeField: el.value,
              },
          ];
      });
      if (ind === 0) {
          newRentalPool = [...newArr];
      } else if (ind === 1) {
          newAvgNumOfDaysPer = [...newArr];
      } else if (ind === 2) {
          newStabilizedOccupancy = [...newArr];
      } else if (ind === 3) {
          newAdrPerUnit = [...newArr];
      }
  });

  let packageTypeArr = topLineAssumptions_Package.packageTypes.map((item) => {
      return {
          type: item.packageTypeLookup,
          num: item.numberOfKeys,
          gpor: item.gpor,
          occRate: item.occupancyRate,
      };
  });

  let roomRateArr = [];
  topLineAssumptions_Package.roomRate.forEach((el, ind) => {
      roomRateArr = [
          ...roomRateArr,
          {
              type: el.packageTypeLookup,
              id: el.packageTypeLookup,
              year: el.rates,
          },
      ];
  });
  let foodBevArr = [];
  topLineAssumptions_Package.foodAndBeverage.forEach((el, ind) => {
      foodBevArr = [
          ...foodBevArr,
          {
              type: el.packageTypeLookup,
              id: el.packageTypeLookup,
              year: el.rates,
          },
      ];
  });
  let otherArr = [];
  topLineAssumptions_Package.otherRates.forEach((el, ind) => {
      otherArr = [
          ...otherArr,
          {
              type: el.packageTypeLookup,
              id: el.packageTypeLookup,
              year: el.rates,
          },
      ];
  });
  let packRateArr = [];
  topLineAssumptions_Package.packageRate.forEach((el, ind) => {
      packRateArr = [
          ...packRateArr,
          {
              type: el.packageTypeLookup,
              id: el.packageTypeLookup,
              year: el.rates,
          },
      ];
  });

  return {
      //STEP 1
      projectName: formField.projectName,
      assetType: formFields.assetType,
      regionLookup: formField.regionLookup,
      operatorLookup: formFields.operatorLookup,
      projectNameValue: formField.projectNameValue,
      brandLookup: formFields.brandLookup,
      managementContractLookup: formFields.managementContractLookup,
      positioningLookup: formFields.positioningLookup,
      projectStatusLookup: formFields.projectStatusLookup,
      projectStageLookup: formFields.projectStageLookup,
      plotSize: formFields.plotSize,
      grossFloorArea: formFields.grossFloorArea,
      parkingArea: formFields.parkingArea,
      assetBaseYear: formFields.assetBaseYear
          ? parse(formFields.assetBaseYear, "yyyy", new Date())
          : "",
      numberOfKeys: formFields.numberOfKeys,
      startDateDevTime: newStartDateDevTime,
      developmentTimeDurationMonths: formFields.developmentTimeDurationMonths,
      developmentTimeCompletionDate: newDevelopmentTimeCompletionDate,
      startDateOpTime: newStartDateOpTime,
      operationTimeDurationMonths: formFields.operationTimeDurationMonths,
      operationTimeEndDate: newOperationTimeEndDate,

      //STEP 2
      developmentAbsorptionLand: general.developmentAbsorptionLand,
      devCostPerKey: general.developmentCostPerKey_SAR,
      landCost: general.landCostFreehold_SAR,
      infraCost: infrastructure.cost_SAR,
      infraDepreciation: infrastructure.depreciation,
      infraDevStartMonth: `${infrastructure.developmentStartMonth}`,
      infraDevDuration: infrastructure.developmentDuration,
      parkingCost: parking.cost_SAR,
      parkingDepreciation: parking.depreciation,
      bDownConsCost: breakdown.construction.cost,
      bDownDepConsCost: breakdown.construction.depreciation,
      bDownFfeOse: breakdown.FFE_OSE.cost,
      bDownDepFfeOse: breakdown.FFE_OSE.depreciation,
      ffeDevelopmentDuration: breakdown.FFE_OSE.developmentDuration,
      bDownSoftCost: breakdown.softCosts.cost,
      bDownDepSoftCost: breakdown.softCosts.depreciation,
      bDownPreOpenTM: breakdown.preOpening.cost,
      bDownDepPreOpenTM: breakdown.preOpening.depreciation,
      preOpeningDevelopmentDuration: breakdown.preOpening.developmentDuration,
      bDownTsaFee: breakdown.TSA.cost,
      bDownDepTsaFee: breakdown.TSA.depreciation,
      bDownNeomDevFee: breakdown.NEOMDevelopmentFee.cost,
      bDownDepNeomDevFee: breakdown.NEOMDevelopmentFee.depreciation,
      bDownContCost: breakdown.contingency.cost,
      bDownDepContCost: breakdown.contingency.depreciation,
      bDownIniWorkCapital: breakdown.initialWorkingCapital,
      baseCost: breakdown.percentageOfBaseCost,
      calcTotalDevelopmentCostPerKey:
          developmentCostValues.totalDevelopmentCostPerKey_SAR,

      refurbishmentYear:
          developmentCostValues.refurbishmentYear != "-1"
              ? parse(developmentCostValues.refurbishmentYear, "yyyy", new Date())
              : "",
      refurbishmentCost:
          developmentCostValues.refurbishmentCost != "-1"
              ? developmentCostValues.refurbishmentCost
              : "",

      //STEP 3
      taxRate: generalFin.taxRate,
      openCashBalance: generalFin.openingCashBalance,
      equity: generalFin.equity,
      annualInterestRate: debt.annualInterestRate,
      debtTerm: debt.debtTerm,
      gracePeriod: debt.gracePeriod,
      debtProcFees: debt.debtProcessingFees,
      securityFees: debt.securityFees,
      commitmentFees: debt.commitmentFees,
      sellingCosts: sale.sellingCosts,
      TaxSaleVal: sale.taxOnSaleValue,
      riskFreeRate: risk.riskFreeRate,
      equityRiskPrem: risk.equityRiskPremium,
      countryRiskPrem: risk.countryRiskPremium,
      unleveredBeta: risk.unleveredBeta,
      manualInput: risk.manualInput,
      buffer: risk.buffer,
      ebitdaMulti: formFields.financingAssumptions.terminalValue.ebidtaMultiple,
      // useCalculation: risk.shouldUseCalculations === true ? "1" : "2",
      useCalculation:
          risk.shouldUseCalculations === true
              ? "1"
              : risk.shouldUseCalculations === false
                  ? "2"
                  : "",

      //STEP 4
      packageType: [...packageTypeArr],
      occupancyIncRateYear: topLineAssumptions_Package.occupancyIncreaseRate,
      pricingInflator: topLineAssumptions_Package.pricingInflator,
      avgLenOfStay: topLineAssumptions_Package.avgLengthOfStay,
      guestPerOccRoom:
          topLineAssumptions_Package.guestsPerOccupiedRoom === ""
              ? 0
              : topLineAssumptions_Package.guestsPerOccupiedRoom,

      //STEP 5
      //from AssetListForm5Package itself
      performaceAssitList2: {
          roomRate: roomRateArr,
          foodAndBeverage: foodBevArr,
          other: otherArr,
          packageRate: packRateArr,
      },

      //STEP 6
      externalVisitors: formFields.externalVisitors || topLineAssumptions_Package.externalVisitors||{
        numberOfVisitors: [0,0,0,0,0,0],
        foodAndBeveragePerVisitor: [0,0,0,0,0,0],
        recreationPerVisitor: [0,0,0,0,0,0],
        spaPerVisitor: [0,0,0,0,0,0],
        otherOnePerVisitor: [0,0,0,0,0,0],
        otherTwoPerVisitor: [0,0,0,0,0,0]
    },

      //STEP 7
      otherRevenueBreakdown: { ...topLineAssumptions_Package.otherRevenues },

      //STEP 8
      economiesOfScale: departmentalExpenses.economiesOfScaleApplied,
      benchmarkType: departmentalExpenses.benchmarkType,
      positioning: departmentalExpenses.positioningLookup,
      expenseLevel: departmentalExpenses.expenseLevel,

      departmentalExpenses: { ...departmentalExpenses.assumptions },

      //STEP 9
      undistributedExpenses: {
          adminGeneral: {
              ...adminGeneral,
          },
          informationTelecom: {
              ...informationTelecom,
          },
          salesMarketing: {
              ...salesMarketing,
          },
          propertyOperations: {
              ...propertyOperations,
          },
          utilities: {
              ...utilities,
          },
      },

      fixedIncome: fixedCharges.income,
      operationalInsurance: fixedCharges.operationalInsurance,
      permitsAndLicenses: fixedCharges.permitsAndLicenses,
      landLeaseFee: fixedCharges.landLeaseFee,
      otherFixedCharges1: fixedCharges.otherFixedChargesLandLease,
      propertyAndOtherTaxes: fixedCharges.propertyAndOtherTaxes,
      neomHotelsDevFee: fixedCharges.NEOMHotelsDevelopmentFee,
      assetManagementFee: fixedCharges.assetManagementFee,
      otherFixedCharges2: fixedCharges.otherFixedCharges,

      // STEP 10
      managmentBaseFee: managementFees.baseFee,
      managmentIncentiveFee: managmentIncentiveFee,
      franchiseFee: managementFees.franchiseFee,
      revenueType: managementFees.revenueType == "" ? "total" : managementFees.revenueType,
      percentageCap: managementFees.percentageCap,
      includeFranchiseFee: managementFees.includeFranchiseFee == "" ? "yes" : managementFees.includeFranchiseFee,
      franchiseFeeLabel: managementFees.franchiseFeeLabel == "" ? "franchise Fee" : managementFees.franchiseFeeLabel,
      reservationContribution: otherFees.reservationContribution,
      reservationContributionRevenueType: otherFees.reservationContributionRevenueType == "" ? "total" : otherFees.reservationContributionRevenueType,
      reservationContributionLabel: otherFees.reservationContributionLabel == "" ? "Reservation Contribution" : otherFees.reservationContributionLabel,
      adminAndGeneral: otherFees.adminAndGeneral,
      adminAndGeneralRevenueType: otherFees.adminAndGeneralRevenueType == "" ? "total" : otherFees.adminAndGeneralRevenueType,
      adminAndGeneralLabel: otherFees.adminAndGeneralLabel == "" ? "Admin & General" : otherFees.adminAndGeneralLabel,
      reservationContribution: otherFees.reservationContribution,
      adminAndGeneral: otherFees.adminAndGeneral,
      marketingContribution: otherFees.marketingContributionType,
      marketingContributionField2: otherFees.marketingContribution,
      rewardProgram: otherFees.rewardProgram,
      roomBookings: otherFees.percentageOfRoomBookings,
      operatorOtherProgramCost: otherFees.operatorOtherProgramCost,
      technicalFeeToOperator: otherFees.technicalFeeToOperator,

      managmentFFEReserveFee: otherFees.ffeReserve,

      // ffeReserveAddBack: otherFees.ffeReserveAddBack === true ? "1" : "2",
      ffeReserveAddBack:
          otherFees.ffeReserveAddBack === true
              ? "1"
              : otherFees.ffeReserveAddBack === false
                  ? "2"
                  : "",
      leaseFixed: otherFees.leaseFixed,
      leaseVariable: otherFees.leaseVariable,
      staffPerKey: staffing.staffToKeyRatio,
      staffHousing: staffing.staffHousingCostPerStaff,
      staffPhasing: staffing.staffPhasing,
      workingCapital: operatorTermsAndStaffing.workingCapital,

      // STEP 11
      unitMix: [...UnitTypesArr],
      annualSalesAbsorption: brandedResidencesGeneral.annualSalesAbsorption,
      Efficiency: brandedResidencesGeneral.GFAEfficiency,
      opreatorContributions: feesAndContributions.operatorContributions,
      adminExpenses: feesAndContributions.adminExpenses,
      marketingSellingCost: feesAndContributions.marketingSellingCost,
      residentialSaleFee: feesAndContributions.residentialSaleFee_USD,
      residentialConsultancyFee:
          feesAndContributions.residentialConsultancyFee_USD,
      adminCharges: feesAndContributions.adminChargesToOwner,
      shareOfResidence: feesAndContributions.shareOfResidenceOwner,
      minimumResidence: feesAndContributions.minimumResidenceOwnerReturnValue,
      finalAssetSale: feesAndContributions.finalAssetSaleValueIncrease,
      calcTotalGfaBrandedRes: brandedResidencesGeneral.calcTotalGfaBrandedRes,
      calcTotalSellableGla: brandedResidencesGeneral.calcTotalSellableGla,
      calcTotalNumberOfKeys: brandedResidencesGeneral.calcTotalNumberOfKeys,
      calcTotalSalesPrice: brandedResidencesGeneral.calcTotalSalesPrice,
      calcTotalBaseCost: brandedResidencesGeneral.calcTotalBaseCost,
      // brandedResidence: brandedResidencesGeneral.isOnOff === true ? "1" : "2",
      brandedResidence:
          brandedResidencesGeneral.isOnOff === true
              ? "1"
              : brandedResidencesGeneral.isOnOff === false
                  ? "2"
                  : "",
      // minimumResidenceRadio:
      //   feesAndContributions.minimumResidenceOwnerReturn === true ? "1" : "2",
      minimumResidenceRadio:
          feesAndContributions.minimumResidenceOwnerReturn === true
              ? "1"
              : feesAndContributions.minimumResidenceOwnerReturn === false
                  ? "2"
                  : "",
      // softCosts: feesAndContributions.softCosts === true ? "1" : "2",
      softCosts:
          feesAndContributions.softCosts === true
              ? "1"
              : feesAndContributions.softCosts === false
                  ? "2"
                  : "",
      // preOpTraining:
      //   feesAndContributions.preOpeningTrainingMarketing === true ? "1" : "2",
      preOpTraining:
          feesAndContributions.preOpeningTrainingMarketing === true
              ? "1"
              : feesAndContributions.preOpeningTrainingMarketing === false
                  ? "2"
                  : "",
      // ffeAndOse: feesAndContributions.ffe_ose === true ? "1" : "2",
      ffeAndOse:
          feesAndContributions.ffe_ose === true
              ? "1"
              : feesAndContributions.ffe_ose === false
                  ? "2"
                  : "",

      // STEP 12

      expenses1: brandedResidencesPerformance.additionalExpenses[0],
      expenses2: brandedResidencesPerformance.additionalExpenses[1],
      expenses3: brandedResidencesPerformance.additionalExpenses[2],
      expenses4: brandedResidencesPerformance.additionalExpenses[3],
      expenses5: brandedResidencesPerformance.additionalExpenses[4],
      brandedBaseFee: brandedResidencesPerformance.managementFees.baseFee,
      brandedIncentiveFee:
          brandedResidencesPerformance.managementFees.incentiveFee,
      brandedFranchiseFee:
          brandedResidencesPerformance.managementFees.franchiseFee,

      brandedResidencesFields: {
          rentalPool: [...newRentalPool],
          avgNumberOfDaysPerAnnum: newAvgNumOfDaysPer,
          stabilizedOccupancy: [...newStabilizedOccupancy],
          adrPerUnit: [...newAdrPerUnit],
          otherRev: newOtherRev,
      },

      //Final Step
      assignTo: formField.assignedTo,
      assignedBy: formField.assignedBy,
      addComment: formField.comments,
      approveAssetInfo: formField.approveAssetInfo,

      //-------------CALCULATION-----------
      calc1: topLineAssumptions_Package.weightedOccupancyIncreaseRate[0],
      calc2: topLineAssumptions_Package.weightedOccupancyIncreaseRate[1],
      calc3: topLineAssumptions_Package.weightedOccupancyIncreaseRate[2],
      calc4: topLineAssumptions_Package.weightedOccupancyIncreaseRate[3],
      calc5: topLineAssumptions_Package.weightedOccupancyIncreaseRate[4],
      calc6: topLineAssumptions_Package.weightedOccupancyIncreaseRate[5],

      //step 5 PACKAGE
      fAndB1: topLineAssumptions_Package.weightedFoodAndBeverageRate[0],
      fAndB2: topLineAssumptions_Package.weightedFoodAndBeverageRate[1],
      fAndB3: topLineAssumptions_Package.weightedFoodAndBeverageRate[2],
      fAndB4: topLineAssumptions_Package.weightedFoodAndBeverageRate[3],
      fAndB5: topLineAssumptions_Package.weightedFoodAndBeverageRate[4],
      fAndB6: topLineAssumptions_Package.weightedFoodAndBeverageRate[5],
      otherCalc1: topLineAssumptions_Package.weightedOtherRates[0],
      otherCalc2: topLineAssumptions_Package.weightedOtherRates[1],
      otherCalc3: topLineAssumptions_Package.weightedOtherRates[2],
      otherCalc4: topLineAssumptions_Package.weightedOtherRates[3],
      otherCalc5: topLineAssumptions_Package.weightedOtherRates[4],
      otherCalc6: topLineAssumptions_Package.weightedOtherRates[5],
      roomRateCalc1: topLineAssumptions_Package.weightedRoomRate[0],
      roomRateCalc2: topLineAssumptions_Package.weightedRoomRate[0],
      roomRateCalc3: topLineAssumptions_Package.weightedRoomRate[0],
      roomRateCalc4: topLineAssumptions_Package.weightedRoomRate[0],
      roomRateCalc5: topLineAssumptions_Package.weightedRoomRate[0],
      roomRateCalc6: topLineAssumptions_Package.weightedRoomRate[0],

      packageRateCalc1: topLineAssumptions_Package.weightedPackageRate[0],
      packageRateCalc2: topLineAssumptions_Package.weightedPackageRate[1],
      packageRateCalc3: topLineAssumptions_Package.weightedPackageRate[2],
      packageRateCalc4: topLineAssumptions_Package.weightedPackageRate[3],
      packageRateCalc5: topLineAssumptions_Package.weightedPackageRate[4],
      packageRateCalc6: topLineAssumptions_Package.weightedPackageRate[5],

      weightedADRCalc1: topLineAssumptions_Package.weightedADR[0],
      weightedADRCalc2: topLineAssumptions_Package.weightedADR[1],
      weightedADRCalc3: topLineAssumptions_Package.weightedADR[2],
      weightedADRCalc4: topLineAssumptions_Package.weightedADR[3],
      weightedADRCalc5: topLineAssumptions_Package.weightedADR[4],
      weightedADRCalc6: topLineAssumptions_Package.weightedADR[5],

      totalPackageRateSARCalc1:
          topLineAssumptions_Package.totalPackageRate_SAR[0],
      totalPackageRateSARCalc2:
          topLineAssumptions_Package.totalPackageRate_SAR[1],
      totalPackageRateSARCalc3:
          topLineAssumptions_Package.totalPackageRate_SAR[2],
      totalPackageRateSARCalc4:
          topLineAssumptions_Package.totalPackageRate_SAR[3],
      totalPackageRateSARCalc5:
          topLineAssumptions_Package.totalPackageRate_SAR[4],
      totalPackageRateSARCalc6:
          topLineAssumptions_Package.totalPackageRate_SAR[5],

      totalPackageRateUSDCalc1:
          topLineAssumptions_Package.totalPackageRate_USD[0],
      totalPackageRateUSDCalc2:
          topLineAssumptions_Package.totalPackageRate_USD[1],
      totalPackageRateUSDCalc3:
          topLineAssumptions_Package.totalPackageRate_USD[2],
      totalPackageRateUSDCalc4:
          topLineAssumptions_Package.totalPackageRate_USD[3],
      totalPackageRateUSDCalc5:
          topLineAssumptions_Package.totalPackageRate_USD[4],
      totalPackageRateUSDCalc6:
          topLineAssumptions_Package.totalPackageRate_USD[5],

      totalPackageRate7DaysSARCalc1:
          topLineAssumptions_Package.totalPackageRateSevenDays_SAR[0],
      totalPackageRate7DaysSARCalc2:
          topLineAssumptions_Package.totalPackageRateSevenDays_SAR[1],
      totalPackageRate7DaysSARCalc3:
          topLineAssumptions_Package.totalPackageRateSevenDays_SAR[2],
      totalPackageRate7DaysSARCalc4:
          topLineAssumptions_Package.totalPackageRateSevenDays_SAR[3],
      totalPackageRate7DaysSARCalc5:
          topLineAssumptions_Package.totalPackageRateSevenDays_SAR[4],
      totalPackageRate7DaysSARCalc6:
          topLineAssumptions_Package.totalPackageRateSevenDays_SAR[5],

      totalPackageRate7DaysUSDCalc1:
          topLineAssumptions_Package.totalPackageRateSevenDays_USD[0],
      totalPackageRate7DaysUSDCalc2:
          topLineAssumptions_Package.totalPackageRateSevenDays_USD[1],
      totalPackageRate7DaysUSDCalc3:
          topLineAssumptions_Package.totalPackageRateSevenDays_USD[2],
      totalPackageRate7DaysUSDCalc4:
          topLineAssumptions_Package.totalPackageRateSevenDays_USD[3],
      totalPackageRate7DaysUSDCalc5:
          topLineAssumptions_Package.totalPackageRateSevenDays_USD[4],
      totalPackageRate7DaysUSDCalc6:
          topLineAssumptions_Package.totalPackageRateSevenDays_USD[5],
  };
};

export const assetListProponentFormPreview = (formField) => {
  // console.log("----1-----", formField);
  let formFields = formField;
  if (!!formField?.formFields) {
    formFields = formField.formFields;
  }
  // console.log("----2-----", formFields);
  let { general, breakdown, parking, infrastructure } =
    formFields.developmentCostValues;

  let { developmentCostValues } = formFields;

  let newStartDateDevTime = formFields.developmentTimeStartDate
    ? parseISO(formFields.developmentTimeStartDate)
    : "";
  let newStartDateOpTime = formFields.operationStartDate
    ? parseISO(formFields.operationStartDate)
    : "";

  let newDevelopmentTimeCompletionDate =
    newStartDateDevTime &&
      (formFields.developmentTimeDurationMonths ||
        formFields.developmentTimeDurationMonths === 0)
      ? format(
        addMonths(
          newStartDateDevTime,
          formFields.developmentTimeDurationMonths
        ),
        "MM/yyyy"
      )
      : "";
  let newOperationTimeEndDate =
    newStartDateOpTime &&
      (formFields.operationTimeDurationMonths ||
        formFields.operationTimeDurationMonths === 0)
      ? format(
        addMonths(newStartDateOpTime, formFields.operationTimeDurationMonths),
        "MM/yyyy"
      )
      : "";

  return {
    //links: [linksGroup],
    //STEP 1
    projectName: formField.projectName,
    assetType: formFields.assetType,
    projectNameValue: formField.projectNameValue,
    regionLookup: formField.regionLookup,
    operatorLookup: formFields.operatorLookup,
    brandLookup: formFields.brandLookup,
    managementContractLookup: formFields.managementContractLookup,
    positioningLookup: formFields.positioningLookup,
    projectStatusLookup: formFields.projectStatusLookup,
    projectStageLookup: formFields.projectStageLookup,
    plotSize: formFields.plotSize,
    grossFloorArea: formFields.grossFloorArea,
    parkingArea: formFields.parkingArea,
    assetBaseYear: formFields.assetBaseYear
      ? parse(formFields.assetBaseYear, "yyyy", new Date())
      : "",
    numberOfKeys: formFields.numberOfKeys,
    startDateDevTime: newStartDateDevTime,
    developmentTimeDurationMonths: formFields.developmentTimeDurationMonths,
    developmentTimeCompletionDate: newDevelopmentTimeCompletionDate,
    startDateOpTime: newStartDateOpTime,
    operationTimeDurationMonths: formFields.operationTimeDurationMonths,
    operationTimeEndDate: newOperationTimeEndDate,

    //STEP 2
    devCostPerKey: general.developmentCostPerKey_SAR,
    landCost: general.landCostFreehold_SAR,
    infraCost: infrastructure.cost_SAR,
    parkingCost: parking.cost_SAR,
    bDownConsCost: breakdown.construction.cost,
    bDownFfeOse: breakdown.FFE_OSE.cost,
    bDownSoftCost: breakdown.softCosts.cost,
    bDownPreOpenTM: breakdown.preOpening.cost,
    bDownTsaFee: breakdown.TSA.cost,
    bDownNeomDevFee: breakdown.NEOMDevelopmentFee.cost,
    bDownContCost: breakdown.contingency.cost,
    bDownIniWorkCapital: breakdown.initialWorkingCapital,
    baseCost: breakdown.percentageOfBaseCost,
    refurbishmentYear:
      developmentCostValues.refurbishmentYear != "-1"
        ? parse(developmentCostValues.refurbishmentYear, "yyyy", new Date())
        : "",
    refurbishmentCost:
      developmentCostValues.refurbishmentCost != "-1"
        ? developmentCostValues.refurbishmentCost
        : "",

    //Final Step
    //assignTo: formField.assignedTo,
    addComment: formField.comments,
    //approveAssetInfo: formField.approveAssetInfo,
  };
};
