import {
  Box,
  Text,
  HStack,
  Spacer,
  Image,
  Divider,
  Progress,
} from "@chakra-ui/react";

import { MainButton } from "../button/MainButton";
import { MainBox } from "../mainBox/MainBox";

import checkGreenSmall from "../../assets/images/icons/checkGreenSmall.svg";
import info from "../../assets/images/icons/Subtract.svg";

export const AssetStepper = ({
  page,
  steps = [],
  setCurrentPage,
  percentage,
  setStepCompleted,
  title,
  isDisabledCalc,
  isDisabledTypes,
  onClickedFormulaOpen,
  onClickedKeysOpen,
  setStepWarning,
  validateKeys,
}) => {
  return (
    <>
      <MainBox
        py={2}
        px={0}
        // maxWidth={["30%", "30%", "30%", "30%", "28%", "380px"]}
        stepper={true}
      >
        <Text py="3" px="4" fontSize="15px" textTransform="uppercase">
          {title}
        </Text>

        <Divider w="100%" orientation="horizontal" mt="10px" mb="5px" />

        <HStack fontSize="12px" px="4" py="3">
          <Box>
            <Text>
              STEP {""} {page + 1}/{steps.length}{" "}
            </Text>
          </Box>
          <Spacer />
          <Text>{percentage}%</Text>
        </HStack>

        <Box px="4">
          <Progress
            className="progress_class"
            borderRadius="5px"
            value={percentage}
            size="xs"
            colorScheme="red"
          />
        </Box>

        <Box mt="6" fontSize="14px">
          {steps.map((step, index) => (
            <Box
              borderTop={"1px solid #F5F5F5"}
              bg={page + 1 != step.id ? "" : "#F8F8F8"}
              id={step.id}
              key={step.id}
            >
              <Box px="3" py="3">
                <HStack>
                  <MainButton
                    disabledBtn={
                      step.completed === false &&
                      page + 1 != step.id &&
                      step.clicked === false
                        ? true
                        : false
                    }
                    fSize="md"
                    fontColor={step.id === steps.length ? "assets" : ""}
                    btnStyle={
                      step.id === steps.length ? "transparent2" : "transparent"
                    }
                    title={step.title}
                    onPress={() => {
                      setCurrentPage((prev) => {
                        if (isDisabledTypes.isStepDisabled) {
                          setStepWarning(isDisabledTypes.step);
                        }
                        if (validateKeys) {
                          onClickedKeysOpen();
                          return 3;
                        }
                        if (prev == 3 && !isDisabledCalc) {
                          onClickedFormulaOpen();
                          return 3;
                        } else {
                          return step.id - 1;
                        }
                      });
                    }}
                  />
                  {step.completed && (
                    <Image
                      size="xxs"
                      src={
                        step.completed && step.id !== steps.length
                          ? checkGreenSmall
                          : ""
                      }
                    />
                  )}
                  {step.warning && (
                    <Image
                      width="17px"
                      size="xxxs"
                      src={step.warning ? info : ""}
                    />
                  )}
                </HStack>
              </Box>
            </Box>
          ))}
        </Box>
      </MainBox>
    </>
  );
};
