import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { PostAssetListModel } from "../../api/AssetListAPI";
import { assetsModellingInitialValues } from "./assetsModellingInitialValues";
import { GenerateModelFormBody } from "./GenerateModelFormBody";
import { assetModellingValidation } from "./assetsModellingValidation";
import { MainButton } from "../../components/button/MainButton";

export const GenerateModelForm = ({
  checkedItems,
  assetsData,
  type,
  id,
  projectNameValue,
  onErrorOpen,
  setErrorMessage,
  onClose,
  loadModelData,
  onMissingOpen,
  packageTypeArr,
}) => {
  const [initConf, setInitConf] = useState(assetsModellingInitialValues);
  const [devDate, setDevDate] = useState("");
  const [opDate, setOpDate] = useState("");

  const handleOnSubmit = (values) => {
    // console.log("submitVal", values);
    let submitValues = {
      ...values,
      // id: id,
      projectNameValue: projectNameValue,
      brandedResidenceInclusion: values === "1" ? true : false,
    };
    if (type === "Standard") {
      // console.log("standard type");
      //  if(submitValues?.roomRate){
      //   delete submitValues.roomRate
      //   delete submitValues.weightedRoomRate
      //  }
      submitValues = {
        ...submitValues,
        incentiveFeeGOPLessThanPercent1:
          submitValues.incentiveFee[0].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent2:
          submitValues.incentiveFee[1].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent3:
          submitValues.incentiveFee[2].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent4:
          submitValues.incentiveFee[3].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent5:
          submitValues.incentiveFee[4].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent6:
          submitValues.incentiveFee[5].ifGOPLessThanPercent,
        incentiveFeePercentOfGOP1: submitValues.incentiveFee[0].feePercentOfGOP,
        incentiveFeePercentOfGOP2: submitValues.incentiveFee[1].feePercentOfGOP,
        incentiveFeePercentOfGOP3: submitValues.incentiveFee[2].feePercentOfGOP,
        incentiveFeePercentOfGOP4: submitValues.incentiveFee[3].feePercentOfGOP,
        incentiveFeePercentOfGOP5: submitValues.incentiveFee[4].feePercentOfGOP,
        incentiveFeePercentOfGOP6: submitValues.incentiveFee[5].feePercentOfGOP,
        roomRate: [],
        weightedRoomRate: [],
      };
      delete submitValues.incentiveFee;
    }
    if (type === "Package") {
      // console.log("package type");
      let newRoomRate = [];
      submitValues.roomRate.forEach((el) => {
        newRoomRate.push({
          packageTypeLookup: el.id,
          rates: el.year,
        });
      });

      submitValues = {
        ...submitValues,
        roomRate: newRoomRate,
        incentiveFeeGOPLessThanPercent1:
          submitValues.incentiveFee[0].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent2:
          submitValues.incentiveFee[1].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent3:
          submitValues.incentiveFee[2].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent4:
          submitValues.incentiveFee[3].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent5:
          submitValues.incentiveFee[4].ifGOPLessThanPercent,
        incentiveFeeGOPLessThanPercent6:
          submitValues.incentiveFee[5].ifGOPLessThanPercent,
        incentiveFeePercentOfGOP1: submitValues.incentiveFee[0].feePercentOfGOP,
        incentiveFeePercentOfGOP2: submitValues.incentiveFee[1].feePercentOfGOP,
        incentiveFeePercentOfGOP3: submitValues.incentiveFee[2].feePercentOfGOP,
        incentiveFeePercentOfGOP4: submitValues.incentiveFee[3].feePercentOfGOP,
        incentiveFeePercentOfGOP5: submitValues.incentiveFee[4].feePercentOfGOP,
        incentiveFeePercentOfGOP6: submitValues.incentiveFee[5].feePercentOfGOP,
        weightedAverageDailyRate: [],
        averageDailyRate: [],
      };
      delete submitValues.incentiveFee;
    }

    // console.log("submitVal final", submitValues);
    PostAssetListModel(projectNameValue, submitValues)
      .then((res) => {
        // console.log("reees post by id", res.data.success);
        if (res?.status == 409) {
          setErrorMessage(
            "The production version has been updated, please perform your changes on the latest version"
          );
          onErrorOpen();
        } else if (res.success) {
          onClose();
          loadModelData();
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage("Something went wrong");
        onErrorOpen();
      });
  };

  const setAssetDataForm = () => {
    if (!!assetsData) {
      let {
        departmentalExpenses,
        operatorTermsAndStaffing,
        developmentCostValues,
        financingAssumptions,
        brandedResidencesPerformance,
      } = assetsData;
      let topLineAssumpType;
      let newOccRate;

      let newRoomRate = [];
      if (type === "Standard") {
        topLineAssumpType = assetsData.topLineAssumptions_Standard;
        newOccRate = topLineAssumpType.roomTypes.map((el) => el.occupancyRate);
      } else if (type === "Package") {
        topLineAssumpType = assetsData.topLineAssumptions_Package;
        newOccRate = topLineAssumpType.packageTypes.map(
          (el) => el.occupancyRate
        );

        assetsData.topLineAssumptions_Package.roomRate.forEach((el) => {
          newRoomRate = [
            ...newRoomRate,
            {
              type: el?.packageTypeLookup,
              id: el?.packageTypeLookup,
              year: el?.rates,
            },
          ];
        });
      }
      let incentiveFee = [];
      incentiveFee.push({
        ifGOPLessThanPercent:
          operatorTermsAndStaffing.managementFees
            .incentiveFeeGOPLessThanPercent1,
        feePercentOfGOP:
          operatorTermsAndStaffing.managementFees.incentiveFeePercentOfGOP1,
      });
      incentiveFee.push({
        ifGOPLessThanPercent:
          operatorTermsAndStaffing.managementFees
            .incentiveFeeGOPLessThanPercent2,
        feePercentOfGOP:
          operatorTermsAndStaffing.managementFees.incentiveFeePercentOfGOP2,
      });
      incentiveFee.push({
        ifGOPLessThanPercent:
          operatorTermsAndStaffing.managementFees
            .incentiveFeeGOPLessThanPercent3,
        feePercentOfGOP:
          operatorTermsAndStaffing.managementFees.incentiveFeePercentOfGOP3,
      });
      incentiveFee.push({
        ifGOPLessThanPercent:
          operatorTermsAndStaffing.managementFees
            .incentiveFeeGOPLessThanPercent4,
        feePercentOfGOP:
          operatorTermsAndStaffing.managementFees.incentiveFeePercentOfGOP4,
      });
      incentiveFee.push({
        ifGOPLessThanPercent:
          operatorTermsAndStaffing.managementFees
            .incentiveFeeGOPLessThanPercent5,
        feePercentOfGOP:
          operatorTermsAndStaffing.managementFees.incentiveFeePercentOfGOP5,
      });
      incentiveFee.push({
        ifGOPLessThanPercent:
          operatorTermsAndStaffing.managementFees
            .incentiveFeeGOPLessThanPercent6,
        feePercentOfGOP:
          operatorTermsAndStaffing.managementFees.incentiveFeePercentOfGOP6,
      });
      // console.log("incentiveFee", incentiveFee);

      setDevDate(new Date(assetsData.developmentTimeStartDate).getFullYear());
      setOpDate(new Date(assetsData.operationStartDate).getFullYear());
      // console.log("roomRate", newRoomRate);
      let addModelObj = {
        //...initConf,
        assetId: id,
        label: "Model",
        isActive: false,
        // expenseLevel: departmentalExpenses.expenseLevel,
        baseFee: operatorTermsAndStaffing.managementFees.baseFee,
        percentageOfBaseCost:
          developmentCostValues.breakdown.percentageOfBaseCost,
        economiesOfScaleApplied: departmentalExpenses.economiesOfScaleApplied,

        incentiveFee: incentiveFee,

        franchiseFee: operatorTermsAndStaffing.managementFees.franchiseFee,
        includeFranchiseFee: operatorTermsAndStaffing.managementFees.includeFranchiseFee,
        percentageCap: operatorTermsAndStaffing.managementFees.percentageCap,
        revenueType: operatorTermsAndStaffing.managementFees.revenueType,
        franchiseFeeLabel: operatorTermsAndStaffing.managementFees.franchiseFeeLabel,
        //occupancyStabilized: "", //where is that
        occupancyIncreaseRate: newOccRate,
        numberOfKeys: assetsData.numberOfKeys,
        developmentCostPerKey_SAR:
          developmentCostValues.general.developmentCostPerKey_SAR,
        contingencyCost: developmentCostValues.breakdown.contingency.cost,
        NEOMDevelopmentFeeCost:
          developmentCostValues.breakdown.NEOMDevelopmentFee.cost,
        preOpeningCost: developmentCostValues.breakdown.preOpening.cost,
        annualInterestRate: financingAssumptions.debt.annualInterestRate,
        equity: financingAssumptions.general.equity,

        ebidtaMultiple: financingAssumptions.terminalValue.ebidtaMultiple,
        brandedResidenceInclusion:
          assetsData.brandedResidencesGeneral.isOnOff === true ? "1" : "2",

        staffToKeyRatio: operatorTermsAndStaffing.staffing.staffToKeyRatio,
        staffHousingCostPerStaff: operatorTermsAndStaffing.staffing.staffHousingCostPerStaff,
        reservationContribution: operatorTermsAndStaffing.otherFees.reservationContribution,
        reservationContributionRevenueType: operatorTermsAndStaffing.otherFees.reservationContributionRevenueType,
        reservationContributionLabel: operatorTermsAndStaffing.otherFees.reservationContributionLabel,
        GFAEfficiency: assetsData.brandedResidencesGeneral.GFAEfficiency,
        otherExpensesRoom: {
          value: departmentalExpenses.assumptions.rooms.other.value,
          fixed: departmentalExpenses.assumptions.rooms.other.fixed,
        },
      };

      if (type === "Standard") {
        addModelObj = {
          ...addModelObj,
          weightedAverageDailyRate: topLineAssumpType.weightedAverageDailyRate,
          averageDailyRate: topLineAssumpType.averageDailyRate,
        };
      }
      if (type === "Package") {
        addModelObj = {
          ...addModelObj,
          roomRate: newRoomRate,
          weightedRoomRate: topLineAssumpType.weightedRoomRate,
        };
      }
      setInitConf(addModelObj);
    }
  };

  useEffect(() => {
    setAssetDataForm();
  }, [checkedItems]);

  return (
    <div>
      <Formik
        initialValues={initConf}
        enableReinitialize={true}
        validationSchema={assetModellingValidation[0]}
      >
        {({ errors, touched, values, handleBlur, setFieldValue }) => (
          <Form className="form_wrapper">
            <>
              <Box
                maxW={["100%", "100%", "60%"]}
                width={["100%", "100%", "60%"]}
                alignSelf="flex-start"
              >
                <GenerateModelFormBody
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  checkedItems={checkedItems}
                  devDate={devDate}
                  opDate={opDate}
                  setInitConf={setInitConf}
                  type={type}
                  packageTypeArr={packageTypeArr}
                />

                <Box paddingY={4}>
                  <MainButton
                    title="Generate Model"
                    reverseRow={true}
                    disabledBtn={checkedItems.length === 0}
                    onPress={() => {
                      // console.log("errors", errors);
                      if (Object.keys(errors).length === 0)
                        handleOnSubmit(values);
                      else {
                        onMissingOpen();
                      }
                    }}
                  />
                </Box>
              </Box>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
};
