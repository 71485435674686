import React from "react";

import { ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import { Box, Text } from "@chakra-ui/react";

import styles from "../forms.module.scss";

// - You can hide the label throw putting labelVisibility to false
export const DatePickerField = ({
  label,
  inputId,
  inputName,
  isRequired = true,
  labelVisibility = true,
  errors,
  touched,
  dColumn = false,
  minDate,
  maxDate,
  fullWidth = "",
  icon,
  hintText,
  values,
  onBlur,
  onChange,
  uppercase = true,
  format = "year",
  isDisable,
  handleClose,
  onChange2,
  isAssetField,
  validateField = false,
  isFormDirty,
  setIsFormDirty,
}) => {
  let validateFieldLogic = validateField ? values === "" : false;

  const errorInputClassname =
    (errors[inputName] && touched[inputName] && errors[inputName]) ||
    validateFieldLogic
      ? `${styles.form_control} ${styles.form_control_error} ${
          dColumn ? "flex_column" : ""
        }`
      : `${styles.form_control} ${dColumn ? "flex_column" : ""}`;

  let formInputClassname = labelVisibility
    ? `${styles.form_control_input} ${fullWidth}`
    : `${styles.form_control_input} ${styles.form_control_full}  ${fullWidth}`;

  formInputClassname +=
    format === "year" ? `datepicker_year` : `datepicker_year_month`;

  let labelUppercaseClassname = uppercase ? `${styles.uppercase}` : "";

  const renderDatePicker = () => {
    if (format === "year") {
      return (
        <DatePicker
          id={inputId}
          name={inputName}
          onBlur={onBlur}
          selected={(values && new Date(values)) || null}
          onChange={(val) => {
            if (setIsFormDirty && !isFormDirty) {
              setIsFormDirty(true);
            }
            onChange(inputName, val);
          }}
          dateFormat="yyyy"
          minDate={minDate}
          maxDate={maxDate}
          showYearPicker
          disabled={isDisable}
        />
      );
    } else if (format === "yearMonth") {
      return (
        <>
          <DatePicker
            onCalendarClose={handleClose}
            id={inputId}
            name={inputName}
            onBlur={onBlur}
            selected={(values && new Date(values)) || null}
            onChange={(val) => {
              if (setIsFormDirty && !isFormDirty) {
                setIsFormDirty(true);
              }
              onChange(inputName, val);
              if (isAssetField) {
                onChange2(inputName, val);
              }
            }}
            dateFormat="MM/yyyy"
            minDate={minDate}
            maxDate={maxDate}
            showMonthYearPicker
            disabled={isDisable}
          />
        </>
      );
    }
  };

  return (
    <div className={errorInputClassname}>
      {labelVisibility && (
        <label
          htmlFor={inputId}
          className={`${fullWidth} ${labelUppercaseClassname}`}
        >
          {label}
        </label>
      )}
      <div className={formInputClassname}>
        {renderDatePicker()}

        {icon && (
          <Box className="iconic_input">
            <img src={icon} alt="date" />
          </Box>
        )}
        {isRequired && (
          <ErrorMessage
            name={inputName}
            component="div"
            className={styles.error}
          />
        )}
        {hintText && (
          <Text mt="2" mb="3" className="hint_sm">
            {hintText}
          </Text>
        )}
      </div>
    </div>
  );
};
