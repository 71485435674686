import React, { useState, useRef, useEffect, useCallback } from "react";

import { Box, Flex, Divider, Checkbox, Text } from "@chakra-ui/react";

import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import { MainButton } from "../../components/button/MainButton";
import { TextArea } from "../../components/textArea/TextArea";
import { MainTitle } from "../../components/mainTitle/MainTitle";

import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import userIcon from "../../assets/images/icons/user.svg";

export const AssetsListForm11 = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  handleOnSubmit,
  handleProponentOnSubmit,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  steps,
  handleMissingPages,
  missingPages,
  onMissingOpen,
  assetData,
  // firstInitFormVal,
  reassign,
  validateStep,
  setValidateStep,
  user,
  updateDisableNextStep,
  prevStep,
  closeModalPath,
  setSubmitLoading,
  getUsers,
  setInitFormVal,
  isFormDirty,
  setIsFormDirty,
}) => {
  const approveRef = useRef(null);
  const dataFetchedRef = useRef(false);
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState("");
  let form11Data = [];
  form11Data.push(formikValues.assignTo); //string
  form11Data.push(formikValues.approveAssetInfo); //string

  let emptyform11Data = [];
  form11Data.forEach((item, ind) => {
    if (ind === 1) {
      if (item != false && item !== "") {
        emptyform11Data.push(item);
      }
    } else {
      if (item !== "") {
        let filterUser = dropdownObj?.users?.filter((el) => item === el.value);
        // console.log("filter", filterUser);
        if (filterUser?.length != 0) {
          emptyform11Data.push(item);
        }
      }
    }
  });
  // console.log("Form Is Dirty", isFormDirty);
  let isDisabledForm = false;

  let isDisabledFormProd = false;
  // let isDisableSubmitProd = false
  let isDirty;
  let status = assetData?.status === "Production";
  if (status) {
    if (
      emptyform11Data.length < form11Data.length - 1 &&
      (form11Data[1] === false || form11Data[1] === "")
    ) {
      isDisabledForm = true;
    }

    isDirty = !isFormDirty;

    isDisabledFormProd =
      isDisabledForm === false && !isDirty === true ? false : true;
  } else {
    if (
      emptyform11Data.length < form11Data.length - 1 &&
      (form11Data[1] === false || form11Data[1] === "")
    ) {
      isDisabledForm = true;
    }
  }

  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }
  const handleAssigneeSelect = () => {
    if (formikValues?.approveAssetInfo) {
      setIsCheckBoxSelected([false]);
      setFieldValue("approveAssetInfo", false);
    }
  };
  const handleCheckBox = useCallback((e) => {
    // console.log("e", e.target.checked);
    if (setIsFormDirty && !isFormDirty) {
      setIsFormDirty(true);
    }
    setIsCheckBoxSelected([e.target.checked]);
    setFieldValue("approveAssetInfo", e.target.checked);
    if (formikValues.assignTo !== "") {
      setFieldValue("assignTo", "");
    }
  }, []);
  // console.log("formikVal", formikValues);
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    handleMissingPages();
    if (getUsers) {
      getUsers(formikValues?.regionLookup);
    }
  }, []);

  const [count, setCount] = React.useState(
    formikValues && formikValues.addComment ? formikValues.addComment.length : 0
  );
  const handleComment = (inputNamme, value) => {
    if (value.length <= 255) {
      setCount(value.length);
      setFieldValue("addComment", value);
    }
  };

  // console.log("formikValues", formikValues.approveAssetInfo);
  const handleApproveStatus = () => {
    if (
      formikValues.devCostPerKey === "" ||
      formikValues.landCost === "" ||
      formikValues.infraCost === "" ||
      formikValues.parkingCost === "" ||
      formikValues.bDownConsCost === "" ||
      formikValues.bDownConsCost > 100 ||
      formikValues.bDownFfeOse === "" ||
      formikValues.bDownFfeOse > 100 ||
      formikValues.bDownSoftCost === "" ||
      formikValues.bDownSoftCost > 100 ||
      formikValues.bDownPreOpenTM === "" ||
      formikValues.bDownPreOpenTM > 100 ||
      formikValues.bDownTsaFee === "" ||
      formikValues.bDownNeomDevFee === "" ||
      formikValues.bDownNeomDevFee > 100 ||
      formikValues.bDownContCost === "" ||
      formikValues.bDownContCost > 100 ||
      formikValues.bDownIniWorkCapital === "" ||
      formikValues.bDownIniWorkCapital > 100 ||
      formikValues.baseCost === "" ||
      formikValues.baseCost > 100 /* ||
      formikValues.refurbishmentCost === "" ||
      formikValues.refurbishmentYear === "" */
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <Box alignItems="flex-start" width="100%" mb={5}>
        <MainTitle title="ADDITIONAL DETAILS" />
      </Box>
      <Box
        maxW={["100%", "100%", "60%"]}
        width={["100%", "100%", "60%"]}
        alignSelf="flex-start"
      >
        {(user === "admin" || user == "manager") && (
          <Box pb="5">
            <SelectListMulti
              key={`myUniqueKey_${formikValues?.assignTo}`}
              dropdownItems={dropdownObj.users}
              // dropdownItems={[
              //   { label: "emad", value: "12313f" },
              //   { label: "hasan", value: "13513f" },
              // ]}
              label="Assign to"
              inputId="assignTo"
              inputName="assignTo"
              placeholder="Select"
              errors={errors}
              setIsFormDirty={setIsFormDirty}
              isFormDirty={isFormDirty}
              isDynamic={true}
              touched={touched}
              isRequired
              values={formikValues.assignTo}
              clearMulti={formikValues.assignTo}
              onChange={setFieldValue}
              onChange2={() => handleAssigneeSelect()}
              onBlur={handleBlur}
              uppercase={true}
              icon={userIcon}
              iconArrow={false}
              placeH="Add assignee by typing a name or email address"
              isDisabled={isRestore}
              isClearable={true}
              validateField={
                isCheckBoxSelected[0] === false || isCheckBoxSelected === ""
                  ? validateStep
                  : ""
              }
            />
          </Box>
        )}

        <TextArea
          label="ADD COMMENT"
          inputId="addComment"
          inputName="addComment"
          placeholder="Add description"
          errors={errors}
          setIsFormDirty={setIsFormDirty}
          isFormDirty={isFormDirty}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={handleComment}
          onBlur={handleBlur}
          isDisabled={user === "manager"}
          validateField={validateStep}
        />
        <Box>{count}/255</Box>
      </Box>
      {/* COMMENTS HISTORY */}
      {/* <Text fontSize="12" alignSelf="flex-start">
        COMMENTS HISTORY
      </Text>
      <Box mt="3" mb="10" w="50%" alignSelf="flex-start" p="5">
        <HStack gap="3" s>
          <Image src={Personas2}></Image>
          <VStack w="100%" fontSize="14" alignItems="left">
            <HStack>
              <Text color="#1A1D1F">Stephan Smith</Text>
              <Spacer />
              <Text color="#6F767E" fontSize="12">
                21.09.2022; 09:28
              </Text>
            </HStack>
            <Text fontSize="15" color="#1D1F22">
              Development cost appears fine.
            </Text>
          </VStack>
        </HStack>

        <Divider orientation="horizontal" mt="20px" mb="30px" />

        <HStack gap="3" s>
          <Image src={Personas}></Image>
          <VStack w="100%" fontSize="14" alignItems="left">
            <HStack>
              <Text color="#1A1D1F">Peter Griffin</Text>
              <Spacer />
              <Text color="#6F767E" fontSize="12">
                19.09.2022; 12:32
              </Text>
            </HStack>
            <Text fontSize="15" color="#1D1F22">
              Initial version reated. Request Sarah to add asset data.
            </Text>
          </VStack>
        </HStack>
      </Box> */}
      {/* COMMENTS HISTORY */}

      {user === "admin" && (
        <Box bgColor="#FCFCFC" py={5} px={5} width="100%" mt={20}>
          {formikValues?.approveAssetInfo && (
            <Checkbox
              ref={approveRef}
              colorScheme="blue"
              className="checkbox_input"
              name="approveAssetInfo"
              onChange={handleCheckBox}
              value={formikValues.approveAssetInfo}
              defaultChecked={formikValues.approveAssetInfo}
              isDisabled={handleApproveStatus()}
            >
              {/* defaultChecked */}
              <Text letterSpacing="0.06em">Approve asset information</Text>
            </Checkbox>
          )}
          {!formikValues?.approveAssetInfo && (
            <Checkbox
              ref={approveRef}
              colorScheme="blue"
              className="checkbox_input"
              name="approveAssetInfo"
              onChange={handleCheckBox}
              value={formikValues.approveAssetInfo}
              defaultChecked={formikValues.approveAssetInfo}
              isDisabled={handleApproveStatus()}
            >
              {/* defaultChecked */}
              <Text letterSpacing="0.06em">Approve asset information</Text>
            </Checkbox>
          )}
        </Box>
      )}

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex
        justifyContent={user == "manager" ? "end" : "space-between"}
        alignItems="center"
        width="100%"
      >
        {user !== "manager" ? (
          <MainButton
            title="Save Draft"
            btnStyle="transparent"
            disabledBtn={isRestore || user === "proponent"}
            fontColor="dark"
            onPress={() => {
              setDismissSave(false);
              onDraftOpen();
            }}
          />
        ) : (
          <></>
        )}
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                if (user == "proponent") {
                  prevPage(1);
                } else if (updateDisableNextStep || prevStep.clicked === false)
                  prevPage(2);
                else prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />

            {user !== "manager" ? (
              status ? (
                isDisabledFormProd ? (
                  <MainButton
                    title={isRestore ? "Restore" : "Submit"}
                    reverseRow={true}
                    disabledBtn={isDirty}
                    onPress={() => {
                      setValidateStep(true);
                      document.getElementById("main_content").scrollTo(0, 0);
                    }}
                  />
                ) : (
                  <MainButton
                    title={isRestore && user == "admin" ? "Restore" : "Submit"}
                    disabledBtn={isRestore && user == "proponent"}
                    reverseRow={true}
                    onPress={() => {
                      //console.log("formikVals", formikValues);
                      // console.log("user", user);
                      if (user === "admin") {
                        if (missingPages.length > 0) {
                          onMissingOpen();
                        } else {
                          //this mean we just edit the assign to field
                          // && assetData.assignedTo !== formikValues.assignTo
                          if (
                            !isFormDirty &&
                            formikValues.assignTo !== "" &&
                            assetData?.status !== "Archived"
                          ) {
                            // if (!isFormDirty) {
                            setSubmitLoading(true);
                            onOpenModal();
                            handleOnSubmit(
                              formikValues,
                              "assignedTo",
                              isRestore
                            );
                          } else {
                            setSubmitLoading(true);
                            onOpenModal();
                            handleOnSubmit(formikValues, "", isRestore);
                          }

                          //handleOnSubmit(formikValues);
                        }
                      } else if (user === "proponent") {
                        if (missingPages.length > 0) {
                          onMissingOpen();
                        } else {
                          setSubmitLoading(true);
                          onOpenModal();
                          handleProponentOnSubmit(formikValues);
                        }
                      }
                    }}
                  />
                )
              ) : isDisabledForm ? (
                <MainButton
                  title={isRestore && user == "admin" ? "Restore" : "Submit"}
                  reverseRow={true}
                  onPress={() => {
                    setValidateStep(true);
                    document.getElementById("main_content").scrollTo(0, 0);
                  }}
                />
              ) : (
                <MainButton
                  title={isRestore && user == "admin" ? "Restore" : "Submit"}
                  disabledBtn={isRestore && user == "proponent"}
                  reverseRow={true}
                  onPress={() => {
                    if (user === "admin") {
                      if (missingPages.length > 0) {
                        onMissingOpen();
                      } else {
                        //this mean we just edit the assign to field
                        if (
                          !isFormDirty &&
                          formikValues.assignTo !== "" &&
                          assetData?.status !== "Archived"
                        ) {
                          setSubmitLoading(true);
                          onOpenModal();
                          handleOnSubmit(formikValues, "assignedTo", isRestore);
                        } else {
                          setSubmitLoading(true);
                          onOpenModal();
                          handleOnSubmit(formikValues, "", isRestore);
                        }

                        //handleOnSubmit(formikValues);
                      }
                    } else if (user === "proponent") {
                      if (missingPages.length > 0) {
                        onMissingOpen();
                      } else {
                        setSubmitLoading(true);
                        onOpenModal();
                        handleProponentOnSubmit(formikValues);
                      }
                    }
                  }}
                />
              )
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
