import React, { forwardRef, useEffect, useState, useCallback } from "react";

import {
  GetNotifications,
  UpdateViewdNotification,
  UpdateArchiveNotification,
  UpdateClearNotification
} from "../../api/DashboardAPI";

import { NavLink, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { parseToken } from "../../utils/Auth";
import {
  Box,
  Text,
  HStack,
  Button,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  WrapItem,
  Avatar,
} from "@chakra-ui/react";
import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";
import { stringFormat } from "../../utils/General";

import styles from "./header.module.scss";

import { useIsMobile } from "../../utils/hooks";
import history from "../../history";

import logoLG from "../../assets/images/icons/NEOM_LOGO3.svg";
import notif from "../../assets/images/icons/notification.svg";
import arrowDown from "../../assets/images/icons/Cheveron.svg";
import buildings from "../../assets/images/icons/buildings.svg";
import user from "../../assets/images/icons/placeholder.svg";
import config from "../../config";
import { hi } from "date-fns/locale";

export const Header = forwardRef((props, ref) => {
  const isMobile = useIsMobile();
  const [notificationsArr, setNotificationsArr] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  let location = useLocation();
  //console.log("history", history);
  let user;
  if (Cookies.get("user_info")) {
    user = jwt_decode(Cookies.get("user_info"));
  }

  const handleNotificationClicked = (id, url, stillMenueOpen = true) => {
    setIsOpen(stillMenueOpen)
    history.push(url);

    UpdateViewdNotification(id)
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });

  };

  const handleNotificationClear = (id, clearAll = false, stillMenueOpen = true) => {
    setIsOpen(stillMenueOpen)

    UpdateClearNotification(id, clearAll)
      .then((res) => {

        GetNotifications()
          .then((res) => {

            // console.log("reees get notification", res);
            let notificationsUnread = res.data.filter((el) => el.isUnread && !el.isCleared && !el.isArchived)
            setNotificationsArr(notificationsUnread);
          })
          .catch((err) => {
            console.log(err);
            //setErrorMessage("Something went wrong");
            //onErrorOpen();
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderNotifications = () => {
    if (!!notificationsArr && notificationsArr.length > 0) {
      return notificationsArr.map((notif, ind) => {
        let date = dateFnsParseISO(notif.createdAt);
        date = dateFnsFormat(date, "dd.MM.yyyy; HH:mm");
        // console.log("notif.actionUrl", notif.actionUrl);
        let actionLabel = !!notif.actionLabel ? notif.actionLabel : "Preview";
        let notifUrlId;
        let notifUrl;
        let newNotifUrl;
        if (
          `${notif.actionUrl}`.includes("Standard") ||
          `${notif.actionUrl}`.includes("Package")
        ) {
          newNotifUrl = `/AssetList${notif.actionUrl}`;
        } else {
          notifUrlId = `${notif.actionUrl}`?.split("/")?.pop();
          notifUrl = `${notif.actionUrl}`?.split("/")[1];
          // modifiedUrl = notifUrl.replace(/([A-Z])/g, " $1").replace(/^./, function(str){ return str.toUpperCase()})
          if (
            `${notif.actionUrl}`.includes("roomType") ||
            `${notif.actionUrl}`.includes("positioning") ||
            `${notif.actionUrl}`.includes("brand")
          ) {
            newNotifUrl = `/MasterDataCustom/${stringFormat(
              notifUrl
            )}/${notifUrlId}`;
          } else {
            newNotifUrl = `/MasterData/${stringFormat(notifUrl)}/${notifUrlId}`;
          }
        }
        // console.log("notif.actionUrl --2--", newNotifUrl);
        return (
          <MenuItem
            px="10px"
            background={!notif.isUnread && "#E2E8F0"}
            key={ind}
          >
            <Box

            >
              <Box display="flex" alignItems="center">
                <Box
                  borderRadius={50}
                  bg="rgba(174, 159, 206, 0.35)"
                  width="30px"
                  height="30px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mr="5px"
                >
                  <img src={buildings} width="16px" alt="icon" />
                </Box>
                <Box ml={1} flex="1" >
                  <Text className="hint_sm" onClick={(e) => handleNotificationClicked(notif.id, newNotifUrl, false)}>{date} </Text>
                  <Text className="title_sm" onClick={(e) => handleNotificationClicked(notif.id, newNotifUrl, false)}>{notif.notificationText}</Text>
                  <Box
                    bg="transparent"
                    color="#007BB5"
                    px="0px"
                    className="title_sm"
                    fontWeight="400"
                    display="flex"
                    justifyContent="space-between"

                  >
                    <Text onClick={(e) => handleNotificationClicked(notif.id, newNotifUrl, false)} id="preview" color="#007BB5" className="title_sm textUnderLine">{actionLabel}</Text>

                    <Text onClick={(e) => handleNotificationClear(notif.id, null, true)} id="read" color="#007BB5" className="title_sm textUnderLine">Clear</Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </MenuItem>
        );
      });
    } else {
      return (
        <Box paddingTop={12} paddingBottom={12}>
          <Center>
            <Text>You have no notifications.</Text>
          </Center>
        </Box>
      );
    }
  };

  const loadNotification = useCallback(() => {
    GetNotifications()
      .then((res) => {
        // console.log("reees get notification", res);
        let notificationsUnread = res.data.filter((el) => el.isUnread && !el.isCleared && !el.isArchived)
        setNotificationsArr(notificationsUnread);
      })
      .catch((err) => {
        console.log(err);
        //setErrorMessage("Something went wrong");
        //onErrorOpen();
      });
  }, []);

  useEffect(() => {
    loadNotification();
  }, [loadNotification]);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      //console.log("re render notification:", location.pathname);
      loadNotification();
    });
    return () => unlisten();
  }, [history, location.pathname]);

  /*  useEffect(() => {
    GetNotifications()
      .then((res) => {
        console.log("reees get notification", res);
        setNotificationsArr(res.data);
      })
      .catch((err) => {
        console.log(err);
        //setErrorMessage("Something went wrong");
        //onErrorOpen();
      });
  }, []); */

  return (
    <nav className={styles.navbar} ref={ref}>
      <HStack justifyContent='space-between' spacing="auto" width="100%" flexDirection="row">
        <Box display="flex" alignItems="center">
          <NavLink to={"/"}>
            <img src={logoLG} width="65px" alt="logo" />
          </NavLink>
          {!isMobile ? (
            <Box
              display="flex"
              alignItems="center"
              /* pl="10px" */ pl="5px"
              pe={3}
            >
              <div className="devider_vertical"></div>
            </Box>
          ) : null}
          {!isMobile ? (
            <Text className="login_title_head">Asset Dashboard</Text>
          ) : null}
        </Box>
        <Box display="flex">
          <Box className="btn_notif">
            {(user?.roles[0]?.toLowerCase() === "admin" ||
              user?.roles[0]?.toLowerCase() === "proponent") && (
                <Menu closeOnSelect={false} autoSelect={false} >
                  <MenuButton as={Button} bg="transparent" onClick={() => setIsOpen(true)}>
                    <Box position="relative" onClick={() => setIsOpen(true)}>
                      <img src={notif} alt="notification icon" />
                      {notificationsArr.length > 0 && (
                        <div fontWeight className="notif_badge">{notificationsArr.length > 9 ? "9+" : notificationsArr.length}</div>
                      )}
                    </Box>
                  </MenuButton>
                  {isOpen && (<MenuList className="notif_dropdown">
                    <Box display="flex"
                      justifyContent="space-between">
                      <Text className="title_lg" px="10px" mb="10px">
                        Notifications
                      </Text>
                      {
                        notificationsArr.length > 0 ? <Text cursor="pointer" onClick={(e) => handleNotificationClear(null, true, true)} color="#007BB5" className="title_sm textUnderLine" px="10px" mb="10px">
                          Clear All
                        </Text> : ''
                      }


                    </Box>


                    {/* <Text textAlign="right" className="title_lg" px="10px" mb="10px">
                      Clear all */}
                    {/* </Text> */}
                    <Box className="notif_dropdown_body">
                      {renderNotifications()}
                    </Box>

                    <Center mt="10px" px="10px">
                      <NavLink
                        className="w_100 text_center"
                        end
                        to="/Notifications"
                      >
                        <MenuItem
                          className="btn_primary w_100 text_center"
                          justifyContent="center"
                          display="flex"
                          onClick={() => setIsOpen(false)}
                        >
                          View All Notifications
                        </MenuItem>
                      </NavLink>
                    </Center>
                  </MenuList>
                  )}
                </Menu>
              )}
          </Box>
          <Box className="btn_profile">
            <Menu autoSelect={false}>
              <MenuButton
                as={Button}
                bg="transparent"
                rightIcon={<img src={arrowDown} alt="arrow icon" />}
              >
                <WrapItem>
                  <Avatar
                    name={user?.displayName?.charAt(0)}
                    src="/"
                    key="user_1"
                    size="sm"
                    background="#bdc6d1"
                  />
                </WrapItem>
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    Cookies.remove("user_info");
                    window.location.href = config.api.logoutBaseURL;
                  }}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Box>
      </HStack>
    </nav>
  );
});
