import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  IconButton,
  Image,
  Tooltip,
  Spinner,
  Center,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import leftArrow3 from "../../assets/images/icons/leftArrow3.svg";
import { getCalculatedOverview } from "../../api/AssetListAPI";
import Select from "react-select";
import { useSelector } from "react-redux";
import downloadIcon from "../../assets/images/icons/download.svg";
import * as XLSX from "xlsx";

export const KpiTemplate = ({ back }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totals, setTotals] = useState({});
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    region: [],
    position: [],
    brand: [],
  });
  const [selectedColumns, setSelectedColumns] = useState([]);
  const regionList = useSelector((state) => state.approval.region);
  const positionList = useSelector((state) => state.approval.position);
  const brandList = useSelector((state) => state.approval.brand);

  useEffect(() => {
    getCalculatedOverview().then((response) => {
      setData(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const filteredData = applyFilters(data, filters);
    const calculatedTotals = calculateTotals(filteredData);
    setFilteredData(filteredData);
    setTotals(calculatedTotals);
  }, [filters, data]);

  const calculateTotals = (data) => {
    const totalKeys = data.reduce(
      (sum, row) => sum + (parseFloat(row.numberOfKeys) || 0),
      0
    );

    const totalAdrSar =
      totalKeys > 0
        ? data.reduce((sum, row) => {
            const adr = parseFloat(row.adrSAR);
            const keys = parseFloat(row.numberOfKeys);
            return sum + (isNaN(adr) || isNaN(keys) ? 0 : adr * keys);
          }, 0) / totalKeys
        : 0;

    const totalAdrUsd = totalAdrSar / 3.75;
    const totalOccupancyRate =
      totalKeys > 0
        ? data.reduce((sum, row) => {
            const occupancyRate = parseFloat(row.occupancyRate);
            const keys = parseFloat(row.numberOfKeys);
            return (
              sum +
              (isNaN(occupancyRate) || isNaN(keys) ? 0 : occupancyRate * keys)
            );
          }, 0) /
          totalKeys
        : 0;
    const totalRevParSar = totalOccupancyRate * totalAdrSar;

    const totalRoomsRevenue =
      data.reduce((sum, row) => sum + (parseFloat(row.roomsRevenue) || 0), 0) /
        data.length || 0;

    const totalFoodBeverageRevenue =
      data.reduce(
        (sum, row) => sum + (parseFloat(row.foodAndBeverageRevenue) || 0),
        0
      ) / data.length || 0;

    const totalOtherOperatingDepartmentsRevenue =
      data.reduce(
        (sum, row) =>
          sum + (parseFloat(row.otherOperatingDepartmentsRevenue) || 0),
        0
      ) / data.length || 0;

    const totalGrossOperatingProfit =
      data.reduce(
        (sum, row) => sum + (parseFloat(row.grossOperatingProfit) || 0),
        0
      ) / data.length || 0;

    const totalEbitda =
      data.reduce((sum, row) => sum + (parseFloat(row.ebitda) || 0), 0) || 0;

    const totalEbitdaPercent =
      data.reduce((sum, row) => sum + (parseFloat(row.ebitdaPercent) || 0), 0) /
        data.length || 0;

    const totalEbit =
      data.reduce((sum, row) => sum + (parseFloat(row.ebit) || 0), 0) || 0;

    const totalEbitPercent =
      data.reduce((sum, row) => sum + (parseFloat(row.ebitPercent) || 0), 0) /
        data.length || 0;
      
    const totalGfa =  data.reduce(
      (sum, row) => sum + (parseFloat(row.gfa) || 0),
      0
    );

    return {
      totalKeys,
      totalAdrSar,
      totalAdrUsd,
      totalRevParSar,
      totalOccupancyRate,
      totalRoomsRevenue,
      totalFoodBeverageRevenue,
      totalOtherOperatingDepartmentsRevenue,
      totalGrossOperatingProfit,
      totalEbitda,
      totalEbitdaPercent,
      totalEbit,
      totalEbitPercent,
      totalGfa
    };
  };

  const applyFilters = (data, filters) => {
    let filteredData = [...data];
    if (filters.region.length > 0) {
      filteredData = filteredData.filter((asset) =>
        filters.region.some((region) => region.value === asset.regionLookup)
      );
    }
    if (filters.position.length > 0) {
      filteredData = filteredData.filter((asset) =>
        filters.position.some(
          (position) => position.value === asset.positioningLookup
        )
      );
    }
    if (filters.brand.length > 0) {
      filteredData = filteredData.filter((asset) =>
        filters.brand.some((brand) => brand.value === asset.brandLookup)
      );
    }
    return filteredData;
  };

  const formatNumber = (num) => {
    if (num === null || num === undefined || num === "-") return "-";
    return Math.round(num)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatPercentage = (num) => {
    if (num === null || num === undefined || num === "-") return "-";
    return `${Math.round(num * 100)}%`;
  };

  

 const colMap ={
  "region": "Region",
  "status": "Approval Status",
  "name": "Name",
  "positioning": "Positioning",
  "brand": "Brand",
  "numberOfKeys": "Number of Keys",
  "adrSAR": "ADR (SAR)",
  "adrUSD": "ADR (USD)",
  "revPAR": "RevPAR (SAR)",
  "occupancyRate": "Occupancy Rate",
  "occupiedRooms": "Occupied Rooms (SAR)",
  "foodAndBeveragePOR": "Food and Beverage POR (SAR)",
  "otherOperatingDepartmentsPOR": "Other Operating Departments POR (SAR)",
  "roomsRevenue": "Rooms Revenue",
  "foodAndBeverageRevenue": "Food and Beverage Revenue",
  "otherOperatingDepartmentsRevenue": "Other Operating Departments Revenue",
  "grossOperatingProfit": "Gross Operating Profit",
  "ebitda": "EBITDA (SAR)",
  "ebitdaPercent": "EBITDA %",
  "ebit": "EBIT (SAR)",
  "ebitPercent": "EBIT %",
  "gfa":'Gross Floor Area (SQM)',
  "capex": "Total Capex (SAR)",
  "capexPerKey": "Capex/Key (SAR)",
  "irr": "IRR",
 }
  const keyColumns = [
    { name: "Region", key: "region" },
    { name: "Name", key: "name" },
    { name: "Approval Status", key: "status" },
    { name: "Positioning", key: "positioning" },
    { name: "Brand", key: "brand" },
    { name: "Number of Keys", key: "numberOfKeys", format: formatNumber },
    { name: "ADR (SAR)", key: "adrSAR", format: formatNumber },
    { name: "ADR (USD)", key: "adrUSD", format: formatNumber },
    { name: "RevPAR (SAR)", key: "revPAR", format: formatNumber },
    { name: "Occupancy Rate", key: "occupancyRate", format: formatPercentage },
    { name: "Occupied Rooms (SAR)", key: "occupiedRooms", format: formatNumber },
    {
      name: "Food and Beverage POR (SAR)",
      key: "foodAndBeveragePOR",
      format: formatNumber,
    },
    {
      name: "Other Operating Departments POR (SAR)",
      key: "otherOperatingDepartmentsPOR",
      format: formatNumber,
    },
    { name: "Rooms Revenue", key: "roomsRevenue", format: formatPercentage },
    {
      name: "Food and Beverage Revenue",
      key: "foodAndBeverageRevenue",
      format: formatPercentage,
    },
    {
      name: "Other Operating Departments Revenue",
      key: "otherOperatingDepartmentsRevenue",
      format: formatPercentage,
    },
    {
      name: "Gross Operating Profit",
      key: "grossOperatingProfit",
      format: formatPercentage,
    },
    { name: "EBITDA (SAR)", key: "ebitda", format: formatNumber },
    { name: "EBITDA %", key: "ebitdaPercent", format: formatPercentage },
    { name: "EBIT (SAR)", key: "ebit", format: formatNumber },
    { name: "EBIT %", key: "ebitPercent", format: formatPercentage },
    { name: "Gross Floor Area (SQM)", key: "gfa", format: formatNumber },
    { name: "Total Capex (SAR)", key: "capex", format: formatNumber },
    { name: "Capex/Key (SAR)", key: "capexPerKey", format: formatNumber },
    { name: "IRR", key: "irr", format: formatPercentage },
  ];

  const totalRow = {
    status: "",
    region: "Total",
    positioning: "",
    brand: "",
    name:"",
    numberOfKeys: totals.totalKeys,
    adrSAR: totals.totalAdrSar,
    adrUSD: totals.totalAdrUsd,
    revPAR: totals.totalRevParSar,
    occupancyRate: totals.totalOccupancyRate,
    occupiedRooms: "-",
    foodAndBeveragePOR: "-",
    otherOperatingDepartmentsPOR: "-",
    roomsRevenue: totals.totalRoomsRevenue,
    foodAndBeverageRevenue: totals.totalFoodBeverageRevenue,
    otherOperatingDepartmentsRevenue:
      totals.totalOtherOperatingDepartmentsRevenue,
    grossOperatingProfit: totals.totalGrossOperatingProfit,
    ebitda: totals.totalEbitda,
    ebitdaPercent: totals.totalEbitdaPercent,
    ebit: totals.totalEbit,
    ebitPercent: totals.totalEbitPercent,
    gfa: totals.totalGfa
  };

  const renderDropDownItems = (data) => {
    const extractedData = data.reduce((unique, item) => {
      if (!unique.some((elem) => elem.label === item.label)) {
        unique.push({ label: item.label, value: item.value });
      }
      return unique;
    }, []);
    return extractedData.sort((a, b) => a.label.localeCompare(b.label));
  };

  const handleFilterChange = (selectedOption, filterType) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: selectedOption || [],
    }));
  };

  const handleColumnChange = (selectedOption) => {
    setSelectedColumns(selectedOption || []);
  };
  const exportToExcel = () => {
    // Get the columns to export (all columns if none are selected)
    const columnsToExport = getFilteredColumns();
  
    // Map selected columns to their keys
    const selectedColumnsKeys = columnsToExport.map((col) => col.key);
    
    const exportData = filteredData.map((row) => {
      const rowData = { 
        Region: row.region, 
        Name: row.name, 
        "Approval Status": row.status === "In Planning" ? "In Planning" : "Approved", 
        Positioning: row.positioning 
      };
  
      // Loop through each column key and add the corresponding value to the rowData object
      selectedColumnsKeys.forEach((key) => {
        if (
          key === "occupancyRate" ||
          key === "roomsRevenue" ||
          key === "foodAndBeverageRevenue" ||
          key === "otherOperatingDepartmentsRevenue" ||
          key === "grossOperatingProfit" ||
          key === "ebitdaPercent" ||
          key === "ebitPercent" ||
          key === "irr"
        ) {
          rowData[colMap[key]] = formatPercentage(row[key]);
        } else if (
          key !== "status" &&
          key !== "region" &&
          key !== "positioning" &&
          key !== "brand" &&
          key !== "name"
        ) {
          rowData[colMap[key]] = formatNumber(row[key]);
        } else {
          rowData[colMap[key]] = row[key];
        }
      });
      return rowData;
    });
    const totalRowData = { };
    selectedColumnsKeys.forEach((key) => {
      if (
        key === "occupancyRate" ||
        key === "roomsRevenue" ||
        key === "foodAndBeverageRevenue" ||
        key === "otherOperatingDepartmentsRevenue" ||
        key === "grossOperatingProfit" ||
        key === "ebitdaPercent" ||
        key === "ebitPercent" ||
        key === "irr"
      ) {
        totalRowData[colMap[key]] = formatPercentage(totalRow[key]);
      } else if (
        key !== "status" &&
        key !== "region" &&
        key !== "positioning" &&
        key !== "brand" &&
        key !== "name"
      ) {
        totalRowData[colMap[key]] = formatNumber(totalRow[key]);
      } else {
        totalRowData[colMap[key]] = totalRow[key];
      }
    });

    exportData.push(totalRowData);

    const date = new Date();
    const formattedDate = `${date.getFullYear()}${String(
      date.getMonth() + 1
    ).padStart(2, "0")}${String(date.getDate()).padStart(2, "0")}`;
    const fileName = `${formattedDate}_asset_list_KPI`;

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const columnOptions = keyColumns.map((col) => ({
    label: col.name,
    value: col.key,
  }));

  for (let i = 0; i < 4; i++) {
    columnOptions.shift();
  }

  const getFilteredColumns = () => {
    if (selectedColumns.length === 0) {
      return keyColumns; // Default columns if none are selected
    }

    const selectedColumnKeys = selectedColumns.map((col) => col.value);
    selectedColumnKeys.unshift("status", "name", "region", "positioning");
    return keyColumns.filter((col) => selectedColumnKeys.includes(col.key));
  };

  const generateLeftValue = (index) => {
    switch (index) {
      case 0:
        return "0px";
      case 1:
        return "150px";
      // case 2:
      //   return "350px";
      // case 3:
      //   return "500px";
       default:
         return "";
      }
  }

  return (
    <>
      <MainButton
        title="Back"
        btnStyle="transparent"
        fontColor="dark"
        marginRight="1"
        onPress={() => back(false)}
        icon={leftArrow3}
      />
      <Flex gap="24px" top="73px" width="100%" p={4}>
        <Select
          zIndex={1000}
          isMulti
          isClearable
          value={filters.region}
          placeholder="Regions ..."
          options={renderDropDownItems(regionList)}
          styles={{ control: (provided) => ({ ...provided, width: 300 }) }}
          onChange={(selectedOption) =>
            handleFilterChange(selectedOption, "region")
          }
        />
        <Select
          isMulti
          isClearable
          value={filters.position}
          placeholder="Positioning ..."
          options={renderDropDownItems(positionList)}
          styles={{ control: (provided) => ({ ...provided, width: 300 }) }}
          onChange={(selectedOption) =>
            handleFilterChange(selectedOption, "position")
          }
        />
        <Select
          isMulti
          isClearable
          value={filters.brand}
          placeholder="Brand ..."
          options={renderDropDownItems(brandList)}
          styles={{ control: (provided) => ({ ...provided, width: 300 }) }}
          onChange={(selectedOption) =>
            handleFilterChange(selectedOption, "brand")
          }
        />
        <Select
          isMulti
          isClearable
          value={selectedColumns}
          placeholder="Select Columns ..."
          options={columnOptions}
          styles={{ control: (provided) => ({ ...provided, width: 300 }) }}
          onChange={handleColumnChange}
        />
      </Flex>
      <Box mt="24px" display="flex" justifyContent="space-between">
        <MainTitle title="KPI Assets" />
        <Tooltip label="Export to Excel">
          <IconButton
            borderRadius="5px"
            icon={<Image src={downloadIcon} />}
            onClick={exportToExcel}
          />
        </Tooltip>
      </Box>
      <MainBox>
        {loading ? (
          <Box width="100%" paddingY={10}>
            <Center>
              <Spinner />
            </Center>
          </Box>
        ) : (
          <Box
            // className="table-container"
            pt={3}
            pb={2}
            mb={5}
            overflowX="auto"
          >
            <Table
                border="1px solid #E2E8F0"
                variant="simple"
                size="lg"
                width="auto"
            > 
              <Thead>
                <Tr>
                  {getFilteredColumns().map((col, index) => (
                  
                    <Th
                      key={col.key}
                      whiteSpace="nowrap"
                      position={index < 2 ? "sticky" : "static"}
                      left={generateLeftValue(index)}
                      // zIndex={index < 4 ? 1 : 0}
                      bg={index < 4 ? "white" : "transparent"}
                    >
                      {col.name}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {filteredData.map((row, index) => (
                  <Tr whiteSpace="nowrap" key={index}>
                    {getFilteredColumns().map((col, colIndex) => (
                      <Td
                        fontSize="sm"
                        key={col.key}
                        position={colIndex < 2 ? "sticky" : "static"}
                        left={generateLeftValue(colIndex)}
                        // zIndex={colIndex < 4 ? 1 : 0}
                        // whiteSpace={colIndex == 2 ? "break-spaces" : ""}
                        bg={colIndex < 2 ? "white" : "transparent"}
                      >
                        {col.key == "status"
                          ? row[col.key] == "In Planning"
                            ? "Unapproved"
                            : "Approved"
                          : col.format
                          ? col.format(row[col.key])
                          : row[col.key]}
                      </Td>
                    ))}
                  </Tr>
                ))}
                <Tr>
                  {getFilteredColumns().map((col, colIndex) => (
                    <Td
                      fontSize="sm"
                      whiteSpace="nowrap"
                      key={col.key}
                      position={colIndex < 2 ? "sticky" : "static"}
                      left={generateLeftValue(colIndex)}
                      bg={colIndex < 2 ? "white" : "transparent"}
                    >
                      {col.format
                        ? col.format(totalRow[col.key])
                        : totalRow[col.key]}
                    </Td>
                  ))}
                </Tr>
              </Tbody>
            </Table>
          </Box>
        )}
      </MainBox>
    </>
  );
};

export default KpiTemplate;
