const packageTypeGroup = { type: "", num: "", gpor: "", occRate: "" };

let managmentFFEReserveFee = Array(5).fill("");
let brandedBaseFee = Array(5).fill("");
let brandedIncentiveFee = Array(5).fill("");
let brandedFranchiseFee = Array(5).fill("");

const unitMixGroup = {
  type: "",
  numKeys: "",
  totalSellable: "",
  baseCost: "",
  salesPrice: "",
};
let payRollIncrementsArr = {
  year1: "",
  year2: "",
  year3: "",
};
let payRollArr = {
  value: "",
  fixed: "",
};
let costOfSaleArr = {
  value: "",
  fixed: "",
};
let otherExpensesArr = {
  value: "",
  fixed: "",
};
let otherExpensesIncrementsArr = {
  year1: "",
  year2: "",
  year3: "",
};
let creditCardArr = {
  value: "",
};
let otherRevArr = Array(6).fill(0);
let otherRevArrZero = Array(6).fill(0);
let managmentBaseFee = Array(5).fill("");
let ffeReserveFee = Array(5).fill(0);
let annualSalesAbsorption = Array(5).fill("");
let developmentAbsorptionLand = Array(5).fill(0);
let occupancyIncRateYear = Array(6).fill("");
let managmentIncentiveFee = Array(6).fill({
  ifGOPLessThanPercent: "",
  feePercentOfGOP: "",
});
let staffingArr = Array(5).fill("");

export const assetsPackageFormInitialValues = (hotelData, developmentData) => {
  /* console.log(
    "assetsPackageFormInitialValue 2222s",
    hotelData,
    developmentData
  ); */

  let { general, breakdown } = developmentData
    ? developmentData?.developmentCostValues
    : "";
  let { debt, risk } = developmentData
    ? developmentData?.debtAndRiskValues
    : "";
  let { baseFee, incentiveFee } = developmentData
    ? developmentData?.managementFees
    : "";
  //console.log("assetsPackageFormInitialValues", hotelData, developmentData);
  return {
    //links: [linksGroup],
    //STEP 1
    projectName: "",
    projectNameValue: "",
    assetType: "Package",
    regionLookup: "",
    operatorLookup: "",
    brandLookup: "",
    managementContractLookup: "",
    positioningLookup: "",
    projectStatusLookup: "",
    projectStageLookup: "",
    plotSize: "",
    grossFloorArea: "",
    parkingArea: "",
    assetBaseYear: "",
    numberOfKeys: "",
    startDateDevTime: "", //we need to split this into two val (year, montsh)
    developmentTimeDurationMonths: "",
    developmentTimeCompletionDate: "",
    startDateOpTime: "",
    operationTimeDurationMonths: "",
    operationTimeEndDate: "",

    developmentTimeStartYear: "",
    developmentTimeStartMonth: "",
    developmentTimeStartDate: "",
    operationStartYear: "",
    operationStartMonth: "",
    operationStartDate: "",

    opStartDate: "",
    compDateDevTime: "",

    //STEP 2
    developmentAbsorptionLand: developmentAbsorptionLand,
    devCostPerKey: "",
    refurbishmentCost: "",
    refurbishmentYear: "",
    landCost: "",
    infraCost: "",
    infraDepreciation: developmentData
      ? general.infrastructure.depreciation
      : "",
    infraDevStartMonth: "",
    infraDevDuration: "",
    parkingCost: "",
    parkingDepreciation: developmentData ? general.parking.depreciation : "",
    //parkingDevDuration: "",
    bDownConsCost: developmentData ? breakdown.construction.cost : "",
    bDownDepConsCost: developmentData
      ? breakdown.construction.depreciation
      : "",
    bDownFfeOse: developmentData ? breakdown.FFE_OSE.cost : "",
    bDownDepFfeOse: developmentData ? breakdown.FFE_OSE.depreciation : "",
    ffeDevelopmentDuration: "",
    bDownSoftCost: developmentData ? breakdown.softCosts.cost : "",
    bDownDepSoftCost: developmentData ? breakdown.softCosts.depreciation : "",
    bDownPreOpenTM: developmentData ? breakdown.preOpening.cost : "",
    bDownDepPreOpenTM: developmentData ? breakdown.preOpening.depreciation : "",
    preOpeningDevelopmentDuration: "",
    bDownTsaFee: developmentData ? breakdown.TSA.cost : "",
    bDownDepTsaFee: developmentData ? breakdown.TSA.depreciation : "",
    // bDownITbudget: "",
    // bDownDepITbudget: "",
    bDownNeomDevFee: developmentData ? breakdown.NEOMDevelopmentFee.cost : "",
    bDownDepNeomDevFee: developmentData
      ? breakdown.NEOMDevelopmentFee.depreciation
      : "",
    bDownContCost: developmentData ? breakdown.contingency.cost : "",
    bDownDepContCost: developmentData ? breakdown.contingency.depreciation : "",
    bDownIniWorkCapital: developmentData ? breakdown.initialWorkingCapital : "",
    baseCost: "",

    //STEP 3
    taxRate: "",
    openCashBalance: "",
    equity: "",
    annualInterestRate: developmentData ? debt.annualInterestRate : "",
    debtTerm: developmentData ? debt.debtTerm : "",
    gracePeriod: developmentData ? debt.gracePeriod : "",
    debtProcFees: developmentData ? debt.debtProcessingFees : "",
    securityFees: developmentData ? debt.securityFees : "",
    commitmentFees: developmentData ? debt.commitmentFees : "",
    sellingCosts: "",
    TaxSaleVal: "",
    riskFreeRate: developmentData ? risk.riskFreeRate : "",
    equityRiskPrem: developmentData ? risk.equityRiskPremium : "",
    countryRiskPrem: developmentData ? risk.countryRiskPremium : "",
    unleveredBeta: developmentData ? risk.unleveredBeta : "",
    manualInput: developmentData ? risk.manualInput : "",
    buffer: "",
    ebitdaMulti: "",
    useCalculation: "",

    //STEP 4
    packageType: [packageTypeGroup],
    occupancyIncRateYear: occupancyIncRateYear,
    pricingInflator: "",
    avgLenOfStay: "",
    guestPerOccRoom: 0,
    calcTotalNumbersKeys: 1, ///////calculation

    //STEP 5
    //from AssetListForm5Package itself

    // STEP 6
    externalVisitors: {
      numberOfVisitors: otherRevArr,
      foodAndBeveragePerVisitor: otherRevArr,
      recreationPerVisitor: otherRevArr,
      spaPerVisitor: otherRevArr,
      otherOnePerVisitor: otherRevArr,
      otherTwoPerVisitor: otherRevArr,
    },

    //STEP 7
    otherRevenueBreakdown: {
      meetingEvents: otherRevArr,
      banquet: otherRevArr,
      recreation: otherRevArr,
      spa: otherRevArr,
      otherProfitDepartments: otherRevArr,
      retail: otherRevArr,
      miscellaneous: otherRevArr,
      rental: otherRevArr,
      other1: otherRevArr,
      //other2: otherRevArr,
      packages: otherRevArr,
      meetingEventsAmounts: otherRevArrZero,
      banquetAmounts: otherRevArrZero,
      recreationAmounts: otherRevArrZero,
      spaAmounts: otherRevArrZero,
      otherProfitDepartmentsAmounts: otherRevArrZero,
      retailAmounts: otherRevArrZero,
      miscellaneousAmounts: otherRevArrZero,
      rentalAmounts: otherRevArrZero,
      other1Amounts: otherRevArrZero,
      //other2Amounts: otherRevArrZero,
      packagesAmounts: otherRevArrZero,
    },

    //STEP 8
    economiesOfScale: "",
    benchmarkType: "",
    positioning: "",
    expenseLevel: "",

    fixedIncome: "",
    operationalInsurance: "",
    permitsAndLicenses: "",
    landLeaseFee: "",
    otherFixedCharges1: "",
    propertyAndOtherTaxes: "",
    neomHotelsDevFee: "",
    assetManagementFee: developmentData ? breakdown?.assetManagementFee : "",
    otherFixedCharges2: "",
    departmentalExpenses: {
      rooms: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      foodAndBeverage: {
        payroll: payRollArr,
        costOfSales: costOfSaleArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      recreation: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      spa: {
        payroll: payRollArr,
        costOfSales: costOfSaleArr,

        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      otherProfitDepartments: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      retail: {
        payroll: payRollArr,
        costOfSales: costOfSaleArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      miscellaneous: {
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      rental: {
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      other1: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
        otherOneLabel: "Other 1",
      },
      other2: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
        otherTwoLabel: "Other 2",
      },
    },

    // STEP 9
    undistributedExpenses: {
      adminGeneral: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
        creditCardCommissions: creditCardArr,
      },
      informationTelecom: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
        // creditCardCommissions: creditCardArr,
      },
      salesMarketing: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
        otherExpensesYearlyIncrement: otherExpensesIncrementsArr,
        // creditCardCommissions: creditCardArr,
      },
      propertyOperations: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
        otherPayrollYearlyIncrement: otherExpensesIncrementsArr,
        // creditCardCommissions: creditCardArr,
      },
      utilities: {
        // payRoll: payRollArr,
        // payrollYearlyIncrement: payRollIncrementsArr,
        otherPerKey: otherExpensesArr,
        payrollYearlyIncrement: otherExpensesIncrementsArr,
        // creditCardCommissions: creditCardArr,
      },
    },
    // STEP 10
    managmentBaseFee: developmentData ? baseFee : managmentBaseFee,
    managmentIncentiveFee: developmentData
      ? incentiveFee
      : managmentIncentiveFee,
    franchiseFee: [
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
    ],
    includeFranchiseFee: "yes",
    revenueType: "total",
    percentageCap: "",
    franchiseFeeLabel: "franshise fee",
    reservationContribution: [
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
    ],
    reservationContributionRevenueType: "total",
    reservationContributionLabel: "RESERVATION CONTRIBUTION",
    adminAndGeneral: [
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
    ],
    adminAndGeneralRevenueType: "total",
    adminAndGeneralLabel: "ADMIN & GENERAL - OTHER",
    marketingContribution: "",
    marketingContributionField2: "",
    rewardProgram: "",
    roomBookings: "",
    operatorOtherProgramCost: "",
    technicalFeeToOperator: "",

    managmentFFEReserveFee: managmentFFEReserveFee,

    ffeReserveAddBack: "",
    leaseFixed: "",
    leaseVariable: "",
    staffPerKey: staffingArr,
    staffHousing: staffingArr,
    staffPhasing: "",
    workingCapital: "",

    // STEP 11
    unitMix: [unitMixGroup],
    annualSalesAbsorption: annualSalesAbsorption,
    Efficiency: "",
    opreatorContributions: "",
    adminExpenses: "",
    marketingSellingCost: "",
    residentialSaleFee: "",
    residentialConsultancyFee: "",
    adminCharges: "",
    shareOfResidence: "",
    minimumResidence: "",
    finalAssetSale: "",
    brandedResidence: "",
    minimumResidenceRadio: "",
    softCosts: "",
    preOpTraining: "",
    ffeAndOse: "",
    // <----->
    // STEP 12
    expenses1: "",
    expenses2: "",
    expenses3: "",
    expenses4: "",
    expenses5: "",
    brandedBaseFee: brandedBaseFee,
    brandedIncentiveFee: brandedIncentiveFee,
    brandedFranchiseFee: brandedFranchiseFee,
    /* brandedResidencesFields: {
      rentalPool: {
        type1SQM: "",
        type2SQM: "",
      },
      avgNumberOfDaysPerAnnum: {
        type1SQM: "",
        type2SQM: "",
      },
      stabilizedOccupancy: {
        type1SQM: "",
        type2SQM: "",
      },
      adrPerUnit: {
        type1SQM: "",
        type2SQM: "",
      },
      packageRate: {
        type1SQM: "",
        type2SQM: "",
      },
    }, */
    brandedResidencesFields: {
      rentalPool: [],
      avgNumberOfDaysPerAnnum: [],
      stabilizedOccupancy: [],
      adrPerUnit: [],
      otherRev: Array(10).fill(""),
    },

    //Final Step
    assignTo: "",
    addComment: "",
    approveAssetInfo: false,

    //-------------CALCULATION-----------
    calc1: 0,
    calc2: 0,
    calc3: 0,
    calc4: 0,
    calc5: 0,
    calc6: 0,

    calcTotalGfaBrandedRes: 0,
    calcTotalSellableGla: 0,
    calcTotalDevelopmentCostPerKey: 0,
    calcTotalNumberOfKeys: 0,
    calcTotalSalesPrice: 0,
    calcTotalBaseCost: 0,

    //step 5 PACKAGE
    fAndB1: 0,
    fAndB2: 0,
    fAndB3: 0,
    fAndB4: 0,
    fAndB5: 0,
    fAndB6: 0,
    otherCalc1: 0,
    otherCalc2: 0,
    otherCalc3: 0,
    otherCalc4: 0,
    otherCalc5: 0,
    otherCalc6: 0,
    roomRateCalc1: 0,
    roomRateCalc2: 0,
    roomRateCalc3: 0,
    roomRateCalc4: 0,
    roomRateCalc5: 0,
    roomRateCalc6: 0,
    packageRateCalc1: 0,
    packageRateCalc2: 0,
    packageRateCalc3: 0,
    packageRateCalc4: 0,
    packageRateCalc5: 0,
    packageRateCalc6: 0,

    weightedADRCalc1: 0,
    weightedADRCalc2: 0,
    weightedADRCalc3: 0,
    weightedADRCalc4: 0,
    weightedADRCalc5: 0,
    weightedADRCalc6: 0,

    totalPackageRateSARCalc1: 0,
    totalPackageRateSARCalc2: 0,
    totalPackageRateSARCalc3: 0,
    totalPackageRateSARCalc4: 0,
    totalPackageRateSARCalc5: 0,
    totalPackageRateSARCalc6: 0,

    totalPackageRateUSDCalc1: 0,
    totalPackageRateUSDCalc2: 0,
    totalPackageRateUSDCalc3: 0,
    totalPackageRateUSDCalc4: 0,
    totalPackageRateUSDCalc5: 0,
    totalPackageRateUSDCalc6: 0,

    totalPackageRate7DaysSARCalc1: 0,
    totalPackageRate7DaysSARCalc2: 0,
    totalPackageRate7DaysSARCalc3: 0,
    totalPackageRate7DaysSARCalc4: 0,
    totalPackageRate7DaysSARCalc5: 0,
    totalPackageRate7DaysSARCalc6: 0,

    totalPackageRate7DaysUSDCalc1: 0,
    totalPackageRate7DaysUSDCalc2: 0,
    totalPackageRate7DaysUSDCalc3: 0,
    totalPackageRate7DaysUSDCalc4: 0,
    totalPackageRate7DaysUSDCalc5: 0,
    totalPackageRate7DaysUSDCalc6: 0,
  };
};
