export const SAVE_FILTERS = 'SAVE_FILTERS';
export const LOAD_FILTERS = 'LOAD_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export const saveFilters = (filters) => ({
  type: SAVE_FILTERS,
  payload: filters,
});

export const loadFilters = () => ({
  type: LOAD_FILTERS,
});

export const clearFilters = () => ({
  type: CLEAR_FILTERS,
});