import axiosClient from "./index";

export const GetHomeData = async () => {
  const URL = "/v1/home";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//GET NOTIFICATION
export const GetNotifications = async () => {
  const URL = "/v1/home/notifications";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
//PUT NOTIFICATION VIEWED
export const UpdateViewdNotification = async (id) => {
  const URL = `/v1/home/notifications/markRead`;

  try {
    const response = await axiosClient.put(URL,{id} );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateClearNotification = async (id, clearAll = false) => {
  const URL = `/v1/home/notifications/clear`;
  let body = {
    clearAll
  }
  if (id)
    body.id = id

  try {
    const response = await axiosClient.put(URL, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//PUT NOTIFICATION VIEWED
export const UpdateArchiveNotification = async (id, archiveAll = false) => {
  let body = {
    archiveAll
  }

  if (id)
    body.id = id

  const URL = `/v1/home/notifications/archive`;
  try {
    const response = await axiosClient.put(URL, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};
//PUT PENDING ACTION VIEWED
export const UpdateViewdAction = async (id) => {
  const URL = `/v1/home/actions/${id}`;
  try {
    const response = await axiosClient.put(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// GET POWERBI TOKEN
export const GetEmbedToken = async () => {
  const URL = `/v1/dashboards/getAccessToken`;
  try {
    const response = await axiosClient.get(URL);
    return response.data.data.token;
  } catch (error) {
    throw error;
  }
}
// GET POWERBI FILTERS
export const GetReportFilters = async (level) => {
  const URL = `/v1/dashboards/getFilters?level=${level}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data.data.filter;
  } catch (error) {
    throw error;
  }
}
