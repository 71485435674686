import React from "react";

import { Box, Divider, Flex } from "@chakra-ui/react";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { TextArea } from "../../components/textArea/TextArea";
import { TooltipElement } from "../../components/tooltip/TooltipElement";

import leftArrow from "../../assets/images/icons/Arrow-Left.svg";

export const GeneralAssumpStep3 = ({
  prevPage,
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  onDraftOpen,
  handleOnSubmit,
  setDismissSave,
  disableSave,
  generalData,
  dirty,
  isRestore,
  isModelWarningOpen,
  setValidateStep,
  validateStep,
  closeToast,
  setFieldTouched,
}) => {
  let form3Data = [];
  form3Data.push(formikValues.firstPayment);

  let emptyform3Data = [];
  form3Data.forEach((item) => {
    if (item !== "" && item <= 100) {
      emptyform3Data.push(item);
    }
  });

  let isDisabledForm = false;
  let isDisabledFormProd = false;
  let status = generalData?.status === "Production";

  if (generalData?.status === "Production") {
    if (emptyform3Data.length !== form3Data.length) {
      isDisabledForm = true;
    }
    isDisabledFormProd =
      isDisabledForm === false && dirty === true ? false : true;
  } else {
    if (emptyform3Data.length !== form3Data.length) {
      isDisabledForm = true;
    }
  }

  return (
    <>
      <Box
        mt="5"
        mb="4"
        justifyContent="flex-start"
        display="flex"
        width="100%"
      >
        <MainTitle title="Other" />
      </Box>

      <Box
        maxW={["100%", "100%", "100%"]}
        width={["100%", "100%", "100%"]}
        alignSelf="flex-start"
        display="flex"
        position="relative"
      >
        <Box
          maxW={["100%", "100%", "40%"]}
          width={["100%", "100%", "40%"]}
          alignSelf="flex-start"
        >
          <InputFieldValidation
            label="Denominator"
            inputType="number"
            inputId="firstPayment"
            inputName="firstPayment"
            errors={errors}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0.00"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />
        </Box>
        <Box className="tooltip_field3" ml={3}>
          <TooltipElement label="The Denominator defines how many steps the S-Curve is divided into (in this model: 100)" />
        </Box>
      </Box>
      <Box
        maxW={["100%", "100%", "40%"]}
        width={["100%", "100%", "40%"]}
        alignSelf="flex-start"
      >
        <TextArea
          label="ADD COMMENT"
          inputId="comments"
          inputName="comments"
          placeholder="Add description"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          btnStyle="transparent"
          disabledBtn={isRestore}
          fontColor="dark"
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/AddGeneralAssumptions`}
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {!isDisabledForm ? (
              <MainButton
                title={isRestore ? "Restore" : "Submit"}
                reverseRow={true}
                onPress={() => {
                  closeToast();
                  handleOnSubmit(formikValues, isRestore);
                }}
                disabledBtn={status ? isDisabledFormProd : false}
              />
            ) : (
              <MainButton
                title={isRestore ? "Restore" : "Submit"}
                reverseRow={true}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
                disabledBtn={false}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
