import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import history from "../../history";
import {
  Button,
  HStack,
  Spacer,
  useDisclosure,
  Box,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Checkbox,
  Input,
  InputLeftElement,
  InputGroup,
  VStack,
  Center,
  Spinner,
} from "@chakra-ui/react";

import {
  GetAssetListModelingByIdAPI,
  GetAssetListByIdAPI,
  UpdateModelName,
  UpdateModelActive,
  DeleteModel,
} from "../../api/AssetListAPI";
import { GetProductionPackageType } from "../../api/MasterDataAPI";

import { ModelCard } from "../../components/modelCard/ModelCard";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { MainButton } from "../../components/button/MainButton";
import { MainBox } from "../../components/mainBox/MainBox";
import { GenerateModel } from "./GenerateModel";

import infoCircle from "../../assets/images/icons/infoCircle.svg";
import leftArrow from "../../assets/images/icons/leftArrow2.svg";
import pen from "../../assets/images/icons/penBlue.svg";
import searchIcon from "../../assets/images/icons/search.svg";

export const SelectAction = () => {
  const dataFetchedRef = useRef(false);
  let isView = useLocation();
  let { id, projectNameValue, type } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [modelData, setModelData] = useState([]);
  const [modelFilteredData, setModelFilteredData] = useState([]);
  const [assetsData, setAssetsData] = useState("");
  const [editModel, setEditModel] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchField, setSearchField] = useState(undefined);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [packageTypeArr, setPackageTypeArr] = useState([]);
  const [types, setTypes] = useState([
    {
      id: "totalRevenue",
      title: "Total Revenue (Year 5)",
      checked: false,
    },
    {
      id: "ebidta",
      title: "EBIDTA (Year 5)",
      checked: false,
    },
    {
      id: "gop",
      title: "GOP (Year 5)",
      checked: false,
    },
    {
      id: "cashFlow",
      title: "Cash Flow (Year 5)",
      checked: false,
    },
    {
      id: "averagePayrollPerStaff",
      title: "Average Payroll per Staff (Year 5)",
      checked: false,
    },
    {
      id: "roomRevenue",
      title: "Room Revenue (Year 5)",
      checked: false,
    },
    {
      id: "fnbRevenue",
      title: "FnB Revenue (Year 5)",
      checked: false,
    },
    {
      id: "otherRevenue",
      title: "Other Revenue (Year 5)",
      checked: false,
    },
    {
      id: "gopPar",
      title: "GOPPAR (Year 5)",
      checked: false,
    },
    {
      id: "ebitdaPar",
      title: "EBITDAPAR (Year 5)",
      checked: false,
    },
    {
      id: "trevPar",
      title: "TRevPAR (Year 5)",
      checked: false,
    },
    {
      id: "occupancy",
      title: "Occupancy",
      checked: false,
    },
    {
      id: "adr",
      title: "ADR",
      checked: false,
    },
    {
      id: "totalCapEx",
      title: "Total CapEx",
      checked: false,
    },
    {
      id: "irr",
      title: "IRR",
      checked: false,
    },
    {
      id: "npv",
      title: "NPV",
      checked: false,
    },
    {
      id: "debtCoverageRatio",
      title: "Debt Coverage Ratio",
      checked: false,
    },
    {
      id: "totalBrandedResidenceSales",
      title: "Total Branded Residence Sales",
      checked: false,
    },
    {
      id: "weightedAveragePremiumBrandedResidenceSale",
      title: "Weighted Average Premium Branded Residence Sale",
      checked: false,
    },
    {
      id: "weightedAveragePricePerSqmBrandedResidenceSale",
      title: "Weighted Average Price per SQM Branded Residence Sale",
      checked: false,
    },
    {
      id: "revPar",
      title: "RevPAR",
      checked: false,
    },
    {
      id: "ebitdaPercent",
      title: "EBITDA %",
      checked: false,
    },
    {
      id: "gopPercent",
      title: "GOP %",
      checked: false,
    },
    {
      id: "totalManagementFees",
      title: "Total Management Fees",
      checked: false,
    },
    {
      id: "totalOperationalCostPercent",
      title: "Total Operational Cost % (GOP and EBITDA)",
      checked: false,
    },
    {
      id: "departmentalProfitPercent",
      title: "Departmental Profit % (for each and total)",
      checked: false,
    },

    /* {
      id: "GOP",
      title: "GOP (Year 5)",
      checked: false,
    }, */
  ]);
  const [output, setOutput] = useState([
    {
      id: "totalRevenue",
      title: "Total Revenue (Year 5)",
      checked: false,
    },
    {
      id: "ebidta",
      title: "EBIDTA (Year 5)",
      checked: false,
    },
    {
      id: "gop",
      title: "GOP (Year 5)",
      checked: false,
    },
    {
      id: "cashFlow",
      title: "Cash Flow (Year 5)",
      checked: false,
    },
    {
      id: "averagePayrollPerStaff",
      title: "Average Payroll per Staff (Year 5)",
      checked: false,
    },
    {
      id: "roomRevenue",
      title: "Room Revenue (Year 5)",
      checked: false,
    },
    {
      id: "fnbRevenue",
      title: "FnB Revenue (Year 5)",
      checked: false,
    },
    {
      id: "otherRevenue",
      title: "Other Revenue (Year 5)",
      checked: false,
    },
    {
      id: "gopPar",
      title: "GOPPAR (Year 5)",
      checked: false,
    },
    {
      id: "ebitdaPar",
      title: "EBITDAPAR (Year 5)",
      checked: false,
    },
    {
      id: "trevPar",
      title: "TRevPAR (Year 5)",
      checked: false,
    },
    {
      id: "occupancy",
      title: "Occupancy",
      checked: false,
    },
    {
      id: "adr",
      title: "ADR",
      checked: false,
    },
    {
      id: "totalCapEx",
      title: "Total CapEx",
      checked: false,
    },
    {
      id: "irr",
      title: "IRR",
      checked: false,
    },
    {
      id: "npv",
      title: "NPV",
      checked: false,
    },
    {
      id: "debtCoverageRatio",
      title: "Debt Coverage Ratio",
      checked: false,
    },
    {
      id: "totalBrandedResidenceSales",
      title: "Total Branded Residence Sales",
      checked: false,
    },
    {
      id: "weightedAveragePremiumBrandedResidenceSale",
      title: "Weighted Average Premium Branded Residence Sale",
      checked: false,
    },
    {
      id: "weightedAveragePricePerSqmBrandedResidenceSale",
      title: "Weighted Average Price per SQM Branded Residence Sale",
      checked: false,
    },
    {
      id: "revPar",
      title: "RevPAR",
      checked: false,
    },
    {
      id: "ebitdaPercent",
      title: "EBITDA %",
      checked: false,
    },
    {
      id: "gopPercent",
      title: "GOP %",
      checked: false,
    },
    {
      id: "totalManagementFees",
      title: "Total Management Fees",
      checked: false,
    },
    {
      id: "totalOperationalCostPercent",
      title: "Total Operational Cost % (GOP and EBITDA)",
      checked: false,
    },
    {
      id: "departmentalProfitPercent",
      title: "Departmental Profit % (for each and total)",
      checked: false,
    },
    {
      id: "roomsProfitPercentCombined",
      title: "Rooms Profit % Combined",
      checked: false,
    },
    {
      id: "foodAndBeverageProfitPercentCombined",
      title: "Food And Beverage Profit % Combined",
      checked: false,
    },
    {
      id: "otherOperatingDepartmentsProfitPercentCombined",
      title: "Other Operating Departments Profit % Combined",
      checked: false,
    },
    /*  {
      id: "GOP",
      title: "GOP (Year 5)",
      checked: false,
    }, */
  ]);

  const {
    isOpen: isSelectOpen,
    onOpen: onSelectOpen,
    onClose: onSelectClose,
  } = useDisclosure();
  const {
    isOpen: isCalculationOpen,
    onOpen: onCalculationOpen,
    onClose: onCalculationClose,
  } = useDisclosure();
  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const setCheckedItems = (val, id) => {
    console.log("checked", val, id);
    let checkedItems = output.filter((el) => el.checked);
    if (checkedItems.length <= 8 || !val) {
      const newArr = output.map((obj) => {
        if (obj.id === id) {
          return { ...obj, checked: val };
        }

        return obj;
      });

      setTypes([...newArr]);
      setOutput([...newArr]);
      if (!!searchField) {
        setIsSearchLoading(true);
      }
    }
  };

  const handleSelectModel = () => {
    // console.log("CLOSED");
    // console.log("selectedModel", selectedModel);
    UpdateModelActive(projectNameValue, selectedModel)
      .then((res) => {
        console.log("reees set active", res);
        if (res.success) {
          onSelectClose();
          history.push(`/AssetVersionHistory/${projectNameValue}`);
          //loadModelData();
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage("Something went wrong");
        onErrorOpen();
      });
  };
  const handleSearch = (val) => {
    setSearchField(val);
    if (val === "") {
      setTypes(output);
    } else {
      const filter = output.filter((el) =>
        el.title.toLowerCase().includes(val)
      );
      setTypes(filter);
    }
    setTimeout(() => {
      setIsSearchLoading(false);
    }, 200);
  };

  const prettyPrintNumber = (value) => {
    const numberValue = Number(value);
    if (Number.isNaN(numberValue) || !Number.isFinite(numberValue)) {
      return value;
    }
    const truncated = numberValue.toFixed(2);
    return Intl.NumberFormat("en-US").format(truncated);
  };

  const filteredModelDataForCard = (model) => {
    let parsedOutput = JSON.parse(model.outputs);
    let outputKeys = Object.keys(parsedOutput);
    let outputCalc = [];
    outputKeys.forEach((key, ind) => {
      let findSelected = output?.find((el) => el.id === key);
      // console.log("findSelected", findSelected, parsedOutput[key]);
      outputCalc.push({
        id: findSelected?.id,
        title: `${findSelected?.title}`,
        checked: !!findSelected ? findSelected?.checked : false,
        value: prettyPrintNumber(parsedOutput[key]),
      });
    });

    return outputCalc;
  };

  const renderRowsAfterDeletion = (id) => {
    if (!id.startsWith("delete|")) {
      return;
    }
    // console.log(">> deleting", id);
    DeleteModel(projectNameValue, id.split("|")[1])
      .then((res) => {
        setErrorMessage("");
        setIsLoading(true);
        if (res.success === true) {
          loadModelData();
          console.log("res deleteed ", res);
        }
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "Something went wrong");
        onErrorOpen();
      });
  };

  useEffect(() => {
    if (!!searchField) {
      console.log("eeeee");
      handleSearch(searchField);
    }
  }, [output]);
  useEffect(() => {
    if (!!editModel?.name) {
      console.log("editModel", editModel);
      UpdateModelName(projectNameValue, editModel.id, editModel.name)
        .then((res) => {
          console.log("reees update name", res);
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    }
  }, [editModel]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (!!id && !!projectNameValue) {
      setIsLoading(true);
      setErrorMessage("");

      GetProductionPackageType()
        .then((res) => {
          let packageTypesOptions = [];
          if (res.data.length > 0) {
            if (res.data[0]?.lookups?.length > 0) {
              res.data[0].lookups.forEach((item) => {
                packageTypesOptions.push({
                  value: item.value,
                  label: item.label,
                });
              });

              setPackageTypeArr(packageTypesOptions);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

      GetAssetListByIdAPI(projectNameValue, id)
        .then((res) => {
          console.log("reees get by id", res.data);

          setAssetsData(res.data);
          setIsLoading(false);
        })

        .catch((err) => {
          console.log(err);
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    }
  }, []);

  const loadModelData = useCallback(() => {
    console.log("opeeeeeen");
    console.log("GetAssetListModelingByIdAPI", projectNameValue, id);
    GetAssetListModelingByIdAPI(projectNameValue, id)
      .then((res) => {
        console.log("reees get model", res);
        //console.log("reees get model", JSON.parse(res.modelList[0].outputs));
        let sortedArr = res.modelList.sort((a, b) => {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          // a must be equal to b
          return 0;
        });
        console.log("sortedArr", sortedArr);
        setModelData(res.modelList);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage("Something went wrong");
        onErrorOpen();
      });
  }, []);

  useEffect(() => {
    loadModelData();
  }, [loadModelData]);

  return (
    <div>
      <Box display="inline-block" width="80px" mb={5}>
        <MainButton
          title="Back"
          fontColor="dark"
          btnStyle="transparent"
          icon={leftArrow}
          navLink={true}
          path={`/AssetVersionHistory/${projectNameValue}`}
        />
      </Box>
      <HStack>
        <MainTitle title={"FINANCIAL ANALYSIS"} />
        <Spacer />

        <Menu autoSelect={false}>
          <MenuButton
            bgColor="transparent"
            as={Button}
            px={1}
            ml={1}
            minWidth="auto"
            _hover={{ backgroundColor: "transparent" }}
            disabled={isView?.state?.from === "View" ? true : false}
          >
            <HStack>
              <Image src={pen} alt="dots icon" />
              <Text
                fontSize="15px"
                color="#007BB5"
                letterSpacing="-0.01em"
                fontWeight="400"
              >
                Customize Output
              </Text>
            </HStack>
          </MenuButton>
          <MenuList
            px="5px"
            py="15px"
            width="300px"
            height="300px"
            overflowY="scroll"
          >
            <Text fontSize="14px" color="#1D1F22" px="10px" mb="7px">
              Customize output fields (you can select up to 8 fields)
            </Text>
            <Box px="10px">
              <Box mb={4} mt={1}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children={<Image src={searchIcon} alt="Search" />}
                  />
                  <Input
                    placeholder="Search fields"
                    borderRadius="5px"
                    borderColor="#F4F4F4"
                    color="#6F767E"
                    fontSize="12px"
                    value={searchField}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </InputGroup>
              </Box>
              <VStack alignItems="flex-start">
                {isSearchLoading ? (
                  <Center width="100%">
                    <Spinner />
                  </Center>
                ) : (
                  types?.map((item, index) => {
                    return (
                      <Checkbox
                        isChecked={item.checked}
                        onChange={(e) =>
                          setCheckedItems(e.target.checked, item.id)
                        }
                        colorScheme="blue"
                        className="checkbox_input_sm"
                        fontSize="13px"
                        key={`check_${index}`}
                      >
                        {item.title}
                      </Checkbox>
                    );
                  })
                )}
              </VStack>
            </Box>
          </MenuList>
        </Menu>
      </HStack>
      <MainBox>
        <Box
          display="flex"
          className="scroll_bar_h"
          overflowX="scroll"
          width="100%"
        >
          {isLoading ? (
            <Box paddingTop={32} paddingBottom={32} width="100%">
              <Center>
                <Spinner />
              </Center>
            </Box>
          ) : (
            <>
              {modelData.map((model, ind) => {
                return (
                  <ModelCard
                    isView={isView?.state?.from === "View" ? true : false}
                    title={model.label}
                    isSelected={model.isActive}
                    data={filteredModelDataForCard(model)}
                    onSelectOpen={onSelectOpen}
                    modelId={model.id}
                    setEditModel={setEditModel}
                    setSelectedModel={setSelectedModel}
                    onDelModalOpen={onDelModalOpen}
                    ind={ind}
                  />
                );
              })}
              <ModelCard
                isView={isView?.state?.from === "View" ? true : false}
                type="Create"
                onOpen={onCalculationOpen}
              />
            </>
          )}
        </Box>
      </MainBox>
      <ModalAlert
        handleSelectModel={handleSelectModel}
        selectAction={true}
        isOpen={isSelectOpen}
        onClose={onSelectClose}
        title="Are you sure you want to proceed?"
        icon={infoCircle}
        hasDesc={true}
        desc="By selecting this model, you will be creating a new version of the asset with all details updated as per
                your input."
        confirmationModal={true}
        haveSaveDraft={true}
        isColorsSwitched={false}
        isDelete={true}
      />
      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
      <GenerateModel
        isView={isView?.state?.from === "View" ? true : false}
        isOpen={isCalculationOpen}
        onClose={onCalculationClose}
        assetsData={assetsData}
        type={type}
        id={id}
        projectNameValue={projectNameValue}
        onErrorOpen={onErrorOpen}
        setErrorMessage={setErrorMessage}
        loadModelData={loadModelData}
        packageTypeArr={packageTypeArr}
      />
      <ModalAlert
        isOpen={isDelModalOpen}
        onClose={onDelModalClose}
        title="Are you sure you want to delete this model?"
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={true}
        haveSaveDraft={true}
        isDelete={true}
        selectedID={selectedModel}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        renderRowsAfterDeletion={renderRowsAfterDeletion}
      />
    </div>
  );
};
