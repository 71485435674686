import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Divider,
  Text,
  Accordion,
  AccordionItem,
  Heading,
  AccordionButton,
  SimpleGrid,
  GridItem,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { FieldArray } from "formik";

import percent from "../../assets/images/icons/filled.svg";
import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import { nullifyYearsField } from "../../utils/General";
import { InputFieldCalc } from "../../components/inputField/InputFieldCalc";
import {CalculationButton} from"../../components/calculationModal/calculationButton"

export const AssetsListForm10 = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  setInitFormVal,
  initFormVal,
  opStartDate,
  unsetStepWarning,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  step,
  unitMixChanged,
  setStepWarning,
  setStepperUpdate,
  stepperUpdate,
  validateStep,
  setValidateStep,
  modelWarningOpen,
  isModelWarningOpen,
  closeModalPath,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
  user
}) => {
  let { type } = useParams();
  const dataFetchedRef = useRef(false);
  let calcFields = ["calc1", "calc2", "calc3", "calc4", "calc5", "calc6"];
  let calcWeightedADRPackage = [
    "weightedADRCalc1",
    "weightedADRCalc2",
    "weightedADRCalc3",
    "weightedADRCalc4",
    "weightedADRCalc5",
    "weightedADRCalc6",
  ];
  let calcWeightedADRStandard = [
    "weightedADR1",
    "weightedADR2",
    "weightedADR3",
    "weightedADR4",
    "weightedADR5",
    "weightedADR6",
  ];
  let currDate2;
  currDate2 = new Date(formikValues?.startDateOpTime).getFullYear();
  currDate2 = currDate2 - 1;
  let currDate4 = new Date(formikValues?.startDateOpTime).getFullYear();
  let accordTitle = [
    "Included in Rental Pool",
    "Average Number of Days per Annum to Owner Exclusive Use",
    "Stabilized Occupancy per Unit",
    "ADR per Unit",
    "Other revenues por",
  ];
  let otherRevTitle = [
    "Incremental Food & Beverage Outlets",
    "Incremental Banquet ",
    "Incremental Recreation",
    "Incremental Spa",
    "Incremental Other Profit Departments",
    "Incremental Retail ",
    "Incremental Miscellaneous Income",
    "Incremental Rental",
    "Incremental Other 1",
    "Incremental Other 2",
  ];

  const [arrayKeys, setArrayKeys] = useState([]);
  const inputRef = useRef(null);
  let currDate = new Date(formikValues?.startDateOpTime).getFullYear() - 1;
  let arrKeys = [];

  if (formikValues?.brandedResidencesFields) {
    arrKeys = Object.keys(formikValues.brandedResidencesFields);
    if (JSON.stringify(arrayKeys) !== JSON.stringify(arrKeys)) {
      setArrayKeys(arrKeys);
    }
  }

  let form10Data = [];
  form10Data.push(formikValues.expenses1); //string
  form10Data.push(formikValues.expenses2); //string
  form10Data.push(formikValues.expenses3); //string
  form10Data.push(formikValues.expenses4); //string
  form10Data.push(formikValues.expenses5); //string
  let emptyform10Data = [];
  formikValues?.brandedBaseFee?.forEach((item) => {
    form10Data.push(item);
  });
  formikValues?.brandedIncentiveFee?.forEach((item) => {
    form10Data.push(item);
  });
  formikValues?.brandedFranchiseFee?.forEach((item) => {
    form10Data.push(item);
  });

  if (arrKeys.length > 0) {
    arrKeys.forEach((el) => {
      if (!!formikValues?.brandedResidencesFields[el] && el !== "otherRev") {
        formikValues?.brandedResidencesFields[el].forEach((item) => {
          form10Data.push(item.typeField);
        });
      }
      if (!!formikValues?.brandedResidencesFields[el] && el == "otherRev") {
        formikValues?.brandedResidencesFields[el].forEach((item) => {
          form10Data.push(item);
        });
      }
    });
  }

  form10Data.forEach((item, ind) => {
    if (ind <= 19) {
      if (ind === 1 || ind === 3) {
        if (item !== "" && item >= "0") {
          emptyform10Data.push(item);
        }
      } else {
        if (item !== "" && item >= "0" && item <= "100") {
          emptyform10Data.push(item);
        }
      }
    } else {
      if (item !== "" && item >= "0") {
        emptyform10Data.push(item);
      }
    }
  });

  let isDisabledForm = false;
  if (emptyform10Data.length !== form10Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }
  const handleAccordErrors = () => {
    let openHigherAccord = true;

    form10Data.forEach((el, index) => {
      if (openHigherAccord) {
        if (el === "" || el > 100) {
          switch (true) {
            case index === 20:
              openHigherAccord = false;
              handleOpenAccord(0);
              break;
            case index === 21:
              openHigherAccord = false;
              handleOpenAccord(1);
              break;
            case index === 22:
              openHigherAccord = false;
              handleOpenAccord(2);
              break;
            case index === 23:
              openHigherAccord = false;
              handleOpenAccord(3);
              break;
            case index >= 24 && index <= 33:
              openHigherAccord = false;
              handleOpenAccord(4);
              break;
            default:
              break;
          }
        }
      }
    });
  };

  const handleOpenAccord = (accordIndex) => {
    if (
      inputRef.current.querySelectorAll(
        ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
      )[accordIndex].ariaExpanded === "false"
    ) {
      inputRef.current
        .querySelectorAll(
          ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
        )
        [accordIndex].click();
    }
  };

  const resetDate = () => {
    currDate = new Date(formikValues?.startDateOpTime).getFullYear() - 1;
  };

  let renderAccrodionFields = (mData, pos) => {
    let typeNum = 0;
    return (
      <>
        <AccordionItem mb="4" borderBottom="none">
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  {accordTitle[pos]}
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>

          <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
            <>
              <Box>
                <Box
                  pt="3"
                  pb="3"
                  maxW={["100%", "100%", "65%"]}
                  width={["100%", "100%", "65%"]}
                  alignSelf="flex-start"
                >
                  <SimpleGrid
                    columns={{ sm: 1, md: 1 }}
                    spacingX="15px"
                    width="100%"
                  >
                    <GridItem colSpan={1}>
                      <SimpleGrid
                        columns={{ sm: 1, md: 1 }}
                        spacing="15px"
                        width="100%"
                      >
                        <GridItem>
                          <FieldArray
                            name={`${arrayKeys[pos]}`}
                            render={(arrayHelper) => (
                              <>
                                {formikValues?.brandedResidencesFields[
                                  arrKeys[pos]
                                ] !== undefined &&
                                  formikValues?.brandedResidencesFields[
                                    arrKeys[pos]
                                  ].length > 0 &&
                                  formikValues?.brandedResidencesFields[
                                    arrKeys[pos]
                                  ].map((brandedArr, index) => {
                                    typeNum = typeNum + 1;

                                    let result = dropdownObj?.types.filter(
                                      (o) =>
                                        formikValues?.unitMix.some(
                                          ({ type }) => o.value === type
                                        )
                                    );
                                    // let subTitle = formikValues?.unitMix.find(
                                    //   (el, ind) => {

                                    //     console.log("soooooooo", el.type, result[ind].value);
                                    //     return el.type == result[ind].value
                                    //   }
                                    // );
                                    let result2 = formikValues.unitMix.map(
                                      (el, ind) => {
                                        let resultFind = result.find(
                                          (element) => element.value == el.type
                                        );
                                        console.log("resultFind", resultFind);
                                        return resultFind;
                                        // return result[ind];
                                        // el.value ==
                                      }
                                    );
                                    console.log("result2", result2);
                                    let subTitle = result2[index];
                                    console.log("res", result, subTitle);
                                    // let subTitle = dropdownObj?.types?.find(
                                    //   (el, i) => {
                                    //     if (!!formikValues?.unitMix[i]) {
                                    //       console.log(
                                    //         "formikValues?.unitMix[i]?.type",
                                    //         formikValues?.unitMix[i]?.type
                                    //       );
                                    //       return (
                                    //         el.value ===
                                    //         formikValues?.unitMix[i]?.type
                                    //       );
                                    //     }
                                    //   }
                                    // );
                                    console.log(
                                      "subTitle",
                                      subTitle,
                                      dropdownObj?.types,
                                      formikValues?.unitMix,
                                      formikValues
                                    );

                                    return (
                                      <>
                                        <Box pb={2} pt={2}>
                                          <>
                                            <GridItem colSpan={2} width="85%">
                                              <SimpleGrid
                                                columns={{ sm: 6, md: 6 }}
                                                spacingX="15px"
                                                width="100%"
                                              >
                                                <GridItem
                                                  colSpan={6}
                                                  key={`brandedResidencesFields_${arrayKeys[pos]}_${index}`}
                                                >
                                                  {arrayKeys[pos] ==
                                                  "otherRev" ? (
                                                    <InputFieldValidation
                                                      label={`${otherRevTitle[index]}`}
                                                      placeholder="0"
                                                      inputType="number"
                                                      inputId={`brandedResidencesFields.${arrayKeys[pos]}.${index}`}
                                                      inputName={`brandedResidencesFields.${arrayKeys[pos]}.${index}`}
                                                      errors={errors}
                                                      setIsFormDirty={
                                                        setIsFormDirty
                                                      }
                                                      isFormDirty={isFormDirty}
                                                      touched={touched}
                                                      isRequired
                                                      leftIcon={true}
                                                      leftIconText={"SAR"}
                                                      onChange={setFieldValue}
                                                      values={
                                                        formikValues
                                                          ?.brandedResidencesFields[
                                                          arrayKeys[pos]
                                                        ][index]
                                                      }
                                                      errorHeight0={true}
                                                      uppercase={true}
                                                      directVal={true}
                                                      isDisabled={
                                                        isRestore ||
                                                        formikValues?.brandedResidence ==
                                                          "2"
                                                      }
                                                      validateField={
                                                        validateStep
                                                      }
                                                      isThousand={true}
                                                      setFieldTouched={
                                                        setFieldTouched
                                                      }
                                                    />
                                                  ) : (
                                                    <InputFieldValidation
                                                      label={`${subTitle?.label?.toUpperCase()} - ${
                                                        formikValues?.unitMix[
                                                          index
                                                        ]?.totalSellable
                                                      } SQM`}
                                                      // label={`${formikValues?.unitMix[
                                                      //   index
                                                      // ]?.type
                                                      //   .toUpperCase()
                                                      //   .split("_")
                                                      //   .join(" ")} - ${
                                                      //   formikValues?.unitMix[
                                                      //     index
                                                      //   ]?.totalSellable
                                                      // } SQM`}
                                                      /* label={
                                                        typeNum === 1
                                                          ? `TYPE ${typeNum} - 170 SQM`
                                                          : `TYPE ${typeNum} - 0 SQM`
                                                      } */ icon={
                                                        accordTitle[pos] ===
                                                          "Included in Rental Pool" ||
                                                        accordTitle[pos] ===
                                                          "Stabilized Occupancy per Unit"
                                                          ? percent
                                                          : ""
                                                      }
                                                      // isThousand={
                                                      //   accordTitle[pos] ===
                                                      //     "Included in Rental Pool" ||
                                                      //   accordTitle[pos] ===
                                                      //     "Stabilized Occupancy per Unit"
                                                      //     ? false
                                                      //     : true
                                                      // }
                                                      placeholder="0"
                                                      leftIcon={
                                                        accordTitle[pos] ===
                                                        "ADR per Unit"
                                                          ? true
                                                          : false
                                                      }
                                                      leftIconText={
                                                        accordTitle[pos] ===
                                                        "ADR per Unit"
                                                          ? "SAR"
                                                          : ""
                                                      }
                                                      inputType="number"
                                                      inputId={`brandedResidencesFields.${arrayKeys[pos]}.${index}.typeField`}
                                                      inputName={`brandedResidencesFields.${arrayKeys[pos]}.${index}.typeField`}
                                                      errors={errors}
                                                      setIsFormDirty={
                                                        setIsFormDirty
                                                      }
                                                      isFormDirty={isFormDirty}
                                                      touched={touched}
                                                      isRequired
                                                      onChange={setFieldValue}
                                                      values={
                                                        formikValues
                                                          ?.brandedResidencesFields[
                                                          arrayKeys[pos]
                                                        ][index].typeField
                                                      }
                                                      errorHeight0={true}
                                                      uppercase={false}
                                                      directVal={true}
                                                      isDisabled={
                                                        isRestore ||
                                                        formikValues?.brandedResidence ==
                                                          "2"
                                                      }
                                                      validateField={
                                                        validateStep
                                                      }
                                                      isThousand={true}
                                                      setFieldTouched={
                                                        setFieldTouched
                                                      }
                                                    />
                                                  )}
                                                </GridItem>
                                              </SimpleGrid>
                                            </GridItem>
                                          </>
                                        </Box>
                                      </>
                                    );
                                  })}
                                {accordTitle[pos] ===
                                  "Stabilized Occupancy per Unit" && (
                                  <FieldArray
                                    name="occupancyIncRateYear"
                                    render={(arrayHelper) => (
                                      <SimpleGrid
                                        mt="10"
                                        columns={{
                                          sm: 6,
                                          md: 6,
                                        }}
                                        spacing="15px"
                                        width="150%"
                                      >
                                        {formikValues?.occupancyIncRateYear !==
                                          undefined &&
                                          formikValues?.occupancyIncRateYear?.map(
                                            (_, index) => {
                                              currDate = currDate + 1;
                                              return (
                                                <GridItem
                                                  key={`occupancyIncRateYear_${index}`}
                                                >
                                                  <InputFieldCalc
                                                    label={`Year ${currDate}`}
                                                    placeholder="0.00  "
                                                    inputType="number"
                                                    inputId={`occupancyIncRateYear.${index}`}
                                                    inputName={`occupancyIncRateYear.${index}`}
                                                    errors={errors}
                                                    setIsFormDirty={
                                                      setIsFormDirty
                                                    }
                                                    isFormDirty={isFormDirty}
                                                    touched={touched}
                                                    isRequired
                                                    onChange={setFieldValue}
                                                    values={
                                                      calcFields[index] ===
                                                      "calc5"
                                                        ? " "
                                                        : formikValues
                                                            ?.occupancyIncRateYear[
                                                            index
                                                          ]
                                                    }
                                                    labelCenter={true}
                                                    //errorHeight0={true}
                                                    uppercase={false}
                                                    isDisabled={true}
                                                    noBg={
                                                      calcFields[index] ===
                                                      "calc5"
                                                        ? true
                                                        : false
                                                    }
                                                    currencyConvertor={true}
                                                    // calculatedOcc={true}
                                                    // onChangeCalc={handleCalcOcc}
                                                    currConvVal={
                                                      formikValues[
                                                        calcFields[index]
                                                      ]
                                                    }
                                                    directVal={true}
                                                    smallIcon={percent}
                                                    isThousand={true}
                                                    setFieldTouched={
                                                      setFieldTouched
                                                    }
                                                    onBlur={handleBlur}
                                                  />
                                                </GridItem>
                                              );
                                            }
                                          )}
                                      </SimpleGrid>
                                    )}
                                  />
                                )}
                                {accordTitle[pos] === "ADR per Unit" && (
                                  <>
                                    <Text
                                      className="title_sm"
                                      display="flex"
                                      mb={5}
                                      mt={"8 !important"}
                                      component="div"
                                      textTransform="uppercase"
                                    >
                                      Weighted Average Daily Rate
                                    </Text>
                                    <SimpleGrid
                                      columns={{ sm: 4, md: 4 }}
                                      spacingX="15px"
                                      width="120%"
                                    >
                                      <GridItem colSpan={4}>
                                        <SimpleGrid
                                          columns={{ sm: 6, md: 6 }}
                                          spacing="15px"
                                          width="120%"
                                        >
                                          {type === "Package"
                                            ? calcWeightedADRPackage.map(
                                                (item, ind) => {
                                                  currDate4 = currDate4 + 1;
                                                  return (
                                                    <GridItem>
                                                      <Box textAlign="center">
                                                        <Text
                                                          mb="-9px !important"
                                                          className="title_sm"
                                                          fontSize="12px"
                                                        >
                                                          Year {currDate4}
                                                        </Text>
                                                        <InputFieldValidation
                                                          hideLabel={true}
                                                          placeholder="0.00 SAR"
                                                          inputType="number"
                                                          inputId={item}
                                                          inputName={item}
                                                          errors={errors}
                                                          setIsFormDirty={
                                                            setIsFormDirty
                                                          }
                                                          isFormDirty={
                                                            isFormDirty
                                                          }
                                                          touched={touched}
                                                          isRequired
                                                          onChange={
                                                            setFieldValue
                                                          }
                                                          values={formikValues}
                                                          labelCenter={true}
                                                          errorHeight0={true}
                                                          uppercase={false}
                                                          isDisabled={true}
                                                          noBg={true}
                                                          fs14={true}
                                                          isThousand={true}
                                                          setFieldTouched={
                                                            setFieldTouched
                                                          }
                                                        />
                                                      </Box>
                                                    </GridItem>
                                                  );
                                                }
                                              )
                                            : calcWeightedADRStandard?.map(
                                                (item, ind) => {
                                                  currDate2 = currDate2 + 1;
                                                  return (
                                                    <GridItem>
                                                      <Box textAlign="center">
                                                        <Text
                                                          mb="-10px !important"
                                                          className="title_sm"
                                                        >
                                                          Year {currDate2}
                                                        </Text>
                                                        <Box>
                                                          <InputFieldValidation
                                                            hideLabel={true}
                                                            placeholder="0.00 SAR"
                                                            inputType="number"
                                                            inputId={item}
                                                            inputName={item}
                                                            errors={errors}
                                                            setIsFormDirty={
                                                              setIsFormDirty
                                                            }
                                                            isFormDirty={
                                                              isFormDirty
                                                            }
                                                            touched={touched}
                                                            isRequired
                                                            onChange={
                                                              setFieldValue
                                                            }
                                                            values={
                                                              formikValues
                                                            }
                                                            labelCenter={true}
                                                            errorHeight0={true}
                                                            uppercase={false}
                                                            noBg={true}
                                                            isDisabled={true}
                                                            validateField={
                                                              validateStep
                                                            }
                                                            isThousand={true}
                                                            setFieldTouched={
                                                              setFieldTouched
                                                            }
                                                          />
                                                        </Box>
                                                      </Box>
                                                    </GridItem>
                                                  );
                                                }
                                              )}
                                        </SimpleGrid>
                                      </GridItem>
                                    </SimpleGrid>
                                  </>
                                )}
                              </>
                            )}
                          />
                          {/* end field array */}
                        </GridItem>
                      </SimpleGrid>
                    </GridItem>
                  </SimpleGrid>
                </Box>
              </Box>
            </>
          </AccordionPanel>
        </AccordionItem>
      </>
    );
  };

  const renderYearFields = (type) => {
    return (
      <>
        <FieldArray
          name={`${type}`}
          render={(arrayHelper) => (
            <Box display="flex" width="100%" gap="2.5%">
              {formikValues?.[`${type}`] !== undefined &&
                formikValues?.[`${type}`]?.map((_, index) => {
                  currDate = currDate + 1;
                  return (
                    <Box
                      width={["100%", "100%", "100%"]}
                      key={`${type}.${index}`}
                    >
                      <InputFieldValidation
                        label={`Year ${currDate}`}
                        placeholder="0"
                        smallIcon={percent}
                        inputType="number"
                        inputId={`${type}.${index}`}
                        inputName={`${type}.${index}`}
                        errors={errors}
                        setIsFormDirty={setIsFormDirty}
                        isFormDirty={isFormDirty}
                        touched={touched}
                        isRequired
                        onChange={setFieldValue}
                        values={formikValues?.[`${type}`][index]}
                        labelCenter={true}
                        errorHeight0={true}
                        uppercase={false}
                        directVal={true}
                        isDisabled={
                          isRestore || formikValues?.brandedResidence == "2"
                        }
                        validateField={validateStep}
                        isThousand={true}
                        setFieldTouched={setFieldTouched}
                      />
                    </Box>
                  );
                })}
              {resetDate()}
            </Box>
          )}
        />
      </>
    );
  };

  useEffect(() => {
    let newInitData = [];
    let newAdrPerUnit = [];
    let newRentalPool = [];
    let newAvgNumOfDaysPer = [];
    let newStabilizedOccupancy = [];
    let {
      adrPerUnit,
      rentalPool,
      stabilizedOccupancy,
      avgNumberOfDaysPerAnnum,
    } = formikValues.brandedResidencesFields;

    if (formikValues?.brandedResidencesFields?.adrPerUnit?.length > 0) {
      formikValues.unitMix.map((el, ind) => {
        let adrPerUnitType = adrPerUnit[ind]?.type ? adrPerUnit[ind].type : "";
        adrPerUnitType =
          adrPerUnitType === "noSelect"
            ? formikValues.unitMix[ind].type
            : adrPerUnitType;
        let rentalPoolType = rentalPool[ind]?.type ? rentalPool[ind].type : "";
        rentalPoolType =
          rentalPoolType === "noSelect"
            ? formikValues.unitMix[ind].type
            : rentalPoolType;
        let avgNumPerAnnumType = avgNumberOfDaysPerAnnum[ind]?.type
          ? avgNumberOfDaysPerAnnum[ind].type
          : "";
        avgNumPerAnnumType =
          avgNumPerAnnumType === "noSelect"
            ? formikValues.unitMix[ind].type
            : avgNumPerAnnumType;
        let stabilizedOccType = stabilizedOccupancy[ind]?.type
          ? stabilizedOccupancy[ind].type
          : "";
        stabilizedOccType =
          stabilizedOccType === "noSelect"
            ? formikValues.unitMix[ind].type
            : stabilizedOccType;

        newAdrPerUnit = [
          ...newAdrPerUnit,
          {
            type: adrPerUnitType,
            id: adrPerUnitType,
            typeField:
              adrPerUnit[ind]?.typeField ? adrPerUnit[ind].typeField : "",
          },
        ];
        newRentalPool = [
          ...newRentalPool,
          {
            type: rentalPoolType,
            id: rentalPoolType,
            typeField:
              rentalPool[ind]?.typeField ? rentalPool[ind].typeField : "",
          },
        ];
        newAvgNumOfDaysPer = [
          ...newAvgNumOfDaysPer,
          {
            type: avgNumPerAnnumType,
            id: avgNumPerAnnumType,
            typeField:
              avgNumberOfDaysPerAnnum[ind]?.typeField
                ? avgNumberOfDaysPerAnnum[ind].typeField
                : "",
          },
        ];
        newStabilizedOccupancy = [
          ...newStabilizedOccupancy,
          {
            type: stabilizedOccType,
            id: stabilizedOccType,
            typeField:
              stabilizedOccupancy[ind]?.typeField
                ? stabilizedOccupancy[ind].typeField
                : "",
          },
        ];
      });
      setInitFormVal({
        ...formikValues,
        brandedResidencesFields: {
          ...formikValues.brandedResidencesFields,
          rentalPool: newRentalPool,
          avgNumberOfDaysPerAnnum: newAvgNumOfDaysPer,
          stabilizedOccupancy: newStabilizedOccupancy,
          adrPerUnit: newAdrPerUnit,
          /* otherRev: Array(10).fill(""), */
        },
      });
    } else {
      formikValues.unitMix.map((el) => {
        newInitData = [
          ...newInitData,
          {
            type: el.type,
            id: el.type,
            typeField: "",
          },
        ];
      });

      setInitFormVal({
        ...formikValues,
        brandedResidencesFields: {
          rentalPool: newInitData,
          avgNumberOfDaysPerAnnum: newInitData,
          stabilizedOccupancy: newInitData,
          adrPerUnit: newInitData,
          otherRev: Array(10).fill(""),
        },
      });
    }
  }, []);
  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);

  // useEffect(() => {
  //   var difference =
  //     new Date(formikValues?.startDateOpTime).getFullYear() - opStartDate;
  //   if (difference !== 0) {
  //     if (!unitMixChanged) {
  //       nullifyYearsField(
  //         step,
  //         opStartDate,
  //         formikValues,
  //         setFieldValue,
  //         formikValues?.startDateOpTime,
  //         ["brandedBaseFee"]
  //       );
  //       nullifyYearsField(
  //         step,
  //         opStartDate,
  //         formikValues,
  //         setFieldValue,
  //         formikValues?.startDateOpTime,
  //         ["brandedFranchiseFee"]
  //       );
  //       nullifyYearsField(
  //         step,
  //         opStartDate,
  //         formikValues,
  //         setFieldValue,
  //         formikValues?.startDateOpTime,
  //         ["brandedIncentiveFee"]
  //       );
  //     }
  //   }

  // }, []);

  return (
    <>
      <Box alignItems="flex-start" justifyContent="space-between" display="flex" width="100%">
        <MainTitle title="Branded Residences - Performance" />
        <CalculationButton  formikValue={formikValues} />

      </Box>
      <Box
        maxW={["100%", "100%", "50%"]}
        width={["100%", "100%", "50%"]}
        alignSelf="flex-start"
      ></Box>

      <Box
        maxW={["100%", "100%", "100%"]}
        width={["100%", "100%", "100%"]}
        alignSelf="flex-start"
      >
        <Accordion
          width="100%"
          allowToggle
          color="black"
          ref={inputRef}
          defaultIndex={[0]}
        >
          {formikValues?.brandedResidencesFields?.rentalPool &&
            renderAccrodionFields(
              formikValues?.brandedResidencesFields?.rentalPool,
              0
            )}
          {formikValues?.brandedResidencesFields?.avgNumberOfDaysPerAnnum &&
            renderAccrodionFields(
              formikValues?.brandedResidencesFields?.avgNumberOfDaysPerAnnum,
              1
            )}

          {formikValues?.brandedResidencesFields?.stabilizedOccupancy &&
            renderAccrodionFields(
              formikValues?.brandedResidencesFields?.stabilizedOccupancy,
              2
            )}

          {formikValues?.brandedResidencesFields?.adrPerUnit &&
            renderAccrodionFields(
              formikValues?.brandedResidencesFields?.adrPerUnit,
              3
            )}

          {formikValues?.brandedResidencesFields?.otherRev &&
            renderAccrodionFields(
              formikValues?.brandedResidencesFields?.otherRev,
              4
            )}
        </Accordion>
      </Box>

      <Box
        maxW={["100%", "100%", "65%"]}
        width={["100%", "100%", "65%"]}
        alignSelf="flex-start"
      >
        <Text className="title_md" display="flex" mb={5} mt={3} component="div">
          Additional Expenses{" "}
        </Text>
        <Box mb={5}>
          <InputFieldValidation
            label="Expenses 1 - as a % of revenue"
            inputType="number"
            inputId="expenses1"
            inputName="expenses1"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            icon={percent}
            isDisabled={isRestore || formikValues?.brandedResidence == "2"}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />
        </Box>
        <Box mb={5}>
          <InputFieldValidation
            label="Expenses 2 - value"
            inputType="number"
            inputId="expenses2"
            inputName="expenses2"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            // icon={percent}
            leftIcon={true}
            leftIconText={"SAR"}
            isDisabled={isRestore || formikValues?.brandedResidence == "2"}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />
        </Box>
        <Box mb={5}>
          <InputFieldValidation
            label="Expenses 3 - as a % of revenue"
            inputType="number"
            inputId="expenses3"
            inputName="expenses3"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            icon={percent}
            isDisabled={isRestore || formikValues?.brandedResidence == "2"}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />
        </Box>
        <Box mb={5}>
          <InputFieldValidation
            label="Expenses 4 - value"
            inputType="number"
            inputId="expenses4"
            inputName="expenses4"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            // icon={percent}
            leftIcon={true}
            leftIconText={"SAR"}
            isDisabled={isRestore || formikValues?.brandedResidence == "2"}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />
        </Box>
        <Box mb={5}>
          <InputFieldValidation
            label="Expenses 5 - as a % of revenue"
            inputType="number"
            inputId="expenses5"
            inputName="expenses5"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            icon={percent}
            isDisabled={isRestore || formikValues?.brandedResidence == "2"}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />
        </Box>

        <Text className="title_md" display="flex" mb={5} mt={3} component="div">
          Management Fees{" "}
        </Text>
        <Text
          className="title_sm"
          display="flex"
          mb={3}
          mt={3}
          component="div"
          letterSpacing="0.06em"
          fontSize="12px"
          textTransform="uppercase"
        >
          Base fee (% of Revenue){" "}
        </Text>
        <Box mb="8">{renderYearFields("brandedBaseFee")}</Box>
        <Text
          className="title_sm"
          display="flex"
          mb={3}
          mt={3}
          component="div"
          textTransform="uppercase"
          letterSpacing="0.06em"
          fontSize="12px"
        >
          Incentive Fee (% of AGOP){" "}
        </Text>
        <Box mb="8">{renderYearFields("brandedIncentiveFee")}</Box>
        <Text
          className="title_sm"
          display="flex"
          mb={2}
          mt={3}
          textTransform="uppercase"
          component="div"
          letterSpacing="0.06em"
          fontSize="12px"
        >
          Franchise Fee (% of Revenue){" "}
        </Text>
        <Box mb="8">{renderYearFields("brandedFranchiseFee")}</Box>
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex justifyContent={user == "manager" ? "end" : "space-between"} alignItems="center" width="100%">
        {
          user !== "manager" ?
            <MainButton
              title="Save Draft"
              btnStyle="transparent"
              disabledBtn={isRestore}
              fontColor="dark"
              onPress={() => {
                setDismissSave(false);
                onDraftOpen();
              }}
            /> : <></>
        }
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {/* <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm ? true : false}
              onPress={() => {
                nextPage();
                unsetStepWarning(page);
                if (percentage < 100) {
                  setPercentage(100);
                }
                setStepCompleted(page);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  handleAccordErrors();
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  nextPage();
                  unsetStepWarning(page);
                  if (percentage < 100) {
                    setPercentage(100);
                  }
                  setStepCompleted(page);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
