export const assetListStepsArr = (type, user = "admin") => {
  // console.log("type 2", type, user);
  user = user.toLowerCase();
  //remove for proponent
  // user = "proponent";
  if (user === "admin" || user ===  "manager") {
    if (type === "Standard") {
      return [
        {
          id: 1,
          title: "Asset Overview",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 2,
          title: "Development Cost Breakdown",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 3,
          title: "Financing Assumptions",
          completed: false,
          clicked: false,
          warning: false,
        },

        {
          id: 4,
          title: "Performance Asset List 1",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 5,
          title: "Performance Asset List 2",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 6,
          title: "F&B P&L",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 7,
          title: "External Visitors",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 8,
          title: "Departmental Expenses",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 9,
          title: "Undistributed Expenses and Charges",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 10,
          title: "Operator Terms and Staffing",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 11,
          title: "Branded Residences - General",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 12,
          title: "Branded Residences - Performance",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 13,
          title: "Additional Details and Submit",
          completed: false,
          clicked: false,
          warning: false,
        },
      ];
    } else {
      return [
        {
          id: 1,
          title: "Asset Overview",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 2,
          title: "Development Cost Breakdown",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 3,
          title: "Financing Assumptions",
          completed: false,
          clicked: false,
          warning: false,
        },

        {
          id: 4,
          title: "Performance Asset List 1",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 5,
          title: "Performance Asset List 2",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 6,
          title: "External Visitors",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 7,
          title: "Other Revenue Breakdown",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 8,
          title: "Departmental Expenses",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 9,
          title: "Undistributed Expenses and Charges",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 10,
          title: "Operator Terms and Staffing",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 11,
          title: "Branded Residences - General",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 12,
          title: "Branded Residences - Performance",
          completed: false,
          clicked: false,
          warning: false,
        },
        {
          id: 13,
          title: "Additional Details and Submit",
          completed: false,
          clicked: false,
          warning: false,
        },
      ];
    }
  } else if (user === "proponent") {
    return [
      {
        id: 1,
        title: "Asset Overview",
        completed: false,
        clicked: false,
        warning: false,
      },
      {
        id: 2,
        title: "Development Cost Breakdown",
        completed: false,
        clicked: false,
        warning: false,
      },
      {
        id: 3,
        title: "Additional Details and Submit",
        completed: false,
        clicked: false,
        warning: false,
      },
    ];
  }
};
export const assetListDraftStepsArr = (type, formData, user = "admin") => {
  user = user.toLowerCase();
  //remove for proponent
  // user = "proponent";
  if (user === "admin" || user === "manager") {
    let { formFields } = formData;
    let {
      developmentCostValues,
      financingAssumptions,
      departmentalExpenses,
      operatorTermsAndStaffing,
      brandedResidencesGeneral,
      brandedResidencesPerformance,
    } = formFields;
    let { general, breakdown, infrastructure, parking } = developmentCostValues;
    let { risk, sale, terminalValue } = financingAssumptions;

    let { undistributedExpensesCharges } = departmentalExpenses;
    let { fixedCharges } = departmentalExpenses.undistributedExpensesCharges;
    let { staffing, otherFees, managementFees } = operatorTermsAndStaffing;
    let { feesAndContributions, annualSalesAbsorption, unitTypes } =
      brandedResidencesGeneral;
    let form1 = [];
    let form2 = [];
    let form3 = [];
    let form4 = [];
    let form5 = [];
    let formPackage = [];
    let form6 = [];
    let form7 = [];
    let form8 = [];
    let form9 = [];
    let form10 = [];
    let form11 = [];
    let form12=[];
    let form13=[];
    form1.push(formData.regionLookup);
    form1.push(formFields.brandLookup);
    form1.push(formFields.operatorLookup);
    form1.push(formFields.positioningLookup);
    form1.push(formFields.projectStageLookup);
    form1.push(formFields.projectStatusLookup);
    form1.push(formFields.managementContractLookup);
    form1.push(formFields.numberOfKeys);
    form1.push(formFields.plotSize);
    form1.push(formFields.grossFloorArea);
    form1.push(formFields.parkingArea);
    form1.push(formFields.developmentTimeCompletionDate);
    form1.push(formFields.developmentTimeDurationMonths);
    form1.push(formFields.developmentTimeStartDate);
    form1.push(formFields.operationStartDate);
    form1.push(formFields.operationTimeDurationMonths);
    form1.push(formFields.operationTimeEndDate);
    let form1IsEmpty = form1.filter((el) => el === "");
    form2.push(infrastructure.developmentStartMonth);
    form2.push(breakdown.FFE_OSE.depreciation);
    form2.push(breakdown.NEOMDevelopmentFee.depreciation);
    form2.push(breakdown.TSA.depreciation);
    form2.push(breakdown.construction.depreciation);
    form2.push(breakdown.contingency.depreciation);
    form2.push(breakdown.preOpening.depreciation);
    form2.push(breakdown.softCosts.depreciation);
    form2.push(infrastructure.depreciation);
    form2.push(parking.depreciation);

    // form2.push(general.developmentCostPerKey_SAR);
    // form2.push(general.landCostFreehold_SAR);
    let devAbsorptionArr = [];
    general.developmentAbsorptionLand.forEach((el) => {
      devAbsorptionArr.push(el);
    });
    let countDevZeros = 0;
    devAbsorptionArr.forEach((el) => {
      if (el === 0) {
        countDevZeros++;
      }
    });
    if (countDevZeros !== 5) {
      devAbsorptionArr.forEach((el) => {
        form2.push(el);
      });
    }
    // form2.push(infrastructure.cost_SAR);
    form2.push(infrastructure.developmentDuration);
    // form2.push(parking.cost_SAR);
    // form2.push(breakdown.percentageOfBaseCost);
    // form2.push(developmentCostValues.refurbishmentCost);
    let checkValidationForm2 = false;
    let tempArrStep2 = [];
    let form2IsEmpty = form2.filter((el, ind) => {
      if (ind !== 10 || ind !== 11 || ind !== 12 || ind !== 13 || ind !== 14) {
        return el === "";
      }
      return false;
    });

    // form2.forEach((el, ind) => {
    //   if (ind === 10 || ind === 11 || ind === 12 || ind === 13 || ind === 14) {
    //     tempArrStep2.push(el);
    //   }
    // });
    devAbsorptionArr.forEach((el, ind) => {
      // if (ind === 10 || ind === 11 || ind === 12 || ind === 13 || ind === 14) {
      tempArrStep2.push(el);
      // }
    });

    if (
      tempArrStep2.reduce((partialSum, a) => partialSum + a, 0) !== 100 &&
      tempArrStep2.reduce((partialSum, a) => partialSum + a, 0) !== 0
    ) {
      checkValidationForm2 = true;
    }
    // console.log("form2", form2, form2IsEmpty, checkValidationForm2);
    form3.push(financingAssumptions.general.equity);
    form3.push(financingAssumptions.general.openingCashBalance);
    form3.push(financingAssumptions.general.taxRate);
    form3.push(sale.sellingCosts);
    form3.push(sale.taxOnSaleValue);
    form3.push(risk.buffer);
    form3.push(terminalValue.ebidtaMultiple);

    let form3IsEmpty = form3.filter((el) => el === "");
    let formPackageIsEmpty;
    if (type === "Standard") {
      formFields.topLineAssumptions_Standard.occupancyIncreaseRate.forEach(
        (el) => {
          form4.push(el);
        }
      );
      form4.push(formFields.topLineAssumptions_Standard.pricingInflator);
      form4.push(formFields.topLineAssumptions_Standard.avgLengthOfStay);
      form4.push(formFields.topLineAssumptions_Standard.guestsPerOccupiedRoom);
      formFields.topLineAssumptions_Standard.roomTypes.forEach((el) => {
        form4.push(el.size);
        form4.push(el.occupancyRate);
        form4.push(el.numberOfKeys);
      });

      //form 5
      formFields.topLineAssumptions_Standard.averageDailyRate.forEach((el) => {
        el.rates.forEach((e) => form5.push(e));
      });
      let perf2Keys = [
        "banquet",
        "foodAndBeverage",
        "meetingEvents",
        "miscellaneous",
        "other1",
        "other2",
        "recreation",
        "rental",
        "retail",
        "spa",
        "otherProfitDepartments",
      ];
      perf2Keys.forEach((key) => {
        formFields.topLineAssumptions_Standard.otherRevenues[key].forEach(
          (el) => {
            form5.push(el);
          }
        );
      });
    } else {
      formFields.topLineAssumptions_Package.occupancyIncreaseRate.forEach(
        (el) => {
          form4.push(el);
        }
      );
      form4.push(formFields.topLineAssumptions_Package.pricingInflator);
      form4.push(formFields.topLineAssumptions_Package.avgLengthOfStay);
      form4.push(formFields.topLineAssumptions_Package.guestsPerOccupiedRoom);
      formFields.topLineAssumptions_Package.packageTypes.forEach((el) => {
        form4.push(el.gpor);
        form4.push(el.occupancyRate);
        form4.push(el.numberOfKeys);
      });

      let perf2Keys = [
        "foodAndBeverage",
        "otherRates",
        "packageRate",
        "roomRate",
      ];
      perf2Keys.forEach((key) => {
        formFields.topLineAssumptions_Package[key].forEach((el) => {
          el.rates.forEach((e) => form5.push(e));
        });
      });

      let otherRevKeys = [
        "banquet",
        "meetingEvents",
        "miscellaneous",
        "other1",
        "packages",
        "otherProfitDepartments",
        "recreation",
        "rental",
        "retail",
        "spa",
      ];

      otherRevKeys.forEach((el) => {
        formFields.topLineAssumptions_Package.otherRevenues[el].forEach(
          (item) => {
            formPackage.push(item);
          }
        );
      });

      formPackageIsEmpty = formPackage.filter((el) => el === "");
    }
    const formPackageIsAllZeroes = formPackage.every((item) => item === 0);

    let validateForm4 = false;
    let form4IsEmpty = form4.filter((el, index) => el === "");
    form4IsEmpty = form4.map((el, index) => {
      if (
        index === 0 ||
        index === 1 ||
        index === 2 ||
        index === 3 ||
        index === 4 ||
        index === 5
      ) {
        form4.slice(6).forEach((element) => {
          if (element === "") validateForm4 = true;
        });
      }
    });
    let form5IsEmpty = form5.filter((el) => el === "");
    const step5IsAllZeros = form5.every((item) => item === 0);

    //form 6
    form6.push(departmentalExpenses.economiesOfScaleApplied);
    form6.push(departmentalExpenses.benchmarkType);
    form6.push(departmentalExpenses.positioningLookup);
    form6.push(departmentalExpenses.expenseLevel);

    let arrKeys = [];
    arrKeys = Object.keys(departmentalExpenses);

    if (arrKeys.length > 0) {
      arrKeys.forEach((el) => {
        if (departmentalExpenses[el]?.costOfSales) {
          form6.push(departmentalExpenses[el].costOfSales.value);
          form6.push(departmentalExpenses[el].costOfSales.fixed);
        }
        if (departmentalExpenses[el]?.other) {
          form6.push(departmentalExpenses[el].other.value);
          form6.push(departmentalExpenses[el].other.fixed);
        }
        if (departmentalExpenses[el]?.payroll) {
          form6.push(departmentalExpenses[el].payroll.value);
          form6.push(departmentalExpenses[el].payroll.fixed);
        }
        if (departmentalExpenses[el]?.payrollYearlyIncrement) {
          form6.push(departmentalExpenses[el].payrollYearlyIncrement.year1);
          form6.push(departmentalExpenses[el].payrollYearlyIncrement.year2);
          form6.push(departmentalExpenses[el].payrollYearlyIncrement.year3);
        }
      });
    }
    let form6IsEmpty = form6.filter((el) => el === "");

    let arrKeys2 = [];

    arrKeys2 = Object.keys(undistributedExpensesCharges);

    form7.push(fixedCharges.income); //string
    form7.push(fixedCharges.operationalInsurance); //string
    form7.push(fixedCharges.permitsAndLicenses); //string
    form7.push(fixedCharges.landLeaseFee); //string
    form7.push(fixedCharges.otherFixedCharges); //string
    form7.push(fixedCharges.propertyAndOtherTaxes); //string
    form7.push(fixedCharges.NEOMHotelsDevelopmentFee); //string
    form7.push(fixedCharges.otherFixedChargesLandLease); //string
    if (arrKeys2.length > 0) {
      arrKeys2.forEach((el) => {
        if (undistributedExpensesCharges[el]?.other) {
          form7.push(undistributedExpensesCharges[el].other.value);
          form7.push(undistributedExpensesCharges[el].other.fixed);
        }
        if (undistributedExpensesCharges[el]?.payroll) {
          form7.push(undistributedExpensesCharges[el].payroll.value);
          form7.push(undistributedExpensesCharges[el].payroll.fixed);
        }
        if (undistributedExpensesCharges[el]?.otherPerKey) {
          form7.push(undistributedExpensesCharges[el].otherPerKey.value);
          form7.push(undistributedExpensesCharges[el].otherPerKey.fixed);
        }
        if (undistributedExpensesCharges[el]?.creditCardCommissions) {
          form7.push(
            undistributedExpensesCharges[el].creditCardCommissions.value
          );
        }
        if (undistributedExpensesCharges[el]?.payrollYearlyIncrement) {
          form7.push(
            undistributedExpensesCharges[el].payrollYearlyIncrement.year1
          );
          form7.push(
            undistributedExpensesCharges[el].payrollYearlyIncrement.year2
          );
          form7.push(
            undistributedExpensesCharges[el].payrollYearlyIncrement.year3
          );
        }
        if (undistributedExpensesCharges[el]?.otherPayrollYearlyIncrement) {
          form7.push(
            undistributedExpensesCharges[el].otherPayrollYearlyIncrement.year1
          );
          form7.push(
            undistributedExpensesCharges[el].otherPayrollYearlyIncrement.year2
          );
          form7.push(
            undistributedExpensesCharges[el].otherPayrollYearlyIncrement.year3
          );
        }
        if (undistributedExpensesCharges[el]?.otherExpensesYearlyIncrement) {
          form7.push(
            undistributedExpensesCharges[el].otherExpensesYearlyIncrement.year1
          );
          form7.push(
            undistributedExpensesCharges[el].otherExpensesYearlyIncrement.year2
          );
          form7.push(
            undistributedExpensesCharges[el].otherExpensesYearlyIncrement.year3
          );
        }
      });
    }
    let form7IsEmpty = form7.filter((el) => el === "");

    otherFees.ffeReserve.forEach((el) => form8.push(el));
    form8.push(managementFees.franchiseFee);
    form8.push(otherFees.reservationContribution);
    form8.push(otherFees.adminAndGeneral);
    // form8.push(otherFees.includeFranchiseFee);
    // form8.push(otherFees.percentageCap);
    // form8.push(otherFees.revenueType);
    // form8.push(otherFees.franchiseFeeLabel);
    // form8.push(otherFees.reservationContributionRevenueType);
    // form8.push(otherFees.reservationContributionLabel);
    // form8.push(otherFees.adminAndGeneralRevenueType);
    // form8.push(otherFees.adminAndGeneralLabel);

    form8.push(otherFees.marketingContributionType);
    form8.push(otherFees.marketingContribution);
    form8.push(otherFees.rewardProgram);
    form8.push(otherFees.percentageOfRoomBookings);
    form8.push(otherFees.operatorOtherProgramCost);
    form8.push(otherFees.technicalFeeToOperator);
    form8.push(otherFees.leaseFixed);
    form8.push(otherFees.leaseVariable);
    form8.push(staffing.staffToKeyRatio);
    form8.push(staffing.staffPhasing);
    form8.push(staffing.staffHousingCostPerStaff);
    let form8IsEmpty = form8.filter((element, index) => {
      if (index <= 4) {
        return element === "" || element === 0;
      } else {
        return element === "";
      }
    });
    const validateForm8 =
      form8IsEmpty.length === 5 && form8IsEmpty.every((item) => item === 0);
    annualSalesAbsorption.forEach((el) => form9.push(el));
    form9.push(brandedResidencesGeneral.GFAEfficiency);
    let disableBrandedPerformance = brandedResidencesGeneral.isOnOff;
    unitTypes.forEach((el) => {
      form9.push(el.baseCostPerKey_SAR);
      form9.push(el.totalSellable);
      form9.push(el.noOfUnits);
      form9.push(el.salesPricePerSqm);
    });
    form9.push(feesAndContributions.operatorContributions);
    form9.push(feesAndContributions.adminExpenses);
    form9.push(feesAndContributions.marketingSellingCost);
    form9.push(feesAndContributions.residentialSaleFee_USD);
    form9.push(feesAndContributions.adminChargesToOwner);
    form9.push(feesAndContributions.shareOfResidenceOwner);
    form9.push(feesAndContributions.minimumResidenceOwnerReturnValue);
    form9.push(feesAndContributions.finalAssetSaleValueIncrease);

    let form9IsEmpty = form9.filter((element, index) => {
      if (index <= 4) {
        return element === "" || element === 0;
      } else {
        return element === "";
      }
    });

    let arrKey3 = [];

    arrKey3 = Object.keys(brandedResidencesPerformance);
    brandedResidencesPerformance.additionalExpenses.forEach((el) =>
      form10.push(el)
    );
    if (arrKey3.length > 0) {
      arrKey3.forEach((el) => {
        if (
          brandedResidencesPerformance[el] &&
          el !== "otherRevenues" &&
          el === "managementFees" &&
          el !== "additionalExpenses"
        ) {
          brandedResidencesPerformance[el].baseFee.forEach((el) =>
            form10.push(el)
          );
          brandedResidencesPerformance[el].franchiseFee.forEach((el) =>
            form10.push(el)
          );
          brandedResidencesPerformance[el].incentiveFee.forEach((el) =>
            form10.push(el)
          );
        }
        if (
          brandedResidencesPerformance[el] &&
          el !== "otherRevenues" &&
          el !== "managementFees" &&
          el !== "additionalExpenses"
        ) {
          brandedResidencesPerformance[el].forEach((item) => {
            form10.push(item.value);
          });
        }
        if (
          brandedResidencesPerformance[el] &&
          el === "otherRevenues" &&
          el !== "managementFees" &&
          el !== "additionalExpenses"
        ) {
          form10.push(brandedResidencesPerformance[el].incBanquet);
          form10.push(
            brandedResidencesPerformance[el].incFoodAndBeverageOutlets
          );
          form10.push(brandedResidencesPerformance[el].incMiscellaneousIncome);
          form10.push(brandedResidencesPerformance[el].incOther1);
          form10.push(brandedResidencesPerformance[el].incOther2);
          form10.push(
            brandedResidencesPerformance[el].incOtherProfitDepartments
          );
          form10.push(brandedResidencesPerformance[el].incRecreation);
          form10.push(brandedResidencesPerformance[el].incRental);
          form10.push(brandedResidencesPerformance[el].incRetail);
          form10.push(brandedResidencesPerformance[el].incSpa);
        }
      });
    }

    let form10IsEmpty = form10.filter((element, index) => {
      if (index < 5 || index > 14) return element === 0 || element === "";
      else return element === "";
    });
    const validateStep10 = form10IsEmpty.every((item) => item === 0);

    form11.push(formData.assignedTo);
    form11.push(formData.approveAssetInfo);

    let form11IsEmpty = form11.filter((element, index) => {
      if (index === 0) {
        return element === "";
      } else {
        return element === false;
      }
    });


    let externalVisitorsKeys = [
      "numberOfVisitors",
      "foodAndBeveragePerVisitor",
      "recreationPerVisitor",
      "spaPerVisitor",
      "otherOnePerVisitor",
      "otherTwoPerVisitor",
    ]
    externalVisitorsKeys.forEach((key) => {
      formFields.externalVisitors?.[key].forEach(
        (el) => {
          form12.push(el);
        }
      );
    });

    let form12IsEmpty = form12.filter((el) => el === "");

    form13 = collectValues(formFields.fnbPnl);


    let form13IsEmpty = form13.filter((el) => el === "");


    if (type === "Standard") {
      return [
        {
          id: 1,
          title: "Asset Overview",
          completed: form1IsEmpty.length > 0 ? false : true,
          clicked:
            form1IsEmpty.length > 0 && form1IsEmpty.length === form1.length
              ? false
              : true,
          warning:
            form1IsEmpty.length !== form1.length && form1IsEmpty.length > 0
              ? true
              : false,
        },
        {
          id: 2,
          title: "Development Cost Breakdown",
          completed:
            form2IsEmpty.length > 0 || checkValidationForm2 ? false : true,
          clicked:
            form2IsEmpty.length > 0 && form2IsEmpty.length === form2.length
              ? false
              : true,
          warning:
            (form2IsEmpty.length !== form2.length && form2IsEmpty.length > 0) ||
            checkValidationForm2
              ? true
              : false,
        },
        {
          id: 3,
          title: "Financing Assumptions",
          completed: form3IsEmpty.length > 0 ? false : true,
          clicked:
            form3IsEmpty.length > 0 && form3IsEmpty.length === form3.length
              ? false
              : true,
          warning:
            form3IsEmpty.length !== form3.length && form3IsEmpty.length > 0
              ? true
              : false,
        },

        {
          id: 4,
          title: "Performance Asset List 1",
          completed: form4IsEmpty.length > 0 && validateForm4 ? false : true,
          clicked:
            form4IsEmpty.length > 0 &&
            form4IsEmpty.length === form4.length &&
            validateForm4
              ? false
              : true,
          warning:
            form4IsEmpty.length !== form4.length && form4IsEmpty.length > 0
              ? true
              : false,
        },
        {
          id: 5,
          title: "Performance Asset List 2",
          completed:
            form5IsEmpty.length > 0 /* || step5IsAllZeros */ ? false : true,
          clicked:
            form5IsEmpty.length > 0 &&
            form5IsEmpty.length === form5.length /* ||
            step5IsAllZeros
              ? false
              : true */,
          warning:
            form5IsEmpty.length !== form5.length && form5IsEmpty.length > 0
              ? true
              : false,
        },
        {
          id: 6,
          title: "F&B P&L",
          completed:
            form13IsEmpty.length > 0  ? false : true,
          clicked:
            (form13IsEmpty.length > 0  &&
            form13IsEmpty.length === form13.length)  
              ? false
              : true ,
          warning:
            form13IsEmpty.length !== form13.length && form13IsEmpty.length > 0
              ? true
              : false,
        },
        {
          id: 7,
          title: "External Visitors",
          completed:
            form12IsEmpty.length > 0 /* || step12IsAllZeros */ ? false : true,
          clicked:
            form12IsEmpty.length > 0 &&
            form12IsEmpty.length === form12.length /* ||
            step5IsAllZeros
              ? false
              : true */,
          warning:
            form12IsEmpty.length !== form12.length && form12IsEmpty.length > 0
              ? true
              : false,
        },
       
        {
          id: 8,
          title: "Departmental Expenses",
          completed: form6IsEmpty.length > 0 ? false : true,
          clicked:
            form6IsEmpty.length > 0 && form6IsEmpty.length === form6.length
              ? false
              : true,
          warning:
            form6IsEmpty.length !== form6.length && form6IsEmpty.length > 0
              ? true
              : false,
        },
        {
          id: 9,
          title: "Undistributed Expenses and Charges",
          completed: form7IsEmpty.length > 0 ? false : true,
          clicked:
            form7IsEmpty.length > 0 && form7IsEmpty.length === form7.length
              ? false
              : true,
          warning:
            form7IsEmpty.length !== form7.length && form7IsEmpty.length > 0
              ? true
              : false,
        },
        {
          id: 10,
          title: "Operator Terms and Staffing",
          completed: form8IsEmpty.length > 0 && !validateForm8 ? false : true,
          clicked:
            form8IsEmpty.length > 0 && form8IsEmpty.length === form8.length
              ? false
              : true,
          warning:
            form8IsEmpty.length !== form8.length &&
            form8IsEmpty.length > 0 &&
            !validateForm8
              ? true
              : false,
        },
        {
          id: 11,
          title: "Branded Residences - General",
          completed:
            form9IsEmpty.length > 0 &&
            !disableBrandedPerformance &&
            form9IsEmpty.length !== 5
              ? false
              : true,
          clicked:
            form9IsEmpty.length > 0 &&
            form9IsEmpty.length === form9.length &&
            !disableBrandedPerformance
              ? false
              : true,
          warning:
            form9IsEmpty.length !== form9.length &&
            form9IsEmpty.length > 0 &&
            form9IsEmpty.length !== 5 &&
            disableBrandedPerformance
              ? true
              : false,
        },
        {
          id: 12,
          title: "Branded Residences - Performance",
          completed:
            (form10IsEmpty.length > 0 && !validateStep10) ||
            !disableBrandedPerformance
              ? false
              : true,
          clicked:
            (form10IsEmpty.length > 0 &&
              form10IsEmpty.length === form10.length) ||
            !disableBrandedPerformance
              ? false
              : true,
          warning:
            form10IsEmpty.length !== form10.length &&
            form10IsEmpty.length > 0 &&
            !validateStep10 &&
            disableBrandedPerformance
              ? true
              : false,
        },
        {
          id: 13,
          title: "Additional Details and Submit",
          completed: form11IsEmpty.length > 0 ? false : true,
          clicked:
            form11IsEmpty.length > 0 && form11IsEmpty.length === form11.length
              ? false
              : true,
          warning: false,
        },
      ];
    } else {
      return [
        {
          id: 1,
          title: "Asset Overview",
          completed: form1IsEmpty.length === 0 ? true : false,
          clicked:
            form1IsEmpty.length > 0 && form1IsEmpty.length === form1.length
              ? false
              : true,
          warning:
            form1IsEmpty.length !== form1.length && form1IsEmpty.length > 0
              ? true
              : false,
        },
        {
          id: 2,
          title: "Development Cost Breakdown",
          completed:
            form2IsEmpty.length > 0 || checkValidationForm2 ? false : true,
          clicked:
            form2IsEmpty.length > 0 && form2IsEmpty.length === form2.length
              ? false
              : true,
          warning:
            (form2IsEmpty.length !== form2.length && form2IsEmpty.length > 0) ||
            checkValidationForm2
              ? true
              : false,
        },
        {
          id: 3,
          title: "Financing Assumptions",
          completed: form3IsEmpty.length === 0 ? true : false,
          clicked:
            form3IsEmpty.length > 0 && form3IsEmpty.length === form3.length
              ? false
              : true,
          warning:
            form3IsEmpty.length !== form3.length && form3IsEmpty.length > 0
              ? true
              : false,
        },

        {
          id: 4,
          title: "Performance Asset List 1",
          completed: form4IsEmpty.length > 0 && validateForm4 ? false : true,
          clicked:
            form4IsEmpty.length > 0 &&
            form4IsEmpty.length === form4.length &&
            validateForm4
              ? false
              : true,
          warning:
            form4IsEmpty.length !== form4.length && form4IsEmpty.length > 0
              ? true
              : false,
        },
        {
          id: 5,
          title: "Performance Asset List 2",
          completed:
            form5IsEmpty.length > 0 &&
            formFields?.topLineAssumptions_Package?.roomRate?.length > 0
              ? true
              : false,
          clicked:
            form5.length !== 0 &&
            formFields?.topLineAssumptions_Package?.roomRate?.length > 0
              ? true
              : false,
          warning:
            form5IsEmpty.length !== form5.length && form5IsEmpty.length > 0
              ? true
              : false,
        },
        {
          id: 6,
          title: "External Visitors",
          completed:
            form12IsEmpty.length > 0 /* || step12IsAllZeros */ ? false : true,
          clicked:
            form12IsEmpty.length > 0 &&
            form12IsEmpty.length === form12.length /* ||
            step12IsAllZeros
              ? false
              : true */,
          warning:
            form12IsEmpty.length !== form12.length && form12IsEmpty.length > 0
              ? true
              : false,
        },
        {
          id: 7,
          title: "Other Revenue Breakdown",
          completed:
            formPackageIsEmpty.length === 0 /* && !formPackageIsAllZeroes */
              ? true
              : false,
          clicked:
            formPackage.length !== 0 && formPackageIsEmpty.length > 0 /* &&
            !formPackageIsAllZeroes */
              ? true
              : false,

          warning:
            formPackageIsEmpty.length !== formPackage.length &&
            formPackageIsEmpty.length > 0
              ? true
              : false,
        },
        {
          id: 8,
          title: "Departmental Expenses",
          completed: form6IsEmpty.length === 0 ? true : false,
          clicked:
            form6IsEmpty.length > 0 && form6IsEmpty.length === form6.length
              ? false
              : true,
          warning:
            form6IsEmpty.length !== form6.length && form6IsEmpty.length > 0
              ? true
              : false,
        },
        {
          id: 9,
          title: "Undistributed Expenses and Charges",
          completed: form7IsEmpty.length === 0 ? true : false,
          clicked:
            form7IsEmpty.length > 0 && form7IsEmpty.length === form7.length
              ? false
              : true,
          warning:
            form7IsEmpty.length !== form7.length && form7IsEmpty.length > 0
              ? true
              : false,
        },

        {
          id: 10,
          title: "Operator Terms and Staffing",
          completed: form8IsEmpty.length > 0 && !validateForm8 ? false : true,
          clicked:
            form8IsEmpty.length > 0 && form8IsEmpty.length === form8.length
              ? false
              : true,
          warning:
            form8IsEmpty.length !== form8.length &&
            form8IsEmpty.length > 0 &&
            !validateForm8
              ? true
              : false,
        },
        {
          id: 11,
          title: "Branded Residences - General",
          completed:
            form9IsEmpty.length > 0 &&
            !disableBrandedPerformance &&
            form9IsEmpty.length !== 5
              ? false
              : true,
          clicked:
            form9IsEmpty.length > 0 &&
            form9IsEmpty.length === form9.length &&
            !disableBrandedPerformance
              ? false
              : true,
          warning:
            form9IsEmpty.length !== form9.length &&
            form9IsEmpty.length > 0 &&
            form9IsEmpty.length !== 5 &&
            disableBrandedPerformance
              ? true
              : false,
        },
        {
          id: 12,
          title: "Branded Residences - Performance",
          completed:
            (form10IsEmpty.length > 0 && !validateStep10) ||
            !disableBrandedPerformance
              ? false
              : true,
          clicked:
            (form10IsEmpty.length > 0 &&
              form10IsEmpty.length === form10.length) ||
            !disableBrandedPerformance
              ? false
              : true,
          warning:
            form10IsEmpty.length !== form10.length &&
            form10IsEmpty.length > 0 &&
            !validateStep10 &&
            disableBrandedPerformance
              ? true
              : false,
        },
        {
          id: 13,
          title: "Additional Details and Submit",
          completed: form11IsEmpty.length > 0 ? false : true,
          clicked:
            form11IsEmpty.length > 0 && form11IsEmpty.length === form11.length
              ? false
              : true,
          warning: false,
        },
      ];
    }
  } else if (user === "proponent") {
    let { formFields } = formData;
    let { developmentCostValues } = formFields;
    let { general, breakdown, infrastructure, parking } = developmentCostValues;

    let form1 = [];
    let form2 = [];

    form1.push(formData.regionLookup);
    form1.push(formFields.brandLookup);
    form1.push(formFields.operatorLookup);
    form1.push(formFields.positioningLookup);
    form1.push(formFields.projectStageLookup);
    form1.push(formFields.projectStatusLookup);
    form1.push(formFields.managementContractLookup);
    form1.push(formFields.numberOfKeys);
    form1.push(formFields.plotSize);
    form1.push(formFields.grossFloorArea);
    form1.push(formFields.parkingArea);
    form1.push(formFields.developmentTimeCompletionDate);
    form1.push(formFields.developmentTimeDurationMonths);
    form1.push(formFields.developmentTimeStartDate);
    form1.push(formFields.operationStartDate);
    form1.push(formFields.operationTimeDurationMonths);
    form1.push(formFields.operationTimeEndDate);
    let form1IsEmpty = form1.filter((el) => el === "");

    form2.push(general.developmentCostPerKey_SAR);
    form2.push(general.landCostFreehold_SAR);

    form2.push(infrastructure.cost_SAR);
    form2.push(parking.cost_SAR);
    form2.push(breakdown.percentageOfBaseCost);
    form2.push(developmentCostValues.refurbishmentCost);
    let form2IsEmpty = form2.filter((el, ind) => {
      if (ind === 0) {
        return el === 0;
      } else {
        return el === "";
      }
    });

    return [
      {
        id: 1,
        title: "Asset Overview",
        completed: form1IsEmpty.length > 0 ? false : true,
        clicked:
          form1IsEmpty.length > 0 && form1IsEmpty.length === form1.length
            ? false
            : true,
        warning:
          form1IsEmpty.length !== form1.length && form1IsEmpty.length > 0
            ? true
            : false,
      },

      {
        id: 2,
        title: "Development Cost Breakdown",
        completed: form2IsEmpty.length > 0 ? false : true,
        clicked:
          form2IsEmpty.length > 0 && form2IsEmpty.length === form2.length
            ? false
            : true,
        warning:
          form2IsEmpty.length !== form2.length && form2IsEmpty.length > 0
            ? true
            : false,
      },
      {
        id: 3,
        title: "Additional Details and Submit",
        completed: form2IsEmpty.length > 0 ? false : true,
        clicked:
          form2IsEmpty.length > 0 && form2IsEmpty.length === form2.length
            ? false
            : true,
        warning: false,
      },
    ];
  }
};


const collectValues = (obj, values = []) => {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      collectValues(obj[key], values);
    } else {
      values.push(obj[key]);
    }
  }
  return values;
};
