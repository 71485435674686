import React, { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import { Box, Text, Center, Spinner, useDisclosure } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import {
  GetSpecificAssetsListAPI,
  GetProjectNameAPI,
  DeleteAssetListDraftAPI,
  GetAssetListCommentsAPI,
  GetDescriptionAPI,
} from "../../api/AssetListAPI";

import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { MainButton } from "../../components/button/MainButton";
import { AssetVersionHistoryTable } from "./AssetVersionHistoryTable";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";

import doc from "../../assets/images/icons/doc.svg";
import plus from "../../assets/images/icons/plus.svg";
import infoCircle from "../../assets/images/icons/infoCircle.svg";

export const AssetVersionHistory = () => {
  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const { id } = useParams();
  const [assetData, setAssetData] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [comments, setComments] = useState([]);
  const [description, setDescription] = useState("");
  const [selectedID, setSelectedID] = useState("-1");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState();
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);

  const loadTableData = useCallback(() => {
    let ignoreResult = false;
    document.getElementById("main_content").scrollTo(0, 0);

    GetSpecificAssetsListAPI(id)
      .then((res) => {
        if (ignoreResult) {
          return;
        }
        setAssetData(res.data);
        setIsDownloadEnabled(!!res.data.isDownloadEnabled);
        setIsLoading(false);
        // console.log("GET ASSET", res.data);
        //return res;
        //return res.data.pageRows[0].projectNameValue;
      })
      /* .then((res) => {
        console.log("1111", res);
      }) */
      .catch((err) => {
        console.log(err);
      });
    GetProjectNameAPI(id)
      .then((res) => {
        if (ignoreResult) {
          return;
        }
        // console.log("GET ASSET 2", res.projectName);
        setProjectName(res.projectName);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      ignoreResult = true;
    }
  }, [id]);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  useEffect(() => {
    if (Cookies.get("user_info")) {
      let token = Cookies.get("user_info");
      let tokenDecode = jwt_decode(token);
      setUser(tokenDecode);
    }
    GetDescriptionAPI(id)
    .then((res) => {
      setDescription(res);
      //return res;
    })
    .catch((err) => {
      console.log(err);
    });
    
    GetAssetListCommentsAPI(id)
      .then((res) => {
        setComments(res.data);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      }); 
  }, []);

  const renderRowsAfterDeletion = (id) => {
    if (!id.startsWith("draft|")) {
      return;
    }
    // console.log(">> deleting", id);

    DeleteAssetListDraftAPI(
      id.split("|")[1],
      id.split("|")[2],
      id.split("|")[3]
    )
      .then((res) => {
        setErrorMessage("");
        setIsLoading(true);
        if (res.success === true) {
          loadTableData();
        }
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "Something went wrong");
        onErrorOpen();
      });
  };

  // console.log("assetData?.data?.length", assetData?.pageRows);
  return (
    <>
      {isLoading ? (
        <>
          <MainTitle title="LIST OF ASSETS" />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {assetData?.pageRows?.length <= 0 ? (
            <>
              <MainTitle title={`${projectName}`} />
              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">
                        No asset list created yet
                      </Text>
                    </Box>
                    {user.roles[0].toLowerCase() !== "proponent" && (
                      <MainButton
                        title="Create New"
                        marginRight="1"
                        icon={plus}
                        navLink={true}
                        path="/AssetList"
                      />
                    )}
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <AssetVersionHistoryTable
                assetData={assetData}
                comments={comments}
                setSelectedID={setSelectedID}
                onDelModalOpen={onDelModalOpen}
                assetId={id}
                projectName={projectName}
                user={user}
                isDownloadEnabled={isDownloadEnabled}
                description={description}
              />
            </>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isDelModalOpen}
        onClose={onDelModalClose}
        title="Are you sure you want to delete this version?"
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={true}
        haveSaveDraft={true}
        isDelete={true}
        selectedID={selectedID}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        renderRowsAfterDeletion={renderRowsAfterDeletion}
        assetVersionModal={assetData?.pageRows?.length === 1}
      />

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
    </>
  );
};
