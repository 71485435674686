export const regionData = [
  {
    id: 1,
    regionName: "Gulf Of Aqaba",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    isActive: "active",
    action: ["edit"],
    actionColor: "#6F767E",
  },
  {
    id: 2,
    regionName: "Archipelago",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    isActive: "active",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 3,
    regionName: "Landscapes of The Line",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    isActive: "active",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 4,
    regionName: "Nature Reserve",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    isActive: "active",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 5,
    regionName: "Al Farshah",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    isActive: "active",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 6,
    regionName: "Oxagon",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    isActive: "active",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 7,
    regionName: "Torjena",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    isActive: "active",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 8,
    regionName: "Sindalah",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    isActive: "active",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 9,
    regionName: "Outside regions",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    isActive: "active",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 10,
    regionName: "Shushah",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    isActive: "active",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 11,
    regionName: "Yuba",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    isActive: "Inactive",
    action: ["edit"],
    actionColor: "#006B44",
  },
];
export const regionDataCols = [
  {
    headerName: "REGION NAME",
    field: "regionName",
    lockPosition: "left",
    cellClass: "locked-col",
    editable: true,
  },
  {
    headerName: "LAST MODIFIED",
    field: "lastModifiedAt",
    lockPosition: "null",
    cellClass: "locked-col",
  },
  {
    headerName: "MODIFIED BY",
    field: "lastModifiedBy",
    lockPosition: "null",
    cellClass: "locked-col",
  },
];

export const projectStatus = [
  {
    id: 1,
    projectStatus: "Planning",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 2,
    projectStatus: "Approved Master Plan",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 3,
    projectStatus: "Under Development",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 4,
    projectStatus: "Completed",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 5,
    projectStatus: "Pre-Opening Stage",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 6,
    projectStatus: "Hotel Operating",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 7,
    projectStatus: "On Hold",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 8,
    projectStatus: "Cancelled",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
];

export const projectStatusCols = [
  {
    headerName: "PROJECT STATUS",
    field: "projectStatus",
    lockPosition: "left",
    cellClass: "locked-col",
    editable: true,
    sortable: true,
  },
  {
    headerName: "LAST MODIFIED",
    field: "lastModifiedAt",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
  {
    headerName: "MODIFIED BY",
    field: "lastModifiedBy",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
];

export const projectStageData = [
  {
    id: 1,
    projectStage: "Not Started",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 2,
    projectStage: "Pre-Design",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 3,
    projectStage: "Design",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 4,
    projectStage: "Conustruction",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 5,
    projectStage: "Pre-Opening",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 6,
    projectStage: "Operating",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
];

export const projectStageCols = [
  {
    headerName: "PROJECT STAGE",
    field: "projectStage",
    lockPosition: "left",
    cellClass: "locked-col",
    editable: true,
    sortable: true,
  },
  {
    headerName: "LAST MODIFIED",
    field: "lastModifiedAt",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
  {
    headerName: "MODIFIED BY",
    field: "lastModifiedBy",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
];

export const contractData = [
  {
    id: 1,
    contractType: "HMA",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 2,
    contractType: "HMA NEOM Brand",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 3,
    contractType: "Franchise",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 4,
    contractType: "Manchise",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 5,
    contractType: "Unbranded",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 6,
    contractType: "Lease",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
];

export const contractDataCols = [
  {
    headerName: "OPERATING MODEL TYPE",
    // headerName: "CONTRACT TYPE",
    field: "contractType",
    lockPosition: "left",
    cellClass: "locked-col",
    editable: true,
    sortable: true,
    //singleClickEdit: false,
    suppressClickEdit: true,
  },
  {
    headerName: "LAST MODIFIED",
    field: "lastModifiedAt",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
  {
    headerName: "MODIFIED BY",
    field: "lastModifiedBy",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
];

export const positioningData = [
  {
    id: 1,
    category: "Midscale",
    associatedBenchmarkReport: "City benchmarks",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 2,
    category: "Upscale",
    associatedBenchmarkReport: "City benchmarks, Resort benchmarks",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 3,
    category: "Upper upscale",
    associatedBenchmarkReport: "City benchmarks, Resort benchmarks",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 4,
    category: "Luxury",
    associatedBenchmarkReport: "City benchmarks, Resort benchmarks",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 5,
    category: "Ultra Luxury",
    associatedBenchmarkReport:
      "City benchmarks, Resort benchmarks, Service apartments",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 6,
    category: "Iconic",
    associatedBenchmarkReport: "City benchmarks, Service apartments",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 7,
    category: "Deluxe Serviced Apartment",
    associatedBenchmarkReport: "Service apartments",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 8,
    category: "Standard Serviced Apartment",
    associatedBenchmarkReport: "City benchmarks",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 9,
    category: "Lodges",
    associatedBenchmarkReport: "City benchmarks",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 10,
    category: "Glamping",
    associatedBenchmarkReport: "City benchmarks",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 11,
    category: "Limited Service Community Living",
    associatedBenchmarkReport: "Resort benchmarks",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 12,
    category: "Full Service Community Living",
    associatedBenchmarkReport: "City benchmarks",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
];

/* export const positioningDataCols = [
  {
    headerName: "POSITONING NAME",
    field: "category",
    lockPosition: "left",
    cellClass: "locked-col",
    editable: true,
  },
  {
    headerName: "ASSOCIATED BENCHMARK REPORT",
    field: "associatedBenchmarkReport",
    lockPosition: "left",
    cellClass: "locked-col",
    cellRenderer: AssociatedCellRenderer,
    minWidth: 220,
    editable: true
  },
  {
    headerName: "LAST MODIFIED",
    field: "lastModifiedAt",
    lockPosition: "null",
    cellClass: "locked-col",
  },
  {
    headerName: "MODIFIED BY",
    field: "lastModifiedBy",
    lockPosition: "null",
    cellClass: "locked-col",
  },
]; */

export const realisationData = [
  {
    id: 1,
    realisationType: "Unlikely",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 2,
    realisationType: "Likely",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 3,
    realisationType: "Certain",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 4,
    realisationType: "Remove From List",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
];

export const realisationCols = [
  {
    headerName: "REALISATION",
    field: "realisationType",
    lockPosition: "left",
    cellClass: "locked-col",
    editable: true,
    sortable: true,
  },
  {
    headerName: "LAST MODIFIED",
    field: "lastModifiedAt",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
  {
    headerName: "MODIFIED BY",
    field: "lastModifiedBy",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
];

export const packageTypeData = [
  {
    id: 1,
    packageType: "Sports",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 2,
    packageType: "Wellness",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 3,
    packageType: "Spa",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 5,
    packageType: "Health",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 6,
    packageType: "Food",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },

  {
    id: 7,
    packageType: "Hike and adventure",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },

  {
    id: 8,
    packageType: "All-in",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },

  {
    id: 9,
    packageType: "Combined",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },

  {
    id: 10,
    packageType: "Deluxe",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 11,
    packageType: "Standard",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
];

export const packageTypeCols = [
  {
    headerName: "PACKAGE TYPE",
    field: "packageType",
    lockPosition: "left",
    cellClass: "locked-col",
    editable: true,
    sortable: true,
  },
  {
    headerName: "LAST MODIFIED",
    field: "lastModifiedAt",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
  {
    headerName: "MODIFIED BY",
    field: "lastModifiedBy",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
];

export const roomTypeData = [
  {
    id: 1,
    roomType: "Standard Single",
    roomCategory: "room cat",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 2,
    roomType: "Standard Double",
    roomCategory: "room cat",

    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 3,
    roomType: "Deluxe Single",
    roomCategory: "room cat",

    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 4,
    roomType: "Deluxe Double",
    roomCategory: "room cat",

    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 5,
    roomType: "1 Bedroom Villa",
    roomCategory: "room cat",

    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 6,
    roomType: " 2 Bedroom Villa",
    roomCategory: "room cat",

    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },

  {
    id: 7,
    roomType: " 3 Bedroom Villa",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
];

export const roomTypeCols = [
  // {
  //   headerName: "ROOM TYPE",
  //   field: "roomType",
  //   lockPosition: "left",
  //   cellClass: "locked-col",
  //   editable: true,
  //   sortable: true,
  // },
  // {
  //   headerName: "ROOM CATEGORY",
  //   field: "roomCategory",
  //   lockPosition: "left",
  //   cellClass: "locked-col",
  //   editable: true,
  //   sortable: true,
  // },
  // {
  //   headerName: "LAST MODIFIED",
  //   field: "lastModifiedAt",
  //   lockPosition: "null",
  //   cellClass: "locked-col",
  //   sortable: true,
  // },
  // {
  //   headerName: "MODIFIED BY",
  //   field: "lastModifiedBy",
  //   lockPosition: "null",
  //   cellClass: "locked-col",
  //   sortable: true,
  // },
];

export const timelineStatusData = [
  {
    id: 1,
    timeLineStatus: "On Track",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 2,
    timeLineStatus: "Delayed",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 3,
    timeLineStatus: "Overdue",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
];

export const timelineStatusCols = [
  {
    headerName: "TIMELINE STATUS",
    field: "timeLineStatus",
    lockPosition: "left",
    cellClass: "locked-col",
    editable: true,
    sortable: true,
  },
  {
    headerName: "LAST MODIFIED",
    field: "lastModifiedAt",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
  {
    headerName: "MODIFIED BY",
    field: "lastModifiedBy",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
];

export const roomCategoryData = [
  // {
  //   id: 1,
  //   roomCategory: "Standard Room - Single Room",
  //   lastModifiedAt: "19.10.2022; 13:21",
  //   lastModifiedBy: "System",
  //   action: ["edit"],
  //   actionColor: "#006B44",
  // },
  // {
  //   id: 2,
  //   roomCategory: "Standard Room - Deluxe King Room",
  //   lastModifiedAt: "19.10.2022; 13:21",
  //   lastModifiedBy: "System",
  //   action: ["edit"],
  //   actionColor: "#006B44",
  // },
  // {
  //   id: 3,
  //   roomCategory: "3 Bedroom Villa",
  //   lastModifiedAt: "19.10.2022; 13:21",
  //   lastModifiedBy: "System",
  //   action: ["edit"],
  //   actionColor: "#006B44",
  // },
];

export const roomCategoryCols = [
  {
    headerName: "ROOM CATEGORY",
    field: "roomCategory",
    lockPosition: "left",
    cellClass: "locked-col",
    editable: true,
    sortable: true,
  },
  {
    headerName: "LAST MODIFIED",
    field: "lastModifiedAt",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
  {
    headerName: "MODIFIED BY",
    field: "lastModifiedBy",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
];

export const assetCategoryData = [
  {
    id: 1,
    assetCategory: "Hotel",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 2,
    assetCategory: "Resort",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
];

export const assetCategoryCols = [
  {
    headerName: "ASSET CATEGORY",
    field: "assetCategory",
    lockPosition: "left",
    cellClass: "locked-col",
    editable: true,
    sortable: true,
  },
  {
    headerName: "LAST MODIFIED",
    field: "lastModifiedAt",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
  {
    headerName: "MODIFIED BY",
    field: "lastModifiedBy",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
];

export const operatorData = [
  // {
  //   id: 1,
  //   operator: "Marriott",
  //   lastModifiedAt: "19.10.2022; 13:21",
  //   lastModifiedBy: "System",
  //   action: ["edit"],
  //   actionColor: "#006B44",
  // },
  // {
  //   id: 2,
  //   operator: "Hilton",
  //   lastModifiedAt: "19.10.2022; 13:21",
  //   lastModifiedBy: "System",
  //   action: ["edit"],
  //   actionColor: "#006B44",
  // },
  // {
  //   id: 3,
  //   operator: "Accor",
  //   lastModifiedAt: "19.10.2022; 13:21",
  //   lastModifiedBy: "System",
  //   action: ["edit"],
  //   actionColor: "#006B44",
  // },
];

export const operatorCols = [
  {
    headerName: "OPERATOR TYPE",
    field: "operator",
    lockPosition: "left",
    cellClass: "locked-col",
    editable: true,
    sortable: true,
  },
  {
    headerName: "LAST MODIFIED",
    field: "lastModifiedAt",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
  {
    headerName: "MODIFIED BY",
    field: "lastModifiedBy",
    lockPosition: "null",
    cellClass: "locked-col",
    sortable: true,
  },
];

export const brandData = [
  {
    id: 1,
    brand: "Luxury Collection",
    operator: "Operator1",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 2,
    brand: "The Ritz Carlton",
    operator: "Operator1",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 3,
    brand: "W Hotels",
    operator: "Operator1",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 4,
    brand: "EDITION",
    operator: "Operator1",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 5,
    brand: "Waldorf Astoria",
    operator: "Operator1",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 6,
    brand: "Double Tree",
    operator: "Operator1",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 7,
    brand: "Conard",
    operator: "Operator1",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 8,
    brand: "LXR",
    operator: "Operator1",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 9,
    brand: "RAFFLES",
    operator: "Operator1",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 10,
    brand: "Sofitel",
    operator: "Operator1",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 11,
    brand: "Mondrian",
    operator: "Operator1",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
  {
    id: 12,
    brand: "Mercure",
    operator: "Operator1",
    lastModifiedAt: "19.10.2022; 13:21",
    lastModifiedBy: "System",
    action: ["edit"],
    actionColor: "#006B44",
  },
];

export const brandCols = [
  // {
  //   headerName: "BRAND",
  //   field: "brand",
  //   lockPosition: "left",
  //   cellClass: "locked-col",
  //   editable: true,
  //   sortable: true,
  // },
  // {
  //   headerName: "OPERATOR",
  //   field: "operator",
  //   lockPosition: "left",
  //   cellClass: "locked-col",
  //   sortable: true,
  // },
  // {
  //   headerName: "LAST MODIFIED",
  //   field: "lastModifiedAt",
  //   lockPosition: "null",
  //   cellClass: "locked-col",
  //   sortable: true,
  // },
  // {
  //   headerName: "MODIFIED BY",
  //   field: "lastModifiedBy",
  //   lockPosition: "null",
  //   cellClass: "locked-col",
  //   sortable: true,
  // },
];
