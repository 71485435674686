import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Flex,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Badge,
  Center,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";

import {
  GetSpecificAssetsListAPI,
  UpdateDescriptionAPI,
 DownloadFile
} from "../../api/AssetListAPI";

import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { MainButton } from "../../components/button/MainButton";
import { TablePagination2 } from "../../components/tablePagination/TablePagination2";
import { RenderCommentsBox } from "../../components/renderCommentsBox/RenderCommentsBox";
import { RenderDescriptionBox } from "../../components/renderDescriptionBox/RenderDescriptionBox";
import threeDots from "../../assets/images/icons/threeDots.svg";
import remove from "../../assets/images/icons/deleteRed.svg";
import undo from "../../assets/images/icons/undo.svg";
import edit from "../../assets/images/icons/user-edit.svg";
import calculator from "../../assets/images/icons/calculatorsm.svg";
import model from "../../assets/images/icons/Modelsm.svg";
import leftArrow3 from "../../assets/images/icons/leftArrow3.svg";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import download from "../../assets/images/icons/downloadFile.svg";
import downloadReport from "../../assets/images/icons/downloadReport.svg";
import checked from "../../assets/images/icons/checked.svg";
import download2 from "../../assets/images/icons/download2.svg"

export const AssetVersionHistoryTable = ({
  onDelModalOpen,
  assetData,
  comments,
  setSelectedID,
  assetId,
  projectName,
  user,
  isDownloadEnabled,
  description
}) => {
  const tableRef = useRef(null);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isLoaderShown, setIsLoaderShown] = useState(false);
  const [isErrorModalShown, setIsErrorModalShown] = useState(false);
  const {
    isOpen: isDescriptionSucsessOpen,
    onOpen: onDescriptionSucsessOpen,
    onClose: onDescriptionSucsessClose,
  } = useDisclosure();

  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  let [currPagData, setCurrPagData] = useState({
    page: 1,
    sortOrder: "DESC",
    sort: "lastModifiedAt",
  });
  const handleSort = async (column, sortDirection) => {
    tableRef?.current
      .querySelectorAll(".table_content .rdt_TableCol_Sortable")
    [column.id - 1]?.children[1]?.classList.add("active");
    let sortOrder = sortDirection.toUpperCase();
    let sort = column.sortField;
    //console.log("handleSort", sort, sortOrder, currPagData);
    handleOnPageChange(currPagData.page, sort, sortOrder);
    setCurrPagData({ ...currPagData, sort: sort, sortOrder: sortOrder });
  };
  //console.log("currPagData", currPagData);
  const [dataArray, setDataArray] = useState([]);
  const columns = [
    {
      name: "VERSION",
      selector: (row) => row.versionNum,
      sortable: true,
      sortField: "versionNumber",
      // cell: (row) => <Box color={`#007BB5`}>{row.versionNum}</Box>,
    },
    {
      name: "LAST MODIFIED",
      selector: (row) => row.modDate,
      sortable: true,
      sortField: "lastModifiedAt",
    },
    {
      name: "MODIFIED BY",
      selector: (row) => row.modBy,
      sortable: true,
      sortField: "lastModifiedBy",
    },

    {
      name: "STATUS",
      selector: (row) => row.status,
      sortable: true,
      sortField: "status",
      minWidth: "160px",
      width: "160px",
      cell: (row) => (
        <Flex justifyContent="center" alignItems="center">
          <Badge
            data-tag="allowRowEvents"
            data-action="edit"
            bg={row.statusBg}
            color="#fff"
            fontSize="14px"
            fontWeight="300"
            px={3}
            py="1"
            textTransform="none"
            borderRadius="3px"
          >
            {row.status === "InReview" || row.status === "InitialDraft"
              ? row.status.split(/(?=[A-Z])/)[0] +
              " " +
              row.status.split(/(?=[A-Z])/)[1]
              : row.status}
          </Badge>
        </Flex>
      ),
    },
    {
      name: "ASSIGNED TO",
      selector: (row) => (row.assignedTo === "" ? "-" : row.assignedTo),
      sortable: true,
      sortField: "assignedTo",
    },

    {
      name: "ACTION",
      selector: (row) => row.action,
      cell: (row) => {
        let previewLink = `/AssetList`;
        if (row.assetType === "Package") {
          previewLink = previewLink + "/Package";
        } else if (row.assetType === "Standard") {
          previewLink = previewLink + "/Standard";
        }
        // console.log(
        //   "displayName",
        //   row.assignedTo,
        //   user.displayName,
        //   user?.roles[0]?.toLowerCase()
        // );
        return (
          <Flex justifyContent="space-between" alignItems="center">
            {(row.assignedTo !== user.displayName ||
              row.status === "Archived" ||
              row.status === "InReview" ||
              row.status === "Production" ||
              row.status === "Draft" ||
              row.status === "InitialDraft") &&
              user?.roles[0]?.toLowerCase() === "proponent" /* &&
            row.status !== "InReview" */ &&
              row.status !== "Open" ? (
              <NavLink
                to={
                  row.isDraftVersion || row.isInitialDraftVersion
                    ? `${previewLink}/${assetId}/${row.parentVersionNumber}/${row.idCustom}`
                    : `${previewLink}/${assetId}/${row.versionNumberInt}`
                }
                // to={`${previewLink}/${assetId}/${row.idCustom}`}
                state={{ from: "Restore" }}
              >
                <MainButton
                  title="Preview"
                  btnStyle="transparent"
                  fontColor="dark"
                  marginRight="5"
                  onPress={() => console.log("clicked")}
                  fSize="sm_x2"
                />
              </NavLink>
            ) : (
              <MainButton
                title="Preview"
                btnStyle="transparent"
                fontColor="dark"
                marginRight="5"
                navLink={true}
                path={
                  row.isDraftVersion || row.isInitialDraftVersion
                    ? `${previewLink}/${assetId}/${row.parentVersionNumber}/${row.idCustom}`
                    : `${previewLink}/${assetId}/${row.versionNumberInt}`
                }
                fSize="sm_x2"
              //path="/EditBenchMark"
              />
            )}
            {(row.status === "Draft" || row.status === "Obsolete") &&
              !row.isInitialDraftVersion && (
                <Menu autoSelect={false}>
                  <MenuButton
                    bgColor="transparent"
                    px={1}
                    ml={1}
                    minWidth="auto"
                    as={Button}
                    _hover={{ backgroundColor: "transparent" }}
                  >
                    <img src={threeDots} alt="dots icon" />
                  </MenuButton>
                  <MenuList px="5px" py="15px" minW="150px" w={"150px"}>
                    <Text fontSize="12px" color="#6F767E" px="10px" mb="7px">
                      More actions
                    </Text>
                    <MenuItem
                      alignItems="center"
                      onClick={() => {
                        setSelectedID(
                          `draft|${assetId}|${row.parentVersionNumber}|${row.idCustom}`
                        );
                        onDelModalOpen();
                      }}
                    >
                      <img src={remove} alt="delete icon" />
                      <Text color="#E0403F" ml="5px">
                        Delete
                      </Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            {row.status === "Obsolete" && row.isInitialDraftVersion && (
              <Menu autoSelect={false}>
                <MenuButton
                  bgColor="transparent"
                  px={1}
                  ml={1}
                  minWidth="auto"
                  as={Button}
                  _hover={{ backgroundColor: "transparent" }}
                >
                  <img src={threeDots} alt="dots icon" />
                </MenuButton>
                <MenuList px="5px" py="15px" minW="150px" w={"150px"}>
                  <Text fontSize="12px" color="#6F767E" px="10px" mb="7px">
                    More actions
                  </Text>
                  <MenuItem
                    alignItems="center"
                    onClick={() => {
                      setSelectedID(
                        `draft-${row.parentVersionNumber}-${row.idCustom}`
                      );
                      onDelModalOpen();
                    }}
                  >
                    <img src={remove} alt="delete icon" />
                    <Text color="#E0403F" ml="5px">
                      Delete
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
            {row.status === "Archived" &&
              user?.roles[0]?.toLowerCase() === "admin" && (
                <Menu autoSelect={false}>
                  <MenuButton
                    bgColor="transparent"
                    px={1}
                    ml={1}
                    minWidth="auto"
                    as={Button}
                    _hover={{ backgroundColor: "transparent" }}
                  >
                    <img src={threeDots} alt="dots icon" />
                  </MenuButton>
                  <MenuList px="5px" py="15px">
                    <Text fontSize="12px" color="#6F767E" px="10px" mb="7px">
                      More actions
                    </Text>
                    <NavLink
                      to={`${previewLink}/${assetId}/${row.versionNumberInt}`}
                      state={{ from: "Restore" }}
                    >
                      <MenuItem alignItems="center">
                        <Box display="flex">
                          <img src={undo} alt="undo icon" />
                          <Text color="#003865" ml="5px">
                            Restore
                          </Text>
                        </Box>
                      </MenuItem>
                    </NavLink>{" "}
                    <NavLink
                      to={`/SelectAction/${row.assetType}/${assetId}/${row.versionNumberInt}`}
                      state={{ from: "View" }} /*  to="/SelectAction" */
                    >
                      <MenuItem alignItems="center">
                        <Box display="flex">
                          <img src={model} alt="edit icon" />
                          <Text color="#003865" ml="5px">
                            View model
                          </Text>
                        </Box>
                      </MenuItem>
                    </NavLink>
                    <NavLink
                      to={`/QuickCalculations/${assetId}/${row.versionNumberInt}`}
                    >
                      <MenuItem alignItems="center">
                        <Box display="flex">
                          <img src={calculator} alt="edit icon" />
                          <Text color="#003865" ml="5px">
                            View calculations
                          </Text>
                        </Box>
                      </MenuItem>
                    </NavLink>
                  </MenuList>
                </Menu>
              )}
            {(row.status === "Production" || row.status === "Open") &&
              user?.roles[0]?.toLowerCase() === "admin" && (
                <Menu autoSelect={false}>
                  <MenuButton
                    bgColor="transparent"
                    as={Button}
                    px={1}
                    ml={1}
                    minWidth="auto"
                    _hover={{ backgroundColor: "transparent" }}
                  >
                    <img src={threeDots} alt="dots icon" />
                  </MenuButton>
                  <MenuList px="5px" py="15px">
                    <Text fontSize="12px" color="#6F767E" px="10px" mb="7px">
                      More actions
                    </Text>

                    {row.status === "Open" && (
                      <NavLink
                        to={
                          row.isDraftVersion || row.isInitialDraftVersion
                            ? `${previewLink}/${assetId}/${row.parentVersionNumber}/${row.idCustom}`
                            : `${previewLink}/${assetId}/${row.versionNumberInt}`
                        }
                        state={{ from: "Reassign" }}
                        style={{ width: "100%" }}
                      >
                        <MenuItem alignItems="center">
                          <Box display="flex">
                            <img src={edit} alt="edit icon" />
                            <Text color="#003865" ml="5px">
                              Re-assign
                            </Text>
                          </Box>
                        </MenuItem>
                      </NavLink>
                    )}
                    {row.status === "Production" && (
                      <NavLink
                        to={
                          row.isDraftVersion || row.isInitialDraftVersion
                            ? `/SelectAction/${row.assetType}/${assetId}/${row.parentVersionNumber}/${row.idCustom}`
                            : `/SelectAction/${row.assetType}/${assetId}/${row.versionNumberInt}`
                        }
                      >
                        <MenuItem alignItems="center">
                          <Box display="flex">
                            <img src={model} alt="edit icon" />
                            <Text color="#003865" ml="5px">
                              Generate model
                            </Text>
                          </Box>
                        </MenuItem>
                      </NavLink>
                    )}
                    {row.status === "Production" && (
                      <NavLink
                        to={
                          row.isDraftVersion || row.isInitialDraftVersion
                            ? `/QuickCalculations/${assetId}/${row.parentVersionNumber}/${row.idCustom}`
                            : `/QuickCalculations/${assetId}/${row.versionNumberInt}`
                        }
                      >
                        <MenuItem alignItems="center">
                          <Box display="flex">
                            <img src={calculator} alt="edit icon" />
                            <Text color="#003865" ml="5px">
                              Quick calculations
                            </Text>
                          </Box>
                        </MenuItem>
                      </NavLink>
                    )}
                  </MenuList>
                </Menu>
              )}
          </Flex>
        );
      },
    },

    {
      name: "Downloads",
      selector: (row) => row.idCustom,
      cell: (row) => {
        return (
          <Flex gap="5px" justifyContent="space-between" alignItems="center">
            <Menu autoSelect={false}>
              <MenuButton
                bgColor="transparent"
                as={Button}
                px={1}
                ml={1}
                minWidth="auto"
                _hover={{ backgroundColor: "transparent" }}
              >
                <img src={threeDots} alt="dots icon" />
              </MenuButton>
              <MenuList px="5px" py="15px">
                <MenuItem alignItems="center">
                  <Box
                    display="flex"
                    onClick={() => {
                      handleDownloadInput(row.idCustom);
                    }}
                  >
                    <img src={download} alt="edit icon" />
                    <Text color="#003865" ml="5px">
                      Download input file
                    </Text>
                  </Box>
                </MenuItem>
                <MenuItem alignItems="center">
                  <Box
                    display="flex"
                    onClick={() => {
                      handleDownloadReport(row.idCustom);
                    }}
                  >
                    <img src={downloadReport} alt="Download output report" />
                    <Text color="#003865" ml="5px">
                    Download output report
                    </Text>
                  </Box>
                </MenuItem>
                {row.assetType == "Standard" && 
                <MenuItem alignItems="center">
                  <Box
                    display="flex"
                    onClick={() => {
                      handleDownloadFNBReport(row.idCustom);
                    }}
                  >
                    <img src={download2} alt="Download F&B report" />
                    <Text color="#003865" ml="5px">
                    Download F&B report
                    </Text>
                  </Box>
                </MenuItem>
                }
              </MenuList>
              
            </Menu>
            {/* <Tooltip label="Download input file" hasArrow>
              <Box cursor="pointer" onClick={() => { handleDownloadInput(row.idCustom) }} as="img" src={download} alt="download input file" />
            </Tooltip>
            <Tooltip label="Download output report" hasArrow>
              <Box cursor="pointer" onClick={() => { handleDownloadReport(row.idCustom) }} as="img" src={downloadReport} alt="download output report" />
            </Tooltip>
          {row.assetType == "Standard" && 
            <Tooltip label="Download F&B report" hasArrow>
              <Box cursor="pointer" onClick={() => { handleDownloadFNBReport(row.idCustom) }} as="img" src={download2} alt="download F&B report" />
            </Tooltip>
            } */}
          </Flex>
        );
      }
    },


  ];

  useEffect(() => {
    let data = [];
    if (assetData?.pageRows?.length > 0 && !!assetData) {
      // console.log(assetData);
      assetData?.pageRows?.forEach((item) => {
        let statusBg;
        if (item.status === "Production") {
          statusBg = "#006B44";
        } else if (item.status === "Archived") {
          statusBg = "#A9A9A9";
        } else if (item.status === "Obsolete") {
          statusBg = "#007BB5";
        } else if (item.status === "InReview") {
          statusBg = "#F18825";
        } else if (item.status === "Hidden") {
          statusBg = "#bc941d";
        } else {
          statusBg = "#007BB5";
        }
        if (!!item.lastModifiedAt === false) return 0;
        let date = dateFnsParseISO(item.lastModifiedAt);
        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.id}`,
          isInitialDraftVersion: item.status === "InitialDraft",
          isDraftVersion:
            item.status === "Draft" ||
            item.status === "Obsolete" ||
            item.status === "Open" ||
            item.status === "InReview",
          versionNum:
            item.status === "Draft" ||
              item.status === "Obsolete" ||
              item.status === "InitialDraft" ||
              item.status === "Open" ||
              item.status === "InReview"
              ? `Version ${item.parentVersionNumber}`
              : `Version ${item.versionNumber}`,
          parentVersionNumber: item.parentVersionNumber,
          versionNumberInt: item.versionNumber || 0,
          modDate: dateFnsFormat(date, "dd.MM.yyyy; HH:mm"),
          modBy: `${item.lastModifiedBy}`,
          assignedTo: `${item.assignedTo}`,
          assetType: `${item.assetType}`,
          status: `${item.status}`,
          statusBg: statusBg,
        };

        data.push(dataObj);
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
      sortOrder: "DESC",
      sort: "lastModifiedAt",
    });
  }, []);

  const handleOnPageChange = (page, sort = "lastModifiedAt", sortOrder) => {
    GetSpecificAssetsListAPI(assetId, page, sort, sortOrder)
      .then((res) => {
        // console.log("handleonpagechnage", res.data);

        let data = [];

        if (res.data.pageRows.length > 0) {
          res.data.pageRows.forEach((item) => {
            let statusBg;
            if (item.status === "Production") {
              statusBg = "#006B44";
            } else if (item.status === "Archived") {
              statusBg = "#A9A9A9";
            } else if (item.status === "Obsolete") {
              statusBg = "#007BB5";
            } else if (item.status === "InReview") {
              statusBg = "#F18825";
            } else if (item.status === "Hidden") {
              statusBg = "#bc941d";
            } else {
              statusBg = "#007BB5";
            }

            let date = dateFnsParseISO(item.lastModifiedAt);
            let dataObj = {
              id: `${item.id + Math.floor(Math.random() * Date.now())}`,
              idCustom: `${item.id}`,
              isInitialDraftVersion: item.status === "InitialDraft",
              isDraftVersion:
                item.status === "Draft" ||
                item.status === "Obsolete" ||
                item.status === "Open" ||
                item.status === "InReview",
              versionNum:
                item.status === "Draft" ||
                  item.status === "Obsolete" ||
                  item.status === "InitialDraft" ||
                  item.status === "Open" ||
                  item.status === "InReview"
                  ? `Version ${item.parentVersionNumber}`
                  : `Version ${item.versionNumber}`,
              parentVersionNumber: item.parentVersionNumber,
              versionNumberInt: item.versionNumber || 0,
              modDate: dateFnsFormat(date, "dd.MM.yyyy; HH:mm"),
              modBy: `${item.lastModifiedBy}`,
              assignedTo: `${item.assignedTo}`,
              assetType: `${item.assetType}`,
              status: `${item.status}`,
              statusBg: statusBg,
            };
            data.push(dataObj);
          });

          setDataArray(data);
          setIsTableLoading(false);
        }
      })
      .catch((err) => {
        setIsTableLoading(false);
        console.log(err);
      });
  };

  const handleDownloadInput = useCallback((versionId) => {
    DownloadFile(versionId, 'downloadInput')
      .then((response) => {
        try {
          const fileData = Uint8Array.from(response.data.fileBuffer.data);
          const content = new Blob([fileData.buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const encodedUri = window.URL.createObjectURL(content);
          const link = document.createElement("a");
          const filename = response.data.filename;
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", filename);

          link.click();
        } catch {
          throw new Error();
        }
      })
      .catch((e) => {
        setIsErrorModalShown(true);
        console.log("Error downloading", e);
      })
      .finally(() => {
        setIsLoaderShown(false);
      });
  });

  const handleDownloadReport = useCallback((versionId) => {
    setIsLoaderShown(true);
    DownloadFile(versionId,'downloadReport')
      .then((response) => {
        try {
          const fileData = Uint8Array.from(response.data.fileBuffer.data);
          const content = new Blob([fileData.buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const encodedUri = window.URL.createObjectURL(content);
          const link = document.createElement("a");
          const filename = response.data.filename;
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", filename);

          link.click();
        } catch {
          throw new Error();
        }
      })
      .catch((e) => {
        setIsErrorModalShown(true);
        console.log("Error downloading", e);
      })
      .finally(() => {
        setIsLoaderShown(false);
      });
  }, []);

  const handleDownloadFNBReport = useCallback((versionId) => {
    setIsLoaderShown(true);
    DownloadFile(versionId,"downloadFnBPnL")
      .then((response) => {
        try {
          const fileData = Uint8Array.from(response.data.fileBuffer.data);
          const content = new Blob([fileData.buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const encodedUri = window.URL.createObjectURL(content);
          const link = document.createElement("a");
          const filename = response.data.filename;
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", filename);

          link.click();
        } catch {
          throw new Error();
        }
      })
      .catch((e) => {
        setIsErrorModalShown(true);
        console.log("Error downloading", e);
      })
      .finally(() => {
        setIsLoaderShown(false);
      });
  }, []);

  const handleSetDescription = useCallback((data) => {
    setIsLoaderShown(true);
    UpdateDescriptionAPI(assetId, { description: data })
      .then((response) => {
        onDescriptionSucsessOpen()
      })
      .catch((e) => {
        onErrorOpen()
        console.log("Error downloading", e);
      })
      .finally(() => {
        setIsLoaderShown(false);
      });
  });

  //console.log("ddddddddd", dataArray);

  return (
    <>
      <Box>
        <Flex justifyContent="space-between" width="98%">
          <MainButton
            title="Back"
            btnStyle="transparent"
            fontColor="dark"
            marginRight="1"
            navLink={true}
            icon={leftArrow3}
            path={"/AssetsHistory"}
          />
        </Flex>
        <MainTitle pt="6" pb="4" title={`${projectName}`} />
      </Box>
      <HStack width="100%">
        <Tabs variant="unstyled" width="100%">
          <TabList w="95%" gap="3" mb={1}>
            <Tab
              _selected={{
                bg: "#F1EFE0",
                borderBottom: "2px",
                borderBottomColor: "#EBC03F !important",
                backgroundColor: "#F1EFE0",
              }}
              className="main_tabs"
              border="1px"
            >
              Version History
            </Tab>

            <Tab
              _selected={{
                bg: "#F1EFE0",
                borderBottom: "2px",
                borderBottomColor: "#EBC03F !important",
                backgroundColor: "#F1EFE0",
              }}
              className="main_tabs"
              border="1px"
            >
              Description
            </Tab>

            <Tab
              _selected={{
                bg: "#F1EFE0",
                borderBottom: "2px",
                borderBottomColor: "#EBC03F !important",
                backgroundColor: "#F1EFE0",
              }}
              className="main_tabs"
              border="1px"
            >
              Comments History
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel w="98%" px="0" ref={tableRef}>
              <TablePagination2
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title=""
                hasDelete={true}
                totalRows={assetData?.totalRows || 0}
                pageSize={assetData?.pageSize || 0}
                GetDataAPI={GetSpecificAssetsListAPI}
                handleOnPageChange={handleOnPageChange}
                onSort={handleSort}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
                isTableLoading={isTableLoading}
                setIsTableLoading={setIsTableLoading}
              />
            </TabPanel>
            <TabPanel paddingX={0} marginX={0}>
              <MainBox>
                <RenderDescriptionBox
                  description={description}
                  isDisabled={user?.roles[0]?.toLowerCase() !== "admin"}
                  handleSetDescription={handleSetDescription}
                />
              </MainBox>
            </TabPanel>
            <TabPanel paddingX={0} marginX={0}>
              <MainBox>
                <RenderCommentsBox comments={comments} assetComments={true} />
              </MainBox>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
      <ModalAlert
        isOpen={isLoaderShown}
        disableClose={true}
        hasDesc={false}
        isSubmitLoading={true}
      />
      <ModalAlert
        isOpen={isErrorModalShown}
        disableClose={false}
        icon={infoCircle}
        confirmationModal={false}
        hasDesc={false}
        haveSaveDraft={false}
        onClose={() => setIsErrorModalShown(false)}
        title={"There was an error generating the report"}
      />
      {isDescriptionSucsessOpen && (<ModalAlert
        isOpen={isDescriptionSucsessOpen}
        onClose={onDescriptionSucsessClose}
        title="Description has been updated successfully"
        icon={checked}
        hasDesc={false}
        confirmationModal={false}
      />)}

      {isErrorOpen && (<ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title="Something went wrong"
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />)}

    </>
  );
};
