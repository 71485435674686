import React from "react";

import { Box, Divider, Flex, SimpleGrid, GridItem } from "@chakra-ui/react";
import { FieldArray } from "formik";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { TooltipElement } from "../../components/tooltip/TooltipElement";

import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";

export const GeneralAssumpStep2 = ({
  nextPage,
  prevPage,
  errors,
  touched,
  setFieldValue,
  onCancelOpen,
  formikValues,
  onDraftOpen,
  disableSave,
  setDismissSave,
  isRestore,
  isModelWarningOpen,
  setValidateStep,
  validateStep,
  closeToast,
  setFieldTouched,
}) => {
  let form2Data = formikValues.haylettFactor;
  let emptyform2Data = [];
  if (form2Data.length > 0) {
    form2Data.forEach((item) => {
      if (
        item.haylettFactorField !== "" &&
        !isNaN(`${item.haylettFactorField}`)
      ) {
        emptyform2Data.push(item);
      }
    });
  }

  let isDisabledForm = false;

  if (emptyform2Data.length !== form2Data.length) {
    isDisabledForm = true;
  }

  const renderhaylettFactorFields = () => {
    return (
      <FieldArray
        name="haylettFactor"
        render={(arrayHelper) => (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <SimpleGrid
              columns={{ sm: 5, md: 10 }}
              spacingX="15px"
              width="100%"
            >
              {formikValues.haylettFactor !== undefined &&
                formikValues.haylettFactor.map((_, index) => (
                  <GridItem colSpan={1} mb={2} key={index}>
                    <InputFieldValidation
                      label={index + 1}
                      placeholder="0"
                      inputType="number"
                      inputId={`haylettFactor.${index}.haylettFactorField`}
                      inputName={`haylettFactor.${index}.haylettFactorField`}
                      errors={errors}
                      touched={touched}
                      isRequired
                      onChange={setFieldValue}
                      values={
                        formikValues.haylettFactor[index].haylettFactorField
                      }
                      labelCenter={true}
                      errorHeight0={true}
                      uppercase={false}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      directVal={true}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                ))}
            </SimpleGrid>
          </Box>
        )}
      />
    );
  };

  return (
    <>
      <Box
        mt="5"
        mb="4"
        justifyContent="flex-start"
        alignItems="center"
        display="flex"
        width="100%"
      >
        <MainTitle title="Haylett Factor" />
        <Box className="tooltip_field2" ml={3}>
          <TooltipElement label="The Haylett Factor distributes development costs along the S-Curve by factoring on a percentage scale of 1-100 how much of the development cost will be consumed per step (assuming 100 steps)" />
        </Box>
      </Box>
      {renderhaylettFactorFields()}

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          btnStyle="transparent"
          disabledBtn={isRestore}
          fontColor="dark"
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/AddGeneralAssumptions`}
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {!isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                disabledBtn={false}
                onPress={() => {
                  setValidateStep(false);
                  closeToast();
                  nextPage();
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                disabledBtn={false}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
