import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Text,
  GridItem,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Heading,
  Flex,
  Divider,
  Badge,
} from "@chakra-ui/react";
import { FieldArray } from "formik";

import { InputFieldAverage } from "../../../components/inputField/InputFieldAverage";
import { MainButton } from "../../../components/button/MainButton";
import { MainTitle } from "../../../components/mainTitle/MainTitle";

import rightArrow from "../../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../../assets/images/icons/Arrow-Left.svg";
import percent from "../../../assets/images/icons/filled.svg";

export const HotelKpisStep3 = ({
  nextPage,
  prevPage,
  errors,
  touched,
  onChange,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  onDraftOpen,
  formikValues,
  disableSave,
  setDismissSave,
  positioning,
  isRestore,
  validateStep,
  setValidateStep,
  isModelWarningOpen,
  closeToast,
  handleAccordErrors,
}) => {
  const inputRef = useRef(null);
  const [arrayKeys, setArrayKeys] = useState([]);
  let form3Data = [];

  if (
    formikValues?.occupancyRampUpValues?.year1 &&
    formikValues?.occupancyRampUpValues?.year2 &&
    formikValues?.occupancyRampUpValues?.year3 &&
    formikValues?.occupancyRampUpValues?.year4 &&
    formikValues?.occupancyRampUpValues?.year5
  ) {
    formikValues.occupancyRampUpValues.year1.forEach((item) => {
      form3Data.push(item);
    });
    formikValues.occupancyRampUpValues.year2.forEach((item) => {
      form3Data.push(item);
    });
    formikValues.occupancyRampUpValues.year3.forEach((item) => {
      form3Data.push(item);
    });
    formikValues.occupancyRampUpValues.year4.forEach((item) => {
      form3Data.push(item);
    });
    formikValues.occupancyRampUpValues.year5.forEach((item) => {
      form3Data.push(item);
    });
  }
  let emptyform3Data = [];
  form3Data.forEach((item) => {
    if (
      item.low !== "" &&
      !isNaN(`${item.low}`) &&
      parseFloat(item.low) >= 0 &&
      item.high !== "" &&
      !isNaN(`${item.high}`) &&
      parseFloat(item.high) >= 0 &&
      item.average !== "" &&
      !isNaN(`${item.average}`) &&
      parseFloat(item.average) >= 0 &&
      parseFloat(item.low) <= parseFloat(item.high)
    ) {
      emptyform3Data.push(item);
    }
  });

  let isDisabledForm = false;

  if (emptyform3Data.length !== form3Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }
  /* if (formikValues?.form3) {
    formikValues?.form3.forEach((el) => {
      el.posFields.forEach((item) => {
        form3Data.push({
          parentName: item.parentName,
          fieldName: "low",
          value: item.low,
          title: item.title,
        });
        form3Data.push({
          parentName: item.parentName,
          fieldName: "high",
          value: item.high,
          title: item.title,
        });
      });
    });
  }

  let emptyform3Data = [];
  form3Data.forEach((item) => {
    if (item.value !== "" && !isNaN(`${item.value}`) && item.value >= 1) {
      emptyform3Data.push(item);
    }
  });

  let isDisabledForm = false;

  if (emptyform3Data.length !== form3Data.length) {
    isDisabledForm = true;
  } */

  // const handleAccordErrors = () => {
  //   let openHigherAccord = true;
  //   let tempArr = [];
  //   form3Data.forEach((el, index) => {
  //     if (openHigherAccord) {
  //       if (el?.low === "" || el?.high === "") {
  //         openHigherAccord = false;
  //         handleOpenAccord(index);
  //       }
  //     }
  //   });
  // };

  // const handleOpenAccord = (accordIndex) => {
  //   if (
  //     inputRef.current.querySelectorAll(
  //       ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
  //     )[accordIndex].ariaExpanded === "false"
  //   ) {
  //     inputRef.current
  //       .querySelectorAll(
  //         ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
  //       )
  //       [accordIndex].click();
  //   }
  // };
  let accordTitle = ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5"];

  let arrKeys = [];
  if (formikValues?.occupancyRampUpValues) {
    arrKeys = Object.keys(formikValues.occupancyRampUpValues);
    if (JSON.stringify(arrayKeys) !== JSON.stringify(arrKeys)) {
      setArrayKeys(arrKeys);
    }
  }
  let renderAccrodionFields = (mData, pos, name) => {
    let counterMas = 0;

    counterMas = counterMas + 1;
    if (counterMas <= mData.length) {
      //let counterComment = 0;

      //counterComment = counterComment + 1;
      return (
        <>
          <AccordionItem mb="4" borderBottom="none">
            <Heading
              borderLeft="1px"
              borderBottom="1px"
              borderRight="1px"
              borderColor="#E6E6E6"
            >
              <AccordionButton
                bg="#FCFCFC"
                className={arrayKeys[pos]}
                onClick={() => {
                  setTimeout(() => {
                    document
                      .querySelector(`.accordionList`)
                      .scrollIntoView({ block: "start" });
                  }, 500);
                }}
              >
                <Box flex="1" textAlign="left">
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                    textTransform="uppercase"
                  >
                    {accordTitle[pos]}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Heading>

            <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
              <FieldArray
                name={`${arrayKeys[pos]}`}
                render={(arrayHelper) => (
                  <>
                    {formikValues.occupancyRampUpValues[`${arrayKeys[pos]}`] !==
                      undefined &&
                      formikValues.occupancyRampUpValues[
                        `${arrayKeys[pos]}`
                      ].map((formArr, index) => {
                        let posObj = positioning.find((el) => {
                          if (el?.id) {
                            return formArr.positioningId === el.id;
                          } else {
                            return formArr.positioningId === el.positioningId;
                          }
                        });
                        return (
                          <Box key={index}>
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                            >
                              <Text
                                fontSize="12px"
                                color="black"
                                fontWeight="400"
                                letterSpacing="0.04em"
                                textAlign="left"
                                textTransform="uppercase"
                                mb={4}
                                mt={3}
                              >
                                {posObj?.posName
                                  ? posObj.posName
                                  : `${formArr.positioningId}`
                                      .split("_")
                                      .join(" ")}
                              </Text>
                            </Box>

                            <GridItem colSpan={2}>
                              <SimpleGrid
                                columns={{ sm: 3, "2xl": 3 }}
                                spacing={{ sm: "25px", "2xl": "40px" }}
                                width={{ sm: "50%", "2xl": "40%" }}
                              >
                                <GridItem
                                  w={{
                                    sm: "170px",
                                    md: "170px",
                                    xl: "170px",
                                    "2xl": "180px",
                                  }}
                                >
                                  <InputFieldAverage
                                    placeholder="0.00"
                                    label="Low"
                                    inputType="number"
                                    inputId={`occupancyRampUpValues.${arrayKeys[pos]}.${index}.low`}
                                    inputName={`occupancyRampUpValues.${arrayKeys[pos]}.${index}.low`}
                                    errors={errors}
                                    touched={touched}
                                    isRequired
                                    values={
                                      formikValues?.occupancyRampUpValues[
                                        arrayKeys[pos]
                                      ][index]?.low
                                    }
                                    labelCenter={true}
                                    uppercase={false}
                                    onChange={onChange}
                                    inputRef={inputRef}
                                    icon={percent}
                                    disable={isRestore}
                                    validateField={validateStep}
                                    directVal={true}
                                  />
                                </GridItem>
                                <GridItem
                                  w={{
                                    sm: "170px",
                                    md: "170px",
                                    xl: "170px",
                                    "2xl": "180px",
                                  }}
                                >
                                  <InputFieldAverage
                                    placeholder="0.00"
                                    label="High"
                                    inputType="number"
                                    inputId={`occupancyRampUpValues.${arrayKeys[pos]}.${index}.high`}
                                    inputName={`occupancyRampUpValues.${arrayKeys[pos]}.${index}.high`}
                                    errors={errors}
                                    touched={touched}
                                    isRequired
                                    values={
                                      formikValues?.occupancyRampUpValues[
                                        arrayKeys[pos]
                                      ][index]?.high
                                    }
                                    labelCenter={true}
                                    uppercase={false}
                                    onChange={onChange}
                                    inputRef={inputRef}
                                    icon={percent}
                                    disable={isRestore}
                                    validateField={validateStep}
                                    directVal={true}
                                  />
                                </GridItem>
                                <GridItem
                                  w={{
                                    sm: "170px",
                                    md: "170px",
                                    lg: "170px",
                                    "2xl": "180px",
                                  }}
                                >
                                  <InputFieldAverage
                                    placeholder="0.00"
                                    label="Average"
                                    inputType="number"
                                    inputId={`occupancyRampUpValues.${arrayKeys[pos]}.${index}.average`}
                                    inputName={`occupancyRampUpValues.${arrayKeys[pos]}.${index}.average`}
                                    errors={errors}
                                    touched={touched}
                                    isRequired
                                    values={
                                      formikValues?.occupancyRampUpValues[
                                        arrayKeys[pos]
                                      ][index]?.average
                                    }
                                    labelCenter={true}
                                    uppercase={false}
                                    //
                                    onChange={onChange}
                                    inputRef={inputRef}
                                    disable={true}
                                    avg={true}
                                    // icon={percent}
                                    noBg={true}
                                    validateField={false}
                                    directVal={true}
                                  />
                                </GridItem>
                              </SimpleGrid>
                              <Box>
                                {parseFloat(
                                  formikValues?.occupancyRampUpValues[
                                    arrayKeys[pos]
                                  ][index]?.low
                                ) >
                                  parseFloat(
                                    formikValues?.occupancyRampUpValues[
                                      arrayKeys[pos]
                                    ][index]?.high
                                  ) && (
                                  <Box
                                    color="#e14642"
                                    fontWeight="400"
                                    fontSize="10px"
                                    lineHeight="17px"
                                    letterSpacing="0.04em"
                                    marginTop="-5px"
                                  >
                                    Low value should be less than high value
                                  </Box>
                                )}
                              </Box>
                            </GridItem>
                          </Box>
                        );
                      })}
                  </>
                )}
              />
            </AccordionPanel>
          </AccordionItem>
        </>
      );
    }
  };
  return (
    <>
      <Box alignItems="flex-start" width="100%" mb={5}>
        <MainTitle title="HOTEL KPIs" transform="unset" />
        <Badge
          fontSize="16px"
          borderRadius="5px"
          bg=" rgba(235, 192, 63, 0.15);"
          color="#1D1F22"
          colorScheme="blackAlpha"
          fontWeight="400"
          lineHeight="24px"
          letterSpacing="0.06em"
          padding="10px 24px"
          mb="3"
        >
          Average Occupancy Ramp-Up
        </Badge>
      </Box>

      <Accordion
        width="100%"
        allowToggle
        color="black"
        ref={inputRef}
        defaultIndex={[0]}
        className="accordionList"
      >
        {formikValues?.occupancyRampUpValues?.year1 &&
          renderAccrodionFields(
            formikValues?.occupancyRampUpValues?.year1,
            0,
            "year1"
          )}
        {formikValues?.occupancyRampUpValues?.year2 &&
          renderAccrodionFields(
            formikValues?.occupancyRampUpValues?.year2,
            1,
            "year2"
          )}
        {formikValues?.occupancyRampUpValues?.year3 &&
          renderAccrodionFields(
            formikValues?.occupancyRampUpValues?.year3,
            2,
            "year3"
          )}
        {formikValues?.occupancyRampUpValues?.year4 &&
          renderAccrodionFields(
            formikValues?.occupancyRampUpValues?.year4,
            3,
            "year4"
          )}
        {formikValues?.occupancyRampUpValues?.year5 &&
          renderAccrodionFields(
            formikValues?.occupancyRampUpValues?.year5,
            4,
            "year5"
          )}
      </Accordion>

      <Divider orientation="horizontal" mt="30px" mb="30px" />

      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          btnStyle="transparent"
          fontColor="dark"
          disabledBtn={isRestore}
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />
        <Box>
          <Box display="flex" gap="30px">
            {!disableSave ? (
              <MainButton
                title="Cancel"
                btnStyle="transparent"
                fontColor="light"
                onPress={() => {
                  setDismissSave(false);
                  onCancelOpen();
                }}
              />
            ) : (
              <MainButton
                title="Cancel"
                btnStyle="transparent"
                fontColor="light"
                navLink={true}
                path={`/HotelKpisHistory`}
              />
            )}
            <Box display="flex" gap="20px">
              <MainButton
                title="Previous"
                btnStyle="gray"
                marginRight="2"
                icon={leftArrow}
                onPress={() => {
                  prevPage();
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />

              {isDisabledForm ? (
                <MainButton
                  title="Next"
                  reverseRow={true}
                  marginLeft="2"
                  icon={rightArrow}
                  onPress={() => {
                    handleAccordErrors(errors);
                    setValidateStep(true);
                    handleAccordErrors(errors);
                    isModelWarningOpen(true);
                    document.getElementById("main_content").scrollTo(0, 0);
                  }}
                />
              ) : (
                <MainButton
                  title="Next"
                  reverseRow={true}
                  marginLeft="2"
                  icon={rightArrow}
                  onPress={() => {
                    closeToast();
                    nextPage();
                    document.getElementById("main_content").scrollTo(0, 0);
                  }}
                />
              )}
              {/* <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                disabledBtn={isDisabledForm}
                onPress={() => {
                  nextPage();
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              /> */}
            </Box>
          </Box>
        </Box>
      </Flex>
    </>
  );
};
