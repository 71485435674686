import axiosClient from "./index";

export const GetGeneralAssumptionsAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/generalAssumptions?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetGeneralAssumptionsCommentsAPI = async () => {
  const URL = "/v1/generalAssumptions/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetGeneralAssumptionsByIdAPI = async (id) => {
  const URL = `/v1/generalAssumptions/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AddGeneralAssumptionsAPI = async (payload) => {
  const URL = "/v1/generalAssumptions";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};

export const DeleteGeneralAssumptionsByIdAPI = async (id) => {
  const URL = `/v1/generalAssumptions/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetGeneralAssumptionsDraftByIdAPI = async (parentId, draftId) => {
  const URL = `/v1/generalAssumptions/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteGeneralAssumptionsDraftAPI = async (parentId, draftId) => {
  const URL = `/v1/generalAssumptions/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
