import axiosClient from "./index";
import Cookies from "js-cookie";

//ASSETS LIST
export const GetAllAssetListAPI = async (
  pageNumber = 0,
  sort = "regionLookup",
  sortOrder = "ASC",
  searchTerm = ""
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/assets?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`;

  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetAssetRelatedFields = async () => {
  const URL = `/v1/assets/relatedFields`;

  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetSpecificAssetsListAPI = async (
  id,
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/assets/getAssetsVersions/${id}?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;

  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetProjectNameAPI = async (id) => {
  const URL = `/v1/assets/getProjectName/${id}`;
  //const URL = "/v1/benchmarks/developmentCosts";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetAssetListCommentsAPI = async (id) => {
  const URL = `/v1/assets/comments/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetAssetListByIdAPI = async (projectNameValue, versionNumber, isFromApproval) => {
  const URL = `/v1/assets/getSpecificAsset/${projectNameValue}/${versionNumber}?isFromApproval=${isFromApproval}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AddAssetListAPI = async (payload) => {
  const URL = "/v1/assets";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    // throw response;
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};

export const GetAssetListDraftByIdAPI = async (
  projectNameValue,
  parentId,
  draftId
) => {
  const URL = `/v1/assets/getDraft/${projectNameValue}/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteAssetListDraftAPI = async (
  projectNameValue,
  parentId,
  draftId
) => {
  const URL = `/v1/assets/deleteDraft/${projectNameValue}/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//GET ASSIGN USERS
export const GetAssignedUsers = async (region) => {
  const URL = !!region
    ? `/v1/assets/users?region=${region}`
    : `/v1/assets/users`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//PUT ASSET LIST FOR PROPONENT
export const UpdateAssetListAPI = async (
  id,
  draftId,
  payload,
  projectNameValue,
  type
) => {
  const URL = `/v1/assets/editProponent/${type}/${projectNameValue}/${id}/${draftId}`;
  try {
    const response = await axiosClient.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};

//PUT ASSET LIST FOR PROPONENT
export const UpdateAssignedAssetListAPI = async (
  id,
  payload,
  type,
  projectNameValue
) => {
  const URL = `/v1/assets/reAssigned/${type}/${projectNameValue}/${id}`;
  try {
    const response = await axiosClient.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//GET ASSIGN USERS
export const PostQuickCalculationById = async (
  projectNameValue,
  id,
  payload
) => {
  const URL = `/v1/assets/quickCalculations/${projectNameValue}/${id}`;
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetAssetListModelingByIdAPI = async (projectNameValue, id) => {
  const URL = `/v1/assets/models/${projectNameValue}/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const PostAssetListModel = async (projectNameValue, payload) => {
  const URL = `/v1/assets/models/${projectNameValue}`;
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//EDIT MODEL NAME
export const UpdateModelName = async (projectNameValue, id, newName) => {
  const URL = `/v1/assets/models/editModelLabel/${projectNameValue}/${id}/${newName}`;
  try {
    const response = await axiosClient.put(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//EDIT MODEL ACTIVE
export const UpdateModelActive = async (projectNameValue, id) => {
  const URL = `/v1/assets/models/setActiveModel/${projectNameValue}/${id}`;
  try {
    const response = await axiosClient.put(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//DELETE MODEL
export const DeleteModel = async (projectNameValue, id) => {
  const URL = `/v1/assets/models/deleteModel/${projectNameValue}/${id}`;
  try {
    const response = await axiosClient.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const DownloadFile = async (reportId = "", route) => {
  const URL = `/v1/assets/${route}/${reportId}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteAssetAPI = async (projectNameValue) => {
  const URL = `/v1/assets/${projectNameValue}`;
  try {
    const response = await axiosClient.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const HideAssetAPI = async (projectNameValue, status) => {
  const URL = `/v1/assets/${status}/${projectNameValue}`;
  try {
    const response = await axiosClient.put(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const calculateTableValues = async (payload) => {
  const URL = `/v1/assets/calculateTableValues`;
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const GetDescriptionAPI = async (id) => {
  const URL = `/v1/assets/description/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data.data.description;
  } catch (error) {
    throw error;
  }
};

export const UpdateDescriptionAPI = async (id, payload) => {
  const URL = `/v1/assets/description/${id}`;
  try {
    const response = await axiosClient.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetVersions = async (assetNameValue = "") => {
  const URL = `/v1/assets/approvalList/asset/${assetNameValue}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const  GetApprovalData = async (assetNameValue,versionNumber)=>{

  const URL = `/v1/assets/approvalList/asset/${assetNameValue}/${versionNumber}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getCalculatedOverview = async () => {
  const URL = `/v1/assets/approvalList/calculatedOverview`;
  try {
    const response = await axiosClient.get(URL);
    return response.data.data;
  } catch (error) {
    throw error;
  }
}
 