import React, { useEffect, useRef, useState } from "react";

import { Box, Text, Flex, Divider, GridItem } from "@chakra-ui/react";

import { InputFieldValidation } from "../../../components/inputField/InputFieldValidation";
import { MainButton } from "../../../components/button/MainButton";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { FieldArray } from "formik";

import rightArrow from "../../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../../assets/images/icons/Arrow-Left.svg";
import percent from "../../../assets/images/icons/filled.svg";

let current = new Date();

export const DevAndFinStep2 = ({
  nextPage,
  prevPage,
  errors,
  touched,
  onChange,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  onDraftOpen,
  formikValues,
  disableSave,
  setDismissSave,
  isRestore,
  isModelWarningOpen,
  setValidateStep,
  validateStep,
  closeToast,
}) => {
  let form2Data = [];
  formikValues.baseFee.forEach((year) => {
    form2Data.push(year);
  });
  formikValues?.incentiveFee?.forEach((item) => {
    form2Data.push(item.ifGOPLessThanPercent);
    form2Data.push(item.feePercentOfGOP);
  });
  let emptyform2Data = [];
  // if (form2Data.length > 0) {
  //   form2Data.forEach((item) => {
  //     if (item !== "" && !isNaN(`${item}`) && item >= 0 && item <= 100) {
  //       emptyform2Data.push(item);
  //     }
  //   });
  // }
  // if (formikValues.incentiveFee.length > 0) {
  //   formikValues.incentiveFee.forEach((item) => {
  //     form2Data.push(item);
  //     if (
  //       item.ifGOPLessThanPercent !== 0 &&
  //       !isNaN(`${item.ifGOPLessThanPercent}`) &&
  //       item.ifGOPLessThanPercent !== "" &&
  //       item.feePercentOfGOP !== 0 &&
  //       item.feePercentOfGOP !== "" &&
  //       !isNaN(`${item.feePercentOfGOP}`) &&
  //       item.ifGOPLessThanPercent <= 100 &&
  //       item.feePercentOfGOP <= 100
  //     ) {
  //       emptyform2Data.push(item);
  //     }
  //   });
  // }
  console.log("form", form2Data);
  let ifGopLessThan = false;
  form2Data.forEach((item, ind) => {
    if (ind >= 5 && ind <= 16) {
      let countGreaterDesc = 0;
      formikValues.incentiveFee.forEach((item2, ind2) => {
        if (formikValues.incentiveFee.length - 2 >= ind2) {
          if (
            formikValues.incentiveFee[ind2 + 1].ifGOPLessThanPercent >=
            item2.ifGOPLessThanPercent
          ) {
            countGreaterDesc = countGreaterDesc + 1;
          }
        }
      });
      if (countGreaterDesc === 5 && parseInt(item) <= "100") {
        ifGopLessThan = false;
        emptyform2Data.push(item);
      } else {
        ifGopLessThan = true;
      }
    } else {
      if (item !== "" && item >= "0") {
        emptyform2Data.push(item);
      }
    }
  });
  let isDisabledForm = false;
  if (emptyform2Data.length !== form2Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  let currDate = parseInt(new Date().toJSON().slice(0, 4));
  currDate = currDate - 1;
  const renderBaseFeeFields = () => {
    return (
      <FieldArray
        name="baseFee"
        render={(arrayHelper) => (
          <Box display="flex" width="100%" gap="2.5%" flexWrap="wrap">
            {formikValues.baseFee !== undefined &&
              formikValues.baseFee.map((_, index) => {
                currDate = currDate + 1;
                return (
                  <Box width={["100%", "100%", "18%"]} key={`baseFee_${index}`}>
                    <InputFieldValidation
                      label={`Year ${currDate}`}
                      placeholder="0.00 "
                      smallIcon={percent}
                      inputType="number"
                      inputId={`baseFee.${index}`}
                      inputName={`baseFee.${index}`}
                      errors={errors}
                      touched={touched}
                      isRequired
                      onChange={setFieldValue}
                      values={formikValues?.baseFee[index]}
                      labelCenter={true}
                      errorHeight0={true}
                      uppercase={false}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      directVal={true}
                    />
                  </Box>
                );
              })}
          </Box>
        )}
      />
    );
  };

  const renderIncentiveFeeFields = () => {
    return (
      <FieldArray
        name="incentiveFee"
        render={(arrayHelper) =>
          formikValues.incentiveFee !== undefined &&
          formikValues.incentiveFee.map((_, index) => {
            return (
              <Box
                display="flex"
                width="100%"
                gap="4%"
                flexWrap="wrap"
                key={`incentiveFee_${index}`}
              >
                <Box width={["100%", "100%", "48%"]}>
                  <InputFieldValidation
                    label="if GOP <"
                    placeholder="0.00"
                    inputType="number"
                    inputId={`incentiveFee.${index}.ifGOPLessThanPercent`}
                    inputName={`incentiveFee.${index}.ifGOPLessThanPercent`}
                    errors={errors}
                    touched={touched}
                    isRequired
                    onChange={setFieldValue}
                    values={
                      formikValues.incentiveFee[index].ifGOPLessThanPercent
                    }
                    // errorHeight0={true}
                    icon={percent}
                    isDisabled={isRestore}
                    labelVisibility={index === 0 ? true : false}
                    validateField={validateStep}
                    directVal={true}
                    errorShape={true}
                  />
                </Box>
                <Box width={["100%", "100%", "48%"]}>
                  <InputFieldValidation
                    label={`Incentive fee % of AGOP`}
                    placeholder="0.00%"
                    icon={percent}
                    inputType="number"
                    inputId={`incentiveFee.${index}.feePercentOfGOP`}
                    inputName={`incentiveFee.${index}.feePercentOfGOP`}
                    errors={errors}
                    touched={touched}
                    isRequired
                    onChange={setFieldValue}
                    values={formikValues.incentiveFee[index].feePercentOfGOP}
                    //errorHeight0={true}
                    isDisabled={isRestore}
                    labelVisibility={index === 0 ? true : false}
                    validateField={validateStep}
                    directVal={true}
                  />
                </Box>
              </Box>
            );
          })
        }
      />
    );
  };
  console.log("isDisabledForm", isDisabledForm);
  return (
    <>
      <Box alignItems="flex-start" width="100%" mb={5}>
        <MainTitle title="Management Fees" />
      </Box>

      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        width="100%"
        position="relative"
      >
        <Box maxW="50%" width="50%" alignSelf="flex-start">
          <Text className="title_md" mb={4}>
            Base fee
          </Text>
          {renderBaseFeeFields()}
        </Box>

        <Box maxW="50%" width="50%" alignSelf="flex-start">
          <Text className="title_md" mb={4} mt={7}>
            Incentive fee
          </Text>
          {renderIncentiveFeeFields()}
          {ifGopLessThan && (
            <GridItem colSpan={4}>
              <Text
                color="#e14642"
                fontWeight="400"
                fontSize="10px"
                lineHeight="17px"
                letterSpacing="0.04em"
              >
                Each field should be less than or equal the next field of IF GOP
              </Text>
            </GridItem>
          )}
        </Box>
      </Box>

      <Divider orientation="horizontal" mt="30px" mb="30px" />

      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          btnStyle="transparent"
          disabledBtn={isRestore}
          fontColor="dark"
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />

        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/DevAndFinHistory`}
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {!isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                disabledBtn={false}
                onPress={() => {
                  closeToast();
                  nextPage();
                  setValidateStep(false);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                disabledBtn={false}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
