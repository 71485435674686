import React, { useEffect, useRef, useState } from "react";

import {
  GetProductionServiceApartmant,
  GetProductionCityBenchmarks,
  GetProductionResortBenchmarks,
} from "../../api/BenchmarksAPI";

import {
  Box,
  Flex,
  Divider,
  Text,
  HStack,
  Accordion,
  AccordionItem,
  Heading,
  AccordionButton,
  SimpleGrid,
  GridItem,
  AccordionPanel,
  AccordionIcon,
  useToast,
} from "@chakra-ui/react";

import { ToastAlert } from "../../components/toastAlert/ToastAlert";

import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";

import percent from "../../assets/images/icons/filled.svg";
import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import { CalculationButton } from "../../components/calculationModal/calculationButton"

export const AssetsListForm6 = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  initFormVal,
  setInitFormVal,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  setAssociatedDropdown,
  benchmarkDropdown,
  associatedDropdown,
  setStepWarning,
  unsetStepWarning,
  setStepperUpdate,
  stepperUpdate,
  validateStep,
  setValidateStep,
  id,
  modelWarningOpen,
  isModelWarningOpen,
  closeModalPath,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
  assetData,
  user
}) => {
  const toast = useToast();
  let accordTitle = [
    "Rooms",
    "Food & Beverage",
    "Recreation",
    "Spa",
    "Other Profit Departments",
    "Retail",
    "Miscellaneous Income",
    "Rental",
    "Other 1",
    "Other 2",
  ];

  const [arrayKeys, setArrayKeys] = useState([]);
  const inputRef = useRef(null);

  const dataFetchedRef = useRef(false);

  let arrKeys = [];
  if (formikValues?.departmentalExpenses) {
    arrKeys = Object.keys(formikValues.departmentalExpenses);
    if (JSON.stringify(arrayKeys) !== JSON.stringify(arrKeys)) {
      setArrayKeys(arrKeys);
    }
  }
  let form6Data = [];
  let emptyform6Data = [];
  form6Data.push(formikValues.benchmarkType); //string
  form6Data.push(formikValues.positioning); //string
  form6Data.push(formikValues.expenseLevel); //string
  form6Data.push(formikValues.economiesOfScale); //string
  let index = []
  if (arrKeys.length > 0) {
    arrKeys.forEach((el) => {
      if (!!formikValues?.departmentalExpenses[el]?.costOfSales) {
        form6Data.push(formikValues.departmentalExpenses[el].costOfSales.value);
        form6Data.push(formikValues.departmentalExpenses[el].costOfSales.fixed);
      }
      if (!!formikValues?.departmentalExpenses[el]?.other) {
        form6Data.push(formikValues.departmentalExpenses[el].other.value);
        form6Data.push(formikValues.departmentalExpenses[el].other.fixed);
      }
      if (!!formikValues?.departmentalExpenses[el]?.payroll) {
        form6Data.push(formikValues.departmentalExpenses[el].payroll.value);
        index.push(form6Data.length - 1)
        // console.log(el, form6Data, form6Data.length - 1)
        form6Data.push(formikValues.departmentalExpenses[el].payroll.fixed);
      }
      if (!!formikValues?.departmentalExpenses[el]?.payrollYearlyIncrement) {
        form6Data.push(
          formikValues.departmentalExpenses[el].payrollYearlyIncrement.year1
        );
        form6Data.push(
          formikValues.departmentalExpenses[el].payrollYearlyIncrement.year2
        );
        form6Data.push(
          formikValues.departmentalExpenses[el].payrollYearlyIncrement.year3
        );
      }
    });
  }
  form6Data.push(formikValues.departmentalExpenses.other1.otherOneLabel);
  form6Data.push(formikValues.departmentalExpenses.other2.otherTwoLabel);

  form6Data.forEach((item, ind) => {

    if (ind <= 2 || ind == 76 || ind == 77) {
      if (item !== "" && item >= "0") {
        emptyform6Data.push(item);
      }
    } else if (index.includes(ind)) {
      console.log(ind)
      if (item !== "" && parseInt(item) >= 0) {
        emptyform6Data.push(item);
      }
    } else if (item !== "" && parseInt(item) >= 0 && parseInt(item) <= 100) {
      emptyform6Data.push(item);
    }

  });
  let isDisabledForm = false;
  console.log(emptyform6Data, form6Data);

  if (emptyform6Data.length !== form6Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }
  const handleAccordErrors = () => {
    let openHigherAccord = true;

    form6Data.forEach((el, index) => {
      if (openHigherAccord) {
        if (el === "" || el > 100) {
          switch (true) {
            case index <= 10:
              openHigherAccord = false;
              handleOpenAccord(0);
              break;
            case index >= 11 && index <= 16:
              openHigherAccord = false;
              handleOpenAccord(1);
              break;
            case index >= 17 && index <= 20:
              openHigherAccord = false;
              handleOpenAccord(2);
              break;
            case index >= 21 && index <= 26:
              openHigherAccord = false;
              handleOpenAccord(3);
              break;
            case index >= 27 && index <= 30:
              openHigherAccord = false;
              handleOpenAccord(4);
              break;
            case index >= 31 && index <= 36:
              openHigherAccord = false;
              handleOpenAccord(5);
              break;
            case index >= 37 && index <= 38:
              openHigherAccord = false;
              handleOpenAccord(6);
              break;
            case index >= 39 && index <= 40:
              openHigherAccord = false;
              handleOpenAccord(7);
              break;
            case index >= 41 && index <= 44:
              openHigherAccord = false;
              handleOpenAccord(8);
              break;
            case index >= 45 && index <= 48:
              openHigherAccord = false;
              handleOpenAccord(9);
              break;
            default:
              break;
          }
        }
      }
    });
  };

  const handleOpenAccord = (accordIndex) => {
    if (
      inputRef.current.querySelectorAll(
        ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
      )[accordIndex].ariaExpanded === "false"
    ) {
      inputRef.current
        .querySelectorAll(
          ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
        )
      [accordIndex].click();
    }
  };
  let changeName = (el, key) => {
    handleBlur(el)

    if (el.target.value == "") {
      setFieldValue(el.target.id, key == "otherOneLabel" ? "Other 1" : "Other 2")

    }
  }
  let renderAccrodionFields = (
    mData,
    pos,
    renderPayRoll,
    renderPayrollIncrements,
    renderCostOfSale,
    renderOtherExpenses
  ) => {

    let inputAccord = false
    let key
    if (accordTitle[pos] == "Other 1" || accordTitle[pos] == "Other 2") {
      inputAccord = true
      key = accordTitle[pos] == "Other 1" ? "otherOneLabel" : "otherTwoLabel"
    }

    return (

      <AccordionItem mb="4" borderBottom="none">
        <Heading
          borderLeft="1px"
          borderBottom="1px"
          borderRight="1px"
          borderColor="#E6E6E6"
        >
          <AccordionButton bg="#FCFCFC">
            <Box flex="1" textAlign="left">
              <Text
                p="1"
                pb="1"
                pt="1"
                fontSize="15px"
                fontWeight="400"
                letterSpacing="0.04em"
                textTransform="uppercase"
              >
                {formikValues?.departmentalExpenses[arrayKeys[pos]] && inputAccord ? formikValues.departmentalExpenses[arrayKeys[pos]][key] : accordTitle[pos]}
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>

        <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">

          <Box>
            {inputAccord && (
              <Box mb={5} maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start">

                <Box textAlign="center">
                  <InputFieldValidation
                    label='label'
                    inputType="text"
                    inputId={`departmentalExpenses.${arrayKeys[pos]}.${key}`}
                    inputName={`departmentalExpenses.${arrayKeys[pos]}.${key}`}
                    errors={errors}
                    setIsFormDirty={setIsFormDirty}
                    isFormDirty={isFormDirty}
                    isRequired
                    onBlur={(e) => { changeName(e, key) }

                    }
                    onChange={setFieldValue}
                    directVal={true}
                    isDisabled={isRestore}
                    validateField={validateStep}
                    setFieldTouched={setFieldTouched}
                    values={
                      formikValues?.departmentalExpenses[
                      arrayKeys[pos]
                      ]?.[key]
                    }
                  />

                </Box>

              </Box>
            )}
            <HStack>
              {renderPayRoll && (
                <Box
                  maxW={["100%", "100%", "40%"]}
                  width={["100%", "100%", "40%"]}
                  alignSelf="flex-start"
                >
                  <Text
                    fontSize={"12px"}
                    display="flex"
                    mb="4 !important"
                    mt={3}
                    component="div"
                  >
                    PAYROLL
                  </Text>
                  <SimpleGrid
                    columns={{ sm: 2, md: 2 }}
                    spacingX="15px"
                    width="100%"
                  >
                    <GridItem colSpan={2}>
                      <SimpleGrid
                        columns={{ sm: 2, md: 2 }}
                        spacing="15px"
                        width="100%"
                      >
                        <GridItem>
                          <Box textAlign="center">
                            <InputFieldValidation
                              label="Value"
                              labelInfo={
                                id === undefined ? "Benchmark Data" : ""
                              }
                              uppercase={false}
                              inputType="number"
                              inputId={`departmentalExpenses.${arrayKeys[pos]}.payroll.value`}
                              inputName={`departmentalExpenses.${arrayKeys[pos]}.payroll.value`}
                              placeholder="0"
                              errors={errors}
                              setIsFormDirty={setIsFormDirty}
                              isFormDirty={isFormDirty}
                              touched={touched}
                              labelCenter={true}
                              isRequired
                              onChange={setFieldValue}
                              values={
                                formikValues?.departmentalExpenses[
                                  arrayKeys[pos]
                                ]?.["payroll"]?.value
                              }
                              minNum={0}
                              directVal={true}
                              // smallLeftIcon={"SAR"}
                              smallLeftIcon="SAR"
                              smallerIcon={true}
                              // smallIcon={percent}
                              isDisabled={isRestore}
                              validateField={validateStep}
                              isThousand={true}
                              setFieldTouched={setFieldTouched}
                            />
                          </Box>
                        </GridItem>

                        <GridItem>
                          <Box textAlign="center">
                            <InputFieldValidation
                              label="Fixed Percentage"
                              uppercase={false}
                              inputType="number"
                              inputId={`departmentalExpenses.${arrayKeys[pos]}.payroll.fixed`}
                              inputName={`departmentalExpenses.${arrayKeys[pos]}.payroll.fixed`}
                              placeholder="0"
                              errors={errors}
                              setIsFormDirty={setIsFormDirty}
                              isFormDirty={isFormDirty}
                              touched={touched}
                              labelCenter={true}
                              isRequired
                              onChange={setFieldValue}
                              // smallLeftIcon={"SAR"}

                              values={
                                formikValues?.departmentalExpenses[
                                  arrayKeys[pos]
                                ]?.["payroll"]?.fixed
                              }
                              minNum={0}
                              directVal={true}
                              smallIcon={percent}
                              isDisabled={isRestore}
                              validateField={validateStep}
                              isThousand={true}
                              setFieldTouched={setFieldTouched}
                            />
                          </Box>
                        </GridItem>
                      </SimpleGrid>
                    </GridItem>
                  </SimpleGrid>
                </Box>
              )}
              {renderPayrollIncrements && (
                <Box
                ml={pos == 6 || pos == 7? "" :"7 !important"}
                maxW={["100%", "100%", "60%"]}
                  width={["100%", "100%", "60%"]}
                  alignSelf="flex-start"
                >
                  <Text
                    fontSize={"12px"}
                    display="flex"
                    mb="4 !important"
                    mt={3}
                    component="div"
                  >
                    YEARLY INCREMENTAL
                  </Text>
                  <SimpleGrid
                    columns={{ sm: 3, md: 3 }}
                    spacingX="15px"
                    width="100%"
                  >
                    <GridItem colSpan={3}>
                      <SimpleGrid
                        columns={{ sm: 3, md: 3 }}
                        spacing="15px"
                        width="100%"
                      >
                        <GridItem>
                          <Box textAlign="center">
                            <InputFieldValidation
                              label="Year 1"
                              uppercase={false}
                              inputType="number"
                              inputId={`departmentalExpenses.${arrayKeys[pos]}.payrollYearlyIncrement.year1`}
                              inputName={`departmentalExpenses.${arrayKeys[pos]}.payrollYearlyIncrement.year1`}
                              placeholder="0"
                              errors={errors}
                              setIsFormDirty={setIsFormDirty}
                              isFormDirty={isFormDirty}
                              touched={touched}
                              labelCenter={true}
                              isRequired
                              onChange={setFieldValue}
                              values={
                                formikValues?.departmentalExpenses[
                                  arrayKeys[pos]
                                ]?.["payrollYearlyIncrement"]?.year1
                              }
                              minNum={0}
                              directVal={true}
                              smallIcon={percent}
                              isDisabled={isRestore}
                              validateField={validateStep}
                              acceptNegative={true}
                              isThousand={true}
                              setFieldTouched={setFieldTouched}
                            />
                          </Box>
                        </GridItem>

                        <GridItem>
                          <Box textAlign="center">
                            <InputFieldValidation
                              label="Year 2"
                              uppercase={false}
                              inputType="number"
                              inputId={`departmentalExpenses.${arrayKeys[pos]}.payrollYearlyIncrement.year2`}
                              inputName={`departmentalExpenses.${arrayKeys[pos]}.payrollYearlyIncrement.year2`}
                              placeholder="0"
                              errors={errors}
                              setIsFormDirty={setIsFormDirty}
                              isFormDirty={isFormDirty}
                              touched={touched}
                              labelCenter={true}
                              isRequired
                              onChange={setFieldValue}
                              values={
                                formikValues?.departmentalExpenses[
                                  arrayKeys[pos]
                                ]?.["payrollYearlyIncrement"]?.year2
                              }
                              minNum={0}
                              directVal={true}
                              smallIcon={percent}
                              isDisabled={isRestore}
                              validateField={validateStep}
                              acceptNegative={true}
                              isThousand={true}
                              setFieldTouched={setFieldTouched}
                            />
                          </Box>
                        </GridItem>
                        <GridItem>
                          <Box textAlign="center">
                            <InputFieldValidation
                              label="Year 3"
                              uppercase={false}
                              inputType="number"
                              inputId={`departmentalExpenses.${arrayKeys[pos]}.payrollYearlyIncrement.year3`}
                              inputName={`departmentalExpenses.${arrayKeys[pos]}.payrollYearlyIncrement.year3`}
                              placeholder="0"
                              errors={errors}
                              setIsFormDirty={setIsFormDirty}
                              isFormDirty={isFormDirty}
                              touched={touched}
                              labelCenter={true}
                              isRequired
                              onChange={setFieldValue}
                              values={
                                formikValues?.departmentalExpenses[
                                  arrayKeys[pos]
                                ]?.["payrollYearlyIncrement"]?.year3
                              }
                              minNum={0}
                              directVal={true}
                              smallIcon={percent}
                              isDisabled={isRestore}
                              validateField={validateStep}
                              acceptNegative={true}
                              isThousand={true}
                              setFieldTouched={setFieldTouched}
                            />
                          </Box>
                        </GridItem>
                      </SimpleGrid>
                    </GridItem>
                  </SimpleGrid>
                </Box>
              )}
            </HStack>
            {renderCostOfSale && (
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  COST OF SALE
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Value"
                            labelInfo={
                              arrayKeys[pos] === "spa" ||
                                arrayKeys[pos] === "retail"
                                ? ""
                                : id === undefined
                                  ? "Benchmark Data"
                                  : ""
                            }
                            uppercase={false}
                            inputType="number"
                            inputId={`departmentalExpenses.${arrayKeys[pos]}.costOfSales.value`}
                            inputName={`departmentalExpenses.${arrayKeys[pos]}.costOfSales.value`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            onChange={setFieldValue}
                            values={
                              formikValues?.departmentalExpenses[
                                arrayKeys[pos]
                              ]?.["costOfSales"]?.value
                            }
                            minNum={0}
                            directVal={true}
                            smallIcon={percent}
                            isDisabled={isRestore}
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          // smallLeftIcon={"SAR"}
                          />
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Fixed Percentage"
                            uppercase={false}
                            inputType="number"
                            inputId={`departmentalExpenses.${arrayKeys[pos]}.costOfSales.fixed`}
                            inputName={`departmentalExpenses.${arrayKeys[pos]}.costOfSales.fixed`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            onChange={setFieldValue}
                            values={
                              formikValues?.departmentalExpenses[
                                arrayKeys[pos]
                              ]?.["costOfSales"]?.fixed
                            }
                            minNum={0}
                            directVal={true}
                            smallIcon={percent}
                            isDisabled={isRestore}
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
            )}
            {renderOtherExpenses && (
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  OTHER EXPENSES
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Value"
                            labelInfo={
                              arrayKeys[pos] === "miscellaneous" ||
                                arrayKeys[pos] === "rental"
                                ? ""
                                : id === undefined
                                  ? "Benchmark Data"
                                  : ""
                            }
                            uppercase={false}
                            inputType="number"
                            inputId={`departmentalExpenses.${arrayKeys[pos]}.other.value`}
                            inputName={`departmentalExpenses.${arrayKeys[pos]}.other.value`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            onChange={setFieldValue}
                            values={
                              formikValues?.departmentalExpenses[
                                arrayKeys[pos]
                              ]?.["other"]?.value
                            }
                            minNum={0}
                            directVal={true}
                            smallIcon={percent}
                            isDisabled={isRestore}
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          // smallLeftIcon={"SAR"}
                          />
                        </Box>
                      </GridItem>
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Fixed Percentage"
                            uppercase={false}
                            inputType="number"
                            inputId={`departmentalExpenses.${arrayKeys[pos]}.other.fixed`}
                            inputName={`departmentalExpenses.${arrayKeys[pos]}.other.fixed`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            onChange={setFieldValue}
                            values={
                              formikValues?.departmentalExpenses[
                                arrayKeys[pos]
                              ]?.["other"]?.fixed
                            }
                            minNum={0}
                            directVal={true}
                            smallIcon={percent}
                            isDisabled={isRestore}
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
            )}
          </Box>

        </AccordionPanel>
      </AccordionItem>

    );
  };

  const closeToast = () => {
    toast.closeAll();
  };

  const handleChange = (e, e2) => {
    if (e2 === "Serviced Apartments") {
      setAssociatedDropdown(benchmarkDropdown.serviced);
      setFieldValue("positioning", "");
    }
    if (e2 === "City benchmark") {
      setAssociatedDropdown(benchmarkDropdown.city);
      setFieldValue("positioning", "");
    }

    if (e2 === "Resort benchmark") {
      setAssociatedDropdown(benchmarkDropdown.resort);
      setFieldValue("positioning", "");
    }
    setFieldValue("expenseLevel", "");

  };
  const handleChangeEmpty = (e, e2) => {
    setFieldValue("expenseLevel", "");
  };
  const handleChangeData = (e, e2) => {
    if (e2 != "") {
      if (formikValues.benchmarkType != "" && formikValues.positioning != "") {
        let posId = `${formikValues.positioning}`.split(" ").join("_");
        let fetchDataAPI;
        let expLevel;
        if (`${e2}`.toLowerCase() === "low") {
          expLevel = "low";
        } else if (`${e2}`.toLowerCase() === "high") {
          expLevel = "high";
        } else if (`${e2}`.toLowerCase() === "average") {
          expLevel = "average";
        }
        if (`${formikValues.benchmarkType}`.includes("Resort")) {
          fetchDataAPI = GetProductionResortBenchmarks;
        } else if (`${formikValues.benchmarkType}`.includes("City")) {
          fetchDataAPI = GetProductionCityBenchmarks;
        } else if (`${formikValues.benchmarkType}`.includes("Serviced")) {
          fetchDataAPI = GetProductionServiceApartmant;
        }

        const multiplier = 1 + (formikValues.economiesOfScale || 0) / 100;
        fetchDataAPI(posId)
          .then((res) => {
            if (res.success) {
              let count = 0;
              if (res.data) {
                let jsonParsed = JSON.parse(res.data.formFields);
                let {
                  rooms,
                  other2,
                  other1,
                  miscellaneous,
                  retail,
                  otherProfitDepartments,
                  spa,
                  recreation,
                  foodAndBeverage,
                } = jsonParsed?.departmentalExpenses;
                let {
                  utilities,
                  propertyOperations,
                  salesMarketing,
                  informationTelecom,
                  adminGeneral,
                  fixedCharges,
                } = jsonParsed?.undistributedExpenses;
                let formikPropertyOperations =
                  formikValues.undistributedExpenses.propertyOperations;
                let formikSalesMarketing =
                  formikValues.undistributedExpenses.salesMarketing;
                let formikInformationTelecom =
                  formikValues.undistributedExpenses.informationTelecom;
                let formikAdminGeneral =
                  formikValues.undistributedExpenses.adminGeneral;
                let formikOther2 = formikValues.departmentalExpenses.other2;
                let formikOther1 = formikValues.departmentalExpenses.other1;
                let formikRetail = formikValues.departmentalExpenses.retail;
                let formikOtherProfDep =
                  formikValues.departmentalExpenses.otherProfitDepartments;
                let formikSpa = formikValues.departmentalExpenses.spa;
                let formikRecreation =
                  formikValues.departmentalExpenses.recreation;
                let formikFoodAndBeverage =
                  formikValues.departmentalExpenses.foodAndBeverage;
                let formikRooms = formikValues.departmentalExpenses.rooms;

                setInitFormVal({
                  ...formikValues,
                  expenseLevel: e2,
                  departmentalExpenses: {
                    rooms: {
                      payroll: {
                        value: rooms.payroll[expLevel] * multiplier,
                        fixed: formikRooms.payroll.fixed,
                      },
                      payrollYearlyIncrement: {
                        ...formikRooms.payrollYearlyIncrement,
                      },
                      other: {
                        value: rooms.other[expLevel] * multiplier,
                        fixed: formikRooms.other.fixed,
                      },
                    },
                    foodAndBeverage: {
                      payroll: {
                        value: foodAndBeverage.payroll[expLevel] * multiplier,
                        fixed: formikFoodAndBeverage.payroll.fixed,
                      },
                      costOfSales: {
                        value: foodAndBeverage.costOfSales[expLevel] * multiplier,
                        fixed: formikFoodAndBeverage.costOfSales.fixed,
                      },

                      other: {
                        value: foodAndBeverage.other[expLevel] * multiplier,
                        fixed: formikFoodAndBeverage.other.fixed,
                      },
                    },
                    recreation: {
                      payroll: {
                        value: recreation.payroll[expLevel] * multiplier,
                        fixed: formikRecreation.payroll.fixed,
                      },

                      other: {
                        value: recreation.other[expLevel] * multiplier,
                        fixed: formikRecreation.other.fixed,
                      },
                    },
                    spa: {
                      payroll: {
                        value: spa.payroll[expLevel] * multiplier,
                        fixed: formikSpa.payroll.fixed,
                      },
                      costOfSales: {
                        value: formikSpa.costOfSales.value,
                        fixed: formikSpa.costOfSales.fixed,
                      },

                      other: {
                        value: spa.other[expLevel] * multiplier,
                        fixed: formikSpa.other.fixed,
                      },
                    },
                    otherProfitDepartments: {
                      payroll: {
                        value: otherProfitDepartments.payroll[expLevel] * multiplier,
                        fixed: formikOtherProfDep.payroll.fixed,
                      },

                      other: {
                        value: otherProfitDepartments.other[expLevel] * multiplier,
                        fixed: formikOtherProfDep.other.fixed,
                      },
                    },
                    retail: {
                      payroll: {
                        value: retail.payroll[expLevel] * multiplier,
                        fixed: formikRetail.payroll.fixed,
                      },
                      costOfSales: {
                        value: formikRetail.costOfSales.value,
                        fixed: formikRetail.costOfSales.fixed,
                      },

                      other: {
                        value: retail.other[expLevel] * multiplier,
                        fixed: formikRetail.other.fixed,
                      },
                    },
                    miscellaneous: {
                      ...formikValues.departmentalExpenses.miscellaneous,
                    },
                    rental: { ...formikValues.departmentalExpenses.rental },
                    other1: {
                      payroll: {
                        value: other1.payroll[expLevel] * multiplier,
                        fixed: formikOther1.payroll.fixed,
                      },

                      other: {
                        value: other1.other[expLevel] * multiplier,
                        fixed: formikOther1.other.fixed,
                      },
                      otherOneLabel: formikOther1.otherOneLabel
                    },
                    other2: {
                      payroll: {
                        value: other2.payroll[expLevel] * multiplier,
                        fixed: formikOther2.payroll.fixed,
                      },

                      other: {
                        value: other2.other[expLevel] * multiplier,
                        fixed: formikOther2.other.fixed,
                      },
                      otherTwoLabel: formikOther2.otherTwoLabel
                    },
                  },
                  //STEP 7
                  undistributedExpenses: {
                    adminGeneral: {
                      payroll: {
                        value: adminGeneral.payroll[expLevel] * multiplier,
                        fixed: formikAdminGeneral.payroll.fixed,
                      },
                      payrollYearlyIncrement: {
                        year1: formikAdminGeneral.payroll.year1,
                        year2: formikAdminGeneral.payroll.year2,
                        year3: formikAdminGeneral.payroll.year3,
                      },
                      other: {
                        value: adminGeneral.other[expLevel] * multiplier,
                        fixed: formikAdminGeneral.payroll.fixed,
                      },
                      creditCardCommissions: {
                        ...formikAdminGeneral.creditCardCommissions,
                      },
                    },
                    informationTelecom: {
                      payroll: {
                        value: informationTelecom.payroll[expLevel] * multiplier,
                        fixed: formikInformationTelecom.payroll.fixed,
                      },
                      payrollYearlyIncrement: {
                        year1:
                          formikInformationTelecom.payrollYearlyIncrement.year1,
                        year2:
                          formikInformationTelecom.payrollYearlyIncrement.year2,
                        year3:
                          formikInformationTelecom.payrollYearlyIncrement.year3,
                      },
                      other: {
                        value: informationTelecom.other[expLevel] * multiplier,
                        fixed: formikInformationTelecom.other.fixed,
                      },
                    },
                    salesMarketing: {
                      payroll: {
                        value: salesMarketing.payroll[expLevel] * multiplier,
                        fixed: formikSalesMarketing.payroll.fixed,
                      },
                      payrollYearlyIncrement: {
                        year1:
                          formikSalesMarketing.payrollYearlyIncrement.year1,
                        year2:
                          formikSalesMarketing.payrollYearlyIncrement.year2,
                        year3:
                          formikSalesMarketing.payrollYearlyIncrement.year3,
                      },
                      other: {
                        value: salesMarketing.other[expLevel] * multiplier,
                        fixed: formikSalesMarketing.other.fixed,
                      },
                      otherExpensesYearlyIncrement: {
                        year1:
                          formikSalesMarketing.otherExpensesYearlyIncrement
                            .year1,
                        year2:
                          formikSalesMarketing.otherExpensesYearlyIncrement
                            .year2,
                        year3:
                          formikSalesMarketing.otherExpensesYearlyIncrement
                            .year3,
                      },
                    },
                    propertyOperations: {
                      payroll: {
                        value: propertyOperations.payroll[expLevel] * multiplier,
                        fixed: formikPropertyOperations.payroll.fixed,
                      },
                      payrollYearlyIncrement: {
                        year1:
                          formikPropertyOperations.payrollYearlyIncrement.year1,
                        year2:
                          formikPropertyOperations.payrollYearlyIncrement.year2,
                        year3:
                          formikPropertyOperations.payrollYearlyIncrement.year3,
                      },
                      other: {
                        value: propertyOperations.other[expLevel] * multiplier,
                        fixed: formikPropertyOperations.other.fixed,
                      },
                      otherPayrollYearlyIncrement: {
                        year1:
                          formikPropertyOperations.otherPayrollYearlyIncrement
                            .year1,
                        year2:
                          formikPropertyOperations.otherPayrollYearlyIncrement
                            .year2,
                        year3:
                          formikPropertyOperations.otherPayrollYearlyIncrement
                            .year3,
                      },
                    },
                    utilities: {
                      otherPerKey: {
                        value: utilities.otherPerKey[expLevel] * multiplier,
                        fixed:
                          formikValues.undistributedExpenses.utilities
                            .otherPerKey.fixed,
                      },
                      payrollYearlyIncrement: {
                        year1:
                          formikValues.undistributedExpenses.utilities
                            .payrollYearlyIncrement.year1,
                        year2:
                          formikValues.undistributedExpenses.utilities
                            .payrollYearlyIncrement.year2,
                        year3:
                          formikValues.undistributedExpenses.utilities
                            .payrollYearlyIncrement.year3,
                      },
                    },
                  },
                  fixedIncome: fixedCharges.income[expLevel] * multiplier,
                  operationalInsurance:
                    fixedCharges.operationalInsurance[expLevel] * multiplier,
                  propertyAndOtherTaxes:
                    fixedCharges.propertyAndOtherTaxes[expLevel] * multiplier,
                });
              } else {
                if (count === 0) {
                  toast({
                    position: "top-right",
                    isClosable: true,
                    render: () => (
                      <ToastAlert
                        title="Please select 3rd party Opex Benchmarks from (i) City Benchmarks, (ii) Resort Benchmarks, (iii) Serviced Apartment Benchmarks."
                        icon={infoCircle}
                        closeToast={closeToast}
                        type="Warning"
                      />
                    ),
                  });
                  count = count + 1;
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        /* } */
      }
    }
  };
  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);
console.log('erro', errors)
  return (
    <>
      <Box alignItems="flex-start" justifyContent="space-between" display="flex" width="100%">
        <MainTitle title="DEPARTMENTAL EXPENSES" />
        <CalculationButton formikValue={formikValues} />

      </Box>
      <Box
        maxW={["100%", "100%", "50%"]}
        width={["100%", "100%", "50%"]}
        alignSelf="flex-start"
      >
        <Text className="title_md" display="flex" mb={5} mt={3} component="div">
          Departmental Expense Benchmark Levels{" "}
        </Text>
        <Box mb={5}>
          <InputFieldValidation
            label="Economies Of Scale"
            inputType="number"
            inputId="economiesOfScale"
            inputName="economiesOfScale"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={-100}
            icon={percent}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />
        </Box>
        <Box mb={5}>
          <SelectListMulti
            key={`myUniqueKey_${formikValues?.benchmarkType}`}
            dropdownItems={dropdownObj.benchmarkTypes}
            label="Benchmark Type"
            inputId="benchmarkType"
            inputName="benchmarkType"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            values={formikValues.benchmarkType}
            onChange={setFieldValue}
            onBlur={handleBlur}
            onChange2={(e, e2) => handleChange(e, e2)}
            uppercase={true}
            iconArrow={true}
            placeH="Select"
            isDisabled={isRestore}
            validateField={validateStep}
            isDynamic={true}
          />
        </Box>
        <Box mb={5}>
          <SelectListMulti
            key={`myUniqueKey_${formikValues?.positioning}`}
            dropdownItems={associatedDropdown}
            label="Positioning"
            inputId="positioning"
            inputName="positioning"
            placeH="Select"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            values={formikValues.positioning}
            onChange={setFieldValue}
            onBlur={handleBlur}
            uppercase={true}
            iconArrow={true}
            isDisabled={isRestore}
            validateField={validateStep}
            onChange2={(e, e2) => handleChangeEmpty(e, e2)}
            isDynamic={true}
          />
        </Box>
        <Box mb={5}>
          <SelectListMulti
            key={`myUniqueKey_${formikValues?.expenseLevel}`}
            dropdownItems={dropdownObj.expenseLevel}
            label="Expense Level"
            inputId="expenseLevel"
            inputName="expenseLevel"
            placeH="Select"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            values={formikValues.expenseLevel}
            onChange={setFieldValue}
            onBlur={handleBlur}
            uppercase={true}
            iconArrow={true}
            isDisabled={isRestore}
            validateField={validateStep}
            onChange2={(e, e2) => handleChangeData(e, e2)}
            isDynamic={true}
          />
        </Box>
      </Box>

      <Box
        maxW={["100%", "100%", "100%"]}
        width={["100%", "100%", "100%"]}
        alignSelf="flex-start"
      >
        <Text className="title_md" display="flex" mb={5} mt={3} component="div">
          Departmental Expense ASSUMPTIONS
        </Text>

        <Accordion
          width="100%"
          allowToggle
          color="black"
          ref={inputRef}
          defaultIndex={[0]}
        >
          {formikValues?.departmentalExpenses?.rooms &&
            renderAccrodionFields(
              formikValues?.departmentalExpenses?.rooms,
              0,
              true,
              true,
              false,
              true
            )}
          {formikValues?.departmentalExpenses?.foodAndBeverage &&
            renderAccrodionFields(
              formikValues?.departmentalExpenses?.foodAndBeverage,
              1,
              true,
              true,
              true,
              true
            )}
          {formikValues?.departmentalExpenses?.recreation &&
            renderAccrodionFields(
              formikValues?.departmentalExpenses?.recreation,
              2,
              true,
              true,
              false,
              true
            )}{" "}
          {formikValues?.departmentalExpenses?.spa &&
            renderAccrodionFields(
              formikValues?.departmentalExpenses?.spa,
              3,
              true,
              true,
              true,
              true
            )}
          {formikValues?.departmentalExpenses?.otherProfitDepartments &&
            renderAccrodionFields(
              formikValues?.departmentalExpenses?.otherProfitDepartments,
              4,
              true,
              true,
              false,
              true
            )}
          {formikValues?.departmentalExpenses?.retail &&
            renderAccrodionFields(
              formikValues?.departmentalExpenses?.retail,
              5,
              true,
              true,
              true,
              true
            )}
          {formikValues?.departmentalExpenses?.miscellaneous &&
            renderAccrodionFields(
              formikValues?.departmentalExpenses?.miscellaneous,
              6,
              false,
              true,
              false,
              true
            )}
          {formikValues?.departmentalExpenses?.rental &&
            renderAccrodionFields(
              formikValues?.departmentalExpenses?.rental,
              7,
              false,
              true,
              false,
              true
            )}
          {formikValues?.departmentalExpenses?.other1 &&
            renderAccrodionFields(
              formikValues?.departmentalExpenses?.other1,
              8,
              true,
              true,
              false,
              true
            )}
          {formikValues?.departmentalExpenses?.other2 &&
            renderAccrodionFields(
              formikValues?.departmentalExpenses?.other2,
              9,
              true,
              true,
              false,
              true
            )}
        </Accordion>
        <Divider orientation="horizontal" mt="30px" mb="30px" />
      </Box>

      <Flex justifyContent={user == "manager" ? "end" : "space-between"} alignItems="center" width="100%">
        {
          user !== "manager" ?
            <MainButton
              title="Save Draft"
              btnStyle="transparent"
              disabledBtn={isRestore}
              fontColor="dark"
              onPress={() => {
                setDismissSave(false);
                onDraftOpen();
              }}
            /> : <></>
        }
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  handleAccordErrors();
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  nextPage();
                  if (percentage < 64) {
                    setPercentage(64);
                  }
                  setStepCompleted(page);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
