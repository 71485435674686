import * as Yup from "yup";

export const validationDevAndFinForm = [
  Yup.object().shape({
    infrastructureCost: Yup.number().min(0, "Required").required("Required"),
    generalDepreciation1: Yup.number().min(0, "Required").required("Required"),
    parkingCost: Yup.number().min(0, "Required").required("Required"),
    generalDepreciation2: Yup.number().min(0, "Required").required("Required"),
    landFreehold: Yup.number().min(0, "Required").required("Required"),

    constructionCost: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    breakDepreciation1: Yup.number().min(0, "Required").required("Required"),
    ffeAndOse: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    breakDepreciation2: Yup.number().min(0, "Required").required("Required"),
    softCosts: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    breakDepreciation3: Yup.number().min(0, "Required").required("Required"),
    preOpening: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    breakDepreciation4: Yup.number().min(0, "Required").required("Required"),
    tsaFee: Yup.number().min(0, "Required").required("Required"),
    ITbudget: Yup.number().min(0, "Required").required("Required"),
    breakDepreciation5: Yup.number().min(0, "Required").required("Required"),
    neomHotelDevFee: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    breakDepreciation6: Yup.number().min(0, "Required").required("Required"),
    contingencyCost: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    breakDepreciation7: Yup.number().min(0, "Required").required("Required"),
    initialWorkingCapital: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    assetManagementFee: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
  }),
  Yup.object().shape({
    // gopSmaller1: Yup.number().min(0, "Required").required("Required"),
    // IncentiveFeeAGOP1: Yup.number().min(0, "Required").required("Required"),
    // gopSmaller2: Yup.number().min(0, "Required").required("Required"),
    // IncentiveFeeAGOP2: Yup.number().min(0, "Required").required("Required"),
    // gopSmaller3: Yup.number().min(0, "Required").required("Required"),
    // IncentiveFeeAGOP3: Yup.number().min(0, "Required").required("Required"),
    // gopSmaller4: Yup.number().min(0, "Required").required("Required"),
    // IncentiveFeeAGOP4: Yup.number().min(0, "Required").required("Required"),
    // gopSmaller5: Yup.number().min(0, "Required").required("Required"),
    // IncentiveFeeAGOP5: Yup.number().min(0, "Required").required("Required"),
    // gopSmaller6: Yup.number().min(0, "Required").required("Required"),
    // IncentiveFeeAGOP6: Yup.number().min(0, "Required").required("Required"),
    baseFee: Yup.array().of(
      Yup.number()
        .min(0, "Required")
        .max(100, "This should be less than 100")
        .required("This field is required")
    ),
    // incentiveFee: Yup.array().of(
    //   Yup.object().shape({
    //     ifGOPLessThanPercent: Yup.number()
    //       .min(0, "Required")
    //       .max(100, "This should be less than 100")
    //       .required("This field is required"),
    //     feePercentOfGOP: Yup.number()
    //       .min(0, "Required")
    //       .max(100, "This should be less than 100")
    //       .required("This field is required"),
    //   })
    // ),
    incentiveFee: Yup.array()
      .of(
        Yup.object().shape({
          ifGOPLessThanPercent: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")

            .required("Required"),
          feePercentOfGOP: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")

            .required("Required"),
        })
      )
      .test({
        name: "incentiveFee",
        exclusive: false,
        params: {},
        message: "   ",
        test: function (values) {
          console.log("ifGOPLessThanPercent values", values);
          let countGreaterDesc = 0;
          values.forEach((inactive, ind) => {
            if (values.length - 2 >= ind) {
              //console.log(inactive);
              console.log(values[ind + 1].ifGOPLessThanPercent);
              console.log(inactive.ifGOPLessThanPercent);
              if (
                values[ind + 1].ifGOPLessThanPercent >=
                inactive.ifGOPLessThanPercent
              ) {
                countGreaterDesc++;
              }
            }
          });
          console.log("countGreaterDesc", countGreaterDesc);
          if (countGreaterDesc === 5) {
            return values;
          } else {
            return false;
          }
        },
      }),
  }),
  Yup.object().shape({
    //level: Yup.string().required("level is required"),
    //addComment: Yup.string().required("This field is required"),
    annualInterestRate: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    debtTerm: Yup.number().min(0, "Required").required("Required"),
    gracePeriod: Yup.number().min(0, "Required").required("Required"),
    debtProcessingFees: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    securityFees: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    commitmentFees: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    riskFreeRate: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    equityRiskPremium: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    countryRiskPremium: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    unleveredBeta: Yup.number().min(0, "Required").required("Required"),
    manualInput: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
  }),
];
