import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import { Box, Text, Center, useDisclosure, Spinner } from "@chakra-ui/react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import { GetAllAssetListAPI, DeleteAssetAPI, HideAssetAPI } from "../../api/AssetListAPI";
import { GetProductionMasterDataWithIsActive } from "../../api/MasterDataAPI";

import { activeMenuItem } from "../../utils/General";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { MainButton } from "../../components/button/MainButton";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { AssetsTable } from "./AssetsTable";
import { AssetsModal } from "../../components/assetsModal/AssetsModal";

import plus from "../../assets/images/icons/plus.svg";
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import hotel from "../../assets/images/icons/hotel.svg";
import checked from "../../assets/images/icons/checked.svg";

export const AssetsHistory = () => {
  const [user, setUser] = useState();
  const activeMenu = activeMenuItem();
  const [assetData, setAssetData] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedID, setSelectedID] = useState("-1");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hideText, setHideText] = useState("");

  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose,
  } = useDisclosure();

  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const {
    isOpen: isDelAssetModalOpen,
    onOpen: onDelAssetModalOpen,
    onClose: onDelAssetModalClose,
  } = useDisclosure();

  const {
    isOpen: isHideAssetModalOpen,
    onOpen: onHideAssetModalOpen,
    onClose: onHideAssetModalClose,
  } = useDisclosure();
  const {
    isOpen: isHiddenSucsessOpen,
    onOpen: onHiddenSucsessOpen,
    onClose: onHiddenSucsessClose,
  } = useDisclosure();

  const {
    isOpen: isDeletSucsessOpen,
    onOpen: onDeletSucsessOpen,
    onClose: onDeletSucsessClose,
  } = useDisclosure();

  const loadTableData = useCallback(() => {
    let ignoreResult = false;
    document.getElementById("main_content").scrollTo(0, 0);

    GetAllAssetListAPI()
      .then((res) => {
        if (ignoreResult) {
          return;
        }
        setAssetData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      ignoreResult = true;
    }
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  useLayoutEffect(() => {
    let userVar = "";
    if (Cookies.get("user_info")) {
      let token = Cookies.get("user_info");
      //console.log("token", token);
      let tokenDecode = jwt_decode(token);
      setUser(tokenDecode);
      userVar = jwt_decode(token);
      setUser(userVar?.roles[0]);
    }

    GetProductionMasterDataWithIsActive()
      .then((res) => {
        if (res.success) {
          if (res?.data[0]?.lookups?.length > 0) {
            // console.log("region", res.data[0].lookups);
            setRegions(res.data[0].lookups);
          }
        }
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const renderRowsAfterDeleteAssest = (id) => {
    DeleteAssetAPI(id)
      .then((res) => {
        setErrorMessage("");
        setIsLoading(true);

        if (res.success === true) {
          onDeletSucsessOpen()
          loadTableData();
        }
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "Something went wrong");
        onErrorOpen();
      });
  };

  const renderRowsAfterHideOrUnhideAssest = (id, status) => {
    HideAssetAPI(id, status)
      .then((res) => {
        setErrorMessage("");
        setIsLoading(true);

        if (res.success === true) {
          status === "hide" ? setHideText("Asset has been hidden successfully") : setHideText("Asset is now not hidden")
          onHiddenSucsessOpen()
          loadTableData();
        }
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "Something went wrong");
        onErrorOpen();
      });
  };

  return (
    <>
      {isLoading ? (
        <>
          <MainTitle title={`Assets List`} />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {assetData?.pageRows?.length <= 0 ? (
            /* {0 === 0 ? ( */
            <>
              <MainTitle title="LIST OF ASSETS" />

              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={hotel} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">No assets created yet</Text>
                    </Box>
                    {user === "proponent" || user === "manager" ? (
                      ""
                    ) : (
                      <MainButton
                        title="Create New Asset"
                        marginRight="1"
                        icon={plus}
                        onPress={onCreateModalOpen}
                      />
                    )}
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <AssetsTable
                user={user}
                assetData={assetData}
                regions={regions}
                setSelectedID={setSelectedID}
                onDelModalOpen={onDelModalOpen}
                GetDataAPI={GetAllAssetListAPI}
                onCreateModalOpen={onCreateModalOpen}
                onDelAssetModalOpen={onDelAssetModalOpen}
                onHideAssetModalOpen={onHideAssetModalOpen}
                setHideText = {setHideText}
              />
            </>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isDelModalOpen}
        onClose={onDelModalClose}
        title="Are you sure you want to delete this version?"
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={true}
        haveSaveDraft={true}
        isDelete={true}
        selectedID={selectedID}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />

      <ModalAlert
        isOpen={isDelAssetModalOpen}
        onClose={onDelAssetModalClose}
        title={"Are you sure you want to delete this assest?"}
        icon={infoCircle}
        // hasDesc={false}
        confirmationModal={true}
        // haveSaveDraft={true}
        selectedID={selectedID}
        isDelete={true}
        isDeleteAssest={true}
        setAssetDelelID={selectedID}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        GetDataAPI={GetAllAssetListAPI}
        DeleteAsset={renderRowsAfterDeleteAssest}

      />

      <ModalAlert
        isOpen={isHideAssetModalOpen}
        onClose={onHideAssetModalClose}
        title={hideText}
        icon={infoCircle}
        // hasDesc={false}
        confirmationModal={true}
        // haveSaveDraft={true}
        selectedID={selectedID}
        isHide={true}
        isHideAssest={true}
        setAssetDelelID={selectedID}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        GetDataAPI={GetAllAssetListAPI}
        hiddenAsset={renderRowsAfterHideOrUnhideAssest}

      />

      <ModalAlert
        isOpen={isDeletSucsessOpen}
        onClose={onDeletSucsessClose}
        title="Asset has been deleted successfully"
        icon={checked}
        hasDesc={false}
        confirmationModal={false}
      />
       <ModalAlert
        isOpen={isHiddenSucsessOpen}
        onClose={onHiddenSucsessClose}
        title={hideText}
        icon={checked}
        hasDesc={false}
        confirmationModal={false}
      />
      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
      <AssetsModal
        isOpen={isCreateModalOpen}
        onClose={onCreateModalClose}
        title="SELECT SERVICE OFFERING"
        disableClose={false}
        path={`/AssetsList`}
      />
    </>
  );
};
