import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  HStack,
  TabPanel,
  TabPanels,
  Tabs,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import history from "../../history";

import { TablePagination2 } from "../../components/tablePagination/TablePagination2";
import threeDots from "../../assets/images/icons/threeDots.svg";
import remove from "../../assets/images/icons/deleteRed.svg";
import clone from "../../assets/images/icons/clone.svg";
import hide from "../../assets/images/icons/hide.svg";
import unhide from "../../assets/images/icons/unhide.svg";

export const AssetsTable = ({
  assetData,
  GetDataAPI,
  regions,
  onCreateModalOpen,
  user,
  onDelAssetModalOpen,
  setSelectedID,
  onHideAssetModalOpen,
  setHideText
}) => {
  const tableRef = useRef(null);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [totalRowsTable, setTotalRowsTable] = useState(assetData.totalRows);
  const columns = [
    {
      name: "ASSET NAME",
      selector: (row) => row.assetName,
      sortable: true,
      sortField: "projectName",
      cell: (row) => (
        <Box color={`#007BB5`} opacity={row.status == "Hidden"? '.5': '1'}>
          <NavLink to={`/AssetVersionHistory/${row.idCustom}`}>
            {row.assetName}
          </NavLink>{" "}
        </Box>
      ),
    },
    {
      name: "REGION",
      selector: (row) => row.region,
      sortable: true,
      sortField: "regionLookup",
      cell: (row) => (
        <Box  opacity={row.status == "Hidden"? '.5': '1'}>  
            {row.region}
        </Box>
      ),
    },
    {
      name: "LOCATION",
      selector: (row) => row.location,
      sortable: true,
      sortField: "regionLookup",
      cell: (row) => (
        <Box  opacity={row.status == "Hidden"? '.5': '1'}>   
            {row.location}    
        </Box>
      ),
    },
    {
      name: "ASSIGNED TO",
      selector: (row) => (row.assignedTo === "" ? "-" : row.assignedTo),
      sortable: true,
      sortField: "assignedTo",
      cell: (row) => (
        <Box  opacity={row.status == "Hidden"? '.5': '1'}>   
            {row.assignedTo}   
        </Box>
      ),
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      sortable: true,
      sortField: "status",
      // minWidth: "160px",
      // width: "160px",
      cell: (row) => (
        <Flex justifyContent="center" alignItems="center">
          <Badge
            data-tag="allowRowEvents"
            data-action="edit"
            bg={row.statusBG}
            color="#fff"
            fontSize="14px"
            fontWeight="300"
            px={3}
            py="1"
            textTransform="none"
            borderRadius="3px"
          >
            {/*  {row.status === "InReview"
              ? row.status.split(/(?=[A-Z])/)[0] +
                " " +
                row.status.split(/(?=[A-Z])/)[1]
              : row.status === "InitialDraft"
              ? "Draft"
              : row.status} */}
            {row.status === "InReview" || row.status === "InitialDraft"
              ? row.status.split(/(?=[A-Z])/)[0] +
              " " +
              row.status.split(/(?=[A-Z])/)[1]
              : row.status}
          </Badge>
          {user === "admin" && (row.status === "Production" || row.status === "Hidden") &&(
            <Menu autoSelect={false} >
              <MenuButton
                bgColor="transparent"
                as={Button}
                px={1}
                ml={1}
                minWidth="auto"
                _hover={{ backgroundColor: "transparent" }}
                onClick={() => console.log("button clicked")} // Typo fixed
              >
                <img src={threeDots} alt="dots icon" />
              </MenuButton>
              <MenuList px="5px" py="15px">
                <Text fontSize="12px" color="#6F767E" px="10px" mb="7px">
                  More actions
                </Text>

                <MenuItem alignItems="center" onClick={() => {
                  setSelectedID(`delete|${row.idCustom}`);
                  onDelAssetModalOpen();
                }}>
                  <Box
                    display="flex"
                    onClick={() => {
                      setSelectedID(`delete|${row.idCustom}`);
                      onDelAssetModalOpen();
                    }}
                  >
                    <img src={remove} width="17px" alt="edit icon" />
                    <Text
                      color="#003865"
                      ml="5px"
                      fontSize="12px"
                      fontWeight="400"
                    >
                      Remove Asset
                    </Text>
                  </Box>
                </MenuItem>
                <MenuItem alignItems="center" onClick={() => {
                  setSelectedID(`${row.status == 'Hidden' ? "unhide" : "hide"}}|${row.idCustom}`);
                  setHideText(`Are you sure you want to ${row.status == 'Hidden' ? "unhide" : "hide"} this assest`);
                  onHideAssetModalOpen();
                }}>
                  <Box
                    display="flex"

                  >
                    <img src={row.status == 'Hidden' ? unhide : hide} width="17px" alt="edit icon" />
                    <Text
                      color="#003865"
                      ml="5px"
                      fontSize="12px"
                      fontWeight="400"
                    >
                      {row.status == 'Hidden' ? "Unhide" : "Hide"} Asset
                    </Text>
                  </Box>
                </MenuItem>
                <MenuItem alignItems="center">
                  <Box
                    display="flex"
                    onClick={() => {
                      history.push(
                        `/AssetList/${row.type}/${row.idCustom}/${row.versionNumber}/0/clone`
                      );
                    }}
                  >
                    <img src={clone} width="17px" alt="edit icon" />
                    <Text
                      color="#003865"
                      ml="5px"
                      fontSize="12px"
                      fontWeight="400"
                    >
                      Clone Asset
                    </Text>
                  </Box>
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      ),
    },
  ];

  // *************** if newFilter<3 then leftright =0
  let [searchTerm, setSearchTerm] = useState("");

  let [currPagData, setCurrPagData] = useState({
    page: 1,
    sortOrder: "ASC",
    sort: "regionLookup",
  });

  const handleSort = async (column, sortDirection) => {
    tableRef?.current
      .querySelectorAll(".table_content .rdt_TableCol_Sortable")
    [column.id - 1]?.children[1]?.classList.add("active");
    let sortOrder = sortDirection.toUpperCase();
    let sort = column.sortField;
    if (!sort || !sortOrder) {
      return;
    }
    if (currPagData.sort === sort && currPagData.sortOrder === sortOrder) {
      return;
    }
    handleOnPageChange(currPagData.page, sort, sortOrder);
    setCurrPagData({ ...currPagData, sort: sort, sortOrder: sortOrder });
  };
  //console.log("currPagData", currPagData);
  const [dataArray, setDataArray] = useState([]);

  useEffect(() => {
    let data = [];
    console.log("dataArray", assetData);

    if (assetData.pageRows?.length >= 0 && !!assetData?.pageRows) {
      setTotalRowsTable(assetData.totalRows);
      assetData.pageRows.forEach((item) => {
        let regionStr = "";
        let statusBg;
        if (item.status === "Production") {
          statusBg = "#006B44";
        } else if (item.status === "Archived") {
          statusBg = "#A9A9A9";
        } else if (item.status === "Obsolete") {
          statusBg = "#007BB5";
        } else if (item.status === "InReview") {
          statusBg = "#F18825";
        }  else if (item.status === "Hidden") {
          statusBg = "#bc941d";
        }else {
          statusBg = "#007BB5";
        }
        //regionStr = item.regionLookup.split("_").join(" ");
        console.log("item.regionLookup", item.regionLookup);
        regionStr = regions.find(
          (reg) =>
            `${reg.value}`.toLowerCase() ===
            `${item.regionLookup}`.toLowerCase()
        )?.label;

        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.projectNameValue}`,
          isDraftVersion: item.status === "Draft",
          assetName: item.projectName,
          region: regionStr,
          assignedTo: item.assignedTo,
          location: regionStr,

          modBy: `${item.lastModifiedBy}`,
          status: `${item.status}`,
          statusBG: statusBg,
          versionNumber: item.versionNumber,
          type: item.assetType,
        };

        data.push(dataObj);
      });

      console.log("dddaaatttaa", data);
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
      sortOrder: "ASC",
      sort: "regionLookup",
    });
  }, []);

  const handleOnPageChange = useCallback(
    (page, sort = "regionLookup", sortOrder) => {
      let ignoreResult = false;
      //console.log("--------------------------------");
      //console.log("handleOnPageChange params", page, sort, sortOrder);
      //console.log("--------------------------------");
      GetDataAPI(page, sort, sortOrder, searchTerm)
        .then((res) => {
          if (ignoreResult) {
            return;
          }
          setTotalRowsTable(res.data.totalRows);
          let data = [];
          if (res.data.pageRows.length >= 0) {
            res.data.pageRows.forEach((item) => {
              let statusBg;
              if (item.status === "Production") {
                statusBg = "#006B44";
              } else if (item.status === "Archived") {
                statusBg = "#A9A9A9";
              } else if (item.status === "Obsolete") {
                statusBg = "#007BB5";
              } else if (item.status === "InReview") {
                statusBg = "#F18825";
              } else if (item.status === "Hidden") {
                statusBg = "#bc941d";
              }
              else {
                statusBg = "#007BB5";
              }
              let regionStr;
              regionStr = regions.find(
                (reg) =>
                  `${reg.value}`.toLowerCase() ===
                  `${item.regionLookup}`.toLowerCase()
              )?.label;
              let dataObj = {
                id: `${item.id + Math.floor(Math.random() * Date.now())}`,
                idCustom: `${item.projectNameValue}`,
                isDraftVersion: item.status === "Draft",
                assetName: item.projectName,
                region: regionStr,
                assignedTo: item.assignedTo,
                location: regionStr,

                modBy: `${item.lastModifiedBy}`,
                status: `${item.status}`,
                statusBG: statusBg,
                versionNumber: item.versionNumber,
                type: item.assetType,
              };

              data.push(dataObj);
            });
            console.log("dddaaatttaa", data);
            setDataArray(data);
            setIsTableLoading(false);
          }
        })
        .catch((err) => {
          setIsTableLoading(false);
          console.log(err);
        });

      return () => {
        ignoreResult = true;
      };
    },
    [GetDataAPI, regions, searchTerm]
  );
  // console.log("assetData.totalRows", assetData.totalRows);
  return (
    <>
      <HStack width="100%" className="asset_main_table">
        <Tabs variant="unstyled" width="100%">
          <TabPanels>
            <TabPanel w="98%" px="0" ref={tableRef}>
              <TablePagination2
                user={user}
                data={dataArray}
                columns={columns}
                isSearchable={true}
                title="LIST OF ASSETS"
                hasDelete={true}
                totalRows={totalRowsTable}
                pageSize={assetData.pageSize}
                handleOnPageChange={handleOnPageChange}
                onSort={handleSort}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
                onCreateModalOpen={onCreateModalOpen}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                isTableLoading={isTableLoading}
                setIsTableLoading={setIsTableLoading}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </>
  );
};
