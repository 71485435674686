import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Text,
  GridItem,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Heading,
  Flex,
  Divider,
} from "@chakra-ui/react";

import { InputFieldAverage } from "../../../components/inputField/InputFieldAverage";
import { MainButton } from "../../../components/button/MainButton";
import { MainTitle } from "../../../components/mainTitle/MainTitle";

import rightArrow from "../../../assets/images/icons/rightArrow2.svg";
import percent from "../../../assets/images/icons/filled.svg";

export const CityBenchmarksStep1 = ({
  nextPage,
  prevPage,
  errors,
  touched,
  values,
  onChange,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  onDraftOpen,
  formikValues,
  setDisableSave,
  isRestore,
  disableSave,
  setDismissSave,
  validateStep,
  setValidateStep,
  isModelWarningOpen,
  handleAccordErrors,
  setFieldTouched,
}) => {
  let { type } = useParams();
  const [arrayKeys, setArrayKeys] = useState([]);
  const inputRef = useRef(null);

  let accordTitle = [
    "Rooms",
    "Food & Beverage",
    "Recreation",
    "Spa",
    "Other Profit Departments",
    "Retail",
    "Other 1",
    "Other 2",
  ];
  let accordElTitle = [
    ["Payroll", "Other Expenses"],
    ["Payroll", "Cost of Sales", "Other Expenses"],
    ["Payroll", "Other Expenses"],
    ["Payroll", "Cost of Sales", "Other Expenses"],
    ["Payroll", "Other Expenses"],
    ["Payroll", "Cost of Sales", "Other Expenses"],
    ["Payroll", "Other Expenses"],
    ["Payroll", "Other Expenses"],
  ];
  let arrKeys = [];
  if (formikValues?.departmentalExpenses) {
    arrKeys = Object.keys(formikValues.departmentalExpenses);
    if (JSON.stringify(arrayKeys) !== JSON.stringify(arrKeys)) {
      setArrayKeys(arrKeys);
    }
  }

  let form1Data = [];

  if (formikValues?.departmentalExpenses && arrayKeys.length >= 1) {
    form1Data.push(formikValues.departmentalExpenses.rooms.payroll);
    form1Data.push(formikValues.departmentalExpenses.rooms.other);
    form1Data.push(formikValues.departmentalExpenses.foodAndBeverage.payroll);
    form1Data.push(
      formikValues.departmentalExpenses.foodAndBeverage.costOfSales
    );
    form1Data.push(formikValues.departmentalExpenses.foodAndBeverage.other);
    form1Data.push(formikValues.departmentalExpenses.recreation.payroll);
    form1Data.push(formikValues.departmentalExpenses.recreation.other);
    form1Data.push(formikValues.departmentalExpenses.spa.payroll);
    form1Data.push(formikValues.departmentalExpenses.spa.costOfSales);
    form1Data.push(formikValues.departmentalExpenses.spa.other);
    form1Data.push(
      formikValues.departmentalExpenses.otherProfitDepartments.payroll
    );
    form1Data.push(
      formikValues.departmentalExpenses.otherProfitDepartments.other
    );
    form1Data.push(formikValues.departmentalExpenses.retail.payroll);
    form1Data.push(formikValues.departmentalExpenses.retail.costOfSales);
    form1Data.push(formikValues.departmentalExpenses.retail.other);
    form1Data.push(formikValues.departmentalExpenses.other1.payroll);
    form1Data.push(formikValues.departmentalExpenses.other1.other);
    form1Data.push(formikValues.departmentalExpenses.other2.payroll);
    form1Data.push(formikValues.departmentalExpenses.other2.other);
  }
  let emptyform1Data = [];
  form1Data.forEach((item) => {
    if (
      item.low !== "" &&
      !isNaN(`${item.low}`) &&
      parseFloat(item.low) >= 0 &&
      item.high !== "" &&
      !isNaN(`${item.high}`) &&
      parseFloat(item.high) >= 0 &&
      item.average !== "" &&
      !isNaN(`${item.average}`) &&
      parseFloat(item.average) >= 0 &&
      parseFloat(item.low) <= parseFloat(item.high)
    ) {
      emptyform1Data.push(item);
    }
  });

  let isDisabledForm = false;

  if (emptyform1Data.length !== form1Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  // if (emptyform1Data.length >= 0) {
  //   // the default value is 3
  //   if (disableSave) {
  //     setDisableSave(false);
  //   }
  // }

  let renderAccrodionFields = (mData, pos, name) => {
    let arrKeys2 = [];
    if (formikValues?.departmentalExpenses[arrayKeys[pos]]) {
      arrKeys2 = Object.keys(
        formikValues?.departmentalExpenses[arrayKeys[pos]]
      );
      /* if (JSON.stringify(arrayKeys2) !== JSON.stringify(arrKeys2)) {
        setArrayKeys2(arrKeys2);
      } */
    }

    return (
      <>
        <AccordionItem mb="4" borderBottom="none">
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC" className={name}>
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  {accordTitle[pos]}
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>

          <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
            <>
              {arrKeys2 !== undefined &&
                arrKeys2.map((typeName, index) => {
                  return (
                    <Box key={index}>
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Text
                          fontSize="12px"
                          color="black"
                          fontWeight="400"
                          letterSpacing="0.04em"
                          textAlign="left"
                          textTransform="uppercase"
                          mb={4}
                          mt={3}
                        >
                          {/* {formArr.positioningId} */}
                          {/* {typeName} */}
                          {accordElTitle[pos][index]}
                        </Text>
                      </Box>

                      <GridItem colSpan={2}>
                        <SimpleGrid
                          columns={{ sm: 3, "2xl": 3 }}
                          spacing={{ sm: "25px", "2xl": "40px" }}
                          width={{ sm: "50%", "2xl": "40%" }}
                        >
                          <GridItem
                            w={{
                              sm: "170px",
                              md: "170px",
                              xl: "170px",
                              "2xl": "180px",
                            }}
                          >
                            <InputFieldAverage
                              placeholder="0.00"
                              label="Low"
                              icon={
                                typeName === "costOfSales"
                                  ? percent
                                  : typeName === "other"
                                  ? percent
                                  : ""
                              }
                              inputType="number"
                              inputId={`departmentalExpenses.${arrayKeys[pos]}.${typeName}.low`}
                              inputName={`departmentalExpenses.${arrayKeys[pos]}.${typeName}.low`}
                              errors={errors}
                              touched={touched}
                              isRequired
                              values={
                                formikValues?.departmentalExpenses[
                                  arrayKeys[pos]
                                ]?.[typeName]?.low
                              }
                              labelCenter={true}
                              uppercase={false}
                              onChange={onChange}
                              inputRef={inputRef}
                              directVal={true}
                              disable={isRestore}
                              validateField={validateStep}
                              isThousand={true}
                              setFieldTouched={setFieldTouched}
                            />
                          </GridItem>
                          <GridItem
                            w={{
                              sm: "170px",
                              md: "170px",
                              xl: "170px",
                              "2xl": "180px",
                            }}
                          >
                            <InputFieldAverage
                              placeholder="0.00"
                              label="High"
                              icon={
                                typeName === "costOfSales"
                                  ? percent
                                  : typeName === "other"
                                  ? percent
                                  : ""
                              }
                              inputType="number"
                              inputId={`departmentalExpenses.${arrayKeys[pos]}.${typeName}.high`}
                              inputName={`departmentalExpenses.${arrayKeys[pos]}.${typeName}.high`}
                              errors={errors}
                              touched={touched}
                              isRequired
                              values={
                                formikValues?.departmentalExpenses[
                                  arrayKeys[pos]
                                ]?.[typeName]?.high
                              }
                              labelCenter={true}
                              uppercase={false}
                              onChange={onChange}
                              inputRef={inputRef}
                              directVal={true}
                              disable={isRestore}
                              validateField={validateStep}
                              isThousand={true}
                              setFieldTouched={setFieldTouched}
                            />
                          </GridItem>
                          <GridItem
                            w={{
                              sm: "170px",
                              md: "170px",
                              lg: "170px",
                              "2xl": "180px",
                            }}
                          >
                            <InputFieldAverage
                              placeholder="0.00"
                              label="Average"
                              /* icon={
                                typeName === "costOfSales"
                                  ? percent
                                  : typeName === "other"
                                  ? percent
                                  : ""
                              } */
                              inputType="number"
                              inputId={`departmentalExpenses.${arrayKeys[pos]}.${typeName}.average`}
                              inputName={`departmentalExpenses.${arrayKeys[pos]}.${typeName}.average`}
                              errors={errors}
                              touched={touched}
                              isRequired
                              values={
                                formikValues?.departmentalExpenses[
                                  arrayKeys[pos]
                                ]?.[typeName]?.average
                              }
                              labelCenter={true}
                              uppercase={false}
                              onChange={onChange}
                              inputRef={inputRef}
                              disable={true}
                              avg={true}
                              directVal={true}
                              noBg={true}
                              isThousand={true}
                              validateField={false}
                            />
                          </GridItem>
                        </SimpleGrid>
                        <Box>
                          {parseFloat(
                            formikValues?.departmentalExpenses[
                              arrayKeys[pos]
                            ]?.[typeName]?.low
                          ) >
                            parseFloat(
                              formikValues?.departmentalExpenses[
                                arrayKeys[pos]
                              ]?.[typeName]?.high
                            ) && (
                            <Box
                              color="#e14642"
                              fontWeight="400"
                              fontSize="10px"
                              lineHeight="17px"
                              letterSpacing="0.04em"
                              marginTop="-5px"
                            >
                              Low value should be less than high value
                            </Box>
                          )}
                        </Box>
                      </GridItem>
                    </Box>
                  );
                })}
            </>
          </AccordionPanel>
        </AccordionItem>
      </>
    );
  };

  return (
    <>
      <Box alignItems="flex-start" width="100%" mb={5}>
        <MainTitle title="Departmental expenses" />
      </Box>

      <Accordion
        width="100%"
        allowToggle
        color="black"
        ref={inputRef}
        defaultIndex={[0]}
      >
        {formikValues?.departmentalExpenses?.rooms &&
          renderAccrodionFields(
            formikValues?.departmentalExpenses?.rooms,
            0,
            "rooms"
          )}
        {formikValues?.departmentalExpenses?.foodAndBeverage &&
          renderAccrodionFields(
            formikValues?.departmentalExpenses?.foodAndBeverage,
            1,
            "foodAndBeverage"
          )}
        {formikValues?.departmentalExpenses?.recreation &&
          renderAccrodionFields(
            formikValues?.departmentalExpenses?.recreation,
            2,
            "recreation"
          )}
        {formikValues?.departmentalExpenses?.spa &&
          renderAccrodionFields(
            formikValues?.departmentalExpenses?.spa,
            3,
            "spa"
          )}
        {formikValues?.departmentalExpenses?.otherProfitDepartments &&
          renderAccrodionFields(
            formikValues?.departmentalExpenses?.otherProfitDepartments,
            4,
            "otherProfitDepartments"
          )}
        {formikValues?.departmentalExpenses?.retail &&
          renderAccrodionFields(
            formikValues?.departmentalExpenses?.retail,
            5,
            "retail"
          )}
        {formikValues?.departmentalExpenses?.other1 &&
          renderAccrodionFields(
            formikValues?.departmentalExpenses?.other1,
            6,
            "other1"
          )}
        {formikValues?.departmentalExpenses?.other2 &&
          renderAccrodionFields(
            formikValues?.departmentalExpenses?.other2,
            7,
            "other2"
          )}
      </Accordion>
      <Divider orientation="horizontal" mt="30px" mb="30px" />

      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          btnStyle="transparent"
          fontColor="dark"
          disabledBtn={isRestore}
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/CityBenchmarksHistory/${type}`}
            />
          )}

          {isDisabledForm ? (
            <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              onPress={() => {
                handleAccordErrors(errors);
                setValidateStep(true);
                isModelWarningOpen(true);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
          ) : (
            <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              onPress={() => {
                nextPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
          )}
          {/* <MainButton
            title="Next"
            reverseRow={true}
            marginLeft="2"
            icon={rightArrow}
            disabledBtn={isDisabledForm}
            onPress={() => {
              nextPage();
              document.getElementById("main_content").scrollTo(0, 0);
            }}
          /> */}
        </Box>
      </Flex>
    </>
  );
};
