export const SET_MY_ASSETS = 'setMyAssets';
export const SET_COMPSET = 'setCompset';
export const FETCH_DATA_FAILURE = 'fetchAssetFailure';
export const SET_REGION_DROPDOWN_ITEMS = 'SET_REGION_DROPDOWN_ITEMS';
export const SET_POSITION_DROPDOWN_ITEMS = 'SET_POSITION_DROPDOWN_ITEMS';
export const SET_MY_ASSETS_SELECTED = 'SET_MY_ASSETS_SELECTED';
export const SET_COMPSET_ASSETS_SELECTED = 'SET_COMPSET_ASSETS_SELECTED';
export const SET_MY_ASSETS_TABLE =  'SET_MY_ASSETS_TABLE';
export const SET_COMPSET_TABLE = 'SET_COMPSET_TABLE '
export const SET_AGGREGATION = 'SET_AGGREGATION';
export const SET_APPROVAL_LIST = 'SET_APPROVAL_LIST';
export const SET_ASSET_MOVE = 'SET_ASSET_MOVE';
export const SET_UNAPPROVAL_LIST = 'SET_UNAPPROVAL_LIST';
export const UPDATE_KEYS = 'UPDATE_KEYS';
