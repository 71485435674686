// calculationsReducer.js
import { SET_MY_ASSETS_TABLE, SET_COMPSET_TABLE, FETCH_DATA_FAILURE, SET_AGGREGATION} from '../actions/actionTypes';
import { calculateTableValues, calculateAggregate } from './calculations';
const initialState = {
  compset: [],
  myAssets: [],
  myAssetsKey: [],
  compsetCalcsKey: [],
  myAssetsAggregate: {},
  compsetAggregate: {},
  isAggregation: false,
  compsetAggregateIrr: null,
  myAssetsAggregateIrr: null,
  error: null,
  loading:true
};

const calculationsReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_COMPSET_TABLE:
      for (const [key, value] of Object.entries(action.payload.compset)) {

        value['calculationValues'] = calculateTableValues(value);
      }
      return {
        ...state,
        compset: action.payload.compset,
        compsetCalcsKey: Object.keys(action.payload.compset),
        compsetAggregate: calculateAggregate(action.payload.compset, Object.keys(action.payload.compset),action.payload.compsetAggregateIrr),
        loading: false
      };
    case SET_MY_ASSETS_TABLE:
      for (const [key, value] of Object.entries(action.payload.myAssets, 'myAssets')) {
        value['calculationValues'] = calculateTableValues(value);
      }

      return {
        ...state,
        myAssets: action.payload.myAssets,
        myAssetsKey: Object.keys(action.payload.myAssets),
        myAssetsAggregate: calculateAggregate(action.payload.myAssets, Object.keys(action.payload.myAssets), action.payload.myAssetsAggregateIrr),
        loading: false
      };

    case SET_AGGREGATION:
      return {
        ...state,
        isAggregation: action.payload
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        error: action.payload
      };


    default:
      return state;
  }
};

export default calculationsReducer;
