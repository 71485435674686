// store.js
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducers/reducers'; // Import your root reducer

export const store = configureStore({
  reducer: rootReducer
 
});



