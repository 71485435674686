import * as Yup from "yup";

export const validationVisAssumptionsForm = [
  Yup.object().shape({
    visitorNumbers: Yup.array().of(
      Yup.object().shape({
        regionId: Yup.string().required("id is required"),

        numbers: Yup.array().of(
          Yup.number().min(0, "Required").required("Required")
        ),
        averageNumbers: Yup.array().of(
          Yup.number().min(0, "Required").required("Required")
        ),
        guestsNumbers: Yup.array().of(
          Yup.number().min(0, "Required").required("Required")
        ),

        note: Yup.string(),
      })
    ),
  }),
];
