import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as xlsx from "xlsx";

import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Box,
  Input,
  VStack,
  Spinner,
} from "@chakra-ui/react";

import { MainButton } from "../button/MainButton";

import upload from "../../assets/images/icons/upload.svg";

export const BenchMarkModal = ({
  isOpen,
  onClose,
  title,
  desc,
  icon,
  hasDesc,
  confirmationModal = false,
  path,
  btnWidthFull,
  disableClose = false,
}) => {
  let modalProps;
  let hideCloseBtn = !disableClose || !confirmationModal ? false : true;

  if (!disableClose) {
    modalProps = {
      onClose: onClose,
    };
  }

  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadError, setIsUploadError] = useState(false);
  const navigate = useNavigate();

  const parseUploadedExcel = useCallback((excelData) => {
    function camelCase(str) {
      return str
        .replace(/\s(.)/g, function (a) {
          return a.toUpperCase();
        })
        .replace(/\s/g, "")
        .replace(/^(.)/, function (b) {
          return b.toLowerCase();
        });
    }

    const excelRows = xlsx.utils.sheet_to_json(excelData.Sheets["Sheet1"]);
    const benchmarkRows = {};
    if (!excelRows.length) {
      throw new Error();
    }
    excelRows.forEach((row) => {
      const expenseType = camelCase(row["Expense"]);
      const positioning = camelCase(row["Positioning"]);
      const type = camelCase(row["Type"]);
      if (!expenseType || !positioning || !type) {
        throw new Error();
      }
      if (!benchmarkRows[expenseType]) {
        benchmarkRows[expenseType] = {};
      }
      if (!benchmarkRows[expenseType][positioning]) {
        benchmarkRows[expenseType][positioning] = {};
      }
      benchmarkRows[expenseType][positioning][type] = {
        low: row["Low"] || 0,
        high: row["High"] || 0,
        average: row["Average"] || 0,
      };
    });
    localStorage.setItem(
      "parsedExcel-Benchmarks",
      JSON.stringify(benchmarkRows)
    );
    onClose();
    navigate(path);
  }, []);

  useEffect(() => {
    setFileName("");
    setIsUploadError(false);
  }, [isOpen]);

  const renderButtons = (confirmModal) => {
    return (
      <>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Text
            alignSelf="flex-start"
            fontSize="12px"
            pb="2"
            px="1px !important"
          >
            UPLOAD FILE
          </Text>

          <Box className="dashed_outline" width="100%">
            <VStack minW="100%" w="100%" gap="1">
              {!isLoading ? (
                <>
                  <Input
                    cursor="pointer"
                    type="file"
                    w="100%"
                    px="20"
                    py="20"
                    opacity="0"
                    zIndex={10}
                    accept=".xlsx"
                    onChange={async (e) => {
                      const file = e.target.files[0];
                      const acceptedMimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                      const isFileExtensionValid = (file.name || '').toLowerCase().endsWith('.xlsx');
                      const isFileMimeTypeValid = (file.type || '').toLowerCase() === acceptedMimeType;

                      if (!isFileExtensionValid || !isFileMimeTypeValid) {
                        setFileName("");
                        setIsLoading(false);
                        setIsUploadError(true);
                      } else {
                        const data = await file.arrayBuffer();
                        try {
                          setIsLoading(true);
                          const excelData = xlsx.read(data);
                          parseUploadedExcel(excelData);
                          setFileName(e.target.files[0].name);
                          setIsUploadError(false);
                        } catch {
                          setFileName("");
                          setIsLoading(false);
                          setIsUploadError(true);
                        }
                      }
                    }}
                  ></Input>

                  <Box position="absolute" mt="12 !important" zIndex={1}>
                    <MainButton
                      marginRight="2"
                      fSize="md"
                      title="Browse"
                      icon={upload}
                    />
                  </Box>

                  {fileName.length > 0 && (
                    <Text
                      position="absolute"
                      color="#6F767E"
                      mt="27 !important"
                      fontSize="12"
                    >
                      {fileName}
                    </Text>
                  )}

                  <Text
                    zIndex={1}
                    mt="28 !important"
                    position="absolute"
                    color="#6F767E"
                    fontSize="15"
                  >
                    or drag file here
                  </Text>
                </>
              ) : (
                <Box width="100%" paddingY={10}>
                  <Center>
                    <Spinner />
                  </Center>
                </Box>
              )}
            </VStack>
          </Box>

          <Text
            pt="2"
            pb="3"
            color="#6F767E"
            fontSize="12px"
            px="1px !important"
          >
            By uploading the file, all the entered data will be populated in the
            form with the ability to review and edit.
          </Text>

          {isUploadError ? <Text
              pt="2"
              pb="3"
              color="#E0232E"
              fontSize="12px"
              px="1px !important"
          >
            The uploaded file could not be processed. Please ensure you have selected a filled out template.
          </Text> : null}

          <Flex w="100%" justifyContent="center" mt={1}>
            <MainButton
              title="Create manually"
              fontColor="dark"
              btnStyle="transparent"
              btnWidthFull={true}
              navLink="true"
              path={path}
            />
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <div className={disableClose ? "modal_disableClose" : ""}>
      <Modal isOpen={isOpen} {...modalProps}>
        <ModalOverlay
          background="rgba(29, 31, 34, 0.5)"
          backdropFilter="blur(7px)"
        />
        <ModalContent py={12} px={4} alignSelf="center" maxWidth="700px">
          <Center flexDirection="column">
            {icon && (
              <img src={icon} width="72px" height="72px" alt="checked icon" />
            )}
            <ModalHeader
              textAlign="center"
              color="#1D1F22"
              fontSize="20px"
              fontWeight="400"
              letterSpacing="-0.02em"
              px={5}
            >
              {title}
            </ModalHeader>
          </Center>

          {/*  {hideCloseBtn && <ModalCloseButton />} */}
          <ModalCloseButton />

          {hasDesc && (
            <ModalBody textAlign="center">
              <Center>
                <Text fontSize="15px">{desc}</Text>
              </Center>
            </ModalBody>
          )}

          <ModalFooter>{renderButtons(confirmationModal)}</ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
