import React from "react";
import { Box, HStack, Spacer, Text } from "@chakra-ui/react";

import { NavLink } from "react-router-dom";

export const LinkBox = ({
  borderWidth = "3",
  maxWidth = "100%",
  title,
  icon,
  path,
}) => {
  const bWidth = borderWidth === "3" ? 3 : 5;
  const titleCapelatize =
    `${title}`.charAt(0).toUpperCase() + `${title}`.slice(1).toLowerCase();

  return (
    <div>
      <NavLink to={path}>
        <Box
          bg="white"
          py={5}
          px={25}
          mb={4}
          mt={3}
          mx={0}
          maxW={maxWidth}
          color="black"
          w="100%"
          borderLeftWidth={bWidth}
          borderLeftColor="#EBC03F"
          borderLeftStyle="solid"
          borderRadius={5}
          className="link_box"
        >
          <HStack>
            <Text className="title_sm" textTransform="capitalize">
              {titleCapelatize}
            </Text>
            <Spacer />
            <img src={icon} />
          </HStack>
        </Box>
      </NavLink>
    </div>
  );
};
