import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  NumberInput,
  NumberInputField,
  Checkbox,
  Switch,
  Flex,
  Box,
  useToast,
} from "@chakra-ui/react";
import {
  GetProductionMasterDataWithIsActive,
  GetProductionOperatorWithIsActive,
  GetProductionBrand,
  GetProductionPositioningWithIsActive,
} from "../../api/MasterDataAPI";
import {
  saveUnapprovedAssets,
  setErrorMessage,
  getApprovedAndUnapprovedAssets
} from "../../redux/actions/approval";
import { ToastAlert } from "../../components/toastAlert/ToastAlert";

import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import calendar from "../../assets/images/icons/calendar.svg";
// import "react-datepicker/dist/react-datepicker.css";
import styles from "../../components/forms.module.scss";
// class="forms_form_control_input__NUVCZ datepicker_year_month"
import errIcon from "../../assets/images/icons/errsm.svg";
import checked from "../../assets/images/icons/checked.svg";

export const EditDataModal = ({ isOpen, onClose, data, isNew = true }) => {
  const toast = useToast();
  const closeToast = () => {
    toast.closeAll();
  };
  const [formData, setFormData] = useState({
    region: "",
    regionLookup: "",
    assetName: "",
    positioning: "",
    positioningLookup: "",
    projectNameValue: "",
    keys: "",
    operator: "",
    operatorLookup: "",
    brand: "",
    brandLookup: "",
    constructionStartYear: new Date().getFullYear(), // any year
    openingYear: new Date().getFullYear(), //Todo > constructionStartYear
    service: "",
    includeBrandedResidence: false,
    numberOfBrandedResidences: "", //Todo if includeBrandedResidence == false then it's not requierd
    staffPerKey: "",
    comments: "",
  });
  const [region, setRegion] = useState([]);
  const [position, setPosition] = useState([]);
  const [brand, setBrand] = useState([]);
  const [operator, setOperator] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [isError, setIsError] = useState({
    assetName: false,
    regionLookup: false,
    positioningLookup: false,
    brandLookup: false,
    operatorLookup: false,
    service: false,
    includeBrandedResidence: false,
    numberOfBrandedResidences: false,
    keys: false,
    constructionStartYear: false,
    openingYear: false,
    staffPerKey: false,
  });
  const service = [
    { label: "Standard", value: "Standard" },
    { label: "Package", value: "Package" },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "assetName" && isNew) {
      let projectNameValue = value
        .toLowerCase()
        .replace(/[^ a-zA-Z0-9]/g, "")
        .replace(/ /g, "_")
        .concat(uuidv4());
      setFormData((prev) => ({
        ...prev,
        projectNameValue: projectNameValue,
      }));
    }

    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = {};
    const requiredFields = [
      "assetName",
      "regionLookup",
      "positioningLookup",
      "brandLookup",
      "operatorLookup",
      "service",
      "keys",
      "staffPerKey",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = true;
      }
    });

    if (
      formData.includeBrandedResidence &&
      !formData.numberOfBrandedResidences
    ) {
      newErrors.numberOfBrandedResidences = true;
    }

    setIsError(newErrors);
    console.log("newErrors", newErrors);

    if (
      Object.keys(newErrors).length === 0 &&
      isValidYearRange(formData.openingYear, formData.constructionStartYear)
    ) {
      dispatch(saveUnapprovedAssets(formData))
      .then((response) => {
        toast({
          position: "top",
          render: () => (
            <ToastAlert
              title="Asset has been saved successfully"
              icon={checked}
              closeToast={closeToast}
            />
          ),
        });
        onClose();
        console.log('Asset saved successfully', response);
      })
      .catch((error) => {
        // handle error
        toast({
          render: () => (
            <ToastAlert title="Something went wrong" icon={errIcon} closeToast={closeToast} type="Error" />
          ),
        });
        dispatch(setErrorMessage(null));
        // onClose();
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const regionResponse = await GetProductionMasterDataWithIsActive();
        setRegion(
          formatLookup(regionResponse.data[0].lookups, "region", "regionLookup")
        );

        const operatorResponse = await GetProductionOperatorWithIsActive();
        setOperator(
          formatLookup(
            operatorResponse.data[0].lookups,
            "operator",
            "operatorLookup"
          )
        );

        const brandResponse = await GetProductionBrand();
        setBrand(
          formatLookup(brandResponse.data[0].lookups, "brand", "brandLookup")
        );

        const positionResponse = await GetProductionPositioningWithIsActive();
        setPosition(
          formatLookup(
            positionResponse.data[0].lookups,
            "positioning",
            "positioningLookup"
          )
        );
      } catch (error) {
        setError("Failed to fetch data: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!isNew) {
      setFormData({
        ...data,
        keys: Number(data.keys),
        constructionStartYear: data.constructionStartYear
          ? new Date(data.constructionStartYear, 0).getFullYear()
          : new Date().getFullYear(), // Assuming constructionStartYear is just the year as a number
        openingYear: data.openingYear
          ? new Date(data.openingYear, 0).getFullYear()
          : new Date().getFullYear(),
        numberOfBrandedResidences: Number(data.numberOfBrandedResidences),
        staffPerKey: Number(data.staffPerKey),
      });
    }
  }, [data, isNew]);

  const formatLookup = (lookups, labelKey, valueKey) => {
    return lookups.map((item) => ({
      label: item.label,
      value: item.value,
    }));
  };

  const handleSelectChange = (selectedOption, field1, field2) => {
    if (field1 === "service")
      setFormData((prev) => ({
        ...prev,
        [field1]: selectedOption.value,
      }));
    else
      setFormData((prev) => ({
        ...prev,
        [field1]: selectedOption.value,
        [field2]: selectedOption.label,
      }));
  };

  useEffect(() => {
    isValidYearRange(formData.openingYear, formData.constructionStartYear);
  }, [formData.openingYear, formData.constructionStartYear]);

  const InputClassname = `${styles.form_control}`;
  let formInputClassname = `${styles.form_control_input} `;
  formInputClassname += `datepicker_year`; //: `datepicker_year_month`;
  const isValidYearRange = (openingYear, constructionStartYear) => {
    if (openingYear < constructionStartYear) {
      console.log("Opening Year must be greater than Construction Start Year.");
      setValidationError(
        "Opening Year must be greater than Construction Start Year."
      );
      return false;
    }
    setValidationError("");
    return true;
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {" "}
          {isNew ? "Add" : `Edit ${formData.assetName}`}{" "}
        </ModalHeader>
        <ModalCloseButton />
        {/* <form > */}
        <ModalBody>
          <FormControl isRequired isInvalid={isError.assetName}>
            <FormLabel>Asset Name</FormLabel>
            <Input
              name="assetName"
              value={formData.assetName}
              onChange={handleChange}
            />
            <FormErrorMessage>Please enter the asset name.</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={isError.regionLookup}>
            <FormLabel>Region</FormLabel>
            <Select
              name="region"
              value={region.find(
                (option) => option.value === formData.regionLookup
              )}
              onChange={(option) =>
                handleSelectChange(option, "regionLookup", "region")
              }
              placeholder="Regions ..."
              options={region}
            />
            <FormErrorMessage>Please select a region.</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={isError.positioningLookup}>
            <FormLabel>Positioning</FormLabel>
            <Select
              name="positioning"
              zIndex={1000}
              onChange={(option) =>
                handleSelectChange(option, "positioningLookup", "positioning")
              }
              value={position.find(
                (option) => option.value === formData.positioningLookup
              )}
              placeholder="Positioning ..."
              options={position}
            />
            <FormErrorMessage>Please select a positioning.</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={isError.brandLookup}>
            <FormLabel>Brand</FormLabel>
            <Select
              name="brand"
              zIndex={1000}
              onChange={(option) =>
                handleSelectChange(option, "brandLookup", "brand")
              }
              value={brand.find(
                (option) => option.value === formData.brandLookup
              )}
              placeholder="Brand ..."
              options={brand}
            />
            <FormErrorMessage>Please select a brand.</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={isError.operatorLookup}>
            <FormLabel>Operator</FormLabel>
            <Select
              name="operator"
              zIndex={1000}
              onChange={(option) =>
                handleSelectChange(option, "operatorLookup", "operator")
              }
              value={operator.find(
                (option) => option.value === formData.operatorLookup
              )}
              placeholder="Operator ..."
              options={operator}
            />
            <FormErrorMessage>Please select an operator.</FormErrorMessage>
          </FormControl>
          <Flex gap="25px">
            <FormControl width="50%" isRequired isInvalid={isError.service}>
              <FormLabel>Service</FormLabel>
              <Select
                name="service"
                zIndex={1000}
                value={service.find(
                  (option) => option.value === formData.service
                )}
                onChange={(option) =>
                  handleSelectChange(option, "service", "service")
                }
                placeholder="Service ..."
                options={service}
              />
              <FormErrorMessage>Please select a service.</FormErrorMessage>
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="includeBrandedResidence" mb="0">
                Include Branded Residence
              </FormLabel>
              <Switch
                id="includeBrandedResidence"
                name="includeBrandedResidence"
                isChecked={formData.includeBrandedResidence}
                onChange={handleChange}
                colorScheme={formData.includeBrandedResidence ? "green" : "red"}
              />
            </FormControl>
          </Flex>
          <Flex gap="2px">
            <FormControl isRequired isInvalid={isError.keys}>
              <FormLabel>Keys</FormLabel>
              <NumberInput
                name="keys"
                value={formData.keys}
                onChange={(valueString) =>
                  setFormData((prev) => ({
                    ...prev,
                    keys: valueString,
                  }))
                }
                min={1}
              >
                <NumberInputField />
              </NumberInput>
              <FormErrorMessage>
                Please enter the number of keys.
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={isError.numberOfBrandedResidences}
            >
              <FormLabel>Number of Branded Residences</FormLabel>
              <NumberInput
                isDisabled={!formData.includeBrandedResidence}
                value={formData.numberOfBrandedResidences}
                onChange={(valueString) =>
                  setFormData((prev) => ({
                    ...prev,
                    numberOfBrandedResidences: valueString,
                  }))
                }
              >
                <NumberInputField name="numberOfBrandedResidences" />
              </NumberInput>
              <FormErrorMessage>
                Please enter the number of branded residences.
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex gap="2px">
            <FormControl isRequired isInvalid={validationError !== ""}>
              <FormLabel>Construction Start Year</FormLabel>
              <Box className={InputClassname}>
                <Box className={formInputClassname}>
                  <DatePicker
                    selected={new Date(formData.constructionStartYear, 0)} // Convert year to Date object
                    onChange={(date) => {
                      const year = date.getFullYear();
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        constructionStartYear: year,
                      }));
                    }}
                    dateFormat="yyyy"
                    // minDate={minDate}
                    // maxDate={maxDate}
                    showYearPicker
                  />
                  <Box className="iconic_input">
                    <img src={calendar} alt="date" />
                  </Box>
                </Box>
              </Box>
            </FormControl>
            <FormControl isRequired isInvalid={validationError !== ""}>
              <FormLabel>Opening Year</FormLabel>
              <Box className={InputClassname}>
                <Box className={formInputClassname}>
                  <DatePicker
                    selected={new Date(formData.openingYear, 0)} // Convert year to Date object
                    onChange={(date) => {
                      const year = date.getFullYear();
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        openingYear: year,
                      }));
                    }}
                    dateFormat="yyyy"
                    // minDate={minDate}
                    // maxDate={maxDate}
                    showYearPicker
                  />
                  <Box className="iconic_input">
                    <img src={calendar} alt="date" />
                  </Box>
                </Box>
              </Box>
              <FormErrorMessage>{validationError}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex gap="2px"></Flex>
          <Flex gap="2px"></Flex>

          <Flex gap="2px">
            <FormControl isRequired isInvalid={isError.staffPerKey}>
              <FormLabel>Staff Per Key</FormLabel>
              <NumberInput
                value={formData.staffPerKey}
                min={0}
                onChange={(valueString) =>
                  setFormData((prev) => ({
                    ...prev,
                    staffPerKey: valueString,
                  }))
                }
              >
                <NumberInputField name="staffPerKey" />
              </NumberInput>
              <FormErrorMessage>
                Please enter the staff per key.
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Comments</FormLabel>
              <Input
                name="comments"
                value={formData.comments}
                onChange={handleChange}
              />
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleSubmit}
            colorScheme="blue"
            mr={3}
            type="submit"
          >
            Save Changes
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
        {/* </form> */}
      </ModalContent>
    </Modal>
  );
};

export default EditDataModal;
