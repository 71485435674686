import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Text,
  GridItem,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Heading,
  Flex,
  Divider,
} from "@chakra-ui/react";

import { InputFieldAverage } from "../../../components/inputField/InputFieldAverage";
import { MainButton } from "../../../components/button/MainButton";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { TextArea } from "../../../components/textArea/TextArea";

import leftArrow from "../../../assets/images/icons/Arrow-Left.svg";
import percent from "../../../assets/images/icons/filled.svg";

export const ServicedApartmentsStep2 = ({
  nextPage,
  prevPage,
  errors,
  touched,
  values,
  onChange,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  onDraftOpen,
  formikValues,
  dropdownObj,
  onSubmitOpen,
  setDisableSave,
  disableSave,
  setDismissSave,
  servicedApart,
  handleOnSubmit,
  dirty,
  isRestore,
  validateStep,
  setValidateStep,
  isModelWarningOpen,
  handleAccordErrors,
}) => {
  let { type } = useParams();
  const [arrayKeys, setArrayKeys] = useState([]);
  const inputRef = useRef(null);

  let accordTitle = [
    "Admin and General",
    "Information & Telecom Systems",
    "Sales & Marketing",
    "Property Operations & Maintenance",
    "Utilities",
    "Fixed Charges",
  ];
  let accordElTitle = [
    ["Payroll", "Other Expenses"],
    ["Payroll", "Other Expenses"],
    ["Payroll", "Other Expenses"],
    ["Payroll", "Other Expenses"],
    ["Other Expenses (Per Key)"],
    ["Income", "Operational Insurance", "Property & Other Taxes"],
  ];
  let arrKeys = [];
  if (formikValues?.undistributedExpenses) {
    arrKeys = Object.keys(formikValues.undistributedExpenses);
    if (JSON.stringify(arrayKeys) !== JSON.stringify(arrKeys)) {
      setArrayKeys(arrKeys);
    }
  }

  let form2Data = [];

  if (formikValues?.undistributedExpenses && arrayKeys.length >= 1) {
    form2Data.push(formikValues.undistributedExpenses.adminGeneral.payroll);
    form2Data.push(formikValues.undistributedExpenses.adminGeneral.other);
    form2Data.push(
      formikValues.undistributedExpenses.informationTelecom.payroll
    );
    form2Data.push(formikValues.undistributedExpenses.informationTelecom.other);
    form2Data.push(formikValues.undistributedExpenses.salesMarketing.payroll);
    form2Data.push(formikValues.undistributedExpenses.salesMarketing.other);
    form2Data.push(
      formikValues.undistributedExpenses.propertyOperations.payroll
    );
    form2Data.push(formikValues.undistributedExpenses.propertyOperations.other);
    form2Data.push(formikValues.undistributedExpenses.utilities.otherPerKey);
    form2Data.push(formikValues.undistributedExpenses.fixedCharges.income);
    form2Data.push(
      formikValues.undistributedExpenses.fixedCharges.operationalInsurance
    );
    form2Data.push(
      formikValues.undistributedExpenses.fixedCharges.propertyAndOtherTaxes
    );
  }
  let emptyform2Data = [];
  form2Data.forEach((item) => {
    if (
      item.low !== "" &&
      !isNaN(`${item.low}`) &&
      parseFloat(item.low) >= 0 &&
      item.high !== "" &&
      !isNaN(`${item.high}`) &&
      parseFloat(item.high) >= 0 &&
      item.average !== "" &&
      !isNaN(`${item.average}`) &&
      parseFloat(item.average) >= 0 &&
      parseFloat(item.low) <= parseFloat(item.high)
    ) {
      emptyform2Data.push(item);
    }
  });

  /* let isDisabledForm = false;

  if (emptyform2Data.length !== form2Data.length) {
    isDisabledForm = true;
  } */
  let isDisabledForm = false;
  let isDisabledFormProd = false;
  let status = servicedApart?.status === "Production";

  if (servicedApart?.status === "Production") {
    if (emptyform2Data.length !== form2Data.length) {
      isDisabledForm = true;
    }
    isDisabledFormProd =
      isDisabledForm === false && dirty === true ? false : true;
    if (validateStep && !isDisabledFormProd) {
      setValidateStep(false);
    }
  } else {
    if (emptyform2Data.length !== form2Data.length) {
      isDisabledForm = true;
    }
    if (validateStep && !isDisabledForm) {
      setValidateStep(false);
    }
  }

  // if (emptyform2Data.length >= 0) {
  //   // the default value is 3
  //   if (disableSave) {
  //     setDisableSave(false);
  //   }
  // }

  let renderAccrodionFields = (mData, pos, name) => {
    let arrKeys2 = [];
    if (formikValues?.undistributedExpenses[arrayKeys[pos]]) {
      arrKeys2 = Object.keys(
        formikValues?.undistributedExpenses[arrayKeys[pos]]
      );
      /* if (JSON.stringify(arrayKeys2) !== JSON.stringify(arrKeys2)) {
        setArrayKeys2(arrKeys2);
      } */
    }

    return (
      <>
        <AccordionItem mb="4" borderBottom="none">
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC" className={name}>
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  {accordTitle[pos]}
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>

          <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
            <>
              {arrKeys2 !== undefined &&
                arrKeys2.map((typeName, index) => {
                  return (
                    <Box>
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Text
                          fontSize="12px"
                          color="black"
                          fontWeight="400"
                          letterSpacing="0.04em"
                          textAlign="left"
                          textTransform="uppercase"
                          mb={4}
                          mt={3}
                        >
                          {accordElTitle[pos][index]}
                        </Text>
                      </Box>

                      <GridItem colSpan={2}>
                        <SimpleGrid
                          columns={{ sm: 3, "2xl": 3 }}
                          spacing={{ sm: "25px", "2xl": "40px" }}
                          width={{ sm: "50%", "2xl": "40%" }}
                        >
                          <GridItem
                            w={{
                              sm: "170px",
                              md: "170px",
                              xl: "170px",
                              "2xl": "180px",
                            }}
                          >
                            <InputFieldAverage
                              placeholder="0.00"
                              label="Low"
                              icon={
                                typeName === "costOfSales"
                                  ? percent
                                  : typeName === "other"
                                  ? percent
                                  : ""
                              }
                              inputType="number"
                              inputId={`undistributedExpenses.${arrayKeys[pos]}.${typeName}.low`}
                              inputName={`undistributedExpenses.${arrayKeys[pos]}.${typeName}.low`}
                              errors={errors}
                              touched={touched}
                              isRequired
                              values={
                                formikValues?.undistributedExpenses[
                                  arrayKeys[pos]
                                ]?.[typeName]?.low
                              }
                              labelCenter={true}
                              uppercase={false}
                              onChange={onChange}
                              inputRef={inputRef}
                              directVal={true}
                              disable={isRestore}
                              validateField={validateStep}
                            />
                          </GridItem>
                          <GridItem
                            w={{
                              sm: "170px",
                              md: "170px",
                              xl: "170px",
                              "2xl": "180px",
                            }}
                          >
                            <InputFieldAverage
                              placeholder="0.00"
                              label="High"
                              icon={
                                typeName === "costOfSales"
                                  ? percent
                                  : typeName === "other"
                                  ? percent
                                  : ""
                              }
                              inputType="number"
                              inputId={`undistributedExpenses.${arrayKeys[pos]}.${typeName}.high`}
                              inputName={`undistributedExpenses.${arrayKeys[pos]}.${typeName}.high`}
                              errors={errors}
                              touched={touched}
                              isRequired
                              values={
                                formikValues?.undistributedExpenses[
                                  arrayKeys[pos]
                                ]?.[typeName]?.high
                              }
                              labelCenter={true}
                              uppercase={false}
                              onChange={onChange}
                              inputRef={inputRef}
                              directVal={true}
                              disable={isRestore}
                              validateField={validateStep}
                            />
                          </GridItem>
                          <GridItem
                            w={{
                              sm: "170px",
                              md: "170px",
                              lg: "170px",
                              "2xl": "180px",
                            }}
                          >
                            <InputFieldAverage
                              placeholder="0.00"
                              label="Average"
                              /* icon={
                                typeName === "costOfSales"
                                  ? percent
                                  : typeName === "other"
                                  ? percent
                                  : ""
                              } */
                              inputType="number"
                              inputId={`undistributedExpenses.${arrayKeys[pos]}.${typeName}.average`}
                              inputName={`undistributedExpenses.${arrayKeys[pos]}.${typeName}.average`}
                              errors={errors}
                              touched={touched}
                              isRequired
                              values={
                                formikValues?.undistributedExpenses[
                                  arrayKeys[pos]
                                ]?.[typeName]?.average
                              }
                              labelCenter={true}
                              uppercase={false}
                              //
                              onChange={onChange}
                              inputRef={inputRef}
                              disable={true}
                              avg={true}
                              directVal={true}
                              noBg={true}
                              validateField={false}
                            />
                          </GridItem>
                        </SimpleGrid>
                        <Box>
                          {parseFloat(
                            formikValues?.undistributedExpenses[
                              arrayKeys[pos]
                            ]?.[typeName]?.low
                          ) >
                            parseFloat(
                              formikValues?.undistributedExpenses[
                                arrayKeys[pos]
                              ]?.[typeName]?.high
                            ) && (
                            <Box
                              color="#e14642"
                              fontWeight="400"
                              fontSize="10px"
                              lineHeight="17px"
                              letterSpacing="0.04em"
                              marginTop="-5px"
                            >
                              Low value should be less than high value
                            </Box>
                          )}
                        </Box>
                      </GridItem>
                    </Box>
                  );
                })}
            </>
          </AccordionPanel>
        </AccordionItem>
      </>
    );
  };

  return (
    <>
      <Box alignItems="flex-start" width="100%" mb={5}>
        <MainTitle title="Undistributed Expenses" />
      </Box>

      <Accordion
        width="100%"
        allowToggle
        color="black"
        ref={inputRef}
        defaultIndex={[0]}
      >
        {formikValues?.undistributedExpenses?.adminGeneral &&
          renderAccrodionFields(
            formikValues?.undistributedExpenses?.adminGeneral,
            0,
            "adminGeneral"
          )}
        {formikValues?.undistributedExpenses?.informationTelecom &&
          renderAccrodionFields(
            formikValues?.undistributedExpenses?.informationTelecom,
            1,
            "informationTelecom"
          )}
        {formikValues?.undistributedExpenses?.salesMarketing &&
          renderAccrodionFields(
            formikValues?.undistributedExpenses?.salesMarketing,
            2,
            "salesMarketing"
          )}
        {formikValues?.undistributedExpenses?.propertyOperations &&
          renderAccrodionFields(
            formikValues?.undistributedExpenses?.propertyOperations,
            3,
            "propertyOperations"
          )}
        {formikValues?.undistributedExpenses?.utilities &&
          renderAccrodionFields(
            formikValues?.undistributedExpenses?.utilities,
            4,
            "utilities"
          )}
        {formikValues?.undistributedExpenses?.fixedCharges &&
          renderAccrodionFields(
            formikValues?.undistributedExpenses?.fixedCharges,
            5,
            "fixedCharges"
          )}
      </Accordion>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Box
        maxW={["100%", "40%", "40%"]}
        width={["100%", "40%", "40%"]}
        alignSelf="flex-start"
        mb={5}
      >
        <TextArea
          label="ADD COMMENT"
          inputId="comments"
          inputName="comments"
          placeholder="Add description"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
          // isDisabled={isRestore}
        />
      </Box>

      <Divider orientation="horizontal" mt="30px" mb="30px" />

      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          disabledBtn={isRestore}
          btnStyle="transparent"
          fontColor="dark"
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/ServicedApartmentsHistory/${type}`}
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />

            {status ? (
              isDisabledFormProd ? (
                <MainButton
                  title={isRestore ? "Restore" : "Submit"}
                  disabledBtn={!dirty}
                  onPress={() => {
                    handleAccordErrors(errors);
                    setValidateStep(true);
                    isModelWarningOpen(true);
                    document.getElementById("main_content").scrollTo(0, 0);
                  }}
                />
              ) : (
                <MainButton
                  title={isRestore ? "Restore" : "Submit"}
                  onPress={() => {
                    handleOnSubmit(formikValues, isRestore);
                  }}
                />
              )
            ) : isDisabledForm ? (
              <MainButton
                title={isRestore ? "Restore" : "Submit"}
                onPress={() => {
                  handleAccordErrors(errors);
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title={isRestore ? "Restore" : "Submit"}
                onPress={() => {
                  handleOnSubmit(formikValues, isRestore);
                }}
              />
            )}
            {/*  <MainButton
              title={isRestore ? "Restore" : "Submit"}
              classname="btn_primary "
              disabledBtn={status ? isDisabledFormProd : isDisabledForm}
              onPress={() => {
                handleOnSubmit(formikValues);
              }}
            /> */}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
