import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  useRef,
} from "react";

import {
  GetMasterDataRegionAPI,
  GetMasterDataRegionCommentsAPI,
  DeleteMasterDataRegionDraftAPI,
  GetMasterDataAssetCategoryAPI,
  GetMasterDataAssetCategoryCommentsAPI,
  DeleteMasterDataAssetCategoryDraftAPI,
  GetMasterDataRoomCategoryAPI,
  GetMasterDataRoomCategoryCommentsAPI,
  DeleteMasterDataRoomCategoryDraftAPI,
  GetMasterDataOperatorAPI,
  GetMasterDataOperatorCommentsAPI,
  DeleteMasterDataOperatorDraftAPI,
  GetMasterDataPackageTypeAPI,
  GetMasterDataPackageTypeCommentsAPI,
  DeleteMasterDataPackageTypeDraftAPI,
  GetMasterDataContractAPI,
  GetMasterDataContractCommentsAPI,
  DeleteMasterDataContractDraftAPI,
  GetMasterDataProjectStatusAPI,
  GetMasterDataProjectStatusCommentsAPI,
  DeleteMasterDataProjectStatusDraftAPI,
  GetMasterDataProjectStageAPI,
  GetMasterDataProjectStageCommentsAPI,
  DeleteMasterDataProjectStageDraftAPI,
  GetMasterDataBrandAPI,
  GetMasterDataBrandCommentsAPI,
  DeleteMasterDataBrandDraftAPI,
  GetMasterDataRoomTypeAPI,
  GetMasterDataRoomTypeCommentsAPI,
  DeleteMasterDataRoomTypeDraftAPI,
  GetMasterDataPositioningAPI,
  GetMasterDataPositioningCommentsAPI,
  DeleteMasterDataPositioningDraftAPI,
} from "../../api/MasterDataAPI";

import {
  Box,
  Center,
  Text,
  Spinner,
  useDisclosure,
  VStack,
  Badge,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import { NavLink, useParams } from "react-router-dom";

import { activeMenuItem } from "../../utils/General";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainButton } from "../../components/button/MainButton";
import { MainBox } from "../../components/mainBox/MainBox";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { MasterDataTable } from "./MasterDataTable";

import doc from "../../assets/images/icons/doc.svg";
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import plus from "../../assets/images/icons/plus.svg";
import leftArrow from "../../assets/images/icons/leftArrow2.svg";
import info from "../../assets/images/icons/Subtract.svg";

export const MasterDataVersions = () => {
  const activeMenu = activeMenuItem();
  let { type } = useParams();

  const renderRef = useRef(false);
  let typeSmall = type.toLowerCase();
  const [initConf, setInitConf] = useState({});
  const [masterData, setMasterData] = useState([]);
  const [selectedID, setSelectedID] = useState("-1");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [specificData, setSpecificData] = useState([]);
  const [specificType, setSpecificType] = useState("");
  const [comments, setComments] = useState([]);

  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const renderRowsAfterDeletion = (id) => {
    if (!id.startsWith("draft-")) {
      return;
    }

    initConf
      .DeleteDraftAPI(id.split("-")[1], id.split("-")[2])
      .then((res) => {
        setErrorMessage("");
        if (res.success === true) {
          setIsLoading(true);
          loadTableData();
        }
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "Something went wrong");
        onErrorOpen();
      });
  };

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);
    initConf
      .GetDataAPI()
      .then((res) => {
        setMasterData(res.data);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }, [initConf]);

  const loadSpecificTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);
    initConf
      .GetSpecificDataAPI()
      .then((res) => {
        setSpecificData(res.data?.pageRows || []);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }, [initConf]);

  const loadCommentsData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);
    initConf
      .GetDataCommentAPI(type)
      .then((res) => {
        setComments(res.data);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }, [initConf]);

  useEffect(() => {
    if (!!initConf.GetDataAPI) {
      loadTableData();
    }

    if (!!initConf.GetSpecificDataAPI) {
      loadSpecificTableData();
    }
    if (!!initConf.GetDataCommentAPI) {
      loadCommentsData();
    }
  }, [loadTableData]);

  useLayoutEffect(() => {
    if (renderRef.current) return;
    renderRef.current = true;
    switch (type) {
      case "Project status":
        setInitConf({
          GetDataAPI: GetMasterDataProjectStatusAPI,
          GetDataCommentAPI: GetMasterDataProjectStatusCommentsAPI,
          GetSpecificDataAPI: "",
          DeleteDraftAPI: DeleteMasterDataProjectStatusDraftAPI,
        });
        break;

      case "Regions":
        setInitConf({
          GetDataAPI: GetMasterDataRegionAPI,
          GetDataCommentAPI: GetMasterDataRegionCommentsAPI,
          GetSpecificDataAPI: "",
          DeleteDraftAPI: DeleteMasterDataRegionDraftAPI,
        });
        break;

      case "Project stage":
        setInitConf({
          GetDataAPI: GetMasterDataProjectStageAPI,
          GetDataCommentAPI: GetMasterDataProjectStageCommentsAPI,
          GetSpecificDataAPI: "",
          DeleteDraftAPI: DeleteMasterDataProjectStageDraftAPI,
        });
        break;

      case "Contract":
        setInitConf({
          GetDataAPI: GetMasterDataContractAPI,
          GetDataCommentAPI: GetMasterDataContractCommentsAPI,
          GetSpecificDataAPI: "",
          DeleteDraftAPI: DeleteMasterDataContractDraftAPI,
        });
        break;
      case "Brand":
        setInitConf({
          GetDataAPI: GetMasterDataBrandAPI,
          GetDataCommentAPI: GetMasterDataBrandCommentsAPI,
          GetSpecificDataAPI: GetMasterDataOperatorAPI,
          DeleteDraftAPI: DeleteMasterDataBrandDraftAPI,
        });
        setSpecificType("Operator");
        break;
      case "Room type":
        setInitConf({
          GetDataAPI: GetMasterDataRoomTypeAPI,
          GetDataCommentAPI: GetMasterDataRoomTypeCommentsAPI,
          GetSpecificDataAPI: GetMasterDataRoomCategoryAPI,
          DeleteDraftAPI: DeleteMasterDataRoomTypeDraftAPI,
        });
        setSpecificType("Room category");
        break;
      case "Positioning":
        setInitConf({
          GetDataAPI: GetMasterDataPositioningAPI,
          GetDataCommentAPI: GetMasterDataPositioningCommentsAPI,
          GetSpecificDataAPI: "",
          DeleteDraftAPI: DeleteMasterDataPositioningDraftAPI,
        });
        break;

      case "Package type":
        setInitConf({
          GetDataAPI: GetMasterDataPackageTypeAPI,
          GetDataCommentAPI: GetMasterDataPackageTypeCommentsAPI,
          GetSpecificDataAPI: "",
          DeleteDraftAPI: DeleteMasterDataPackageTypeDraftAPI,
        });
        break;

      case "Operator":
        setInitConf({
          GetDataAPI: GetMasterDataOperatorAPI,
          GetDataCommentAPI: GetMasterDataOperatorCommentsAPI,
          GetSpecificDataAPI: "",
          DeleteDraftAPI: DeleteMasterDataOperatorDraftAPI,
        });
        break;

      case "Room category":
        setInitConf({
          GetDataAPI: GetMasterDataRoomCategoryAPI,
          GetDataCommentAPI: GetMasterDataRoomCategoryCommentsAPI,
          GetSpecificDataAPI: "",
          DeleteDraftAPI: DeleteMasterDataRoomCategoryDraftAPI,
        });
        break;

      case "Asset category":
        setInitConf({
          GetDataAPI: GetMasterDataAssetCategoryAPI,
          GetDataCommentAPI: GetMasterDataAssetCategoryCommentsAPI,
          GetSpecificDataAPI: "",
          DeleteDraftAPI: DeleteMasterDataAssetCategoryDraftAPI,
        });
        break;
      default:
        break;
    }
  }, []);

  /* useEffect(() => {
    if (window.location.href.toLowerCase().includes("masterdata")) {
      if (
        !document
          .querySelector(".masterData")
          .classList.contains("masterActive")
      ) {
        document.querySelector(".masterData").classList.add(`${styles.active}`);
      }
    }
  }, []); */

  return (
    <>
      <VStack gap="2" alignItems="left">
        <Box>
          <Box display="inline-block" width="80px" pb={2}>
            <MainButton
              title="Back"
              fontColor="dark"
              btnStyle="transparent"
              icon={leftArrow}
              navLink={true}
              path={"/MasterDataHome"}
            />
          </Box>
        </Box>

        {/* <MainTitle title={`MASTER DATA - ${type}`} /> */}
      </VStack>
      {isLoading ? (
        <>
          {type === "Contract" ? (
            <MainTitle title={`MASTER DATA - Operating Model`} />
          ) : (
            <MainTitle title={`MASTER DATA - ${type}`} />
          )}

          {/* <MainTitle title={`MASTER DATA - ${type}`} /> */}
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {specificData.length === 0 &&
            (type === "Room type" || type === "Brand") && (
              <Badge bg="#EBC03F26" className="masterDataBadge" width={"100%"}>
                <HStack p="1">
                  <Tooltip
                    aria-label="A tooltip"
                    bg="#f4f4ed"
                    color="#1A1D1F"
                    borderRadius="5px"
                    border="1px solid #f4f4ed"
                    hasArrow
                    placement="right"
                    px={5}
                    py={2}
                  >
                    <img src={info} alt="tooltip icon" />
                  </Tooltip>
                  <Box fontSize={{ lg: 12, "2xl": 15 }}>
                    Ensure that you create
                    {specificType.startsWith("O") ? " an " : " a "}
                    <NavLink
                      // className={"myNav"}
                      style={{ color: "#007BB5", textDecoration: "underline" }}
                      to={`/MasterDataVersions/${specificType}`}
                    >
                      {specificType}
                    </NavLink>{" "}
                    to add a {type}
                  </Box>
                </HStack>
              </Badge>
            )}
          {type === "Contract" ? (
            <MainTitle pt="3" title={`MASTER DATA - Operating Model`} />
          ) : (
            <MainTitle pt="3" title={`MASTER DATA - ${type}`} />
          )}
          {masterData.pageRows?.length <= 0 ? (
            <>
              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">
                        No master data - {typeSmall} created yet
                      </Text>
                    </Box>
                    <MainButton
                      title="Create New"
                      marginRight="1"
                      icon={plus}
                      navLink={true}
                      disabledBtn={
                        specificData.length === 0 &&
                        (type === "Room type" || type === "Brand")
                          ? true
                          : false
                      }
                      path={
                        type === "Regions" ||
                        typeSmall === "positioning" ||
                        type === "Room type" ||
                        type === "Brand"
                          ? `/MasterDataCustom/${type}`
                          : `/MasterData/${type}`
                      }
                      /* path={`/MasterData/${type}`} */
                    />
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <MasterDataTable
                masterData={masterData}
                comments={comments}
                setSelectedID={setSelectedID}
                onDelModalOpen={onDelModalOpen}
                GetDataAPI={initConf?.GetDataAPI}
              />
            </>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isDelModalOpen}
        onClose={onDelModalClose}
        title="Are you sure you want to delete this version?"
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={true}
        haveSaveDraft={true}
        isDelete={true}
        selectedID={selectedID}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        renderRowsAfterDeletion={renderRowsAfterDeletion}
      />

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
    </>
  );
};
