import React from "react";

import { Box, Text } from "@chakra-ui/react";

import styles from "../../components/summaryBox/summaryBox.module.scss";

export const SummaryBox = ({
    type
}) => {

    const SummaryBoxClassname = styles.border_summary_box;
    const renderDisclaimer = () => {
        return (
            type == 'ceo'?
            <Box>
               <Text >• NHD uses the key counts and assets shared by Proponent.</Text>
                <Text >• Proponent has not provided the updated development cost and Proponent is directly responsible for the accuracy of development cost.</Text>
                <Text >• Upon confirmation from Proponent, Capex and IRR will be updated accordingly</Text>
                <Text >• In P&L projections staff accommodation cost has been assumed at SAR 4,687/FTE/month in Fixed component of P&L, provided by Proponent</Text>
                <Text >• Service charge cost is under review and will be added upon approval and confirmation from proponent</Text>
                <Text >• IRR and Capex are run in Proponent's project model</Text>
                <Text >• Note that any other data in this dashboard, such as bottom-line and return figures, is based on NHD projections and
                     might slightly differ from the official board-approved figures due to differences in modeling approaches. It is advised not to
                     communicate the figures in this dashboard with any other party; its use is solely for informational purposes.</Text>
                    </Box>
                    :<Text>Regional Visitors numbers are under review and will be updated upon further clarity</Text>
        )
    }
    return (
        <Box minH="98px" bg='#FCFCFC' className={`${styles.summery_box} ${SummaryBoxClassname}`}>

            {/* <Box
                borderRadius={50}
                bg="rgba(235, 192, 63, 0.25)"
                className={`${styles.summery_box_circle}`}
            >
                <img src={icon} alt="summary icon" />
            </Box> */}
            <Box fontSize='12px'>

                {renderDisclaimer()}
            
            </Box>


        </Box>
    );
};
