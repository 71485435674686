import React, { useState, useEffect, memo } from "react";
import { Box, Text, Center, Button, Image, Spinner } from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import styles from "../../components/forms.module.scss";
import tableRightArrow from "../../assets/images/icons/tableRightArrow.svg";
import searchIcon from "../../assets/images/icons/search.svg";
import plus from "../../assets/images/icons/plus.svg";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { MainButton } from "../../components/button/MainButton";

export const TablePagination2 = memo(function TablePagination2({
  data,
  columns,
  onPressBtn,
  isSearchable,
  title,
  hideBox,
  hasDelete,
  pageSize,
  totalRows,
  handleOnPageChange,
  onSort,
  setCurrPagData,
  currPagData,
  onCreateModalOpen,
  searchTerm,
  setSearchTerm,
  user,
  isTableLoading = false,
  setIsTableLoading,
}) {
  const [tableData, setTableData] = useState(data);
  const [pages, setPages] = useState([]);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [pageToShow, setPageToShow] = useState(1);
  const [leftLeftSide, setLeftLeftSide] = useState(0);
  const [leftRightSide, setLeftRightSide] = useState(3);
  const temp = [];
  const [isTextDisabled, setIsTextDisabled] = useState(true);

  let totalRowsVal = Math.ceil(totalRows / pageSize);
  console.log("totalRows", totalRowsVal, totalRows, pageSize);
  const handlePageChange = (event, page) => {
    setPageToShow(page);
    setCurrPagData({ ...currPagData, page: page });

    handleOnPageChange(page, currPagData.sort, currPagData.sortOrder);
  };
  const handleNext = () => {
    if (pageToShow !== totalRowsVal) {
      handleOnPageChange(
        pageToShow + 1,
        currPagData.sort,
        currPagData.sortOrder
      );
      setPageToShow(pageToShow + 1);
      setCurrPagData({ ...currPagData, page: pageToShow + 1 });
    }
  };
  const handlePrev = () => {
    if (pageToShow !== 1) {
      handleOnPageChange(
        pageToShow - 1,
        currPagData.sort,
        currPagData.sortOrder
      );
      setPageToShow(pageToShow - 1);
      setCurrPagData({ ...currPagData, page: pageToShow - 1 });
    }
  };

  //filter data
  const handleSearchChange = (e) => {
    // if (data.length === 0) return;
    setSearchTerm(e.target.value);
    setIsTableLoading(true);
    setPageToShow(1);
    setLeftLeftSide(0);
    setLeftRightSide(3);
    setCurrPagData({ ...currPagData, page: 1 });
  };

  useEffect(() => {
    for (var i = 0; i < totalRowsVal; i++) {
      temp[i] = totalRowsVal - i;
    }
    const reversed = temp.slice().reverse();

    setPages(reversed);

    if (totalRowsVal - 1 <= leftRightSide) {
      setIsTextDisabled(false);
    }
  }, []);

  useEffect(() => {
    if (pageToShow === leftLeftSide + 1 && pageToShow !== 1) {
      console.log("enter here==1==");
      setLeftLeftSide(leftLeftSide - 1);
      setLeftRightSide(leftRightSide - 1);

      if (totalRowsVal - 1 === leftRightSide) {
        setIsTextDisabled(true);
      }
    }
    if (pageToShow === leftRightSide && pageToShow !== totalRowsVal - 1) {
      console.log("enter here==2==");
      setLeftRightSide(leftRightSide + 1);
      setLeftLeftSide(leftLeftSide + 1);
      if (totalRowsVal - 1 === leftRightSide + 1) {
        setIsTextDisabled(false);
      }
    }
    console.log("pageToShow", pageToShow, totalRowsVal);
    if (pageToShow === totalRowsVal && totalRowsVal > 5) {
      console.log("enter here==3==");
      setLeftRightSide(totalRowsVal - 1);
      setLeftLeftSide(totalRowsVal - 4);
      setIsTextDisabled(false);
    }

    if (pageToShow === 1) setDisablePrev(true);
    else setDisablePrev(false);

    if (pageToShow === totalRowsVal) setDisableNext(true);
    else setDisableNext(false);

    if (totalRowsVal === 0) {
      setDisableNext(true);
      setDisablePrev(true);
    }
  }, [handleNext, handlePrev]);

  useEffect(() => {
    /* setTableData(tableData); */
    setTableData((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(tableData)) {
        return prev;
      } else {
        return tableData;
      }
    });
  }, [tableData]);
  useEffect(() => {
    //const value = e.target.value;
    //searchText
    const getData = setTimeout(() => {
      handleOnPageChange(
        pageToShow,
        currPagData.sort,
        currPagData.sortOrder,
        searchTerm
      );
    }, 1000);

    return () => clearTimeout(getData);
  }, [searchTerm]);

  console.log("leftLeftSide, leftRightSide", leftLeftSide, leftRightSide);

  return (
    <>
      {isSearchable && (
        <Box display="flex" justifyContent="space-between">
          <MainTitle title={title} />
          <Box
            display="flex"
            maxWidth={user === "proponent" ? "40%" : "50%"}
            width={user === "proponent" ? "40%" : "50%"}
          >
            <Box
              maxWidth={user === "proponent" ? "100%" : "80%"}
              width={user === "proponent" ? "100%" : "80%"}
              flex="1"
            >
              <div className={styles.form_control}>
                <div
                  className={`${styles.form_control_input} ${styles.form_control_input_pl}`}
                >
                  <input
                    name="search"
                    placeholder="Search"
                    type="text"
                    id="search"
                    value={searchTerm}
                    //onChange={handleSearchChange}
                    onChange={handleSearchChange}
                  />
                  <div className="iconic_left_input">
                    <img src={searchIcon} alt="icon" />
                  </div>
                </div>
              </div>
            </Box>
            {user === "proponent" || user === "manager" ? (
              ""
            ) : (
              <Box ml={3}>
                <MainButton
                  title="Create new asset"
                  icon={plus}
                  btnHeight="48px"
                  onPress={() => {
                    onCreateModalOpen();
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box pb={8} mr={3} mb={8} width="100%">
        {hideBox ? (
          <Box className="table_content" pt={3} pb={2} mb={5}>
            <DataTable
              columns={columns}
              data={data}
              onSort={onSort}
              sortServer
              defaultSortAsc={false}
            />
          </Box>
        ) : (
          <MainBox>
            <Box className="table_content" pt={3} pb={2}>
              {isTableLoading ? (
                <Center>
                  <Box paddingTop={12} paddingBottom={12}>
                    <Center>
                      <Spinner />
                    </Center>
                  </Box>{" "}
                </Center>
              ) : (
                <DataTable
                  columns={columns}
                  data={[...data]}
                  onSort={onSort}
                  sortServer
                  defaultSortAsc={false}
                />
              )}
            </Box>
          </MainBox>
        )}

        {totalRows > 10 && (
          <Center mt="-3">
            <Button
              mx="1"
              border="1px solid #E2E2EA"
              bg="#FFFFFF"
              px="2.5 !important"
              py="3.5"
              size={{ base: "xs", md: "xs", lg: "xs" }}
              onClick={() => {
                handlePrev();
                setIsTableLoading(true);
              }}
              isDisabled={disablePrev}
            >
              <Box>
                <Image className="table_prev" src={tableRightArrow}></Image>
              </Box>
            </Button>

            {pages.slice(leftLeftSide, leftRightSide).map((page) => {
              console.log("page", page);
              if (page !== totalRowsVal && page < totalRowsVal) {
                return (
                  <Button
                    key={page}
                    page={page}
                    color="#6F767E"
                    bg="transparent"
                    size="xs"
                    mx="1"
                    fontSize="15"
                    borderRadius="lg"
                    isActive={pageToShow === page}
                    _active={{ color: "black" }}
                    onClick={(event) => {
                      handlePageChange(event, page);
                      setIsTableLoading(true);
                    }}
                  >
                    {page}
                  </Button>
                );
              }
              return null;
            })}

            {isTextDisabled && <Text fontSize="12">...</Text>}

            {pages.slice(totalRowsVal - 1, totalRowsVal).map((page) => (
              <Button
                key={page}
                page={page}
                color="#6F767E"
                mx="1"
                bg="transparent"
                size="xs"
                fontSize="15"
                borderRadius="lg"
                isActive={pageToShow === page}
                _active={{ color: "black" }}
                onClick={(event) => {
                  handlePageChange(event, page);
                  setIsTableLoading(true);
                }}
              >
                {page}
              </Button>
            ))}

            <Button
              mx="1"
              border="1px solid #E2E2EA"
              bg="#FFFFFF"
              px="2.5 !important"
              py="3.5"
              size={{ base: "xs", md: "xs", lg: "xs" }}
              onClick={() => {
                handleNext();
                setIsTableLoading(true);
              }}
              isDisabled={disableNext}
            >
              <Box>
                <Image src={tableRightArrow}></Image>
              </Box>
            </Button>
          </Center>
        )}
      </Box>
    </>
  );
});
