import React from "react";
import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useEditableControls,
  EditableInput,
  Editable,
  EditablePreview,
  Input,
} from "@chakra-ui/react";

import { MainButton } from "../button/MainButton";

import check from "../../assets/images/icons/checkBlack.svg";
import threeDots from "../../assets/images/icons/threeDots.svg";
import add from "../../assets/images/icons/addGray.svg";
import pen from "../../assets/images/icons/penBlue.svg";
import remove from "../../assets/images/icons/removeMain.svg";
import editRow from "../../assets/images/icons/editRow.svg";
import removeRow from "../../assets/images/icons/removeRow.svg";

export const ModelCard = ({
  maxWidth = "100%",
  title,
  isSelected,
  onSelectOpen,
  type,
  data = [],
  onOpen,
  setEditModel,
  modelId,
  setSelectedModel,
  onDelModalOpen,
  ind,
  isView,
}) => {
  const EditableControls = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <Box display="flex" justifyContent="space-around" width="80px">
        <button {...getSubmitButtonProps()}>
          <Image src={editRow} alt="edit" width="20px" />
        </button>
        <button {...getCancelButtonProps()}>
          <Image src={removeRow} alt="edit" width="20px" />
        </button>
      </Box>
    ) : (
      <Box flex="1" display="flex" justifyContent="flex-end">
        <Menu autoSelect={false}>
          <MenuButton
            bgColor="transparent"
            as={Button}
            px={1}
            ml={1}
            minWidth="auto"
            _hover={{ backgroundColor: "transparent" }}
            disabled={isView}
          >
            <img src={threeDots} alt="dots icon" />
          </MenuButton>
          <MenuList px="5px" py="15px">
            <Text
              fontSize="12px"
              color="#6F767E"
              px="10px"
              mb="7px"
              textAlign="left"
            >
              More actions
            </Text>

            <MenuItem alignItems="center">
              <button {...getEditButtonProps()}>
                <Box display="flex">
                  <img src={pen} width="13px" alt="edit icon" />
                  <Text
                    color="#003865"
                    ml="7px"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    Edit Model Name
                  </Text>
                </Box>
              </button>
            </MenuItem>
            <MenuItem alignItems="center">
              <Box
                display="flex"
                onClick={() => {
                  setSelectedModel(`delete|${modelId}`);
                  onDelModalOpen();
                }}
              >
                <img src={remove} width="17px" alt="edit icon" />
                <Text color="#003865" ml="5px" fontSize="12px" fontWeight="400">
                  Remove Model
                </Text>
              </Box>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    );
  };

  const onEditModelName = (event) => {
    setEditModel({
      name: event,
      id: modelId,
    });
  };

  const renderFields = () => {
    const filter = data.filter((el) => el.checked);
    // console.log("data", data);
    if (filter.length > 0) {
      return filter.map((el, index) => {
        if (el.id === "departmentalProfitPercent") {
          let roomsField = data.filter(
            (el) => el.id === "roomsProfitPercentCombined"
          );
          let otherField = data.filter(
            (el) => el.id === "otherOperatingDepartmentsProfitPercentCombined"
          );
          let foodField = data.filter(
            (el) => el.id === "foodAndBeverageProfitPercentCombined"
          );
          // console.log("departmentalProfitPercent", roomsField, otherField);
          return (
            <>
              <Box
                flexDirection="column"
                color="#1D1F22"
                alignItems="start"
                key={`field_${el.id}_${index + 1}`}
                className="ml_0"
                paddingBottom={2}
                width="100%"
              >
                <Text fontSize="12px" textTransform="uppercase">
                  {roomsField[0].title}
                </Text>
                <Text fontSize="15px">{roomsField[0].value}</Text>
              </Box>
              <Box
                flexDirection="column"
                color="#1D1F22"
                alignItems="start"
                key={`field_${el.id}_${index + 2}`}
                className="ml_0"
                paddingBottom={2}
                width="100%"
              >
                <Text fontSize="12px" textTransform="uppercase">
                  {foodField[0].title}
                </Text>
                <Text fontSize="15px">{foodField[0].value}</Text>
              </Box>
              <Box
                flexDirection="column"
                color="#1D1F22"
                alignItems="start"
                key={`field_${el.id}_${index + 3}`}
                className="ml_0"
                paddingBottom={2}
                width="100%"
              >
                <Text fontSize="12px" textTransform="uppercase">
                  {otherField[0].title}
                </Text>
                <Text fontSize="15px">{otherField[0].value}</Text>
              </Box>
            </>
          );
        } else {
          return (
            <Box
              flexDirection="column"
              color="#1D1F22"
              alignItems="start"
              key={`field_${index}`}
              className="ml_0"
              paddingBottom={2}
              width="100%"
            >
              <Text fontSize="12px" textTransform="uppercase">
                {el.title}
              </Text>
              <Text fontSize="15px">{el.value}</Text>
            </Box>
          );
        }
      });
    } else {
      return data.map((el, index) => {
        if (index <= 7) {
          return (
            <Box
              flexDirection="column"
              color="#1D1F22"
              alignItems="start"
              key={`field_${index}`}
              className="ml_0"
              paddingBottom={2}
              width="100%"
            >
              <Text fontSize="12px" textTransform="uppercase">
                {el.title}
              </Text>
              <Text fontSize="15px">{el.value}</Text>
            </Box>
          );
        }
      });
    }
  };

  if (type !== "Create") {
    return (
      <Box
        bg="white"
        py={15}
        px={25}
        pt={25}
        mb={8}
        mt={3}
        className="model_card"
        maxW={maxWidth}
        color="black"
        w="22.5%"
        minWidth="22.5%"
        borderRightColor="#CCD7E0"
        borderRightWidth="1px"
        borderLeftStyle="solid"
        position="relative"
        minH="150px"
        key={ind}
      >
        {isSelected && (
          <Badge
            color="#EBC03F"
            background="#1D1F22"
            borderRadius="5px"
            px="6px"
            py="3px"
            textTransform="capitalize"
            fontSize="10px"
            letterSpacing="0.06em"
            position="absolute"
            top="0px"
          >
            Current Asset
          </Badge>
        )}

        <HStack
          marginBottom={4}
          marginTop={1}
          borderBottomColor="#CCD7E0"
          borderBottomWidth="1px"
          pb={2}
        >
          <Editable
            textAlign="center"
            defaultValue={`${title}`}
            // value={`${title}`}
            fontSize="15px"
            // isPreviewFocusable={false}
            width="100%"
            display="flex"
            alignItems="center"
            onSubmit={onEditModelName}
          >
            <EditablePreview />
            {/* Here is the custom input */}
            <Input as={EditableInput} borderRadius={0} textAlign="left" />
            <EditableControls />
          </Editable>
        </HStack>
        <HStack
          display="flex"
          justifyContent="flex-start"
          fontSize="12px"
          w="100%"
          flexDirection="column"
          alignItems="flex-start"
        >
          {renderFields()}
        </HStack>

        <HStack mt="8" py="1">
          <MainButton
            disabledBtn={isView}
            onPress={() => {
              if (!isSelected) {
                onSelectOpen();
                setSelectedModel(modelId);
              }
            }}
            title={isSelected ? "Selected" : "Select"}
            marginRight="1"
            icon={check}
            btnWidthFull={true}
            btnStyle={isSelected ? "" : "gray_border"}
          />
        </HStack>
      </Box>
    );
  } else {
    return (
      <Box
        bg="white"
        py={15}
        px={25}
        pt={25}
        mb={8}
        mt={3}
        className="model_card model_card-create"
        maxW={maxWidth}
        color="black"
        w="22.5%"
        minWidth="22.5%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginLeft="1%"
        minH="150px"
        key={ind}
      >
        <Button
          backgroundColor="transparent"
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          onClick={() => onOpen()}
          isDisabled={isView}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Image src={add} alt="add" />
            <Text
              fontSize="15px"
              textTransform="capitalize"
              color="#003865"
              letterSpacing="-0.01em"
              mt={3}
            >
              Generate another model
            </Text>
          </Box>
        </Button>
      </Box>
    );
  }
};
