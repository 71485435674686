import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import {
  Box,
  Text,
  Flex,
  Divider,
  SimpleGrid,
  GridItem,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import {
  assetListStep9calc,
  assetListStep9HandleCalc,
} from "../../utils/Formula";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { InputFieldCalc } from "../../components/inputField/InputFieldCalc";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { FieldArray } from "formik";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";

import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import percent from "../../assets/images/icons/filled.svg";
import m2 from "../../assets/images/icons/m2.svg";
import plus from "../../assets/images/icons/plus2.svg";
import deleteIcon2 from "../../assets/images/icons/delete2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import { nullifyYearsField } from "../../utils/General";
import { isDecimalNumber } from "../../utils/General";
import {CalculationButton} from"../../components/calculationModal/calculationButton"

export const AssetsListForm9 = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  setInitFormVal,
  initFormVal,
  opStartDate,
  unsetStepWarning,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  step,
  setStepWarning,
  brandedPerformanceLength = 1,
  nextStep,
  setIsDisabledTypes,
  isDisabledTypes,
  setUnitMixChanged,
  type,
  setStepperUpdate,
  stepperUpdate,
  validateStep,
  setValidateStep,
  unsetPageClicked,
  undoUnsetPageClicked,
  setUpdateDisableNextStep,
  updateDisableNextStep,
  modelWarningOpen,
  isModelWarningOpen,
  closeModalPath,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
  user
}) => {
  // const [unitMix, setUnitMix] = useState();
  const [unitMix, setUnitMix] = useState([...dropdownObj.types]);
  const [addDisabled, setAddDisabled] = useState(false);
  // const [results, setResults] = useState([]);
  const [results, setResults] = useState([...dropdownObj.types]);
  const dataFetchedRef = useRef(false);

  const unitMixGroup = {
    type: "",
    numKeys: "",
    totalSellable: "",
    baseCost: "",
    salesPrice: "",
  };

  const handleNewlyAdded = () => {
    setStepWarning(page + 1);
    setUnitMixChanged(true);
  };
  const handleRemoveWarning = () => {
    if (
      formikValues?.unitMix.length - 1 === brandedPerformanceLength &&
      (nextStep.completed || nextStep.warning)
    ) {
      unsetStepWarning(page + 1);
      setStepCompleted(page + 1);
      setUnitMixChanged(false);
    }
  };
  let form9Data = [];
  form9Data.push(formikValues?.brandedResidence); //radio
  form9Data.push(formikValues.minimumResidenceRadio); //radio
  form9Data.push(formikValues.preOpTraining); //radio
  form9Data.push(formikValues.softCosts); //radio
  form9Data.push(formikValues.ffeAndOse); //radio
  formikValues?.annualSalesAbsorption?.forEach((item) => {
    form9Data.push(item);
  });
  form9Data.push(formikValues.Efficiency); //string
  form9Data.push(formikValues.opreatorContributions); //string
  form9Data.push(formikValues.adminExpenses); //string
  form9Data.push(formikValues.marketingSellingCost); //string
  form9Data.push(formikValues.adminCharges); //string
  form9Data.push(formikValues.shareOfResidence); //string
  form9Data.push(formikValues.finalAssetSale); //string
  form9Data.push(formikValues.minimumResidence); //string

  form9Data.push(formikValues.residentialSaleFee); //string
  form9Data.push(formikValues.residentialConsultancyFee); //string

  let emptyform9Data = [];
  formikValues?.unitMix?.forEach((item) => {
    form9Data.push(item.type);
    form9Data.push(item.numKeys);
    form9Data.push(item.totalSellable);
    form9Data.push(item.baseCost);
    form9Data.push(item.salesPrice);
  });
  let annualSalesError = false;
  form9Data.forEach((item, ind) => {
    if (ind <= 4) {
      if (item != "") {
        emptyform9Data.push(item);
      }
    } else if (ind > 4 && ind <= 9) {
      let valSum = 0;
      let countZero = 0;
      formikValues?.annualSalesAbsorption?.forEach((item2) => {
        valSum += item2;
        if (item2 == 0) {
          countZero++;
        }
      });
      if ((valSum == 100 && item <= "100") || countZero === 5) {
        emptyform9Data.push(item);
        annualSalesError = false;
      } else {
        annualSalesError = true;
      }
    } else if (ind <= 17) {
      if (item !== "" && item >= "0" && item <= "100") {
        emptyform9Data.push(item);
      }
    } else if (ind === 20) {
      if (formikValues.brandedResidence == "1") {
        let find = formikValues?.unitMix?.find((el, i) => {
          let typesFind = dropdownObj.types.find(
            (element) => element.value === el.type
          );
          return el.type === typesFind?.value;
          // return el.type === dropdownObj.types[i].value;
        });
        // console.log("find", find);
        if (item !== "" && item >= "0" && find) {
          emptyform9Data.push(item);
        }
      } else {
        if (item !== "" && item >= "0") {
          emptyform9Data.push(item);
        }
      }
    } else {
      if (item !== "" && item >= "0") {
        emptyform9Data.push(item);
      }
    }
  });
  let isDisabledForm = false;
  // console.log(emptyform9Data, form9Data);
  if (emptyform9Data.length !== form9Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  const calcOccupancyIncField5 = (val = -1, index, fieldName) => {
    let newInitObj = assetListStep9calc(val, index, fieldName, formikValues);
    if (newInitObj != "0") {
      //console.log("intiob", newInitObj, { ...formikValues, newInitObj });
      setInitFormVal({ ...newInitObj });
    }
  };

  const handleCalcOcc = (name, val) => {
    //console.log("fffffas", formikValues);
    //console.log("fffffaaassss", name, val);
    if (`${name}`.includes("unitMix.")) {
      let unitTypeIndex = `${name}`.split(".")[1];

      if (`${name}`.includes(".totalSellable")) {
        if (formikValues.unitMix[unitTypeIndex].numKeys != "" && val !== "") {
          calcOccupancyIncField5(val, unitTypeIndex, "totalSellable");
        }
      } else if (`${name}`.includes(".numKeys") && val !== "") {
        if (formikValues.unitMix[unitTypeIndex].totalSellable != "") {
          calcOccupancyIncField5(val, unitTypeIndex, "totalNumKeysCalc");
        } else {
          calcOccupancyIncField5(val, unitTypeIndex, "totalNumKeysCalc");
        }
        /*   if (formikValues.unitMix[unitTypeIndex].baseCost != "") {
          calcOccupancyIncField5(val, unitTypeIndex, "numKeys");
        } else {
          calcOccupancyIncField5(val, unitTypeIndex, "baseCost");
        }
        if (formikValues.unitMix[unitTypeIndex].salesPrice != "") {
          calcOccupancyIncField5(val, unitTypeIndex, "numKeys");
        } else {
          calcOccupancyIncField5(val, unitTypeIndex, "salesPrice");
        } */
      } /* else if (`${name}`.includes(".salesPrice")) {
        if (formikValues.unitMix[unitTypeIndex].numKeys != "") {
          calcOccupancyIncField5(val, unitTypeIndex, "salesPrice");
        }
      }*/ else if (`${name}`.includes(".baseCost") && val !== "") {
        //if (formikValues.unitMix[unitTypeIndex].numKeys != "") {
        calcOccupancyIncField5(val, unitTypeIndex, "baseCost");
        //}
      } else if (`${name}`.includes(".salesPrice") && val !== "") {
        //if (formikValues.unitMix[unitTypeIndex].numKeys != "") {
        calcOccupancyIncField5(val, unitTypeIndex, "salesPrice");
        //}
      }
    } else {
      let newInitObj = assetListStep9HandleCalc(name, val, formikValues);
      if (newInitObj !== 0) {
        setInitFormVal({ ...formikValues, ...newInitObj });
      }
    }
  };
  const handleChangeRadio = (event, name) => {
    if (setIsFormDirty && !isFormDirty) {
      setIsFormDirty(true);
    }
    setFieldValue(name, event);

    if (name === "minimumResidenceRadio" && event == "2") {
      setFieldValue("minimumResidence", 0);
    }
    if (name === "brandedResidence" && event == "1") {
      if (type === "Standard") {
        undoUnsetPageClicked(11);
      } else {
        undoUnsetPageClicked(11);
      }
      setUpdateDisableNextStep(false);
      let newUnitMix1 = formikValues.unitMix.map((el, ind) => {
        return {
          type: el.type == "" || !el.type ? "" : el.type,
          numKeys: el.numKeys == "" ? 0 : el.numKeys,
          totalSellable: el.totalSellable == "" ? 0 : el.totalSellable,
          baseCost: el.baseCost == "" ? 0 : el.baseCost,
          salesPrice: el.salesPrice == "" ? 0 : el.salesPrice,
        };
      });
      setInitFormVal({
        ...formikValues,
        brandedResidence: "1",
        unitMix: newUnitMix1,
      });
    }
    if (name === "brandedResidence" && event == "2") {
      if (type === "Standard") {
        unsetPageClicked(10);
        setUpdateDisableNextStep(true);
      } else {
        unsetPageClicked(11);
        setUpdateDisableNextStep(true);
      }

      let newUnitMix = formikValues.unitMix.map((el, ind) => {
        return {
          type: el.type == "" || !el.type ? "noSelect" : el.type,
          numKeys: el.numKeys == "" ? 0 : el.numKeys,
          totalSellable: el.totalSellable == "" ? 0 : el.totalSellable,
          baseCost: el.baseCost == "" ? 0 : el.baseCost,
          salesPrice: el.salesPrice == "" ? 0 : el.salesPrice,
        };
      });
      let newAdrUnit = formikValues.brandedResidencesFields.adrPerUnit.map(
        (el, ind) => {
          return {
            id: el.id == "" ? "" : el.id,
            type: el.type == "" ? "" : el.type,
            typeField: el.typeField == "" ? 0 : el.typeField,
          };
        }
      );
      let newAvgNumberOfDays =
        formikValues.brandedResidencesFields.avgNumberOfDaysPerAnnum.map(
          (el, ind) => {
            return {
              id: el.id == "" ? "" : el.id,
              type: el.type == "" ? "" : el.type,
              typeField: el.typeField == "" ? 0 : el.typeField,
            };
          }
        );
      let newRentalPool = formikValues.brandedResidencesFields.rentalPool.map(
        (el, ind) => {
          return {
            id: el.id == "" ? "" : el.id,
            type: el.type == "" ? "" : el.type,
            typeField: el.typeField == "" ? 0 : el.typeField,
          };
        }
      );
      let newStabilizedOcc =
        formikValues.brandedResidencesFields.stabilizedOccupancy.map(
          (el, ind) => {
            return {
              id: el.id == "" ? "" : el.id,
              type: el.type == "" ? "" : el.type,
              typeField: el.typeField == "" ? 0 : el.typeField,
            };
          }
        );

      let newAnnualSalesAbsorp = formikValues.annualSalesAbsorption.map(
        (el, ind) => {
          return el == "" ? 0 : el;
        }
      );
      let newBrandedBaseFee = formikValues.brandedBaseFee.map((el, ind) => {
        return el == "" ? 0 : el;
      });
      let newBrandedIncentiveFee = formikValues.brandedIncentiveFee.map(
        (el, ind) => {
          return el == "" ? 0 : el;
        }
      );
      let newBrandedFranchiseFee = formikValues.brandedFranchiseFee.map(
        (el, ind) => {
          return el == "" ? 0 : el;
        }
      );
      let newOtherRev = formikValues.brandedResidencesFields.otherRev.map(
        (el, ind) => {
          return el == "" ? 0 : el;
        }
      );
      setInitFormVal({
        ...formikValues,
        Efficiency: formikValues.Efficiency == "" ? 0 : formikValues.Efficiency,
        calcTotalBaseCost:
          formikValues.calcTotalBaseCost == ""
            ? 0
            : formikValues.calcTotalBaseCost,
        calcTotalSalesPrice:
          formikValues.calcTotalSalesPrice == ""
            ? 0
            : formikValues.calcTotalSalesPrice,
        calcTotalNumberOfKeys:
          formikValues.calcTotalNumberOfKeys == ""
            ? 0
            : formikValues.calcTotalNumberOfKeys,
        calcTotalSellableGla:
          formikValues.calcTotalSellableGla == ""
            ? 0
            : formikValues.calcTotalSellableGla,
        calcTotalGfaBrandedRes:
          formikValues.calcTotalGfaBrandedRes == ""
            ? 0
            : formikValues.calcTotalGfaBrandedRes,
        annualSalesAbsorption: newAnnualSalesAbsorp,
        opreatorContributions:
          formikValues.opreatorContributions == ""
            ? 0
            : formikValues.opreatorContributions,
        adminCharges:
          formikValues.adminCharges == "" ? 0 : formikValues.adminCharges,
        adminExpenses:
          formikValues.adminExpenses == "" ? 0 : formikValues.adminExpenses,
        marketingSellingCost:
          formikValues.marketingSellingCost == ""
            ? 0
            : formikValues.marketingSellingCost,
        residentialSaleFee:
          formikValues.residentialSaleFee == ""
            ? 0
            : formikValues.residentialSaleFee,
        residentialConsultancyFee:
          formikValues.residentialConsultancyFee == ""
            ? 0
            : formikValues.residentialConsultancyFee,
        shareOfResidence:
          formikValues.shareOfResidence == ""
            ? 0
            : formikValues.shareOfResidence,
        minimumResidence: formikValues.minimumResidence == "" ? 0 : formikValues.minimumResidence,
        finalAssetSale:
          formikValues.finalAssetSale == "" ? 0 : formikValues.finalAssetSale,
        softCosts: formikValues.softCosts == "" ? "2" : formikValues.softCosts,
        ffeAndOse: formikValues.ffeAndOse == "" ? "2" : formikValues.ffeAndOse,
        minimumResidenceRadio:
          formikValues.minimumResidenceRadio == ""
            ? "2"
            : formikValues.minimumResidenceRadio,
        preOpTraining:
          formikValues.preOpTraining == "" ? "2" : formikValues.preOpTraining,
        brandedResidence: "2",

        unitMix: newUnitMix,
        expenses1: formikValues.expenses1 == "" ? 0 : formikValues.expenses1,
        expenses2: formikValues.expenses2 == "" ? 0 : formikValues.expenses2,
        expenses3: formikValues.expenses3 == "" ? 0 : formikValues.expenses3,
        expenses4: formikValues.expenses4 == "" ? 0 : formikValues.expenses4,
        expenses5: formikValues.expenses5 == "" ? 0 : formikValues.expenses5,
        brandedBaseFee: newBrandedBaseFee,
        brandedIncentiveFee: newBrandedIncentiveFee,
        brandedFranchiseFee: newBrandedFranchiseFee,
        brandedResidencesFields: {
          adrPerUnit: newAdrUnit,
          avgNumberOfDaysPerAnnum: newAvgNumberOfDays,
          rentalPool: newRentalPool,
          stabilizedOccupancy: newStabilizedOcc,
          otherRev: newOtherRev,
        },
      });
    }
  };

  // console.log("formikValues", formikValues);
  let currDate = new Date(formikValues?.startDateDevTime).getFullYear();
  currDate = currDate - 1;
  const renderAnnualSalesFields = () => {
    return (
      <>
        <FieldArray
          name="annualSalesAbsorption"
          render={(arrayHelper) => (
            <Box display="flex" width="100%" gap="2.5%">
              {formikValues?.annualSalesAbsorption !== undefined &&
                formikValues?.annualSalesAbsorption?.map((_, index) => {
                  currDate = currDate + 1;
                  return (
                    <Box
                      width={["100%", "100%", "40%"]}
                      key={`annualSalesAbsorption${index}`}
                    >
                      <InputFieldValidation
                        label={`Year ${currDate}`}
                        placeholder="0"
                        smallIcon={percent}
                        inputType="number"
                        inputId={`annualSalesAbsorption.${index}`}
                        inputName={`annualSalesAbsorption.${index}`}
                        errors={errors}
                        setIsFormDirty={setIsFormDirty}
                        isFormDirty={isFormDirty}
                        touched={touched}
                        isRequired
                        onChange={setFieldValue}
                        values={formikValues?.annualSalesAbsorption[index]}
                        labelCenter={true}
                        errorHeight0={true}
                        uppercase={false}
                        directVal={true}
                        isDisabled={
                          isRestore || formikValues?.brandedResidence == "2"
                        }
                        validateField={validateStep}
                        errorShape={true}
                        isThousand={true}
                        setFieldTouched={setFieldTouched}
                      />
                    </Box>
                  );
                })}
            </Box>
          )}
        />
      </>
    );
  };

  useEffect(() => {
    let newUnitMix2 = [].concat(
      unitMix.filter((obj1) =>
        formikValues.unitMix.every((obj2) => obj1.value !== obj2.type)
      ) /* ,
      formikValues.unitMix.filter((obj2) =>
        unitMix.every((obj1) => obj2.type !== obj1.value)
      ) */
    );
    setResults(newUnitMix2);
  }, [formikValues.unitMix]);

  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);

  // useEffect(() => {
  //   var difference =
  //     new Date(formikValues?.startDateOpTime).getFullYear() - opStartDate;
  //   if (difference !== 0) {
  //     nullifyYearsField(
  //       step,
  //       opStartDate,
  //       formikValues,
  //       setFieldValue,
  //       formikValues?.startDateOpTime,
  //       ["annualSalesAbsorption"]
  //     );
  //   }

  // }, []);

  return (
    <>
      <Box alignItems="flex-start" justifyContent="space-between" display="flex" width="100%" mb={5}>
        <MainTitle title="BRANDED RESIDENCES - GENERAL" />
        <CalculationButton  formikValue={formikValues} />

      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Box
          maxW={["100%", "100%", "65%"]}
          width={["100%", "100%", "65%"]}
          alignSelf="flex-start"
        >
          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            General
          </Text>

          <Text
            className="title_sm"
            display="flex"
            mb={2}
            mt={3}
            component="div"
            letterSpacing="0.06em"
            fontSize="12px"
            textTransform="uppercase"
          >
            Branded Residences inclusion{" "}
          </Text>

          <RadioGroup
            onChange={(event) => handleChangeRadio(event, "brandedResidence")}
            value={`${formikValues?.brandedResidence}`}
            className="radio_group_custom"
            mb={4}
            name="brandedResidence"
            isDisabled={isRestore}
            validateField={validateStep}
          >
            <Stack direction="row">
              <Radio
                color="#003865 !important"
                bg="none !important"
                borderColor="#6F767E !important"
                value="1"
              >
                Yes
              </Radio>
              <Radio
                color="#003865 !important"
                bg="none !important"
                borderColor="#6F767E !important"
                value="2"
              >
                No
              </Radio>
            </Stack>
            {!formikValues?.brandedResidence && validateStep && (
              <Box
                color="#e14642"
                fontWeight="400"
                fontSize="10px"
                lineHeight="17px"
                letterSpacing="0.04em"
                marginTop="5px"
              >
                Required
              </Box>
            )}
          </RadioGroup>
        </Box>

        <Box
          maxW={["100%", "100%", "100%"]}
          width={["100%", "100%", "100%"]}
          alignSelf="flex-start"
        >
          <Box
            maxW={["100%", "100%", "100%"]}
            width={["100%", "100%", "100%"]}
            alignSelf="flex-start"
            className="addfield_wrapper"
          >
            <FieldArray name="unitMix">
              {({ push, remove }) => (
                <Box container spacing={2} sx={{ marginTop: 2, paddingX: 0 }}>
                  <Box>
                    <Text
                      className="title_sm"
                      display="flex"
                      mb={5}
                      mt={3}
                      component="div"
                      letterSpacing="0.06em"
                      fontSize="12px"
                    >
                      UNIT MIX
                    </Text>
                  </Box>
                  {formikValues?.unitMix?.map((_, index) => {
                    let placeHolder = dropdownObj.types.filter((el) => {
                      return el.value === formikValues?.unitMix[index].type;
                    });

                    return (
                      <>
                        <Box
                          display="flex"
                          alignItems="center"
                          pb={2}
                          className={index == 0 ? "addfieldSec" : ""}
                        >
                          <SimpleGrid
                            columns={{ sm: 3, md: 3 }}
                            spacingX="15px"
                            width="100%"
                          >
                            <GridItem colSpan={3}>
                              <SimpleGrid
                                columns={{ sm: 3, md: 3 }}
                                spacing="15px"
                                width="95%"
                              >
                                <GridItem>
                                  <SelectListMulti
                                    dropdownItems={results}
                                    label="Type"
                                    inputId={`unitMix.${index}.type`}
                                    inputName={`unitMix.${index}.type`}
                                    placeH={
                                      formikValues?.unitMix[index].type === ""
                                        ? "Select"
                                        : placeHolder[0]
                                            ?.label /* formikValues?.unitMix[index].type
                                          .split("_")
                                          .join(" ") */
                                      //   .charAt(0)
                                      //   .toUpperCase() +
                                      // formikValues?.unitMix[index].type.slice(
                                      //   1
                                      // )
                                    }
                                    errors={errors}
                                    setIsFormDirty={setIsFormDirty}
                                    isFormDirty={isFormDirty}
                                    touched={touched}
                                    isRequired
                                    values={
                                      formikValues.unitMix[index].type
                                      // formikValues.unitMix[index].type ===
                                      //   "noSelect" ||
                                      /* !formikValues?.brandedResidence
                                        ? */
                                      // : "noSelect"
                                    }
                                    onChange={setFieldValue}
                                    onBlur={handleBlur}
                                    uppercase={true}
                                    // onChange2={(e, e2) => handleChange(e, e2)}
                                    isDynamic={true}
                                    hideSelected={false}
                                    placeHolderValue={false}
                                    isDisabled={
                                      isRestore ||
                                      formikValues?.brandedResidence == "2"
                                    }
                                    validateField={validateStep}
                                  />
                                </GridItem>
                                <GridItem>
                                  <InputFieldCalc
                                    label="Number of keys"
                                    inputType="number"
                                    inputId={`unitMix.${index}.numKeys`}
                                    inputName={`unitMix.${index}.numKeys`}
                                    errors={errors}
                                    setIsFormDirty={setIsFormDirty}
                                    isFormDirty={isFormDirty}
                                    touched={touched}
                                    isRequired
                                    minNum="0"
                                    placeholder="0"
                                    onChange={setFieldValue}
                                    values={formikValues.unitMix[index].numKeys}
                                    directVal={true}
                                    isDisabled={
                                      isRestore ||
                                      formikValues?.brandedResidence == "2"
                                    }
                                    validateField={validateStep}
                                    calculatedOcc={true}
                                    onChangeCalc={handleCalcOcc}
                                    isThousand={true}
                                    setFieldTouched={setFieldTouched}
                                    onBlur={handleBlur}
                                  />
                                </GridItem>
                                <GridItem>
                                  <InputFieldCalc
                                    label="Total Sellable GLA/Unit"
                                    inputType="number"
                                    inputId={`unitMix.${index}.totalSellable`}
                                    inputName={`unitMix.${index}.totalSellable`}
                                    errors={errors}
                                    setIsFormDirty={setIsFormDirty}
                                    isFormDirty={isFormDirty}
                                    touched={touched}
                                    isRequired
                                    minNum="0"
                                    placeholder="0"
                                    icon={m2}
                                    onChange={setFieldValue}
                                    values={
                                      formikValues.unitMix[index].totalSellable
                                    }
                                    directVal={true}
                                    isDisabled={
                                      isRestore ||
                                      formikValues?.brandedResidence == "2"
                                    }
                                    validateField={validateStep}
                                    //values={formikValues?.roomType[index].numKeys}
                                    calculatedOcc={true}
                                    onChangeCalc={handleCalcOcc}
                                    isThousand={true}
                                    setFieldTouched={setFieldTouched}
                                    onBlur={handleBlur}
                                  />
                                </GridItem>
                                <GridItem className="mt--10">
                                  <InputFieldCalc
                                    label="Base Cost per Key"
                                    inputType="number"
                                    inputId={`unitMix.${index}.baseCost`}
                                    inputName={`unitMix.${index}.baseCost`}
                                    errors={errors}
                                    setIsFormDirty={setIsFormDirty}
                                    isFormDirty={isFormDirty}
                                    touched={touched}
                                    isRequired
                                    minNum="0"
                                    placeholder="0"
                                    // icon={percent}
                                    onChange={setFieldValue}
                                    values={
                                      formikValues.unitMix[index].baseCost
                                    }
                                    directVal={true}
                                    isDisabled={
                                      isRestore ||
                                      formikValues?.brandedResidence == "2"
                                    }
                                    validateField={validateStep}
                                    calculatedOcc={true}
                                    onChangeCalc={handleCalcOcc}
                                    leftIcon={true}
                                    leftIconText="SAR"
                                    isThousand={true}
                                    setFieldTouched={setFieldTouched}
                                    onBlur={handleBlur}
                                  />
                                </GridItem>
                                <GridItem className="mt--10">
                                  <InputFieldCalc
                                    label="Sales Price per SQM GLA"
                                    inputType="number"
                                    inputId={`unitMix.${index}.salesPrice`}
                                    inputName={`unitMix.${index}.salesPrice`}
                                    errors={errors}
                                    setIsFormDirty={setIsFormDirty}
                                    isFormDirty={isFormDirty}
                                    touched={touched}
                                    isRequired
                                    minNum="0"
                                    placeholder="0"
                                    // icon={percent}
                                    onChange={setFieldValue}
                                    values={
                                      formikValues.unitMix[index].salesPrice
                                    }
                                    directVal={true}
                                    isDisabled={
                                      isRestore ||
                                      formikValues?.brandedResidence == "2"
                                    }
                                    validateField={validateStep}
                                    calculatedOcc={true}
                                    onChangeCalc={handleCalcOcc}
                                    leftIcon={true}
                                    leftIconText="SAR"
                                    isThousand={true}
                                    setFieldTouched={setFieldTouched}
                                    onBlur={handleBlur}
                                  />
                                </GridItem>
                              </SimpleGrid>
                            </GridItem>
                          </SimpleGrid>
                          <Box>
                            {index > 0 && (
                              <Box>
                                <MainButton
                                  title=""
                                  icon={deleteIcon2}
                                  disabledBtn={
                                    isRestore ||
                                    formikValues.brandedResidence == "2"
                                  }
                                  btnStyle="transparent"
                                  fontColor="danger"
                                  fSize="sm"
                                  onPress={() => {
                                    remove(index);
                                    handleRemoveWarning();
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                  <Box>
                    <MainButton
                      title="Add unit type"
                      btnStyle="transparent"
                      icon={plus}
                      fontColor="dark"
                      onPress={() => {
                        push(unitMixGroup);
                        handleNewlyAdded();
                      }}
                      // disabledBtn={
                      //   addDisabled ||
                      //   isRestore ||
                      //   formikValues?.brandedResidence == "2"
                      //     ? true
                      //     : formikValues?.unitMix?.length >= 10
                      //     ? true
                      //     : false
                      // }
                      disabledBtn={
                        formikValues?.unitMix?.length ==
                          dropdownObj.types.length ||
                        isRestore ||
                        formikValues.brandedResidence == "2"
                          ? true
                          : formikValues?.unitMix?.length >= 10
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Box>
              )}
            </FieldArray>
          </Box>
        </Box>
        <Box
          pt="10"
          maxW={["100%", "100%", "100%"]}
          width={["100%", "100%", "100%"]}
          alignSelf="flex-start"
        >
          <Stack
            width="100%"
            pt="1"
            gap={{ sm: 2, md: 2, lg: 2, "2xl": "4" }}
            direction="row"
            h="40px"
            marginBottom={5}
            flexWrap="wrap"
          >
            <Box width="20%">
              <Text
                fontSize="15px"
                color="#003865"
                fontWeight="400"
                letterSpacing="0.04em"
                textAlign="left"
              >
                Average Base <br /> Cost (SAR)
              </Text>
              <Box className="calc_input">
                <InputFieldValidation
                  placeholder="0"
                  label=""
                  inputType="number"
                  inputId="calcTotalBaseCost"
                  inputName="calcTotalBaseCost"
                  errors={errors}
                  setIsFormDirty={setIsFormDirty}
                  isFormDirty={isFormDirty}
                  touched={touched}
                  isRequired
                  minNum="0"
                  onChange={setFieldValue}
                  // values={formikValues}
                  values={
                    isDecimalNumber(formikValues["calcTotalBaseCost"])
                      ? parseFloat(formikValues["calcTotalBaseCost"])?.toFixed(
                          2
                        )
                      : formikValues["calcTotalBaseCost"]
                  }
                  disable={true}
                  avg={true}
                  noBg={true}
                  isDisabled={true}
                  isThousand={true}
                  setFieldTouched={setFieldTouched}
                  onBlur={handleBlur}
                  directVal={true}
                />
              </Box>
            </Box>

            <Divider
              borderColor="#EFEFEF"
              border="2px"
              orientation="vertical"
            />
            <Box width="20%">
              <Text
                fontSize="15px"
                color="#003865"
                fontWeight="400"
                letterSpacing="0.04em"
                textAlign="left"
              >
                Average Sales <br />
                Price (SAR)
              </Text>
              <Box className="calc_input">
                <InputFieldValidation
                  placeholder="0"
                  label=""
                  inputType="number"
                  inputId="calcTotalSalesPrice"
                  inputName="calcTotalSalesPrice"
                  errors={errors}
                  setIsFormDirty={setIsFormDirty}
                  isFormDirty={isFormDirty}
                  touched={touched}
                  isRequired
                  minNum="0"
                  onChange={setFieldValue}
                  values={
                    isDecimalNumber(formikValues["calcTotalSalesPrice"])
                      ? parseFloat(
                          formikValues["calcTotalSalesPrice"]
                        )?.toFixed(2)
                      : formikValues["calcTotalSalesPrice"]
                  }
                  disable={true}
                  avg={true}
                  noBg={true}
                  isDisabled={true}
                  isThousand={true}
                  setFieldTouched={setFieldTouched}
                  onBlur={handleBlur}
                  directVal={true}
                />
              </Box>
            </Box>
            <Divider
              borderColor="#EFEFEF"
              border="2px"
              orientation="vertical"
            />
            <Box width="20%">
              <Text
                fontSize="15px"
                color="#003865"
                fontWeight="400"
                letterSpacing="0.04em"
                textAlign="left"
              >
                Total Number <br />
                of Keys
              </Text>
              <Box className="calc_input">
                <InputFieldValidation
                  placeholder="0"
                  label=""
                  inputType="number"
                  inputId="calcTotalNumberOfKeys"
                  inputName="calcTotalNumberOfKeys"
                  errors={errors}
                  setIsFormDirty={setIsFormDirty}
                  isFormDirty={isFormDirty}
                  touched={touched}
                  isRequired
                  minNum="0"
                  onChange={setFieldValue}
                  values={
                    isDecimalNumber(formikValues["calcTotalNumberOfKeys"])
                      ? parseFloat(
                          formikValues["calcTotalNumberOfKeys"]
                        )?.toFixed(2)
                      : formikValues["calcTotalNumberOfKeys"]
                  }
                  disable={true}
                  avg={true}
                  noBg={true}
                  isDisabled={true}
                  isThousand={true}
                  setFieldTouched={setFieldTouched}
                  onBlur={handleBlur}
                  directVal={true}
                />
              </Box>
            </Box>

            <Divider
              borderColor="#EFEFEF"
              border="2px"
              orientation="vertical"
            />
            <Box width="20%">
              <Text
                fontSize="15px"
                color="#003865"
                fontWeight="400"
                letterSpacing="0.04em"
                textAlign="left"
              >
                Total Sellable <br /> GLA (SQM)
              </Text>
              <Box className="calc_input">
                <InputFieldValidation
                  placeholder="0"
                  label=""
                  inputType="number"
                  inputId="calcTotalSellableGla"
                  inputName="calcTotalSellableGla"
                  errors={errors}
                  setIsFormDirty={setIsFormDirty}
                  isFormDirty={isFormDirty}
                  touched={touched}
                  isRequired
                  minNum="0"
                  onChange={setFieldValue}
                  values={
                    isDecimalNumber(formikValues["calcTotalSellableGla"])
                      ? parseFloat(
                          formikValues["calcTotalSellableGla"]
                        )?.toFixed(2)
                      : formikValues["calcTotalSellableGla"]
                  }
                  disable={true}
                  avg={true}
                  noBg={true}
                  isDisabled={true}
                  isThousand={true}
                  setFieldTouched={setFieldTouched}
                  onBlur={handleBlur}
                  directVal={true}
                />
              </Box>
            </Box>
          </Stack>
        </Box>
        <Box
          pt="10"
          maxW={["100%", "100%", "65%"]}
          width={["100%", "100%", "65%"]}
          alignSelf="flex-start"
        >
          <InputFieldCalc
            label="GFA Efficiency"
            inputType="number"
            inputId="Efficiency"
            inputName="Efficiency"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            icon={percent}
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore || formikValues?.brandedResidence == "2"}
            validateField={validateStep}
            calculatedOcc={true}
            onChangeCalc={handleCalcOcc}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />
          <Box pt="5">
            <Text
              fontSize="18px"
              color="#003865"
              fontWeight="400"
              letterSpacing="0.04em"
              textAlign="left"
            >
              Total GFA Branded Residences{" "}
            </Text>
          </Box>

          <Box className="calc_input">
            <InputFieldValidation
              placeholder="0"
              label=""
              inputType="number"
              inputId="calcTotalGfaBrandedRes"
              inputName="calcTotalGfaBrandedRes"
              errors={errors}
              setIsFormDirty={setIsFormDirty}
              isFormDirty={isFormDirty}
              touched={touched}
              isRequired
              minNum="0"
              onChange={setFieldValue}
              values={
                isDecimalNumber(formikValues["calcTotalGfaBrandedRes"])
                  ? parseFloat(formikValues["calcTotalGfaBrandedRes"])?.toFixed(
                      2
                    )
                  : formikValues["calcTotalGfaBrandedRes"]
              }
              disable={true}
              avg={true}
              noBg={true}
              isDisabled={true}
              directVal={true}
            />
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Text
              fontSize="12px"
              color="black"
              fontWeight="400"
              letterSpacing="0.04em"
              textAlign="left"
              textTransform="uppercase"
              mt={5}
              mb={3}
            >
              Annual Sales Absorption{" "}
            </Text>
            {renderAnnualSalesFields()}
            {annualSalesError && (
              <GridItem colSpan={4}>
                <Text
                  color="#e14642"
                  fontWeight="400"
                  fontSize="10px"
                  lineHeight="17px"
                  letterSpacing="0.04em"
                >
                  The summation of all years should be equal to 100
                </Text>
              </GridItem>
            )}
          </Box>

          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={5}
            component="div"
          >
            FEES AND CONTRIBUTION
          </Text>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
              <GridItem colSpan={4}>
                <SimpleGrid
                  columns={{ sm: 4, md: 4 }}
                  spacing="15px"
                  width="100%"
                >
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="OPERATOR CONTRIBUTIONS (SALE)"
                      inputType="number"
                      inputId="opreatorContributions"
                      inputName="opreatorContributions"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      icon={percent}
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={
                        isRestore || formikValues?.brandedResidence == "2"
                      }
                      validateField={validateStep}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="ADMIN EXPENSES (SALE)"
                      inputType="number"
                      inputId="adminExpenses"
                      inputName="adminExpenses"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      icon={percent}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={
                        isRestore || formikValues?.brandedResidence == "2"
                      }
                      validateField={validateStep}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="Marketing and Selling Cost "
                      inputType="number"
                      inputId="marketingSellingCost"
                      inputName="marketingSellingCost"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      icon={percent}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={
                        isRestore || formikValues?.brandedResidence == "2"
                      }
                      validateField={validateStep}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="Residential Sale and Marketing Advisory Fee "
                      inputType="number"
                      inputId="residentialSaleFee"
                      inputName="residentialSaleFee"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      leftIcon={true}
                      leftIconText="SAR"
                      isDisabled={
                        isRestore || formikValues?.brandedResidence == "2"
                      }
                      validateField={validateStep}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                      onBlur={handleBlur}
                    />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="Residential Consultancy Services Fee "
                      inputType="number"
                      inputId="residentialConsultancyFee"
                      inputName="residentialConsultancyFee"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      leftIcon={true}
                      leftIconText="SAR"
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={
                        isRestore || formikValues?.brandedResidence == "2"
                      }
                      validateField={validateStep}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                      onBlur={handleBlur}
                    />
                  </GridItem>

                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="ADMIN CHARGES TO OWNER (Yearly % of Total Profit)"
                      inputType="number"
                      inputId="adminCharges"
                      inputName="adminCharges"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      icon={percent}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={
                        isRestore || formikValues?.brandedResidence == "2"
                      }
                      validateField={validateStep}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="Profit SHARE OF RESIDENCE OWNER"
                      inputType="number"
                      inputId="shareOfResidence"
                      inputName="shareOfResidence"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      icon={percent}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={
                        isRestore || formikValues?.brandedResidence == "2"
                      }
                      validateField={validateStep}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Text
                      className="title_md"
                      display="flex"
                      mb={3}
                      mt={4}
                      component="div"
                    >
                      MINIMUM RESIDENCE OWNER'S RETURN (per year on total sales
                      price)
                    </Text>

                    <RadioGroup
                      onChange={(event) =>
                        handleChangeRadio(event, "minimumResidenceRadio")
                      }
                      value={`${formikValues?.minimumResidenceRadio}`}
                      className="radio_group_custom"
                      mb={1}
                      name="minimumResidenceRadio"
                      isDisabled={
                        isRestore || formikValues?.brandedResidence == "2"
                      }
                      validateField={validateStep}
                    >
                      <Stack direction="row">
                        <Radio
                          color="#003865 !important"
                          bg="none !important"
                          borderColor="#6F767E !important"
                          value="1"
                        >
                          Yes
                        </Radio>
                        <Radio
                          color="#003865 !important"
                          bg="none !important"
                          borderColor="#6F767E !important"
                          value="2"
                        >
                          No
                        </Radio>
                      </Stack>
                      {!formikValues?.minimumResidenceRadio && validateStep && (
                        <Box
                          color="#e14642"
                          fontWeight="400"
                          fontSize="10px"
                          lineHeight="17px"
                          letterSpacing="0.04em"
                          marginTop="5px"
                        >
                          Required
                        </Box>
                      )}
                    </RadioGroup>
                    <InputFieldValidation
                      inputType="number"
                      inputId="minimumResidence"
                      inputName="minimumResidence"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      icon={percent}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={
                        isRestore ||
                        formikValues?.minimumResidenceRadio == "2" ||
                        formikValues?.brandedResidence == "2"
                      }
                      validateField={validateStep}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="Final Asset Sale - Value Increase "
                      inputType="number"
                      inputId="finalAssetSale"
                      inputName="finalAssetSale"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      icon={percent}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={
                        isRestore || formikValues?.brandedResidence == "2"
                      }
                      validateField={validateStep}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>

                  <GridItem colSpan={4}>
                    <Text className="title_md" display="flex" component="div">
                      Development Cost Charged to Owner{" "}
                    </Text>
                    <Text
                      className="title_sm"
                      display="flex"
                      mb={2}
                      mt={3}
                      component="div"
                      letterSpacing="0.06em"
                      fontSize="12px"
                      textTransform="uppercase"
                    >
                      FF&E & OS&E
                    </Text>

                    <RadioGroup
                      onChange={(event) =>
                        handleChangeRadio(event, "ffeAndOse")
                      }
                      value={`${formikValues?.ffeAndOse}`}
                      className="radio_group_custom"
                      mb={4}
                      name="ffeAndOse"
                      isDisabled={
                        isRestore || formikValues?.brandedResidence == "2"
                      }
                      validateField={validateStep}
                    >
                      <Stack direction="row">
                        <Radio
                          color="#003865 !important"
                          bg="none !important"
                          borderColor="#6F767E !important"
                          value="1"
                        >
                          Yes
                        </Radio>
                        <Radio
                          color="#003865 !important"
                          bg="none !important"
                          borderColor="#6F767E !important"
                          value="2"
                        >
                          No
                        </Radio>
                      </Stack>
                      {!formikValues?.ffeAndOse && validateStep && (
                        <Box
                          color="#e14642"
                          fontWeight="400"
                          fontSize="10px"
                          lineHeight="17px"
                          letterSpacing="0.04em"
                          marginTop="5px"
                        >
                          Required
                        </Box>
                      )}
                    </RadioGroup>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Text
                      className="title_sm"
                      display="flex"
                      mb={2}
                      mt={3}
                      component="div"
                      letterSpacing="0.06em"
                      fontSize="12px"
                      textTransform="uppercase"
                    >
                      Soft Costs (Professional Fees)
                    </Text>

                    <RadioGroup
                      onChange={(event) =>
                        handleChangeRadio(event, "softCosts")
                      }
                      value={`${formikValues?.softCosts}`}
                      className="radio_group_custom"
                      mb={4}
                      name="softCosts"
                      isDisabled={
                        isRestore || formikValues?.brandedResidence == "2"
                      }
                      validateField={validateStep}
                    >
                      <Stack direction="row">
                        <Radio
                          color="#003865 !important"
                          bg="none !important"
                          borderColor="#6F767E !important"
                          value="1"
                        >
                          Yes
                        </Radio>
                        <Radio
                          color="#003865 !important"
                          bg="none !important"
                          borderColor="#6F767E !important"
                          value="2"
                        >
                          No
                        </Radio>
                      </Stack>
                      {!formikValues?.softCosts && validateStep && (
                        <Box
                          color="#e14642"
                          fontWeight="400"
                          fontSize="10px"
                          lineHeight="17px"
                          letterSpacing="0.04em"
                          marginTop="5px"
                        >
                          Required
                        </Box>
                      )}
                    </RadioGroup>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Text
                      className="title_sm"
                      display="flex"
                      mb={2}
                      mt={3}
                      component="div"
                      letterSpacing="0.06em"
                      fontSize="12px"
                      textTransform="uppercase"
                    >
                      Pre-Opening Training & Marketing
                    </Text>

                    <RadioGroup
                      onChange={(event) =>
                        handleChangeRadio(event, "preOpTraining")
                      }
                      value={`${formikValues?.preOpTraining}`}
                      className="radio_group_custom"
                      mb={4}
                      name="preOpTraining"
                      isDisabled={
                        isRestore || formikValues?.brandedResidence == "2"
                      }
                      validateField={validateStep}
                    >
                      <Stack direction="row">
                        <Radio
                          color="#003865 !important"
                          bg="none !important"
                          borderColor="#6F767E !important"
                          value="1"
                        >
                          Yes
                        </Radio>
                        <Radio
                          color="#003865 !important"
                          bg="none !important"
                          borderColor="#6F767E !important"
                          value="2"
                        >
                          No
                        </Radio>
                      </Stack>
                      {!formikValues?.preOpTraining && validateStep && (
                        <Box
                          color="#e14642"
                          fontWeight="400"
                          fontSize="10px"
                          lineHeight="17px"
                          letterSpacing="0.04em"
                          marginTop="5px"
                        >
                          Required
                        </Box>
                      )}
                    </RadioGroup>
                  </GridItem>
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          </Box>
        </Box>
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex justifyContent={user == "manager" ? "end" : "space-between"} alignItems="center" width="100%">
        {
          user !== "manager" ?
            <MainButton
              title="Save Draft"
              btnStyle="transparent"
              disabledBtn={isRestore}
              fontColor="dark"
              onPress={() => {
                setDismissSave(false);
                onDraftOpen();
              }}
            /> : <></>
        }
        <Box display="flex" gap="15px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {/* <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm ? true : false}
              onPress={() => {
                nextPage();
                setStepCompleted(page);
                unsetStepWarning(page);
                if (percentage < 91) {
                  setPercentage(91);
                }
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  if (updateDisableNextStep || nextStep.clicked === false) {
                    nextPage(2);
                  } else {
                    nextPage();
                  }
                  setStepCompleted(page);
                  unsetStepWarning(page);
                  if (percentage < 91) {
                    setPercentage(91);
                  }
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
