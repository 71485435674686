import React, { useState, useEffect, useRef } from "react";

import {
  GetVisitorNumAssumptionsByIdAPI,
  AddVisitorNumAssumptionsAPI,
  GetVisitorNumAssumptionsDraftByIdAPI,
} from "../../api/VisitorNumAssumptionsAPI";
import { GetProductionMasterDataWithIsActive } from "../../api/MasterDataAPI";

import { NavLink, useLocation } from "react-router-dom";

import history from "../../history";

import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";
import { useParams } from "react-router-dom";

import { Formik, Form, FieldArray, Field } from "formik";
import {
  Box,
  Text,
  useDisclosure,
  HStack,
  Badge,
  Spacer,
  Divider,
  Flex,
  SimpleGrid,
  GridItem,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Tooltip,
  useToast,
  Center,
  Spinner,
} from "@chakra-ui/react";

import { activeMenuItem } from "../../utils/General";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { MainButton } from "../../components/button/MainButton";
import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { TextArea } from "../../components/textArea/TextArea";
import { validationVisAssumptionsForm } from "./validationVisAssumptionsForm";

import deleteIcon2 from "../../assets/images/icons/delete2.svg";
import plus2 from "../../assets/images/icons/plus2.svg";
import checked from "../../assets/images/icons/checked.svg";
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import info from "../../assets/images/icons/Subtract.svg";
import { ToastAlert } from "../../components/toastAlert/ToastAlert";

export const VisitorNumAssumptions = () => {
  let location = useLocation();
  const [isRestore, setIsRestore] = useState(false);
  const accordWrapper = useRef(null);
  const activeMenu = activeMenuItem();
  const { id, draftId } = useParams();
  let counterMas = 0;
  let currDate;
  let currDateAvg;
  let currDateGus;
  const accordRef = useRef(null);
  const [isClear, setIsClear] = useState(false);
  const [initFormVal, setInitFormVal] = useState({});
  const [visitorData, setVisitorData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState("");
  const [isDisabledForm, setIsDisabledForm] = useState(true);
  const [masterDataRegion, setMasterDataRegion] = useState([]);
  const [regions, setRegions] = useState([]);

  const [validateStep, setValidateStep] = useState(false);
  const [modelWarningOpen, isModelWarningOpen] = useState(false);
  const toast = useToast();

  let date;
  let lastMod;
  let submitModalTitle =
    "Visitor number assumptions form has been successfully created.";

  if (!!visitorData.lastModifiedAt) {
    date = dateFnsParseISO(visitorData.lastModifiedAt);
    lastMod = dateFnsFormat(date, "dd.MM.yyyy; HH:mm");
  }

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();
  const {
    isOpen: isDraftOpen,
    onOpen: onDraftOpen,
    onClose: onDraftClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const toggleButton = (event, type) => {
    //TOGGLE ADD COMMENT BUTTON
    if (type === "add") {
      event.target.parentElement.children[0].style.display = "none";
      event.target.parentElement.children[1].style.display = "flex";
    } else if (type === "remove") {
      event.target.parentElement.parentElement.children[0].style.display =
        "flex";
      event.target.parentElement.parentElement.children[1].style.display =
        "none";
    }
  };
  const clearInput = (event, formikValues) => {
    //AFTER CLOSE ADD COMMENT FIELD
    let textareaField =
      event.target.parentElement.firstChild?.firstChild?.children[1]
        ?.firstChild;
    let textareaIndex = textareaField.name.split(".")[1];

    if (textareaField) {
      formikValues.visitorNumbers[textareaIndex].note = "";
      textareaField.value = "";
      setInitFormVal(formikValues);
      setIsClear(!isClear);
    }
  };

  const handleOnSubmit = (formikValue, isRestore) => {
    if (!!id) {
      let visitorDataParsed = JSON.parse(visitorData.formFields);

      let commentsLogic =
        visitorDataParsed.comments === formikValue.comments
          ? ""
          : formikValue.comments;

      const submitValues = {
        ...formikValue,
        isDraft: false,
        isRestore: !!isRestore,
        comments: commentsLogic,
        startYear: "2025",
        parentVersionNumber:
          visitorData.versionNumber || visitorData.parentVersionNumber,
      };

      AddVisitorNumAssumptionsAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setIsDisabledForm(true);
            onErrorOpen();
            onSubmitClose();
          } else if (res.success === true) {
            setIsDisabledForm(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res.data;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      const submitValues = {
        ...formikValue,
        isDraft: false,
        startYear: "2025",
        parentVersionNumber: -1,
      };

      //ADD VISITOR NUMBER ASSUMPTION VERSION
      AddVisitorNumAssumptionsAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setIsDisabledForm(true);
            onErrorOpen();
            onSubmitClose();
          } else if (res.success === true) {
            setIsDisabledForm(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res.data;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };
  const handleSaveDraft = (formikValue) => {
    if (!!id) {
      const submitValues = {
        ...formikValue,
        isDraft: true,
        versionNumber: !!draftId ? Number(draftId) : 0,
        parentVersionNumber: Number(id),
        startYear: "2025",
      };

      AddVisitorNumAssumptionsAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setIsDisabledForm(true);
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            setErrorMessage("");
            setIsDisabledForm(true);
            onDraftClose();
            onSubmitOpen();
          }
          return res.data;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      const submitValues = {
        ...formikValue,
        isDraft: true,
        startYear: `${new Date().getFullYear()}`,
      };

      AddVisitorNumAssumptionsAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setIsDisabledForm(true);
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            setErrorMessage("");
            setIsDisabledForm(true);
            onDraftClose();
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };
  const closeToast = () => {
    toast.closeAll();
  };

  const handleAccordErrors = (errors) => {
    let openHigherAccord = true;
    console.log("errors", errors);
    if (Object.keys(errors)?.length > 0) {
      errors[`${Object.keys(errors)[0]}`].forEach((el, ind) => {
        if (!!el) {
          if (openHigherAccord) {
            openHigherAccord = false;
            handleOpenAccord(ind);
          }
        }
      });
    }
  };

  const handleOpenAccord = (accordIndex) => {
    if (
      accordRef.current.querySelectorAll(
        ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
      )[accordIndex].ariaExpanded === "false"
    ) {
      accordRef.current
        .querySelectorAll(
          ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
        )
        [accordIndex].click();
    }
  };
  useEffect(() => {
    if (modelWarningOpen) {
      toast({
        position: "top-right",
        isClosable: true,
        render: () => (
          <ToastAlert
            title="You must fill all the fields."
            icon={infoCircle}
            closeToast={closeToast}
            type="Warning"
          />
        ),
      });
    }
    isModelWarningOpen(false);
  }, [modelWarningOpen]);

  useEffect(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    let formField;
    if (!!id && !!draftId) {
      let newInitData = [];
      let newInitDataNames = [];
      setErrorMessage("");
      GetProductionMasterDataWithIsActive()
        .then((res) => {
          if (res.data.length > 0) {
            let regionProd = res.data[0];
            if (regionProd.status === "Production") {
              if (regionProd.lookups.length > 0) {
                regionProd.lookups.forEach((el) => {
                  newInitData = [
                    ...newInitData,
                    {
                      regionId: el.value,
                      note: "",
                      numbers: Array(24).fill(""),
                      averageNumbers: Array(8).fill(""),
                      guestsNumbers: Array(8).fill(""),
                    },
                  ];

                  newInitDataNames = [
                    ...newInitDataNames,
                    {
                      regionName: el.label,
                      isActive: el.isActive,
                      regionId: el.value,
                      note: "",
                      numbers: Array(24).fill(""),
                      averageNumbers: Array(8).fill(""),
                      guestsNumbers: Array(8).fill(""),
                    },
                  ];
                });

                setRegions(newInitDataNames);

                return {
                  visitorNumbers: newInitData,
                };
              }
            }
          } else {
            setIsLoadingText("You should add a regions");
          }
        })
        .then((regionObject) => {
          GetVisitorNumAssumptionsDraftByIdAPI(id, draftId)
            .then((res) => {
              formField = JSON.parse(res.data.formFields);

              let findAdded = [];
              let newVisitorArr = regionObject.visitorNumbers.filter(
                (object1) => {
                  return !formField.visitorNumbers.some((object2, index) => {
                    if (object1.regionId === object2.regionId)
                      findAdded.push(index);
                    return object1.regionId === object2.regionId;
                  });
                }
              );

              let findAddedInd = [];
              regionObject.visitorNumbers.map((object1, index) => {
                newVisitorArr.map((object2) => {
                  if (object1.regionId === object2.regionId) {
                    findAddedInd.push({
                      index: index,
                      regionId: object2.regionId,
                      numbers: object2.numbers,
                      averageNumbers: object2.averageNumbers,
                      guestsNumbers: object2.guestsNumbers,
                      note: object2.note,
                    });
                  }
                });
              });

              let finalVisitorAssumpArr = [...formField.visitorNumbers];
              if (findAddedInd.length >= 1) {
                findAddedInd.map((item) => {
                  newVisitorArr.map((regObj) => {
                    if (
                      regionObject.visitorNumbers[item.index].regionId ===
                      regObj.regionId
                    ) {
                      finalVisitorAssumpArr.splice(item.index, 0, regObj);
                    }
                  });
                });

                let reOrderArr = [];
                regionObject.visitorNumbers.map((object1) => {
                  finalVisitorAssumpArr.map((object2) => {
                    if (object1.regionId === object2.regionId) {
                      reOrderArr.push(object2);
                    }
                  });
                });

                setMasterDataRegion([...reOrderArr]);
                setInitFormVal({
                  comments: res.data.comments,
                  visitorNumbers: [...reOrderArr],
                });
              } else {
                //if there is no row added but just change the order
                let reOrderArr = [];
                regionObject.visitorNumbers.map((object1) => {
                  finalVisitorAssumpArr.map((object2) => {
                    if (object1.regionId === object2.regionId) {
                      reOrderArr.push(object2);
                    }
                  });
                });

                setMasterDataRegion([...reOrderArr]);
                setInitFormVal({
                  comments: res.data.comments,
                  visitorNumbers: [...reOrderArr],
                });
              }

              setIsLoading(false);
              if (res.data.status != "Production") {
                setIsDisabledForm(false);
              }
              setVisitorData(res.data);
            })
            .catch((err) => {
              setErrorMessage("Something went wrong");
              onErrorOpen();
            });
        })
        .catch((err) => {
          setErrorMessage("You should add a region");
          onErrorOpen();
        });
    } else if (!!id) {
      let newInitData = [];
      let newInitDataNames = [];
      setErrorMessage("");
      GetProductionMasterDataWithIsActive()
        .then((res) => {
          if (res.data.length > 0) {
            let regionProd = res.data[0];
            if (regionProd.status === "Production") {
              if (regionProd.lookups.length > 0) {
                regionProd.lookups.forEach((el) => {
                  newInitData = [
                    ...newInitData,
                    {
                      regionId: el.value,
                      note: "",
                      numbers: Array(24).fill(""),
                      averageNumbers: Array(8).fill(""),
                      guestsNumbers: Array(8).fill(""),
                    },
                  ];
                  newInitDataNames = [
                    ...newInitDataNames,
                    {
                      regionName: el.label,
                      isActive: el.isActive,
                      regionId: el.value,
                      note: "",
                      numbers: Array(24).fill(""),
                      averageNumbers: Array(8).fill(""),
                      guestsNumbers: Array(8).fill(""),
                    },
                  ];
                });

                setRegions(newInitDataNames);
                return {
                  comments: "",
                  visitorNumbers: newInitData,
                };
              }
            }
          } else {
            setIsLoadingText("You should add a regions");
          }
        })
        .then((regionObject) => {
          GetVisitorNumAssumptionsByIdAPI(id)
            .then((res) => {
              formField = JSON.parse(res.data.formFields);

              let findAdded = [];
              let newVisitorArr = regionObject.visitorNumbers.filter(
                (object1) => {
                  return !formField.visitorNumbers.some((object2, index) => {
                    if (object1.regionId === object2.regionId)
                      findAdded.push(index);
                    return object1.regionId === object2.regionId;
                  });
                }
              );

              let findAddedInd = [];
              regionObject.visitorNumbers.map((object1, index) => {
                newVisitorArr.map((object2) => {
                  if (object1.regionId === object2.regionId) {
                    findAddedInd.push({
                      index: index,
                      regionId: object2.regionId,
                      numbers: object2.numbers,
                      averageNumbers: object2.averageNumbers,
                      guestsNumbers: object2.guestsNumbers,
                      note: object2.note,
                    });
                  }
                });
              });

              let finalVisitorAssumpArr = [...formField.visitorNumbers];
              if (findAddedInd.length >= 1) {
                findAddedInd.map((item) => {
                  newVisitorArr.map((regObj) => {
                    if (
                      regionObject.visitorNumbers[item.index].regionId ===
                      regObj.regionId
                    ) {
                      finalVisitorAssumpArr.splice(item.index, 0, regObj);
                    }
                  });
                });

                let reOrderArr = [];
                regionObject.visitorNumbers.map((object1) => {
                  finalVisitorAssumpArr.map((object2) => {
                    if (object1.regionId === object2.regionId) {
                      reOrderArr.push(object2);
                    }
                  });
                });

                setMasterDataRegion([...reOrderArr]);
                setInitFormVal({
                  comments: res.data.comments,
                  visitorNumbers: [...reOrderArr],
                });
              } else {
                //if there is no row added but just change the order
                let reOrderArr = [];
                regionObject.visitorNumbers.map((object1) => {
                  finalVisitorAssumpArr.map((object2) => {
                    if (object1.regionId === object2.regionId) {
                      reOrderArr.push(object2);
                    }
                  });
                });

                setMasterDataRegion([...reOrderArr]);
                setInitFormVal({
                  comments: res.data.comments,
                  visitorNumbers: [...reOrderArr],
                });
              }
              setIsLoading(false);
              if (res.data.status != "Production") {
                setIsDisabledForm(false);
              }

              setVisitorData(res.data);
            })
            .catch((err) => {
              setErrorMessage("Something went wrong");
              onErrorOpen();
            });
        })
        .catch((err) => {
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    } else {
      let newInitData = [];
      let newInitDataNames = [];
      GetProductionMasterDataWithIsActive()
        .then((res) => {
          if (res.data.length > 0) {
            let regionProd = res.data[0];
            if (regionProd.status === "Production") {
              if (regionProd.lookups.length > 0) {
                regionProd.lookups.forEach((el) => {
                  newInitData = [
                    ...newInitData,
                    {
                      regionId: el.value,
                      note: "",
                      numbers: Array(24).fill(""),
                      averageNumbers: Array(8).fill(""),
                      guestsNumbers: Array(8).fill(""),
                    },
                  ];
                  newInitDataNames = [
                    ...newInitDataNames,
                    {
                      regionName: el.label,
                      isActive: el.isActive,
                      regionId: el.value,
                      note: "",
                      numbers: Array(24).fill(""),
                      averageNumbers: Array(8).fill(""),
                      guestsNumbers: Array(8).fill(""),
                    },
                  ];
                });

                setRegions(newInitDataNames);
                setIsLoading(false);
                setMasterDataRegion(regionProd.lookups);
                setInitFormVal({
                  comments: "",
                  visitorNumbers: newInitData,
                });
              }
            }
          } else {
            setIsLoadingText("You should add a regions");
          }
        })
        .catch((err) => {});
    }
    if (location?.state?.from === "Restore") {
      setIsRestore(true);
      setIsDisabledForm(false);
    }
  }, []);

  useEffect(() => {
    let unblock;
    if (!isDisabledForm) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`Are you sure you want to discard the changes?`)) {
          unblock();

          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [isDisabledForm]);
  useEffect(() => {
    if (JSON.stringify(initFormVal) !== "{}") {
      initFormVal.visitorNumbers.forEach((item, index) => {
        if (item.note !== "") {
          accordWrapper.current
            .querySelectorAll(".accordion_item")
            [index].querySelector(".btn_add")
            .click();
        }
      });
    }
  }, [initFormVal]);
  counterMas = counterMas + 1;
  currDate = parseInt(new Date().toJSON().slice(0, 4));
  currDate = currDate - 1;
  currDateGus = parseInt(new Date().toJSON().slice(0, 4));
  currDateGus = currDateGus - 1;
  currDateAvg = parseInt(new Date().toJSON().slice(0, 4));
  currDateAvg = currDateAvg - 1;
  // console.log("isDisabledForm", isDisabledForm);
  return (
    <div>
      {id === undefined ? (
        <MainTitle title="Create Visitor number assumptions" />
      ) : (
        <MainTitle title="Edit Visitor number assumptions" />
      )}

      {isLoadingText !== "" ? (
        <Badge bg="#EBC03F26" className="masterDataBadge" width="100%">
          <HStack p="1">
            <Tooltip
              aria-label="A tooltip"
              bg="#f4f4ed"
              color="#1A1D1F"
              borderRadius="5px"
              border="1px solid #f4f4ed"
              hasArrow
              placement="right"
              px={5}
              py={2}
            >
              <img src={info} alt="tooltip icon" />
            </Tooltip>
            <Box fontSize={{ lg: 12, "2xl": 15 }}>
              Ensure that you create a&nbsp;
              <NavLink
                style={{
                  color: "#007BB5",
                  textDecoration: "underline",
                }}
                to={`/MasterDataVersions/Regions`}
              >
                Region
              </NavLink>{" "}
              to add a Visitor Number Assumption
              {"."}
            </Box>
          </HStack>
        </Badge>
      ) : (
        <>
          {isLoading ? (
            <Box paddingTop={28} paddingBottom={12}>
              <Center>
                <Spinner size={"xl"} />
              </Center>
            </Box>
          ) : (
            <MainBox>
              <Box className="table_content" pt={6} pb={6}>
                <Box display="flex" alignItems="center" pb={8}>
                  <Box>
                    <MainTitle pb="0" pt="0" title="Expected visitors" />
                  </Box>
                  {id !== undefined && (
                    <HStack w="80%" px="8">
                      <Badge
                        bg="rgba(0, 123, 181, 0.2)"
                        className="masterDataBadge"
                      >
                        Version Number:{" "}
                        {visitorData.status === "Draft" ||
                        visitorData.status === "InitialDraft"
                          ? visitorData.parentVersionNumber
                          : visitorData.versionNumber}
                      </Badge>
                      <Badge
                        bg="rgba(241, 136, 37, 0.3)"
                        className="masterDataBadge"
                      >
                        Last Modified By: {visitorData.lastModifiedBy},{" "}
                        {lastMod}
                      </Badge>

                      {/* IF NOT SARA SHOW VIEW ONLY */}
                      <Spacer />
                      {/* <HStack
                            px="2"
                            py="1"
                            borderRadius="5"
                            fontSize="12"
                            bg="rgba(0, 107, 68, 0.2);"
                          >
                            <Image src={eye}></Image>
                            <Text>View Only</Text>
                          </HStack> */}
                    </HStack>
                  )}
                </Box>

                <Formik
                  initialValues={initFormVal}
                  enableReinitialize={true}
                  validationSchema={validationVisAssumptionsForm[0]}
                  validate={(values) => {
                    let form = values.visitorNumbers;
                    let formLength = [];
                    values.visitorNumbers.forEach((el) => {
                      return el.numbers.forEach((num) => formLength.push(num));
                    });
                    values.visitorNumbers.forEach((el) => {
                      return el.averageNumbers.forEach((num) =>
                        formLength.push(num)
                      );
                    });
                    values.visitorNumbers.forEach((el) => {
                      return el.guestsNumbers.forEach((num) =>
                        formLength.push(num)
                      );
                    });
                    let emptyform = [];
                    if (form.length > 0) {
                      form.forEach((item) => {
                        item.numbers.forEach((el) => {
                          if (el !== "" && !isNaN(`${el}`) && el >= 0) {
                            emptyform.push(el);
                          }
                        });
                        item.averageNumbers.forEach((el) => {
                          if (el !== "" && !isNaN(`${el}`) && el >= 0) {
                            emptyform.push(el);
                          }
                        });
                        item.guestsNumbers.forEach((el) => {
                          if (el !== "" && !isNaN(`${el}`) && el >= 0) {
                            emptyform.push(el);
                          }
                        });
                      });
                    }
                    if (formLength.length === emptyform.length) {
                      setIsDisabledForm(false);
                    } else {
                      setIsDisabledForm(true);
                    }
                  }}
                  onSubmit={(values) => {
                    setTimeout(() => {
                      alert(JSON.stringify(values, null, 2));
                    }, 400);
                  }}
                >
                  {({
                    errors,
                    touched,
                    values,
                    handleBlur,
                    setFieldValue,
                    dirty,
                    setFieldTouched,
                  }) => (
                    <Form className="form_wrapper">
                      <>
                        {counterMas <= masterDataRegion.length && (
                          <FieldArray
                            name="visitorNumbers"
                            render={(arrayHelper) => (
                              <>
                                <Box
                                  width="100%"
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                  ref={accordWrapper}
                                >
                                  <Accordion
                                    width="100%"
                                    allowToggle
                                    color="black"
                                    defaultIndex={[0]}
                                    ref={accordRef}
                                  >
                                    {values.visitorNumbers !== undefined &&
                                      values.visitorNumbers.map(
                                        (regione, index) => {
                                          let regName = regions.find((el) => {
                                            if (
                                              masterDataRegion[index]?.regionId
                                            ) {
                                              return (
                                                el.regionId ===
                                                masterDataRegion[index].regionId
                                              );
                                            } else {
                                              return (
                                                el.regionId ===
                                                masterDataRegion[index].value
                                              );
                                            }
                                          });
                                          let regionTitle = regName?.regionName
                                            ? regName.regionName
                                            : masterDataRegion[index].label;
                                          let isActiveReg = regName?.isActive;
                                          currDate = parseInt(
                                            new Date("2024")
                                              .toJSON()
                                              .slice(0, 4)
                                          );
                                          currDateAvg = parseInt(
                                            new Date("2024")
                                              .toJSON()
                                              .slice(0, 4)
                                          );
                                          currDateGus = parseInt(
                                            new Date("2024")
                                              .toJSON()
                                              .slice(0, 4)
                                          );
                                          currDate = currDate;
                                          currDateAvg = currDateAvg;
                                          currDateGus = currDateGus;
                                          return (
                                            <AccordionItem
                                              mb="4"
                                              borderBottom="none"
                                              key={`accord_${index}`}
                                              className="accordion_item"
                                            >
                                              <Heading
                                                borderLeft="1px"
                                                borderBottom="1px"
                                                borderRight="1px"
                                                borderColor="#E6E6E6"
                                              >
                                                <AccordionButton bg="#FCFCFC">
                                                  <Box
                                                    flex="1"
                                                    textAlign="left"
                                                  >
                                                    <Text
                                                      p="1"
                                                      pb="1"
                                                      pt="1"
                                                      fontSize="15px"
                                                      fontWeight="400"
                                                      letterSpacing="0.04em"
                                                      textTransform="uppercase"
                                                    >
                                                      {isActiveReg ? (
                                                        regionTitle
                                                      ) : (
                                                        <Box
                                                          color="#6F767E"
                                                          display="flex"
                                                        >
                                                          {regionTitle}
                                                          <Text
                                                            color="#6F767E"
                                                            textTransform="lowercase"
                                                            ml={2}
                                                          >
                                                            {`(inactive region)`}
                                                          </Text>
                                                        </Box>
                                                      )}
                                                    </Text>
                                                  </Box>
                                                  <AccordionIcon />
                                                </AccordionButton>
                                              </Heading>

                                              <AccordionPanel
                                                pb={4}
                                                pt={6}
                                                border="1px"
                                                borderColor="#E6E6E6"
                                              >
                                                <>
                                                  <GridItem
                                                    colSpan={2}
                                                    width="85%"
                                                  >
                                                    <SimpleGrid
                                                      columns={{
                                                        sm: 2,
                                                        md: 6,
                                                      }}
                                                      spacingX="15px"
                                                      width="100%"
                                                    >
                                                      <Box
                                                        className="grid_full"
                                                        mt={0}
                                                        mb={3}
                                                      >
                                                        <Text
                                                          className="title_sm  "
                                                          textTransform="capitalize"
                                                        >
                                                          Number of unique
                                                          overnight visitors
                                                        </Text>
                                                      </Box>

                                                      {regione.numbers.map(
                                                        (_, ind) => {
                                                          currDate =
                                                            currDate + 1;
                                                          return (
                                                            <GridItem
                                                              colSpan={1}
                                                              key={`region_${ind}`}
                                                            >
                                                              <InputFieldValidation
                                                                label={`Year ${currDate}`}
                                                                placeholder="0"
                                                                inputType="number"
                                                                inputId={`visitorNumbers.${index}.numbers.${ind}`}
                                                                inputName={`visitorNumbers.${index}.numbers.${ind}`}
                                                                errors={errors}
                                                                touched={
                                                                  touched
                                                                }
                                                                isRequired
                                                                onChange={
                                                                  setFieldValue
                                                                }
                                                                values={
                                                                  values
                                                                    ?.visitorNumbers[
                                                                    index
                                                                  ]?.numbers[
                                                                    ind
                                                                  ]
                                                                }
                                                                labelCenter={
                                                                  true
                                                                }
                                                                errorHeight0={
                                                                  true
                                                                }
                                                                uppercase={
                                                                  false
                                                                }
                                                                isDisabled={
                                                                  isRestore
                                                                }
                                                                validateField={
                                                                  validateStep
                                                                }
                                                                directVal={true}
                                                                // isThousand={
                                                                //   true
                                                                // }
                                                                // setFieldTouched={
                                                                //   setFieldTouched
                                                                // }
                                                              />
                                                            </GridItem>
                                                          );
                                                        }
                                                      )}

                                                      <Box
                                                        className="grid_full"
                                                        mt={4}
                                                        mb={3}
                                                      >
                                                        <Text
                                                          className="title_sm  "
                                                          textTransform="capitalize"
                                                        >
                                                          Average Length of Stay
                                                        </Text>
                                                      </Box>
                                                      {regione.averageNumbers.map(
                                                        (_, ind) => {
                                                          currDateAvg =
                                                            currDateAvg + 1;
                                                          return (
                                                            <GridItem
                                                              colSpan={1}
                                                              key={`avgNum_${ind}`}
                                                            >
                                                              <InputFieldValidation
                                                                label={`Year ${currDateAvg}`}
                                                                placeholder="0"
                                                                inputType="number"
                                                                inputId={`visitorNumbers.${index}.averageNumbers.${ind}`}
                                                                inputName={`visitorNumbers.${index}.averageNumbers.${ind}`}
                                                                errors={errors}
                                                                touched={
                                                                  touched
                                                                }
                                                                isRequired
                                                                onChange={
                                                                  setFieldValue
                                                                }
                                                                values={
                                                                  values
                                                                    ?.visitorNumbers[
                                                                    index
                                                                  ]
                                                                    ?.averageNumbers[
                                                                    ind
                                                                  ]
                                                                }
                                                                labelCenter={
                                                                  true
                                                                }
                                                                errorHeight0={
                                                                  true
                                                                }
                                                                uppercase={
                                                                  false
                                                                }
                                                                isDisabled={
                                                                  isRestore
                                                                }
                                                                validateField={
                                                                  validateStep
                                                                }
                                                                directVal={true}
                                                                // isThousand={
                                                                //   true
                                                                // }
                                                                // setFieldTouched={
                                                                //   setFieldTouched
                                                                // }
                                                              />
                                                            </GridItem>
                                                          );
                                                        }
                                                      )}

                                                      <Box
                                                        className="grid_full"
                                                        mt={4}
                                                        mb={3}
                                                      >
                                                        <Text
                                                          className="title_sm  "
                                                          textTransform="capitalize"
                                                        >
                                                          Guests per Occupied
                                                          Room
                                                        </Text>
                                                      </Box>
                                                      {regione.guestsNumbers.map(
                                                        (_, ind) => {
                                                          currDateGus =
                                                            currDateGus + 1;
                                                          return (
                                                            <GridItem
                                                              colSpan={1}
                                                              key={`guestNum_${ind}`}
                                                            >
                                                              <InputFieldValidation
                                                                label={`Year ${currDateGus}`}
                                                                placeholder="0"
                                                                inputType="number"
                                                                inputId={`visitorNumbers.${index}.guestsNumbers.${ind}`}
                                                                inputName={`visitorNumbers.${index}.guestsNumbers.${ind}`}
                                                                errors={errors}
                                                                touched={
                                                                  touched
                                                                }
                                                                isRequired
                                                                onChange={
                                                                  setFieldValue
                                                                }
                                                                values={
                                                                  values
                                                                    ?.visitorNumbers[
                                                                    index
                                                                  ]
                                                                    ?.guestsNumbers[
                                                                    ind
                                                                  ]
                                                                }
                                                                labelCenter={
                                                                  true
                                                                }
                                                                errorHeight0={
                                                                  true
                                                                }
                                                                uppercase={
                                                                  false
                                                                }
                                                                isDisabled={
                                                                  isRestore
                                                                }
                                                                validateField={
                                                                  validateStep
                                                                }
                                                                directVal={true}
                                                                // isThousand={
                                                                //   true
                                                                // }
                                                                // setFieldTouched={
                                                                //   setFieldTouched
                                                                // }
                                                              />
                                                            </GridItem>
                                                          );
                                                        }
                                                      )}

                                                      <GridItem
                                                        colSpan={6}
                                                        display="flex"
                                                      >
                                                        <HStack gap="2">
                                                          <MainButton
                                                            // disabledBtn={
                                                            //   isRestore
                                                            // }
                                                            title="Add Note"
                                                            icon={plus2}
                                                            btnStyle="transparent"
                                                            fontColor="dark"
                                                            fSize="sm"
                                                            marginRight={1}
                                                            mt="mt7"
                                                            className="btn_add"
                                                            onPress={(
                                                              event
                                                            ) => {
                                                              toggleButton(
                                                                event,
                                                                "add"
                                                              );
                                                            }}
                                                          />

                                                          <HStack
                                                            gap="0"
                                                            className="note_wrapper"
                                                            display="none"
                                                            flexDirection="column"
                                                            alignItems="flex-start"
                                                          >
                                                            <Box
                                                              minW={[
                                                                "450",
                                                                "450",
                                                                "500",
                                                              ]}
                                                            >
                                                              <TextArea
                                                                label="NOTE"
                                                                inputId={`visitorNumbers.${index}.note`}
                                                                inputName={`visitorNumbers.${index}.note`}
                                                                placeholder="Add remarks"
                                                                errors={errors}
                                                                touched={
                                                                  touched
                                                                }
                                                                isRequired
                                                                values={
                                                                  values
                                                                    ?.visitorNumbers[
                                                                    index
                                                                  ]?.note
                                                                }
                                                                onChange={
                                                                  setFieldValue
                                                                }
                                                                onBlur={
                                                                  handleBlur
                                                                }
                                                                insideArr={true}
                                                                isDisabled={
                                                                  isRestore
                                                                }
                                                              />
                                                            </Box>

                                                            <MainButton
                                                              disabledBtn={
                                                                isRestore
                                                              }
                                                              title="Remove"
                                                              icon={deleteIcon2}
                                                              btnStyle="transparent"
                                                              fontColor="danger"
                                                              fSize="sm"
                                                              marginRight={1}
                                                              onPress={(
                                                                event
                                                              ) => {
                                                                toggleButton(
                                                                  event,
                                                                  "remove"
                                                                );
                                                                clearInput(
                                                                  event,
                                                                  values
                                                                );
                                                              }}
                                                            />
                                                          </HStack>
                                                        </HStack>
                                                      </GridItem>
                                                    </SimpleGrid>
                                                  </GridItem>
                                                </>
                                              </AccordionPanel>
                                            </AccordionItem>
                                          );
                                        }
                                      )}
                                  </Accordion>
                                </Box>
                              </>
                            )}
                          />
                        )}

                        <Divider orientation="horizontal" mt="20px" mb="30px" />
                        <Box display="flex" width="100%">
                          <Box minW={["100%", "40%", "40%"]}>
                            <TextArea
                              label="ADD COMMENT"
                              inputId={`comments`}
                              inputName={`comments`}
                              placeholder="Add description"
                              errors={errors}
                              touched={touched}
                              isRequired
                              values={values}
                              onChange={setFieldValue}
                              onBlur={handleBlur}
                              // isDisabled={isRestore}
                            />
                          </Box>
                        </Box>
                      </>

                      <Divider orientation="horizontal" mt="20px" mb="30px" />
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        <MainButton
                          title="Save Draft"
                          disabledBtn={isRestore}
                          btnStyle="transparent"
                          fontColor="dark"
                          onPress={() => {
                            onDraftOpen();
                          }}
                        />

                        <Box display="flex" gap="30px">
                          {dirty ? (
                            <MainButton
                              title="Cancel"
                              btnStyle="transparent"
                              fontColor="light"
                              onPress={() => {
                                onCancelOpen();
                              }}
                            />
                          ) : (
                            <MainButton
                              title="Cancel"
                              btnStyle="transparent"
                              fontColor="light"
                              navLink={true}
                              path={`/VisitorNumHistory`}
                            />
                          )}
                          {!isDisabledForm ? (
                            <MainButton
                              title={isRestore ? "Restore" : "Submit"}
                              reverseRow={true}
                              marginLeft="2"
                              disabledBtn={false}
                              onPress={() => {
                                closeToast();
                                handleOnSubmit(values, isRestore);
                              }}
                            />
                          ) : (
                            <MainButton
                              title={isRestore ? "Restore" : "Submit"}
                              reverseRow={true}
                              marginLeft="2"
                              disabledBtn={!dirty}
                              onPress={() => {
                                handleAccordErrors(errors);
                                setValidateStep(true);
                                isModelWarningOpen(true);
                                document
                                  .getElementById("main_content")
                                  .scrollTo(0, 0);
                              }}
                            />
                          )}
                        </Box>
                      </Flex>

                      <ModalAlert
                        isOpen={isDraftOpen}
                        onClose={onDraftClose}
                        title="Are you sure you want to save this as draft?"
                        icon={infoCircle}
                        confirmationModal={true}
                        hasDesc={false}
                        haveSaveDraft={false}
                        path={`/VisitorNumHistory`}
                        pathDraft={`/VisitorNumHistory/success`}
                        onPressSaveDraft={() => handleSaveDraft(values)}
                      />
                      <ModalAlert
                        isOpen={isCancelOpen}
                        onClose={onCancelClose}
                        title="Are you sure you want to discard the changes?"
                        icon={infoCircle}
                        hasDesc={false}
                        confirmationModal={true}
                        path={`/VisitorNumHistory`}
                        onPressSaveDraft={() => handleSaveDraft(values)}
                      />
                    </Form>
                  )}
                </Formik>
              </Box>
            </MainBox>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />

      <ModalAlert
        isOpen={isSubmitOpen}
        onClose={onSubmitClose}
        title={submitModalTitle}
        btnWidthFull={true}
        icon={checked}
        hasDesc={false}
        disableClose={true}
        path={`/VisitorNumHistory`}
      />
    </div>
  );
};
