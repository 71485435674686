import React, {useMemo} from "react";

import {Box, Text, VStack, ListItem, UnorderedList} from "@chakra-ui/react";

import styles from "./infoCard.module.scss";

export const InfoCard = ({
    activeRegions = []
}) => {
    const regionsText = useMemo(() => {
        if (activeRegions.length) {
            return activeRegions.join(', ');
        }
        return '-';
    }, [activeRegions])
    return (
        <Box
            borderRadius={5}
            bg={'#f1efe0'}
            padding={'24px 36px'}
            className={`${styles.info_card}`}>
            <VStack alignItems={'start'}>
                <div>
                    <Text className={styles.background_text}>Active Regions: <span>{regionsText}</span></Text>
                </div>
                <UnorderedList>
                    <ListItem>Total Keys reflects the total number of keys by 2030 and includes any hotel & serviced apartment keys planned and developed by 2030.</ListItem>
                    <ListItem>The number of Hotels reflects the total number of hotels by 2030 and includes any hotels & serviced apartments  planned and developed by 2030.</ListItem>
                    <ListItem>Occupancy reflects the weighted-average occupancy across all hotels & serviced apartments operational by 2030. Some hotels & serviced apartments might still ramp up, while others are already stabilized.</ListItem>
                    <ListItem>Average Daily Rate (ADR) and Revenue per Available Room (RevPAR) reflect the weighted-average across all hotels & serviced apartments operational by 2030. Some hotels & serviced apartments might still ramp up, while others are already stabilized.</ListItem>
                    <ListItem>Capex reflects the aggregate of all planning & development budgets for hotels, branded residences, and serviced apartments initiated until and including 2030. Total capex figure includes Construction Cost, FF&E, OS&E, Soft Costs (Professional Fees), Pre-Opening Training & Marketing, TSA Fees (if available), IT budget, Developer's Fee, Contingency, and Working Capital. Surrounding infrastructure costs, parking costs, and land value is mainly excluded from the Capex and needs to be confirmed on a case-by-case basis.</ListItem>
                    <ListItem>Supply-based visitors refer to overnight visitors calculated based on projected hotel occupancy, guests per occupied room, and average length of stay. Day visitors and visitors who are not staying in a hotel, serviced apartment, or branded residence are excluded.</ListItem>
                </UnorderedList>
            </VStack>
        </Box>
    )
}