import React from "react";

import { Tooltip } from "@chakra-ui/react";

import info from "../../assets/images/icons/Subtract.svg";

export const TooltipElement = ({ label }) => {
  return (
    <Tooltip
      label={`${label}`}
      aria-label="A tooltip"
      bg="#f4f4ed"
      color="#1A1D1F"
      borderRadius="5px"
      border="1px solid #f4f4ed"
      hasArrow
      placement="right"
      px={5}
      py={2}
    >
      <img src={info} alt="tooltip icon" />
    </Tooltip>
  );
};
