const calculateTableValues = (asset) => {
  return {
    keys: asset.keys.toLocaleString("en-US", { maximumFractionDigits: 0 }),
    positioning: asset.positioning,
    occupancyPercent: (asset.occupancyPercent * 100).toFixed(1) + " %",
    totalRoomsOccupied: asset.totalRoomsOccupied.toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    adr: Math.ceil(asset.adr).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    avgKeys: asset.avgKeys.toLocaleString("en-US", { maximumFractionDigits: 0 }),
    revpar: Math.ceil(asset.revpar).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    totalRevenue: Math.ceil(asset.totalRevenue).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    gop: Math.ceil(asset.gop).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    ebitda: Math.ceil(asset.ebitda).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    payrollPerEmployee: Math.ceil(
      asset.payroll / asset.numberOfStaff
    ).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    staffHousingPerEmployee: Math.ceil(
      asset.staffHousingCost / asset.numberOfStaff
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    baseFee: ((asset.baseFee / asset.totalRevenue) * 100).toFixed(1) + " %",
    incentiveFeeOfRevenue:
      ((asset.incentiveFee / asset.totalRevenue) * 100).toFixed(1) + " %",
    incentiveFeeOfAGOP:
      ((asset.incentiveFee / asset.agop) * 100).toFixed(1) + " %",
    franchiseFee:
      ((asset.franchiseFee / asset.totalRevenue) * 100).toFixed(1) + " %",
    roomRevenueSARPOR: Math.ceil(
      asset.roomRevenue / asset.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    fnbRevenueSARPOR: Math.ceil(
      asset.fnbRevenue / asset.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    otherRevenueSARPOR: Math.ceil(
      asset.otherRevenue / asset.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    departmentalProfitSARPOR: Math.ceil(
      asset.departmentalProfit / asset.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    utilitiesSARPOR: Math.ceil(
      asset.utilities / asset.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    gopSARPOR: Math.ceil(asset.gop / asset.totalRoomsOccupied).toLocaleString(
      "en"
    ),
    ebitdaSARPOR: Math.ceil(
      asset.ebitda / asset.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    roomRevenuePercentOfRevenue:
      ((asset.roomRevenue / asset.totalRevenue) * 100).toFixed(1) + " %",
    fnbRevenuePercentOfRevenue:
      ((asset.fnbRevenue / asset.totalRevenue) * 100).toFixed(1) + " %",
    otherRevenuePercentOfRevenue:
      ((asset.otherRevenue / asset.totalRevenue) * 100).toFixed(1) + " %",
    roomProfitPercentOfRevenue:
      ((asset.roomProfit / asset.roomRevenue) * 100).toFixed(1) + " %",
    fnbProfitPercentOfRevenue:
      ((asset.fnbProfit / asset.fnbRevenue) * 100).toFixed(1) + " %",
    otherProfitPercentOfRevenue:
      ((asset.otherProfit / asset.otherRevenue) * 100).toFixed(1) + " %",
    departmentalProfitPercentOfRevenue:
      ((asset.departmentalProfit / asset.totalRevenue) * 100).toFixed(1) + " %",
    gopPercentOfRevenue:
      ((asset.gop / asset.totalRevenue) * 100).toFixed(1) + " %",
    ebitdaPercentOfRevenue:
      ((asset.ebitda / asset.totalRevenue) * 100).toFixed(1) + " %",
    irrPercent: (asset.irr * 100).toFixed(1) + " %",
    capex: Math.ceil(asset.capex),
    parkingPercentOfCapEx:
      ((asset.parking / asset.capex) * 100).toFixed(1) + " %",
    constructionPercentOfCapEx:
      ((asset.construction / asset.capex) * 100).toFixed(1) + " %",
    ffe_osePercentOfCapEx:
      ((asset.ffe_ose / asset.capex) * 100).toFixed(1) + " %",
    softCostPercentOfCapEx:
      ((asset.softCosts / asset.capex) * 100).toFixed(1) + " %",
    preOpeningPercentOfCapEx:
      ((asset.preOpening / asset.capex) * 100).toFixed(1) + " %",
    tsa_itPercentOfCapEx:
      ((asset.tsa_it / asset.capex) * 100).toFixed(1) + " %",
    developmentFeePercentOfCapEx:
      ((asset.developmentFee / asset.capex) * 100).toFixed(1) + " %",
    contingencyPercentOfCapEx:
      ((asset.contingency / asset.capex) * 100).toFixed(1) + " %",
    initialWorkingCapitalPercentOfCapEx:
      ((asset.initialWorkingCapital / asset.capex) * 100).toFixed(1) + " %",
    gopPerSQM: Math.ceil(asset.gop / asset.gfa).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    totalRevenuePerSQM: Math.ceil(
      asset.totalRevenue / asset.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    capexPerSQM: Math.ceil(asset.capex / asset.gfa).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    landPerSQM: Math.ceil(asset.land / asset.gfa).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    infrastructurePerSQM: Math.ceil(
      asset.infrastructure / asset.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    parkingPerSQM: Math.ceil(asset.parking / asset.gfa).toLocaleString(
      "en-US",
      { maximumFractionDigits: 0 }
    ),
    constructionPerSQM: Math.ceil(
      asset.construction / asset.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    ffe_osePerSQM: Math.ceil(asset.ffe_ose / asset.gfa).toLocaleString(
      "en-US",
      { maximumFractionDigits: 0 }
    ),
    softCostPerSQM: Math.ceil(asset.softCosts / asset.gfa).toLocaleString(
      "en-US",
      { maximumFractionDigits: 0 }
    ),
    preOpeningPerSQM: Math.ceil(asset.preOpening / asset.gfa).toLocaleString(
      "en"
    ),
    tsa_itPerSQM: Math.ceil(asset.tsa_it / asset.gfa).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    developmentFeePerSQM: Math.ceil(
      asset.developmentFee / asset.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    contingencyPerSQM: Math.ceil(asset.contingency / asset.gfa).toLocaleString(
      "en"
    ),
    initialWorkingCapitalPerSQM: Math.ceil(
      asset.initialWorkingCapital / asset.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    totalRevenuePerKey: Math.ceil(
      asset.totalRevenue / asset.keys
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    totalUndistributedExpensesPerKey: Math.ceil(
      asset.totalUndistributedExpenses / asset.keys
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    gopPerKey: Math.ceil(asset.gop / asset.keys).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    ebitdaPerKey: Math.ceil(asset.ebitda / asset.keys).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    capexPerKey: Math.ceil(asset.capex / asset.keys),

    totalRevenuePerAvailableRoom: Math.ceil(
      asset.totalRevenue / asset.roomsAvailable
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),

    roomsAvailable: asset.roomsAvailable.toLocaleString(
      "en-US",
      { maximumFractionDigits: 0 }
    ),

    totalUndistributedExpensesPerAvailableRoom: Math.ceil(
      asset.totalUndistributedExpenses / asset.roomsAvailable
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),

    gopPerAvailableRoom: Math.ceil(
      asset.gop / asset.roomsAvailable
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),

    ebitdaPerAvailableRoom: Math.ceil(
      asset.ebitda / asset.roomsAvailable
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    adrPerYear: asset.adrPerYear.map((adr) => Math.ceil(adr)),
    occupancyPerYear: asset.occupancyPerYear.map((occupancy) =>
      Math.ceil(occupancy * 100)
    ),
    revparPerYear: asset.revparPerYear.map((revpar) => Math.ceil(revpar)),
    gopPerYear: asset.gopPerYear.map((gop) =>
      Math.ceil((gop / asset.totalRevenue) * 100)
    ),
    ebitdaPerYear: asset.ebitdaPerYear.map((ebitda) =>
      Math.ceil((ebitda / asset.totalRevenue) * 100)
    ),
    parkingPerKey: Math.ceil(asset.parking / asset.keys).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    constructionPerKey: Math.ceil(asset.construction / asset.keys).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    ffe_osePerKey: Math.ceil(asset.ffe_ose / asset.keys).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    softCostPerKey: Math.ceil(asset.softCosts / asset.keys).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    preOpeningPerKey: Math.ceil(asset.preOpening / asset.keys).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    tsa_itPerKey: Math.ceil(asset.tsa_it / asset.keys).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    developmentFeePerKey: Math.ceil(asset.developmentFee / asset.keys).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    contingencyPerKey: Math.ceil(asset.contingency / asset.keys).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    initialWorkingCapitalPerKey: Math.ceil(
      asset.initialWorkingCapital / asset.keys
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    revparAvailableRoom: Math.ceil(
      asset.revpar / asset.roomsAvailable
      ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
  };
};



const calculateAggregate = (assets, assetNames, irrAggregation) => {
  let aggregateAssets = {
    keys: 0,
    totalRoomsOccupied: 0,
    roomsAvailable: 0,
    gfa: 0,
    positioning: "Iconic",
    avgKeys: 0,
    occupancyPercent: 0,
    adr: 0,
    revpar: 0,
    totalRevenue: 0,
    gop: 0,
    ebitda: 0,
    capex: 0,
    totalUndistributedExpenses: 0,
    roomRevenue: 0,
    fnbRevenue: 0,
    otherRevenue: 0,
    departmentalProfit: 0,
    utilities: 0,
    roomProfit: 0,
    fnbProfit: 0,
    otherProfit: 0,
    land: 0,
    infrastructure: 0,
    parking: 0,
    construction: 0,
    ffe_ose: 0,
    softCosts: 0,
    preOpening: 0,
    tsa_it: 0,
    developmentFee: 0,
    contingency: 0,
    initialWorkingCapital: 0,
    payroll: 0,
    numberOfStaff: 0,
    staffHousingCost: 0,
    baseFee: 0,
    incentiveFee: 0,
    incentiveFeeOfRevenue: 0,
    franchiseFee: 0,
    payrollPerEmployee: 0,
    staffHousingPerEmployee: 0,
    agop: 0,
    adrPerYear: new Array(7).fill(0),
    occupancyPerYear: new Array(7).fill(0),
    revparPerYear: new Array(7).fill(0),
    gopPerYear: new Array(7).fill(0),
    ebitdaPerYear: new Array(7).fill(0),
    roomsRevenuePerYear: new Array(7).fill(0),
    occupiedRoomsPerYear: new Array(7).fill(0),
    roomsAvailablePerYear: new Array(7).fill(0),
    totalRevenuePerYear: new Array(7).fill(0),
  };
  for (const name of assetNames) {
    aggregateAssets.keys += assets[name].keys;
    aggregateAssets.totalRoomsOccupied += assets[name].totalRoomsOccupied;
    aggregateAssets.roomsAvailable += assets[name].roomsAvailable;
    aggregateAssets.gfa += assets[name].gfa;
    aggregateAssets.avgKeys += assets[name].keys;
    aggregateAssets.occupancyPercent += assets[name].occupancyPercent;
    aggregateAssets.adr += assets[name].adr;
    aggregateAssets.revpar += assets[name].revpar;
    aggregateAssets.totalRevenue += assets[name].totalRevenue;
    aggregateAssets.gop += assets[name].gop;
    aggregateAssets.ebitda += assets[name].ebitda;
    aggregateAssets.capex += assets[name].capex;
    aggregateAssets.totalUndistributedExpenses +=
      assets[name].totalUndistributedExpenses;
    aggregateAssets.roomRevenue += assets[name].roomRevenue;
    aggregateAssets.fnbRevenue += assets[name].fnbRevenue;
    aggregateAssets.otherRevenue += assets[name].otherRevenue;
    aggregateAssets.departmentalProfit += assets[name].departmentalProfit;
    aggregateAssets.utilities += assets[name].utilities;
    aggregateAssets.roomProfit += assets[name].roomProfit;
    aggregateAssets.fnbProfit += assets[name].fnbProfit;
    aggregateAssets.otherProfit += assets[name].otherProfit;
    aggregateAssets.land += assets[name].land;
    aggregateAssets.infrastructure += assets[name].infrastructure;
    aggregateAssets.parking += assets[name].parking;
    aggregateAssets.construction += assets[name].construction;
    aggregateAssets.ffe_ose += assets[name].ffe_ose;
    aggregateAssets.softCosts += assets[name].softCosts;
    aggregateAssets.preOpening += assets[name].preOpening;
    aggregateAssets.tsa_it += assets[name].tsa_it;
    aggregateAssets.developmentFee += assets[name].developmentFee;
    aggregateAssets.contingency += assets[name].contingency;
    aggregateAssets.initialWorkingCapital += assets[name].initialWorkingCapital;
    aggregateAssets.payroll += assets[name].payroll;
    aggregateAssets.numberOfStaff += assets[name].numberOfStaff;
    aggregateAssets.staffHousingCost += assets[name].staffHousingCost;
    aggregateAssets.baseFee += assets[name].baseFee;
    aggregateAssets.incentiveFee += assets[name].incentiveFee;
    aggregateAssets.franchiseFee += assets[name].franchiseFee;
    aggregateAssets.agop += assets[name].agop;
    aggregateAssets.roomsRevenuePerYear =
      aggregateAssets.roomsRevenuePerYear.map((revenue, index) => {
        return revenue + assets[name].roomsRevenuePerYear[index];
      });
    aggregateAssets.occupiedRoomsPerYear =
      aggregateAssets.occupiedRoomsPerYear.map((rooms, index) => {
        return rooms + assets[name].occupiedRoomsPerYear[index];
      });
    aggregateAssets.roomsAvailablePerYear =
      aggregateAssets.roomsAvailablePerYear.map((rooms, index) => {
        return rooms + assets[name].roomsAvailablePerYear[index];
      });
    aggregateAssets.totalRevenuePerYear =
      aggregateAssets.totalRevenuePerYear.map((revenue, index) => {
        return revenue + assets[name].totalRevenuePerYear[index];
      });
    aggregateAssets.gopPerYear = aggregateAssets.gopPerYear.map(
      (gop, index) => {
        return gop + assets[name].gopPerYear[index];
      }
    );
    aggregateAssets.ebitdaPerYear = aggregateAssets.ebitdaPerYear.map(
      (ebitda, index) => {
        return ebitda + assets[name].ebitdaPerYear[index];
      }
    );
  }
  /* END for loop */

  aggregateAssets.payrollPerEmployee =
    aggregateAssets.payroll / aggregateAssets.numberOfStaff;
  aggregateAssets.staffHousingPerEmployee =
    aggregateAssets.staffHousingCost / aggregateAssets.numberOfStaff;
  aggregateAssets.baseFee =
    (aggregateAssets.baseFee / aggregateAssets.totalRevenue) * 100;
  aggregateAssets.incentiveFeeOfRevenue =
    (aggregateAssets.incentiveFee / aggregateAssets.totalRevenue) * 100;
  aggregateAssets.incentiveFee =
    (aggregateAssets.incentiveFee / aggregateAssets.agop) * 100;
  aggregateAssets.franchiseFee =
    (aggregateAssets.franchiseFee / aggregateAssets.totalRevenue) * 100;

  aggregateAssets.adrPerYear = aggregateAssets.adrPerYear.map((adr, index) => {
    return (
      adr +
      aggregateAssets.roomsRevenuePerYear[index] /
        aggregateAssets.occupiedRoomsPerYear[index]
    );
  });
  aggregateAssets.occupancyPerYear = aggregateAssets.occupancyPerYear.map(
    (occupancy, index) => {
      return (
        occupancy +
        (aggregateAssets.occupiedRoomsPerYear[index] /
          aggregateAssets.roomsAvailablePerYear[index]) *
          100
      );
    }
  );
  aggregateAssets.revparPerYear = aggregateAssets.revparPerYear.map(
    (revpar, index) => {
      return (
        revpar +
        aggregateAssets.roomsRevenuePerYear[index] /
          aggregateAssets.roomsAvailablePerYear[index]
      );
    }
  );
  aggregateAssets.gopPerYear = aggregateAssets.gopPerYear.map((gop, index) => {
    return (gop / aggregateAssets.totalRevenuePerYear[index]) * 100;
  });
  aggregateAssets.ebitdaPerYear = aggregateAssets.ebitdaPerYear.map(
    (ebitda, index) => {
      return (ebitda / aggregateAssets.totalRevenuePerYear[index]) * 100;
    }
  );

  let aggregateAssetsFinalData = {
    keys: aggregateAssets.keys,
    positioning: "Iconic",
    avgKeys: (aggregateAssets.avgKeys/assetNames.length).toLocaleString(
      "en-US",
      { maximumFractionDigits: 0 }
    ),
    totalRoomsOccupied: aggregateAssets.totalRoomsOccupied.toLocaleString(
      "en-US",
      { maximumFractionDigits: 0 }
    ),
    roomsAvailable: aggregateAssets.roomsAvailable.toLocaleString(
      "en-US",
      { maximumFractionDigits: 0 }
    ),
    occupancyPercent: (
      (aggregateAssets.totalRoomsOccupied / aggregateAssets.roomsAvailable) *
      100
    ).toFixed(1),
    adr: Math.ceil(
      aggregateAssets.roomRevenue / aggregateAssets.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    revpar: Math.ceil(
      aggregateAssets.roomRevenue / aggregateAssets.roomsAvailable
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    totalRevenue: Math.ceil(aggregateAssets.totalRevenue).toLocaleString(
      "en-US",
      { maximumFractionDigits: 0 }
    ),
    gop: Math.ceil(aggregateAssets.gop).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    ebitda: Math.ceil(aggregateAssets.ebitda).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    }),
    payrollPerEmployee: Math.ceil(
      aggregateAssets.payrollPerEmployee
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    staffHousingPerEmployee: Math.ceil(
      aggregateAssets.staffHousingPerEmployee
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    baseFee: aggregateAssets.baseFee.toFixed(1) + " %",
    incentiveFeeOfRevenue:
      aggregateAssets.incentiveFeeOfRevenue.toFixed(1) + " %",
    incentiveFeeOfAGOP: aggregateAssets.incentiveFee.toFixed(1) + " %",
    franchiseFee: aggregateAssets.franchiseFee.toFixed(1) + " %",
    roomRevenueSARPOR: Math.ceil(
      aggregateAssets.roomRevenue / aggregateAssets.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    fnbRevenueSARPOR: Math.ceil(
      aggregateAssets.fnbRevenue / aggregateAssets.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    otherRevenueSARPOR: Math.ceil(
      aggregateAssets.otherRevenue / aggregateAssets.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    departmentalProfitSARPOR: Math.ceil(
      aggregateAssets.departmentalProfit / aggregateAssets.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    utilitiesSARPOR: Math.ceil(
      aggregateAssets.utilities / aggregateAssets.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    gopSARPOR: Math.ceil(
      aggregateAssets.gop / aggregateAssets.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    ebitdaSARPOR: Math.ceil(
      aggregateAssets.ebitda / aggregateAssets.totalRoomsOccupied
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    roomRevenuePercentOfRevenue:
      (
        (aggregateAssets.roomRevenue / aggregateAssets.totalRevenue) *
        100
      ).toFixed(1) + " %",
    fnbRevenuePercentOfRevenue:
      (
        (aggregateAssets.fnbRevenue / aggregateAssets.totalRevenue) *
        100
      ).toFixed(1) + " %",
    otherRevenuePercentOfRevenue:
      (
        (aggregateAssets.otherRevenue / aggregateAssets.totalRevenue) *
        100
      ).toFixed(1) + " %",
    roomProfitPercentOfRevenue:
      (
        (aggregateAssets.roomProfit / aggregateAssets.roomRevenue) *
        100
      ).toFixed(1) + " %",
    fnbProfitPercentOfRevenue:
      ((aggregateAssets.fnbProfit / aggregateAssets.fnbRevenue) * 100).toFixed(
        1
      ) + " %",
    otherProfitPercentOfRevenue:
      (
        (aggregateAssets.otherProfit / aggregateAssets.otherRevenue) *
        100
      ).toFixed(1) + " %",
    departmentalProfitPercentOfRevenue:
      (
        (aggregateAssets.departmentalProfit / aggregateAssets.totalRevenue) *
        100
      ).toFixed(1) + " %",
    gopPercentOfRevenue:
      ((aggregateAssets.gop / aggregateAssets.totalRevenue) * 100).toFixed(1) +
      " %",
    ebitdaPercentOfRevenue:
      ((aggregateAssets.ebitda / aggregateAssets.totalRevenue) * 100).toFixed(
        1
      ) + " %",
    irrPercent: (irrAggregation * 100).toFixed(1),
    capex: Math.ceil(aggregateAssets.capex),
    landPercentOfCapEx: Math.ceil(aggregateAssets.land).toLocaleString(
      "en-US",
      { maximumFractionDigits: 0 }
    ),
    infrastructurePercentOfCapEx: Math.ceil(
      aggregateAssets.infrastructure
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    parkingPercentOfCapEx:
      ((aggregateAssets.parking / aggregateAssets.capex) * 100).toFixed(1) +
      " %",
    constructionPercentOfCapEx:
      ((aggregateAssets.construction / aggregateAssets.capex) * 100).toFixed(
        1
      ) + " %",
    ffe_osePercentOfCapEx:
      ((aggregateAssets.ffe_ose / aggregateAssets.capex) * 100).toFixed(1) +
      " %",
    softCostPercentOfCapEx:
      ((aggregateAssets.softCosts / aggregateAssets.capex) * 100).toFixed(1) +
      " %",
    preOpeningPercentOfCapEx:
      ((aggregateAssets.preOpening / aggregateAssets.capex) * 100).toFixed(1) +
      " %",
    tsa_itPercentOfCapEx:
      ((aggregateAssets.tsa_it / aggregateAssets.capex) * 100).toFixed(1) +
      " %",
    developmentFeePercentOfCapEx:
      ((aggregateAssets.developmentFee / aggregateAssets.capex) * 100).toFixed(
        1
      ) + " %",
    contingencyPercentOfCapEx:
      ((aggregateAssets.contingency / aggregateAssets.capex) * 100).toFixed(1) +
      " %",
    initialWorkingCapitalPercentOfCapEx:
      (
        (aggregateAssets.initialWorkingCapital / aggregateAssets.capex) *
        100
      ).toFixed(1) + " %",
    totalRevenuePerSQM: Math.ceil(
      aggregateAssets.totalRevenue / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    gopPerSQM: Math.ceil(
      aggregateAssets.gop / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    capexPerSQM: Math.ceil(
      aggregateAssets.capex / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    landPerSQM: Math.ceil(
      aggregateAssets.land / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    infrastructurePerSQM: Math.ceil(
      aggregateAssets.infrastructure / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    parkingPerSQM: Math.ceil(
      aggregateAssets.parking / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    constructionPerSQM: Math.ceil(
      aggregateAssets.construction / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    ffe_osePerSQM: Math.ceil(
      aggregateAssets.ffe_ose / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    softCostPerSQM: Math.ceil(
      aggregateAssets.softCosts / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    preOpeningPerSQM: Math.ceil(
      aggregateAssets.preOpening / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    tsa_itPerSQM: Math.ceil(
      aggregateAssets.tsa_it / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    developmentFeePerSQM: Math.ceil(
      aggregateAssets.developmentFee / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    contingencyPerSQM: Math.ceil(
      aggregateAssets.contingency / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    initialWorkingCapitalPerSQM: Math.ceil(
      aggregateAssets.initialWorkingCapital / aggregateAssets.gfa
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    totalRevenuePerKey: Math.ceil(
      aggregateAssets.totalRevenue / aggregateAssets.keys
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    revparPerKey: Math.ceil(
      aggregateAssets.revpar / aggregateAssets.keys
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    totalUndistributedExpensesPerKey: Math.ceil(
      aggregateAssets.totalUndistributedExpenses / aggregateAssets.keys
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    gopPerKey: Math.ceil(
      aggregateAssets.gop / aggregateAssets.keys
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    ebitdaPerKey: Math.ceil(
      aggregateAssets.ebitda / aggregateAssets.keys
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    capexPerKey: Math.ceil(aggregateAssets.capex / aggregateAssets.keys),
    adrPerYear: aggregateAssets.adrPerYear.map((adr) => Math.ceil(adr)),
    occupancyPerYear: aggregateAssets.occupancyPerYear,
    revparPerYear: aggregateAssets.revparPerYear,
    gopPerYear: aggregateAssets.gopPerYear,
    ebitdaPerYear: aggregateAssets.ebitdaPerYear,
    totalRevenuePerAvailableRoom: Math.ceil(
      aggregateAssets.totalRevenue / aggregateAssets.roomsAvailable
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    totalUndistributedExpensesPerAvailableRoom: Math.ceil(
      aggregateAssets.totalUndistributedExpenses /
        aggregateAssets.roomsAvailable
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    gopPerAvailableRoom: Math.ceil(
      aggregateAssets.gop / aggregateAssets.roomsAvailable
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    ebitdaPerAvailableRoom: Math.ceil(
      aggregateAssets.ebitda / aggregateAssets.roomsAvailable
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    parkingPerKey: Math.ceil(aggregateAssets.parking / aggregateAssets.keys).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    constructionPerKey: Math.ceil(
      aggregateAssets.construction / aggregateAssets.keys
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    ffe_osePerKey: Math.ceil(aggregateAssets.ffe_ose / aggregateAssets.keys).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    softCostPerKey: Math.ceil(aggregateAssets.softCosts / aggregateAssets.keys).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    preOpeningPerKey: Math.ceil(aggregateAssets.preOpening / aggregateAssets.keys).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    tsa_itPerKey: Math.ceil(aggregateAssets.tsa_it / aggregateAssets.keys).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    developmentFeePerKey: Math.ceil(
      aggregateAssets.developmentFee / aggregateAssets.keys
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    contingencyPerKey: Math.ceil(
      aggregateAssets.contingency / aggregateAssets.keys
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    initialWorkingCapitalPerKey: Math.ceil(
      aggregateAssets.initialWorkingCapital / aggregateAssets.keys
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
    revparAvailableRoom: Math.ceil(
      aggregateAssets.revpar / aggregateAssets.roomsAvailable
    ).toLocaleString("en-US", { maximumFractionDigits: 0 }),
  };

  return aggregateAssetsFinalData;
};

export { calculateTableValues, calculateAggregate };
