import { set } from "date-fns";
import axiosClient from "../../api/index";
import {
  SET_APPROVAL_LIST,
  SET_UNAPPROVAL_LIST,
  UPDATE_KEYS,
} from "./actionTypes";

export const setApprovalList = (approvalList) => ({
  type: SET_APPROVAL_LIST,
  payload: approvalList,
});

export const setUnapprovalList = (unapprovalList) => ({
  type: SET_UNAPPROVAL_LIST,
  payload: unapprovalList,
});

// Assuming this is defined in your actions file
export const updateKeys = (data) => ({
  type: UPDATE_KEYS,
  payload: data,
});

export const setSaveDismiss = (value) => ({
  type: "SET_SAVE",
  payload: value,
});

export const setLoading = (value) => ({
  type: "SET_LOADING",
  payload: value,
});

export const setSavedSuccess = (value) => ({
  type: "SET_SAVED_SUCCESS",
  payload: value,
});

export const setErrorMessage = (value) => ({
  type: "SET_ERROR",
  payload: value,
});

export const saveUnapprovedAssets = (data) => async (dispatch) => {
  const URL = `/v1/assets/approvalList/saveUnapproved`;
  try {
    const response = await axiosClient.post(URL, data);
    dispatch(getApprovedAndUnapprovedAssets());
    
  } catch (error) {
    console.error("Error getting approval list:", error);
    throw error;  // Ensure that the error is thrown to be caught in the component
  }
};


const setAllAssets = (data) => ({
  type: "SET_ALL_ASSETS",
  payload: data,
});

const getUniqueItems = (data, key) => {
  const unique = Array.from(new Set(data.map((item) => item[key]))).map(
    (value) => {
      return data.find((item) => item[key] === value);
    }
  );
  return unique.map((item) => ({
    label: item[key],
    value: item[`${key}Lookup`],
  }));
};

export const getApprovedAndUnapprovedAssets = () => async (dispatch) => {
  const URL = `/v1/assets/approvalList`;
  try {
    const response = await axiosClient.get(URL);
    const { approvedAssets, unapprovedAssets } = seperateData(
      response.data.data
    );

    dispatch(setRegion(getUniqueItems(response.data.data, "region")));
    dispatch(setPosition(getUniqueItems(response.data.data, "positioning")));
    dispatch(setBrand(getUniqueItems(response.data.data, "brand")));
    dispatch(setOperator(getUniqueItems(response.data.data, "operator")));

    dispatch(setApprovalList(approvedAssets));
    dispatch(setUnapprovalList(unapprovedAssets));
    dispatch(setAllAssets(response.data.data));
  } catch (error) {
    console.error("Error getting approval list:", error);
  }
};

export const setRegion = (region) => ({
  type: "SET_REGION",
  payload: region,
});

export const setPosition = (position) => ({
  type: "SET_POSITION",
  payload: position,
});

export const setBrand = (brand) => ({
  type: "SET_BRAND",
  payload: brand,
});

export const setOperator = (operator) => ({
  type: "SET_OPERATOR",
  payload: operator,
});

export const updateStatus = (data) => ({
  type: "UPDATE_STATUS_TO_APPROVED",
  payload: data,
});

export const updateData = (data) => async (dispatch) => {
  const URL = `/v1/assets/approvalList/saveApproved`;
  try {
    const response = await axiosClient.post(URL, data);
    console.log(response.data.data);
    dispatch(updateKeys(data));
    dispatch(setSavedSuccess(true));
  } catch (error) {
    dispatch(setErrorMessage("error saving data"));
    console.error("Error getting approval list:", error);
  }
};

export const updateUnaprroveStatus = (id) => async (dispatch) => {
  const URL = `/v1/assets/approvalList/approve/${id}`;
  try {
    const response = await axiosClient.put(URL);
    let { id, status } = response.data.data.approvedAsset;
    // dispatch(updateStatus({ id, status }));
    dispatch(getApprovedAndUnapprovedAssets());
    dispatch(setSavedSuccess(true));
    console.log(id, status);
    // dispatch(updateKeys(data));
  } catch (error) {
    console.error("Error getting approval list:", error);
  }
};

const seperateData = (data) => {
  let unapprovedAssets = [];
  const approvedAssets = [];
  // Loop through each item in the data
  data.forEach((item) => {
    if (item.status === "In Planning") {
      unapprovedAssets.push(item);
    } else {
      approvedAssets.push(item);
    }
  });
  return { approvedAssets, unapprovedAssets };
};
