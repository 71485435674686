// // MainComponent.js
import { React, useState, useEffect } from 'react';
import {
    Box, color, Text

} from '@chakra-ui/react';

import { useSelector, useDispatch } from 'react-redux'
import { Line } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,

} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement
);

const LineChartComponent = (params) => {
    const compsetCalc = useSelector((state) => state.calculation.compset)
    const myAssetsCalc = useSelector((state) => state.calculation.myAssets)
    const myAssetsKey = useSelector((state) => state.calculation.myAssetsKey)
    const compsetCalcsKey = useSelector((state) => state.calculation.compsetCalcsKey)
    const isAggregation = useSelector((state) => state.calculation.isAggregation)
    const myAssetsAggregate = useSelector((state) => state.calculation.myAssetsAggregate)
    const compsetAggregate = useSelector((state) => state.calculation.compsetAggregate)
    const unitMap = {
        'irr': '%',
        'capex': 'SAR',
        'capexPerKey': 'SAR',
        'adrPerYear': 'SAR',
        'revparPerYear': 'SAR',
        'occupancyPerYear': '%',
        'gopPerYear': '%',
        'ebitdaPerYear': '%'
    }

    const unit = unitMap[params.value]
    const orangeDgree = {
        1: "#E9967A",
        2: "#E25822",
        3: "#FF7F50",
        4: "#FF3800",

    };

    const blueDgree = {
        1: "#7CB9E8",
        2: "#007FFF",
        3: "#0000FF",
        4: "#00BFFF",
    };

    let data = [];
    if (isAggregation) {
        const chartData = myAssetsAggregate[params.value];
        const chartData2 = compsetAggregate[params.value];
        data.push({
            label: 'My Assets',
            data: chartData,
            fill: false,
            borderColor: orangeDgree[1],
            tension: 0.1
        })
        if (Object.keys(compsetAggregate).length !== 0)
            data.push({
                label: 'Compset Assets',
                data: chartData2,
                fill: false,
                borderColor: blueDgree[1],
                tension: 0.1
            })
    } else {
        myAssetsKey.map((asset, ind) => {
            const chartData = myAssetsCalc[asset].calculationValues[params.value].slice(0, 7);
            data.push({
                label: asset.replace(/_/g, ' '),
                data: chartData,
                fill: false,
                borderColor: orangeDgree[ind + 1],
                tension: 0.1
            })

        })

        compsetCalcsKey.map((asset, ind) => {
            const chartData = compsetCalc[asset].calculationValues[params.value].slice(0, 7);
            data.push({
                label: asset.replace(/_/g, ' '),
                data: chartData,
                fill: false,
                borderColor: blueDgree[ind + 1],
                tension: 0.1
            })

        }
        )
    }

    const formatNumberToReadable = (num) => {
        if (num >= 1e9) {
            return `${(num / 1e9).toFixed(0)}B ${unit}`;
        } else if (num >= 1e6) {
            return `${(num / 1e6).toFixed(0)}M ${unit}`;
        } else if (num >= 1e3) {
            return `${(num / 1e3).toFixed(0)}K ${unit}`;
        } else {
            return `${num} ${unit}`;
        }
    }

    const dataChart = {
        labels: ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5', 'Year 6', 'Year 7'],
        datasets:
            data
    };
    const options = {
        plugins: {
            scales: {
                x: {
                    type: 'category' // Ensure that the type is set to 'category' for the x-axis scale
                }
            },
            title: {
                display: true,
                text: params.title,
                font: {
                    size: 20 // Adjust the font size as needed
                },
                color: 'black'
            },
            legend: {
                position: 'bottom',

            },
        },

        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    callback: function (value, index, values) {
                        // Use the formatting function for each tick label
                        return formatNumberToReadable(value);
                    }
                }
            }
        }
    };



    return (

        <Box position="relative">
            <Line id="test" data={dataChart} options={options} />
        </Box>

    );
}

export default LineChartComponent;








