import { Box } from "@chakra-ui/react";

export const MainBox = ({
  boxSize = "lg",
  borderWidth = "3",
  maxWidth = "100%",
  children,
  py = 15,
  px = 25,
  stepper = false,
}) => {
  let boxSizeClassname =
    boxSize === "md"
      ? "login_wrapper"
      : stepper === true
      ? "asset_stepper"
      : "box_shadow";
  boxSizeClassname += " main_box ";
  const bWidth = borderWidth === "3" ? 3 : borderWidth === "0" ? "0" : "5";

  return (
    <Box
      bg="white"
      py={py}
      px={px}
      mb={8}
      maxW={maxWidth}
      color="white"
      w="100%"
      className={boxSizeClassname}
      borderTopWidth={bWidth}
      borderTopColor="#EBC03F"
      borderTopStyle="solid"
      borderRadius={5}
    >
      {children}
    </Box>
  );
};
