// MainComponent.js
import { React, useState, useEffect } from "react";

import {
  Box,
  Text,
  Flex,
  Center,
  Spinner,
  SimpleGrid,
  Image,
  Button,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Heading,
  IconButton,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  ModalFooter,
} from "@chakra-ui/react";

// import FileModal from './createModal';
import { MainTitle } from "../../components/mainTitle/MainTitle";
// import styles from "../../components/forms.module.scss";
import styles from "./assetCard.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchData,
  setMyAssetsSelected,
  setCompsetAssetsSelected,
} from "../../redux/actions/card.js"; // Import the thunk action
import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import room from "../../assets/images/icons/room.svg";
import position from "../../assets/images/icons/positioning.svg";
import region from "../../assets/images/icons/region.svg";
import info from "../../assets/images/icons/info.svg";
import operator from "../../assets/images/icons/operator.svg";
import brand from "../../assets/images/icons/brand.svg";
import { setAggregation } from "../../redux/actions/calculation.js"; // Import the thunk action
import history from "../../history";

const Review = () => {
  const [isLoading, setIsLoading] = useState(false);
  const compset = useSelector((state) => state.card.compset);
  const myAssets = useSelector((state) => state.card.myAssets);
  const [myAssetsIds, setMyAssetsIds] = useState([]);
  const [compAssetsIds, setCompAssetsIds] = useState([]);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDescription, setSelectedDescription] = useState("");
  const [assetName, setAssetName] = useState("");
  let myAssetsIdsNew = myAssets
    .filter((asset) => asset.selected)
    .map((asset) => asset.id);
  let compAssetsIdsNew = compset
    .filter((asset) => asset.selected)
    .map((asset) => asset.id);
  useEffect(() => {
    dispatch(fetchData()).then(() => {
      // Assuming fetchData is a synchronous action or you have a way to know it's completed
      // Now, read the IDs from localStorage
      const myAssetsIds = JSON.parse(localStorage.getItem("myAssetsIds")) || [];
      const compAssetsIds =
        JSON.parse(localStorage.getItem("compAssetsIds")) || [];

      // Log to verify correct IDs are loaded

      setMyAssetsIds(myAssetsIds);
      setCompAssetsIds(compAssetsIds);
      // Use these IDs to mark assets as selected
      // This will depend on how you're tracking the selected state
      // For demonstration, let's assume you dispatch actions to update these
      myAssetsIds.forEach((id) => {
        dispatch(setMyAssetsSelected(id, true));
      });
      compAssetsIds.forEach((id) => {
        dispatch(setCompsetAssetsSelected(id, true));
      });
    });
  }, [dispatch]);

  const handleBoxClick = (id, type) => {
    if (type == "compAsset")
      compset.map((asset) => {
        if (asset.id == id) {
          dispatch(setCompsetAssetsSelected(id, !asset.selected));

          asset.selected = !asset.selected;
        }
        return asset;
      });
    else
      myAssets.map((asset) => {
        if (asset.id == id) {
          dispatch(setMyAssetsSelected(id, !asset.selected));

          asset.selected = !asset.selected;
        }
        return asset;
      });
  };
  const renderCard = (data, type) => {
    let assetsToRender;
    if (type === "myAssests")
      assetsToRender = data.filter((asset) => myAssetsIds.includes(asset.id));
    else
      assetsToRender = data.filter((asset) => compAssetsIds.includes(asset.id));
    console.log("assetsToRender", assetsToRender);
    return (
      <Box mt="20px" className={`${styles.ag_format_container}`}>
        <SimpleGrid columns={[1, 3, 5]} spacing={6}>
          {" "}
          {/* Render 1 column for small screens, 2 columns for medium screens, and 3 columns for large screens */}
          {assetsToRender.map((asset) => (
            <Box
              key={asset.id}
              className={`${styles.ag_courses_item} ${asset.selected ? styles.checked : ""
                }`}
            >
              <Box
                onClick={() => handleBoxClick(asset.id, type)}
                className={`${styles.ag_courses_item_link}`}
              >
                <Box
                  className={
                    asset.selected
                      ? `${styles.ag_courses_item_bg_selected}`
                      : `${styles.ag_courses_item_bg}`
                  }
                ></Box>
                <Text className={`${styles.ag_courses_item_title}`}>
                  {asset.projectName}
                </Text>
                <Box
                  className={`${styles.ag_courses_item_content} ${styles.ag_courses_item_date_box}`}
                >
                  <Box className={`${styles.icon_wrapper}`}>
                    <Image src={region} alt="Icon" />
                  </Box>
                  <Text className={`${styles.ag_courses_item_text}`}>
                    {asset.region}
                  </Text>
                </Box>
                <Box
                  className={`${styles.ag_courses_item_content} ${styles.ag_courses_item_date_box}`}
                >
                  <Box className={`${styles.icon_wrapper}`}>
                    <Image src={position} alt="Icon" />
                  </Box>
                  <Text className={`${styles.ag_courses_item_text}`}>
                    {asset.positioning}
                  </Text>
                </Box>
                <Box
                  className={`${styles.ag_courses_item_content} ${styles.ag_courses_item_date_box}`}
                >
                  <Box className={`${styles.icon_wrapper}`}>
                    <Image src={room} alt="Icon" />
                  </Box>
                  <Text className={`${styles.ag_courses_item_text}`}>
                    {asset.numberOfKeys}
                  </Text>
                </Box>
                <Box
                  className={`${styles.ag_courses_item_content} ${styles.ag_courses_item_date_box}`}
                >
                  <Box className={`${styles.icon_wrapper}`}>
                    <Image src={brand} alt="Icon" />
                  </Box>
                  <Text className={`${styles.ag_courses_item_text}`}>
                    {asset.brand}
                  </Text>
                </Box>
                <Box
                  className={`${styles.ag_courses_item_content} ${styles.ag_courses_item_date_box}`}
                >
                  <Box className={`${styles.icon_wrapper}`}>
                    <Image src={operator} alt="Icon" />
                  </Box>
                  <Text className={`${styles.ag_courses_item_text}`}>
                    {asset.operator}
                  </Text>
                </Box>
              </Box>

              <Box className={`${styles.ag_courses_item_link}`}>
                {/* <Text fontSize={"15px"} className={`${styles.ag_courses_item_text}`}>
                                        {asset.description}
                                    </Text> */}

                <Button
                  fontWeight="100px"
                  backgroundColor="#f2d98c"
                  onClick={() =>
                    openModalWithDescription(
                      asset.description,
                      asset.projectName
                    )
                  }
                >
                  <Image marginRight="5px" src={info} alt="Icon" />
                  <Text>Show Description</Text>
                </Button>
              </Box>
            </Box>
          ))}
        </SimpleGrid>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{assetName} Description</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {selectedDescription.split("\n").map((line, index) => (
                <Text key={index} mb={2}>
                  {line}
                </Text>
              ))}{" "}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    );
  };

  const openModalWithDescription = (description, projectName) => {
    setSelectedDescription(description);
    setAssetName(projectName);
    onOpen();
  };

  const setIdsinLocalStorage = () => {
    let myAssetsIds = myAssets
      .filter((asset) => asset.selected)
      .map((asset) => asset.id);
    let compAssetsIds = compset
      .filter((asset) => asset.selected)
      .map((asset) => asset.id);

    dispatch(
      setAggregation(myAssetsIds.length + compAssetsIds.length > 5)
    );
    localStorage.setItem("myAssetsIds", JSON.stringify(myAssetsIds));
    localStorage.setItem("compAssetsIds", JSON.stringify(compAssetsIds));
    history.push("/AssetComp/calculation");

  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <MainTitle title={"Review Selection"} />
        {myAssetsIdsNew.length + compAssetsIdsNew.length > 5 ? (
          <Box>
            <Text>
              * You selected more than 5 assets and will only be able to see an
              aggregate comparison
            </Text>
          </Box>
        ) : (
          ""
        )}

        <Tooltip label="Next">
          <IconButton
            // width="80px"
            lineHeight="24px"
            fontWeight="400px"
            fontSize="15px"
            borderRadius="5px"
            backgroundColor="#f2d98c"
            icon={<Image src={rightArrow} />}
            onClick={() => {

              setIdsinLocalStorage();
            }}
          />
        </Tooltip>
      </Box>

      {/* <MainBox> */}

      {isLoading ? (
        <Box width="100%" paddingY={10}>
          <Center>
            <Spinner />
          </Center>
        </Box>
      ) : (
        ""
      )}

      <Accordion
        className="accord"
        width="100%"
        color="black"
        defaultIndex={[0, 1]}
        allowMultiple
      >
        {myAssetsIds.length > 0 && (
          <AccordionItem className="accordion_main" mb="4">
            <Heading
              borderLeft="1px"
              borderBottom="1px"
              borderRight="1px"
              borderColor="#E6E6E6"
            >
              <AccordionButton bg="#FCFCFC">
                <Box flex="1" textAlign="left">
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                  >
                    My Assets
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Heading>

            <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
              <Flex
                width="100%"
                zIndex="3"
                justifyContent="flex-end"
                gap="20px"
              >
                <Box>
                  <Button
                    onClick={() => history.push(`/AssetComp/myAssets`)}
                    lineHeight="24px"
                    fontWeight="400px"
                    fontSize="15px"
                    borderRadius="5px"
                    backgroundColor="#ebc03f"
                  >
                    My Assets
                  </Button>
                </Box>
              </Flex>
              {myAssetsIds.length > 0 && renderCard(myAssets, "myAssests")}
            </AccordionPanel>
          </AccordionItem>
        )}
        {compAssetsIds.length > 0 && (
          <AccordionItem className="accordion_main" mb="4">
            <Heading
              borderLeft="1px"
              borderBottom="1px"
              borderRight="1px"
              borderColor="#E6E6E6"
            >
              <AccordionButton bg="#FCFCFC">
                <Box flex="1" textAlign="left" cursor="pointer">
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                    cursor="pointer"
                  >
                    {" "}
                    Compset Assets
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Heading>

            <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
              <Flex
                width="100%"
                zIndex="3"
                justifyContent="flex-end"
                gap="20px"
              >
                <Box>
                  <Button
                    lineHeight="24px"
                    fontWeight="400px"
                    fontSize="15px"
                    borderRadius="5px"
                    backgroundColor="#ebc03f"
                    onClick={() => history.push(`/AssetComp/compset`)}
                  >
                    Compset Assets
                  </Button>
                </Box>
              </Flex>
              {renderCard(compset, "compAsset")}
            </AccordionPanel>
          </AccordionItem>
        )}
      </Accordion>

      {/* </MainBox> */}
    </>
  );
};

export default Review;
