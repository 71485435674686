import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useIsMobile } from "../../utils/hooks";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Stack } from "@chakra-ui/react";
import { GetEmbedToken, GetReportFilters } from "../../api/DashboardAPI";
import { useParams, useNavigate } from "react-router-dom";
import { SummaryBox } from "./disclaimers";

export const DashboardOverview = () => {
  const params = useParams();
  const navigate = useNavigate();
  let user;
  let useRregion;
  if (Cookies.get("user_info")) {
    user = jwt_decode(Cookies.get("user_info"));
    useRregion = user.regions?.[0] || "";
    useRregion = useRregion.toLowerCase();
  }

  const [reportLevelOptions, setReportLevelOptions] = useState(() => {
    if (user.roles.includes("ceo") || user.roles.includes("investor") || user.roles.includes("visitor")) {
      return [
        {
          label: "NEOM Level",
          value: "neomLevel",
        },
      ];
    }
    if (
      user.roles.includes("admin") ||
      user.roles.includes("head of department") || user.roles.includes("manager")
    ) {
      return [
        {
          label: "NEOM Level",
          value: "neomLevel",
        },
        {
          label: "Region Level",
          value: "regionalLevel",
        },
        {
          label: "Asset Level",
          value: "assetLevel",
        },
      ];
    }
    return [
      {
        label: "Region Level",
        value: "regionalLevel",
      },
      {
        label: "Asset Level",
        value: "assetLevel",
      },
    ];
  });



  const [accessToken, setAccessToken] = useState("");
  const [reportFilters, setReportFilters] = useState("");
  const [gotReportFilters, setGotReportFilters] = useState(false);
  const dashboardParams = {
    desktop: {
      neomLevel: {
        id: process.env.REACT_APP_DASHBOARD_OVERVIEW_NEOM_ID,
        embedUrl: process.env.REACT_APP_DASHBOARD_OVERVIEW_NEOM,
        pageName: "ReportSectionfcdcfd7b1a4780750e26",
      },
      regionalLevel: {
        id: process.env.REACT_APP_DASHBOARD_OVERVIEW_REGION_ID,
        embedUrl: process.env.REACT_APP_DASHBOARD_OVERVIEW_REGION,
        pageName: "ReportSection4f3f6125c1251841c7cc",
      },
      assetLevel: {
        id: process.env.REACT_APP_DASHBOARD_OVERVIEW_ASSET_ID,
        embedUrl: process.env.REACT_APP_DASHBOARD_OVERVIEW_ASSET,
        pageName: "ReportSection701782b2f3c5c5e17aab",
      },
    },
    mobile: {
      neomLevel: {
        id: "3123da9b-069f-491b-addc-6ba0963632b5",
        // embedUrl:
        //   "https://app.powerbi.com/reportEmbed?reportId=3123da9b-069f-491b-addc-6ba0963632b5&groupId=b3389fb5-e6b4-4930-b13f-90c304af54ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19",
        embedUrl:
          "https://app.powerbi.com/reportEmbed?reportId=fc829513-629e-4ae0-a14f-6a18bc3751e5&autoAuth=true&ctid=8288fdf2-0e8b-47a6-abbb-004395ecab56",
        pageName: "ReportSection2ae0ac05a18b8a716017",
      },
      regionalLevel: {
        id: "9981df6b-0891-4b70-a2c0-4f36d7da108a",
        // embedUrl:
        //   "https://app.powerbi.com/reportEmbed?reportId=9981df6b-0891-4b70-a2c0-4f36d7da108a&groupId=b3389fb5-e6b4-4930-b13f-90c304af54ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19",
        embedUrl:
          "https://app.powerbi.com/reportEmbed?reportId=eb353b6a-78b3-48ed-881e-bf884c02ef61&autoAuth=true&ctid=8288fdf2-0e8b-47a6-abbb-004395ecab56",
        pageName: "ReportSection2ae0ac05a18b8a716017",
      },
      assetLevel: {
        id: "c2f2d96d-ffa8-4a87-8ff4-476c4111296f",
        // embedUrl:
        //   "https://app.powerbi.com/reportEmbed?reportId=c2f2d96d-ffa8-4a87-8ff4-476c4111296f&groupId=b3389fb5-e6b4-4930-b13f-90c304af54ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19",
        embedUrl:
          "https://app.powerbi.com/reportEmbed?reportId=5852b172-c0fa-4d49-95c6-2525efd5ad42&autoAuth=true&ctid=8288fdf2-0e8b-47a6-abbb-004395ecab56",
        pageName: "ReportSection2ae0ac05a18b8a716017",
      },
    },
  };

  const isMobile = useIsMobile();

  const screenSizeToUse = isMobile ? "mobile" : "desktop";
  const [reportLevelToUse, setReportLevel] = useState(params.dashboardLevel);
  useEffect(() => {
    if (
      !reportLevelOptions.some(
        (reportLevel) => reportLevel.value === params.dashboardLevel
      )
    ) {
      setReportLevel(reportLevelOptions[reportLevelOptions.length - 1].value);
      navigate(
        `/dashboard/overview/${reportLevelOptions[reportLevelOptions.length - 1].value
        }`
      );
    }
  }, [navigate, reportLevelOptions, params.dashboardLevel]);
  const [isEmbedShown, setIsEmbedShown] = useState(
    ["neomLevel", "regionalLevel", "assetLevel"].includes(reportLevelToUse)
  );

  const eventHandlers = new Map([
    [
      "loaded",
      function () {
        console.log("Report loaded");
      },
    ],
    [
      "rendered",
      function () {
        console.log("Report rendered");
      },
    ],
    [
      "error",
      function (event) {
        console.log(event.detail);
      },
    ],
  ]);

  useEffect(() => {
    GetEmbedToken()
      .then((token) => {
        if (token) {
          setAccessToken(token);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    GetReportFilters(reportLevelToUse)
      .then((filter) => {
        setReportFilters(filter);
        setGotReportFilters(true);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const embedConfig = {
    type: "report",
    id: dashboardParams[screenSizeToUse][reportLevelToUse]?.id,
    embedUrl:
      dashboardParams[screenSizeToUse][reportLevelToUse]?.embedUrl +
      reportFilters,
    pageName: dashboardParams[screenSizeToUse][reportLevelToUse]?.pageName,
    accessToken,
    tokenType: models.TokenType.Aad,
    settings: {
      panes: {
        pageNavigation: {
          visible: false,
        },
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
  };

  useEffect(() => {
    setIsEmbedShown(false);
    setTimeout(() => {
      if (
        ["neomLevel", "regionalLevel", "assetLevel"].includes(reportLevelToUse)
      ) {
        GetReportFilters(reportLevelToUse)
          .then((filter) => {
            setReportFilters(filter);
            setGotReportFilters(true);
          })
          .catch((err) => {
            console.error(err);
          });
        setIsEmbedShown(true);
      }
    }, 250);
  }, [reportLevelToUse, screenSizeToUse]);

  const frameParams =
    reportFilters +
    "&filterPaneEnabled=false&pageNavigation=false&&navContentPaneEnabled=false";

  return (
    <div className={"Embed-frame"}>
      {!user.roles.includes("ceo") ? (
        <Flex mb="20px" gap="20px" justifyContent="space-between">
          <Box maxW={["100%", "100%", "30%"]} width={["100%", "100%", "50%"]}>

            <SelectListMulti
              dropdownItems={reportLevelOptions}
              label="Viewing On"
              inputId="regionLookup"
              inputName="regionLookup"
              placeholder="Select"
              errors={{}}
              isRequired
              values={reportLevelToUse}
              onChange={(fieldName, value) => {
                navigate(`/dashboard/overview/${value}`);
                setReportLevel(value);
              }}
              uppercase={true}
            />

          </Box>
          {/* {useRregion == "trojena" && reportLevelToUse == 'regionalLevel' ? <SummaryBox title="Test TEst TEst Test TEst TEst Test TEst TEst Test TEst TEst Test TEst TEst Test TEst TEst Test TE"/>
            : ''

          } */}


        </Flex>
      ) : null}
      {/*{isEmbedShown && accessToken && gotReportFilters ? (*/}
      {/*  <PowerBIEmbed*/}
      {/*    embedConfig={embedConfig}*/}
      {/*    eventHandlers={eventHandlers}*/}
      {/*    cssClassName={"Embed-container"}*/}
      {/*    getEmbeddedComponent={(embeddedReport) => {*/}
      {/*      window.report = embeddedReport;*/}
      {/*    }}*/}
      {/*  />*/}
      {/*) : null}*/}
      {isEmbedShown && gotReportFilters ? (
        <iframe
          className={"Embed-container"}
          title="Embed"
          src={
            dashboardParams[screenSizeToUse][reportLevelToUse]?.embedUrl +
            frameParams
          }
          frameBorder="0"
          allowFullScreen={false}
        />
      ) : null}
    </div>
  );
};
