import React, { useState, useEffect } from "react";
import { Box, Text, Center, Button, Image } from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import styles from "../../components/forms.module.scss";
import tableRightArrow from "../../assets/images/icons/tableRightArrow.svg";
import searchIcon from "../../assets/images/icons/search.svg";
import plus from "../../assets/images/icons/plus.svg";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { MainButton } from "../../components/button/MainButton";

export const TablePagination = ({
  data,
  columns,
  onPressBtn,
  isSearchable,
  title,
  hideBox,
  hasDelete,
}) => {
  const [tableData, setTableData] = useState(data);
  const [search, setSearch] = useState(tableData);

  const [pages, setPages] = useState([]);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [pageToShow, setPageToShow] = useState(1);
  const [leftLeftSide, setLeftLeftSide] = useState(0);
  const [leftRightSide, setLeftRightSide] = useState(3);
  const temp = [];
  const [isTextDisabled, setIsTextDisabled] = useState(true);
  let pageSize = 10;
  let totalRows = isSearchable
    ? Math.ceil(search.length / pageSize)
    : Math.ceil(tableData.length / pageSize);

  const handlePageChange = (event, page) => {
    setPageToShow(page);
  };
  const handleNext = () => {
    if (pageToShow !== totalRows) setPageToShow(pageToShow + 1);
  };
  const handlePrev = () => {
    if (pageToShow !== 1) setPageToShow(pageToShow - 1);
  };

  //filter data
  const handleSearchChange = (e) => {
    if (tableData.length === 0) return;
    const value = e.target.value;

    const newFilter = [...tableData].filter(
      (val) =>
        val.assetName.toLowerCase().includes(value.toLowerCase()) ||
        val.region.toLowerCase().includes(value.toLowerCase()) ||
        val.location.toLowerCase().includes(value.toLowerCase()) ||
        val.assignedTo.toLowerCase().includes(value.toLowerCase())
    );

    //console.log("newFilter", newFilter);

    if (newFilter.length > 0) {
      setPageToShow(1);
      setLeftLeftSide(0);

      if (newFilter.length / pageSize !== pages.length) {
        setLeftRightSide(3);
      } else {
        setLeftRightSide(3);
      }
      setSearch(newFilter);
      totalRows = Math.ceil(newFilter.length / pageSize);
      //console.log("toootal rows", totalRows);
    }

    if (newFilter.length === 0) {
      setLeftLeftSide(0);
      setLeftRightSide(0);
      setSearch(newFilter);
    }

    if (
      totalRows - 1 === leftRightSide ||
      newFilter.length === 0 ||
      Math.ceil(newFilter.length / pageSize) === leftRightSide + 1
    ) {
      setIsTextDisabled(false);
    } else {
      if (totalRows !== 2) {
        setIsTextDisabled(true);
      } else {
        setIsTextDisabled(false);
      }
    }

    if (e.target.value === "") {
      setLeftLeftSide(0);
      setLeftRightSide(3);
      if (totalRows !== 2) {
        setIsTextDisabled(true);
      } else {
        setIsTextDisabled(false);
      }
    }
  };

  useEffect(() => {
    for (var i = 0; i < totalRows; i++) {
      temp[i] = totalRows - i;
    }
    const reversed = temp.slice().reverse();

    setPages(reversed);

    if (totalRows - 1 <= leftRightSide) {
      setIsTextDisabled(false);
    }
  }, []);

  useEffect(() => {
    if (pageToShow === leftLeftSide + 1 && pageToShow !== 1) {
      setLeftLeftSide(leftLeftSide - 1);
      setLeftRightSide(leftRightSide - 1);

      if (totalRows - 1 === leftRightSide) {
        setIsTextDisabled(true);
      }
    }
    if (pageToShow === leftRightSide && pageToShow !== totalRows - 1) {
      setLeftRightSide(leftRightSide + 1);
      setLeftLeftSide(leftLeftSide + 1);
      if (totalRows - 1 === leftRightSide + 1) {
        setIsTextDisabled(false);
      }
    }
    if (pageToShow === totalRows) {
      setLeftRightSide(totalRows - 1);
      setLeftLeftSide(totalRows - 4);
      setIsTextDisabled(false);
    }

    if (pageToShow === 1) setDisablePrev(true);
    else setDisablePrev(false);

    if (pageToShow === totalRows) setDisableNext(true);
    else setDisableNext(false);

    if (totalRows === 0) {
      setDisableNext(true);
      setDisablePrev(true);
    }
  }, [handleNext, handlePrev]);

  useEffect(() => {
    setTableData(tableData);
  }, [tableData]);
  /*   useEffect(() => {
    setTableData(data);
   }, [data]); */

  return (
    <>
      {isSearchable && (
        <Box display="flex" justifyContent="space-between">
          <MainTitle title={title} />
          <Box display="flex" maxWidth="50%" width="50%">
            <Box maxWidth="80%" width="80%" flex="1">
              <div className={styles.form_control}>
                <div
                  className={`${styles.form_control_input} ${styles.form_control_input_pl}`}
                >
                  <input
                    name="search"
                    placeholder="Search"
                    type="text"
                    id="search"
                    onChange={handleSearchChange}
                  />
                  <div className="iconic_left_input">
                    <img src={searchIcon} alt="icon" />
                  </div>
                </div>
              </div>
            </Box>
            <Box ml={3}>
              <MainButton
                title="Create new asset"
                icon={plus}
                btnHeight="48px"
                onPress={() => {
                  onPressBtn(true);
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Box pb={8} mr={3} mb={8} width="100%">
        {hideBox ? (
          <Box className="table_content" pt={3} pb={2} mb={5}>
            <DataTable
              columns={columns}
              data={data.slice(
                pageToShow * pageSize - pageSize,
                pageToShow * pageSize
              )}
            />
          </Box>
        ) : (
          <MainBox>
            <Box className="table_content" pt={3} pb={2}>
              {hasDelete ? (
                <DataTable
                  columns={columns}
                  data={[
                    ...data.slice(
                      pageToShow * pageSize - pageSize,
                      pageToShow * pageSize
                    ),
                  ]}
                />
              ) : (
                <DataTable
                  columns={columns}
                  data={search.slice(
                    pageToShow * pageSize - pageSize,
                    pageToShow * pageSize
                  )}
                />
              )}
            </Box>
            {/* <Box className="table_content" pt={3} pb={2}>
              <DataTable
                columns={columns}
                data={search.slice(
                  pageToShow * pageSize - pageSize,
                  pageToShow * pageSize
                )}
              />
            </Box> */}
          </MainBox>
        )}

        {data.length > 10 && (
          <Center mt="-3">
            <Button
              mx="1"
              border="1px solid #E2E2EA"
              bg="#FFFFFF"
              px="2.5 !important"
              py="3.5"
              size={{ base: "xs", md: "xs", lg: "xs" }}
              onClick={handlePrev}
              isDisabled={disablePrev}
            >
              {" "}
              <Box>
                <Image className="table_prev" src={tableRightArrow}></Image>
              </Box>
            </Button>

            {pages.slice(leftLeftSide, leftRightSide).map((page) => {
              if (page !== totalRows && page < totalRows) {
                return (
                  <Button
                    key={page}
                    page={page}
                    color="#6F767E"
                    bg="transparent"
                    size="xs"
                    mx="1"
                    fontSize="15"
                    borderRadius="lg"
                    isActive={pageToShow === page}
                    _active={{ color: "black" }}
                    onClick={(event) => handlePageChange(event, page)}
                  >
                    {page}
                  </Button>
                );
              }
              return null;
            })}

            {isTextDisabled && <Text fontSize="12">...</Text>}

            {pages.slice(totalRows - 1, totalRows).map((page) => (
              <Button
                key={page}
                page={page}
                color="#6F767E"
                mx="1"
                bg="transparent"
                size="xs"
                fontSize="15"
                borderRadius="lg"
                isActive={pageToShow === page}
                _active={{ color: "black" }}
                onClick={(event) => handlePageChange(event, page)}
              >
                {page}
              </Button>
            ))}

            <Button
              mx="1"
              border="1px solid #E2E2EA"
              bg="#FFFFFF"
              px="2.5 !important"
              py="3.5"
              size={{ base: "xs", md: "xs", lg: "xs" }}
              onClick={handleNext}
              isDisabled={disableNext}
            >
              {" "}
              <Box>
                <Image src={tableRightArrow}></Image>
              </Box>
            </Button>
          </Center>
        )}
      </Box>
    </>
  );
};
