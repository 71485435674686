import React from "react";

import { ErrorMessage, Field } from "formik";
import styles from "../forms.module.scss";

export const TextArea = ({
  label,
  inputId,
  inputName,
  labelVisibility = true,
  placeholder,
  errors,
  touched,
  values,
  onBlur,
  onChange,
  insideArr = false,
  isDisabled = false,
  isFormDirty,
  setIsFormDirty,
}) => {
  let inputProps;

  if (values !== undefined) {
    if (JSON.stringify(values) !== "{}") {
      if (values[inputName] !== null) {
        inputProps = {
          value: values[inputName],
        };
      }
    }

    const errorSelectClassname =
      errors[inputName] && touched[inputName] && errors[inputName]
        ? `${styles.form_control} ${styles.form_control_error}`
        : `${styles.form_control}`;
    return (
      <div className={errorSelectClassname}>
        {labelVisibility && <label htmlFor={inputId}>{label}</label>}
        <div className={styles.form_control_input}>
          {insideArr ? (
            <Field
              disabled={isDisabled}
              component="textarea"
              name={inputName}
              id={inputName}
              placeholder={placeholder}
              value={values}
              onChange={(val) => {
                if (setIsFormDirty && !isFormDirty) {
                  setIsFormDirty(true);
                }
                onChange(inputName, val.target.value);
              }}
            />
          ) : (
            <Field
              disabled={isDisabled}
              component="textarea"
              name={inputName}
              id={inputName}
              placeholder={placeholder}
              {...inputProps}
              onChange={(val) => {
                if (setIsFormDirty && !isFormDirty) {
                  setIsFormDirty(true);
                }
                onChange(inputName, val.target.value);
              }}
            />
          )}

          <ErrorMessage
            name={inputName}
            component="div"
            className={styles.error}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.form_control}>
        {labelVisibility && <label htmlFor={inputId}>{label}</label>}
        <div className={styles.form_control_input}>
          <textarea placeholder={placeholder}></textarea>
        </div>
      </div>
    );
  }
};
