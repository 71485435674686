import React, {useState} from "react";

import { useParams } from "react-router-dom";

import {
  Box,
  HStack,
  Text,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Heading,
} from "@chakra-ui/react";

import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainButton } from "../../components/button/MainButton";
import { QuickCalculationsTable } from "../../components/quickCalculationsTable/QuickCalculationsTable";

import leftArrow3 from "../../assets/images/icons/leftArrow3.svg";
import {ModalAlert} from "../../components/modalAlert/ModalAlert";

export const QuickCalculations = () => {
  let { id, projectNameValue } = useParams();
  const [ isLoading, setIsLoading ] = useState(false);

  return (
    <>
      <Box mb={3} display="inline-block">
        <MainButton
          title="Back"
          btnStyle="transparent"
          fontColor="dark"
          marginRight="1"
          navLink={true}
          icon={leftArrow3}
          path={`/AssetVersionHistory/${projectNameValue}`}
        />
      </Box>
      <MainTitle title={"Quick calculations"} />

      <HStack width="100%">
        <Accordion
          className="accord"
          width="100%"
          allowToggle
          color="black"
          defaultIndex={[0]}
        >
          <AccordionItem className="accordion_main" mb="4">
            <Heading
              borderLeft="1px"
              borderBottom="1px"
              borderRight="1px"
              borderColor="#E6E6E6"
            >
              <AccordionButton bg="#FCFCFC">
                <Box flex="1" textAlign="left">
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                  >
                    ADR vs Occupancy (Unlevered IRR)
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Heading>

            <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
              <QuickCalculationsTable
                titleY="Occupancy"
                titleX="ADR"
                id={id}
                projectNameValue={projectNameValue}
                type="ADRVSOccupancy"
                setIsLoading={setIsLoading}
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem className="accordion_main" mb="4">
            <Heading
              borderLeft="1px"
              borderBottom="1px"
              borderRight="1px"
              borderColor="#E6E6E6"
            >
              <AccordionButton bg="#FCFCFC">
                <Box flex="1" textAlign="left">
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                  >
                    Total Revenue vs Total CapEx (Unlevered IRR)
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Heading>
            <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
              <QuickCalculationsTable
                titleY="Total CapEx"
                titleX="Total Revenue"
                id={id}
                projectNameValue={projectNameValue}
                type="RevenueVSCapex"
                setIsLoading={setIsLoading}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem className="accordion_main" mb="4">
            <Heading
              borderLeft="1px"
              borderBottom="1px"
              borderRight="1px"
              borderColor="#E6E6E6"
            >
              <AccordionButton bg="#FCFCFC">
                <Box flex="1" textAlign="left">
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                  >
                    Equity % to Debt Interest Rate (Levered IRR)
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Heading>
            <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
              <QuickCalculationsTable
                titleY="Debt Interest Rate"
                titleX="Equity"
                id={id}
                projectNameValue={projectNameValue}
                type="EquityVSDebtInterest"
                setIsLoading={setIsLoading}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem className="accordion_main" mb="4">
            <Heading
              borderLeft="1px"
              borderBottom="1px"
              borderRight="1px"
              borderColor="#E6E6E6"
            >
              <AccordionButton bg="#FCFCFC">
                <Box flex="1" textAlign="left">
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                  >
                    Branded Residence Average Sales Price vs Branded Residences
                    Rental Pool Inclusion (Unlevered IRR)
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Heading>
            <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
              <QuickCalculationsTable
                titleY="Branded Residences Rental Pool Inclusion"
                titleX="Branded Residence Average Sales Price"
                id={id}
                projectNameValue={projectNameValue}
                type="BRSalesPriceVSBRRentalPoolInclusion"
                setIsLoading={setIsLoading}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </HStack>

      <ModalAlert
          isOpen={isLoading}
          disableClose={true}
          hasDesc={false}
          isSubmitLoading={true}
      />
    </>
  );
};
