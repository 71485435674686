import React, { useState, useEffect } from "react";

import { GetNotifications, UpdateViewdNotification, UpdateArchiveNotification } from "../api/DashboardAPI";

import {
  Box, Center, Text, Spinner, Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Heading, HStack
} from "@chakra-ui/react";
import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";
import { MainButton } from "../components/button/MainButton";
import { MainTitle } from "../components/mainTitle/MainTitle";
import { MainBox } from "../components/mainBox/MainBox";
import { NotificationBox } from "../components/notificationBox/NotificationBox";
import history from "../history";
import { stringFormat } from "../utils/General";

import leftArrow3 from "../assets/images/icons/leftArrow3.svg";
import buildings from "../assets/images/icons/buildings.svg";
import assumption from "../assets/images/icons/assump.svg";

export const Notifications = () => {
  const [notificationsArr, setNotificationsArr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleNotificationClicked = (id, url) => {
    // console.log("clicked", id);
    history.push(url);
    UpdateViewdNotification(id)
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleNotificationArchived = (id, archiveAll = false) => {
    // console.log("clicked", id);
    UpdateArchiveNotification(id, archiveAll)
      .then(() => {
        history.push('/Notifications')
        return GetNotifications();
      })
      .then((res) => {
        setNotificationsArr(res?.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
        // Handle error, e.g., show a user-friendly message
      });
  };
  useEffect(() => {
    GetNotifications()
      .then((res) => {
        // console.log("reees get notification", res);
        setNotificationsArr(res?.data);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        //setErrorMessage("Something went wrong");
        //onErrorOpen();
      });
  }, []);

  const renderNotifications = (archivedOnly) => {
    if (!!notificationsArr && notificationsArr.length > 0) {
      /* let iconLogic;
      let iconBg;

      if (item.name.includes("General")) {
        iconLogic = assumption;
        iconBg = "#F5DBC2";
      } else if (item.name.includes("Benchmarks")) {
        iconLogic = benchmark;
        iconBg = "rgba(0, 123, 181, 0.2)";
      } else {
        iconLogic = assumption;
        iconBg = "#F5DBC2";
      } */
      let notificationData = archivedOnly ? notificationsArr.filter((el) => el.isArchived) : notificationsArr.filter((el) => !el.isArchived)

      return notificationData.map((notif) => {
        let date = dateFnsParseISO(notif.createdAt);
        date = dateFnsFormat(date, "dd.MM.yyyy; HH:mm");
        let notifUrlId;
        let notifUrl;
        let newNotifUrl;
        if (
          `${notif.actionUrl}`.includes("Standard") ||
          `${notif.actionUrl}`.includes("Package")
        ) {
          newNotifUrl = `/AssetList${notif.actionUrl}`;
        } else {
          notifUrlId = `${notif.actionUrl}`?.split("/")?.pop();
          notifUrl = `${notif.actionUrl}`?.split("/")[1];
          // modifiedUrl = notifUrl.replace(/([A-Z])/g, " $1").replace(/^./, function(str){ return str.toUpperCase()})

          if (
            `${notif.actionUrl}`.includes("roomType") ||
            `${notif.actionUrl}`.includes("positioning") ||
            `${notif.actionUrl}`.includes("brand")
          ) {
            newNotifUrl = `/MasterDataCustom/${stringFormat(
              notifUrl
            )}/${notifUrlId}`;
          } else {
            newNotifUrl = `/MasterData/${stringFormat(notifUrl)}/${notifUrlId}`;
          }
        }
        console.log("newNotifUrl", newNotifUrl);
        return (
          <NotificationBox
            date={date}
            icon={buildings}
            title={notif.notificationText}
            url={newNotifUrl}
            actionLabel={notif.actionLabel}
            isUnread={notif.isUnread}
            notifId={notif.id}
            handleNotificationClicked={handleNotificationClicked}
            handleNotificationArchived={handleNotificationArchived}
            isCleared = {notif.isCleared}
            isArchived = {notif.isArchived}
          />
        );
      });
    } else {
      return (
        <MainBox>
          <Box paddingTop={12} paddingBottom={12}>
            <Center>
              <Text>You have no notifications.</Text>
            </Center>
          </Box>
        </MainBox>
      );
    }
  };

  return (
    <div>
      <Box display="inline-block">
        <MainButton
          title="Back"
          btnStyle="transparent"
          fontColor="dark"
          marginRight="1"
          navLink={true}
          icon={leftArrow3}
          path={"/"}
        />
      </Box>
      <MainTitle title="All Notifications " />
      {isLoading ? (
        <MainBox>
          <Box paddingTop={12} paddingBottom={12}>
            <Center>
              <Spinner />
            </Center>
          </Box>
        </MainBox>
      ) : (
        <HStack width="100%">
          <Accordion
            className="accord"
            width="100%"
            allowToggle
            color="black"
            defaultIndex={[0]}
          >
            <AccordionItem className="accordion_main" mb="4">
              <Heading
                borderLeft="1px"
                borderBottom="1px"
                borderRight="1px"
                borderColor="#E6E6E6"
              >
                <AccordionButton bg="#FCFCFC">
                  <Box flex="1" textAlign="left">
                    <Text
                      p="1"
                      pb="1"
                      pt="1"
                      fontSize="15px"
                      fontWeight="400"
                      letterSpacing="0.04em"
                    >
                      All Notifications
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Heading>

              <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
                {
                  notificationsArr.filter((el) => !el.isArchived).length > 0 ?
                    <Box flex="1" textAlign="left">


                      <Text
                        p="1"
                        pb="1"
                        pt="1"
                        fontSize="15px"
                        fontWeight="400"
                        letterSpacing="0.04em"
                        textAlign="right"
                        color="#007BB5"
                        className="textUnderLine"
                        cursor="pointer"
                        onClick={() => handleNotificationArchived(null, true)}
                      >
                        Archive all
                      </Text>
                      {renderNotifications(false)}

                    </Box> :

                    <Box paddingTop={12} paddingBottom={12}>
                      <Center>
                        <Text>No notifications at the moment.</Text>
                      </Center>
                    </Box>

                }
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="accordion_main" mb="4">
              <Heading
                borderLeft="1px"
                borderBottom="1px"
                borderRight="1px"
                borderColor="#E6E6E6"
              >
                <AccordionButton bg="#FCFCFC">
                  <Box flex="1" textAlign="left" 
                    cursor="pointer">
                    <Text
                      p="1"
                      pb="1"
                      pt="1"
                      fontSize="15px"
                      fontWeight="400"
                      letterSpacing="0.04em"
                      cursor="pointer"
                    >Archived Notifications
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Heading>

              <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
            

{
                 notificationsArr.filter((el) => el.isArchived).length > 0 ?
                  <Box flex="1" textAlign="left">

                      {renderNotifications(true)}

                    </Box> :

                    <Box paddingTop={12} paddingBottom={12}>
                      <Center>
                        <Text>No notifications at the moment.</Text>
                      </Center>
                    </Box>

                }
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </HStack>
      )}

      {/* <NotificationBox
        date="10.09.2022; 16:17"
        icon={buildings}
        title="Hotel Marina approved by Peter Griffin."
      /> */}
    </div>
  );
};
