import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as xlsx from "xlsx";

import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Box,
  VStack,
} from "@chakra-ui/react";

import { MainButton } from "../button/MainButton";

import rightArrow from "../../assets/images/icons/rightArrow.svg";

export const AssetsModal = ({
  isOpen,
  onClose,
  title = "Select seRvice offering",
  desc,
  icon,
  hasDesc,
  confirmationModal = false,
  path,
  pathDraft,
  btnWidthFull,
  haveSaveDraft = true,
  isColorsSwitched,
  disableClose = false,
}) => {
  let modalProps;
  let hideCloseBtn = !disableClose || !confirmationModal ? false : true;

  if (!disableClose) {
    modalProps = {
      onClose: onClose,
    };
  }

  const renderButtons = (confirmModal) => {
    return (
      <>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Box className="" width="100%">
            <VStack minW="100%" w="100%" gap="1">
              <Box width="100%" paddingY={2}>
                <Flex w="100%" justifyContent="center" mt={1} gap="25px">
                  <MainButton
                    title="Standard Offering"
                    btnStyle="gray"
                    reverseRow={true}
                    marginLeft="2"
                    icon={rightArrow}
                    navLink="true"
                    path={`/AssetList/Standard`}
                  />
                  <MainButton
                    title="Package Offering"
                    btnStyle="gray"
                    navLink="true"
                    path={`/AssetList/Package`}
                    reverseRow={true}
                    marginLeft="2"
                    icon={rightArrow}
                  />
                </Flex>
              </Box>
            </VStack>
          </Box>
        </Flex>
      </>
    );
  };

  return (
    <div className={disableClose ? "modal_disableClose" : ""}>
      <Modal isOpen={isOpen} {...modalProps}>
        <ModalOverlay
          background="rgba(29, 31, 34, 0.5)"
          backdropFilter="blur(7px)"
        />
        <ModalContent py={12} px={4} alignSelf="center" maxWidth="700px">
          <Center flexDirection="column">
            {icon && (
              <img src={icon} width="72px" height="72px" alt="checked icon" />
            )}
            <ModalHeader
              textAlign="center"
              color="#1D1F22"
              fontSize="20px"
              fontWeight="400"
              letterSpacing="-0.02em"
              px={5}
            >
              {title}
            </ModalHeader>
          </Center>

          {/*  {hideCloseBtn && <ModalCloseButton />} */}
          <ModalCloseButton />

          {hasDesc && (
            <ModalBody textAlign="center">
              <Center>
                <Text fontSize="15px">{desc}</Text>
              </Center>
            </ModalBody>
          )}

          <ModalFooter>{renderButtons(confirmationModal)}</ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
