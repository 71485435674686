// // MainComponent.js
import { React, useState, useEffect } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  Image,
  Tooltip,
  IconButton,
  Text
} from "@chakra-ui/react";
import { MainTitle } from "../../../components/mainTitle/MainTitle";

import { MainBox } from "../../../components/mainBox/MainBox";
import { useSelector, useDispatch } from "react-redux";
import {
  submitSelectedAssets,
  setAggregation,
} from "../../../redux/actions/calculation.js"; // Import the thunk action
import table from "../../../assets/images/icons/table.svg";
import history from "../../../history";
import LineChartComponent from "./comonLineChart";
import bar from "../../../assets/images/icons/barGraph.svg";
import line from "../../../assets/images/icons/lineGraph.svg";
import BarChartComponent from "./comonBarChart";
import cards from "../../../assets/images/icons/cards.svg";
import aggergatinIcon from "../../../assets/images/icons/aggergatinIcon.svg";

const MyChartComponent = () => {
  const compset = useSelector((state) => state.card.compset);
  const myAssets = useSelector((state) => state.card.myAssets);
  const isAggregation = useSelector((state) => state.calculation.isAggregation);
  const [aggregationMode, setAggregationMode] = useState(isAggregation);
  let myAssetsIds = myAssets
    .filter((asset) => asset.selected)
    .map((asset) => asset.id);
  let compAssetsIds = compset
    .filter((asset) => asset.selected)
    .map((asset) => asset.id);
  const dispatch = useDispatch();

  const loadStateFromLocalStorage = () => {
    try {
      const myAssetsIds = JSON.parse(localStorage.getItem("myAssetsIds"));
      const compAssetsIds = JSON.parse(localStorage.getItem("compAssetsIds"));
      // Dispatch action to set loaded state into Redux store
      dispatch(submitSelectedAssets(myAssetsIds, compAssetsIds));
    } catch (err) {
      console.error("Error loading state from local storage :", err);
    }
  };

  useEffect(() => {
    // Load state from local storage when the component mounts
    if (myAssets.length === 0 && compset.length === 0) {
      loadStateFromLocalStorage();
    } else dispatch(submitSelectedAssets(myAssetsIds, compAssetsIds));
  }, [dispatch]);

  const [graphType, setGraphType] = useState({
    adrPerYear: "line",
    revparPerYear: "line",
    occupancyPerYear: "line",
    gopPerYear: "line",
    ebitdaPerYear: "line",
    irr: "bar",
    capex: "bar",
    capexPerKey: "bar",
  }); // State to track graph type

  const graphs = [
    "adrPerYear",
    "revparPerYear",
    "occupancyPerYear",
    "gopPerYear",
    "ebitdaPerYear",
    "irr",
    "capex",
    "capexPerKey",
  ];

  const toggleGraphType = (graph) => {
    setGraphType((prevType) => ({
      ...prevType,
      [graph]: prevType[graph] === "line" ? "bar" : "line",
    })); // Toggle between line and bar
  };

  const renderGraph = (name, type) => {
    switch (name) {
      case "adrPerYear":
        return type === "line" ? (
          <LineChartComponent title="ADR" value="adrPerYear" />
        ) : (
          <BarChartComponent title="ADR" value="adrPerYear" />
        );
      case "revparPerYear":
        return type === "line" ? (
          <LineChartComponent title="RevPAR" value="revparPerYear" />
        ) : (
          <BarChartComponent title="RevPAR" value="revparPerYear" />
        );
      case "occupancyPerYear":
        return type === "line" ? (
          <LineChartComponent title="Occupancy" value="occupancyPerYear" />
        ) : (
          <BarChartComponent title="Occupancy" value="occupancyPerYear" />
        );
      case "gopPerYear":
        return type === "line" ? (
          <LineChartComponent title="GOP" value="gopPerYear" />
        ) : (
          <BarChartComponent title="GOP" value="gopPerYear" />
        );
      case "ebitdaPerYear":
        return type === "line" ? (
          <LineChartComponent title="EBITDA" value="ebitdaPerYear" />
        ) : (
          <BarChartComponent title="EBITDA" value="ebitdaPerYear" />
        );
      case "irr":
        return <BarChartComponent title="IRR" value="irr" />;
      case "capex":
        return <BarChartComponent title="Capex" value="capex" />;
      case "capexPerKey":
        return <BarChartComponent title="Capex per Key" value="capexPerKey" />;
    }
  };

  const toggleAggregation = () => {
    setAggregationMode(!aggregationMode);
    dispatch(setAggregation(!aggregationMode));
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <MainTitle title="Asset Comparison - Graph View" />
        {myAssetsIds.length + compAssetsIds.length > 5 ? <Box><Text>* You selected more than 5 assets and will only be able to see an aggregate comparison</Text></Box> : ""}

      </Box>
      <MainBox>
        <Flex width="100%" justifyContent="space-between" gap="20px">
          <Tooltip label="Go Back to Selection">
            <Image
              cursor="pointer"
              src={cards}
              onClick={() => history.push(`/AssetComp/review`)}
            />
          </Tooltip>
          <Tooltip label={myAssetsIds.length + compAssetsIds.length > 5 ? 'More than 5 assets selected. Select 5 or less assets to allow individual comparison' : aggregationMode ? 'Show Individual Assets' : 'Aggregate Assets'}>
            <IconButton
              icon={<Image src={aggergatinIcon} />}
              style={{ backgroundColor: aggregationMode ? "#caa122" : "white" }}
              cursor="pointer"
              src={aggergatinIcon}
              isDisabled={myAssetsIds.length + compAssetsIds.length > 5}
              onClick={() => toggleAggregation()}
            />
          </Tooltip>
          <Tooltip label="Table View">
            <Image
              cursor="pointer"
              src={table}
              onClick={() => history.push(`/AssetComp/calculation`)}
            />
          </Tooltip>
          {/* <Image cursor="pointer" onClick={()=> history.push('/AssetComp/graph')} src={graph} /> */}
        </Flex>
        <SimpleGrid mt="20px" columns={2} spacing={10}>
          {graphs.map((graph, index) => (
            <Box key={index}>
              <Flex justifyContent="end" size="md">
                {graph != "irr" &&
                  graph != "capex" &&
                  graph != "capexPerKey" && (
                    <Tooltip
                      label={`Switch to ${graphType[graph] == "line" ? "Column Graph" : "Line Graph"
                        } graph`}
                    >
                      <Image
                        cursor="pointer"
                        src={graphType[graph] == "line" ? bar : line}
                        onClick={() => toggleGraphType(graph)}
                      />
                    </Tooltip>
                  )}
                {/* <Tooltip label={`Download graph`}>

                                    <Image cursor="pointer" src={download} />
                                </Tooltip> */}
              </Flex>
              {renderGraph(graph, graphType[graph])}
            </Box>
          ))}
        </SimpleGrid>
        <Text fontSize="sm" color="gray.500" textAlign="center" mt={3}>
          * All figures are based on the property opening year aligning the first year of operations of each property.
        </Text>
        <Text fontSize="sm" color="gray.500" textAlign="center" mt={3}>
          * NHD uses the latest board-approved key counts and top-line figures.
        </Text>
        <Text fontSize="sm" color="gray.500" textAlign="center" mt={3}>
          * Note that any other data in this dashboard (for example, bottom-line and return figures) is based on NHD projections and might slightly differ from the official board-approved figures due to differences in modeling approaches.
        </Text>
        <Text fontSize="sm" color="gray.500" textAlign="center" mt={3}>
          * Do not communicate the figures in this dashboard at any time with any other party; the use of this dashboard is for informational purposes only.
        </Text>
      </MainBox>
    </>
  );
};

export default MyChartComponent;
