import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Flex,
  Divider,
  GridItem,
  SimpleGrid,
  Text,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Button,
  useEditableControls,
  EditableInput,
  Editable,
  EditablePreview,
  Input,
} from "@chakra-ui/react";
import { assetListStep5calcWeightedAvgRate } from "../../utils/Formula";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { InputFieldCalc } from "../../components/inputField/InputFieldCalc";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { FieldArray } from "formik";
import { isDecimalNumber } from "../../utils/General";

import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import percent from "../../assets/images/icons/filled.svg";
import editRow from "../../assets/images/icons/editRow.svg";
import removeRow from "../../assets/images/icons/removeRow.svg";
import pen from "../../assets/images/icons/penBlue.svg";
import { CalculationButton } from "../../components/calculationModal/calculationButton";

export const AssetsListForm6External = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  initFormVal,
  setInitFormVal,
  opStartDate,
  unsetStepWarning,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  step,
  setStepWarning,
  stepperUpdate,
  setStepperUpdate,
  validateStep,
  setValidateStep,
  modelWarningOpen,
  isModelWarningOpen,
  closeModalPath,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
  user,
}) => {
  // console.log("formik values tep 5", formikValues);
  const dataFetchedRef = useRef(false);
  const accordRef = useRef(null);
  let arrTitle = [
    "Food & Beverage",
    "Recreation",
    "Spa",
    formikValues?.departmentalExpenses?.other1.otherOneLabel,
    formikValues?.departmentalExpenses?.other2.otherTwoLabel,
  ];

  let form12Data = [];

  let emptyform12Data = [];
  let otherRevKeys = [
    "numberOfVisitors",
    "foodAndBeveragePerVisitor",
    "recreationPerVisitor",
    "spaPerVisitor",
    "otherOnePerVisitor",
    "otherTwoPerVisitor",
  ];
  otherRevKeys.forEach((el) => {
    formikValues.externalVisitors[el].forEach((item) => {
      form12Data.push(item);
    });
  });
  form12Data.forEach((item, ind) => {
    /* && Number.isInteger(item) */
    if (item !== "" && item >= "0") {
      emptyform12Data.push(item);
    }
  });

  let isDisabledForm = false;
  //console.log(emptyform12Data, form12Data);
  if (emptyform12Data.length !== form12Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  let currDate;
  currDate = new Date(formikValues?.startDateOpTime).getFullYear() - 1;

  const handleAccordErrors = () => {
    let openHigherAccord = true;

    form12Data.forEach((el, index) => {
      if (openHigherAccord) {
        if (el === "") {
          // console.log("index", index);
          if (index > 5) {
            handleOpenAccord(0);
            openHigherAccord = false;
          }
        }
      }
    });
  };

  const handleOpenAccord = (accordIndex) => {
    if (
      accordRef.current.querySelectorAll(
        ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
      )[accordIndex].ariaExpanded === "false"
    ) {
      accordRef.current
        .querySelectorAll(
          ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
        )
        [accordIndex].click();
    }
  };
  const resetDate = () => {
    currDate = new Date(formikValues?.startDateOpTime).getFullYear() - 1;
  };

  const onEditModelName = (event, labelName, name) => {
    let defultData;
    if (event !== "") {
      setFieldValue(labelName, event);
      if (setIsFormDirty && !isFormDirty) {
        setIsFormDirty(true);
      }
    }
    else {
      switch (name) {
        case "otherOnePerVisitor":
          defultData = "Other 1";
          break;
        case "otherTwoPerVisitor":
          defultData = "Other 2";
          break;
      }
      setFieldValue(labelName, defultData);
    }
  };
  const handleEditChange = (newValue, labelName) => {
    setFieldValue(labelName, newValue)
  };

  const EditableControls = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <Box display="flex" justifyContent="space-around" width="80px">
        <button {...getSubmitButtonProps()}>
          <img src={editRow} alt="edit" width="20px" />
        </button>
        <button {...getCancelButtonProps()}>
          <img src={removeRow} alt="edit" width="20px" />
        </button>
      </Box>
    ) : (
      <Box marginLeft="10px">
        <button {...getEditButtonProps()} size="md">
          <img src={pen} width="13px" alt="edit icon" />
        </button>
      </Box>
    );
  };

  const renderOtherRevenuesPerformance = (name, i) => {
    // console.log("name", name);
    let renameActive = false
    let key
    let otherName
    switch (name) {
      case "otherOnePerVisitor":
        renameActive = true
        key = "otherOneLabel"
        otherName= 'other1'
        break;
      case "otherTwoPerVisitor":
        renameActive = true
        key = "otherTwoLabel"
        otherName='other2'

        break;
    }
    return (
      <>
        <Box py={1} px={2}>
          <Text pb={3} textTransform="uppercase" fontSize="12px">
            {renameActive ? (<Editable
              value={arrTitle[i]}
              onChange={(value) => {
                handleEditChange(value, `departmentalExpenses.${otherName}.${key}`)
              }}


              fontSize="12px"
              // isPreviewFocusable={false}
              width="100%"
              display="flex"
              alignItems="center"
              className="title_md"
              onSubmit={(value) => {
                onEditModelName(value, `departmentalExpenses.${otherName}.${key}`, name)
              }}
            >
              <EditablePreview />
              {/* Here is the custom input */}
              <Input as={EditableInput} borderRadius={0} textAlign="left" />
              <EditableControls />
            </Editable>) : arrTitle[i]}
          </Text>
          <FieldArray
            name={`${name}`}
            render={(arrayHelper) => (
              <Box display="flex" width="90%" gap="2.5%">
                {formikValues.externalVisitors[name] !== undefined &&
                  formikValues.externalVisitors[name].map((_, index) => {
                    currDate = currDate + 1;
                    return (
                      <Box
                        width={["100%", "100%", "18%"]}
                        key={`${name}_${index}`}
                      >
                        <InputFieldValidation
                          label={`Year ${currDate}`}
                          placeholder="0"
                          inputType="number"
                          inputId={`externalVisitors.${name}.${index}`}
                          inputName={`externalVisitors.${name}.${index}`}
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={formikValues?.externalVisitors[name][index]}
                          labelCenter={true}
                          uppercase={false}
                          directVal={true}
                          // smallIcon={percent}
                          isDisabled={isRestore}
                          validateField={validateStep}
                          smallLeftIcon={
                            name === "meetingEvents" ? "SQM" : "SAR"
                          }
                          smallerIcon={name === "meetingEvents" ? "SQM" : "SAR"}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                        />
                      </Box>
                    );
                  })}
                {resetDate()}
              </Box>
            )}
          />
        </Box>
      </>
    );
  };

  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);

  let otherRevenuesTypes = [
    "foodAndBeverage",
    "recreation",
    "spa",
    "other1",
    "other2",
  ];

  useEffect(() => {
    if (step.warning) {
      if (!isNaN(opStartDate)) {
        var difference =
          new Date(formikValues?.startDateOpTime).getFullYear() - opStartDate;
        //console.log("difference");
        if (difference !== 0 && formikValues.externalVisitors !== undefined) {
          otherRevenuesTypes.forEach((element) => {
            setFieldValue(
              `externalVisitors.${element}`,
              formikValues?.externalVisitors[element].fill("")
            );
          });
        }
      }
    }
  }, []);

  return (
    <>
      <Box
        alignItems="flex-start"
        justifyContent="space-between"
        display="flex"
        width="100%"
        mb={5}
      >
        <MainTitle title=" External Visitors" />
        <CalculationButton formikValue={formikValues} />
      </Box>
      <Box py={1} px={2}>
        <Text pb={3} textTransform="uppercase" fontSize="12px">
          number Of Visitors
        </Text>
        <FieldArray
          name="numberOfVisitors"
          render={(arrayHelper) => (
            <Box display="flex" width="90%" gap="2.5%">
              {formikValues.externalVisitors.numberOfVisitors !== undefined &&
                formikValues.externalVisitors.numberOfVisitors.map(
                  (_, index) => {
                    currDate = currDate + 1;
                    return (
                      <Box
                        width={["100%", "100%", "18%"]}
                        key={`numberOfVisitors_${index}`}
                      >
                        <InputFieldValidation
                          label={`Year ${currDate}`}
                          placeholder="0"
                          inputType="number"
                          inputId={`externalVisitors.numberOfVisitors.${index}`}
                          inputName={`externalVisitors.numberOfVisitors.${index}`}
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={
                            formikValues?.externalVisitors.numberOfVisitors[
                              index
                            ]
                          }
                          labelCenter={true}
                          uppercase={false}
                          directVal={true}
                          // smallIcon={percent}
                          isDisabled={isRestore}
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                        />
                      </Box>
                    );
                  }
                )}
              {resetDate()}
            </Box>
          )}
        />
      </Box>
      <Accordion
        width="100%"
        allowToggle
        color="black"
        defaultIndex={[0]}
        ref={accordRef}
      >
        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  Revenues (Average spent per visitor)
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={6} border="1px" borderColor="#E6E6E6">
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              position="relative"
            ></Box>
            {formikValues?.externalVisitors?.foodAndBeveragePerVisitor &&
              renderOtherRevenuesPerformance("foodAndBeveragePerVisitor", 0)}
            {formikValues?.externalVisitors?.recreationPerVisitor &&
              renderOtherRevenuesPerformance("recreationPerVisitor", 1)}
            {formikValues?.externalVisitors?.spaPerVisitor &&
              renderOtherRevenuesPerformance("spaPerVisitor", 2)}
            {formikValues?.externalVisitors?.otherOnePerVisitor &&
              renderOtherRevenuesPerformance("otherOnePerVisitor", 3)}
            {formikValues?.externalVisitors?.otherTwoPerVisitor &&
              renderOtherRevenuesPerformance("otherTwoPerVisitor", 4)}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex
        justifyContent={user == "manager" ? "end" : "space-between"}
        alignItems="center"
        width="100%"
      >
        {user !== "manager" ? (
          <MainButton
            title="Save Draft"
            btnStyle="transparent"
            disabledBtn={isRestore}
            fontColor="dark"
            onPress={() => {
              setDismissSave(false);
              onDraftOpen();
            }}
          />
        ) : (
          <></>
        )}
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {/* <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm ? true : false}
              onPress={() => {
                nextPage();
                if (percentage < 55) {
                  setPercentage(55);
                }
                unsetStepWarning(page);
                setStepCompleted(page);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  handleAccordErrors();
                  setValidateStep(true);

                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  nextPage();
                  if (percentage < 55) {
                    setPercentage(55);
                  }
                  unsetStepWarning(page);
                  setStepCompleted(page);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
