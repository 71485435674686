import React from "react";
import { ErrorMessage, Field } from "formik";
import { Text, Box } from "@chakra-ui/react";

import styles from "../forms.module.scss";
import { el } from "date-fns/locale";

// - You can hide the label throw putting labelVisibility to false
export const InputFieldAverage = ({
  label,
  inputType,
  inputId,
  inputName,
  isRequired = true,
  labelVisibility = true,
  placeholder,
  errors,
  touched,
  dColumn = false,
  minNum,
  maxNum,
  fullWidth = "",
  icon,
  onChange,
  values,
  step,
  uppercase = true,
  labelCenter,
  mbBox,
  errorHeight0,
  leftIcon,
  leftIconText,
  currency,
  currencyConvertor,
  inputRef,
  disable,
  avg,
  directVal,
  noBg,
  validateField = false,
  optional = false,
  acceptNegative = false,
  smallLeftIcon = "",
  smallerIcon = false,
  setFieldTouched,
  quarter = false,
}) => {
  let inputProps;
  if (values) {
    if (directVal) {
      inputProps = {
        value: values,
      };
    } else {
      inputProps = {
        value: values[inputName],
      };
    }
  }
  let boxProps;
  let labelUppercaseClassname = uppercase ? `${styles.uppercase}` : "";

  labelUppercaseClassname += labelCenter ? ` ${styles.labelCenter} ` : "";

  if (mbBox) {
    boxProps = {
      mb: mbBox,
    };
  }
  let validateFieldLogic;
  if (optional) {
    if (validateField && directVal) {
      validateFieldLogic = validateField ? values != "" && values < 0 : false;
    } else if (validateField) {
      validateFieldLogic = validateField
        ? inputProps.value != "" && inputProps.value < 0
        : false;
    }
  } else {
    if (validateField && directVal) {
      if (acceptNegative) {
        validateFieldLogic = validateField
          ? values === "" || values > 100
          : false;
      } else {
        validateFieldLogic = validateField
          ? values === "" || values < 0
          : false;
      }
    } else if (validateField) {

      validateFieldLogic = validateField
        ? inputProps.value === "" || inputProps.value < 0
        : false;
    }
    // console.log("validateField 2", validateField);
  }

  const errorInputClassname =
    (errors[inputName] && touched[inputName] && errors[inputName]) ||
      validateFieldLogic
      ? `${styles.form_control} ${styles.form_control_error} ${dColumn ? "flex_column" : ""
      } `
      : `${styles.form_control} ${dColumn ? "flex_column" : ""}`;

  let formInputClassname = labelVisibility
    ? `${styles.form_control_input} ${fullWidth}`
    : `${styles.form_control_input} ${styles.form_control_full}  ${fullWidth}`;

  formInputClassname += labelCenter ? ` ${styles.textAlignCenter} ` : "";

  formInputClassname += noBg ? ` ${styles.fieldNoBg} ` : "";

  formInputClassname += leftIcon ? ` ${styles.haveLeftIcon} ` : "";
  /* let newInputName = `${inputName}`.split(".");
  if (JSON.stringify(errors) !== "{}") {
    console.log(
      "errors",
      errors,
      touched,
      errors[newInputName[0]],
      errors[newInputName[0]][newInputName[1]][newInputName[2]],
      inputName
    );
  } */
  const haveOnChangeMethod = onChange ? (
    <Field
      type={inputType}
      name={inputName}
      id={inputName}
      placeholder={placeholder}
      min={minNum}
      max={maxNum}
      step={step}
      validateOnChange={true}
      disabled={disable}
      onWheel={(e) => e.target.blur()}
      {...inputProps}
      onChange={(val) => {
        // console.log("enter on change");
        // setFieldTouched(inputName);
        if(quarter)
        onChange(inputName, parseInt(val.target.value));
      else
        onChange(inputName, val.target.value);

        let name = `${inputName}`.split(".");
        name = name.splice(0, name.length - 1);
        name = name.join(".");
        let nameAvg = name + ".average";
        let nameHigh = name + ".high";
        let nameLow = name + ".low";

        // setFieldTouched(nameAvg)
        if (!quarter)
          onChange(nameAvg, 0);

        if (inputRef) {
          if (
            inputRef.current.querySelector(`input[name="${nameHigh}"`) &&
            inputRef.current.querySelector(`input[name="${nameLow}"`)
          ) {
            if (
              inputRef.current.querySelector(`input[name="${nameHigh}"`)
                .value !== "" &&
              inputRef.current.querySelector(`input[name="${nameLow}"`)
                .value !== ""
            ) {
              onChange(
                nameAvg,
                (parseInt(
                  inputRef.current.querySelector(`input[name="${nameHigh}"`)
                    .value
                ) +
                  parseInt(
                    inputRef.current.querySelector(`input[name="${nameLow}"`)
                      .value
                  )) /
                2
              );

              inputRef.current.querySelector(`input[name="${nameAvg}"`).value =
                (parseInt(
                  inputRef.current.querySelector(`input[name="${nameHigh}"`)
                    .value
                ) +
                  parseInt(
                    inputRef.current.querySelector(`input[name="${nameLow}"`)
                      .value
                  )) /
                2;
            }
          }
        }
      }}
    />
  ) : (
    <input
      type={inputType}
      name={inputName}
      id={inputName}
      placeholder={placeholder}
      min={minNum}
      max={maxNum}
      step={step}
    />
  );

  return (
    <Box className={errorInputClassname} {...boxProps}>
      {labelVisibility && (
        <label
          htmlFor={inputId}
          className={`${fullWidth} ${labelUppercaseClassname}`}
        >
          {label}
        </label>
      )}
      <div className={formInputClassname}>
        {haveOnChangeMethod}
        {smallLeftIcon !== "" && (
          <div
            className={
              smallerIcon
                ? styles.input_left_icon_smaller
                : styles.input_left_icon_small
            }
          >
            <span>{smallLeftIcon}</span>
          </div>
        )}
        {leftIcon && (
          <div className={styles.input_left_icon}>
            <span>{leftIconText}</span>
          </div>
        )}

        {icon && (
          <div className="iconic_input">
            <img src={icon} alt="icon" />
          </div>
        )}
        {isRequired && (
          <ErrorMessage
            name={inputName}
            component="div"
            className={`${styles.error} ${errorHeight0 ? styles.h_0 : ""} ${labelCenter ? styles.textAlignCenter : ""
              }`}
          />
        )}
        {currencyConvertor && (
          <Box>
            <Text className="hint_sm" textAlign="center">
              <span className="currVal">0.00</span>
              <span className="currType"> {currency}</span>
            </Text>
          </Box>
        )}
      </div>
    </Box>
  );
};
