import React, { useState, useEffect, useRef } from "react";

import {
  Box,
  Text,
  Flex,
  Divider,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";
import { FieldArray } from "formik";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { InputFieldCalc } from "../../components/inputField/InputFieldCalc";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import {
  assetListStep4PackagecalcOccupancyIncField5,
  assetListStep4HandleCalcOcc,
} from "../../utils/Formula";
import { isDecimalNumber } from "../../utils/General";
import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import percent from "../../assets/images/icons/filled.svg";
import plus from "../../assets/images/icons/plus2.svg";
import deleteIcon2 from "../../assets/images/icons/delete2.svg";
import { nullifyYearsField } from "../../utils/General";
import {CalculationButton} from"../../components/calculationModal/calculationButton"

export const AssetsListForm4Package = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  formikValues,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  initFormVal,
  setInitFormVal,
  opStartDate,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  unsetStepWarning,
  step,
  packagePerformance = 1,
  setStepWarning,
  setPackageRooms,
  nextStep,
  occIncRate,
  setOccIncRate,
  isDisabledCalc,
  setIsDisabledCalc,
  isDisabledTypes,
  setIsDisabledTypes,
  setStepperUpdate,
  stepperUpdate,
  validateStep,
  setValidateStep,
  onClickedFormulaOpen,
  onClickedKeysOpen,
  modelWarningOpen,
  isModelWarningOpen,
  validateKeys,
  setValidateKeys,
  closeModalPath,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
  assetData,
  occIncRateGeneral,
  setOccIncRateGeneral,
  setIsCalculateClicked,
  isCalculateClicked,
  user
}) => {
  // const [roomTypes, setRoomTypes] = useState();
  const [roomTypes, setRoomTypes] = useState([...dropdownObj.packageTypes]);
  // const [results, setResults] = useState([]);
  const [results, setResults] = useState([...dropdownObj.packageTypes]);
  const dataFetchedRef = useRef(false);
  let step1TotalKeys = formikValues?.numberOfKeys;
  // const [isDisabledCalc, setIsDisabledCalc] = useState(true);
  const packageTypeGroup = { type: "", num: "", gpor: "", occRate: "" };
  let calcFields = ["calc1", "calc2", "calc3", "calc4", "calc5", "calc6"];
  let occData = [];
  let form4Data = [];
  form4Data.push(formikValues.pricingInflator); //string
  formikValues?.occupancyIncRateYear?.forEach((item, ind) => {
    if (ind !== 4) {
      form4Data.push(item);
      occData.push(item);
    }
  });
  form4Data.push(formikValues.avgLenOfStay); //string
  form4Data.push(formikValues.guestPerOccRoom); //string

  let emptyformOccData = [];
  let emptyform4Data = [];
  formikValues?.packageType?.forEach((item) => {
    form4Data.push(item.type);
    form4Data.push(item.num);
    form4Data.push(item.occRate);
    form4Data.push(item.gpor);
  });
  form4Data.forEach((item, ind) => {
    if (ind <= 5) {
      if (item !== "" && item >= "0" && item <= "100") {
        emptyform4Data.push(item);
      }
    } else if (ind === 6 || ind === 7) {
      // let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

      if (
        item !== "" &&
        parseFloat(item) >= 0 &&
        parseFloat(item) < 10 /* &&
        patternTwoDigisAfterComma.test(item) */
      ) {
        emptyform4Data.push(item);
      }
    } else {
      if (item !== "" && item >= "0") {
        emptyform4Data.push(item);
      }
    }
  });

  occData.forEach((item, ind) => {
    if (item !== "" && item >= "-100") {
      emptyformOccData.push(item);
    }
  });

  let isDisabledForm = false;
  // console.log(emptyform4Data, form4Data);
  if (emptyform4Data.length !== form4Data.length) {
    isDisabledForm = true;
  }
  if (
    JSON.stringify(emptyform4Data) == JSON.stringify(new Array(5).fill(0)) &&
    !isDisabledCalc
  ) {
    setIsDisabledCalc(true);
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  useEffect(() => {
    //let isDisabledCalculate = false;
    if (emptyformOccData.length !== occData.length) {
      if (!isDisabledCalc) {
        setIsDisabledCalc(true);
      }
    } else {
      let cont = false;
      if (occIncRate?.length > 0) {
        cont = true;
        formikValues.occupancyIncRateYear.forEach((el, ind) => {
          if (ind !== 4) {
            if (el != occIncRate[ind]) {
              cont = false;
            }
          }
        });
      }
      if (isDisabledCalc && !cont) {
        setIsDisabledCalc(false);
      }

      let newAssetData;
      if (assetData.status === "InitialDraft" || assetData.status === "Draft") {
        newAssetData = assetData?.formFields;
      } else {
        newAssetData = assetData;
      }
      if (!!newAssetData && !isCalculateClicked) {
        let isEmptyArr = formikValues.occupancyIncRateYear.filter(
          (el, ind) => ind != 4
        );
        if (
          JSON.stringify(
            newAssetData.topLineAssumptions_Package.occupancyIncreaseRate
          ) == JSON.stringify(formikValues.occupancyIncRateYear) &&
          isEmptyArr.length == 5
        ) {
          setIsDisabledCalc(true);
          // console.log("the same as Old one");
        }
        // console.log("isEmptyArr", isEmptyArr);
        // if(assetData.pricingInflator)
      } else if (occData?.length == 5 && isCalculateClicked) {
        // let isEmptyArr = occIncRateGeneral.filter((el, ind) => el != "");
        // console.log("create", occData, isEmptyArr);
        if (JSON.stringify(occData) == JSON.stringify(occIncRateGeneral)) {
          setIsDisabledCalc(true);
          // console.log("the same as Old one for draft and create");
        }
        // console.log("isEmptyArr 2", isEmptyArr);
      }
    }
    // console.log("change occ rate");
  }, [formikValues.occupancyIncRateYear]);

  const handleTotalKeys = (e, e2) => {
    //DO NOT REMOVE
  };

  useEffect(() => {
    let total = 0;
    formikValues?.packageType?.forEach((el) => {
      total += el.num;
    });
    if (total > step1TotalKeys) {
      setValidateKeys(true);
    } else {
      if (validateKeys) {
        setValidateKeys(false);
      }
    }
  }, [handleTotalKeys]);

  const handleNewlyAdded = () => {
    setStepWarning(page + 1);
    setPackageRooms(true);
  };
  const handleRemoveWarning = () => {
    if (
      formikValues?.packageType?.length - 1 === packagePerformance &&
      (nextStep.completed || nextStep.warning)
    ) {
      unsetStepWarning(page + 1);
      setStepCompleted(page + 1);
      setPackageRooms(false);
    }
  };

  const calcOccupancyIncField5 = (val = -1, index, fieldName) => {
    // console.log("calcOccupancyIncField5");
    let newInitObj = assetListStep4PackagecalcOccupancyIncField5(
      val,
      index,
      fieldName,
      formikValues
    );
    // console.log("intiob", newInitObj);
    if (newInitObj != "0") {
      // console.log("intiob", newInitObj, { ...formikValues, newInitObj });
      setInitFormVal({ ...newInitObj });
    }
  };

  const handleCalcOcc = (name, val) => {
    //console.log("fffffas", formikValues);
    //console.log("fffffaaassss", name, val);
    // console.log("clciked");
    if (!isDisabledCalc) {
      setIsDisabledCalc(true);
    }
    if (!isCalculateClicked) {
      setIsCalculateClicked(true);
    }
    if (occIncRate?.length > 0) {
      setOccIncRate([...occData]);
      setOccIncRateGeneral([...occData]);
      // setOccIncRate([...formikValues.occupancyIncRateYear]);
    }

    if (`${name}`.includes("packageType.")) {
      let packageTypeIndex = `${name}`.split(".")[1];

      // console.log("----here---");
      if (`${name}`.includes(".num")) {
        if (
          formikValues.packageType[packageTypeIndex].occRate != "" &&
          val !== ""
        ) {
          calcOccupancyIncField5(val, packageTypeIndex, "num");
        }
      } else if (`${name}`.includes(".occRate")) {
        if (
          formikValues.packageType[packageTypeIndex].num != "" &&
          val !== ""
        ) {
          calcOccupancyIncField5(val, packageTypeIndex, "occRate");
        }
      } else if (`${name}`.includes(".gpor")) {
        if (
          formikValues.packageType[packageTypeIndex].num != "" &&
          formikValues.packageType[packageTypeIndex].occRate != "" &&
          val !== ""
        ) {
          calcOccupancyIncField5(val, packageTypeIndex, "gpor");
        }
      }
    } else {
      if (!`${name}`.includes("occupancyIncRateYear.")) {
        let newInitObj = assetListStep4HandleCalcOcc(name, val, formikValues);
        if (newInitObj !== "packageType" || newInitObj != "notFound") {
          //console.log("intiob", newInitObj, { ...formikValues, ...newInitObj });
          setInitFormVal({ ...formikValues, ...newInitObj });
        }
      }
      // let newInitObj = assetListStep4HandleCalcOcc(name, val, formikValues);
      // if (newInitObj !== "packageType") {
      //   //console.log("intiob", newInitObj, { ...formikValues, ...newInitObj });
      //   setInitFormVal({ ...formikValues, ...newInitObj });
      // }
    }
  };
  useEffect(() => {
    // console.log("after 2", dropdownObj.packageTypes, formikValues);
    // let newRoomTypes = roomTypes.filter()
    // let newRoomTypes = roomTypes.filter(
    //   (val) => !formikValues?.packageType.includes(val.value)
    // );
    let newRoomTypes2 = [].concat(
      roomTypes.filter((obj1) =>
        formikValues?.packageType.every((obj2) => obj1.value !== obj2.type)
      )
    );
    // console.log("newRoomTypes", newRoomTypes, newRoomTypes2);
    setResults(newRoomTypes2);
  }, [formikValues.packageType]);

  let currDate = new Date(formikValues?.startDateOpTime).getFullYear();
  currDate = currDate - 1;
  const renderOccIncYearFields = () => {
    return (
      <FieldArray
        name="occupancyIncRateYear"
        render={(arrayHelper) => (
          <SimpleGrid columns={{ sm: 6, md: 6 }} spacing="15px" width="100%">
            {formikValues.occupancyIncRateYear !== undefined &&
              formikValues.occupancyIncRateYear.map((_, index) => {
                currDate = currDate + 1;
                return (
                  <GridItem key={`occupancyIncRateYear_${index}`}>
                    <InputFieldCalc
                      label={`Year ${currDate}`}
                      placeholder="0"
                      inputType="number"
                      inputId={`occupancyIncRateYear.${index}`}
                      inputName={`occupancyIncRateYear.${index}`}
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      onChange={setFieldValue}
                      minNum="-100"
                      values={
                        calcFields[index] === "calc5"
                          ? " "
                          : formikValues?.occupancyIncRateYear[index] == 0
                          ? "0"
                          : formikValues?.occupancyIncRateYear[index]
                      }
                      labelCenter={true}
                      //errorHeight0={true}
                      uppercase={false}
                      isDisabled={
                        calcFields[index] === "calc5" || isRestore
                          ? true
                          : false
                      }
                      noBg={calcFields[index] === "calc5" ? true : false}
                      currencyConvertor={true}
                      // calculatedOcc={true}
                      // onChangeCalc={handleCalcOcc}
                      currConvVal={
                        isDecimalNumber(formikValues[calcFields[index]])
                          ? parseFloat(formikValues[calcFields[index]]).toFixed(
                              2
                            )
                          : formikValues[calcFields[index]]
                      }
                      directVal={true}
                      smallIcon={percent}
                      seperatedFieldCalc={true}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                      onBlur={handleBlur}
                    />
                  </GridItem>
                );
              })}
          </SimpleGrid>
        )}
      />
    );
  };

  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);

  // useEffect(() => {
  //   var difference =
  //     new Date(formikValues?.startDateOpTime).getFullYear() - opStartDate;
  //   if (difference !== 0) {
  //     nullifyYearsField(
  //       step,
  //       opStartDate,
  //       formikValues,
  //       setFieldValue,
  //       formikValues?.startDateOpTime,
  //       ["occupancyIncRateYear"]
  //     );
  //   }
  // }, []);
  return (
    <>
      <Box alignItems="flex-start" justifyContent="space-between" display="flex" width="100%" mb={5}>
        <MainTitle title="Performance Asset List 1" />
        <CalculationButton  formikValue={formikValues} />

      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Box
          maxW={["100%", "100%", "65%"]}
          width={["100%", "100%", "65%"]}
          alignSelf="flex-start"
        >
          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            Top-Line Assumptions
          </Text>

          <InputFieldValidation
            label="Pricing Inflator (over inflation)"
            inputType="number"
            inputId="pricingInflator"
            inputName="pricingInflator"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            icon={percent}
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />

          <InputFieldValidation
            label="Average Length of Stay (in Days)"
            inputType="number"
            inputId="avgLenOfStay"
            inputName="avgLenOfStay"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            validateField={validateStep}
            // isThousand={true}
            onBlur={handleBlur}
          />

          <InputFieldValidation
            label="Guests per Occupied Room"
            inputType="number"
            inputId="guestPerOccRoom"
            inputName="guestPerOccRoom"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={true}
            validateField={validateStep}
            //isThousand={true}
            onBlur={handleBlur}
          />
          {(formikValues.guestPerOccRoom < 0 ||
            formikValues.guestPerOccRoom >= 10) && (
            <Box
              color="#e14642"
              fontWeight="400"
              fontSize="10px"
              lineHeight="17px"
              letterSpacing="0.04em"
              marginTop="0px"
            >
              The amount should be a decimal with maximum two digits after dot,
              and should be greater than 0
            </Box>
          )}
        </Box>
        <Box
          maxW={["100%", "100%", "100%"]}
          width={["100%", "100%", "100%"]}
          alignSelf="flex-start"
          className="addfield_wrapper"
        >
          <FieldArray name="packageType">
            {({ push, remove }) => (
              <Box container spacing={2} sx={{ marginTop: 2, paddingX: 2 }}>
                <Box>
                  <Text
                    className="title_sm"
                    display="flex"
                    mb={5}
                    mt={3}
                    component="div"
                    letterSpacing="0.06em"
                    fontSize="12px"
                  >
                    PACKAGE TYPES
                  </Text>
                </Box>
                {formikValues?.packageType?.map((_, index) => {
                  let placeHolder = dropdownObj.packageTypes.filter((el) => {
                    return el.value === formikValues?.packageType[index].type;
                  });
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      pb={4}
                      className={index == 0 ? "addfieldSec" : ""}
                      key={`packageType_${index}`}
                    >
                      <SimpleGrid
                        columns={{ sm: 4, md: 4 }}
                        spacingX="15px"
                        width="100%"
                      >
                        <GridItem colSpan={4}>
                          <SimpleGrid
                            columns={{ sm: 4, md: 4 }}
                            spacing="15px"
                            width="95%"
                          >
                            <GridItem>
                              <SelectListMulti
                                dropdownItems={results}
                                label="Type"
                                inputId={`packageType.${index}.type`}
                                inputName={`packageType.${index}.type`}
                                placeH={
                                  formikValues?.packageType[index]?.type === ""
                                    ? "Select"
                                    : placeHolder[0]
                                        ?.label /* formikValues?.packageType[index].type
                                      .split("_")
                                      .join(" ") */
                                }
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                isRequired
                                values={formikValues?.packageType[index].type}
                                onChange={setFieldValue}
                                onBlur={handleBlur}
                                uppercase={true}
                                directVal={true}
                                // onChange2={(e, e2) => handleChange(e, e2)}
                                isDynamic={true}
                                hideSelected={false}
                                placeHolderValue={false}
                                isDisabled={isRestore}
                                validateField={validateStep}
                              />
                            </GridItem>
                            <GridItem>
                              <InputFieldCalc
                                label="Number of keys"
                                inputType="number"
                                inputId={`packageType.${index}.num`}
                                inputName={`packageType.${index}.num`}
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                isRequired
                                minNum="0"
                                placeholder="0"
                                onChange={setFieldValue}
                                values={formikValues?.packageType[index].num}
                                calculatedOcc={true}
                                onChangeCalc={handleCalcOcc}
                                onChange2={(e, e2) => handleTotalKeys(e, e2)}
                                isDynamic={true}
                                directVal={true}
                                isDisabled={isRestore}
                                validateField={validateStep}
                                isThousand={true}
                                setFieldTouched={setFieldTouched}
                                onBlur={handleBlur}
                              />
                            </GridItem>
                            <GridItem>
                              <InputFieldCalc
                                label="GPOR"
                                inputType="number"
                                inputId={`packageType.${index}.gpor`}
                                inputName={`packageType.${index}.gpor`}
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                isRequired
                                minNum="0"
                                placeholder="0"
                                // icon={m2}
                                onChange={setFieldValue}
                                values={formikValues?.packageType[index].gpor}
                                directVal={true}
                                isDisabled={isRestore}
                                validateField={validateStep}
                                isThousand={true}
                                setFieldTouched={setFieldTouched}
                                onBlur={handleBlur}
                                calculatedOcc={true}
                                onChangeCalc={handleCalcOcc}
                              />
                            </GridItem>
                            <GridItem className="lable_fixed_height">
                              <InputFieldCalc
                                label="Occupancy Rate - stabilized"
                                inputType="number"
                                inputId={`packageType.${index}.occRate`}
                                inputName={`packageType.${index}.occRate`}
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                isRequired
                                minNum="0"
                                placeholder="0"
                                icon={percent}
                                onChange={setFieldValue}
                                values={
                                  formikValues?.packageType[index].occRate
                                }
                                calculatedOcc={true}
                                onChangeCalc={handleCalcOcc}
                                directVal={true}
                                isDisabled={isRestore}
                                validateField={validateStep}
                                isThousand={true}
                                setFieldTouched={setFieldTouched}
                                onBlur={handleBlur}
                              />
                            </GridItem>
                          </SimpleGrid>
                        </GridItem>
                      </SimpleGrid>
                      <Box>
                        {index > 0 && (
                          <Box>
                            <MainButton
                              title=""
                              icon={deleteIcon2}
                              disabledBtn={isRestore}
                              btnStyle="transparent"
                              fontColor="danger"
                              fSize="sm"
                              onPress={() => {
                                remove(index);
                                handleRemoveWarning();
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  );
                })}{" "}
                <Box>
                  <MainButton
                    title="Add package type"
                    btnStyle="transparent"
                    icon={plus}
                    fontColor="dark"
                    disabledBtn={
                      formikValues?.packageType?.length ==
                        dropdownObj.packageTypes.length || isRestore
                        ? true
                        : formikValues?.packageType?.length >= 10
                        ? true
                        : false
                    }
                    onPress={() => {
                      push(packageTypeGroup);
                      handleNewlyAdded();
                    }}
                  />
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
        <Box
          maxW={["100%", "100%", "80%"]}
          width={["100%", "100%", "80%"]}
          alignSelf="flex-start"
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            mt={4}
          >
            <Text
              fontSize="12px"
              color="black"
              fontWeight="400"
              letterSpacing="0.04em"
              textAlign="left"
              textTransform="uppercase"
              mt={3}
              mb={4}
            >
              Occupancy Ramp Up
              {/* Actual occupancy */}
            </Text>

            {renderOccIncYearFields()}

            <MainButton
              title="Calculate"
              disabledBtn={isDisabledCalc}
              onPress={() => {
                handleCalcOcc("occupancyIncRateYear", 0);
              }}
            />
          </Box>
        </Box>
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex justifyContent={user == "manager" ? "end" : "space-between"} alignItems="center" width="100%">
        {
          user !== "manager" ?
            <MainButton
              title="Save Draft"
              btnStyle="transparent"
              disabledBtn={isRestore}
              fontColor="dark"
              onPress={() => {
                setDismissSave(false);
                onDraftOpen();
              }}
            /> : <></>
        }
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {/* <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm || !isDisabledCalc ? true : false}
              onPress={() => {
                nextPage();
                unsetStepWarning(page);
                setStepCompleted(page);
                if (percentage < 46) {
                  setPercentage(46);
                }
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  if (!isDisabledCalc) {
                    onClickedFormulaOpen();
                  } else if (validateKeys) {
                    onClickedKeysOpen();
                  } else {
                    nextPage();
                    unsetStepWarning(page);
                    setStepCompleted(page);
                    if (percentage < 46) {
                      setPercentage(46);
                    }
                    document.getElementById("main_content").scrollTo(0, 0);
                  }
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
