import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  AddMasterDataRegionAPI,
  GetMasterDataRegionByIdAPI,
  GetMasterDataRegionDraftByIdAPI,
  GetMasterDataAssetCategoryByIdAPI,
  GetMasterDataAssetCategoryDraftByIdAPI,
  AddMasterDataAssetCategoryAPI,
  GetMasterDataRoomCategoryByIdAPI,
  GetMasterDataRoomCategoryDraftByIdAPI,
  AddMasterDataRoomCategoryAPI,
  GetMasterDataOperatorByIdAPI,
  GetMasterDataOperatorDraftByIdAPI,
  AddMasterDataOperatorAPI,
  GetMasterDataPackageTypeByIdAPI,
  GetMasterDataPackageTypeDraftByIdAPI,
  AddMasterDataPackageTypeAPI,
  GetMasterDataContractByIdAPI,
  GetMasterDataContractDraftByIdAPI,
  AddMasterDataContractAPI,
  GetMasterDataProjectStageByIdAPI,
  GetMasterDataProjectStageDraftByIdAPI,
  AddMasterDataProjectStageAPI,
  GetMasterDataProjectStatusByIdAPI,
  GetMasterDataProjectStatusDraftByIdAPI,
  AddMasterDataProjectStatusAPI,
} from "../../api/MasterDataAPI";
import history from "../../history";

import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";
import Cookies from "js-cookie";
import {
  Box,
  Divider,
  HStack,
  Tabs,
  Badge,
  Text,
  Flex,
  Spacer,
  useDisclosure,
  Image,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { MainBox } from "../../components/mainBox/MainBox";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { Formik, Form } from "formik";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { MainButton } from "../../components/button/MainButton";
import { TextArea } from "../../components/textArea/TextArea";
import { MasterDataTable } from "../../components/masterDataTable/MasterDataTable";
import { ToastAlert } from "../../components/toastAlert/ToastAlert";
import { TooltipElement } from "../../components/tooltip/TooltipElement";

import { parseToken } from "../../utils/Auth";

import checked from "../../assets/images/icons/checked.svg";
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import eye from "../../assets/images/icons/eye.svg";

export const MasterData = () => {
  let newToken = parseToken();
  let date;
  let lastMod;
  let submitModalTitle = "Master data is successfully saved";
  const toast = useToast();
  const { type, id, draftId } = useParams();
  const [initConf, setInitConf] = useState({});
  const [finalValues, setFinalValues] = useState({});
  const [initFormVal, setInitFormVal] = useState({});
  const [masterData, setMasterData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [disablePagination, setDisablePagination] = useState(false);
  const [validateErrors, setValidateErrors] = useState({});
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [data, setData] = useState([]);
  const [isNewError, setIsNewError] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  let typeSingular =
    type[`${type}`.length - 1].toLowerCase() === "s"
      ? type.slice(0, -1).toLowerCase()
      : type.toLowerCase();

  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isDraftOpen,
    onOpen: onDraftOpen,
    onClose: onDraftClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  if (!!masterData.lastModifiedAt) {
    date = dateFnsParseISO(masterData.lastModifiedAt);
    lastMod = dateFnsFormat(date, "dd.MM.yyyy; HH:mm");
  }

  const closeToast = () => {
    toast.closeAll();
  };
  const handleSave = () => {
    setTimeout(() => {
      setDisableSave(false);
    }, 400);
  };

  const onEditRecord = (editVal) => {
    if (editVal[initConf.cellVal] !== "") {
      let newIsActive = editVal.isActive === "active" ? true : false;
      let filter = finalValues.edits.filter((item) => {
        return item.value !== editVal.id;
      });

      const submitValues = {
        ...finalValues,
        edits: [
          ...filter,
          {
            value: editVal.id,
            newLabel: editVal[initConf.cellVal],
            ...(type.toLowerCase() === "regions" && {
              newIsActive: newIsActive,
            }),
          },
        ],
      };

      setFinalValues(submitValues);
    }
  };
  const onAddRecord = (addedval) => {
    const submitValues = {
      ...finalValues,
      additions: [
        ...finalValues.additions,
        {
          // value: addedval[`${initConf.cellVal}`]
          //   .toLowerCase()
          //   .split(" ")
          //   .join("_")
          //   .concat(uuidv4()),
          value: addedval[`${initConf.cellVal}`]
            .toLowerCase()
            .split(" ")
            .join("_")
            .replace(/[^A-Za-z0-9]+/g, "")
            .slice(0, 20)
            .concat(uuidv4()),
          label: addedval[`${initConf.cellVal}`],
          ...(type.toLowerCase() === "regions" && { isActive: true }),
        },
      ],
    };
    console.log("submitVal", submitValues);
    setFinalValues(submitValues);
  };
  const onRemoveRecord = (removedVal) => {
    const filteredData = finalValues.additions.filter(
      (item) => item.label !== removedVal[`${initConf.cellVal}`]
    );
    const submitValues = {
      ...finalValues,
      additions: [...filteredData],
    };
    setFinalValues(submitValues);
  };
  const onReorderRecords = (reorderRecords) => {
    let reorderArr = reorderRecords.map(
      (item) => item.id
      //item[`${initConf.cellVal}`].toLowerCase().split(" ").join("_")
    );

    const submitValues = {
      ...finalValues,
      ordering: [...reorderArr],
    };

    setFinalValues(submitValues);
  };
  const onSubmitForm = (values) => {
    if (!!id) {
      let commentsLogic =
        masterData.comments === values.addComment ? "" : values.addComment;

      const submitValues = {
        ...finalValues,
        comments: commentsLogic,
        //isDraft: false,
        parentVersionNumber:
          masterData.versionNumber || masterData.parentVersionNumber,
        ...(!!draftId && { draftVersionNumber: Number(draftId) }),
      };
      initConf
        .AddDataAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      const submitValues = {
        ...finalValues,
        comments: values.addComment,
        parentVersionNumber: -1,
      };

      initConf
        .AddDataAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };
  const handleSaveDraft = (values) => {
    if (!!id) {
      let commentsLogic =
        masterData.comments === values.addComment ? "" : values.addComment;
      const submitValues = {
        ...finalValues,
        comments: commentsLogic,
        versionNumber: !!draftId ? Number(draftId) : 0,
        parentVersionNumber: Number(id),
        isDraft: true,
        ...(!!draftId && { draftVersionNumber: Number(draftId) }),
      };

      initConf
        .AddDataAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            setErrorMessage("");
            setDisableSave(true);
            onDraftClose();
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      const submitValues = {
        ...finalValues,
        comments: values.addComment,
        isDraft: true,
        parentVersionNumber: Number(0),
      };

      initConf
        .AddDataAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            setErrorMessage("");
            setDisableSave(true);
            onDraftClose();
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };

  useEffect(() => {
    let initObj;
    switch (type) {
      case "Project status":
        initObj = {
          cellVal: "projectStatus",
          GetSingleDataAPI: GetMasterDataProjectStatusByIdAPI,
          GetSingleDataDraftAPI: GetMasterDataProjectStatusDraftByIdAPI,
          AddDataAPI: AddMasterDataProjectStatusAPI,
          actionColor: "#006B44",
        };
        setInitConf(initObj);
        break;

      case "Regions":
        initObj = {
          cellVal: "regionName",
          GetSingleDataAPI: GetMasterDataRegionByIdAPI,
          GetSingleDataDraftAPI: GetMasterDataRegionDraftByIdAPI,
          AddDataAPI: AddMasterDataRegionAPI,
          actionColor: "#6F767E",
        };
        setInitConf(initObj);
        setDisablePagination(true);
        break;

      case "Project stage":
        initObj = {
          cellVal: "projectStage",
          GetSingleDataAPI: GetMasterDataProjectStageByIdAPI,
          GetSingleDataDraftAPI: GetMasterDataProjectStageDraftByIdAPI,
          AddDataAPI: AddMasterDataProjectStageAPI,
          actionColor: "#006B44",
        };
        setInitConf(initObj);
        break;
      case "Contract":
        initObj = {
          cellVal: "contractType",
          GetSingleDataAPI: GetMasterDataContractByIdAPI,
          GetSingleDataDraftAPI: GetMasterDataContractDraftByIdAPI,
          AddDataAPI: AddMasterDataContractAPI,
          actionColor: "#006B44",
        };
        setInitConf(initObj);
        break;
      case "Package type":
        initObj = {
          cellVal: "packageType",
          GetSingleDataAPI: GetMasterDataPackageTypeByIdAPI,
          GetSingleDataDraftAPI: GetMasterDataPackageTypeDraftByIdAPI,
          AddDataAPI: AddMasterDataPackageTypeAPI,
          actionColor: "#006B44",
        };
        setInitConf(initObj);
        break;
      case "Operator":
        initObj = {
          cellVal: "operator",
          GetSingleDataAPI: GetMasterDataOperatorByIdAPI,
          GetSingleDataDraftAPI: GetMasterDataOperatorDraftByIdAPI,
          AddDataAPI: AddMasterDataOperatorAPI,
          actionColor: "#006B44",
        };
        setInitConf(initObj);
        break;
      case "Room category":
        initObj = {
          cellVal: "roomCategory",
          GetSingleDataAPI: GetMasterDataRoomCategoryByIdAPI,
          GetSingleDataDraftAPI: GetMasterDataRoomCategoryDraftByIdAPI,
          AddDataAPI: AddMasterDataRoomCategoryAPI,
          actionColor: "#006B44",
        };
        setInitConf(initObj);
        break;

      case "Asset category":
        initObj = {
          cellVal: "assetCategory",
          GetSingleDataAPI: GetMasterDataAssetCategoryByIdAPI,
          GetSingleDataDraftAPI: GetMasterDataAssetCategoryDraftByIdAPI,
          AddDataAPI: AddMasterDataAssetCategoryAPI,
          actionColor: "#006B44",
        };
        setInitConf(initObj);
        break;
      default:
        break;
    }
    document.getElementById("main_content").scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let formField;
    if (JSON.stringify(initConf) !== "{}") {
      if (!!id && !!draftId) {
        setIsReadOnly(false);
        setIsDraft(true);
        setErrorMessage("");
        initConf
          ?.GetSingleDataDraftAPI(id, draftId)
          .then((res) => {
            let lookupsParsed = res.data.lookups;
            let newDataArr = [];
            if (lookupsParsed.length > 0) {
              lookupsParsed.map((item) => {
                let dateItem = dateFnsParseISO(
                  item.lastModifiedAt,
                  "T",
                  new Date()
                );
                let lastModItem = dateFnsFormat(dateItem, "dd.MM.yyyy; HH:mm");
                let isActive;
                if (type.toLowerCase() === "regions") {
                  isActive = item.isActive ? "active" : "Inactive";
                } else {
                  isActive = "active";
                }
                newDataArr.push({
                  id: item.value,
                  [initConf.cellVal]: item.label,
                  //value: item.value,
                  lastModifiedAt: lastModItem,
                  lastModifiedBy: item.lastModifiedBy,
                  isActive: isActive,
                  action: ["edit"],
                  actionColor: initConf.actionColor,
                });
              });
            }

            const submitVal = {
              //comments: res.data.comments,
              comments: "",
              additions: [],
              edits: [],
              ordering: [],
            };
            setFinalValues(submitVal);
            setInitFormVal({
              reg: "",
              id: "",
              [initConf.cellVal]: "",
              lastModifiedAt: "",
              lastModifiedBy: "",
              action: ["edit"],
              actionColor: "",
              addComment: res.data.comments || "",
            });
            setData(newDataArr);
            setMasterData(res.data);
          })
          .catch((err) => {
            setErrorMessage("Something went wrong");
            onErrorOpen();
          });
      } else if (!!id) {
        setErrorMessage("");
        initConf
          ?.GetSingleDataAPI(id)
          .then((res) => {
            let newDataArr = [];
            if (res.data.lookups.length > 0) {
              res.data.lookups.map((item) => {
                let dateItem = dateFnsParseISO(
                  item.lastModifiedAt,
                  "T",
                  new Date()
                );
                let lastModItem = dateFnsFormat(dateItem, "dd.MM.yyyy; HH:mm");
                let isActive;
                if (type.toLowerCase() === "regions") {
                  isActive = item.isActive ? "active" : "Inactive";
                } else {
                  isActive = "active";
                }
                newDataArr.push({
                  id: item.value,
                  [initConf.cellVal]: item.label,
                  //value: item.value,
                  lastModifiedAt: lastModItem,
                  lastModifiedBy: item.lastModifiedBy,
                  isActive: isActive,
                  action: ["edit"],
                  actionColor: initConf.actionColor,
                });
              });
            }

            const submitVal = {
              //comments: res.data.comments,
              comments: "",
              additions: [],
              edits: [],
              ordering: [],
            };
            setFinalValues(submitVal);
            setInitFormVal({
              reg: "",
              id: "",
              [initConf.cellVal]: "",
              lastModifiedAt: "",
              lastModifiedBy: "",
              action: ["edit"],
              actionColor: "",
              addComment: res.data.comments || "",
            });
            setData(newDataArr);
            setMasterData(res.data);
            setIsReadOnly(res.data.status === "Archived");
          })
          .catch((err) => {
            setErrorMessage("Something went wrong");
            onErrorOpen();
          });
      } else {
        const submitVal = {
          comments: "",
          additions: [],
          edits: [],
          ordering: [],
        };
        setIsReadOnly(false);
        setFinalValues(submitVal);
        setInitFormVal({
          reg: "",
          id: "",
          [initConf.cellVal]: "",
          lastModifiedAt: "",
          lastModifiedBy: "",
          action: ["edit"],
          actionColor: "",
          addComment: "",
        });
      }
    }
  }, [initConf]);

  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      // Block navigation and register a callback that
      // fires when a navigation attempt is blocked.
      unblock = history.block((tx) => {
        // Navigation was blocked! Let's show a confirmation dialog
        // so the user can decide if they actually want to navigate
        // away and discard changes they've made in the current page.
        let url = tx.location.pathname;
        if (window.confirm(`Are you sure you want to discard the changes?`)) {
          // Unblock the navigation.
          unblock();

          // Retry the transition.
          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  return (
    <div>
      <MainBox>
        <HStack gap="10px" pb={8} pt={3}>
          <Box>
            {type === "Contract" ? (
              <MainTitle pb="0" pt="0" title={`Operating Model`} />
            ) : (
              <MainTitle pb="0" pt="0" title={`${type}`} />
            )}
          </Box>
          {type === "Asset category" && (
            <Box className="tooltip_field2" ml={3}>
              <TooltipElement label="" />
            </Box>
          )}
          {id !== undefined && (
            <HStack w="auto" px="2" pt="0.5">
              <Badge bg="rgba(0, 123, 181, 0.2)" className="masterDataBadge">
                Version Number: &nbsp;
                {masterData.status === "Draft" ||
                masterData.status === "InitialDraft"
                  ? masterData.parentVersionNumber
                  : masterData.versionNumber}
              </Badge>
              <Badge bg="rgba(241, 136, 37, 0.3)" className="masterDataBadge">
                Last Modified By: {masterData.lastModifiedBy}, {lastMod}
              </Badge>
            </HStack>
          )}
          <Spacer />
          {isReadOnly ? (
            <HStack
              px="2"
              py="1"
              borderRadius="5"
              fontSize="12"
              bg="rgba(0, 107, 68, 0.2);"
            >
              <Image src={eye}></Image>
              <Text>View Only</Text>
            </HStack>
          ) : (
            ""
          )}
        </HStack>
        <HStack
          width="100%"
          className={type === "Regions" ? "table_region" : ""}
        >
          <Tabs variant="unstyled" width="100%">
            <Divider orientation="horizontal" mt="0px" mb="30px" />

            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validate={(values) => {
                const errors = {};

                setValidateErrors(errors);
                setIsNewError("");
                return errors;
              }}
              onSubmit={(values, { setSubmitting, setFieldValue }) => {
                const tempObj = {
                  id: values[`${initConf.cellVal}`]
                    .trim()
                    .toLowerCase()
                    .split(" ")
                    .join("_"),
                  [initConf.cellVal]: values[`${initConf.cellVal}`].trim(),
                  lastModifiedAt: `-`,
                  lastModifiedBy: `${newToken.displayName}`,
                  action: ["edit", "delete"],
                  actionColor: "#E0403F",
                };

                let isNew = data.findIndex(
                  (item) =>
                    item[`${initConf.cellVal}`].toLowerCase().trim() ===
                    tempObj[`${initConf.cellVal}`].toLowerCase().trim()
                );
                // let format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                // let format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

                let format = /[`'"]/;
                if (format.test(values[`${initConf.cellVal}`])) {
                  toast({
                    position: "top-right",
                    isClosable: true,
                    duration: 5000,
                    render: () => (
                      <ToastAlert
                        title="You shouldn't have any special character."
                        icon={infoCircle}
                        closeToast={closeToast}
                        type="Warning"
                      />
                    ),
                  });
                } else if (isNew === -1) {
                  closeToast();
                  setData((data) => [tempObj, ...data]);
                  setSubmitting(false);
                  onAddRecord(tempObj);
                  setFieldValue(initConf.cellVal, "");
                } else {
                  //setIsNewError("Already Exists");
                  toast({
                    position: "top-right",
                    isClosable: true,
                    render: () => (
                      <ToastAlert
                        title={`Already exists`}
                        icon={infoCircle}
                        closeToast={closeToast}
                        type="Warning"
                      />
                    ),
                  });
                }
              }}
            >
              {({
                isSubmitting,
                errors,
                touched,
                values,
                handleChange,
                setFieldValue,
              }) => (
                <Form>
                  {!isReadOnly ? (
                    <HStack w="40%" alignItems="flex-end" mb={4}>
                      <InputFieldValidation
                        label={
                          type === "Regions"
                            ? `Add  ${typeSingular}`.toUpperCase()
                            : type === "Contract"
                            ? `Add  Operating Model`.toUpperCase()
                            : `Add  ${type}`.toUpperCase()
                        }
                        inputType="string"
                        inputId={`${
                          initConf.cellVal ? initConf.cellVal : "reg"
                        }`}
                        inputName={`${
                          initConf.cellVal ? initConf.cellVal : "reg"
                        }`}
                        errors={errors}
                        touched={touched}
                        isRequired
                        mbBox="0"
                        errorHeight0={true}
                      />

                      <MainButton
                        title="Add"
                        disabledBtn={
                          JSON.stringify(validateErrors) === "{}" &&
                          values[`${initConf.cellVal}`] !== ""
                            ? false
                            : true
                        }
                        onPress={() => {
                          handleSave();
                        }}
                        type="submit"
                        btnStyle="navyBlue"
                        btnHeight="48px"
                      />
                    </HStack>
                  ) : null}

                  <Text mt="6" fontSize="18" color="#000000">
                    List of{" "}
                    {type.slice(-2) === "us"
                      ? type.toLowerCase() + "es"
                      : type.slice(-1) === "y"
                      ? type.toLowerCase().slice(0, -1) + "ies"
                      : type.slice(-1) === "s"
                      ? type.toLowerCase()
                      : type.toLowerCase() + "s"}
                  </Text>

                  <Box width="100%" className="masterData_table">
                    <MasterDataTable
                      data={data}
                      setData={setData}
                      setDisableSave={setDisableSave}
                      disableSave={disableSave}
                      isDraggable={true}
                      type={type}
                      disablePagination={disablePagination}
                      onEditRecord={onEditRecord}
                      onRemoveRecord={onRemoveRecord}
                      onReorderRecords={onReorderRecords}
                      newToken={newToken}
                      isReadOnly={isReadOnly}
                    />
                  </Box>

                  {!isReadOnly ? (
                    <Box mb="3" mt="5" width="40%">
                      <TextArea
                        label="ADD COMMENT"
                        inputId="addComment"
                        inputName="addComment"
                        placeholder="Add description"
                        errors={errors}
                        touched={touched}
                        isRequired
                        onChange={setFieldValue}
                        value={values}
                        values={values}
                      />
                    </Box>
                  ) : null}
                  <Divider orientation="horizontal" mt="5" mb="8" />

                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <MainButton
                      title="Save Draft"
                      btnStyle="transparent"
                      fontColor="light"
                      disabledBtn={isReadOnly}
                      onPress={() => {
                        onDraftOpen();
                      }}
                    />
                    <Box display="flex" gap="30px">
                      {disableSave ? (
                        <MainButton
                          title="Cancel"
                          btnStyle="transparent"
                          fontColor="light"
                          navLink={true}
                          path={`/MasterDataVersions/${type}`}
                        />
                      ) : (
                        <MainButton
                          title="Cancel"
                          btnStyle="transparent"
                          fontColor="light"
                          onPress={() => {
                            setDismissSave(false);
                            onCancelOpen();
                          }}
                        />
                      )}
                      <MainButton
                        title="Save"
                        reverseRow={true}
                        disabledBtn={
                          isDraft ? false : disableSave ? true : false
                        }
                        onPress={() => {
                          onSubmitForm(values);
                        }}
                      />
                    </Box>
                  </Flex>

                  <ModalAlert
                    isOpen={isDraftOpen}
                    onClose={onDraftClose}
                    title="Are you sure you want to save this as draft?"
                    icon={infoCircle}
                    confirmationModal={true}
                    hasDesc={false}
                    haveSaveDraft={false}
                    path={`/MasterDataVersions`}
                    pathDraft={`/MasterDataVersions/${type}`}
                    onPressSaveDraft={() => handleSaveDraft(values)}
                  />
                  <ModalAlert
                    isOpen={isCancelOpen}
                    onClose={onCancelClose}
                    title="Are you sure you want to discard the changes?"
                    icon={infoCircle}
                    hasDesc={false}
                    confirmationModal={true}
                    path={`/MasterDataVersions/${type}`}
                    onPressSaveDraft={() => handleSaveDraft(values)}
                    setDismissSave={() => setDismissSave(true)}
                  />
                </Form>
              )}
            </Formik>
          </Tabs>
        </HStack>

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          icon={checked}
          hasDesc={false}
          btnWidthFull={true}
          path={`/MasterDataVersions/${type}`}
          disableClose={true}
        />

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />
      </MainBox>
    </div>
  );
};