import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";

export const activeMenuItem = () => {
  let assumpItem = document
    .querySelector(".sidebar_dropdown")
    ?.parentElement?.querySelector("#Assumptions");
  let benchItem = document
    .querySelector(".sidebar_dropdown")
    ?.parentElement?.querySelector("#Benchmark");
  let masterItem = document
    .querySelector(".sidebar_dropdown")
    ?.parentElement?.parentElement?.querySelector(".masterData");

  if (
    (window.location.pathname.includes("General") &&
      !window.location.pathname.includes("AddGeneral")) ||
    (window.location.pathname.includes("VisitorNum") &&
      !window.location.pathname.includes("NumHistory"))
  ) {
    assumpItem?.classList?.add("activeH3");
  } else if (
    (window.location.pathname.includes("Hotel") &&
      !window.location.pathname.includes("History")) ||
    (window.location.pathname.includes("Dev") &&
      !window.location.pathname.includes("History")) ||
    (window.location.pathname.includes("City") &&
      !window.location.pathname.includes("Type")) ||
    (window.location.pathname.includes("Resort") &&
      !window.location.pathname.includes("Type")) ||
    (window.location.pathname.includes("Serviced") &&
      !window.location.pathname.includes("Type"))
  ) {
    //console.log("enter hotel");
    benchItem?.classList?.add("activeH3");
  } else if (
    window.location.pathname.includes("MasterData") &&
    !window.location.pathname.includes("MasterDataHome")
  ) {
    assumpItem?.classList?.remove("activeH3");
    benchItem?.classList?.remove("activeH3");
    masterItem?.classList?.add("activeH3");
    //console.log("eeeelse");
  } else {
    //console.log("enter else");
    masterItem?.classList?.remove("activeH3");
    assumpItem?.classList?.remove("activeH3");
    benchItem?.classList?.remove("activeH3");
  }
};

export const convertEmpStringsArrToZero = (array) => {
  let newArr = [];
  newArr = array.map((num) => {
    if (num === "") {
      return 0;
    } else {
      return num;
    }
  });
  return newArr;
};

export const nullifyYearsField = (
  step,
  startDate,
  formikValues,
  setFieldValue,
  formikStartDate,
  fieldName,
  otherRevenueBreakdown = false
) => {
  if (step.warning) {
    if (!isNaN(startDate)) {
      var difference =
        new Date(formikValues[formikStartDate]).getFullYear() - startDate;

      if (difference !== 0) {
        if (otherRevenueBreakdown) {
          fieldName.forEach((element) => {
            console.log("element", element);
            setFieldValue(
              `otherRevenueBreakdown.${element}`,
              formikValues?.otherRevenueBreakdown[element]?.fill("")
            );
          });
        } else {
          fieldName.forEach((element) => {
            console.log("element", element);
            setFieldValue(element, formikValues[element]?.fill(""));
          });
        }
      }
    }
  }
};

export const selectedRadioButton = (val) => {
  if (val === "2") {
    return false;
  } else if (val === "1") {
    return true;
  } else {
    return null;
  }
};

export const isDecimalNumber = (n) => {
  var result = n - Math.floor(n) !== 0;

  if (result) return true;
  else return false;
};

//assetCategory => Asset category
export const stringFormat = (string) => {
  let outputString = string
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });
  let words = outputString.split(" ");
  for (let i = 1; i < words.length; i++) {
    words[i] = words[i].toLowerCase();
  }
  outputString = words.join(" ");
  return outputString;
};

//SORT DEPENDING ON THE DATE
export const sortArrByDate = (arr) => {
  return arr.sort((a, b) => {
    let date1 = dateFnsParseISO(a.createdAt);
    date1 = dateFnsFormat(date1, "dd.MM.yyyy; HH:mm");
    date1 = date1.split(" ")[1].split(":");
    let date2 = dateFnsParseISO(b.createdAt);
    date2 = dateFnsFormat(date2, "dd.MM.yyyy; HH:mm");
    date2 = date2.split(" ")[1].split(":");

    if (date1[0] < date2[0]) {
      return 1;
    }
    if (date1[0] > date2[0]) {
      return -1;
    }
    if (date1[0] === date2[0]) {
      if (date1[1] < date2[1]) {
        return 1;
      }
      if (date1[1] > date2[1]) {
        return -1;
      }
    }
    // a must be equal to b
    return 0;
  });
};
