import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useIsMobile } from "../../utils/hooks";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import React, { useState, useEffect } from "react";
import { Box ,Flex } from "@chakra-ui/react";
import { GetEmbedToken, GetReportFilters } from "../../api/DashboardAPI";
import { useParams, useNavigate } from "react-router-dom";
import { SummaryBox } from "./disclaimers";

export const DashboardRollout = () => {
  const params = useParams();
  const navigate = useNavigate();
  let user;
  let useRregion;
  if (Cookies.get("user_info")) {
    user = jwt_decode(Cookies.get("user_info"));
    useRregion = user.regions?.[0] || "";
    useRregion = useRregion.toLowerCase();
  }

  const [reportLevelOptions, setReportLevelOptions] = useState(() => {
    if (user.roles.includes("ceo")|| user.roles.includes("visitor")) {
      return [
        {
          label: "NEOM Level",
          value: "neomLevel",
        },
      ];
    }
    if (
      user.roles.includes("admin") ||
      user.roles.includes("head of department") ||  user.roles.includes("manager")
    ) {
      return [
        {
          label: "NEOM Level",
          value: "neomLevel",
        },
        {
          label: "Region Level",
          value: "regionalLevel",
        },
        {
          label: "Asset Level",
          value: "assetLevel",
        },
      ];
    }
    return [
      {
        label: "Region Level",
        value: "regionalLevel",
      },
      {
        label: "Asset Level",
        value: "assetLevel",
      },
    ];
  });

  const [accessToken, setAccessToken] = useState("");
  const [reportFilters, setReportFilters] = useState("");
  const [gotReportFilters, setGotReportFilters] = useState(false);
  const dashboardParams = {
    desktop: {
      neomLevel: {
        id: process.env.REACT_APP_DASHBOARD_ROLLOUT_NEOM_ID,
        embedUrl: process.env.REACT_APP_DASHBOARD_ROLLOUT_NEOM,
        pageName: "ReportSection",
      },
      regionalLevel: {
        id: process.env.REACT_APP_DASHBOARD_ROLLOUT_REGION_ID,
        embedUrl: process.env.REACT_APP_DASHBOARD_ROLLOUT_REGION,
        pageName: "ReportSection",
      },
      assetLevel: {
        id: process.env.REACT_APP_DASHBOARD_ROLLOUT_ASSET_ID,
        embedUrl: process.env.REACT_APP_DASHBOARD_ROLLOUT_ASSET,
        pageName: "ReportSection2e485fe0c76c01c5e7c1",
      },
    },
    mobile: {
      neomLevel: {
        id: "f0f85205-ebf8-4fd7-bb92-7d8d5cd012db",
        // embedUrl:
        //   "https://app.powerbi.com/reportEmbed?reportId=f0f85205-ebf8-4fd7-bb92-7d8d5cd012db&groupId=b3389fb5-e6b4-4930-b13f-90c304af54ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19",
        embedUrl:
          "https://app.powerbi.com/reportEmbed?reportId=9614708d-1d41-4c2a-8b1d-5d05dc34e907&autoAuth=true&ctid=8288fdf2-0e8b-47a6-abbb-004395ecab56",
        pageName: "ReportSectionf8ca3888577ca29ea972",
      },
      regionalLevel: {
        id: "816743e7-fb30-43b0-b7b6-bac0c84f4453",
        // embedUrl:
        //   "https://app.powerbi.com/reportEmbed?reportId=816743e7-fb30-43b0-b7b6-bac0c84f4453&groupId=b3389fb5-e6b4-4930-b13f-90c304af54ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19",
        embedUrl:
          "https://app.powerbi.com/reportEmbed?reportId=4ec67b3c-4c37-432a-a9ba-bdc4c9adfe82&autoAuth=true&ctid=8288fdf2-0e8b-47a6-abbb-004395ecab56",
        pageName: "ReportSectionf8ca3888577ca29ea972",
      },
      assetLevel: {
        id: "e777e3c9-c0e6-43a4-951e-0ef69334c81b",
        // embedUrl:
        //   "https://app.powerbi.com/reportEmbed?reportId=e777e3c9-c0e6-43a4-951e-0ef69334c81b&groupId=b3389fb5-e6b4-4930-b13f-90c304af54ee&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19",
        embedUrl:
          "https://app.powerbi.com/reportEmbed?reportId=2b422c3d-f2a1-46cb-85b5-18b626467840&autoAuth=true&ctid=8288fdf2-0e8b-47a6-abbb-004395ecab56",
        pageName: "ReportSectionf8ca3888577ca29ea972",
      },
    },
  };

  const isMobile = useIsMobile();

  const screenSizeToUse = isMobile ? "mobile" : "desktop";
  const [reportLevelToUse, setReportLevel] = useState(params.dashboardLevel);
  useEffect(() => {
    if (
      !reportLevelOptions.some(
        (reportLevel) => reportLevel.value === params.dashboardLevel
      )
    ) {
      setReportLevel(reportLevelOptions[reportLevelOptions.length - 1].value);
      navigate(
        `/dashboard/rollout/${
          reportLevelOptions[reportLevelOptions.length - 1].value
        }`
      );
    }
  }, [navigate, reportLevelOptions, params.dashboardLevel]);
  const [isEmbedShown, setIsEmbedShown] = useState(true);

  useEffect(() => {
    GetEmbedToken()
      .then((token) => {
        if (token) {
          setAccessToken(token);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    GetReportFilters(reportLevelToUse)
      .then((filter) => {
        setReportFilters(filter);
        setGotReportFilters(true);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const embedConfig = {
    type: "report",
    id: dashboardParams[screenSizeToUse][reportLevelToUse]?.id,
    embedUrl:
      dashboardParams[screenSizeToUse][reportLevelToUse]?.embedUrl +
      reportFilters,
    pageName: dashboardParams[screenSizeToUse][reportLevelToUse]?.pageName,
    accessToken,
    tokenType: models.TokenType.Aad,
    settings: {
      panes: {
        pageNavigation: {
          visible: false,
        },
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
  };

  const eventHandlers = new Map([
    [
      "loaded",
      function () {
        console.log("Report loaded");
      },
    ],
    [
      "rendered",
      function () {
        console.log("Report rendered");
      },
    ],
    [
      "error",
      function (event) {
        console.log(event.detail);
      },
    ],
  ]);

  useEffect(() => {
    setIsEmbedShown(false);
    setTimeout(() => {
      if (
        ["neomLevel", "regionalLevel", "assetLevel"].includes(reportLevelToUse)
      ) {
        GetReportFilters(reportLevelToUse)
        .then((filter) => {
          setReportFilters(filter);
          setGotReportFilters(true);
        })
        .catch((err) => {
          console.error(err);
        });
        setIsEmbedShown(true);
      }
    }, 250);
  }, [reportLevelToUse, screenSizeToUse]);

  const frameParams =
    reportFilters +
    "&filterPaneEnabled=false&pageNavigation=false&&navContentPaneEnabled=false";

  return (
    <div className={"Embed-frame"}>
      {!user.roles.includes("ceo") ? (
                  <Flex mb="20px" gap="20px" justifyContent="space-between">

          <Box maxW={["100%", "100%", "30%"]} width={["100%", "100%", "50%"]}>
            <SelectListMulti
              dropdownItems={reportLevelOptions}
              label="Viewing On"
              inputId="regionLookup"
              inputName="regionLookup"
              placeholder="Select"
              errors={{}}
              isRequired
              values={reportLevelToUse}
              onChange={(fieldName, value) => {
                navigate(`/dashboard/rollout/${value}`);
                setReportLevel(value);
              }}
              uppercase={true}
            />
          </Box>
          {/* {useRregion == "trojena" && reportLevelToUse == 'regionalLevel' ? 
          <SummaryBox title="Test TEst TEst Test TEst TEst Test TEst TEst Test TEst TEst Test TEst TEst Test TEst TEst Test TE"/>
            : ''

          } */}
        </Flex>
      ) : null}
      {/*{isEmbedShown && accessToken && gotReportFilters ? (*/}
      {/*  <PowerBIEmbed*/}
      {/*    embedConfig={embedConfig}*/}
      {/*    eventHandlers={eventHandlers}*/}
      {/*    cssClassName={"Embed-container"}*/}
      {/*    getEmbeddedComponent={(embeddedReport) => {*/}
      {/*      window.report = embeddedReport;*/}
      {/*    }}*/}
      {/*  />*/}
      {/*) : null}*/}
      {isEmbedShown && gotReportFilters ? (
        <iframe
          className={"Embed-container"}
          title="Embed"
          src={
            dashboardParams[screenSizeToUse][reportLevelToUse]?.embedUrl +
            frameParams
          }
          frameBorder="0"
          allowFullScreen={false}
        />
      ) : null}
    </div>
  );
};