import {useEffect, useState} from "react";

export const useIsMobile = () => {
    const [windowDimensions, setWindowDimensions] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })

    const updateSize = () => {
        setWindowDimensions({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }

    useEffect(() => {
        window.addEventListener('resize', updateSize)

        return () => {
            window.removeEventListener('resize', updateSize)
        }
    }, [windowDimensions])

    return windowDimensions.winWidth <= 650;
}
