export const operations = [
  { label: "General" },

  { label: "Keys", key: "keys" },
  { label: "Positioning", key: "positioning" },
  { label: "Average Keys", key: "avgKeys" },
  { label: "Performance" },

  { label: "Rooms Occupied", key: "totalRoomsOccupied" },
  { label: "Occupancy", key: "occupancyPercent", unit: "%" },
  { label: "ADR", key: "adr", unit: "SAR" },
  { label: "RevPAR", key: "revpar", unit: "SAR" },
  { label: "Total Revenue", key: "totalRevenue", unit: "SAR" },
  { label: "GOP", key: "gop", unit: "SAR" },
  { label: "EBITDA", key: "ebitda", unit: "SAR" },
  { label: "GOP", key: "gopPercentOfRevenue", unit: "% of Revenue" },
  { label: "EBITDA", key: "ebitdaPercentOfRevenue", unit: "% of Revenue" },
  { label: "Staffing" },
  { label: "Yearly Payroll Cost per Employee", key: "payrollPerEmployee", unit: "SAR" },
  {
    label: "Yearly Staff Housing Cost per Employee",
    key: "staffHousingPerEmployee",
    unit: "SAR",
  },
  { label: "Investment" },
  { label: "IRR", key: "irrPercent", unit: "%" },
  { label: "CAPEX", key: "capex", unit: "SAR" },
];


export const sarPerKey = [
  { label: "General" },

  { label: "Keys", key: "keys" },
  { label: "Positioning", key: "positioning" },
  { label: "Average Keys", key: "avgKeys" },

  { label: "Performance" },

  { label: "Rooms Occupied", key: "totalRoomsOccupied" },
  { label: "Rooms Available", key: "roomsAvailable" },
  { label: "Occupancy", key: "occupancyPercent", unit: "%" },

  { label: "ADR", key: "adr", unit: "SAR" },
  { label: "RevPAR", key: "revpar", unit: "SAR" },
  {
    label: "Total Revenue",
    key: "totalRevenuePerAvailableRoom",
    unit: "SAR PAR",
  },

  {
    label: "Total Undistributed Expenses",
    key: "totalUndistributedExpensesPerAvailableRoom",
    unit: "SAR PAR",
  },
  { label: "GOP", key: "gopPerAvailableRoom", unit: "SAR PAR" },
  { label: "EBITDA", key: "ebitdaPerAvailableRoom", unit: "SAR PAR" },

  { label: "Development Cost (per Key)" },
  { label: "CAPEX", key: "capexPerKey", unit: "SAR per Key" },
  { label: "Parking", key: "parkingPerKey", unit: "SAR per Key" },
  { label: "Construction", key: "constructionPerKey", unit: "SAR per Key" },
  { label: "FFE & OSE", key: "ffe_osePerKey", unit: "SAR per Key" },
  { label: "Soft Cost", key: "softCostPerKey", unit: "SAR per Key" },
  { label: "Pre-Opening", key: "preOpeningPerKey", unit: "SAR per Key" },
  { label: "TSA & IT", key: "tsa_itPerKey", unit: "SAR per Key" },

  {
    label: "Development Fee",
    key: "developmentFeePerKey",
    unit: "SAR per Key",
  },
  { label: "Contingency", key: "contingencyPerKey", unit: "SAR per Key" },
  {
    label: "Initial Working Capital",
    key: "initialWorkingCapitalPerKey",
    unit: "SAR per Key",
  },
];

export const perSQm = [
  { label: "General" },

  { label: "Keys", key: "keys" },
  { label: "Positioning", key: "positioning" },
  { label: "Average Keys", key: "avgKeys" },
  { label: "Performance" },
  { label: "Occupancy", key: "occupancyPercent", unit: "%" },
  { label: "ADR", key: "adr", unit: "SAR" },
  { label: "RevPAR", key: "revpar", unit: "SAR" },
  { label: "Total Revenue", key: "totalRevenuePerSQM", unit: "SAR per SQM" },
  { label: "GOP", key: "gopPerSQM", unit: "SAR per SQM" },
  { label: "Development Cost" },
  { label: "CAPEX", key: "capexPerSQM", unit: "SAR per SQM" },
  { label: "Land", key: "landPerSQM", unit: "SAR per SQM" },
  { label: "Infrastucture", key: "infrastructurePerSQM", unit: "SAR per SQM" },
  { label: "Parking", key: "parkingPerSQM", unit: "SAR per SQM" },
  { label: "Construction", key: "constructionPerSQM", unit: "SAR per SQM" },
  { label: "FFE & OSE", key: "ffe_osePerSQM", unit: "SAR per SQM" },
  { label: "Soft Cost", key: "softCostPerSQM", unit: "SAR per SQM" },
  { label: "Pre-Opening", key: "preOpeningPerSQM", unit: "SAR per SQM" },
  { label: "TSA & IT", key: "tsa_itPerSQM", unit: "SAR per SQM" },
  {
    label: "Development Fee",
    key: "developmentFeePerSQM",
    unit: "SAR per SQM",
  },
  { label: "Contingency", key: "contingencyPerSQM", unit: "SAR per SQM" },
  {
    label: "Initial Working Capital",
    key: "initialWorkingCapitalPerSQM",
    unit: "SAR per SQM",
  },
];

export const percentage = [
  { label: "General" },
  { label: "Keys", key: "keys" },
  { label: "Positioning", key: "positioning" },
  { label: "Average Keys", key: "avgKeys" },
  { label: "Performance" },
  { label: "Occupancy", key: "occupancyPercent", unit: "%" },
  { label: "ADR", key: "adr", unit: "SAR" },
  { label: "RevPAR", key: "revpar", unit: "SAR" },
  {
    label: "Room Revenue",
    key: "roomRevenuePercentOfRevenue",
    unit: "% of Revenue",
  },
  {
    label: "FnB Revenue",
    key: "fnbRevenuePercentOfRevenue",
    unit: "% of Revenue",
  },
  {
    label: "Other Revenue",
    key: "otherRevenuePercentOfRevenue",
    unit: "% of Revenue",
  },
  {
    label: "Room Profit",
    key: "roomProfitPercentOfRevenue",
    unit: "% of Revenue",
  },
  {
    label: "FnB Profit",
    key: "fnbProfitPercentOfRevenue",
    unit: "% of Revenue",
  },
  {
    label: "Other Profit",
    key: "otherProfitPercentOfRevenue",
    unit: "% of Revenue",
  },
  {
    label: "Departmental Profit",
    key: "departmentalProfitPercentOfRevenue",
    unit: "% of Revenue",
  },
  { label: "GOP", key: "gopPercentOfRevenue", unit: "% of Revenue" },
  { label: "EBITDA", key: "ebitdaPercentOfRevenue", unit: "% of Revenue" },

  { label: "Operator Fees" },
  { label: "Base Fee", key: "baseFee", unit: "% of Revenue" },
  {
    label: "Incentive Fee",
    key: "incentiveFeeOfRevenue",
    unit: "% of Revenue",
  },
  { label: "Incentive Fee", key: "incentiveFeeOfAGOP", unit: "% of AGOP" },
  {
    label: "Franchise/License Fee",
    key: "franchiseFee",
    unit: "% of Revenue",
  },
  { label: "Development Cost" },
  { label: "Parking", key: "parkingPercentOfCapEx", unit: "% of CapEx" },
  {
    label: "Construction",
    key: "constructionPercentOfCapEx",
    unit: "% of CapEx",
  },
  { label: "FFE & OSE", key: "ffe_osePercentOfCapEx", unit: "% of CapEx" },
  { label: "Soft Cost", key: "softCostPercentOfCapEx", unit: "% of CapEx" },
  { label: "Pre-Opening", key: "preOpeningPercentOfCapEx", unit: "% of CapEx" },
  { label: "TSA & IT", key: "tsa_itPercentOfCapEx", unit: "% of CapEx" },

  {
    label: "Development Fee",
    key: "developmentFeePercentOfCapEx",
    unit: "% of CapEx",
  },
  {
    label: "Contingency",
    key: "contingencyPercentOfCapEx",
    unit: "% of CapEx",
  },
  {
    label: "Initial Working Capital",
    key: "initialWorkingCapitalPercentOfCapEx",
    unit: "% of CapEx",
  },
];

export const por = [
  { label: "General" },
  { label: "Keys", key: "keys" },
  { label: "Positioning", key: "positioning" },
  { label: "Average Keys", key: "avgKeys" },
  { label: "Performance" },
  { label: "Rooms Occupied", key: "totalRoomsOccupied" },
  { label: "Occupancy", key: "occupancyPercent", unit: "%" },
  { label: "ADR", key: "adr", unit: "SAR" },
  { label: "RevPAR", key: "revpar", unit: "SAR" },
  { label: "Room Revenue", key: "roomRevenueSARPOR", unit: "SAR POR" },
  { label: "FnB Revenue", key: "fnbRevenueSARPOR", unit: "SAR POR" },
  { label: "Other Revenue", key: "otherRevenueSARPOR", unit: "SAR POR" },
  {
    label: "Departmental Profit",
    key: "departmentalProfitSARPOR",
    unit: "SAR POR",
  },
  { label: "Utilities", key: "utilitiesSARPOR", unit: "SAR POR" },
  { label: "GOP", key: "gopSARPOR", unit: "SAR POR" },
  { label: "EBITDA", key: "ebitdaSARPOR", unit: "SAR POR" },
];

export const allValues = [
  { label: "General" },

  { label: "Keys", key: "keys" },
  { label: "Positioning", key: "positioning" },
  { label: "Average Keys", key: "avgKeys" },

  { label: "Performance" },

  { label: "Rooms Occupied", key: "totalRoomsOccupied" },
  { label: "Occupancy", key: "occupancyPercent", unit: "%" },
  { label: "ADR", key: "adr", unit: "SAR" },
  { label: "RevPAR", key: "revpar", unit: "SAR" },
  { label: "Total Revenue", key: "totalRevenue", unit: "SAR" },
  { label: "GOP", key: "gop", unit: "SAR" },
  { label: "EBITDA", key: "ebitda", unit: "SAR" },
  { label: "GOP", key: "gopPercentOfRevenue", unit: "% of Revenue" },
  { label: "EBITDA", key: "ebitdaPercentOfRevenue", unit: "% of Revenue" }, 
  { label: "Rooms Available", key: "roomsAvailable" },
  {
    label: "Total Revenue",
    key: "totalRevenuePerAvailableRoom",
    unit: "SAR PAR",
  },

  {
    label: "Total Undistributed Expenses",
    key: "totalUndistributedExpensesPerAvailableRoom",
    unit: "SAR PAR",
  },
  { label: "GOP", key: "gopPerAvailableRoom", unit: "SAR PAR" },
  { label: "EBITDA", key: "ebitdaPerAvailableRoom", unit: "SAR PAR" },
  { label: "Total Revenue", key: "totalRevenuePerSQM", unit: "SAR per SQM" },
  { label: "GOP", key: "gopPerSQM", unit: "SAR per SQM" },
  {
    label: "Room Revenue",
    key: "roomRevenuePercentOfRevenue",
    unit: "% of Revenue",
  },
  {
    label: "FnB Revenue",
    key: "fnbRevenuePercentOfRevenue",
    unit: "% of Revenue",
  },
  {
    label: "Other Revenue",
    key: "otherRevenuePercentOfRevenue",
    unit: "% of Revenue",
  },
  {
    label: "Room Profit",
    key: "roomProfitPercentOfRevenue",
    unit: "% of Revenue",
  },
  {
    label: "FnB Profit",
    key: "fnbProfitPercentOfRevenue",
    unit: "% of Revenue",
  },
  {
    label: "Other Profit",
    key: "otherProfitPercentOfRevenue",
    unit: "% of Revenue",
  },
  {
    label: "Departmental Profit",
    key: "departmentalProfitPercentOfRevenue",
    unit: "% of Revenue",
  },
  { label: "GOP", key: "gopPercentOfRevenue", unit: "% of Revenue" },
  { label: "EBITDA", key: "ebitdaPercentOfRevenue", unit: "% of Revenue" },
  { label: "Room Revenue", key: "roomRevenueSARPOR", unit: "SAR POR" },
  { label: "FnB Revenue", key: "fnbRevenueSARPOR", unit: "SAR POR" },
  { label: "Other Revenue", key: "otherRevenueSARPOR", unit: "SAR POR" },
  {
    label: "Departmental Profit",
    key: "departmentalProfitSARPOR",
    unit: "SAR POR",
  },
  { label: "Utilities", key: "utilitiesSARPOR", unit: "SAR POR" },
  { label: "GOP", key: "gopSARPOR", unit: "SAR POR" },
  { label: "EBITDA", key: "ebitdaSARPOR", unit: "SAR POR" },



  { label: "Development Cost (per Key)" },
  { label: "CAPEX", key: "capexPerKey", unit: "SAR per Key" },
  { label: "Parking", key: "parkingPerKey", unit: "SAR per Key" },
  { label: "Construction", key: "constructionPerKey", unit: "SAR per Key" },
  { label: "FFE & OSE", key: "ffe_osePerKey", unit: "SAR per Key" },
  { label: "Soft Cost", key: "softCostPerKey", unit: "SAR per Key" },
  { label: "Pre-Opening", key: "preOpeningPerKey", unit: "SAR per Key" },
  { label: "TSA & IT", key: "tsa_itPerKey", unit: "SAR per Key" },
  {
    label: "Development Fee",
    key: "developmentFeePerKey",
    unit: "SAR per Key",
  },
  { label: "Contingency", key: "contingencyPerKey", unit: "SAR per Key" },
  {
    label: "Initial Working Capital",
    key: "initialWorkingCapitalPerKey",
    unit: "SAR per Key",
  },
  { label: "CAPEX", key: "capexPerSQM", unit: "SAR per SQM" },
  { label: "Land", key: "landPerSQM", unit: "SAR per SQM" },
  { label: "Infrastucture", key: "infrastructurePerSQM", unit: "SAR per SQM" },
  { label: "Parking", key: "parkingPerSQM", unit: "SAR per SQM" },
  { label: "Construction", key: "constructionPerSQM", unit: "SAR per SQM" },
  { label: "FFE & OSE", key: "ffe_osePerSQM", unit: "SAR per SQM" },
  { label: "Soft Cost", key: "softCostPerSQM", unit: "SAR per SQM" },
  { label: "Pre-Opening", key: "preOpeningPerSQM", unit: "SAR per SQM" },
  { label: "TSA & IT", key: "tsa_itPerSQM", unit: "SAR per SQM" },
  {
    label: "Development Fee",
    key: "developmentFeePerSQM",
    unit: "SAR per SQM",
  },
  { label: "Contingency", key: "contingencyPerSQM", unit: "SAR per SQM" },
  {
    label: "Initial Working Capital",
    key: "initialWorkingCapitalPerSQM",
    unit: "SAR per SQM",
  },
  { label: "Parking", key: "parkingPercentOfCapEx", unit: "% of CapEx" },
  {
    label: "Construction",
    key: "constructionPercentOfCapEx",
    unit: "% of CapEx",
  },
  { label: "FFE & OSE", key: "ffe_osePercentOfCapEx", unit: "% of CapEx" },
  { label: "Soft Cost", key: "softCostPercentOfCapEx", unit: "% of CapEx" },
  { label: "Pre-Opening", key: "preOpeningPercentOfCapEx", unit: "% of CapEx" },
  { label: "TSA & IT", key: "tsa_itPercentOfCapEx", unit: "% of CapEx" },
  
  {
    label: "Development Fee",
    key: "developmentFeePercentOfCapEx",
    unit: "% of CapEx",
  },
  {
    label: "Contingency",
    key: "contingencyPercentOfCapEx",
    unit: "% of CapEx",
  },
  {
    label: "Initial Working Capital",
    key: "initialWorkingCapitalPercentOfCapEx",
    unit: "% of CapEx",
  },

  { label: "Operator Fees" },
  { label: "Base Fee", key: "baseFee", unit: "% of Revenue" },
  {
    label: "Incentive Fee",
    key: "incentiveFeeOfRevenue",
    unit: "% of Revenue",
  },
  { label: "Incentive Fee", key: "incentiveFeeOfAGOP", unit: "% of AGOP" },
  {
    label: "Franchise/License Fee",
    key: "franchiseFee",
    unit: "% of Revenue",
  },

  { label: "Staffing" },
  { label: "Yearly Payroll Cost per Employee", key: "payrollPerEmployee", unit: "SAR" },
  {
    label: "Yearly Staff Housing Cost per Employee",
    key: "staffHousingPerEmployee",
    unit: "SAR",
  },

  { label: "Investment" },
  { label: "IRR", key: "irrPercent", unit: "%" },
  { label: "CAPEX", key: "capex", unit: "SAR" },

];
