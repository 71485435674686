// cardReducer.js
import { SET_MY_ASSETS, SET_COMPSET, FETCH_DATA_FAILURE, SET_COMPSET_ASSETS_SELECTED, SET_MY_ASSETS_SELECTED } from '../actions/actionTypes';

const initialState = {
  compset: [],
  myAssets: [],
  myAssetsRegion: [],
  myAssetsPosition: [],
  compsetRegion: [],
  compsetPosition: [],
  error: null,
  loading: true
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPSET:
      return {
        ...state,
        compset: action.payload,
        compsetRegion:action.payload.map(item => ({
          label: item.region,
          value: item.regionLookup
      })),
        compsetPosition:action.payload.map(item => ({
            label: item.positioning,
            value: item.positioningLookup
        })),
        loading: false
      };
    case SET_MY_ASSETS:
      return {
        ...state,
        myAssets: action.payload,
        myAssetsRegion:action.payload.map(item => ({
          label: item.region,
          value: item.regionLookup
      })),
        myAssetsPosition:action.payload.map(item => ({
            label: item.positioning,
            value: item.positioningLookup
        })),
        loading: false
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case SET_MY_ASSETS_SELECTED:
      return {
        ...state,
        myAssets: state.myAssets.map(asset => {
          if (asset.id === action.payload.assetId) {
            return { ...asset, selected: action.payload.selected };
          }
          return asset;
        }),
      };
    case SET_COMPSET_ASSETS_SELECTED:
      return {
        ...state,
        compset: state.compset.map(asset => {
          if (asset.id === action.payload.assetId) {
            return { ...asset, selected: action.payload.selected };
          }
          return asset;
        }),
      };
    default:
      return state;
  }
};

export default cardReducer;
