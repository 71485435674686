import React, { useRef, useEffect, useState } from "react";

import {
  Box,
  Text,
  Flex,
  Divider,
  SimpleGrid,
  GridItem,
  HStack,
} from "@chakra-ui/react";
import { FieldArray } from "formik";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { DatePickerField } from "../../components/datePickerField/DatePickerField";
import { nullifyYearsField } from "../../utils/General";
import { TooltipElement } from "../../components/tooltip/TooltipElement";
import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import percent from "../../assets/images/icons/filled.svg";
import calendar from "../../assets/images/icons/calendar.svg";
import {CalculationButton} from"../../components/calculationModal/calculationButton"

export const AssetsListForm2 = ({
  nextPage,
  prevPage,
  onCancelOpen,
  errors,
  touched,
  setFieldValue,
  handleBlur,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  devStartDate,
  setStepWarning,
  unsetStepWarning,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  step,
  setStepperUpdate,
  stepperUpdate,
  validateStep,
  setValidateStep,
  id,
  user,
  isModelWarningOpen,
  closeModalPath,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
}) => {
  const [infraDevStartMonthDD, setInfraDevStartMonthDD] = useState([]);
  let devAbsorptionError = false;
  let form2Data = [];
  const dataFetchedRef = useRef(false);
  const opStartDate = new Date(formikValues?.startDateOpTime);

  formikValues?.developmentAbsorptionLand?.forEach((item) => {
    form2Data.push(item);
  });
  form2Data.push(formikValues.bDownConsCost); //string
  form2Data.push(formikValues.bDownFfeOse); //string
  form2Data.push(formikValues.bDownSoftCost); //string
  form2Data.push(formikValues.bDownPreOpenTM); //string
  form2Data.push(formikValues.bDownNeomDevFee); //string
  form2Data.push(formikValues.bDownContCost); //string
  form2Data.push(formikValues.bDownIniWorkCapital); //string
  form2Data.push(formikValues.baseCost); //string
  form2Data.push(formikValues.refurbishmentCost); //string

  form2Data.push(formikValues.devCostPerKey); //string
  form2Data.push(formikValues.landCost); //string
  form2Data.push(formikValues.infraCost); //string
  form2Data.push(formikValues.parkingCost); //string
  form2Data.push(formikValues.bDownTsaFee); //string
  form2Data.push(formikValues.refurbishmentYear); //string

  form2Data.push(formikValues.parkingDepreciation); //string
  form2Data.push(formikValues.infraDepreciation); //string
  form2Data.push(formikValues.infraDevDuration); //string
  form2Data.push(formikValues.infraDevStartMonth); //string
  form2Data.push(formikValues.bDownDepConsCost); //string
  form2Data.push(formikValues.bDownDepFfeOse); //string
  form2Data.push(formikValues.bDownDepSoftCost); //string
  form2Data.push(formikValues.bDownDepPreOpenTM); //string
  form2Data.push(formikValues.bDownDepTsaFee); //string
  form2Data.push(formikValues.bDownDepNeomDevFee); //string
  form2Data.push(formikValues.bDownDepContCost); //string
  form2Data.push(formikValues.ffeDevelopmentDuration); //string
  form2Data.push(formikValues.preOpeningDevelopmentDuration); //string
  let emptyform2Data = [];
  console.log('fooorm 2 ', formikValues)
  form2Data.forEach((item, ind) => {
    if (ind <= 4) {
      let valSum = 0;
      let countZero = 0;
      formikValues?.developmentAbsorptionLand?.forEach((item2) => {
        valSum += item2;
        if (item2 === 0) {
          countZero++;
        }
      });
      if (valSum === 100 || countZero === 5) {
        emptyform2Data.push(item);
        devAbsorptionError = false;
      } else if (valSum != 0) {
        devAbsorptionError = true;
      }
    } else if (ind > 4 && ind <= 12) {
      if (user === "admin") {
        if (!isNaN(item) && item <= 100) {
          emptyform2Data.push(item);
        } else if (item >= 0 && item <= 100) {
          emptyform2Data.push(item);
        }
      }
    } else if (ind > 12 && ind <= 19) {
      if (user === "admin") {
        if (!isNaN(item)) {
          emptyform2Data.push(item);
        } else if (item >= 0) {
          emptyform2Data.push(item);
        }
      }
    } else if (ind === 31 || ind === 32) {
      if (
        parseFloat(item) <=
        parseFloat(formikValues.developmentTimeDurationMonths)
      ) {
        emptyform2Data.push(item);
      }
    } else {
      if (item !== "" && item >= 0) {
        emptyform2Data.push(item);
      }
    }
  });

  let isDisabledForm = false;
  if (emptyform2Data.length !== form2Data.length) {
    isDisabledForm = true;
  }
  // console.log("isDisabledForm", isDisabledForm);
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  let currDate = new Date(formikValues?.startDateDevTime).getFullYear();
  currDate = currDate - 1;

  const renderDevekopmentLandFields = () => {
    return (
      <FieldArray
        name="developmentAbsorptionLand"
        render={(arrayHelper) => (
          <SimpleGrid
            columns={{ sm: 5, md: 5 }}
            spacing="15px"
            width="100%"
            mb={2}
          >
            {formikValues.developmentAbsorptionLand !== undefined &&
              formikValues.developmentAbsorptionLand.map((_, index) => {
                currDate = currDate + 1;
                return (
                  <GridItem key={`developmentAbsorptionLand_${index}`}>
                    <InputFieldValidation
                      label={`Year ${currDate}`}
                      placeholder="0"
                      inputType="number"
                      inputId={`developmentAbsorptionLand.${index}`}
                      inputName={`developmentAbsorptionLand.${index}`}
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      onChange={setFieldValue}
                      values={formikValues?.developmentAbsorptionLand[index]}
                      labelCenter={true}
                      errorHeight0={true}
                      uppercase={false}
                      smallIcon={percent}
                      directVal={true}
                      isDisabled={isRestore}
                      errorShape={true}
                      validateField={validateStep}
                      setFieldTouched={setFieldTouched}
                      isThousand={true}
                    />
                  </GridItem>
                );
              })}
          </SimpleGrid>
        )}
      />
    );
  };

  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);

  useEffect(() => {
    var difference =
      new Date(formikValues?.startDateDevTime).getFullYear() - devStartDate;
    // if (difference !== 0) {
    //   nullifyYearsField(
    //     step,
    //     devStartDate,
    //     formikValues,
    //     setFieldValue,
    //     formikValues?.startDateDevTime,
    //     ["developmentAbsorptionLand"]
    //   );
    // }

    if (
      formikValues.developmentTimeDurationMonths >
        infraDevStartMonthDD.length ||
      parseInt(formikValues.developmentTimeDurationMonths) === 0
    ) {
      let devTimeDurMonths =
        parseInt(formikValues.developmentTimeDurationMonths) === 0
          ? 1
          : parseInt(formikValues.developmentTimeDurationMonths);
      let newInfDropdownArr = [];
      for (let i = 0; i < devTimeDurMonths; i++) {
        newInfDropdownArr = [
          ...newInfDropdownArr,
          {
            value: `${i + 1}`,
            label: `${i + 1}`,
          },
        ];
      }
      setInfraDevStartMonthDD(newInfDropdownArr);
    }
  }, []);

  return (
    <>
      <Box alignItems="flex-start" width="100%" justifyContent="space-between" display="flex" mb={5}>
        <MainTitle title="Development Cost Breakdown" />
        <CalculationButton  formikValue={formikValues} />

      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Box
          maxW={["100%", "100%", "65%"]}
          width={["100%", "100%", "65%"]}
          alignSelf="flex-start"
        >
          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            General
          </Text>

          <InputFieldValidation /* (UNINFLATED) */
            label={`Development Cost per Key ${
              user === "admin" && "(Optional)"
            }`}
            inputType="number"
            inputId="devCostPerKey"
            inputName="devCostPerKey"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            leftIcon={true}
            leftIconText="SAR"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            validateField={validateStep}
            optional={true}
            isThousand={true}
            onBlur={handleBlur}
            setFieldTouched={setFieldTouched}
          />

          <InputFieldValidation
            label={`Land Cost - Development Absorption ${
              user === "admin" && "(Optional)"
            }`}
            // label={`Land Cost - Freehold ${user === "admin" && "(Optional)"}`}
            inputType="number"
            inputId="landCost"
            inputName="landCost"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            leftIcon={true}
            leftIconText="SAR"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            validateField={validateStep}
            optional={true}
            isThousand={true}
            onBlur={handleBlur}
            setFieldTouched={setFieldTouched}
          />

          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Text
              fontSize="12px"
              color="black"
              fontWeight="400"
              letterSpacing="0.04em"
              textAlign="left"
              textTransform="uppercase"
              mt={3}
              mb={4}
            >
              Development Absorption Land
            </Text>

            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
              <GridItem colSpan={4}>{renderDevekopmentLandFields()}</GridItem>
              {devAbsorptionError && (
                <GridItem colSpan={4}>
                  <Text
                    color="#e14642"
                    fontWeight="400"
                    fontSize="10px"
                    lineHeight="17px"
                    letterSpacing="0.04em"
                  >
                    The summation of all years should be equal to 100
                  </Text>
                </GridItem>
              )}
            </SimpleGrid>
          </Box>

          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            INFRASTRUCTURE
          </Text>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
              <GridItem colSpan={4}>
                <SimpleGrid
                  columns={{ sm: 4, md: 4 }}
                  spacing="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label={`Cost ${user === "admin" && "(Optional)"}`}
                      inputType="number"
                      inputId="infraCost"
                      inputName="infraCost"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      leftIcon={true}
                      leftIconText="SAR"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      optional={true}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="Depreciation (in Years)"
                      labelInfo={id === undefined ? "Benchmark Data" : ""}
                      inputType="number"
                      inputId="infraDepreciation"
                      inputName="infraDepreciation"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <SelectListMulti
                      dropdownItems={infraDevStartMonthDD}
                      label="Development Start Month"
                      inputId="infraDevStartMonth"
                      inputName="infraDevStartMonth"
                      placeholder="Select"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      values={formikValues.infraDevStartMonth}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      uppercase={true}
                      isDisabled={isRestore}
                      validateField={validateStep}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="Development Duration (in Months) "
                      inputType="number"
                      inputId="infraDevDuration"
                      inputName="infraDevDuration"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          </Box>

          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            Parking
          </Text>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
              <GridItem colSpan={4}>
                <SimpleGrid
                  columns={{ sm: 4, md: 4 }}
                  spacing="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label={`Cost ${user === "admin" && "(Optional)"}`}
                      inputType="number"
                      inputId="parkingCost"
                      inputName="parkingCost"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      leftIcon={true}
                      leftIconText="SAR"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      optional={true}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="Depreciation (in Years)"
                      labelInfo={id === undefined ? "Benchmark Data" : ""}
                      inputType="number"
                      inputId="parkingDepreciation"
                      inputName="parkingDepreciation"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          </Box>

          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            Breakdown&nbsp;
            {id === undefined && (
              <Box className="tooltip_field5" mt="1.5">
                <TooltipElement label="Prepopulated from Benchmark data." />
              </Box>
            )}
          </Text>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
              <GridItem colSpan={4}>
                <SimpleGrid
                  columns={{ sm: 4, md: 4 }}
                  spacing="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label={`Construction Cost ${
                        user === "admin" && "(Optional)"
                      }`}
                      inputType="number"
                      inputId="bDownConsCost"
                      inputName="bDownConsCost"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      optional={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="Depreciation (in Years)"
                      inputType="number"
                      inputId="bDownDepConsCost"
                      inputName="bDownDepConsCost"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>

                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label={`FF&E & OS&E ${user === "admin" && "(Optional)"}`}
                      inputType="number"
                      inputId="bDownFfeOse"
                      inputName="bDownFfeOse"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      optional={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="Depreciation (in Years)"
                      inputType="number"
                      inputId="bDownDepFfeOse"
                      inputName="bDownDepFfeOse"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="FF&E & OS&E Development Duration (in Months)"
                      inputType="number"
                      inputId="ffeDevelopmentDuration"
                      inputName="ffeDevelopmentDuration"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2} className="lable_fixed_height">
                    <InputFieldValidation
                      label={`Soft Costs (Professional Fees) ${
                        user === "admin" && "(Optional)"
                      }`}
                      inputType="number"
                      inputId="bDownSoftCost"
                      inputName="bDownSoftCost"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      optional={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="Depreciation (in Years)"
                      inputType="number"
                      inputId="bDownDepSoftCost"
                      inputName="bDownDepSoftCost"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2} className="lable_fixed_height">
                    <InputFieldValidation
                      label={`Pre-Opening Training & Marketing ${
                        user === "admin" && "(Optional)"
                      }`}
                      inputType="number"
                      inputId="bDownPreOpenTM"
                      inputName="bDownPreOpenTM"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      optional={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="Depreciation (in Years)"
                      inputType="number"
                      inputId="bDownDepPreOpenTM"
                      inputName="bDownDepPreOpenTM"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="pre-Opening Development Duration (in Months)"
                      inputType="number"
                      inputId="preOpeningDevelopmentDuration"
                      inputName="preOpeningDevelopmentDuration"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label={`TSA Fee ${user === "admin" && "(Optional)"}`}
                      inputType="number"
                      inputId="bDownTsaFee"
                      inputName="bDownTsaFee"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      optional={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="Depreciation (in Years)"
                      inputType="number"
                      inputId="bDownDepTsaFee"
                      inputName="bDownDepTsaFee"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>

                  <GridItem colSpan={2} className="lable_fixed_height">
                    <InputFieldValidation
                      label={`NEOM Hotel Development Fee ${
                        user === "admin" && "(Optional)"
                      }`}
                      inputType="number"
                      inputId="bDownNeomDevFee"
                      inputName="bDownNeomDevFee"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      optional={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="Depreciation (in Years)"
                      inputType="number"
                      inputId="bDownDepNeomDevFee"
                      inputName="bDownDepNeomDevFee"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label={`Contingency Cost ${
                        user === "admin" && "(Optional)"
                      }`}
                      inputType="number"
                      inputId="bDownContCost"
                      inputName="bDownContCost"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      optional={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="Depreciation (in Years)"
                      inputType="number"
                      inputId="bDownDepContCost"
                      inputName="bDownDepContCost"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label={`Initial Working Capital (% of Year 2 Revenue) ${
                        user === "admin" && "(Optional)"
                      }`}
                      inputType="number"
                      inputId="bDownIniWorkCapital"
                      inputName="bDownIniWorkCapital"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      optional={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Text
                      className="title_lg"
                      display="flex"
                      mb={5}
                      mt={3}
                      component="div"
                    >
                      IT BUDGET
                    </Text>

                    <InputFieldValidation
                      label={`% of Base Cost ${
                        user === "admin" && "(Optional)"
                      }`}
                      inputType="number"
                      inputId="baseCost"
                      inputName="baseCost"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      optional={true}
                      setFieldTouched={setFieldTouched}
                    />
                    {/* <HStack>
                      <Text
                        fontSize="15px"
                        color="#003865"
                        fontWeight="400"
                        letterSpacing="0.01em"
                        textAlign="left"
                      >
                        Total Development Cost per Key
                      </Text>
                    </HStack> */}
                  </GridItem>
                  {/* <Box className="calc_input">
                    <InputFieldValidation
                      placeholder="0"
                      label=""
                      inputType="number"
                      inputId="calcTotalDevelopmentCostPerKey"
                      inputName="calcTotalDevelopmentCostPerKey"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      disable={true}
                      avg={true}
                      noBg={true}
                      isDisabled={true}
                      validateField={validateStep}
                      //
                      leftIconText="SAR"
                      noInputIcon={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </Box> */}
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          </Box>

          <InputFieldValidation
            label={`Refurbishment/Demolition Cost (% of Total Development Cost) ${
              user === "admin" && "(Optional)"
            }`}
            inputType="number"
            inputId="refurbishmentCost"
            inputName="refurbishmentCost"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            optional={true}
            setFieldTouched={setFieldTouched}
          />
          <Box maxW="65%" width="65%" alignSelf="flex-start">
            <DatePickerField
              label={`Year of Refurbishment/Demolition ${
                user === "admin" && "(Optional)"
              }`}
              inputType="date"
              inputId="refurbishmentYear"
              inputName="refurbishmentYear"
              errors={errors}
              setIsFormDirty={setIsFormDirty}
              isFormDirty={isFormDirty}
              touched={touched}
              isRequired
              values={formikValues.refurbishmentYear}
              onChange={setFieldValue}
              onBlur={handleBlur}
              minDate={new Date(opStartDate.toLocaleString().split(",")[0])}
              maxDate={new Date(`01/${formikValues?.operationTimeEndDate}`)}
              // hintText="Select a year between 2022 - 2050"
              icon={calendar}
              isDisable={isRestore}
              optional={true}
            />
          </Box>
        </Box>
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex justifyContent={user == "manager"? "end": "space-between"} alignItems="center" width="100%">
        {
            user !== "manager" ?  <MainButton
            title="Save Draft"
            btnStyle="transparent"
            fontColor="dark"
            disabledBtn={isRestore}
            onPress={() => {
              setDismissSave(false);
              onDraftOpen();
            }}
          /> : <></>
        }
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}

          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />

            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  nextPage();
                  unsetStepWarning(page);
                  setStepCompleted(page);
                  if (percentage < 28) {
                    setPercentage(28);
                  }
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
