import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Accordion,
  Box,
  Text,
} from "@chakra-ui/react";
import styles from "../../layouts/sidebar/sidebar.module.scss";

export const DropdownList = ({
  handleAccordBtn,
  isOpen,
  title,
  id,
  listItem,
  icon,
  handleSidebar,
  dashboard,
  listItemAssetList,
  listItemGeneral,
  userRole
}) => {
  const accordItemRef = useRef(null);

  const handleMenuItemActive = () => {
    let menuItemChildren =
      accordItemRef.current.children[1].children[0].children;
    let h2Item = accordItemRef.current.children[0];

    for (let i = 0; i <= menuItemChildren.length; i++) {
      if (menuItemChildren[i]) {
        h2Item.classList.remove("activeH2");
      }
    }

    for (let i = 0; i <= menuItemChildren.length; i++) {
      if (menuItemChildren[i]) {
        if (menuItemChildren[i].classList.contains(h2Item.id)) {
          h2Item.classList.add("activeH2");
        }
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleMenuItemActive();
    }
  });

  const renderIcon = (icon) => {
    if (icon === "benchmark") {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${styles.iconPos}`}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 7C7 6.44772 7.44772 6 8 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H8C7.44772 8 7 7.55228 7 7Z"
            fill="#6F767E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 12C7 11.4477 7.44772 11 8 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H8C7.44772 13 7 12.5523 7 12Z"
            fill="#6F767E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 17C7 16.4477 7.44772 16 8 16H20C20.5523 16 21 16.4477 21 17C21 17.5523 20.5523 18 20 18H8C7.44772 18 7 17.5523 7 17Z"
            fill="#6F767E"
          />
          <circle cx="4" cy="7" r="1" fill="#6F767E" />
          <circle cx="4" cy="12" r="1" fill="#6F767E" />
          <circle cx="4" cy="17" r="1" fill="#6F767E" />
        </svg>
      );
    } else if (icon === "asset") {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${styles.iconPos}`}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.7575 4.87216L6.75746 6.37216C6.3123 6.48345 6 6.88343 6 7.3423V20H14V9.99997V7.99997V5.8423C14 5.19173 13.3886 4.71437 12.7575 4.87216ZM16 9.99997V20H18V11C18 10.4477 17.5523 9.99997 17 9.99997H16ZM20 22H21C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20H20V11C20 9.34312 18.6569 7.99997 17 7.99997H16V5.8423C16 3.89059 14.1658 2.45851 12.2724 2.93187L6.27239 4.43187C4.93689 4.76575 4 5.9657 4 7.3423V20H3C2.44772 20 2 20.4477 2 21C2 21.5523 2.44772 22 3 22H4H6H14H16H18H20Z"
            fill="#6F767E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 17C8 16.4477 8.44772 16 9 16H11C11.5523 16 12 16.4477 12 17C12 17.5523 11.5523 18 11 18H9C8.44772 18 8 17.5523 8 17Z"
            fill="#6F767E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 13C8 12.4477 8.44772 12 9 12H11C11.5523 12 12 12.4477 12 13C12 13.5523 11.5523 14 11 14H9C8.44772 14 8 13.5523 8 13Z"
            fill="#6F767E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 9C8 8.44772 8.44772 8 9 8H11C11.5523 8 12 8.44772 12 9C12 9.55228 11.5523 10 11 10H9C8.44772 10 8 9.55228 8 9Z"
            fill="#6F767E"
          />
        </svg>
      );
    } else if (icon === "report") {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${styles.iconPos}`}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 2C3.55228 2 4 2.44772 4 3V19C4 19.5523 4.44772 20 5 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H5C3.34315 22 2 20.6569 2 19V3C2 2.44772 2.44772 2 3 2Z"
            fill="#6F767E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.7071 8.29289C22.0976 8.68342 22.0976 9.31658 21.7071 9.70711L17.1213 14.2929C15.9497 15.4645 14.0503 15.4645 12.8787 14.2929L10.7071 12.1213C10.3166 11.7308 9.68342 11.7308 9.29289 12.1213L7.20711 14.2071C6.81658 14.5976 6.18342 14.5976 5.79289 14.2071C5.40237 13.8166 5.40237 13.1834 5.79289 12.7929L7.87868 10.7071C9.05025 9.53553 10.9497 9.53553 12.1213 10.7071L14.2929 12.8787C14.6834 13.2692 15.3166 13.2692 15.7071 12.8787L20.2929 8.29289C20.6834 7.90237 21.3166 7.90237 21.7071 8.29289Z"
            fill="#6F767E"
          />
        </svg>
      );
    } else if (icon === "masterData") {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${styles.iconPos}`}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0253 5.18648C12.3631 4.94565 11.6371 4.94565 10.9749 5.18648L3.92627 7.74961L10.9749 10.3127C11.6371 10.5536 12.3631 10.5536 13.0253 10.3127L20.0739 7.74961L13.0253 5.18648ZM10.2914 3.30689C11.3952 2.90551 12.605 2.90552 13.7088 3.30689L20.7574 5.87002C22.5128 6.50833 22.5128 8.99089 20.7574 9.62919L13.7088 12.1923C12.605 12.5937 11.3952 12.5937 10.2914 12.1923L3.24278 9.62919C1.48743 8.99088 1.48744 6.50833 3.24278 5.87002L10.2914 3.30689Z"
            fill="#6F767E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.05757 12.3948C2.24259 11.8744 2.81442 11.6025 3.33479 11.7876L11.3298 14.6302C11.7632 14.7843 12.2364 14.7843 12.6698 14.6302L20.6648 11.7876C21.1851 11.6025 21.757 11.8744 21.942 12.3948C22.127 12.9151 21.8552 13.487 21.3348 13.672L13.3398 16.5146C12.473 16.8228 11.5265 16.8228 10.6597 16.5146L2.66477 13.672C2.1444 13.487 1.87255 12.9151 2.05757 12.3948Z"
            fill="#6F767E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.05757 16.6448C2.24259 16.1244 2.81442 15.8525 3.33479 16.0376L11.3298 18.8802C11.7632 19.0343 12.2364 19.0343 12.6698 18.8802L20.6648 16.0376C21.1851 15.8525 21.757 16.1244 21.942 16.6448C22.127 17.1651 21.8552 17.737 21.3348 17.922L13.3398 20.7646C12.473 21.0728 11.5265 21.0728 10.6597 20.7646L2.66477 17.922C2.1444 17.737 1.87255 17.1651 2.05757 16.6448Z"
            fill="#6F767E"
          />
        </svg>
      );
    } else if (icon === "assumptions") {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${styles.iconPos}`}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 11C7 10.4477 7.44772 10 8 10H16C16.5523 10 17 10.4477 17 11C17 11.5523 16.5523 12 16 12H8C7.44772 12 7 11.5523 7 11Z"
            fill="#6F767E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 15C7 14.4477 7.44772 14 8 14H12C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16H8C7.44772 16 7 15.5523 7 15Z"
            fill="#6F767E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V5C3 3.34315 4.34315 2 6 2H16L16.0107 2.01071C16.7136 2.07025 17.3761 2.3761 17.8787 2.87868L20.1213 5.12132C20.6239 5.6239 20.9297 6.28645 20.9893 6.98929L21 7V19ZM18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44771 4 6 4H15V6C15 7.10457 15.8954 8 17 8H19V19C19 19.5523 18.5523 20 18 20Z"
            fill="#6F767E"
          />
        </svg>
      );
    }
  };

  const renderListItems = () => {
    if (listItem.length > 0) {
      return listItem.map((li, index) => {
        return (
          <NavLink
            key={index}
            to={`/${li.url}`}
            className={({ isActive }) =>
              `${styles.nav_link}` +
              (!isActive ? "" : ` ${styles.active} ${li.classNameKey}`)
            }
          >
            <div
              className={styles.dropdown_item}
              onClick={() => {
                handleSidebar();
              }}
            >
              <Text noOfLines={1}>{li.title}</Text>
            </div>
          </NavLink>
        );
      });
    }
  };

  const renderItemForDashboard = () => (
    <Accordion defaultIndex={[0, 1]} allowMultiple>
      {[
        {
          title: "Approved",
          items: listItemGeneral,
        },
        ...(userRole == "admin"
          ? [
            {
              title: "Approved + In Planning",
              items: listItemAssetList,
            },
            ]
          : []),
        
      ].map((section, index) => (
        <AccordionItem className={`${styles.accord_item} sidebar_dropdown`} key={index}>
          <h2 id={`${id}-${index}`}>
            <AccordionButton
              _hover={{ background: "rgba(0, 0, 0, 0.04)", borderRadius: "5px" }}
              px="12px"
              py="12px"
              onClick={(event) => {
                handleAccordBtn(event);
              }}
            >
              <Box flex="1" ps="8px" ml="25px" textAlign="left" noOfLines={1}   >
                {section.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {section.items.map((li, idx) => (
              <NavLink
                key={idx}
                to={`/${li.url}`}
                className={({ isActive }) =>
                  `${styles.nav_link} ${isActive ? `${styles.active} ${li.classNameKey}` : ""}`
                }
              >
                <Box
                  className={`${styles.dropdown_item} ${styles.dropdown_item_dashboards}`}
                  onClick={() => {handleSidebar()}}
                >
                  <Text noOfLines={1}>{li.title}</Text>
                </Box>
              </NavLink>
            ))}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );

  return (
    <AccordionItem
      className={`${styles.accord_item} sidebar_dropdown`}
      ref={accordItemRef}
      my="5px"
    >
      <h2 id={id}>
        <AccordionButton
          _hover={{ background: "rgba(0, 0, 0, 0.04)", borderRadius: "5px" }}
          px="12px"
          py="12px"
          onClick={(event) => {
            handleAccordBtn(event);
          }}
        >
          <Box height="25px">{renderIcon(icon)}</Box>
          <Box flex="1" ps="8px" ml="25px" textAlign="left" noOfLines={1}>
            {title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} className={styles.accord_panel}>
        {dashboard ? renderItemForDashboard() : renderListItems()}
      </AccordionPanel>
    </AccordionItem>
  );
};
