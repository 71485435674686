import React, { useEffect } from "react";
import { HStack, Box, Text } from "@chakra-ui/react";

import { LinkBox } from "../../components/linkBox/LinkBox";
import { MainTitle } from "../../components/mainTitle/MainTitle";

import rightArrow from "../../assets/images/icons/rightArrow.svg";

export const MasterDataHome = () => {
  const masterData = {
    general: [
      { type: "Regions", id: "1" },
      { type: "Asset category", id: "2" },
      { type: "Room category", id: "3" },
      { type: "Room type", id: "4" },
      { type: "Positioning", id: "5" },
      { type: "Package type", id: "6" },
      { type: "Operator", id: "7" },
      { type: "Brand", id: "8" },
      { type: "Contract", id: "9" },
      // { type: "Operating Model", id: "9" },
    ],
    project: [
      { type: "Project status", id: "1" },
      { type: "Project stage", id: "2" },
    ],
  };

  const renderGeneral = (masterData) => {
    return masterData.general.map((el, index) => {
      return (
        <Box width="48%" className="m_0" key={index}>
          <LinkBox
            title={el.type == "Contract" ? "Operating Model" : el.type}
            icon={rightArrow}
            path={`/MasterDataVersions/${el.type}`}
          />
        </Box>
      );
    });
  };
  const renderProject = (masterData) => {
    return masterData.project.map((el, index) => {
      return (
        <Box width="48%" className="m_0" key={index}>
          <LinkBox
            title={el.type}
            icon={rightArrow}
            path={`/MasterDataVersions/${el.type}`}
          />
        </Box>
      );
    });
  };

  useEffect(() => {
    document?.getElementById("main_content")?.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HStack>
        <MainTitle title={"Master Data"} />
      </HStack>
      <Text className="title_md" mb={5}>
        GENERAL
      </Text>
      <HStack flexWrap="wrap" columnGap="20px">
        {renderGeneral(masterData)}
      </HStack>

      <Text className="title_md" mt={10} mb={5}>
        PROJECT
      </Text>
      <HStack flexWrap="wrap" columnGap="20px">
        {renderProject(masterData)}
      </HStack>
    </div>
  );
};
