import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Input,
  Flex,
  Box,
  Tooltip,
  IconButton,
  Image,
  Switch,
  useDisclosure,
  Badge,
  Center,
  Spinner,
  useToast,
  Menu,
  MenuList,
  MenuItem,
  Button,
  MenuButton,
  Text,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import Select from "react-select";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import { DeleteAssetAPI } from "../../api/AssetListAPI";

import {
  getApprovedAndUnapprovedAssets,
  updateKeys,
  updateData,
  updateUnaprroveStatus,
  setSavedSuccess,
  saveUnapprovedAssets,
  setLoading,
  setErrorMessage,
} from "../../redux/actions/approval";
import { EditDataModal } from "./addNew";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { AssetListAggregation } from "./aggregationAssets";
import styles from "../../components/forms.module.scss";
import { v4 as uuidv4 } from "uuid";

import saveIcon from "../../assets/images/icons/save.svg";
import downloadIcon from "../../assets/images/icons/download.svg";

import doneIcon from "../../assets/images/icons/done.svg";
import plusIcon from "../../assets/images/icons/plus2.svg";
import aggregationIcon from "../../assets/images/icons/aggergatinIcon.svg";
import { ToastAlert } from "../../components/toastAlert/ToastAlert";
import checked from "../../assets/images/icons/checked.svg";
import graph from "../../assets/images/icons/graph.svg";
import kpi from "../../assets/images/icons/kpi.svg";
import update from "../../assets/images/icons/update.svg";
import threeDots from "../../assets/images/icons/threeDots.svg";
import remove from "../../assets/images/icons/deleteRed.svg";
import clone from "../../assets/images/icons/clone.svg";
import errIcon from "../../assets/images/icons/errsm.svg";
import history from "../../history";
import { KpiTemplate } from "./kpiTemplate";
import { GetProductionPositioningWithIsActive } from "../../api/MasterDataAPI";
import {
  saveFilters,
  loadFilters,
  clearFilters,
} from "../../redux/actions/filter";

export const AssetList = ({ date, name, commentDesc, versionNum, icon }) => {
  const dispatch = useDispatch();
  const approvalList = useSelector((state) => state.approval.approvalList);
  const unapprovalList = useSelector((state) => state.approval.unapprovalList);
  const allAssets = useSelector((state) => state.approval.allAssets);
  const regionList = useSelector((state) => state.approval.region);
  const [positionListProduction, setPositionListProduction] = useState([]);
  const positionList = useSelector((state) => state.approval.position);
  const brandList = useSelector((state) => state.approval.brand);
  const operatorList = useSelector((state) => state.approval.operator);
  const isLoading = useSelector((state) => state.approval.loading);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const savedSuccess = useSelector((state) => state.approval.savedSuccess);
  const [viewType, setViewType] = useState("all");
  const [isAggregation, setIsAggregation] = useState(false);
  const [isKpi, setIsKpi] = useState(false);
  const [selectedID, setSelectedID] = useState();

  const [errorMessage2, setErrorMessage2] = useState("");
  const [editCell, setEditCell] = useState({
    editing: false,
    id: "",
    column: "",
  });
  const [editRow, setEditRow] = useState({});
  const [isNew, setIsNew] = useState();
  const [user, setUser] = useState();

  const {
    isOpen: isDelAssetModalOpen,
    onOpen: onDelAssetModalOpen,
    onClose: onDelAssetModalClose,
  } = useDisclosure();

  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const {
    isOpen: isDeletSucsessOpen,
    onOpen: onDeletSucsessOpen,
    onClose: onDeletSucsessClose,
  } = useDisclosure();

  let filterClearTimeout;

  // const [filters, setFilters] = useState({
  //   region: [],
  //   position: [],
  //   brand: [],
  //   operator: [],
  //   viewType: [
  //     { label: "Approved Assets", value: "approve" },
  //     { label: "In Planning Assets", value: "unapprove" },
  //     { label: "All Assets", value: "all" },
  //   ],
  // });

  const storedFilters = useSelector((state) => state.filters.filters);
  const [filters, setFilters] = useState(storedFilters);

  const toast = useToast();
  const closeToast = () => {
    toast.closeAll();
  };

  useEffect(() => {
    setFilters(storedFilters);
  }, [storedFilters]);

  useEffect(() => {
    dispatch(getApprovedAndUnapprovedAssets());
    GetProductionPositioningWithIsActive()
      .then((res) => {
        if (res.data[0]?.lookups?.length > 0) {
          setPositionListProduction(res.data[0]?.lookups);
        }
      })
      .catch((err) => console.log(err));

    dispatch(loadFilters());

    if (filterClearTimeout) {
      clearTimeout(filterClearTimeout);
    }
    filterClearTimeout = setTimeout(() => {
      dispatch(clearFilters());
    }, 3600000); // 1 hour

    const token = Cookies.get("user_info");
    if (token) {
      const tokenDecode = jwt_decode(token);
      setUser(tokenDecode);
    }
  }, [dispatch]);

  useEffect(() => {
    if (savedSuccess) {
      toast({
        position: "top",
        render: () => (
          <ToastAlert
            title="Asset has been saved successfully"
            icon={checked}
            closeToast={closeToast}
          />
        ),
      });
      dispatch(setSavedSuccess(false));
    }
  }, [savedSuccess]);

  const applyFilters = () => {
    let data =
      viewType === "approve"
        ? approvalList
        : viewType === "all"
        ? allAssets
        : unapprovalList;
    let filteredData = [...data];

    if (filters.region.length > 0) {
      filteredData = filteredData.filter((asset) =>
        filters.region.some((region) => region.value === asset.regionLookup)
      );
    }
    if (filters.position.length > 0) {
      filteredData = filteredData.filter((asset) =>
        filters.position.some(
          (position) => position.value === asset.positioningLookup
        )
      );
    }
    if (filters.brand.length > 0) {
      filteredData = filteredData.filter((asset) =>
        filters.brand.some((brand) => brand.value === asset.brandLookup)
      );
    }
    if (filters.operator.length > 0) {
      filteredData = filteredData.filter((asset) =>
        filters.operator.some(
          (operator) => operator.value === asset.operatorLookup
        )
      );
    }
    return filteredData;
  };

  const filteredList = applyFilters();
  const totalKeys = filteredList.reduce(
    (acc, curr) => acc + Number(curr.keys),
    0
  );
  const unapprovedKeys = filteredList.reduce(
    (acc, curr) => acc + Number(curr.unapprovedKeys),
    0
  );

  const handleMoveButtonClick = (row) => {
    if (row.status === "In Planning") {
      dispatch(updateUnaprroveStatus(row.id));
    }
    console.log(`Move button clicked for ${row.id}`);
  };
  const renderRowsAfterDeleteAssest = (id) => {
    DeleteAssetAPI(id)
      .then((res) => {
        setErrorMessage2("");
        // dispatch(setLoading(true));s

        if (res.success === true) {
          onDeletSucsessOpen();
          // loadTableData();
        }
        return res;
      })
      .catch((err) => {
        setErrorMessage2(
          err?.response?.data?.message || "Something went wrong"
        );
        onErrorOpen();
      });
  };

  const exportToExcel = () => {
    const filteredData = applyFilters();
    const formattedData = filteredData.map((row) => ({
      STATUS: row.status,
      "Approval Status":
        row.status === "In Planning" ? "Unapproved" : "Approved",
      REGION: row.region,
      "ASSET NAME": row.assetName,
      POSITIONING: row.positioning,
      OPERATOR: row.operator,
      BRAND: row.brand,
      KEYS: row.keys,
      "UNAPPROVED KEYS": row.unapprovedKeys,
      "UNAPPROVED POSITIONING": row.unapprovedPositioning,
      "UNAPPROVED OPENING YEAR": row.unapprovedOpeningYear,
      "CONSTRUCTION START YEAR": row.constructionStartYear,
      "OPENING YEAR": row.openingYear,
      SERVICE: row.service,
      "BRANDED RESIDENCES": row.includeBrandedResidence ? "Yes" : "No",
      "NUMBER OF BRANDED RESIDENCES": row.numberOfBrandedResidences,
      "STAFF PER KEY": row.staffPerKey,
      COMMENTS: row.comments,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Assets");
    const type = viewType;
    const date = new Date();
    const formattedDate = `${date.getFullYear()}${String(
      date.getMonth() + 1
    ).padStart(2, "0")}${String(date.getDate()).padStart(2, "0")}`;
    let fileName = `${formattedDate}_asset lis_${type}`;

    const regionFilter =
      filters.region.map((region) => region.label).join("_") || "";
    console.log(regionFilter);
    if (regionFilter) fileName += `_${regionFilter}`;
    const positionFilter =
      filters.position.map((position) => position.label).join("_") || "";
    if (positionFilter) fileName += `_${positionFilter}`;

    const brandFilter =
      filters.brand.map((brand) => brand.label).join("_") || "";
    if (brandFilter) fileName += `_${brandFilter}`;
    const operatorFilter =
      filters.operator.map((operator) => operator.label).join("_") || "";
    if (operatorFilter) fileName += `_${operatorFilter}`;

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const renderDropDownItems = (data) => {
    const extractedData = data.reduce((unique, item) => {
      if (!unique.some((elem) => elem.label === item.label)) {
        unique.push({ label: item.label, value: item.value });
      }
      return unique;
    }, []);
    return extractedData.sort((a, b) => a.label.localeCompare(b.label));
  };

  const handleFilterChange = (selectedOption, filterType) => {
    const newFilters = {
      ...filters,
      [filterType]: selectedOption || [],
    };
    setFilters(newFilters);
    dispatch(saveFilters(newFilters));

    // Reset the timer whenever filters are saved
    clearTimeout(window.filterClearTimeout);
    window.filterClearTimeout = setTimeout(() => {
      dispatch(clearFilters());
    }, 3600000); // 1 hour
  };

  const addNew = (row = {}, isNew = true) => {
    setEditRow(row);
    setIsNew(isNew);
    onOpen();
  };

  const aggregationClicked = () => {
    setIsAggregation(!isAggregation);
  };

  const kpiCliked = () => {
    setIsKpi(!isKpi);
  };

  const handleCloneButtonClick = (row) => {
    console.log("rowwww", row);

    // Create a clone of the original row and modify the assetName and versionNumber
    let clonedAsset = {
      ...row,
      assetName: row.assetName + " Clone",
      versionNumber: 0,
    };

    // Generate a project name value based on the asset name
    let projectNameValue = clonedAsset.assetName
      .toLowerCase()
      .replace(/[^ a-zA-Z0-9]/g, "")
      .replace(/ /g, "_")
      .concat(uuidv4());

    // Update the cloned asset with the new projectNameValue
    clonedAsset.projectNameValue = projectNameValue;

    // Remove id and lastModifiedBy from the cloned asset
    delete clonedAsset.id;
    delete clonedAsset.lastModifiedBy;

    // Log the cloned asset for debugging purposes
    console.log("Cloned Asset:", clonedAsset);

    // Dispatch the saveUnapprovedAssets action with the cloned asset
    dispatch(saveUnapprovedAssets(clonedAsset))
      .then((response) => {
        toast({
          position: "top",
          render: () => (
            <ToastAlert
              title="Asset has been saved successfully"
              icon={checked}
              closeToast={closeToast}
            />
          ),
        });
        onClose();
        console.log("Asset saved successfully", response);
      })
      .catch((error) => {
        // handle error
        toast({
          render: () => (
            <ToastAlert
              title="Something went wrong"
              icon={errIcon}
              closeToast={closeToast}
              type="Error"
            />
          ),
        });
        dispatch(setErrorMessage(null));
        // onClose();
      });
  };

  return !isAggregation && !isKpi ? (
    <>
      <Flex gap="24px" top="73px" width="100%" p={2}>
        <Tooltip label="KPI Assets">
          <IconButton
            aria-label="Kpi"
            icon={<Image src={kpi} />}
            style={{ backgroundColor: "white" }}
            onClick={kpiCliked}
          />
        </Tooltip>
        <Tooltip
          label={isAggregation ? "Show Individual Assets" : "Aggregate Assets"}
        >
          <IconButton
            aria-label="Toggle Aggregation"
            icon={<Image src={aggregationIcon} />}
            style={{ backgroundColor: isAggregation ? "#caa122" : "white" }}
            onClick={aggregationClicked}
          />
        </Tooltip>
        <Tooltip label="Dashboard">
          <IconButton
            aria-label="Dashboard"
            icon={<Image src={graph} />}
            style={{ backgroundColor: "white" }}
            onClick={() => history.push("/dashboard/assetListDashboard")}
          />
        </Tooltip>
        <Select
          zIndex="3"
          isClearable={true}
          onChange={(selectedOption) => setViewType(selectedOption.value)}
          placeholder="View..."
          name="viewType"
          options={[
            { label: "Approved Assets", value: "approve" },
            { label: "In Planning Assets", value: "unapprove" },
            { label: "All Assets", value: "all" },
          ]}
          defaultValue={{ label: "All Assets", value: "all" }}
          styles={{
            control: (provided) => ({ ...provided, width: 200, zIndex: 3 }),
            menu: (provided) => ({ ...provided, zIndex: 2 }),
          }}
        />
        <Select
          zIndex={1000}
          isMulti
          isClearable
          value={filters.region}
          placeholder="Regions ..."
          options={renderDropDownItems(regionList)}
          styles={{
            control: (provided) => ({ ...provided, width: 200 }),
            menu: (provided) => ({ ...provided, zIndex: 2 }),
          }}
          onChange={(selectedOption) =>
            handleFilterChange(selectedOption, "region")
          }
        />
        <Select
          isMulti
          isClearable
          value={filters.position}
          placeholder="Positioning ..."
          options={renderDropDownItems(positionList)}
          styles={{
            control: (provided) => ({ ...provided, width: 200 }),
            menu: (provided) => ({ ...provided, zIndex: 2 }),
          }}
          onChange={(selectedOption) =>
            handleFilterChange(selectedOption, "position")
          }
        />
        <Select
          isMulti
          isClearable
          value={filters.brand}
          placeholder="Brand ..."
          options={renderDropDownItems(brandList)}
          styles={{
            control: (provided) => ({ ...provided, width: 200 }),
            menu: (provided) => ({ ...provided, zIndex: 2 }),
          }}
          onChange={(selectedOption) =>
            handleFilterChange(selectedOption, "brand")
          }
        />
        <Select
          isMulti
          isClearable
          value={filters.operator}
          placeholder="Operator ..."
          options={renderDropDownItems(operatorList)}
          styles={{
            control: (provided) => ({ ...provided, width: 200 }),
            menu: (provided) => ({ ...provided, zIndex: 2 }),
          }}
          onChange={(selectedOption) =>
            handleFilterChange(selectedOption, "operator")
          }
        />
      </Flex>

      <Box mt="24px" display="flex" justifyContent="space-between">
        <MainTitle
          title={
            viewType === "approve"
              ? "Approved Assets"
              : viewType === "all"
              ? "All Assets"
              : "Unapproved Assets"
          }
        />
        <Flex gap="24px">
          {(viewType === "unapprove" || viewType === "all") && (
            <Tooltip label="Add new">
              <IconButton
                borderRadius="5px"
                isDisabled={user?.roles[0]?.toLowerCase() !== "admin"}
                icon={<Image src={plusIcon} />}
                onClick={() => addNew()}
              />
            </Tooltip>
          )}
          <Tooltip label="Export to Excel">
            <IconButton
              borderRadius="5px"
              icon={<Image src={downloadIcon} />}
              onClick={exportToExcel}
            />
          </Tooltip>
        </Flex>
      </Box>

      <MainBox mt="24px">
        {isLoading ? (
          <Box width="100%" paddingY={10}>
            <Center>
              <Spinner />
            </Center>
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            position="relative"
            overflowX="auto"
            className={styles.scrollableTable}
          >
            <Box w="100%">
              <Table
                border="1px solid #E2E8F0"
                variant="simple"
                size="lg"
                width="auto"
                id="asset-table"
                className={styles.scrollableTable}
              >
                <Thead>
                  <Tr>
                    <Th
                      className={styles.fixedColumn}
                      fontSize="sm"
                      position="sticky"
                      left="0"
                      backgroundColor="white"
                    >
                      Region
                    </Th>
                    <Th
                      className={styles.fixedColumn}
                      whiteSpace="nowrap"
                      fontSize="sm"
                      position="sticky"
                      left="150px"
                      backgroundColor="white"
                    >
                      System Status
                    </Th>
                    <Th
                      className={styles.fixedColumn}
                      whiteSpace="nowrap"
                      fontSize="sm"
                      position="sticky"
                      left="250"
                      backgroundColor="white"
                    >
                      Approval Status
                    </Th>

                    <Th
                      className={styles.fixedColumn}
                      fontSize="sm"
                      position="sticky"
                      left="380px"
                      backgroundColor="white"
                    >
                      Asset Name
                    </Th>
                    <Th whiteSpace="nowrap" fontSize="sm">
                      Action
                    </Th>
                    <Th whiteSpace="nowrap" fontSize="sm">
                      Positioning
                    </Th>
                    <Th whiteSpace="nowrap" fontSize="sm">
                      Current Keys
                    </Th>
                    {(viewType === "all" || viewType === "approve") && (
                      <>
                        <Th whiteSpace="nowrap" fontSize="sm">
                          Updated Keys
                        </Th>
                        <Th whiteSpace="nowrap" fontSize="sm">
                          Updated Positioning
                        </Th>
                        <Th whiteSpace="nowrap" fontSize="sm">
                          Updated Opening Year
                        </Th>
                        <Th whiteSpace="nowrap" fontSize="sm">
                          Updated Staff per Key
                        </Th>
                      </>
                    )}
                    <Th whiteSpace="nowrap" fontSize="sm">
                      Operator
                    </Th>
                    <Th whiteSpace="nowrap" fontSize="sm">
                      Brand
                    </Th>
                    <Th whiteSpace="nowrap" fontSize="sm">
                      Construction Start Year
                    </Th>
                    <Th whiteSpace="nowrap" fontSize="sm">
                      Opening Year
                    </Th>
                    <Th whiteSpace="nowrap" fontSize="sm">
                      Service
                    </Th>
                    <Th whiteSpace="nowrap" fontSize="sm">
                      Branded Residences
                    </Th>
                    <Th whiteSpace="nowrap" fontSize="sm">
                      Number of Branded Residences
                    </Th>
                    <Th whiteSpace="nowrap" fontSize="sm">
                      Staff per Key
                    </Th>
                    <Th whiteSpace="nowrap" fontSize="sm">
                      Comments
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredList.map((row) => (
                    <Tr key={row.projectNameValue + row.id}>
                      <Td
                        className={styles.fixedColumn}
                        position="sticky"
                        left="0px"
                        zIndex={1}
                        backgroundColor="white"
                        whiteSpace="nowrap"
                        fontSize="sm"
                      >
                        {row.region}
                      </Td>
                      <Td
                        className={styles.fixedColumn}
                        whiteSpace="nowrap"
                        fontSize="sm"
                        position="sticky"
                        left="150px"
                        zIndex={1}
                        backgroundColor="white"
                      >
                        <Flex justifyContent="center" alignItems="center">
                          <Badge
                            data-tag="allowRowEvents"
                            data-action="edit"
                            bg={
                              row.status === "Production"
                                ? "#006B44"
                                : row.status === "In Planning"
                                ? "#FFC000"
                                : row.status === "Fin. Pending"
                                ? "#462ebc"
                                : "#A9A9A9"
                            }
                            color="#fff"
                            whiteSpace=""
                            fontSize="14px"
                            fontWeight="300"
                            px={3}
                            py="1"
                            textTransform="none"
                            borderRadius="3px"
                          >
                            {row.status}
                          </Badge>
                        </Flex>
                      </Td>

                      <Td
                        className={styles.fixedColumn}
                        width="max-content"
                        whiteSpace="nowrap"
                        fontSize="sm"
                        position="sticky"
                        left="280px"
                        zIndex={1}
                        backgroundColor="white"
                      >
                        {row.status === "In Planning"
                          ? "In Planning"
                          : "Approved"}
                      </Td>

                      <Td
                        width="max-content"
                        // whiteSpace="nowrap"
                        fontSize="sm"
                        color={`#007BB5`}
                        className={styles.fixedColumn}
                        position="sticky"
                        left="400px"
                        zIndex={1}
                        backgroundColor="white"
                      >
                        <NavLink
                          to={`/AssetApprovalVersionHistory/${row.projectNameValue}/${row.assetName}`}
                        >
                          {row.assetName}
                        </NavLink>
                      </Td>
                      <Td whiteSpace="nowrap" fontSize="sm">
                        <Flex
                          gap="5px"
                          // justifyContent="space-between"
                          direction="row"
                        >
                          <Menu autoSelect={false}>
                            <MenuButton
                              bgColor="transparent"
                              as={Button}
                              px={1}
                              ml={1}
                              minWidth="auto"
                              _hover={{ backgroundColor: "transparent" }}
                              onClick={() => console.log("button clicked")} // Typo fixed
                            >
                              <img src={threeDots} alt="dots icon" />
                            </MenuButton>
                            <MenuList px="5px" py="15px">
                              <MenuItem
                                alignItems="center"
                                isDisabled={
                                  user?.roles[0]?.toLowerCase() !== "admin"
                                }
                              >
                                <Box
                                  display="flex"
                                  onClick={() =>
                                    dispatch(
                                      updateData({
                                        id: row.id,

                                        unapprovedKeys: Number(
                                          row.unapprovedKeys
                                        ),
                                        unapprovedPositioning:
                                          row.unapprovedPositioning,
                                        unapprovedOpeningYear: Number(
                                          row.unapprovedOpeningYear
                                        ),
                                        unapprovedStaffPerKey: Number(
                                          row.unapprovedStaffPerKey
                                        ),
                                      })
                                    )
                                  }
                                >
                                  <img
                                    src={saveIcon}
                                    width="17px"
                                    alt="edit icon"
                                  />
                                  <Text
                                    color="#003865"
                                    ml="5px"
                                    fontSize="12px"
                                    fontWeight="400"
                                  >
                                    Save changes
                                  </Text>
                                </Box>
                              </MenuItem>

                              {row.status !== "Production" ? (
                                <>
                                  <MenuItem
                                    alignItems="center"
                                    isDisabled={
                                      user?.roles[0]?.toLowerCase() !== "admin"
                                    }
                                    onClick={() => {
                                      setSelectedID(
                                        `delete|${row.projectNameValue}`
                                      );
                                      onDelAssetModalOpen();
                                    }}
                                  >
                                    <Box
                                      display="flex"
                                      onClick={() => {
                                        setSelectedID(
                                          `delete|${row.projectNameValue}`
                                        );
                                        onDelAssetModalOpen();
                                      }}
                                    >
                                      <img
                                        src={remove}
                                        width="17px"
                                        alt="edit icon"
                                      />
                                      <Text
                                        color="#003865"
                                        ml="5px"
                                        fontSize="12px"
                                        fontWeight="400"
                                      >
                                        Remove Asset
                                      </Text>
                                    </Box>
                                  </MenuItem>
                                  <MenuItem
                                    alignItems="center"
                                    isDisabled={
                                      user?.roles[0]?.toLowerCase() !== "admin"
                                    }
                                  >
                                    <Box
                                      display="flex"
                                      onClick={() => {
                                        handleCloneButtonClick(row);
                                      }}
                                    >
                                      <img
                                        src={clone}
                                        width="17px"
                                        alt="edit icon"
                                      />
                                      <Text
                                        color="#003865"
                                        ml="5px"
                                        fontSize="12px"
                                        fontWeight="400"
                                      >
                                        Clone Asset
                                      </Text>
                                    </Box>
                                  </MenuItem>
                                  {row.status == "In Planning" ? (
                                    <>
                                      <MenuItem
                                        alignItems="center"
                                        isDisabled={
                                          (row.unapprovedKeys == row.keys &&
                                            row.unapprovedPositioning ==
                                              row.positioningLookup &&
                                            row.unapprovedOpeningYear ==
                                              row.openingYear &&
                                            row.unapprovedStaffPerKey ==
                                              row.staffPerKey) ||
                                          user?.roles[0]?.toLowerCase() !==
                                            "admin"
                                        }
                                        onClick={() => {
                                          let data = {
                                            ...row,
                                            keys: row.unapprovedKeys,
                                            positioningLookup:
                                              row.unapprovedPositioning,
                                            openingYear:
                                              row.unapprovedOpeningYear,
                                            staffPerKey:
                                              row.unapprovedStaffPerKey,
                                          };
                                          dispatch(saveUnapprovedAssets(data));
                                        }}
                                      >
                                        <Box
                                          display="flex"
                                          onClick={() => {
                                            let data = {
                                              ...row,
                                              keys: row.unapprovedKeys,
                                              positioningLookup:
                                                row.unapprovedPositioning,
                                              openingYear:
                                                row.unapprovedOpeningYear,
                                              staffPerKey:
                                                row.unapprovedStaffPerKey,
                                            };
                                            dispatch(
                                              saveUnapprovedAssets(data)
                                            );
                                          }}
                                        >
                                          <img
                                            src={update}
                                            width="17px"
                                            alt="edit icon"
                                          />
                                          <Text
                                            color="#003865"
                                            ml="5px"
                                            fontSize="12px"
                                            fontWeight="400"
                                          >
                                            Update Key changes
                                          </Text>
                                        </Box>
                                      </MenuItem>
                                      <MenuItem
                                        alignItems="center"
                                        onClick={() =>
                                          handleMoveButtonClick(row)
                                        }
                                        isDisabled={
                                          user?.roles[0]?.toLowerCase() !==
                                          "admin"
                                        }
                                      >
                                        <Box
                                          display="flex"
                                          onClick={() =>
                                            handleMoveButtonClick(row)
                                          }
                                        >
                                          <img
                                            src={doneIcon}
                                            width="17px"
                                            alt="edit icon"
                                          />
                                          <Text
                                            color="#003865"
                                            ml="5px"
                                            fontSize="12px"
                                            fontWeight="400"
                                          >
                                            Move to Approval
                                          </Text>
                                        </Box>
                                      </MenuItem>
                                    </>
                                  ) : (
                                    <>
                                      <MenuItem
                                        alignItems="center"
                                        isDisabled={
                                          user?.roles[0]?.toLowerCase() !==
                                          "admin"
                                        }
                                      >
                                        <NavLink
                                          to={
                                            row.status === "Production"
                                              ? `/AssetList/${row.service}/${row.projectNameValue}/${row.versionNumber}`
                                              : `/AssetList/${row.service}`
                                          }
                                          state={
                                            row.status === "Production"
                                              ? { isFromApproval: "true" }
                                              : {
                                                  isPreProduction: true,
                                                  projectNameValue:
                                                    row.projectNameValue,
                                                  versionNumber:
                                                    row.versionNumber,
                                                }
                                          }
                                        >
                                          <Box
                                            display="flex"
                                            onClick={() => {
                                              // handleMoveButtonClick(row)
                                            }}
                                          >
                                            <img
                                              src={doneIcon}
                                              width="17px"
                                              alt="edit icon"
                                            />
                                            <Text
                                              color="#003865"
                                              ml="5px"
                                              fontSize="12px"
                                              fontWeight="400"
                                            >
                                              Move to Production
                                            </Text>
                                          </Box>
                                        </NavLink>
                                      </MenuItem>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <MenuItem
                                    alignItems="center"
                                    isDisabled={
                                      (row.unapprovedKeys == row.keys && row.unapprovedPositioning == row.positioningLookup && 
                                      row.unapprovedOpeningYear == row.openingYear && row.unapprovedStaffPerKey == row.staffPerKey) ||
                                       user?.roles[0]?.toLowerCase() !== "admin"
                                     }
                                  >
                                    <Box
                                      display="flex"
                                      onClick={() => {
                                        // handleMoveButtonClick(row)
                                      }}
                                    >
                                      <img
                                        src={update}
                                        width="17px"
                                        alt="edit icon"
                                      />
                                      <NavLink
                                        to={
                                          row.status === "Production"
                                            ? `/AssetList/${row.service}/${row.projectNameValue}/${row.versionNumber}`
                                            : `/AssetList/${row.service}`
                                        }
                                        state={
                                          row.status === "Production"
                                            ? { isFromApproval: "true" }
                                            : {
                                                isPreProduction: true,
                                                projectNameValue:
                                                  row.projectNameValue,
                                                versionNumber:
                                                  row.versionNumber,
                                              }
                                        }
                                      >
                                        <Text
                                          color="#003865"
                                          ml="5px"
                                          fontSize="12px"
                                          fontWeight="400"
                                        >
                                          Update Data
                                        </Text>
                                      </NavLink>
                                    </Box>
                                  </MenuItem>
                                </>
                              )}
                            </MenuList>
                          </Menu>
                        </Flex>
                      </Td>
                      <Td whiteSpace="nowrap" fontSize="sm">
                        {row.positioning}
                      </Td>
                      <Td whiteSpace="nowrap" fontSize="sm">
                        {row.keys}
                      </Td>

                      <>
                        <EditableTd
                          row={row}
                          editCell={editCell}
                          setEditCell={setEditCell}
                          column="unapprovedKeys"
                          dispatch={dispatch}
                          user={user}
                          value={row.unapprovedKeys}
                          updateAction={updateKeys}
                          inputType="number"
                        />
                        <EditableTd
                          row={row}
                          editCell={editCell}
                          setEditCell={setEditCell}
                          column="unapprovedPositioning"
                          dispatch={dispatch}
                          user={user}
                          value={row.unapprovedPositioning}
                          updateAction={updateKeys}
                          options={positionListProduction}
                        />
                        <EditableTd
                          row={row}
                          editCell={editCell}
                          setEditCell={setEditCell}
                          column="unapprovedOpeningYear"
                          dispatch={dispatch}
                          user={user}
                          value={row.unapprovedOpeningYear}
                          updateAction={updateKeys}
                          inputType="date"
                        />
                        <EditableTd
                          row={row}
                          editCell={editCell}
                          setEditCell={setEditCell}
                          column="unapprovedStaffPerKey"
                          dispatch={dispatch}
                          user={user}
                          value={row.unapprovedStaffPerKey}
                          updateAction={updateKeys}
                          inputType="number"
                        />
                      </>

                      <Td whiteSpace="nowrap" fontSize="sm">
                        {row.operator}
                      </Td>
                      <Td whiteSpace="nowrap" fontSize="sm">
                        {row.brand}
                      </Td>
                      <Td whiteSpace="nowrap" fontSize="sm">
                        {row.constructionStartYear}
                      </Td>
                      <Td whiteSpace="nowrap" fontSize="sm">
                        {row.openingYear}
                      </Td>
                      <Td whiteSpace="nowrap" fontSize="sm">
                        {row.service}
                      </Td>
                      <Td whiteSpace="nowrap" fontSize="sm">
                        <Switch
                          id="isReadOnly"
                          isReadOnly
                          colorScheme={
                            row.includeBrandedResidence ? "green" : "red"
                          }
                          defaultChecked={row.includeBrandedResidence}
                        />
                      </Td>
                      <Td whiteSpace="nowrap" fontSize="sm">
                        {row.numberOfBrandedResidences}
                      </Td>
                      <Td whiteSpace="nowrap" fontSize="sm">
                        {row.staffPerKey}
                      </Td>
                      <Td whiteSpace="break-spaces" fontSize="sm">
                        {row.comments}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th fontSize="sm">Total</Th>
                    <Th colSpan="5" />
                    <Th fontSize="sm">{totalKeys}</Th>
                    {(viewType === "all" || viewType === "approve") && (
                      <Th fontSize="sm">{unapprovedKeys}</Th>
                    )}
                    <Th colSpan="10" />
                  </Tr>
                </Tfoot>
              </Table>
            </Box>
          </Box>
        )}
      </MainBox>

      {isOpen && (
        <EditDataModal
          data={editRow}
          isNew={isNew}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      <ModalAlert
        isOpen={isDelAssetModalOpen}
        onClose={onDelAssetModalClose}
        title={"Are you sure you want to delete this assest?"}
        icon={infoCircle}
        // hasDesc={false}
        confirmationModal={true}
        // haveSaveDraft={true}
        selectedID={selectedID}
        isDelete={true}
        isDeleteAssest={true}
        setAssetDelelID={selectedID}
        // errorMessage={errorMessage}
        // setErrorMessage={setErrorMessage}
        GetDataAPI={dispatch(getApprovedAndUnapprovedAssets)}
        DeleteAsset={renderRowsAfterDeleteAssest}
      />

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage2}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />

      <ModalAlert
        isOpen={isDeletSucsessOpen}
        onClose={onDeletSucsessClose}
        title="Asset has been deleted successfully"
        icon={checked}
        hasDesc={false}
        confirmationModal={false}
      />
    </>
  ) : isKpi ? (
    <KpiTemplate back={setIsKpi} />
  ) : (
    <AssetListAggregation back={setIsAggregation} />
  );
};

const EditableTd = ({
  row,
  editCell,
  setEditCell,
  column,
  dispatch,
  user,
  value,
  updateAction,
  inputType,
  options,
}) => {
  const handleBlur = (e) => {
    const newValue =
      inputType === "number" ? Number(e.target.value) : e.target.value;
    console.log(newValue);
    setEditCell({ editing: false, id: "", column: "" });
    dispatch(updateAction({ id: row.id, [column]: newValue, key: column }));
  };

  const handleDateChange = (date) => {
    const year = date.getFullYear();
    setEditCell({ editing: false, id: "", column: "" });
    dispatch(updateAction({ id: row.id, [column]: year, key: column }));
  };

  const handleSelectChange = (selectedOption) => {
    const newValue = selectedOption?.value || "";
    setEditCell({ editing: false, id: "", column: "" });
    dispatch(updateAction({ id: row.id, [column]: newValue, key: column }));
  };

  const renderInput = () => {
    if (inputType === "number") {
      return (
        <Input
          defaultValue={Number(value)}
          type="number"
          onBlur={handleBlur}
          autoFocus
        />
      );
    }
    if (inputType === "date") {
      return (
        <Box width="max-content" className={styles.form_control}>
          <Box className={`${styles.form_control_input} datepicker_year`}>
            <DatePicker
              inline
              selected={value ? new Date(value, 0) : new Date()}
              onChange={handleDateChange}
              dateFormat="yyyy"
              showYearPicker
            />
          </Box>
        </Box>
      );
    }
    if (options) {
      return (
        <Select
          isClearable
          value={options.filter((option) => option.value === value)}
          placeholder="Select..."
          options={options}
          styles={{
            control: (provided) => ({ ...provided, width: 200 }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onChange={handleSelectChange}
        />
      );
    }
  };

  return (
    <Td
      whiteSpace=""
      fontSize="sm"
      backgroundColor={"#ddeaf3"}
      onDoubleClick={() =>
        user?.roles[0]?.toLowerCase() === "admin" &&
        setEditCell({ editing: true, id: row.id, column })
      }
    >
      {editCell.editing && row.id === editCell.id && editCell.column === column
        ? renderInput()
        : column !== "unapprovedPositioning"
        ? value || "-"
        : options.filter((option) => option.value === value)[0]?.label || "-"}
    </Td>
  );
};

export default AssetList;
