import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Text,
  GridItem,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Heading,
  Flex,
  Divider,
  Badge,
} from "@chakra-ui/react";
import { FieldArray } from "formik";

import { InputFieldAverage } from "../../../components/inputField/InputFieldAverage";
import { MainButton } from "../../../components/button/MainButton";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { DatePickerField } from "../../../components/datePickerField/DatePickerField";
import { TooltipElement } from "../../../components/tooltip/TooltipElement";
import { SelectListMulti } from "../../../components/selectField/SelectListMulti";

import rightArrow from "../../../assets/images/icons/rightArrow2.svg";
import calendar from "../../../assets/images/icons/calendar.svg";
import percent from "../../../assets/images/icons/filled.svg";

export const HotelKpisStep1 = ({
  nextPage,
  prevPage,
  errors,
  touched,
  onChange,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  onDraftOpen,
  formikValues,
  dropdownObj,
  setDisableSave,
  disableSave,
  setDismissSave,
  positioning,
  isRestore,
  validateStep,
  setValidateStep,
  isModelWarningOpen,
  handleAccordErrors,
  closeToast,
}) => {
  const [arrayKeys, setArrayKeys] = useState([]);
  const inputRef = useRef(null);
  let form1Data = [];
  form1Data.push({
    fieldName: "baseYear",
    value: formikValues?.baseYear,
  });
  form1Data.push({
    fieldName: "benchmarkLevel",
    value: formikValues.benchmarkLevel,
  });
  if (
    formikValues?.adrValues?.adr &&
    formikValues?.adrValues?.fnbRevenues &&
    formikValues?.adrValues?.otherRevenues
  ) {
    formikValues.adrValues.adr.forEach((item) => {
      form1Data.push(item);
    });
    formikValues.adrValues.fnbRevenues.forEach((item) => {
      form1Data.push(item);
    });
    formikValues.adrValues.otherRevenues.forEach((item) => {
      form1Data.push(item);
    });
  }
  let emptyform1Data = [];
  form1Data.forEach((item) => {
    if (
      item?.fieldName === "benchmarkLevel" ||
      item?.fieldName === "baseYear"
    ) {
      if (item.value !== "") {
        emptyform1Data.push(item);
      }
    } else {
      if (
        item.low !== "" &&
        !isNaN(`${item.low}`) &&
        parseFloat(item.low) >= 0 &&
        item.high !== "" &&
        !isNaN(`${item.high}`) &&
        parseFloat(item.high) >= 0 &&
        item.average !== "" &&
        !isNaN(`${item.average}`) &&
        parseFloat(item.average) >= 0 &&
        parseFloat(item.low) <= parseFloat(item.high)
      ) {
        emptyform1Data.push(item);
      }
    }
  });
  console.log("errors", errors);
  let isDisabledForm = false;

  if (emptyform1Data.length !== form1Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }
  /*  if (emptyform1Data.length >= 0) {
    // the default value is 3
    if (disableSave) {
      setDisableSave(false);
    }
  } */

  let accordTitle = ["ADR (SAR)", "F&B Revenues (POR)", "Other Revenues (POR)"];
  let arrKeys = [];
  if (formikValues?.adrValues) {
    arrKeys = Object.keys(formikValues.adrValues);
    if (JSON.stringify(arrayKeys) !== JSON.stringify(arrKeys)) {
      setArrayKeys(arrKeys);
    }
  }
  // const handleAccordErrors = () => {
  //   let openHigherAccord = true;
  //   let tempArr = [];
  //   form1Data.forEach((el, index) => {
  //     if (openHigherAccord) {
  //       if (el?.low === "" || el?.high === "" || el.low > el.high||el.low>100||el.high>100) {
  //         console.log("index", index - 2);
  //         openHigherAccord = false;
  //         handleOpenAccord(index - 2);
  //       }
  //     }
  //   });
  // };

  let renderAccrodionFields = (mData, pos, name) => {
    let counterMas = 0;

    counterMas = counterMas + 1;
    if (counterMas <= mData.length) {
      //let counterComment = 0;

      //counterComment = counterComment + 1;
      return (
        <>
          <AccordionItem mb="4" borderBottom="none">
            <Heading
              borderLeft="1px"
              borderBottom="1px"
              borderRight="1px"
              borderColor="#E6E6E6"
            >
              <AccordionButton
                bg="#FCFCFC"
                className={arrayKeys[pos]}
                onClick={() => {
                  setTimeout(() => {
                    document
                      .querySelector(`.accordionList`)
                      .scrollIntoView({ block: "start" });
                  }, 500);
                }}
              >
                <Box flex="1" textAlign="left">
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                    textTransform="uppercase"
                  >
                    {accordTitle[pos]}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Heading>

            <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
              <FieldArray
                name={`${arrayKeys[pos]}`}
                render={(arrayHelper) => (
                  <>
                    {formikValues.adrValues.adr !== undefined &&
                      formikValues.adrValues.adr.map((formArr, index) => {
                        let posObj = positioning.find((el) => {
                          if (el?.id) {
                            return formArr.positioningId === el.id;
                          } else {
                            return formArr.positioningId === el.positioningId;
                          }
                        });

                        return (
                          <Box key={index}>
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                            >
                              <Text
                                fontSize="12px"
                                color="black"
                                fontWeight="400"
                                letterSpacing="0.04em"
                                textAlign="left"
                                textTransform="uppercase"
                                mb={4}
                                mt={3}
                              >
                                {posObj?.posName
                                  ? posObj.posName
                                  : `${formArr.positioningId}`
                                      .split("_")
                                      .join(" ")}
                                {/* {`${formArr.positioningId}`
                                  .split("_")
                                  .join(" ")} */}
                              </Text>
                            </Box>

                            <GridItem colSpan={2}>
                              <SimpleGrid
                                columns={{ sm: 3, "2xl": 3 }}
                                spacing={{ sm: "25px", "2xl": "40px" }}
                                width={{ sm: "50%", "2xl": "40%" }}
                              >
                                <GridItem
                                  w={{
                                    sm: "170px",
                                    md: "170px",
                                    xl: "170px",
                                    "2xl": "180px",
                                  }}
                                >
                                  <InputFieldAverage
                                    placeholder="0.00"
                                    label="Low"
                                    inputType="number"
                                    inputId={`adrValues.${arrayKeys[pos]}.${index}.low`}
                                    inputName={`adrValues.${arrayKeys[pos]}.${index}.low`}
                                    errors={errors}
                                    touched={touched}
                                    isRequired
                                    values={
                                      formikValues?.adrValues[arrayKeys[pos]][
                                        index
                                      ]?.low
                                    }
                                    labelCenter={true}
                                    uppercase={false}
                                    onChange={onChange}
                                    inputRef={inputRef}
                                    // icon={percent}
                                    // leftIconText="SAR"
                                    // leftIcon={true}
                                    smallLeftIcon={"SAR"}
                                    disable={isRestore}
                                    validateField={validateStep}
                                    directVal={true}
                                  />
                                </GridItem>
                                <GridItem
                                  w={{
                                    sm: "170px",
                                    md: "170px",
                                    xl: "170px",
                                    "2xl": "180px",
                                  }}
                                >
                                  <InputFieldAverage
                                    placeholder="0.00"
                                    label="High"
                                    inputType="number"
                                    inputId={`adrValues.${arrayKeys[pos]}.${index}.high`}
                                    inputName={`adrValues.${arrayKeys[pos]}.${index}.high`}
                                    errors={errors}
                                    touched={touched}
                                    isRequired
                                    values={
                                      formikValues?.adrValues[arrayKeys[pos]][
                                        index
                                      ]?.high
                                    }
                                    labelCenter={true}
                                    uppercase={false}
                                    onChange={onChange}
                                    inputRef={inputRef}
                                    // icon={percent}
                                    smallLeftIcon={"SAR"}
                                    disable={isRestore}
                                    validateField={validateStep}
                                    directVal={true}
                                  />
                                </GridItem>
                                <GridItem
                                  w={{
                                    sm: "170px",
                                    md: "170px",
                                    lg: "170px",
                                    "2xl": "180px",
                                  }}
                                >
                                  <InputFieldAverage
                                    placeholder="0.00"
                                    label="Average"
                                    inputType="number"
                                    inputId={`adrValues.${arrayKeys[pos]}.${index}.average`}
                                    inputName={`adrValues.${arrayKeys[pos]}.${index}.average`}
                                    errors={errors}
                                    touched={touched}
                                    isRequired
                                    values={
                                      formikValues?.adrValues[arrayKeys[pos]][
                                        index
                                      ]?.average
                                    }
                                    labelCenter={true}
                                    uppercase={false}
                                    //
                                    onChange={onChange}
                                    inputRef={inputRef}
                                    disable={true}
                                    avg={true}
                                    // icon={percent}
                                    // smallLeftIcon={"SAR"}
                                    noBg={true}
                                    validateField={false}
                                    directVal={true}
                                  />
                                </GridItem>
                              </SimpleGrid>
                              <Box>
                                {parseFloat(
                                  formikValues?.adrValues[arrayKeys[pos]][index]
                                    ?.low
                                ) >
                                  parseFloat(
                                    formikValues?.adrValues[arrayKeys[pos]][
                                      index
                                    ]?.high
                                  ) && (
                                  <Box
                                    color="#e14642"
                                    fontWeight="400"
                                    fontSize="10px"
                                    lineHeight="17px"
                                    letterSpacing="0.04em"
                                    marginTop="-5px"
                                  >
                                    Low value should be less than high value
                                  </Box>
                                )}
                              </Box>
                            </GridItem>
                          </Box>
                        );
                      })}
                  </>
                )}
              />
            </AccordionPanel>
          </AccordionItem>
        </>
      );
    }
  };

  return (
    <>
      <Box alignItems="flex-start" width="100%" mb={5}>
        <MainTitle title="HOTEL KPIs" transform="unset" />
      </Box>

      <Box display="flex" alignItems="center" width="100%" position="relative">
        <Box maxW="50%" width="40%" alignSelf="flex-start">
          <DatePickerField
            label="Base Year"
            inputType="date"
            inputId="baseYear"
            inputName="baseYear"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues?.baseYear}
            onChange={onChange}
            onBlur={handleBlur}
            minDate={new Date(`01/01/${new Date().getFullYear()}`)}
            maxDate={new Date("01/01/2050")}
            hintText={`Select a year between ${new Date().getFullYear()} - 2050`}
            icon={calendar}
            isDisable={isRestore}
            validateField={false}
          />
        </Box>
        <Box className="tooltip_field3">
          <TooltipElement label="Base year is the starting year used to depict the 5-year projection." />
        </Box>
      </Box>

      <Box
        maxW={["100%", "40%", "40%"]}
        width={["100%", "40%", "40%"]}
        alignSelf="flex-start"
        mb={5}
      >
        <SelectListMulti
          dropdownItems={dropdownObj?.benchmarkLevel}
          label="Default benchmark level"
          inputId="benchmarkLevel"
          inputName="benchmarkLevel"
          placeholder="Select"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues?.benchmarkLevel}
          onChange={setFieldValue}
          onBlur={handleBlur}
          uppercase={true}
          isDisabled={true}
          defaultVal={{ value: "average", label: "Average" }}
        />
      </Box>

      <Box alignItems="flex-start" width="100%" mb={5}>
        <Badge
          fontSize="16px"
          borderRadius="5px"
          bg=" rgba(235, 192, 63, 0.15);"
          color="#1D1F22"
          fontWeight="400"
          lineHeight="24px"
          letterSpacing="0.06em"
          padding="10px 24px"
          mb="3"
        >
          ADR - OTHER REVENUES
        </Badge>
      </Box>

      <Accordion
        width="100%"
        allowToggle
        color="black"
        ref={inputRef}
        defaultIndex={[0]}
        className="accordionList"
      >
        {formikValues?.adrValues?.adr &&
          renderAccrodionFields(formikValues?.adrValues?.adr, 0, "adr")}
        {formikValues?.adrValues?.fnbRevenues &&
          renderAccrodionFields(
            formikValues?.adrValues?.fnbRevenues,
            1,
            "fnbRevenues"
          )}
        {formikValues?.adrValues?.otherRevenues &&
          renderAccrodionFields(
            formikValues?.adrValues?.otherRevenues,
            2,
            "otherRevenues"
          )}
      </Accordion>
      <Divider orientation="horizontal" mt="30px" mb="30px" />

      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          btnStyle="transparent"
          fontColor="dark"
          disabledBtn={isRestore}
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/HotelKpisHistory`}
            />
          )}

          {isDisabledForm ? (
            <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              onPress={() => {
                handleAccordErrors(errors);
                setValidateStep(true);
                isModelWarningOpen(true);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
          ) : (
            <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              onPress={() => {
                closeToast();
                nextPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
          )}
          {/* <MainButton
            title="Next"
            reverseRow={true}
            marginLeft="2"
            icon={rightArrow}
            disabledBtn={isDisabledForm}
            onPress={() => {
              nextPage();
              document.getElementById("main_content").scrollTo(0, 0);
            }}
          /> */}
        </Box>
      </Flex>
    </>
  );
};
