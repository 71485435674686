import { SAVE_FILTERS, LOAD_FILTERS, CLEAR_FILTERS } from '../actions/filter';

const initialState = {
  filters: {
    region: [],
    position: [],
    brand: [],
    operator: [],
    viewType: [
            { label: "Approved Assets", value: "approve" },
            { label: "In Planning Assets", value: "unapprove" },
            { label: "All Assets", value: "all" },
          ],
  },
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
      case SAVE_FILTERS:
        return {
          ...state,
          filters: action.payload,
        };
      case LOAD_FILTERS:
        return {
          ...state,
        };
      case CLEAR_FILTERS:
        return {
          ...state,
          filters: initialState.filters,
        };
      default:
        return state;
    }
  };

export default filterReducer;
