let newInitData = Array(100).fill({
  haylettFactorField: "",
});
export const GeneralAssumpFormInitialValues = {
  localCurrency: "SAR",
  benchmarkCurrency: "USD",
  currencyRate: 3.75,
  conversionToStatements: "",
  annualInflation: "",
  developmentCostInflation: "",
  firstPayment: "",
  comments: "",
  haylettFactor: newInitData,
};
