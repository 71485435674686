import React, { useEffect, useState, useCallback } from "react";

import {
  DeleteVisitorNumAssumptionsDraftAPI,
  GetVisitorNumAssumptionsAPI,
  GetVisitorNumAssumptionsCommentsAPI,
} from "../../api/VisitorNumAssumptionsAPI";

import { Box, Text, Center, Spinner, useDisclosure } from "@chakra-ui/react";

import { MainBox } from "../../components/mainBox/MainBox";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainButton } from "../../components/button/MainButton";
import { VisitorNumTable } from "./VisitorNumTable";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";

import plus from "../../assets/images/icons/plus.svg";
import doc from "../../assets/images/icons/doc.svg";
import infoCircle from "../../assets/images/icons/infoCircle.svg";

export const VisitorNumHistory = () => {
  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const [visitorData, setVisitorData] = useState([]);
  const [comments, setComments] = useState([]);
  const [selectedID, setSelectedID] = useState("-1");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    //GET VISITOR NUMBER ASSUMPTION VERSION
    GetVisitorNumAssumptionsAPI()
      .then((res) => {
        setVisitorData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const renderRowsAfterDeletion = (id) => {
    if (!id.startsWith("draft-")) {
      return;
    }

    //DELETE VISITOR NUMBER ASSUMPTION DRAFT VERSION
    DeleteVisitorNumAssumptionsDraftAPI(id.split("-")[1], id.split("-")[2])
      .then((res) => {
        setErrorMessage("");
        setIsLoading(true);
        if (res.success === true) {
          loadTableData();
        }
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "Something went wrong");
        onErrorOpen();
      });
  };

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  useEffect(() => {
    document.getElementById("main_content").scrollTo(0, 0);
    GetVisitorNumAssumptionsCommentsAPI()
      .then((res) => {
        setComments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <MainTitle title="VISITOR NUMBER ASSUMPTIONS" />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {visitorData?.pageRows?.length <= 0 ? (
            <>
              <MainTitle title="VISITOR NUMBER ASSUMPTIONS" />
              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">
                        No visitor number assumptions created yet
                      </Text>
                    </Box>
                    <MainButton
                      title="Create New"
                      marginRight="1"
                      icon={plus}
                      navLink={true}
                      path="/VisitorNumAssumptions"
                    />
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <VisitorNumTable
                visitorData={visitorData}
                comments={comments}
                setSelectedID={setSelectedID}
                onDelModalOpen={onDelModalOpen}
              />
            </>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isDelModalOpen}
        onClose={onDelModalClose}
        title="Are you sure you want to delete this version?"
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={true}
        haveSaveDraft={true}
        isDelete={true}
        selectedID={selectedID}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        renderRowsAfterDeletion={renderRowsAfterDeletion}
      />

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
    </>
  );
};
