import React from "react";

import { Box, Text, Center, Spinner } from "@chakra-ui/react";

import styles from "./summaryBox.module.scss";
import { isDecimalNumber } from "../../utils/General";

export const SummaryBox = ({
  title,
  number = 0,
  boxBg,
  circleBg,
  icon,
  border = false,
  isWhole,
  isPercent,
  isLoading = false,
  isDecimals = false,
}) => {
  var fixedNumber = number;
  if (!isNaN(number) && isWhole) {
    fixedNumber = number.toLocaleString("en-US", {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
  }
  if (!isNaN(number) && isPercent) {
    fixedNumber = (number * 100).toFixed(2) + " %";
  }
  if (!isNaN(number) && isDecimals) {
    fixedNumber = number.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }
  const SummaryBoxClassname = border ? `${styles.border_summary_box}` : "";
  return (
    <Box bg={boxBg} className={`${styles.summery_box} ${SummaryBoxClassname}`}>
      {isLoading ? (
        <Box width="100%" /* paddingTop={12} paddingBottom={12} */>
          <Center>
            <Spinner />
          </Center>
        </Box>
      ) : (
        <>
          <Box
            borderRadius={50}
            bg={circleBg}
            className={`${styles.summery_box_circle}`}
          >
            <img src={icon} alt="summary icon" />
          </Box>
          <Box>
            <Text className="title_md" fontSize="16px">
              {title}
            </Text>
            <Text className="num_lg">{fixedNumber}</Text>
          </Box>
        </>
      )}
    </Box>
  );
};
