// reducers.js
import { combineReducers } from 'redux';
import cardReducer from './cardReducer';
import dropdownReducer from './dropdownReducer';
import calculationsReducer from './calculationsReducer'
import approvalReducer from './approval';
import filterReducer from './filterReducer'


const rootReducer = combineReducers({
  card: cardReducer,
  dropdown: dropdownReducer,
  calculation: calculationsReducer,
  approval: approvalReducer,
  filters:filterReducer
  

  // anotherState: anotherReducer
});

export default rootReducer;
