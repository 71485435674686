import { Heading, Box } from "@chakra-ui/react";

export const MainTitle = ({
  title,
  pb = 6,
  pt = 2,
  transform = "uppercase",
}) => {
  let transformClass = transform === "unset" ? "transUnset" : "uppercase";
  return (
    <Box>
      <Heading
        fontSize="24px"
        className={`main_title ${transformClass}`}
        pb={pb}
        pt={pt}
        textTransform="lowercase"
      >
        {title}
      </Heading>
    </Box>
  );
};
