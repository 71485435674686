// FolderGrid.js
import { React, useState, useEffect } from 'react';
import {
    Box, Text, Flex, Badge, Center, Menu,
    MenuButton,
    MenuList,
    MenuItem, Button,
    useEditableControls,
    EditableInput,
    Editable,
    EditablePreview,
    Input,
    Image,
    useDisclosure,
    VStack,
    Spinner
} from '@chakra-ui/react';
import folderIcon from "../../assets/images/icons/folder.svg"
import { MainButton } from "../../components/button/MainButton";
import pdf from "../../assets/images/icons/pdf.svg"
import xls from "../../assets/images/icons/xlsx.svg"
import { GetFoldersAndFiles, DownloadFile, EditFile, EditFolder, DeleteFile, DeleteFolder } from "../../api/MarketReportApi";
import history from "../../history";
import { useParams } from "react-router-dom";
import FileModal from './createModal';
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import plus from "../../assets/images/icons/plus.svg";
import doc from "../../assets/images/icons/doc.svg";
import threeDots from "../../assets/images/icons/threeDots.svg";
import remove from "../../assets/images/icons/delete2.svg";
import pen from "../../assets/images/icons/penBlue.svg";
import download from 'downloadjs';
import editRow from "../../assets/images/icons/editRow.svg";
import removeRow from "../../assets/images/icons/removeRow.svg";
import checked from "../../assets/images/icons/checked.svg";
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import leftArrow from "../../assets/images/icons/leftArrow2.svg";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

const FolderAndFileGrid = () => {
    const [files, setFiles] = useState([]);
    const [folders, setFolders] = useState([]);
    const { id, name } = useParams();
    const [isModalOpen, setModalOpen] = useState(false);
    const [folderId, setFolderId] = useState(id);
    const [title, setTitle] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const {
        isOpen: isRequestSucsessOpen,
        onOpen: onRequestSuccessOpen,
        onClose: onRequestSucsessClose,
    } = useDisclosure();

    const {
        isOpen: isErrorOpen,
        onOpen: onErrorOpen,
        onClose: onErrorClose,
    } = useDisclosure();

    const whitelistedEmails = [
        'calogero.granatella@neom.com',
        'anjum.khan@neom.com',
        'dania.hamdan@neom.com',
        'muhannad.alomari@neom.com'

    ];

    useEffect(() => {
        setFolderId(id);
    }, [id]);

    const [user, setUser] = useState();

    useEffect(() => {
        if (Cookies.get("user_info")) {
            let token = Cookies.get("user_info");
            let tokenDecode = jwt_decode(token);
            setUser(tokenDecode);

        }
    }, []);

    const EditableControls = (data) => {
        const type = data.type;
        let folder = {}
        let file = {}
        if (type == 'folder')
            folder = data.folder;
        else
            file = data.file;
        const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
            getEditButtonProps,
        } = useEditableControls();

        return isEditing ? (
            <Box display="flex" justifyContent="space-around" width="80px">
                <button {...getSubmitButtonProps()}>
                    <Image src={editRow} alt="edit" width="20px" />
                </button>
                <button {...getCancelButtonProps()}>
                    <Image src={removeRow} alt="edit" width="20px" />
                </button>
            </Box>
        ) : (
            <Box flex="1" display="flex" justifyContent="flex-end">
                <Menu autoSelect={false}>
                    <MenuButton
                        bgColor="transparent"
                        as={Button}
                        px={1}
                        ml={1}
                        minWidth="auto"
                        _hover={{ backgroundColor: "transparent" }}
                    >
                        <img src={threeDots} alt="dots icon" />
                    </MenuButton>
                    <MenuList >
                        <Text fontSize="12px" color="#6F767E" px="10px" mb="7px" textAlign='left'>
                            More actions
                        </Text>

                        <MenuItem alignItems="center">
                            <Box
                                display="flex"
                                onClick={() => {
                                    handleDeleteClick(type == 'file' ? file.id : folder.id, type)
                                }}
                            >
                                <img src={remove} width="17px" alt="edit icon" />
                                <Text
                                    color="#003865"
                                    ml="5px"
                                    fontSize="12px"
                                    fontWeight="400"
                                >
                                    Remove Document
                                </Text>
                            </Box>
                        </MenuItem>
                        <MenuItem alignItems="center">
                            <button {...getEditButtonProps()}>
                                <Box display="flex">
                                    <img src={pen} width="13px" alt="edit icon" />
                                    <Text
                                        color="#003865"
                                        ml="5px"
                                        fontSize="12px"
                                        fontWeight="400"
                                    >
                                        Edit Document Name
                                    </Text>
                                </Box>
                            </button>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Box>

        );
    };

    const isChangeed = (event, lastName) => {
        return event !== "" && event !== lastName
    }

    const editFolderName = async (event, id, lastName) => {
        try {
            await EditFolder(id, { folderName: event });

            const updatedArray = folders.map(folder => {
                if (folder.id === id) {
                    folder.folderName = event;
                }
                return folder;
            });

            setFolders(updatedArray);
            setTitle('Folder name has been changed successfully');
            onRequestSuccessOpen();
        } catch (error) {
            const updatedArray = folders.map(folder => {
                if (folder.id === id) {
                    folder.folderName = lastName;
                }
                return folder;
            });

            setFolders(updatedArray);
            onErrorOpen();
        }
    };



    const editFileName = async (event, id, lastName) => {
        try {
            await EditFile(id, { fileName: event });

            const updatedArray = files.map(file => {
                if (file.id === id) {
                    file.fileName = event;
                }
                return file;
            });

            setFiles(updatedArray);
            setTitle('File name has been changed successfully');
            onRequestSuccessOpen();
        } catch (error) {
            const updatedArray = files.map(file => {
                if (file.id === id) {
                    file.fileName = lastName;
                }
                return file;
            });

            setFiles(updatedArray);
            onErrorOpen();

        }
    };



    const onEditModelName = (id, event, lastName, type) => {
        if (isChangeed(event, lastName)) {
            if (type === 'folder') {
                editFolderName(event, id, lastName);
            }
            else {
                editFileName(event, id, lastName);
            }
        }



    };

    useEffect(() => {
        const fetchFoldersAndFiles = async () => {
            try {
                // Fetch folders and files based on the current folderId
                const data = await GetFoldersAndFiles(folderId);

                // Update file types based on file extensions
                const updatedFiles = data.files.map(file => {
                    const fileExtension = file.fileName.split('.').pop().toLowerCase();
                    return { ...file, type: fileExtension };
                });

                // Set the state with the fetched folders and updated files
                setFiles(updatedFiles);
                setFolders(data.folders);

                // Set loading state to false
                setIsLoading(false);
            } catch (error) {
                // Handle errors by setting loading state to false and displaying an error modal
                setIsLoading(false);
                onErrorOpen();
            }
        };

        // Fetch folders and files when the folderId changes
        fetchFoldersAndFiles();

    }, [folderId]);


    const handleToggleModal = () => {
        setModalOpen(!isModalOpen);
    };


    const handleFolderClick = (folderId, name) => {
        history.push(`/MarketReports/${folderId}/${name}`);
    };


    // Example usage:
    // handleDownloadClick(fileId, 'example', 'pdf');
    // handleDownloadClick(fileId, 'document', 'xlsx');

    const handleDownloadClick = async (fileId, fileName, type) => {
        try {
            // Make API request to download the file
            const file = await DownloadFile(fileId, type);

            // Create a Blob from the file content
            const blob = new Blob([file]);

            // Determine the MIME type based on the file type
            const mimeType = type === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

            // Initiate the file download using the download function
            download(blob, `${fileName}.${type}`, mimeType);

            // Set success message and open success modal
            setTitle('File has been downloaded successfully');
            onRequestSuccessOpen();
        } catch (error) {
            // Handle any errors that occur during the download
            onErrorOpen();
        }
    };



    // Example usage:
    // handleDeleteClick(folderId, 'folder');
    // handleDeleteClick(fileId, 'file');

    const handleDeleteClick = async (id, type) => {
        try {
            // Determine if the item to delete is a folder or a file
            if (type === 'folder') {
                // Delete the folder and update the state
                await DeleteFolder(id);
                const updatedFolders = folders.filter(folder => folder.id !== id);
                setFolders(updatedFolders);
                setTitle('Folder has been deleted successfully');
                onRequestSuccessOpen();
            } else {
                // Delete the file and update the state
                await DeleteFile(id);
                const updatedFiles = files.filter(file => file.id !== id);
                setFiles(updatedFiles);
                setTitle('File has been deleted successfully');
                onRequestSuccessOpen();

            }
        } catch (error) {
            // Handle any errors that occur during deletion
            onErrorOpen();
        }
    };


    // Example usage:
    // handleCreate('folder', folderData, null);
    // handleCreate('file', fileData, null);
    // handleCreate('folder', null, error);
    const handleCreate = (isFolder, data, err) => {
        try {
            // Check for errors and display error modal if present
            if (err) {
                onErrorOpen();
                return;
            }

            if (isFolder === 'folder') {
                // If the user is creating a folder, update the folders array
                setFolders(prevFolders => [...prevFolders, data]);
                setTitle('Folder has been created successfully');
            } else {
                // If the user is uploading files, update the files array
                const updatedFiles = data.map(element => {
                    // Determine the file type based on the file extension
                    const fileExtension = element.fileName.split('.').pop().toLowerCase();
                    return { ...element, type: fileExtension };

                });

                setFiles(prevFiles => [...prevFiles, ...updatedFiles]);
                setTitle('File(s) have been uploaded successfully');
            }

            // Open success modal
            onRequestSuccessOpen();
        } catch (error) {
            // Handle any errors that occur during the creation process
            onErrorOpen();
        }

    }

    const checkUser = () => {
        return user && whitelistedEmails.includes(user.email);
    }

    const fileAndFolder = () => {
        return (<>
            <Flex flexWrap="wrap" gap={6}>
                {folders.map((folder) => (
                    <Box key={folder.id} p={4} cursor="pointer" width="222px"> {/* Set a fixed width, adjust as needed */}
                        <Flex direction="column" alignItems="center" textAlign="center">
                            <img
                                src={folderIcon}
                                alt="report icon"
                                onClick={() => handleFolderClick(folder.id, folder.folderName)}
                            />
                            {
                                checkUser() ? (
                                    <Editable
                                        defaultValue={
                                            folder.folderName.length > 20
                                                ? `${folder.folderName.slice(0, 20)}...`
                                                : folder.folderName
                                        }
                                        width="222px"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        justifyContent="center"
                                        onSubmit={(value) => {
                                            onEditModelName(folder.id, value, folder.folderName, 'folder');
                                        }}

                                    >
                                        <EditablePreview />
                                        <Flex alignItems="center">
                                            <Input fontSize='12px' as={EditableInput} borderRadius={0} textAlign="left" />
                                            <EditableControls folder={folder} type="folder" />
                                        </Flex>
                                    </Editable>

                                ) : (
                                    <Text>{folder.folderName.length > 20
                                        ? `${folder.folderName.slice(0, 20)}...`
                                        : folder.folderName}</Text>
                                )}
                        </Flex>
                    </Box>
                ))}
            </Flex>


            <Box mt={4}>
                {files.map((file, index) => (
                    <Flex
                        bg={index % 2 === 0 ? '#f7f7f7' : 'white'}
                        key={file.id}
                        alignItems="center"
                        p={4}
                        justifyContent="space-between"
                    >
                        <Flex alignItems="center" width="100%">
                            <img alt="folder" src={file.type === 'pdf' ? pdf : xls} />
                            <Box justifyContent={'space-between'} width="100%" ml={4}>

                                {user && whitelistedEmails.includes(user.email) ? (
                                    <Editable
                                        textAlign="center"
                                        defaultValue={file.fileName}
                                        width="100%"
                                        display="flex"
                                        alignItems="center"
                                        onSubmit={(value) => {
                                            onEditModelName(file.id, value, file.fileName, 'file');
                                        }}
                                    >
                                        <EditablePreview />
                                        {/* Here is the custom input */}
                                        <Input as={EditableInput} borderRadius={0} textAlign="left" />
                                        <EditableControls file={file} type='file' />
                                    </Editable>
                                ) : (
                                    <Text>{file.fileName}</Text>
                                )}
                            </Box>
                        </Flex>

                        <Badge
                            bg={'#ebc03f'}
                            color="#1d1f22"
                            fontSize="14px"
                            fontWeight="300"
                            px={3}
                            py="1"
                            textTransform="none"
                            borderRadius="3px"
                            ml={4}
                            textAlign={'right'}
                            cursor="pointer"
                            onClick={() => handleDownloadClick(file.id, file.fileName, file.type)}
                        >
                            Download
                        </Badge>
                    </Flex>
                ))}
            </Box>
        </>)
    }
    const fileAndFolderCheck = (folder, file) => {
        if (folders.length === 0 && files.length === 0)
            return (< Center flexDirection="column">
                <img src={doc} alt="icon" />
                <Box mt={3} mb={5}>
                    <Text className="title_sm">No document created yet</Text>
                </Box>
            </Center>)
        else {
            return fileAndFolder();

        }
    }


    return (

        <>
            <VStack gap="2" alignItems="left">
                <Box>
                    <Box display="inline-block" width="80px" pb={2}>
                        <MainButton
                            title="Back"
                            fontColor="dark"
                            btnStyle="transparent"
                            icon={leftArrow}
                            onPress={() => history.back()}
                        />
                    </Box>
                </Box>

            </VStack>

            <Box display="flex" justifyContent="space-between">
                <MainTitle title={`Market Reports - ${name}`} />
                {
                    user && whitelistedEmails.includes(user.email) && (
                        <Box textAlign='right'>
                            <MainButton
                                title="Create New Document"
                                icon={plus}
                                btnHeight="48px"
                                onPress={() => { handleToggleModal() }
                                }
                            />
                        </Box>)
                }

            </Box>

            {isModalOpen && (<FileModal isOpen={isModalOpen} onClose={handleToggleModal} handleCreate={handleCreate} />
            )}

            <MainBox>
                {
                    isLoading ? (<Box width="100%" paddingY={10}>
                        <Center>
                            <Spinner />
                        </Center>
                    </Box>) : fileAndFolderCheck()

                }


            </MainBox>

            {isRequestSucsessOpen && (<ModalAlert
                isOpen={isRequestSucsessOpen}
                onClose={onRequestSucsessClose}
                title={title}
                icon={checked}
                hasDesc={false}
                confirmationModal={false}
            />)}

            {isErrorOpen && (<ModalAlert
                isOpen={isErrorOpen}
                onClose={onErrorClose}
                title='Something went wrong'
                icon={infoCircle}
                hasDesc={false}
                confirmationModal={false}
            />)}

        </>
    );
};

export default FolderAndFileGrid;
