import React, { useEffect, useState } from "react";

import { Box, Divider, Flex, Text } from "@chakra-ui/react";

import { InputFieldValidation } from "../../../components/inputField/InputFieldValidation";
import { TextArea } from "../../../components/textArea/TextArea";
import { MainButton } from "../../../components/button/MainButton";
import { MainTitle } from "../../../components/mainTitle/MainTitle";

import leftArrow from "../../../assets/images/icons/Arrow-Left.svg";
import percent from "../../../assets/images/icons/filled.svg";

export const DevAndFinStep3 = ({
  onSubmitOpen,
  handleChange,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  onDraftOpen,
  errors,
  touched,
  values,
  onChange,
  setFieldValue,
  handleBlur,
  formikValues,
  handleOnSubmit,
  disableSave,
  setDismissSave,
  devData,
  dirty,
  isRestore,
  isModelWarningOpen,
  setValidateStep,
  validateStep,
  closeToast,
}) => {
  let form3Data = [];
  form3Data.push({
    fieldName: "annualInterestRate",
    value: formikValues.annualInterestRate,
  });
  form3Data.push({
    fieldName: "debtTerm",
    value: formikValues.debtTerm,
  });
  form3Data.push({
    fieldName: "gracePeriod",
    value: formikValues.gracePeriod,
  });
  form3Data.push({
    fieldName: "debtProcessingFees",
    value: formikValues.debtProcessingFees,
  });
  form3Data.push({
    fieldName: "securityFees",
    value: formikValues.securityFees,
  });
  form3Data.push({
    fieldName: "commitmentFees",
    value: formikValues.commitmentFees,
  });
  form3Data.push({
    fieldName: "riskFreeRate",
    value: formikValues.riskFreeRate,
  });
  form3Data.push({
    fieldName: "equityRiskPremium",
    value: formikValues.equityRiskPremium,
  });
  form3Data.push({
    fieldName: "countryRiskPremium",
    value: formikValues.countryRiskPremium,
  });
  form3Data.push({
    fieldName: "unleveredBeta",
    value: formikValues.unleveredBeta,
  });
  form3Data.push({
    fieldName: "manualInput",
    value: formikValues.manualInput,
  });
  //form3Data.push({ fieldName: "addComment", value: formikValues.addComment });
  let emptyform3Data = [];
  form3Data.forEach((item, ind) => {
    if (item.value !== "" && !isNaN(`${item.value}`) && item.value >= 0) {
      if (
        ind === 0 ||
        ind === 3 ||
        ind === 4 ||
        ind === 5 ||
        ind === 6 ||
        ind === 7 ||
        ind === 8 ||
        ind === 10
      ) {
        if (item.value <= 100) {
          emptyform3Data.push(item);
        }
      } else {
        emptyform3Data.push(item);
      }
    }
  });

  let isDisabledForm = false;
  let isDisabledFormProd;
  /* if (emptyform3Data.length !== form3Data.length) {
    isDisabledForm = true;
  }  */
  let status = devData?.status === "Production";

  if (devData?.status === "Production") {
    if (emptyform3Data.length !== form3Data.length) {
      isDisabledForm = true;
    }
    isDisabledFormProd =
      isDisabledForm === false && dirty === true ? false : true;
  } else {
    if (emptyform3Data.length !== form3Data.length) {
      isDisabledForm = true;
    }
  }

  return (
    <>
      <Box alignItems="flex-start" width="100%" mb={5}>
        <MainTitle title="Debt terms and risk calculations" />
      </Box>

      <Box
        maxW={["100%", "100%", "40%"]}
        width={["100%", "100%", "40%"]}
        alignSelf="flex-start"
      >
        <Text className="title_md" mb={4}>
          Debt Terms
        </Text>
        <Box display="flex" width="100%" gap="4%" flexWrap="wrap">
          <InputFieldValidation
            label="Annual Interest Rate"
            inputType="number"
            inputId="annualInterestRate"
            inputName="annualInterestRate"
            errors={errors}
            touched={touched}
            isRequired
            icon={percent}
            minNum="0"
            placeholder="0.00"
            onChange={setFieldValue}
            values={formikValues.annualInterestRate}
            isDisabled={isRestore}
            validateField={validateStep}
            directVal={true}
          />
          <InputFieldValidation
            label="Debt Term"
            inputType="number"
            inputId="debtTerm"
            inputName="debtTerm"
            errors={errors}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0.00"
            onChange={setFieldValue}
            values={formikValues.debtTerm}
            isDisabled={isRestore}
            validateField={validateStep}
            directVal={true}
          />
          <InputFieldValidation
            label="Grace Period (in Years)"
            inputType="number"
            inputId="gracePeriod"
            inputName="gracePeriod"
            errors={errors}
            touched={touched}
            isRequired
            /* icon={percent} */
            minNum="0"
            placeholder="0.00"
            onChange={setFieldValue}
            values={formikValues.gracePeriod}
            isDisabled={isRestore}
            validateField={validateStep}
            directVal={true}
          />
          <InputFieldValidation
            label="Debt Processing Fees"
            inputType="number"
            inputId="debtProcessingFees"
            inputName="debtProcessingFees"
            errors={errors}
            touched={touched}
            isRequired
            icon={percent}
            minNum="0"
            placeholder="0.00"
            onChange={setFieldValue}
            values={formikValues.debtProcessingFees}
            isDisabled={isRestore}
            validateField={validateStep}
            directVal={true}
          />
          <InputFieldValidation
            label="Security Fees"
            inputType="number"
            inputId="securityFees"
            inputName="securityFees"
            errors={errors}
            touched={touched}
            isRequired
            icon={percent}
            minNum="0"
            placeholder="0.00"
            onChange={setFieldValue}
            values={formikValues.securityFees}
            isDisabled={isRestore}
            validateField={validateStep}
            directVal={true}
          />
          <InputFieldValidation
            label="Commitment Fees (on undrawn amounts)"
            inputType="number"
            inputId="commitmentFees"
            inputName="commitmentFees"
            errors={errors}
            touched={touched}
            isRequired
            icon={percent}
            minNum="0"
            placeholder="0.00"
            onChange={setFieldValue}
            values={formikValues.commitmentFees}
            isDisabled={isRestore}
            validateField={validateStep}
            directVal={true}
          />
        </Box>
      </Box>

      <Box
        maxW={["100%", "100%", "40%"]}
        width={["100%", "100%", "40%"]}
        alignSelf="flex-start"
      >
        <Text className="title_md" mb={4} mt={7}>
          Risk Calculations
        </Text>
        <Box display="flex" width="100%" gap="4%" flexWrap="wrap">
          <InputFieldValidation
            label="Risk Free Rate"
            inputType="number"
            inputId="riskFreeRate"
            inputName="riskFreeRate"
            errors={errors}
            touched={touched}
            isRequired
            icon={percent}
            minNum="0"
            placeholder="0.00"
            onChange={setFieldValue}
            values={formikValues.riskFreeRate}
            isDisabled={isRestore}
            validateField={validateStep}
            directVal={true}
          />
          <InputFieldValidation
            label="Equity Risk Premium"
            inputType="number"
            inputId="equityRiskPremium"
            inputName="equityRiskPremium"
            errors={errors}
            touched={touched}
            isRequired
            icon={percent}
            minNum="0"
            placeholder="0.00"
            onChange={setFieldValue}
            values={formikValues.equityRiskPremium}
            isDisabled={isRestore}
            validateField={validateStep}
            directVal={true}
          />
          <InputFieldValidation
            label="Country Risk Premium"
            inputType="number"
            inputId="countryRiskPremium"
            inputName="countryRiskPremium"
            errors={errors}
            touched={touched}
            isRequired
            icon={percent}
            minNum="0"
            placeholder="0.00"
            onChange={setFieldValue}
            values={formikValues.countryRiskPremium}
            isDisabled={isRestore}
            validateField={validateStep}
            directVal={true}
          />
          <InputFieldValidation
            label="Unlevered Beta"
            inputType="number"
            inputId="unleveredBeta"
            inputName="unleveredBeta"
            errors={errors}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0.00"
            onChange={setFieldValue}
            values={formikValues.unleveredBeta}
            isDisabled={isRestore}
            validateField={validateStep}
            directVal={true}
          />
          <InputFieldValidation
            label="manual Input for Discount rate"
            inputType="number"
            inputId="manualInput"
            inputName="manualInput"
            errors={errors}
            touched={touched}
            isRequired
            icon={percent}
            minNum="0"
            placeholder="0.00"
            onChange={setFieldValue}
            values={formikValues.manualInput}
            isDisabled={isRestore}
            validateField={validateStep}
            directVal={true}
          />
        </Box>
      </Box>
      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Box
        maxW={["100%", "100%", "40%"]}
        width={["100%", "100%", "40%"]}
        alignSelf="flex-start"
      >
        <TextArea
          label="ADD COMMENT"
          inputId="comment"
          inputName="addComment"
          placeholder="Add description"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
          // isDisabled={isRestore}
        />
      </Box>

      <Divider orientation="horizontal" mt="40px" mb="30px" />
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          btnStyle="transparent"
          disabledBtn={isRestore}
          fontColor="dark"
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/DevAndFinHistory`}
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {!isDisabledForm ? (
              <MainButton
                title={isRestore ? "Restore" : "Submit"}
                reverseRow={true}
                marginLeft="2"
                disabledBtn={status ? isDisabledFormProd : false}
                onPress={() => {
                  closeToast();
                  handleOnSubmit(formikValues, isRestore);
                  setValidateStep(false);
                }}
              />
            ) : (
              <MainButton
                title="Submit"
                reverseRow={true}
                marginLeft="2"
                disabledBtn={false}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
