import axiosClient from "./index";


export const AddFolder = async (payload) => {
  const URL = `/v1/marketReport/addFolder`;
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    // throw response;
    console.log('data load', response)
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const GetFolder = async () => {
  const URL = `/v1/marketReport/listFolders`;
  try {
    const response = await axiosClient.get(URL);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};


export const UploadFile = async (payload) => {
  const URL = `/v1/marketReport/upload`;
  try {
    const response = await axiosClient.post(URL, payload);
    // throw response;

    return response.data;
  } catch (error) {

    throw error;
  }
};

export const GetFoldersAndFiles = async (folderId) => {
  const URL = `/v1/marketReport/listFolders/${folderId}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteFolder = async (folderId) => {
  const URL = `/v1/marketReport/delete/folder/${folderId}`;
  try {
    const response = await axiosClient.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteFile = async (fileId) => {
  const URL = `/v1/marketReport/delete/file/${fileId}`;
  try {
    const response = await axiosClient.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DownloadFile = async (fileId) => {
  const URL = `/v1/marketReport/download/${fileId}`;
  try {
    const response = await axiosClient.get(URL, {
      responseType: 'arraybuffer'
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const EditFolder = async (folderId, payload) => {
  const URL = `/v1/marketReport/edit/folder/${folderId}`;
  try {
    const response = await axiosClient.put(URL, {
      ...payload,
    });
    // throw response;
    return response.data;
  } catch (error) {

    throw error;
  }
};

export const EditFile = async (fileId, payload) => {
  const URL = `/v1/marketReport/edit/file/${fileId}`;
  try {
    const response = await axiosClient.put(URL, {
      ...payload,
    });
    // throw response;
    return response.data;
  } catch (error) {

    throw error;
  }
};