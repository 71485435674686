// MainComponent.js
import { React, useState, useEffect } from "react";

import {
  Box,
  Text,
  Flex,
  Center,
  Spinner,
  SimpleGrid,
  Image,
  Button,
  IconButton,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  ModalFooter,
} from "@chakra-ui/react";
import Select from "react-select";

import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import styles from "./assetCard.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchData,
  submitSelectedAssets,
  setMyAssetsSelected,
  setCompsetAssetsSelected,
} from "../../redux/actions/card.js"; // Import the thunk action
import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import room from "../../assets/images/icons/room.svg";
import position from "../../assets/images/icons/positioning.svg";
import region from "../../assets/images/icons/region.svg";
import info from "../../assets/images/icons/info.svg";
import operator from "../../assets/images/icons/operator.svg";
import brand from "../../assets/images/icons/brand.svg";
import submit from "../../assets/images/icons/submit.svg";

import history from "../../history";
import { useParams } from "react-router-dom";

const AssetCard = () => {
  const [user, setUser] = useState();
  const { type } = useParams();
  const initialFilters = {
    region: [],
    position: [],
  };
  const compset = useSelector((state) => state.card.compset);
  const myAssets = useSelector((state) => state.card.myAssets);
  const isLoading = useSelector((state) => state.card.loading);
  let myAssetsIds = myAssets
    .filter((asset) => asset.selected)
    .map((asset) => asset.id);
  let compAssetsIds = compset
    .filter((asset) => asset.selected)
    .map((asset) => asset.id);
  const [selectedCountMy, setSelectedCountMy] = useState(
    type ? myAssetsIds.length : 0
  );
  const [selectedCountCop, setSelectedCountCop] = useState(
    type ? compAssetsIds.length : 0
  );

  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const [filters, setFilters] = useState({
    region: [],
    position: [],
  });

  const [viewType, setViewType] = useState(type || "myAssets");

  let myAssetsRegion = useSelector((state) => state.card.myAssetsRegion);
  let myAssetsPosition = useSelector((state) => state.card.myAssetsPosition);
  let compsetRegion = useSelector((state) => state.card.compsetRegion);
  let compsetPosition = useSelector((state) => state.card.compsetPosition);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDescription, setSelectedDescription] = useState("");
  const [assetName, setAssetName] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    if (!type) {
      dispatch(fetchData());
    }
  }, [dispatch]);

  const renderDropDownItems = (data) => {
    const extractedData = data.reduce((unique, item) => {
      const existingItem = unique.find((elem) => elem.label === item.label);
      if (!existingItem) {
        unique.push({ label: item.label, value: item.value });
      }
      return unique;
    }, []);

    extractedData.sort((a, b) => a.label.localeCompare(b.label));

    return extractedData;
  };

  // Decode and set user information from cookies on component mount
  useEffect(() => {
    // Check if user_info cookie exists and user state is not set
    if (Cookies.get("user_info") && !user) {
      try {
        // Retrieve token from cookie and decode it
        let token = Cookies.get("user_info");
        let tokenDecode = jwt_decode(token);

        // Set user state with decoded token information
        setUser(tokenDecode);
      } catch (error) {
        // Handle decoding errors by logging the error
        console.error("Error decoding user token:", error);
      }
    }
  }, [user]); // Add the 'user' dependency to prevent unnecessary re-execution when 'user' changes

  const handleShowOnlySelected = () => {
    setShowOnlySelected(!showOnlySelected);
  };
  const handleSubmit = () => {
    let myAssetsIds = myAssets
      .filter((asset) => asset.selected)
      .map((asset) => asset.id);
    let compAssetsIds = compset
      .filter((asset) => asset.selected)
      .map((asset) => asset.id);
    dispatch(submitSelectedAssets(myAssetsIds, compAssetsIds));
    history.push(`/AssetComp/review`);
  };

  const handleBoxClick = (id, type) => {
    if (type == "compAsset")
      compset.map((asset) => {
        if (asset.id == id) {
          dispatch(setCompsetAssetsSelected(id, !asset.selected));

          if (asset.selected) setSelectedCountCop(selectedCountCop - 1);
          else setSelectedCountCop(selectedCountCop + 1);
          asset.selected = !asset.selected;
        }
        return asset;
      });
    else
      myAssets.map((asset) => {
        if (asset.id == id) {
          if (asset.selected) {
            dispatch(setMyAssetsSelected(id, false));
            setSelectedCountMy(selectedCountMy - 1);
          } else {
            dispatch(setMyAssetsSelected(id, true));
            setSelectedCountMy(selectedCountMy + 1);
          }
          asset.selected = !asset.selected;
        }
        return asset;
      });
  };

  const handleFilterChange = (selectedOption, filterType) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: selectedOption,
    }));
  };

  const applyFilters = (data) => {
    let filteredData = [...data];

    // Apply region filter
    if (filters.region.length > 0) {
      filteredData = filteredData.filter((asset) =>
        filters.region.some((region) => region.value === asset.regionLookup)
      );
    }

    // Apply position filter
    if (filters.position.length > 0) {
      filteredData = filteredData.filter((asset) =>
        filters.position.some(
          (position) => position.value === asset.positioningLookup
        )
      );
    }

    return filteredData;
  };

  const handleUnselectAll = () => {
    let assetsToSelect;
    if (viewType == "myAssets") {
      assetsToSelect = applyFilters(myAssets);
      assetsToSelect.forEach((asset) => {
        asset.selected = false;
      });

      if (assetsToSelect.length == myAssets.length) setSelectedCountMy(0);
      else setSelectedCountMy(selectedCountMy - assetsToSelect.length);
    } else {
      assetsToSelect = applyFilters(compset);
      assetsToSelect.forEach((asset) => {
        asset.selected = false;
      });

      if (assetsToSelect.length == compset.length) setSelectedCountCop(0);
      else setSelectedCountCop(selectedCountCop - assetsToSelect.length);
      // setSelectedCountCop(selectedCountCop- assetsToSelect.length);
    }
  };

  const renderCard = (data, type) => {
    let assetsToRender = showOnlySelected
      ? data.filter((asset) => asset.selected)
      : applyFilters(data);

    return (
      <SimpleGrid columns={[1, 3, 5]} spacing={4}>
        {" "}
        {/* Render 1 column for small screens, 2 columns for medium screens, and 3 columns for large screens */}
        {assetsToRender.map((asset) => (
          <Box
            key={asset.id}
            className={`${styles.ag_courses_item} ${
              asset.selected ? styles.checked : ""
            }`}
          >
            <Box
              onClick={() => handleBoxClick(asset.id, type)}
              className={`${styles.ag_courses_item_link}`}
            >
              <Box
                className={
                  asset.selected
                    ? `${styles.ag_courses_item_bg_selected}`
                    : `${styles.ag_courses_item_bg}`
                }
              ></Box>

              <Box
                className={`${styles.ag_courses_item_content} ${styles.ag_courses_item_date_box}`}
              >
                <Text className={`${styles.ag_courses_item_title}`}>
                  {asset.projectName}
                </Text>
              </Box>

              <Box
                className={`${styles.ag_courses_item_content} ${styles.ag_courses_item_date_box}`}
              >
                <Box className={`${styles.icon_wrapper}`}>
                  <Image src={region} alt="Icon" />
                </Box>
                <Text className={`${styles.ag_courses_item_text}`}>
                  {asset.region}
                </Text>
              </Box>
              <Box
                className={`${styles.ag_courses_item_content} ${styles.ag_courses_item_date_box}`}
              >
                <Box className={`${styles.icon_wrapper}`}>
                  <Image src={position} alt="Icon" />
                </Box>
                <Text className={`${styles.ag_courses_item_text}`}>
                  {asset.positioning}
                </Text>
              </Box>
              <Box
                className={`${styles.ag_courses_item_content} ${styles.ag_courses_item_date_box}`}
              >
                <Box className={`${styles.icon_wrapper}`}>
                  <Image src={room} alt="Icon" />
                </Box>
                <Text className={`${styles.ag_courses_item_text}`}>
                  {asset.numberOfKeys}
                </Text>
              </Box>
              <Box
                className={`${styles.ag_courses_item_content} ${styles.ag_courses_item_date_box}`}
              >
                <Box className={`${styles.icon_wrapper}`}>
                  <Image src={brand} alt="Icon" />
                </Box>
                <Text className={`${styles.ag_courses_item_text}`}>
                  {asset.brand}
                </Text>
              </Box>
              <Box
                className={`${styles.ag_courses_item_content} ${styles.ag_courses_item_date_box}`}
              >
                <Box className={`${styles.icon_wrapper}`}>
                  <Image src={operator} alt="Icon" />
                </Box>
                <Text className={`${styles.ag_courses_item_text}`}>
                  {asset.operator}
                </Text>
              </Box>
            </Box>
            <Box className={`${styles.ag_courses_item_link}`}>
              {/* <Text fontSize={"15px"} className={`${styles.ag_courses_item_text}`}>
                                        {asset.description}
                                    </Text> */}

              <Button
                fontWeight="100px"
                backgroundColor="#f2d98c"
                onClick={() =>
                  openModalWithDescription(asset.description, asset.projectName)
                }
              >
                <Image marginRight="5px" src={info} alt="Icon" />
                <Text>Show Description</Text>
              </Button>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    );
  };

  const handleSelectAll = () => {
    // Select all assets in myAssets
    let assetsToSelect;
    if (viewType == "myAssets") {
      assetsToSelect = applyFilters(myAssets);
      setSelectedCountMy(assetsToSelect.length);
      assetsToSelect.forEach((asset) => {
        asset.selected = true;
      });
    } else {
      assetsToSelect = applyFilters(compset);
      assetsToSelect.forEach((asset) => {
        asset.selected = true;
      });
      setSelectedCountCop(assetsToSelect.length);
    }

    // Update state
    // dispatch(submitSelectedAssets([], [])); // Assuming this resets the selected assets in the Redux store
  };

  const setIdsinLocalStorage = () => {
    let myAssetsIds = myAssets
      .filter((asset) => asset.selected)
      .map((asset) => asset.id);
    let compAssetsIds = compset
      .filter((asset) => asset.selected)
      .map((asset) => asset.id);
    localStorage.setItem("myAssetsIds", JSON.stringify(myAssetsIds));
    localStorage.setItem("compAssetsIds", JSON.stringify(compAssetsIds));
  };
  const openModalWithDescription = (description, projectName) => {
    setSelectedDescription(description);
    setAssetName(projectName);
    onOpen();
  };
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <MainTitle
          title={viewType == "myAssets" ? "My Assets" : "Compset Assets"}
        />
      </Box>

      <Box
        bg="white"
        py="15"
        px="25"
        maxW="100%"
        color="white"
        // w="100%"
        borderTopWidth="3px"
        borderTopColor="#EBC03F"
        borderTopStyle="solid"
        className="main_box box_shadow"
      >
        <Flex justifyContent="space-between">
          <Tooltip label="Previous">
            <IconButton
              // width="110px"
              lineHeight="24px"
              fontWeight="400px"
              fontSize="15px"
              borderRadius="5px"
              backgroundColor="#f2d98c"
              icon={<Image src={leftArrow} />}
              isDisabled={viewType !== "compset"}
              onClick={() => {
                setViewType("myAssets");
                setIdsinLocalStorage();
                setShowOnlySelected(false);
                document.getElementById("card_content").scrollTo(0, 0);
                setFilters(initialFilters);
              }}
            />
          </Tooltip>

          <Button
            width="110px"
            lineHeight="24px"
            fontWeight="400px"
            fontSize="15px"
            borderRadius="5px"
            backgroundColor="#f2d98c"
            onClick={handleShowOnlySelected}
          >
            {viewType == "compset" ? selectedCountCop : selectedCountMy}
          </Button>

          <Button
            width="110px"
            lineHeight="24px"
            fontWeight="400px"
            fontSize="15px"
            borderRadius="5px"
            backgroundColor="#f2d98c"
            onClick={handleSelectAll}
          >
            Select All
          </Button>
          <Button
            width="110px"
            lineHeight="24px"
            fontWeight="400px"
            fontSize="15px"
            borderRadius="5px"
            backgroundColor="#f2d98c"
            onClick={handleUnselectAll}
            isDisabled={selectedCountMy === 0 && selectedCountCop === 0}
          >
            Unselect All
          </Button>

          <Select
            isMulti
            isClearable
            value={filters.region} // Controlled component
            placeholder="Regions ..."
            options={
              viewType == "myAssets"
                ? renderDropDownItems(myAssetsRegion)
                : renderDropDownItems(compsetRegion)
            }
            styles={{ control: (provided) => ({ ...provided, width: 300 }) }} // Adjust the width here
            onChange={(selectedOption) =>
              handleFilterChange(selectedOption, "region")
            }
          />
          <Select
            isMulti
            isClearable
            value={filters.position} // Controlled component
            placeholder="Position ..."
            options={
              viewType == "myAssets"
                ? renderDropDownItems(myAssetsPosition)
                : renderDropDownItems(compsetPosition)
            }
            styles={{ control: (provided) => ({ ...provided, width: 300 }) }} // Adjust the width here
            onChange={(selectedOption) =>
              handleFilterChange(selectedOption, "position")
            }
          />

          {viewType == "compset" ? (
            <Tooltip label="Submit">
              <IconButton
                lineHeight="24px"
                fontWeight="400px"
                fontSize="15px"
                borderRadius="5px"
                backgroundColor="#f2d98c"
                icon={<Image src={submit} />}
                isDisabled={selectedCountMy == 0}
                onClick={() => {
                  handleSubmit();
                  setIdsinLocalStorage();
                  setFilters(initialFilters);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip label="Next">
              <IconButton
                // width="80px"
                lineHeight="24px"
                fontWeight="400px"
                fontSize="15px"
                borderRadius="5px"
                backgroundColor="#f2d98c"
                isDisabled={selectedCountMy == 0}
                icon={<Image src={rightArrow} />}
                onClick={() => {
                  setViewType("compset");
                  setIdsinLocalStorage();
                  setShowOnlySelected(false);
                  document.getElementById("card_content").scrollTo(0, 0);
                  setFilters(initialFilters);
                }}
              />
            </Tooltip>
          )}
        </Flex>
      </Box>

      <Box
        bg="white"
        py="15"
        px="25"
        maxW="100%"
        color="white"
        // w="100%"
        overflowY="scroll"
        height="70vh"
        id="card_content"
        scrollBehavior="smooth"
      >
        {isLoading ? (
          <Box width="100%" paddingY={10}>
            <Center>
              <Spinner />
            </Center>
          </Box>
        ) : (
          <Box id="test" mt="20px" className={`${styles.ag_format_container}`}>
            {viewType == "myAssets"
              ? renderCard(myAssets, "myAssets")
              : renderCard(compset, "compAsset")}
          </Box>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{assetName} Description</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedDescription.split("\n").map((line, index) => (
              <Text key={index} mb={2}>
                {line}
              </Text>
            ))}{" "}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <Divider orientation="horizontal" mt="20px" mb="30px" /> */}
    </>
  );
};

export default AssetCard;
