import React, { useState, useEffect } from "react";

import {
  GetGeneralAssumptionsByIdAPI,
  AddGeneralAssumptionsAPI,
  GetGeneralAssumptionsDraftByIdAPI,
} from "../../api/GeneralAssumptionsAPI";

import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";
import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import {
  Box,
  Text,
  useDisclosure,
  HStack,
  Badge,
  Spacer,
  useToast,
} from "@chakra-ui/react";

import { activeMenuItem } from "../../utils/General";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { GeneralAssumpStep1 } from "./GeneralAssumpStep1";
import { GeneralAssumpStep2 } from "./GeneralAssumpStep2";
import { GeneralAssumpStep3 } from "./GeneralAssumpStep3";
import { validationGeneralAssumpForm } from "./validationGeneralAssumpForm";
import { GeneralAssumpFormInitialValues } from "./GeneralAssumpFormInitialValues";
import { ToastAlert } from "../../components/toastAlert/ToastAlert";

import history from "../../history";

import checked from "../../assets/images/icons/checked.svg";
import infoCircle from "../../assets/images/icons/infoCircle.svg";

export const GeneralAssumptions = () => {
  const activeMenu = activeMenuItem();
  const { id, draftId } = useParams();
  let location = useLocation();
  const [isRestore, setIsRestore] = useState(false);
  const [initFormVal, setInitFormVal] = useState(
    GeneralAssumpFormInitialValues
  );
  const [generalData, setGeneralData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [page, setPage] = useState(0);
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [validateStep, setValidateStep] = useState(false);
  const [modelWarningOpen, isModelWarningOpen] = useState(false);
  const steps = [
    { title: "General Details" },
    { title: "ALOS" },
    { title: "GPOR" },
  ];

  let date;
  let lastMod;
  let submitModalTitle = "General assumption is successfully created.";
  const toast = useToast();

  if (!!generalData.lastModifiedAt) {
    date = dateFnsParseISO(generalData.lastModifiedAt);
    lastMod = dateFnsFormat(date, "dd.MM.yyyy; HH:mm");
  }
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();
  const {
    isOpen: isDraftOpen,
    onOpen: onDraftOpen,
    onClose: onDraftClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const handleOnSubmit = (formikValue, isRestore) => {
    if (!!id) {
      let commentsLogic =
        generalData.comments === formikValue.comments
          ? ""
          : formikValue.comments;

      let newHaylettFactor = Array(100).fill("");
      formikValue.haylettFactor.forEach((formValue, index) => {
        if (formValue.haylettFactorField) {
          newHaylettFactor[index] = formValue.haylettFactorField;
        }
      });
      const submitValues = {
        ...formikValue,
        isDraft: false,
        isRestore: !!isRestore,
        comments: commentsLogic,
        parentVersionNumber:
          generalData.versionNumber || generalData.parentVersionNumber,
        haylettFactor: newHaylettFactor,
      };

      AddGeneralAssumptionsAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            onErrorOpen();

            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      let newHaylettFactor = Array(100).fill("");
      formikValue.haylettFactor.forEach((formValue, index) => {
        if (formValue.haylettFactorField) {
          newHaylettFactor[index] = formValue.haylettFactorField;
        }
      });
      const submitValues = {
        ...formikValue,
        isDraft: false,
        parentVersionNumber: -1,
        haylettFactor: newHaylettFactor,
      };

      AddGeneralAssumptionsAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            onErrorOpen();
            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };
  const handleSaveDraft = (formikValue) => {
    if (!!id) {
      let commentsLogic =
        generalData.comments === formikValue.comments
          ? ""
          : formikValue.comments;
      let newHaylettFactor = Array(100).fill("");
      formikValue.haylettFactor.forEach((formValue, index) => {
        if (formValue.haylettFactorField) {
          newHaylettFactor[index] = formValue.haylettFactorField;
        }
      });
      const submitValues = {
        ...formikValue,
        isDraft: true,
        versionNumber: !!draftId ? Number(draftId) : 0,
        parentVersionNumber: Number(id),
        comments: commentsLogic,
        haylettFactor: newHaylettFactor,
      };

      AddGeneralAssumptionsAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onDraftClose();
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      let newHaylettFactor = Array(100).fill("");
      formikValue.haylettFactor.forEach((formValue, index) => {
        if (formValue.haylettFactorField) {
          newHaylettFactor[index] = formValue.haylettFactorField;
        }
      });
      const submitValues = {
        ...formikValue,
        isDraft: true,
        haylettFactor: newHaylettFactor,
      };

      AddGeneralAssumptionsAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onDraftClose();
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };

  const closeToast = () => {
    toast.closeAll();
  };
  useEffect(() => {
    // console.log("modelWarningOpen", modelWarningOpen);
    if (modelWarningOpen) {
      //let count = 0;
      //if (count === 0) {
      toast({
        position: "top-right",
        isClosable: true,
        render: () => (
          <ToastAlert
            title="You must fill all the fields."
            icon={infoCircle}
            closeToast={closeToast}
            type="Warning"
          />
        ),
      });
      //count = count + 1;
    }
    // setValidateStep(false);
    isModelWarningOpen(false);
    //}
  }, [modelWarningOpen]);

  useEffect(() => {
    let formField;

    if (!!id && !!draftId) {
      setErrorMessage("");
      //GET GENERAL ASSUMPTION DRAFT VERSION
      GetGeneralAssumptionsDraftByIdAPI(id, draftId)
        .then((res) => {
          formField = JSON.parse(res.data.formFields);

          let newInitData = formField.haylettFactor.map((factor) => ({
            haylettFactorField: factor,
          }));

          //UPDATE FORMIL INITIAL VALUES
          setInitFormVal({
            localCurrency: formField.localCurrency,
            benchmarkCurrency: formField.benchmarkCurrency,
            currencyRate: formField.currencyRate,
            conversionToStatements: formField.conversionToStatements,
            annualInflation: formField.annualInflation,
            developmentCostInflation: formField.developmentCostInflation,
            firstPayment: formField.firstPayment,
            comments: res.data.comments,
            haylettFactor: newInitData,
          });
          setGeneralData(res.data);
        })
        .catch((err) => {
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    } else if (!!id) {
      setErrorMessage("");
      //GET GENERAL ASSUMPTION VERSION
      GetGeneralAssumptionsByIdAPI(id)
        .then((res) => {
          formField = JSON.parse(res.data.formFields);

          let newInitData = formField.haylettFactor.map((factor) => ({
            haylettFactorField: factor,
          }));

          setInitFormVal({
            localCurrency: formField.localCurrency,
            benchmarkCurrency: formField.benchmarkCurrency,
            currencyRate: formField.currencyRate,
            conversionToStatements: formField.conversionToStatements,
            annualInflation: formField.annualInflation,
            developmentCostInflation: formField.developmentCostInflation,
            firstPayment: formField.firstPayment,
            comments: res.data.comments,
            haylettFactor: newInitData,
          });
          setGeneralData(res.data);
        })
        .catch((err) => {
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    }

    if (location?.state?.from === "Restore") {
      setIsRestore(true);
    }
  }, []);
  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`Are you sure you want to discard the changes?`)) {
          unblock();
          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  return (
    <div className="Assumptions">
      <>
        {id === undefined ? (
          <MainTitle title="Create general assumptions" />
        ) : (
          <MainTitle title="Edit general assumptions" />
        )}

        <MainBox>
          <Box className="table_content" pt={6} pb={6}>
            <Box display="flex">
              <Text className="title_md" display="flex" component="div">
                STEP
              </Text>
              <Text ml={1}>
                {page + 1}/{steps.length}
              </Text>
              {id !== undefined && (
                <HStack w="100%" px="8">
                  <Badge
                    bg="rgba(0, 123, 181, 0.2)"
                    className="masterDataBadge"
                  >
                    Version Number:{" "}
                    {generalData.status === "Draft" ||
                    generalData.status === "InitialDraft"
                      ? generalData.parentVersionNumber
                      : generalData.versionNumber}
                  </Badge>
                  <Badge
                    bg="rgba(241, 136, 37, 0.3)"
                    className="masterDataBadge"
                  >
                    Last Modified By: {generalData.lastModifiedBy}, {lastMod}
                  </Badge>

                  {/* IF NOT SARA SHOW VIEW ONLY */}
                  <Spacer />
                  {/* <HStack
                  px="2"
                  py="1"
                  borderRadius="5"
                  fontSize="12"
                  bg="rgba(0, 107, 68, 0.2);"
                >
                  <Image src={eye}></Image>
                  <Text>View Only</Text>
                </HStack> */}
                </HStack>
              )}
            </Box>

            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validationSchema={validationGeneralAssumpForm[page]}
              validate={(values) => {
                if (disableSave) {
                  setDisableSave(false);
                }
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleBlur,
                setFieldValue,
                isValid,
                dirty,
                setFieldTouched,
              }) => (
                <Form className="form_wrapper">
                  {page === 0 && (
                    <GeneralAssumpStep1
                      nextPage={nextPage}
                      onCancelOpen={onCancelOpen}
                      onDraftOpen={onDraftOpen}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      formikValues={values}
                      isValid={isValid}
                      dirty={dirty}
                      disableSave={disableSave}
                      setDisableSave={setDisableSave}
                      dismissSave={dismissSave}
                      setDismissSave={setDismissSave}
                      isRestore={isRestore}
                      validateStep={validateStep}
                      setValidateStep={setValidateStep}
                      isModelWarningOpen={isModelWarningOpen}
                      closeToast={closeToast}
                      setFieldTouched={setFieldTouched}
                    />
                  )}
                  {page === 1 && (
                    <GeneralAssumpStep2
                      nextPage={nextPage}
                      prevPage={prevPage}
                      onCancelOpen={onCancelOpen}
                      onDraftOpen={onDraftOpen}
                      errors={errors}
                      touched={touched}
                      formikValues={values}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isValid={isValid}
                      dirty={dirty}
                      dismissSave={dismissSave}
                      setDismissSave={setDismissSave}
                      isRestore={isRestore}
                      validateStep={validateStep}
                      setValidateStep={setValidateStep}
                      isModelWarningOpen={isModelWarningOpen}
                      closeToast={closeToast}
                      setFieldTouched={setFieldTouched}
                    />
                  )}
                  {page === 2 && (
                    <GeneralAssumpStep3
                      prevPage={prevPage}
                      onSubmitOpen={onSubmitOpen}
                      onCancelOpen={onCancelOpen}
                      onDraftOpen={onDraftOpen}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      formikValues={values}
                      handleOnSubmit={handleOnSubmit}
                      dismissSave={dismissSave}
                      setDismissSave={setDismissSave}
                      generalData={generalData}
                      dirty={dirty}
                      isRestore={isRestore}
                      validateStep={validateStep}
                      setValidateStep={setValidateStep}
                      isModelWarningOpen={isModelWarningOpen}
                      closeToast={closeToast}
                      setFieldTouched={setFieldTouched}
                    />
                  )}

                  <ModalAlert
                    isOpen={isDraftOpen}
                    onClose={onDraftClose}
                    title="Are you sure you want to save this as draft?"
                    icon={infoCircle}
                    confirmationModal={true}
                    hasDesc={false}
                    haveSaveDraft={false}
                    path={`/AddGeneralAssumptions`}
                    pathDraft={`/AddGeneralAssumptions/success`}
                    onPressSaveDraft={() => handleSaveDraft(values)}
                  />
                  <ModalAlert
                    isOpen={isCancelOpen}
                    onClose={onCancelClose}
                    title="Are you sure you want to discard the changes?"
                    icon={infoCircle}
                    hasDesc={false}
                    confirmationModal={true}
                    path={`/AddGeneralAssumptions`}
                    onPressSaveDraft={() => handleSaveDraft(values)}
                    setDismissSave={() => setDismissSave(true)}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </MainBox>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/AddGeneralAssumptions`}
        />
      </>
    </div>
  );
};
