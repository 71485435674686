import axiosClient from "./index";
import Cookies from "js-cookie";

//DEVELOPMENT AND COST
export const GetDevelopmentCostsAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/benchmarks/developmentCosts?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = "/v1/benchmarks/developmentCosts";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetDevelopmentCostsCommentsAPI = async () => {
  const URL = "/v1/benchmarks/developmentCosts/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetDevelopmentCostsByIdAPI = async (id) => {
  const URL = `/v1/benchmarks/developmentCosts/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AddDevelopmentCostsAPI = async (payload) => {
  const URL = "/v1/benchmarks/developmentCosts";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};

export const UpdateDevelopmentCostsAPI = async (payload, id) => {
  const URL = `/v1/benchmarks/developmentCosts/${id}`;
  try {
    const response = await axiosClient.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteDevelopmentCostsByIdAPI = async (id) => {
  const URL = `/v1/benchmarks/developmentCosts/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetDevelopmentCostsDraftByIdAPI = async (parentId, draftId) => {
  const URL = `/v1/benchmarks/developmentCosts/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteDevelopmentCostsDraftAPI = async (parentId, draftId) => {
  const URL = `/v1/benchmarks/developmentCosts/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

//HOTEL KPIS
export const GetHotelKpisAPI = async (
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/benchmarks/hotelKpis?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = "/v1/benchmarks/hotelKpis";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetHotelKpisCommentsAPI = async () => {
  const URL = "/v1/benchmarks/hotelKpis/comments";
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetHotelKpisByIdAPI = async (id) => {
  const URL = `/v1/benchmarks/hotelKpis/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AddHotelKpisAPI = async (payload) => {
  const URL = "/v1/benchmarks/hotelKpis";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};

export const UpdateHotelKpisAPI = async (payload, id) => {
  const URL = `/v1/benchmarks/hotelKpis/${id}`;
  try {
    const response = await axiosClient.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteHotelKpisByIdAPI = async (id) => {
  const URL = `/v1/benchmarks/hotelKpis/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetHotelKpisDraftByIdAPI = async (parentId, draftId) => {
  const URL = `/v1/benchmarks/hotelKpis/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteHotelKpisDraftAPI = async (parentId, draftId) => {
  const URL = `/v1/benchmarks/hotelKpis/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

//CITY BENCHMARKS
export const GetCityBenchmarksAPI = async (
  positioningId,
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/benchmarks/cityBenchmarks/${positioningId}?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;
  //const URL = `/v1/benchmarks/cityBenchmarks/${positioningId}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetCityBenchmarksCommentsAPI = async (positioningId) => {
  const URL = `/v1/benchmarks/cityBenchmarks/${positioningId}/comments`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetCityBenchmarksByIdAPI = async (positioningId, id) => {
  const URL = `/v1/benchmarks/cityBenchmarks/${positioningId}/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AddCityBenchmarksAPI = async (positioningId, payload) => {
  const URL = `/v1/benchmarks/cityBenchmarks/${positioningId}`;
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};

export const GetCityBenchmarksDraftByIdAPI = async (
  positioningId,
  parentId,
  draftId
) => {
  const URL = `/v1/benchmarks/cityBenchmarks/${positioningId}/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteCityBenchmarksDraftAPI = async (
  positioningId,
  parentId,
  draftId
) => {
  const URL = `/v1/benchmarks/cityBenchmarks/${positioningId}/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

//RESORT BENCHMARKS
export const GetResortBenchmarksAPI = async (
  positioningId,
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/benchmarks/resortBenchmarks/${positioningId}?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;

  //const URL = `/v1/benchmarks/resortBenchmarks/${positioningId}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetResortBenchmarksCommentsAPI = async (positioningId) => {
  const URL = `/v1/benchmarks/resortBenchmarks/${positioningId}/comments`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetResortBenchmarksByIdAPI = async (positioningId, id) => {
  const URL = `/v1/benchmarks/resortBenchmarks/${positioningId}/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AddResortBenchmarksAPI = async (positioningId, payload) => {
  const URL = `/v1/benchmarks/resortBenchmarks/${positioningId}`;
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};

export const GetResortBenchmarksDraftByIdAPI = async (
  positioningId,
  parentId,
  draftId
) => {
  const URL = `/v1/benchmarks/resortBenchmarks/${positioningId}/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteResortBenchmarksDraftAPI = async (
  positioningId,
  parentId,
  draftId
) => {
  const URL = `/v1/benchmarks/resortBenchmarks/${positioningId}/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

//SERVICED APARTMANT
export const GetServicedApartmentsAPI = async (
  positioningId,
  pageNumber = 0,
  sort = "lastModifiedAt",
  sortOrder = "DESC"
) => {
  if (pageNumber > 0) pageNumber = pageNumber - 1;
  const URL = `/v1/benchmarks/servicedApartmentBenchmarks/${positioningId}?pageNumber=${pageNumber}&sort=${sort}&sortOrder=${sortOrder}`;

  // const URL = `/v1/benchmarks/servicedApartmentBenchmarks/${positioningId}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetServicedApartmentsCommentsAPI = async (positioningId) => {
  const URL = `/v1/benchmarks/servicedApartmentBenchmarks/${positioningId}/comments`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetServicedApartmentsByIdAPI = async (positioningId, id) => {
  const URL = `/v1/benchmarks/servicedApartmentBenchmarks/${positioningId}/${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AddServicedApartmentsAPI = async (positioningId, payload) => {
  const URL = `/v1/benchmarks/servicedApartmentBenchmarks/${positioningId}`;
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return { success: false, status: 409 };
    }
    throw error;
  }
};

export const GetServicedApartmentsDraftByIdAPI = async (
  positioningId,
  parentId,
  draftId
) => {
  const URL = `/v1/benchmarks/servicedApartmentBenchmarks/${positioningId}/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteServicedApartmentsDraftAPI = async (
  positioningId,
  parentId,
  draftId
) => {
  const URL = `/v1/benchmarks/servicedApartmentBenchmarks/${positioningId}/deleteDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

//GET PRODUCTION VERSION OF SERVICE APARTMANT
export const GetProductionServiceApartmant = async (posId) => {
  const URL = `/v1/benchmarks/servicedApartmentBenchmarksProduction/${posId}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
//GET PRODUCTION VERSION OF CITY BENCHMARKS
export const GetProductionCityBenchmarks = async (posId) => {
  const URL = `/v1/benchmarks/cityBenchmarksProduction/${posId}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
//GET PRODUCTION VERSION OF RESORT BENCHAMRKS
export const GetProductionResortBenchmarks = async (posId) => {
  const URL = `/v1/benchmarks/resortBenchmarksProduction/${posId}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
