import React, { useState, useEffect } from "react";

import {
  GetDevelopmentCostsByIdAPI,
  AddDevelopmentCostsAPI,
  GetDevelopmentCostsDraftByIdAPI,
} from "../../../api/BenchmarksAPI";

import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";
import { useLocation, useParams } from "react-router-dom";

import { Formik, Form } from "formik";
import {
  Box,
  Text,
  useDisclosure,
  Spacer,
  Badge,
  HStack,
  useToast,
} from "@chakra-ui/react";

import { activeMenuItem } from "../../../utils/General";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";
import { ToastAlert } from "../../../components/toastAlert/ToastAlert";
import { DevAndFinStep1 } from "./DevAndFinStep1";
import { DevAndFinStep2 } from "./DevAndFinStep2";
import { DevAndFinStep3 } from "./DevAndFinStep3";
import { validationDevAndFinForm } from "./validationDevAndFinForm";
import history from "../../../history";
//import { benchmarksFormInitialValues } from "./benchmarksFormInitialValues";

import checked from "../../../assets/images/icons/checked.svg";
import infoCircle from "../../../assets/images/icons/infoCircle.svg";

export const DevAndFin = () => {
  const activeMenu = activeMenuItem();
  const { id, draftId } = useParams();
  let location = useLocation();
  const [isRestore, setIsRestore] = useState(false);
  const [initFormVal, setInitFormVal] = useState({});
  const [devData, setDevData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [page, setPage] = useState(0);
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [validateStep, setValidateStep] = useState(false);
  const [modelWarningOpen, isModelWarningOpen] = useState(false);
  const toast = useToast();

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();
  const {
    isOpen: isDraftOpen,
    onOpen: onDraftOpen,
    onClose: onDraftClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const steps = [
    { title: "Development cost breakdown" },
    { title: "Management Fees" },
    { title: "Dept terms and risk calculations" },
  ];

  let date;
  let lastMod;
  let submitModalTitle = "Development and financing is successfully created.";

  if (!!devData.lastModifiedAt) {
    date = dateFnsParseISO(devData.lastModifiedAt);
    lastMod = dateFnsFormat(date, "dd.MM.yyyy; HH:mm");
  }

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const closeToast = () => {
    toast.closeAll();
  };

  const handleOnSubmit = (formikValue, isRestore) => {
    if (!!id) {
      let baseFee = Array(5).fill(0);
      let incentiveFee = Array(6).fill({
        ifGOPLessThanPercent: "",
        feePercentOfGOP: "",
      });
      let commentsLogic =
        devData.comments === formikValue.addComment
          ? ""
          : formikValue.addComment;

      formikValue.baseFee.forEach((el, index) => {
        baseFee[index] = el;
      });

      formikValue.incentiveFee.forEach((formValue, index) => {
        if (formValue.ifGOPLessThanPercent && formValue.feePercentOfGOP) {
          incentiveFee[index].ifGOPLessThanPercent =
            formValue.ifGOPLessThanPercent;
          incentiveFee[index].feePercentOfGOP = formValue.feePercentOfGOP;
        }
      });

      const submitValues = {
        //...formikValue,
        isDraft: false,
        isRestore: !!isRestore,
        comments: commentsLogic,
        parentVersionNumber:
          devData.versionNumber || devData.parentVersionNumber,
        developmentCostValues: {
          general: {
            infrastructure: {
              cost: formikValue.infrastructureCost,
              depreciation: formikValue.generalDepreciation1,
            },
            parking: {
              cost: formikValue.parkingCost,
              depreciation: formikValue.generalDepreciation2,
            },
            landCostFreehold: formikValue.landFreehold,
          },
          breakdown: {
            construction: {
              cost: formikValue.constructionCost,
              depreciation: formikValue.breakDepreciation1,
            },
            FFE_OSE: {
              cost: formikValue.ffeAndOse,
              depreciation: formikValue.breakDepreciation2,
            },
            softCosts: {
              cost: formikValue.softCosts,
              depreciation: formikValue.breakDepreciation3,
            },
            preOpening: {
              cost: formikValue.preOpening,
              depreciation: formikValue.breakDepreciation4,
            },
            TSA: {
              cost: formikValue.tsaFee,
              depreciation: formikValue.breakDepreciation5,
            },
            IT: {
              cost: formikValue.ITbudget,
              depreciation: formikValue.breakDepreciation6,
            },
            NEOMDevelopmentFee: {
              cost: formikValue.neomHotelDevFee,
              depreciation: formikValue.breakDepreciation7,
            },
            contingency: {
              cost: formikValue.contingencyCost,
              depreciation: formikValue.breakDepreciation8,
            },
            initialWorkingCapital: formikValue.initialWorkingCapital,
            assetManagementFee: formikValue.assetManagementFee,
          },
        },
        managementFees: {
          baseFeeStartYear: "2020",
          baseFee: formikValue.baseFee,
          incentiveFee: formikValue.incentiveFee,
        },
        debtAndRiskValues: {
          debt: {
            annualInterestRate: formikValue.annualInterestRate,
            debtTerm: formikValue.debtTerm,
            gracePeriod: formikValue.gracePeriod,
            debtProcessingFees: formikValue.debtProcessingFees,
            securityFees: formikValue.securityFees,
            commitmentFees: formikValue.commitmentFees,
          },
          risk: {
            riskFreeRate: formikValue.riskFreeRate,
            equityRiskPremium: formikValue.equityRiskPremium,
            countryRiskPremium: formikValue.countryRiskPremium,
            unleveredBeta: formikValue.unleveredBeta,
            manualInput: formikValue.manualInput,
          },
        },
      };

      AddDevelopmentCostsAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      let baseFee = Array(5).fill(0);
      let incentiveFee = Array(6).fill({
        ifGOPLessThanPercent: "",
        feePercentOfGOP: "",
      });

      formikValue.baseFee.forEach((el, index) => {
        baseFee[index] = el;
      });

      formikValue.incentiveFee.forEach((formValue, index) => {
        if (formValue.ifGOPLessThanPercent && formValue.feePercentOfGOP) {
          incentiveFee[index].ifGOPLessThanPercent =
            formValue.ifGOPLessThanPercent;
          incentiveFee[index].feePercentOfGOP = formValue.feePercentOfGOP;
        }
      });

      const submitValues = {
        //...formikValue,
        isDraft: false,
        parentVersionNumber: -1,

        comments: formikValue.addComment,
        developmentCostValues: {
          general: {
            infrastructure: {
              cost: formikValue.infrastructureCost,
              depreciation: formikValue.generalDepreciation1,
            },
            parking: {
              cost: formikValue.parkingCost,
              depreciation: formikValue.generalDepreciation2,
            },
            landCostFreehold: formikValue.landFreehold,
          },
          breakdown: {
            construction: {
              cost: formikValue.constructionCost,
              depreciation: formikValue.breakDepreciation1,
            },
            FFE_OSE: {
              cost: formikValue.ffeAndOse,
              depreciation: formikValue.breakDepreciation2,
            },
            softCosts: {
              cost: formikValue.softCosts,
              depreciation: formikValue.breakDepreciation3,
            },
            preOpening: {
              cost: formikValue.preOpening,
              depreciation: formikValue.breakDepreciation4,
            },
            TSA: {
              cost: formikValue.tsaFee,
              depreciation: formikValue.breakDepreciation5,
            },
            IT: {
              cost: formikValue.ITbudget,
              depreciation: formikValue.breakDepreciation6,
            },
            NEOMDevelopmentFee: {
              cost: formikValue.neomHotelDevFee,
              depreciation: formikValue.breakDepreciation7,
            },
            contingency: {
              cost: formikValue.contingencyCost,
              depreciation: formikValue.breakDepreciation8,
            },
            initialWorkingCapital: formikValue.initialWorkingCapital,
            assetManagementFee: formikValue.assetManagementFee,
          },
        },
        managementFees: {
          baseFeeStartYear: "2020",
          baseFee: formikValue.baseFee,
          incentiveFee: formikValue.incentiveFee,
        },
        debtAndRiskValues: {
          debt: {
            annualInterestRate: formikValue.annualInterestRate,
            debtTerm: formikValue.debtTerm,
            gracePeriod: formikValue.gracePeriod,
            debtProcessingFees: formikValue.debtProcessingFees,
            securityFees: formikValue.securityFees,
            commitmentFees: formikValue.commitmentFees,
          },
          risk: {
            riskFreeRate: formikValue.riskFreeRate,
            equityRiskPremium: formikValue.equityRiskPremium,
            countryRiskPremium: formikValue.countryRiskPremium,
            unleveredBeta: formikValue.unleveredBeta,
            manualInput: formikValue.manualInput,
          },
        },
      };

      AddDevelopmentCostsAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };
  const handleSaveDraft = (formikValue) => {
    if (!!id) {
      let baseFee = Array(5).fill(0);
      let incentiveFee = Array(6).fill({
        ifGOPLessThanPercent: "",
        feePercentOfGOP: "",
      });
      let commentsLogic =
        devData.comments === formikValue.addComment
          ? ""
          : formikValue.addComment;

      formikValue.baseFee.forEach((el, index) => {
        baseFee[index] = el;
      });

      formikValue.incentiveFee.forEach((formValue, index) => {
        if (formValue.ifGOPLessThanPercent && formValue.feePercentOfGOP) {
          incentiveFee[index].ifGOPLessThanPercent =
            formValue.ifGOPLessThanPercent;
          incentiveFee[index].feePercentOfGOP = formValue.feePercentOfGOP;
        }
      });

      const submitValues = {
        //...formikValue,
        isDraft: true,
        versionNumber: !!draftId ? Number(draftId) : 0,
        parentVersionNumber: Number(id),
        comments: commentsLogic,
        developmentCostValues: {
          general: {
            infrastructure: {
              cost: formikValue.infrastructureCost,
              depreciation: formikValue.generalDepreciation1,
            },
            parking: {
              cost: formikValue.parkingCost,
              depreciation: formikValue.generalDepreciation2,
            },
            landCostFreehold: formikValue.landFreehold,
          },
          breakdown: {
            construction: {
              cost: formikValue.constructionCost,
              depreciation: formikValue.breakDepreciation1,
            },
            FFE_OSE: {
              cost: formikValue.ffeAndOse,
              depreciation: formikValue.breakDepreciation2,
            },
            softCosts: {
              cost: formikValue.softCosts,
              depreciation: formikValue.breakDepreciation3,
            },
            preOpening: {
              cost: formikValue.preOpening,
              depreciation: formikValue.breakDepreciation4,
            },
            TSA: {
              cost: formikValue.tsaFee,
              depreciation: formikValue.breakDepreciation5,
            },
            IT: {
              cost: formikValue.ITbudget,
              depreciation: formikValue.breakDepreciation6,
            },
            NEOMDevelopmentFee: {
              cost: formikValue.neomHotelDevFee,
              depreciation: formikValue.breakDepreciation7,
            },
            contingency: {
              cost: formikValue.contingencyCost,
              depreciation: formikValue.breakDepreciation8,
            },
            initialWorkingCapital: formikValue.initialWorkingCapital,
            assetManagementFee: formikValue.assetManagementFee,
          },
        },
        managementFees: {
          baseFeeStartYear: "2020",
          baseFee: formikValue.baseFee,
          incentiveFee: formikValue.incentiveFee,
        },
        debtAndRiskValues: {
          debt: {
            annualInterestRate: formikValue.annualInterestRate,
            debtTerm: formikValue.debtTerm,
            gracePeriod: formikValue.gracePeriod,
            debtProcessingFees: formikValue.debtProcessingFees,
            securityFees: formikValue.securityFees,
            commitmentFees: formikValue.commitmentFees,
          },
          risk: {
            riskFreeRate: formikValue.riskFreeRate,
            equityRiskPremium: formikValue.equityRiskPremium,
            countryRiskPremium: formikValue.countryRiskPremium,
            unleveredBeta: formikValue.unleveredBeta,
            manualInput: formikValue.manualInput,
          },
        },
      };

      /* let newHaylettFactor = Array(100).fill(0);
      formikValue.haylettFactor.forEach((formValue, index) => {
        if (formValue.haylettFactorField) {
          newHaylettFactor[index] = formValue.haylettFactorField;
        }
      });
      const submitValues = {
        ...formikValue,
        isDraft: true,
        versionNumber: !!draftId ? Number(draftId) : 0,
        parentVersionNumber: Number(id),
        haylettFactor: newHaylettFactor,
      }; */

      AddDevelopmentCostsAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            setErrorMessage("");
            setDisableSave(true);
            onDraftClose();
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      let baseFee = Array(5).fill(0);
      let incentiveFee = Array(6).fill({
        ifGOPLessThanPercent: "",
        feePercentOfGOP: "",
      });
      let commentsLogic =
        devData.comments === formikValue.addComment
          ? ""
          : formikValue.addComment;

      formikValue.baseFee.forEach((el, index) => {
        baseFee[index] = el;
      });

      formikValue.incentiveFee.forEach((formValue, index) => {
        if (formValue.ifGOPLessThanPercent && formValue.feePercentOfGOP) {
          incentiveFee[index].ifGOPLessThanPercent =
            formValue.ifGOPLessThanPercent;
          incentiveFee[index].feePercentOfGOP = formValue.feePercentOfGOP;
        }
      });

      const submitValues = {
        //...formikValue,
        isDraft: true,
        comments: commentsLogic,
        developmentCostValues: {
          general: {
            infrastructure: {
              cost: formikValue.infrastructureCost,
              depreciation: formikValue.generalDepreciation1,
            },
            parking: {
              cost: formikValue.parkingCost,
              depreciation: formikValue.generalDepreciation2,
            },
            landCostFreehold: formikValue.landFreehold,
          },
          breakdown: {
            construction: {
              cost: formikValue.constructionCost,
              depreciation: formikValue.breakDepreciation1,
            },
            FFE_OSE: {
              cost: formikValue.ffeAndOse,
              depreciation: formikValue.breakDepreciation2,
            },
            softCosts: {
              cost: formikValue.softCosts,
              depreciation: formikValue.breakDepreciation3,
            },
            preOpening: {
              cost: formikValue.preOpening,
              depreciation: formikValue.breakDepreciation4,
            },
            TSA: {
              cost: formikValue.tsaFee,
              depreciation: formikValue.breakDepreciation5,
            },
            IT: {
              cost: formikValue.ITbudget,
              depreciation: formikValue.breakDepreciation6,
            },
            NEOMDevelopmentFee: {
              cost: formikValue.neomHotelDevFee,
              depreciation: formikValue.breakDepreciation7,
            },
            contingency: {
              cost: formikValue.contingencyCost,
              depreciation: formikValue.breakDepreciation8,
            },
            initialWorkingCapital: formikValue.initialWorkingCapital,
            assetManagementFee: formikValue.assetManagementFee,
          },
        },
        managementFees: {
          baseFeeStartYear: "2020",
          baseFee: formikValue.baseFee,
          incentiveFee: formikValue.incentiveFee,
        },
        debtAndRiskValues: {
          debt: {
            annualInterestRate: formikValue.annualInterestRate,
            debtTerm: formikValue.debtTerm,
            gracePeriod: formikValue.gracePeriod,
            debtProcessingFees: formikValue.debtProcessingFees,
            securityFees: formikValue.securityFees,
            commitmentFees: formikValue.commitmentFees,
          },
          risk: {
            riskFreeRate: formikValue.riskFreeRate,
            equityRiskPremium: formikValue.equityRiskPremium,
            countryRiskPremium: formikValue.countryRiskPremium,
            unleveredBeta: formikValue.unleveredBeta,
            manualInput: formikValue.manualInput,
          },
        },
      };

      /* let newHaylettFactor = Array(100).fill(0);
      formikValue.haylettFactor.forEach((formValue, index) => {
        if (formValue.haylettFactorField) {
          newHaylettFactor[index] = formValue.haylettFactorField;
        }
      });
      const submitValues = {
        ...formikValue,
        isDraft: true,
        haylettFactor: newHaylettFactor,
      }; */

      AddDevelopmentCostsAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            setErrorMessage("");
            setDisableSave(true);
            onDraftClose();
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };

  useEffect(() => {
    let formField;

    if (!!id && !!draftId) {
      setErrorMessage("");
      GetDevelopmentCostsDraftByIdAPI(id, draftId)
        .then((res) => {
          formField = JSON.parse(res.data.formFields);
          let devCosVals = formField.developmentCostValues;
          let devCosValsGen = devCosVals.general;
          let devCosValsBd = devCosVals.breakdown;
          let deptRiskVals = formField.debtAndRiskValues;

          let incentiveFee = Array(6).fill({
            ifGOPLessThanPercent: "",
            feePercentOfGOP: "",
          });

          incentiveFee = incentiveFee.map((formValue, ind) => {
            let feePeeVal =
              formField.managementFees?.incentiveFee[ind]?.feePercentOfGOP;
            let ifGopVal =
              formField.managementFees?.incentiveFee[ind]?.ifGOPLessThanPercent;
            return {
              ifGOPLessThanPercent: ifGopVal == undefined ? 0 : ifGopVal,
              feePercentOfGOP: feePeeVal == undefined ? 0 : feePeeVal,
            };
          });

          setInitFormVal({
            infrastructureCost: devCosValsGen.infrastructure.cost,
            generalDepreciation1: devCosValsGen.infrastructure.depreciation,
            parkingCost: devCosValsGen.parking.cost,
            generalDepreciation2: devCosValsGen.parking.depreciation,
            landFreehold: devCosValsGen.landCostFreehold,
            constructionCost: devCosValsBd.construction.cost,
            breakDepreciation1: devCosValsBd.construction.depreciation,
            ffeAndOse: devCosValsBd.FFE_OSE.cost,
            breakDepreciation2: devCosValsBd.FFE_OSE.depreciation,
            softCosts: devCosValsBd.softCosts.cost,
            breakDepreciation3: devCosValsBd.softCosts.depreciation,
            preOpening: devCosValsBd.preOpening.cost,
            breakDepreciation4: devCosValsBd.preOpening.depreciation,
            tsaFee: devCosValsBd.TSA.cost,
            breakDepreciation5: devCosValsBd.TSA.depreciation,
            ITbudget: devCosValsBd.IT.cost,
            breakDepreciation6: devCosValsBd.IT.depreciation,
            neomHotelDevFee: devCosValsBd.NEOMDevelopmentFee.cost,
            breakDepreciation7: devCosValsBd.NEOMDevelopmentFee.depreciation,
            contingencyCost: devCosValsBd.contingency.cost,
            breakDepreciation8: devCosValsBd.contingency.depreciation,
            initialWorkingCapital: devCosValsBd.initialWorkingCapital,
            assetManagementFee: devCosValsBd.assetManagementFee,
            baseFee: formField.managementFees.baseFee,
            incentiveFee: incentiveFee,
            annualInterestRate: deptRiskVals.debt.annualInterestRate,
            debtTerm: deptRiskVals.debt.debtTerm,
            gracePeriod: deptRiskVals.debt.gracePeriod,
            debtProcessingFees: deptRiskVals.debt.debtProcessingFees,
            securityFees: deptRiskVals.debt.securityFees,
            commitmentFees: deptRiskVals.debt.commitmentFees,
            riskFreeRate: deptRiskVals.risk.riskFreeRate,
            equityRiskPremium: deptRiskVals.risk.equityRiskPremium,
            countryRiskPremium: deptRiskVals.risk.countryRiskPremium,
            unleveredBeta: deptRiskVals.risk.unleveredBeta,
            manualInput: deptRiskVals.risk.manualInput,
            addComment: res.data.comments,
          });

          setDevData(res.data);
        })
        .catch((err) => {
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    } else if (!!id) {
      setErrorMessage("");
      GetDevelopmentCostsByIdAPI(id)
        .then((res) => {
          formField = JSON.parse(res.data.formFields);
          let devCosVals = formField.developmentCostValues;
          let devCosValsGen = devCosVals.general;
          let devCosValsBd = devCosVals.breakdown;
          let deptRiskVals = formField.debtAndRiskValues;

          let incentiveFee = Array(6).fill({
            ifGOPLessThanPercent: "",
            feePercentOfGOP: "",
          });

          incentiveFee = incentiveFee.map((formValue, ind) => {
            let feePeeVal =
              formField.managementFees?.incentiveFee[ind]?.feePercentOfGOP;
            let ifGopVal =
              formField.managementFees?.incentiveFee[ind]?.ifGOPLessThanPercent;
            return {
              ifGOPLessThanPercent: ifGopVal == undefined ? 0 : ifGopVal,
              feePercentOfGOP: feePeeVal == undefined ? 0 : feePeeVal,
            };
          });

          setInitFormVal({
            infrastructureCost: devCosValsGen.infrastructure.cost,
            generalDepreciation1: devCosValsGen.infrastructure.depreciation,
            parkingCost: devCosValsGen.parking.cost,
            generalDepreciation2: devCosValsGen.parking.depreciation,
            landFreehold: devCosValsGen.landCostFreehold,
            constructionCost: devCosValsBd.construction.cost,
            breakDepreciation1: devCosValsBd.construction.depreciation,
            ffeAndOse: devCosValsBd.FFE_OSE.cost,
            breakDepreciation2: devCosValsBd.FFE_OSE.depreciation,
            softCosts: devCosValsBd.softCosts.cost,
            breakDepreciation3: devCosValsBd.softCosts.depreciation,
            preOpening: devCosValsBd.preOpening.cost,
            breakDepreciation4: devCosValsBd.preOpening.depreciation,
            tsaFee: devCosValsBd.TSA.cost,
            breakDepreciation5: devCosValsBd.TSA.depreciation,
            ITbudget: devCosValsBd.IT.cost,
            breakDepreciation6: devCosValsBd.IT.depreciation,
            neomHotelDevFee: devCosValsBd.NEOMDevelopmentFee.cost,
            breakDepreciation7: devCosValsBd.NEOMDevelopmentFee.depreciation,
            contingencyCost: devCosValsBd.contingency.cost,
            breakDepreciation8: devCosValsBd.contingency.depreciation,
            initialWorkingCapital: devCosValsBd.initialWorkingCapital,
            assetManagementFee: devCosValsBd.assetManagementFee,
            baseFee: formField.managementFees.baseFee,
            incentiveFee: incentiveFee,
            annualInterestRate: deptRiskVals.debt.annualInterestRate,
            debtTerm: deptRiskVals.debt.debtTerm,
            gracePeriod: deptRiskVals.debt.gracePeriod,
            debtProcessingFees: deptRiskVals.debt.debtProcessingFees,
            securityFees: deptRiskVals.debt.securityFees,
            commitmentFees: deptRiskVals.debt.commitmentFees,
            riskFreeRate: deptRiskVals.risk.riskFreeRate,
            equityRiskPremium: deptRiskVals.risk.equityRiskPremium,
            countryRiskPremium: deptRiskVals.risk.countryRiskPremium,
            unleveredBeta: deptRiskVals.risk.unleveredBeta,
            manualInput: deptRiskVals.risk.manualInput,
            addComment: res.data.comments,
          });

          setDevData(res.data);
        })
        .catch((err) => {
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    } else {
      let baseFee = Array(5).fill("");
      let incentiveFee = Array(6).fill({
        ifGOPLessThanPercent: "",
        feePercentOfGOP: "",
      });

      setInitFormVal({
        infrastructureCost: "",
        generalDepreciation1: "",
        parkingCost: "",
        generalDepreciation2: "",
        landFreehold: "",
        constructionCost: "",
        breakDepreciation1: "",
        ffeAndOse: "",
        breakDepreciation2: "",
        softCosts: "",
        breakDepreciation3: "",
        preOpening: "",
        breakDepreciation4: "",
        tsaFee: "",
        breakDepreciation5: "",
        ITbudget: "",
        breakDepreciation6: "",
        neomHotelDevFee: "",
        breakDepreciation7: "",
        contingencyCost: "",
        breakDepreciation8: "",
        initialWorkingCapital: "",
        assetManagementFee: "",
        baseFee: baseFee,
        incentiveFee: incentiveFee,
        annualInterestRate: "",
        debtTerm: "",
        gracePeriod: "",
        debtProcessingFees: "",
        securityFees: "",
        commitmentFees: "",
        riskFreeRate: "",
        equityRiskPremium: "",
        countryRiskPremium: "",
        unleveredBeta: "",
        manualInput: "",
        addComment: "",
      });
    }
    if (location?.state?.from == "Restore") {
      setIsRestore(true);
    }
  }, []);

  useEffect(() => {
    console.log("modelWarningOpen", modelWarningOpen);
    if (modelWarningOpen) {
      toast({
        position: "top-right",
        isClosable: true,
        render: () => (
          <ToastAlert
            title="You must fill all the fields."
            icon={infoCircle}
            closeToast={closeToast}
            type="Warning"
          />
        ),
      });
    }
    isModelWarningOpen(false);
  }, [modelWarningOpen]);

  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`Are you sure you want to discard the changes?`)) {
          unblock();

          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  return (
    <div>
      <>
        {id === undefined ? (
          <MainTitle title="Create new Development and financing" />
        ) : (
          <MainTitle title="Edit new Development and financing" />
        )}

        <MainBox>
          <Box className="table_content" pt={6} pb={6}>
            <Box display="flex">
              <Text className="title_md" display="flex" component="div">
                STEP
              </Text>
              <Text ml={1}>
                {page + 1}/{steps.length}
              </Text>
              {id !== undefined && (
                <HStack w="100%" px="8">
                  <Badge
                    bg="rgba(0, 123, 181, 0.2)"
                    className="masterDataBadge"
                  >
                    Version Number:{" "}
                    {devData.status === "Draft" ||
                    devData.status === "InitialDraft"
                      ? devData.parentVersionNumber
                      : devData.versionNumber}
                  </Badge>
                  <Badge
                    bg="rgba(241, 136, 37, 0.3)"
                    className="masterDataBadge"
                  >
                    Last Modified By: {devData.lastModifiedBy}, {lastMod}
                  </Badge>

                  {/* IF NOT SARA SHOW VIEW ONLY */}
                  <Spacer />
                  {/* <HStack
                  px="2"
                  py="1"
                  borderRadius="5"
                  fontSize="12"
                  bg="rgba(0, 107, 68, 0.2);"
                >
                  <Image src={eye}></Image>
                  <Text>View Only</Text>
                </HStack> */}
                </HStack>
              )}
            </Box>

            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validationSchema={validationDevAndFinForm[page]}
              validate={(values) => {
                if (disableSave) {
                  setDisableSave(false);
                }
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  onSubmitOpen();
                }, 400);
              }}
            >
              {({
                isSubmitting,
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                isValid,
                dirty,
              }) => (
                <>
                  <Form className="form_wrapper justify_content_start">
                    {page === 0 && (
                      <DevAndFinStep1
                        nextPage={nextPage}
                        errors={errors}
                        touched={touched}
                        isRequired
                        onChange={setFieldValue}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        onCancelOpen={onCancelOpen}
                        onDraftOpen={onDraftOpen}
                        formikValues={values}
                        dirty={dirty}
                        setDisableSave={setDisableSave}
                        disableSave={disableSave}
                        setDismissSave={setDismissSave}
                        isRestore={isRestore}
                        validateStep={validateStep}
                        setValidateStep={setValidateStep}
                        isModelWarningOpen={isModelWarningOpen}
                        modelWarningOpen={modelWarningOpen}
                        closeToast={closeToast}
                      />
                    )}
                    {page === 1 && (
                      <DevAndFinStep2
                        nextPage={nextPage}
                        prevPage={prevPage}
                        errors={errors}
                        touched={touched}
                        isRequired
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                        onCancelOpen={onCancelOpen}
                        onDraftOpen={onDraftOpen}
                        formikValues={values}
                        disableSave={disableSave}
                        setDismissSave={setDismissSave}
                        isRestore={isRestore}
                        validateStep={validateStep}
                        setValidateStep={setValidateStep}
                        isModelWarningOpen={isModelWarningOpen}
                        modelWarningOpen={modelWarningOpen}
                        closeToast={closeToast}
                      />
                    )}
                    {page === 2 && (
                      <DevAndFinStep3
                        nextPage={nextPage}
                        prevPage={prevPage}
                        onOpenModal={onSubmitOpen}
                        errors={errors}
                        touched={touched}
                        isRequired
                        formikValues={values}
                        onChange={setFieldValue}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        onBlur={handleBlur}
                        onCancelOpen={onCancelOpen}
                        onDraftOpen={onDraftOpen}
                        onSubmitOpen={onSubmitOpen}
                        handleOnSubmit={handleOnSubmit}
                        disableSave={disableSave}
                        setDismissSave={setDismissSave}
                        devData={devData}
                        dirty={dirty}
                        isRestore={isRestore}
                        validateStep={validateStep}
                        setValidateStep={setValidateStep}
                        isModelWarningOpen={isModelWarningOpen}
                        modelWarningOpen={modelWarningOpen}
                        closeToast={closeToast}
                      />
                    )}

                    <ModalAlert
                      isOpen={isDraftOpen}
                      onClose={onDraftClose}
                      title="Are you sure you want to save this as draft?"
                      icon={infoCircle}
                      confirmationModal={true}
                      hasDesc={false}
                      haveSaveDraft={false}
                      path={`/DevAndFinHistory`}
                      pathDraft={`/DevAndFinHistory`}
                      onPressSaveDraft={() => handleSaveDraft(values)}
                    />
                    <ModalAlert
                      isOpen={isCancelOpen}
                      onClose={onCancelClose}
                      title="Are you sure you want to discard the changes?"
                      icon={infoCircle}
                      hasDesc={false}
                      confirmationModal={true}
                      path={`/DevAndFinHistory`}
                      onPressSaveDraft={() => handleSaveDraft(values)}
                      setDismissSave={() => setDismissSave(true)}
                    />
                  </Form>
                </>
              )}
            </Formik>
          </Box>
        </MainBox>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/DevAndFinHistory`}
        />
      </>
    </div>
  );
};
