import React from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Image,
} from "@chakra-ui/react";

import { GenerateModelSelect } from "./GenerateModelSelect";
import close from "../../assets/images/icons/close.svg";

export const GenerateModel = ({
  onClose,
  isOpen,
  assetsData,
  type,
  id,
  projectNameValue,
  onErrorOpen,
  setErrorMessage,
  loadModelData,
  packageTypeArr,
}) => {
  return (
    <Modal onClose={onClose} size={"full"} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent className="modal_full" paddingX={8} background="#F9F7F2">
        <ModalHeader
          fontWeight="400"
          fontSize="24px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontWeight="400" fontSize="24px">
            Generate a Model
          </Text>

          <ModalCloseButton position="relative" paddingX={1}>
            <Image src={close} />
          </ModalCloseButton>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          background="#fff"
          borderRadius="5px"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
        >
          <GenerateModelSelect
            assetsData={assetsData}
            type={type}
            id={id}
            projectNameValue={projectNameValue}
            onErrorOpen={onErrorOpen}
            setErrorMessage={setErrorMessage}
            onClose={onClose}
            loadModelData={loadModelData}
            packageTypeArr={packageTypeArr}
          />
        </ModalBody>
        <ModalFooter>
          {/* <MainButton onPress={onClose} title="Close" /> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
