import { models } from "powerbi-client";
import { useIsMobile } from "../../utils/hooks";
import React, { useEffect, useState } from "react";
import { GetEmbedToken } from "../../api/DashboardAPI";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export const DashboardInvestment = () => {
  let user;
  if (Cookies.get("user_info")) {
    user = jwt_decode(Cookies.get("user_info"));
  }

  const [accessToken, setAccessToken] = useState("");
  const [reportFilters, setReportFilters] = useState("");
  const dashboardParams = {
    desktop: {
      main: {
        id: process.env.REACT_APP_DASHBOARD_INVESTMENT_ID,
        embedUrl: process.env.REACT_APP_DASHBOARD_INVESTMENT,
        pageName: "ReportSection",
      },
    },
    mobile: {
      main: {
        id: "6e30db86-a490-4fab-a8a8-54187ad75cbe",
        embedUrl:
          "https://app.powerbi.com/reportEmbed?reportId=72fc9764-61ab-40b2-9020-9c40ae24ec9c&autoAuth=true&ctid=8288fdf2-0e8b-47a6-abbb-004395ecab56",
        pageName: "ReportSectionf8ca3888577ca29ea972",
      },
    },
  };

  const isMobile = useIsMobile();

  const screenSizeToUse = isMobile ? "mobile" : "desktop";

  const [reportLevelToUse, setReportLevel] = useState("main");
  const [isEmbedShown, setIsEmbedShown] = useState(
    !user.roles.includes("proponent")
  );

  useEffect(() => {
    GetEmbedToken()
      .then((token) => {
        if (token) {
          setAccessToken(token);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const embedConfig = {
    type: "report",
    id: dashboardParams[screenSizeToUse][reportLevelToUse].id,
    embedUrl: dashboardParams[screenSizeToUse][reportLevelToUse].embedUrl,
    pageName: dashboardParams[screenSizeToUse][reportLevelToUse].pageName,
    accessToken,
    tokenType: models.TokenType.Aad,
    settings: {
      panes: {
        pageNavigation: {
          visible: false,
        },
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
  };

  const eventHandlers = new Map([
    [
      "loaded",
      function () {
        console.log("Report loaded");
      },
    ],
    [
      "rendered",
      function () {
        console.log("Report rendered");
      },
    ],
    [
      "error",
      function (event) {
        console.log(event.detail);
      },
    ],
  ]);

  useEffect(() => {
    setIsEmbedShown(false);
    setTimeout(() => {
      setIsEmbedShown(true);
    }, 250);
  }, [reportLevelToUse, screenSizeToUse]);

  const frameParams =
    reportFilters +
    "&filterPaneEnabled=false&pageNavigation=false&&navContentPaneEnabled=false";

  return (
    <div className={"Embed-frame"}>
      {isEmbedShown ? (
        <iframe
          className={"Embed-container"}
          title="Embed"
          src={
            dashboardParams[screenSizeToUse][reportLevelToUse]?.embedUrl +
            frameParams
          }
          frameBorder="0"
          allowFullScreen={false}
        />
      ) : null}
    </div>
  );
};
