import * as Yup from "yup";

export const validationGeneralAssumpForm = [
  Yup.object().shape({
    localCurrency: Yup.string().required("This field is required"),

    benchmarkCurrency: Yup.string().required("This field is required"),

    currencyRate: Yup.number()
      .min(0, "Currency Rate should be greater than 0")
      .required("This field is required"),

    conversionToStatements: Yup.number()
      .min(0, "Conversion to statments should be greater than 0")
      .required("This field is required"),

    annualInflation: Yup.number()
      .min(0, "Annual inflation should be greater than 0")
      .max(100, "Annual inflation should be less than 100")
      .required("This field is required"),

    developmentCostInflation: Yup.number()
      .min(0, "Development cost inflation should be greater than 0")
      .max(100, "Development cost inflation should be less than 100")
      .required("This field is required"),
  }),
  Yup.object().shape({
    haylettFactor: Yup.array().of(
      Yup.object().shape({
        haylettFactorField: Yup.number("Required")
          .min(0, "Required")
          .required("Required"),
      })
    ),
  }),
  Yup.object().shape({
    firstPayment: Yup.number()
      .min(0, "First payment should be greater than 0")
      .max(100, "First payment should be less than 100")
      .required("This field is required"),
  }),
];
