import React from "react";

import { Box, Divider, Flex } from "@chakra-ui/react";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";

import percent from "../../assets/images/icons/filled.svg";
import rightArrow from "../../assets/images/icons/rightArrow2.svg";

export const GeneralAssumpStep1 = ({
  nextPage,
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  onDraftOpen,
  disableSave,
  setDismissSave,
  isRestore,
  isModelWarningOpen,
  setValidateStep,
  validateStep,
  closeToast,
  setFieldTouched,
}) => {
  let form1Data = [];
  form1Data.push(formikValues.annualInflation);
  form1Data.push(formikValues.developmentCostInflation);

  form1Data.push(formikValues.conversionToStatements);
  form1Data.push(formikValues.currencyRate);
  form1Data.push(formikValues.benchmarkCurrency);
  form1Data.push(formikValues.localCurrency);

  let emptyform1Data = [];
  form1Data.forEach((item, ind) => {
    if (item !== "" && item >= "0") {
      if (ind === 0 || ind === 1) {
        if (item <= 100) {
          emptyform1Data.push(item);
        }
      } else {
        emptyform1Data.push(item);
      }
    }
  });

  let isDisabledForm = false;

  if (emptyform1Data.length !== form1Data.length) {
    isDisabledForm = true;
  }
  // console.log("isDisabledForm", isDisabledForm);
  return (
    <>
      <Box
        mt="5"
        mb="4"
        justifyContent="flex-start"
        display="flex"
        width="100%"
      >
        <MainTitle title="general details" />
      </Box>
      <Box
        maxW={["100%", "100%", "40%"]}
        width={["100%", "100%", "40%"]}
        alignSelf="flex-start"
      >
        <Box display="flex" width="100%" gap="15px">
          <Box width={["100%", "100%", "50%"]}>
            <SelectListMulti
              label="Local Currency"
              placeholder="Select"
              inputId="localCurrency"
              inputName="localCurrency"
              errors={errors}
              touched={touched}
              isRequired
              values={formikValues?.localCurrency}
              onChange={setFieldValue}
              onBlur={handleBlur}
              isDisabled={true}
              uppercase={true}
              defaultVal={{ value: "SAR", label: "SAR" }}
              dropdownItems={[
                /*  { value: "", label: "select currency" }, */
                { value: "AFN", label: "Afghan Afghani" },
                { value: "ALL", label: "Albanian Lek" },
                { value: "DZD", label: "Algerian Dinar" },
                { value: "AOA", label: "Angolan Kwanza" },
                { value: "ARS", label: "Argentine Peso" },
                { value: "AMD", label: "Armenian Dram" },
                { value: "AWG", label: "Aruban Florin" },
                { value: "AUD", label: "Australian Dollar" },
                { value: "AZN", label: "Azerbaijani Manat" },
                { value: "BSD", label: "Bahamian Dollar" },
                { value: "BHD", label: "Bahraini Dinar" },
                { value: "BDT", label: "Bangladeshi Taka" },
                { value: "BBD", label: "Barbadian Dollar" },
                { value: "BYR", label: "Belarusian Ruble" },
                { value: "BEF", label: "Belgian Franc" },
                { value: "BZD", label: "Belize Dollar" },
                { value: "BMD", label: "Bermudan Dollar" },
                { value: "BTN", label: "Bhutanese Ngultrum" },
                { value: "BTC", label: "Bitcoin" },
                { value: "BOB", label: "Bolivian Boliviano" },
                { value: "BAM", label: "Bosnia-Herzegovina Convertible Mark" },
                { value: "BWP", label: "Botswanan Pula" },
                { value: "BRL", label: "Brazilian Real" },
                { value: "GBP", label: "British Pound Sterling" },
                { value: "BND", label: "Brunei Dollar" },
                { value: "BGN", label: "Bulgarian Lev" },
                { value: "BIF", label: "Burundian Franc" },
                { value: "KHR", label: "Cambodian Riel" },
                { value: "CAD", label: "Canadian Dollar" },
                { value: "CVE", label: "Cape Verdean Escudo" },
                { value: "KYD", label: "Cayman Islands Dollar" },
                { value: "XOF", label: "CFA Franc BCEAO" },
                { value: "XAF", label: "CFA Franc BEAC" },
                { value: "XPF", label: "CFP Franc" },
                { value: "CLP", label: "Chilean Peso" },
                { value: "CNY", label: "Chinese Yuan" },
                { value: "COP", label: "Colombian Peso" },
                { value: "KMF", label: "Comorian Franc" },
                { value: "CDF", label: "Congolese Franc" },
                { value: "CRC", label: "Costa Rican ColÃ³n" },
                { value: "HRK", label: "Croatian Kuna" },
                { value: "CUC", label: "Cuban Convertible Peso" },
                { value: "CZK", label: "Czech Republic Koruna" },
                { value: "DKK", label: "Danish Krone" },
                { value: "DJF", label: "Djiboutian Franc" },
                { value: "DOP", label: "Dominican Peso" },
                { value: "XCD", label: "East Caribbean Dollar" },
                { value: "EGP", label: "Egyptian Pound" },
                { value: "ERN", label: "Eritrean Nakfa" },
                { value: "EEK", label: "Estonian Kroon" },
                { value: "ETB", label: "Ethiopian Birr" },
                { value: "EUR", label: "Euro" },
                { value: "FKP", label: "Falkland Islands Pound" },
                { value: "FJD", label: "Fijian Dollar" },
                { value: "GMD", label: "Gambian Dalasi" },
                { value: "GEL", label: "Georgian Lari" },
                { value: "DEM", label: "German Mark" },
                { value: "GHS", label: "Ghanaian Cedi" },
                { value: "GIP", label: "Gibraltar Pound" },
                { value: "GRD", label: "Greek Drachma" },
                { value: "GTQ", label: "Guatemalan Quetzal" },
                { value: "GNF", label: "Guinean Franc" },
                { value: "GYD", label: "Guyanaese Dollar" },
                { value: "HTG", label: "Haitian Gourde" },
                { value: "HNL", label: "Honduran Lempira" },
                { value: "HKD", label: "Hong Kong Dollar" },
                { value: "HUF", label: "Hungarian Forint" },
                { value: "ISK", label: "Icelandic KrÃ³na" },
                { value: "INR", label: "Indian Rupee" },
                { value: "IDR", label: "Indonesian Rupiah" },
                { value: "IRR", label: "Iranian Rial" },
                { value: "IQD", label: "Iraqi Dinar" },
                { value: "ILS", label: "Israeli New Sheqel" },
                { value: "ITL", label: "Italian Lira" },
                { value: "JMD", label: "Jamaican Dollar" },
                { value: "JPY", label: "Japanese Yen" },
                { value: "JOD", label: "Jordanian Dinar" },
                { value: "KZT", label: "Kazakhstani Tenge" },
                { value: "KES", label: "Kenyan Shilling" },
                { value: "KWD", label: "Kuwaiti Dinar" },
                { value: "KGS", label: "Kyrgystani Som" },
                { value: "LAK", label: "Laotian Kip" },
                { value: "LVL", label: "Latvian Lats" },
                { value: "LBP", label: "Lebanese Pound" },
                { value: "LSL", label: "Lesotho Loti" },
                { value: "LRD", label: "Liberian Dollar" },
                { value: "LYD", label: "Libyan Dinar" },
                { value: "LTL", label: "Lithuanian Litas" },
                { value: "MOP", label: "Macanese Pataca" },
                { value: "MKD", label: "Macedonian Denar" },
                { value: "MGA", label: "Malagasy Ariary" },
                { value: "MWK", label: "Malawian Kwacha" },
                { value: "MYR", label: "Malaysian Ringgit" },
                { value: "MVR", label: "Maldivian Rufiyaa" },
                { value: "MRO", label: "Mauritanian Ouguiya" },
                { value: "MUR", label: "Mauritian Rupee" },
                { value: "MXN", label: "Mexican Peso" },
                { value: "MDL", label: "Moldovan Leu" },
                { value: "MNT", label: "Mongolian Tugrik" },
                { value: "MAD", label: "Moroccan Dirham" },
                { value: "MZM", label: "Mozambican Metical" },
                { value: "MMK", label: "Myanmar Kyat" },
                { value: "NAD", label: "Namibian Dollar" },
                { value: "NPR", label: "Nepalese Rupee" },
                { value: "ANG", label: "Netherlands Antillean Guilder" },
                { value: "TWD", label: "New Taiwan Dollar" },
                { value: "NZD", label: "New Zealand Dollar" },
                { value: "NIO", label: "Nicaraguan CÃ³rdoba" },
                { value: "NGN", label: "Nigerian Naira" },
                { value: "KPW", label: "North Korean Won" },
                { value: "NOK", label: "Norwegian Krone" },
                { value: "OMR", label: "Omani Rial" },
                { value: "PKR", label: "Pakistani Rupee" },
                { value: "PAB", label: "Panamanian Balboa" },
                { value: "PGK", label: "Papua New Guinean Kina" },
                { value: "PYG", label: "Paraguayan Guarani" },
                { value: "PEN", label: "Peruvian Nuevo Sol" },
                { value: "PHP", label: "Philippine Peso" },
                { value: "PLN", label: "Polish Zloty" },
                { value: "QAR", label: "Qatari Rial" },
                { value: "RON", label: "Romanian Leu" },
                { value: "RUB", label: "Russian Ruble" },
                { value: "RWF", label: "Rwandan Franc" },
                { value: "SVC", label: "Salvadoran ColÃ³n" },
                { value: "WST", label: "Samoan Tala" },
                { value: "SAR", label: "SAR" },
                { value: "RSD", label: "Serbian Dinar" },
                { value: "SCR", label: "Seychellois Rupee" },
                { value: "SLL", label: "Sierra Leonean Leone" },
                { value: "SGD", label: "Singapore Dollar" },
                { value: "SKK", label: "Slovak Koruna" },
                { value: "SBD", label: "Solomon Islands Dollar" },
                { value: "SOS", label: "Somali Shilling" },
                { value: "ZAR", label: "South African Rand" },
                { value: "KRW", label: "South Korean Won" },
                { value: "XDR", label: "Special Drawing Rights" },
                { value: "LKR", label: "Sri Lankan Rupee" },
                { value: "SHP", label: "St. Helena Pound" },
                { value: "SDG", label: "Sudanese Pound" },
                { value: "SRD", label: "Surinamese Dollar" },
                { value: "SZL", label: "Swazi Lilangeni" },
                { value: "SEK", label: "Swedish Krona" },
                { value: "CHF", label: "Swiss Franc" },
                { value: "SYP", label: "Syrian Pound" },
                { value: "STD", label: "São Tomé and Príncipe Dobra" },
                { value: "TJS", label: "Tajikistani Somoni" },
                { value: "TZS", label: "Tanzanian Shilling" },
                { value: "THB", label: "Thai Baht" },
                { value: "TOP", label: "Tongan pa'anga" },
                { value: "TTD", label: "Trinidad &amp; Tobago Dollar" },
                { value: "TND", label: "Tunisian Dinar" },
                { value: "TRY", label: "Turkish Lira" },
                { value: "TMT", label: "Turkmenistani Manat" },
                { value: "UGX", label: "Ugandan Shilling" },
                { value: "UAH", label: "Ukrainian Hryvnia" },
                { value: "AED", label: "United Arab Emirates Dirham" },
                { value: "UYU", label: "Uruguayan Peso" },
                { value: "USD", label: "US Dollar" },
                { value: "UZS", label: "Uzbekistan Som" },
                { value: "VUV", label: "Vanuatu Vatu" },
                { value: "VEF", label: "Venezuelan BolÃ­var" },
                { value: "VND", label: "Vietnamese Dong" },
                { value: "YER", label: "Yemeni Rial" },
                { value: "ZMK", label: "Zambian Kwacha" },
              ]}
            />
          </Box>

          <Box width={["100%", "100%", "50%"]}>
            <SelectListMulti
              label="Benchmark Currency"
              placeholder="Select"
              inputId="benchmarkCurrency"
              inputName="benchmarkCurrency"
              errors={errors}
              touched={touched}
              isRequired
              values={formikValues?.benchmarkCurrency}
              onChange={setFieldValue}
              onBlur={handleBlur}
              isDisabled={true}
              uppercase={true}
              defaultVal={{ value: "USD", label: "USD" }}
              dropdownItems={[
                /* { value: "", label: "select currency" }, */
                { value: "AFN", label: "Afghan Afghani" },
                { value: "ALL", label: "Albanian Lek" },
                { value: "DZD", label: "Algerian Dinar" },
                { value: "AOA", label: "Angolan Kwanza" },
                { value: "ARS", label: "Argentine Peso" },
                { value: "AMD", label: "Armenian Dram" },
                { value: "AWG", label: "Aruban Florin" },
                { value: "AUD", label: "Australian Dollar" },
                { value: "AZN", label: "Azerbaijani Manat" },
                { value: "BSD", label: "Bahamian Dollar" },
                { value: "BHD", label: "Bahraini Dinar" },
                { value: "BDT", label: "Bangladeshi Taka" },
                { value: "BBD", label: "Barbadian Dollar" },
                { value: "BYR", label: "Belarusian Ruble" },
                { value: "BEF", label: "Belgian Franc" },
                { value: "BZD", label: "Belize Dollar" },
                { value: "BMD", label: "Bermudan Dollar" },
                { value: "BTN", label: "Bhutanese Ngultrum" },
                { value: "BTC", label: "Bitcoin" },
                { value: "BOB", label: "Bolivian Boliviano" },
                { value: "BAM", label: "Bosnia-Herzegovina Convertible Mark" },
                { value: "BWP", label: "Botswanan Pula" },
                { value: "BRL", label: "Brazilian Real" },
                { value: "GBP", label: "British Pound Sterling" },
                { value: "BND", label: "Brunei Dollar" },
                { value: "BGN", label: "Bulgarian Lev" },
                { value: "BIF", label: "Burundian Franc" },
                { value: "KHR", label: "Cambodian Riel" },
                { value: "CAD", label: "Canadian Dollar" },
                { value: "CVE", label: "Cape Verdean Escudo" },
                { value: "KYD", label: "Cayman Islands Dollar" },
                { value: "XOF", label: "CFA Franc BCEAO" },
                { value: "XAF", label: "CFA Franc BEAC" },
                { value: "XPF", label: "CFP Franc" },
                { value: "CLP", label: "Chilean Peso" },
                { value: "CNY", label: "Chinese Yuan" },
                { value: "COP", label: "Colombian Peso" },
                { value: "KMF", label: "Comorian Franc" },
                { value: "CDF", label: "Congolese Franc" },
                { value: "CRC", label: "Costa Rican ColÃ³n" },
                { value: "HRK", label: "Croatian Kuna" },
                { value: "CUC", label: "Cuban Convertible Peso" },
                { value: "CZK", label: "Czech Republic Koruna" },
                { value: "DKK", label: "Danish Krone" },
                { value: "DJF", label: "Djiboutian Franc" },
                { value: "DOP", label: "Dominican Peso" },
                { value: "XCD", label: "East Caribbean Dollar" },
                { value: "EGP", label: "Egyptian Pound" },
                { value: "ERN", label: "Eritrean Nakfa" },
                { value: "EEK", label: "Estonian Kroon" },
                { value: "ETB", label: "Ethiopian Birr" },
                { value: "EUR", label: "Euro" },
                { value: "FKP", label: "Falkland Islands Pound" },
                { value: "FJD", label: "Fijian Dollar" },
                { value: "GMD", label: "Gambian Dalasi" },
                { value: "GEL", label: "Georgian Lari" },
                { value: "DEM", label: "German Mark" },
                { value: "GHS", label: "Ghanaian Cedi" },
                { value: "GIP", label: "Gibraltar Pound" },
                { value: "GRD", label: "Greek Drachma" },
                { value: "GTQ", label: "Guatemalan Quetzal" },
                { value: "GNF", label: "Guinean Franc" },
                { value: "GYD", label: "Guyanaese Dollar" },
                { value: "HTG", label: "Haitian Gourde" },
                { value: "HNL", label: "Honduran Lempira" },
                { value: "HKD", label: "Hong Kong Dollar" },
                { value: "HUF", label: "Hungarian Forint" },
                { value: "ISK", label: "Icelandic KrÃ³na" },
                { value: "INR", label: "Indian Rupee" },
                { value: "IDR", label: "Indonesian Rupiah" },
                { value: "IRR", label: "Iranian Rial" },
                { value: "IQD", label: "Iraqi Dinar" },
                { value: "ILS", label: "Israeli New Sheqel" },
                { value: "ITL", label: "Italian Lira" },
                { value: "JMD", label: "Jamaican Dollar" },
                { value: "JPY", label: "Japanese Yen" },
                { value: "JOD", label: "Jordanian Dinar" },
                { value: "KZT", label: "Kazakhstani Tenge" },
                { value: "KES", label: "Kenyan Shilling" },
                { value: "KWD", label: "Kuwaiti Dinar" },
                { value: "KGS", label: "Kyrgystani Som" },
                { value: "LAK", label: "Laotian Kip" },
                { value: "LVL", label: "Latvian Lats" },
                { value: "LBP", label: "Lebanese Pound" },
                { value: "LSL", label: "Lesotho Loti" },
                { value: "LRD", label: "Liberian Dollar" },
                { value: "LYD", label: "Libyan Dinar" },
                { value: "LTL", label: "Lithuanian Litas" },
                { value: "MOP", label: "Macanese Pataca" },
                { value: "MKD", label: "Macedonian Denar" },
                { value: "MGA", label: "Malagasy Ariary" },
                { value: "MWK", label: "Malawian Kwacha" },
                { value: "MYR", label: "Malaysian Ringgit" },
                { value: "MVR", label: "Maldivian Rufiyaa" },
                { value: "MRO", label: "Mauritanian Ouguiya" },
                { value: "MUR", label: "Mauritian Rupee" },
                { value: "MXN", label: "Mexican Peso" },
                { value: "MDL", label: "Moldovan Leu" },
                { value: "MNT", label: "Mongolian Tugrik" },
                { value: "MAD", label: "Moroccan Dirham" },
                { value: "MZM", label: "Mozambican Metical" },
                { value: "MMK", label: "Myanmar Kyat" },
                { value: "NAD", label: "Namibian Dollar" },
                { value: "NPR", label: "Nepalese Rupee" },
                { value: "ANG", label: "Netherlands Antillean Guilder" },
                { value: "TWD", label: "New Taiwan Dollar" },
                { value: "NZD", label: "New Zealand Dollar" },
                { value: "NIO", label: "Nicaraguan CÃ³rdoba" },
                { value: "NGN", label: "Nigerian Naira" },
                { value: "KPW", label: "North Korean Won" },
                { value: "NOK", label: "Norwegian Krone" },
                { value: "OMR", label: "Omani Rial" },
                { value: "PKR", label: "Pakistani Rupee" },
                { value: "PAB", label: "Panamanian Balboa" },
                { value: "PGK", label: "Papua New Guinean Kina" },
                { value: "PYG", label: "Paraguayan Guarani" },
                { value: "PEN", label: "Peruvian Nuevo Sol" },
                { value: "PHP", label: "Philippine Peso" },
                { value: "PLN", label: "Polish Zloty" },
                { value: "QAR", label: "Qatari Rial" },
                { value: "RON", label: "Romanian Leu" },
                { value: "RUB", label: "Russian Ruble" },
                { value: "RWF", label: "Rwandan Franc" },
                { value: "SVC", label: "Salvadoran ColÃ³n" },
                { value: "WST", label: "Samoan Tala" },
                { value: "SAR", label: "Saudi Riyal" },
                { value: "RSD", label: "Serbian Dinar" },
                { value: "SCR", label: "Seychellois Rupee" },
                { value: "SLL", label: "Sierra Leonean Leone" },
                { value: "SGD", label: "Singapore Dollar" },
                { value: "SKK", label: "Slovak Koruna" },
                { value: "SBD", label: "Solomon Islands Dollar" },
                { value: "SOS", label: "Somali Shilling" },
                { value: "ZAR", label: "South African Rand" },
                { value: "KRW", label: "South Korean Won" },
                { value: "XDR", label: "Special Drawing Rights" },
                { value: "LKR", label: "Sri Lankan Rupee" },
                { value: "SHP", label: "St. Helena Pound" },
                { value: "SDG", label: "Sudanese Pound" },
                { value: "SRD", label: "Surinamese Dollar" },
                { value: "SZL", label: "Swazi Lilangeni" },
                { value: "SEK", label: "Swedish Krona" },
                { value: "CHF", label: "Swiss Franc" },
                { value: "SYP", label: "Syrian Pound" },
                { value: "STD", label: "São Tomé and Príncipe Dobra" },
                { value: "TJS", label: "Tajikistani Somoni" },
                { value: "TZS", label: "Tanzanian Shilling" },
                { value: "THB", label: "Thai Baht" },
                { value: "TOP", label: "Tongan pa'anga" },
                { value: "TTD", label: "Trinidad &amp; Tobago Dollar" },
                { value: "TND", label: "Tunisian Dinar" },
                { value: "TRY", label: "Turkish Lira" },
                { value: "TMT", label: "Turkmenistani Manat" },
                { value: "UGX", label: "Ugandan Shilling" },
                { value: "UAH", label: "Ukrainian Hryvnia" },
                { value: "AED", label: "United Arab Emirates Dirham" },
                { value: "UYU", label: "Uruguayan Peso" },
                { value: "USD", label: "USD" },
                { value: "UZS", label: "Uzbekistan Som" },
                { value: "VUV", label: "Vanuatu Vatu" },
                { value: "VEF", label: "Venezuelan BolÃ­var" },
                { value: "VND", label: "Vietnamese Dong" },
                { value: "YER", label: "Yemeni Rial" },
                { value: "ZMK", label: "Zambian Kwacha" },
              ]}
            />
          </Box>
        </Box>

        <InputFieldValidation
          label="Currency Rate"
          inputType="number"
          inputId="currencyRate"
          inputName="currencyRate"
          errors={errors}
          touched={touched}
          isRequired
          minNum="0"
          placeholder="3.75"
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
          validateField={validateStep}
          isThousand={true}
          setFieldTouched={setFieldTouched}
        />
        <InputFieldValidation
          label="Conversion to Statements"
          inputType="number"
          inputId="conversionToStatements"
          inputName="conversionToStatements"
          errors={errors}
          touched={touched}
          isRequired
          minNum="0"
          placeholder="0.00"
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={isRestore}
          validateField={validateStep}
          isThousand={true}
          setFieldTouched={setFieldTouched}
        />

        <InputFieldValidation
          label="Annual Inflation"
          inputType="number"
          inputId="annualInflation"
          inputName="annualInflation"
          errors={errors}
          touched={touched}
          isRequired
          icon={percent}
          minNum="0"
          placeholder="0.00"
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={isRestore}
          validateField={validateStep}
          isThousand={true}
          setFieldTouched={setFieldTouched}
        />
        <InputFieldValidation
          label="Development Cost Inflation"
          inputType="number"
          inputId="developmentCostInflation"
          inputName="developmentCostInflation"
          errors={errors}
          touched={touched}
          isRequired
          icon={percent}
          minNum="0"
          placeholder="0.00"
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={isRestore}
          validateField={validateStep}
          isThousand={true}
          setFieldTouched={setFieldTouched}
        />
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          btnStyle="transparent"
          disabledBtn={isRestore}
          fontColor="dark"
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />

        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/AddGeneralAssumptions`}
            />
          )}
          {!isDisabledForm ? (
            <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={false}
              onPress={() => {
                setValidateStep(false);
                closeToast();
                nextPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
          ) : (
            <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={false}
              onPress={() => {
                setValidateStep(true);
                isModelWarningOpen(true);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
          )}
        </Box>
      </Flex>
    </>
  );
};
