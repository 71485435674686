import { Box, Divider, HStack, Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import { Formik, Form } from "formik";
import { MainButton } from "../../components/button/MainButton";
import { GenerateModelForm } from "./GenerateModelForm";

import closeSmall from "../../assets/images/icons/closeSmall.svg";
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";

export const GenerateModelSelect = ({
  assetsData,
  type,
  id,
  projectNameValue,
  onErrorOpen,
  setErrorMessage,
  onClose,
  loadModelData,
  packageTypeArr,
}) => {
  const franchiseFeeLabel = assetsData.operatorTermsAndStaffing.managementFees.franchiseFeeLabel
  const reservationContributionLabel = assetsData.operatorTermsAndStaffing.otherFees.reservationContributionLabel
  const [dropDown, setDropDown] = useState([
    {
      value: "ADR",
      label: "ADR",
    },
    {
      value: "Room Rate",
      label: "ADR",
    },
    /* {
      value: "Room count, size and occupancy",
      label: "Room count, size and occupancy",
    }, */
    {
      value: "Cost per key",
      label: "Cost per key",
    },
    // {
    //   value: "Expense Level",
    //   label: "Expense Level",
    // },
    {
      value: "Economies of Scale",
      label: "Economies of Scale",
    },
    {
      value: "Base Fee",
      label: "Base Fee",
    },
    {
      value: "IT Budget",
      label: "IT Budget",
    },
    {
      value: "Contingency",
      label: "Contingency",
    },
    {
      value: "Occupancy (stabilized)",
      label: "Occupancy (stabilized)",
    },
    {
      value: "Developer Fee in %",
      label: "Developer Fee in %",
    },
    {
      value: "Pre-Opening and Marketing Expenses in %",
      label: "Pre-Opening and Marketing Expenses in %",
    },
    {
      value: "Equity %",
      label: "Equity %",
    },
    {
      value: "Interest Rate",
      label: "Interest Rate",
    },
    {
      value: "EBITDA Multiple",
      label: "EBITDA Multiple",
    },
    {
      value: "Branded Residence Inclusion",
      label: "Branded Residence Inclusion",
    },
    {
      value: "Staff per key",
      label: "Staff per key",
    },
    {
      value: "Staff cost per staff",
      label: "Staff cost per staff",
    },
    {
      value: `${reservationContributionLabel}`,
      label: `${reservationContributionLabel}`,
    },
    {
      value: "GFA",
      label: "GFA",
    },
    {
      value: "Incentive Fee",
      label: "Incentive Fee",
    },
    {
      value: `${franchiseFeeLabel}`,
      label: `${franchiseFeeLabel}`,
    },
    {
      value: `Include ${franchiseFeeLabel} in calculations`,
      label: `Include ${franchiseFeeLabel} in calculations`
    },
    {
      value: "Fee Cap",
      label: "Fee Cap"
    },
    {
      value: "Other expenses rooms",
      label: "Other expenses rooms",
    },
    {
      value: "Number of Keys",
      label: "Number of Keys",
    },
  ]);
  const [clearSelect, setClearSelect] = useState(false);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [initFormVal, setInitFormVal] = useState({ variables: "" });
  const {
    isOpen: isMissingOpen,
    onOpen: onMissingOpen,
    onClose: onMissingClose,
  } = useDisclosure();

  useEffect(() => {
    if (!!initFormVal?.variables) {
      let newArr = [];
      initFormVal.variables.forEach((el) =>
        newArr.push({ value: el, label: el })
      );
      // console.log("newArr", newArr);
      setSelectedVariables(newArr);
    }
  }, [initFormVal]);

  const handleDelete = (index, setFieldValue) => {
    let newInitVal = [
      ...initFormVal.variables.slice(0, index),
      ...initFormVal.variables.slice(index + 1),
    ];
    // console.log([
    //   ...initFormVal.variables.slice(0, index),
    //   ...initFormVal.variables.slice(index + 1),
    // ]);
    setInitFormVal({ variables: newInitVal });
    setFieldValue("variables", newInitVal);
  };

  useEffect(() => {
    if (type.toLowerCase() === "package") {
      let newDropDown = dropDown.filter((el) => el.value !== "ADR");
      // console.log([...newDropDown]);
      setDropDown([...newDropDown]);
    } else {
      let newDropDown = dropDown.filter((el) => el.value !== "Room Rate");
      // console.log([...newDropDown]);
      setDropDown([...newDropDown]);
    }
  }, []);

  return (
    <div>
      <Text pb="2" pt="1" fontSize="18px" letterSpacing={"0.02em"}>
        Select Variables
      </Text>
      <Text fontSize="14px" letterSpacing={"0.02em"}>
        You can select up to 8 variables
      </Text>
      <Formik
        initialValues={initFormVal}
        enableReinitialize={true}
        validate={(values) => {
          // console.log("values", values);
          setInitFormVal({ variables: values.variables });
        }}
        onSubmit={(values) => {
          setCheckedItems(values.variables);
        }}
      >
        {({ errors, touched, values, handleBlur, setFieldValue }) => (
          <Form className="">
            <>
              {initFormVal.variables.length !== 0 && (
                <HStack pt="2" width="100%">
                  {initFormVal.variables.map((variable, index) => (
                    <div key={index}>
                      <HStack
                        bg="#FCFCFC"
                        border="1px solid #EFEFEF"
                        borderRadius="5px"
                      >
                        <Text ml="2" p="1" fontSize="12px">
                          {variable}
                        </Text>

                        <MainButton
                          onPress={() => handleDelete(index, setFieldValue)}
                          icon={closeSmall}
                          btnStyle={"transparent"}
                        />
                      </HStack>
                    </div>
                  ))}
                  {initFormVal.variables.length > 0 && (
                    <MainButton
                      title="Clear All"
                      btnStyle="transparent"
                      fontColor="dark"
                      onPress={() => {
                        setSelectedVariables([]);
                        setInitFormVal({ variables: "" });
                      }}
                    />
                  )}
                </HStack>
              )}
              <Box pt="2" w="45%" alignSelf="flex-start">
                <HStack gap="2">
                  <SelectListMulti
                    placeH={"Select"}
                    inputId="variables"
                    inputName="variables"
                    errors={errors}
                    touched={touched}
                    isRequired
                    values={selectedVariables}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    uppercase={true}
                    isMulti={true}
                    errorHeight0={true}
                    clearMulti={clearSelect}
                    setClearSelect={setClearSelect}
                    iconArrow={false}
                    isValueReadable={true}
                    dropdownItems={dropDown}
                  />

                  <Box pb={{ sm: 1.5, md: 1.5, lg: 1.5, xl: 1.5, "2xl": 2 }}>
                    <MainButton
                      // onPress={() =>
                      //   console.log(
                      //     "dropdownValues",
                      //     setSelectedVariables(values.variables)
                      //   )
                      // }
                      type="submit"
                      title="Set Variables"
                      btnStyle="navyBlue"
                      btnHeight="48px"
                    />
                  </Box>
                </HStack>
              </Box>
            </>
          </Form>
        )}
      </Formik>
      <Divider orientation="horizontal" mt="10px" mb="30px" />{" "}
      <GenerateModelForm
        checkedItems={checkedItems}
        assetsData={assetsData}
        type={type}
        id={id}
        projectNameValue={projectNameValue}
        onErrorOpen={onErrorOpen}
        onMissingOpen={onMissingOpen}
        setErrorMessage={setErrorMessage}
        onClose={onClose}
        loadModelData={loadModelData}
        packageTypeArr={packageTypeArr}
      />
      <ModalAlert
        isOpen={isMissingOpen}
        onClose={onMissingClose}
        title={"Ensure that all fields are filled in."}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
    </div>
  );
};
