import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  GetMasterDataBrandByIdAPI,
  GetMasterDataBrandDraftByIdAPI,
  AddMasterDataBrandAPI,
  GetMasterDataRoomTypeByIdAPI,
  GetMasterDataRoomTypeDraftByIdAPI,
  AddMasterDataRoomTypeAPI,
  GetMasterDataPositioningByIdAPI,
  GetMasterDataPositioningDraftByIdAPI,
  AddMasterDataPositioningAPI,
  GetProductionOperatorWithIsActive,
  GetProductionRoomCategoryWithIsActive,
  GetMasterDataRegionByIdAPI,
  GetMasterDataRegionDraftByIdAPI,
  AddMasterDataRegionAPI,
  GetGIS,
} from "../../api/MasterDataAPI";

import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";
import Cookies from "js-cookie";
import {
  Box,
  HStack,
  Badge,
  Text,
  Flex,
  useDisclosure,
  Divider,
  Image,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";

import { MainBox } from "../../components/mainBox/MainBox";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { MainButton } from "../../components/button/MainButton";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import { MasterDataTable } from "../../components/masterDataTable/MasterDataTable";
import { TextArea } from "../../components/textArea/TextArea";
import { ToastAlert } from "../../components/toastAlert/ToastAlert";

import { parseToken } from "../../utils/Auth";
import history from "../../history";

import checked from "../../assets/images/icons/checked.svg";
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import eye from "../../assets/images/icons/eye.svg";

export const MasterDataCustom = () => {
  let newToken = parseToken();
  let date;
  let lastMod;
  let submitModalTitle = "Master data is successfully saved";

  const toast = useToast();
  const { type, id, draftId } = useParams();
  const [initConf, setInitConf] = useState({});
  const [finalValues, setFinalValues] = useState({});
  const [initFormVal, setInitFormVal] = useState({});
  const [masterData, setMasterData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [validateErrors, setValidateErrors] = useState({});
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [data, setData] = useState([]);
  const [clearSelect, setClearSelect] = useState(false);
  const [isNewError, setIsNewError] = useState("");
  const [dropDown, setDropDown] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isDraftOpen,
    onOpen: onDraftOpen,
    onClose: onDraftClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  if (!!masterData.lastModifiedAt) {
    date = dateFnsParseISO(masterData.lastModifiedAt);
    lastMod = dateFnsFormat(date, "dd.MM.yyyy; HH:mm");
  }

  const onEditRecord = (editVal) => {
    // console.log("editVal", editVal);
    if (editVal[initConf.cellVal] !== "") {
      // let newIsActive =
      //   !!editVal.isActive || editVal.isActive == "active" ? true : false;
      let newIsActive = editVal.isActive == "active" ? true : false;
      // let newIsActive = editVal.isActive === "active" ? true : false;
      let filter = finalValues.edits.filter((item) => {
        return item.value !== editVal.id;
      });
      // console.log("newIsActive", newIsActive);

      const submitValues = {
        ...finalValues,
        edits: [
          ...filter,
          {
            value: editVal.id,
            newLabel: editVal[initConf.cellVal],
            newAssociatedWith: editVal[initConf.cellVal2],
            ...(type.toLowerCase() === "regions" && {
              newIsActive: newIsActive,
            }),
          },
        ],
      };
      console.log("editVal", submitValues);

      setFinalValues(submitValues);
    }
  };

  const onAddRecord = (addedval) => {
    const submitValues = {
      ...finalValues,
      additions: [
        ...finalValues.additions,
        {
          // value: addedval[`${initConf.cellVal}`]
          //   .toLowerCase()
          //   .split(" ")
          //   .join("_")
          //   .concat(uuidv4()),
          value: addedval[`${initConf.cellVal}`]
            .toLowerCase()
            .split(" ")
            .join("_")
            .replace(/[^A-Za-z0-9]+/g, "")
            .concat(uuidv4()),
          label: addedval[`${initConf.cellVal}`],
          associatedWith: addedval[`${initConf.cellVal2}`],
          ...(type.toLowerCase() === "regions" && { isActive: true }),
        },
      ],
    };

    console.log("onAddRecord", submitValues);
    setFinalValues(submitValues);
  };
  const onRemoveRecord = (removedVal) => {
    const filteredData = finalValues.additions.filter(
      (item) => item.label !== removedVal[`${initConf.cellVal}`]
    );
    const submitValues = {
      ...finalValues,
      additions: [...filteredData],
    };
    setFinalValues(submitValues);
  };
  const onReorderRecords = (reorderRecords) => {
    let reorderArr = reorderRecords.map((item) => item.id);

    const submitValues = {
      ...finalValues,
      ordering: [...reorderArr],
    };
    setFinalValues(submitValues);
  };
  const closeToast = () => {
    toast.closeAll();
  };

  const onSubmitForm = (values) => {
    if (!!id) {
      let commentsLogic =
        masterData.comments === values.addComment ? "" : values.addComment;

      const submitValues = {
        ...finalValues,
        comments: commentsLogic,
        //isDraft: false,
        parentVersionNumber:
          masterData.versionNumber || masterData.parentVersionNumber,
        ...(!!draftId && { draftVersionNumber: Number(draftId) }),
      };
      initConf
        .AddDataAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
          } else if (res.success === true) {
            setErrorMessage("");
            setDisableSave(true);
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      const submitValues = {
        ...finalValues,
        comments: values.addComment,
        parentVersionNumber: -1,
      };

      initConf
        .AddDataAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };
  const handleSaveDraft = (values) => {
    if (!!id) {
      let commentsLogic =
        masterData.comments === values.addComment ? "" : values.addComment;
      const submitValues = {
        ...finalValues,
        comments: commentsLogic,
        versionNumber: !!draftId ? Number(draftId) : 0,
        parentVersionNumber: Number(id),
        isDraft: true,
        ...(!!draftId && { draftVersionNumber: Number(draftId) }),
      };

      initConf
        .AddDataAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            setErrorMessage("");
            setDisableSave(true);
            onDraftClose();
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      const submitValues = {
        ...finalValues,
        comments: values.addComment,
        isDraft: true,
        parentVersionNumber: Number(0),
      };

      initConf
        .AddDataAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            setErrorMessage("");
            setDisableSave(true);
            onDraftClose();
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };

  useEffect(() => {
    let initObj;
    switch (type) {
      case "Positioning":
        initObj = {
          cellVal: "category",
          cellVal2: "associatedBenchmarkReport",
          GetSingleDataAPI: GetMasterDataPositioningByIdAPI,
          GetSingleDataDraftAPI: GetMasterDataPositioningDraftByIdAPI,
          AddDataAPI: AddMasterDataPositioningAPI,
          GetProdDropdown: "",
          actionColor: "#006B44",
        };
        setInitConf(initObj);
        break;
      case "Room type":
        initObj = {
          cellVal: "roomType",
          cellVal2: "roomCategory",
          GetSingleDataAPI: GetMasterDataRoomTypeByIdAPI,
          GetSingleDataDraftAPI: GetMasterDataRoomTypeDraftByIdAPI,
          AddDataAPI: AddMasterDataRoomTypeAPI,
          GetProdDropdown: GetProductionRoomCategoryWithIsActive,
          actionColor: "#006B44",
        };
        setInitConf(initObj);
        break;
      case "Brand":
        initObj = {
          cellVal: "brand",
          cellVal2: "operator",
          GetSingleDataAPI: GetMasterDataBrandByIdAPI,
          GetSingleDataDraftAPI: GetMasterDataBrandDraftByIdAPI,
          AddDataAPI: AddMasterDataBrandAPI,
          GetProdDropdown: GetProductionOperatorWithIsActive,
          actionColor: "#006B44",
        };
        setInitConf(initObj);
        break;
      case "Regions":
        initObj = {
          cellVal: "regionName",
          cellVal2: "GISRegions",
          GetSingleDataAPI: GetMasterDataRegionByIdAPI,
          GetSingleDataDraftAPI: GetMasterDataRegionDraftByIdAPI,
          AddDataAPI: AddMasterDataRegionAPI,
          GetProdDropdown: GetGIS,
          actionColor: "#006B44",
        };
        setInitConf(initObj);
        break;

      default:
        break;
    }
    document.getElementById("main_content").scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (JSON.stringify(initConf) !== "{}") {
      //GET PRODUCTION DROPDOWN
      if (initConf.GetProdDropdown !== "") {
        initConf
          ?.GetProdDropdown()
          .then((res) => {
            let newDropDown = [];
            let resultArr = res?.data[0]?.lookups
              ? res.data[0]?.lookups
              : res.data;
            resultArr.forEach((item) => {
              newDropDown.push({
                value: item.value,
                label: item.label,
              });
            });
            setDropDown(newDropDown);
          })
          .catch((err) => {
            setErrorMessage("Something went wrong");
            onErrorOpen();
          });
      }

      if (!!id && !!draftId) {
        setIsReadOnly(false);
        setIsDraft(true);
        setErrorMessage("");
        initConf
          ?.GetSingleDataDraftAPI(id, draftId)
          .then((res) => {
            let lookupsParsed = res.data.lookups;
            let newDataArr = [];
            if (lookupsParsed.length > 0) {
              lookupsParsed.map((item) => {
                let dateItem = dateFnsParseISO(item.lastModifiedAt);
                let lastModItem = dateFnsFormat(dateItem, "dd.MM.yyyy; HH:mm");
                let isActive;
                if (type.toLowerCase() === "regions") {
                  isActive = item.isActive ? "active" : "Inactive";
                } else {
                  isActive = "active";
                }
                newDataArr.push({
                  id: item.value,
                  [initConf.cellVal]: item.label,
                  [initConf.cellVal2]: item.associatedWith,
                  lastModifiedAt: lastModItem,
                  lastModifiedBy: item.lastModifiedBy,
                  isActive: isActive,
                  action: ["edit"],
                  actionColor: initConf.actionColor,
                });
              });
            }

            const submitVal = {
              comments: "",
              additions: [],
              edits: [],
              ordering: [],
            };
            setFinalValues(submitVal);
            setInitFormVal({
              reg: "",
              id: "",
              [initConf.cellVal]: "",
              [initConf.cellVal2]: "",
              lastModifiedAt: "",
              lastModifiedBy: "",
              action: ["edit"],
              actionColor: "",
              addComment: res.data.comments || "",
            });
            setData(newDataArr);
            setMasterData(res.data);
          })
          .catch((err) => {
            setErrorMessage("Something went wrong");
            onErrorOpen();
          });
      } else if (!!id) {
        setErrorMessage("");
        initConf
          ?.GetSingleDataAPI(id)
          .then((res) => {
            let newDataArr = [];
            if (res.data.lookups.length > 0) {
              res.data.lookups.map((item) => {
                let dateItem = dateFnsParseISO(item.lastModifiedAt);
                let lastModItem = dateFnsFormat(dateItem, "dd.MM.yyyy; HH:mm");
                let isActive;
                if (type.toLowerCase() === "regions") {
                  isActive = item.isActive ? "active" : "Inactive";
                } else {
                  isActive = "active";
                }
                newDataArr.push({
                  id: item.value,
                  [initConf.cellVal]: item.label,
                  [initConf.cellVal2]: item.associatedWith,
                  lastModifiedAt: lastModItem,
                  lastModifiedBy: item.lastModifiedBy,
                  isActive: isActive,
                  action: ["edit"],
                  actionColor: initConf.actionColor,
                });
              });
            }

            const submitVal = {
              comments: "",
              additions: [],
              edits: [],
              ordering: [],
            };
            setFinalValues(submitVal);
            setInitFormVal({
              reg: "",
              id: "",
              [initConf.cellVal]: "",
              [initConf.cellVal2]: "",
              lastModifiedAt: "",
              lastModifiedBy: "",
              action: ["edit"],
              actionColor: "",
              addComment: res.data.comments || "",
            });
            setData(newDataArr);
            setMasterData(res.data);
            setIsReadOnly(res.data.status === "Archived");
            // setIsReadOnly(res.data.status === "Archived" && type !== "Regions");
          })
          .catch((err) => {
            setErrorMessage("Something went wrong");
            onErrorOpen();
          });
      } else {
        const submitVal = {
          comments: "",
          additions: [],
          edits: [],
          ordering: [],
        };
        setIsReadOnly(false);
        setFinalValues(submitVal);
        setInitFormVal({
          reg: "",
          id: "",
          [initConf.cellVal]: "",
          [initConf.cellVal2]: "",
          lastModifiedAt: "",
          lastModifiedBy: "",
          action: ["edit"],
          actionColor: "",
          addComment: "",
        });
      }
    }
  }, [initConf]);

  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        // let url = tx.location.pathname;
        if (window.confirm(`Are you sure you want to discard the changes?`)) {
          unblock();
          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  const handleSave = () => {
    setTimeout(() => {
      setDisableSave(false);
    }, 400);
  };

  return (
    <div>
      <MainBox>
        <HStack gap="10px" pb={8} pt={3}>
          <Box>
            <MainTitle pb="0" pt="0" title={type} />
          </Box>
          {id !== undefined && (
            <HStack w="auto" px="2" pt="0.5">
              <Badge bg="rgba(0, 123, 181, 0.2)" className="masterDataBadge">
                Version Number:&nbsp;
                {masterData.status === "Draft" ||
                masterData.status === "InitialDraft"
                  ? masterData.parentVersionNumber
                  : masterData.versionNumber}
              </Badge>
              <Badge bg="rgba(241, 136, 37, 0.3)" className="masterDataBadge">
                Last Modified By: {masterData.lastModifiedBy}, {lastMod}
              </Badge>
            </HStack>
          )}
          <Spacer />
          {isReadOnly ? (
            <HStack
              px="2"
              py="1"
              borderRadius="5"
              fontSize="12"
              bg="rgba(0, 107, 68, 0.2);"
            >
              <Image src={eye}></Image>
              <Text>View Only</Text>
            </HStack>
          ) : (
            ""
          )}
        </HStack>
        <HStack width="100%" flexDirection="column">
          <Formik
            initialValues={initFormVal}
            enableReinitialize={true}
            validate={(values) => {
              const errors = {};

              setValidateErrors(errors);
              setIsNewError("");

              return errors;
            }}
            onSubmit={(values, { setSubmitting, setFieldValue }) => {
              let cell2New = [];
              if (
                initConf.cellVal2 === "operator" ||
                initConf.cellVal2 === "roomCategory"
              ) {
                const findVal = dropDown.find(
                  (el) => values[`${initConf.cellVal2}`] === el.value
                );
                if (!!findVal) {
                  cell2New.push(findVal.label);
                }
              } else {
                cell2New = [...values[`${initConf.cellVal2}`]];
              }
              const tempObj = {
                id: values[`${initConf.cellVal}`]
                  ?.trim()
                  ?.split(" ")
                  ?.join("_"),
                [initConf.cellVal]: values[`${initConf.cellVal}`].trim(),
                [initConf.cellVal2]: cell2New,
                lastModifiedAt: `-`,
                lastModifiedBy: `${newToken.displayName}`,
                action: ["edit", "delete"],
                actionColor: "#E0403F",
                ...(type.toLowerCase() === "regions" && { isActive: true }),
              };

              let isNew = data.findIndex(
                (item) =>
                  item[`${initConf.cellVal}`].toLowerCase().trim() ===
                  tempObj[`${initConf.cellVal}`].toLowerCase().trim()
              );
              // let format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
              // let format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;
              let format = /[`'"]/;
              if (format.test(values[`${initConf.cellVal}`])) {
                toast({
                  position: "top-right",
                  isClosable: true,
                  duration: 5000,
                  render: () => (
                    <ToastAlert
                      title="You shouldn't have any special character."
                      icon={infoCircle}
                      closeToast={closeToast}
                      type="Warning"
                    />
                  ),
                });
              } else if (isNew === -1) {
                closeToast();
                setData((data) => [tempObj, ...data]);
                setSubmitting(false);
                onAddRecord(tempObj);
                setClearSelect(!clearSelect);
                setFieldValue(initConf.cellVal, "");
                setFieldValue(initConf.cellVal2, "");

                // if (
                //   initConf.cellVal2 == "operator" ||
                //   initConf.cellVal2 == "roomCategory" ||
                //   initConf.cellVal === "GISRegions"
                // ) {
                //   //setInitFormVal(initFormVal);
                //   //values[initConf.cellVal2] = "";
                // }
              } else {
                //setIsNewError("Already Exists");
                toast({
                  position: "top-right",
                  isClosable: true,
                  render: () => (
                    <ToastAlert
                      title={`Already exists`}
                      icon={infoCircle}
                      closeToast={closeToast}
                      type="Warning"
                    />
                  ),
                });
              }
            }}
          >
            {({
              isSubmitting,
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (
              <Form className="form">
                {!isReadOnly ? (
                  <HStack w="90%" alignItems="flex-end" mb={6}>
                    {type === "Positioning" && (
                      <>
                        <InputFieldValidation
                          label="POSITIONING NAME"
                          inputType="string"
                          inputId="category"
                          inputName="category"
                          errors={errors}
                          touched={touched}
                          isRequired
                          mbBox="0"
                          errorHeight0={true}
                        />
                        <SelectListMulti
                          label="Associated Benchmark report"
                          placeholder="Select"
                          inputId="associatedBenchmarkReport"
                          inputName="associatedBenchmarkReport"
                          errors={errors}
                          touched={touched}
                          isRequired
                          values={values?.[initConf.cellVal2]}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          uppercase={true}
                          isMulti={true}
                          errorHeight0={true}
                          clearMulti={clearSelect}
                          setClearSelect={setClearSelect}
                          dropdownItems={[
                            {
                              value: "City benchmarks",
                              label: "City benchmarks",
                            },
                            {
                              value: "Resort benchmarks",
                              label: "Resort benchmarks",
                            },
                            {
                              value: "Serviced Apartments",
                              label: "Serviced Apartments",
                            },
                          ]}
                        />
                      </>
                    )}
                    {type === "Regions" && (
                      <>
                        <Box width="40%">
                          <InputFieldValidation
                            label="Regions"
                            inputType="string"
                            inputId="regionName"
                            inputName="regionName"
                            errors={errors}
                            touched={touched}
                            isRequired
                            mbBox="0"
                            errorHeight0={true}
                          />
                        </Box>
                        {/* <SelectListMulti
                          label="GIS Regions"
                          placeholder="Select"
                          inputId="GISRegions"
                          inputName="GISRegions"
                          errors={errors}
                          touched={touched}
                          isRequired
                          values={values?.[initConf.cellVal2]}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          uppercase={true}
                          isMulti={true}
                          errorHeight0={true}
                          clearMulti={clearSelect}
                          setClearSelect={setClearSelect}
                          dropdownItems={dropDown}
                        /> */}
                      </>
                    )}
                    {type === "Room type" && (
                      <>
                        <InputFieldValidation
                          label="ROOM TYPE"
                          inputType="string"
                          inputId="roomType"
                          inputName="roomType"
                          errors={errors}
                          touched={touched}
                          isRequired
                          mbBox="0"
                          errorHeight0={true}
                        />
                        <SelectListMulti
                          label="ROOM CATEGORY"
                          placeholder="Select"
                          inputId="roomCategory"
                          inputName="roomCategory"
                          errors={errors}
                          touched={touched}
                          isRequired
                          values={values?.[initConf.cellVal2]}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          uppercase={true}
                          errorHeight0={true}
                          clearMulti={clearSelect}
                          setClearSelect={setClearSelect}
                          dropdownItems={dropDown}
                        />
                      </>
                    )}

                    {type === "Brand" && (
                      <>
                        <InputFieldValidation
                          label="brand"
                          inputType="string"
                          inputId="brand"
                          inputName="brand"
                          errors={errors}
                          touched={touched}
                          isRequired
                          mbBox="0"
                          errorHeight0={true}
                        />
                        <SelectListMulti
                          label="operator"
                          placeholder="Select"
                          inputId="operator"
                          inputName="operator"
                          errors={errors}
                          touched={touched}
                          isRequired
                          values={values?.[initConf.cellVal2]}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          uppercase={true}
                          errorHeight0={true}
                          clearMulti={clearSelect}
                          setClearSelect={setClearSelect}
                          dropdownItems={dropDown}
                        />
                      </>
                    )}
                    {type === "Positioning" && (
                      <MainButton
                        title="Add"
                        disabledBtn={
                          JSON.stringify(validateErrors) === "{}" &&
                          values[`${initConf.cellVal}`] !== ""
                            ? false
                            : true
                        }
                        type="submit"
                        btnStyle="navyBlue"
                        btnHeight="48px"
                        onPress={() => {
                          handleSave();
                        }}
                      />
                    )}
                    {(type === "Brand" || type === "Room type") && (
                      <MainButton
                        title="Add"
                        disabledBtn={
                          initConf.cellVal2 === "operator"
                            ? JSON.stringify(validateErrors) === "{}" &&
                              values[`${initConf.cellVal}`] !== ""
                              ? false
                              : true
                            : JSON.stringify(validateErrors) === "{}" &&
                              values[`${initConf.cellVal}`] !== "" &&
                              values[`${initConf.cellVal2}`] !== "" &&
                              values[`${initConf.cellVal2}`]?.length
                            ? false
                            : true
                        }
                        type="submit"
                        btnStyle="navyBlue"
                        btnHeight="48px"
                        onPress={() => {
                          handleSave();
                        }}
                      />
                    )}
                    {type === "Regions" && (
                      <MainButton
                        title="Add"
                        disabledBtn={
                          initConf.cellVal2 === "GISRegions"
                            ? JSON.stringify(validateErrors) === "{}" &&
                              values[`${initConf.cellVal}`] !== ""
                              ? false
                              : true
                            : JSON.stringify(validateErrors) === "{}" &&
                              values[`${initConf.cellVal}`] !== "" &&
                              values[`${initConf.cellVal2}`] !== "" &&
                              values[`${initConf.cellVal2}`]?.length
                            ? false
                            : true
                          // false
                        }
                        type="submit"
                        btnStyle="navyBlue"
                        btnHeight="48px"
                        onPress={() => {
                          handleSave();
                        }}
                      />
                    )}
                  </HStack>
                ) : null}

                <Text mt="3" fontSize="18" color="#000000">
                  List of{" "}
                  {type.slice(-2) === "us"
                    ? type.toLowerCase() + "es"
                    : type.slice(-1) === "y"
                    ? type.toLowerCase().slice(0, -1) + "ies"
                    : type.slice(-1) === "s"
                    ? type.toLowerCase()
                    : type.toLowerCase() + "s"}
                </Text>

                <Box width="100%" className="masterData_table_custom">
                  <MasterDataTable
                    data={data}
                    setData={setData}
                    setDisableSave={setDisableSave}
                    disableSave={disableSave}
                    isDraggable={true}
                    type={type}
                    disablePagination={true}
                    onEditRecord={onEditRecord}
                    onRemoveRecord={onRemoveRecord}
                    onReorderRecords={onReorderRecords}
                    newToken={newToken}
                    dropdown={dropDown}
                    isMulti={
                      type === "Positioning" || type === "Regions"
                        ? true
                        : false
                    }
                    isReadOnly={isReadOnly}
                  />
                </Box>

                {!isReadOnly ? (
                  <Box mb="3" /* mt="5" */ mt="1" width="45%">
                    <TextArea
                      label="ADD COMMENT"
                      inputId="addComment"
                      inputName="addComment"
                      placeholder="Add description"
                      errors={errors}
                      touched={touched}
                      isRequired
                      onChange={setFieldValue}
                      value={values}
                      values={values}
                    />
                  </Box>
                ) : null}
                <Divider orientation="horizontal" mt="5" mb="5" />
                <Flex
                  mt="3"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <MainButton
                    title="Save Draft"
                    btnStyle="transparent"
                    fontColor="light"
                    disabledBtn={isReadOnly}
                    onPress={() => {
                      onDraftOpen();
                    }}
                  />
                  <Box display="flex" gap="30px">
                    {disableSave ? (
                      <MainButton
                        title="Cancel"
                        btnStyle="transparent"
                        fontColor="light"
                        navLink={true}
                        path={`/MasterDataVersions/${type}`}
                      />
                    ) : (
                      <MainButton
                        title="Cancel"
                        btnStyle="transparent"
                        fontColor="light"
                        onPress={() => {
                          onCancelOpen();
                        }}
                      />
                    )}
                    <MainButton
                      title="Save"
                      reverseRow={true}
                      disabledBtn={isDraft ? false : disableSave ? true : false}
                      onPress={() => {
                        onSubmitForm(values);
                      }}
                    />
                  </Box>
                </Flex>

                <ModalAlert
                  isOpen={isDraftOpen}
                  onClose={onDraftClose}
                  title="Are you sure you want to save this as draft?"
                  icon={infoCircle}
                  confirmationModal={true}
                  hasDesc={false}
                  haveSaveDraft={false}
                  path={`/MasterDataVersions`}
                  pathDraft={`/MasterDataVersions/${type}`}
                  onPressSaveDraft={() => handleSaveDraft(values)}
                />
                <ModalAlert
                  isOpen={isCancelOpen}
                  onClose={onCancelClose}
                  title="Are you sure you want to discard the changes?"
                  icon={infoCircle}
                  hasDesc={false}
                  confirmationModal={true}
                  path={`/MasterDataVersions/${type}`}
                  onPressSaveDraft={() => handleSaveDraft(values)}
                  setDismissSave={() => setDismissSave(true)}
                />
              </Form>
            )}
          </Formik>
        </HStack>

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          icon={checked}
          hasDesc={false}
          btnWidthFull={true}
          path={`/MasterDataVersions/${type}`}
          disableClose={true}
        />

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />
      </MainBox>
    </div>
  );
};