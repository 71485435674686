import { position } from "@chakra-ui/react";
import {
    SET_APPROVAL_LIST,
    SET_UNAPPROVAL_LIST,
    UPDATE_KEYS,
} from "../actions/actionTypes";
import { ca } from "date-fns/locale";

const initialState = {
    approvalList: [],
    unapprovalList: [],
    region: [],
    position: [],
    brand: [],
    operator: [],
    allAssets: [],
    loading: true,
    savedSuccess: false,
    dismissSave: false,
    error: null,
};

const approvalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_APPROVAL_LIST:
            return {
                ...state,
                approvalList: action.payload,
                loading: false,
            };
        case SET_UNAPPROVAL_LIST:
            return {
                ...state,
                unapprovalList: action.payload,

                loading: false,
            };
        case UPDATE_KEYS:
            console.log("Received UPDATE_KEYS action:", action.payload);
            let input = action.payload.key;
            return {
                ...state,
                approvalList: state.approvalList.map((item) =>
                    item.id === action.payload.id
                        ? { ...item, [input]: action.payload[input], edit: true }
                        : item
                ),
                allAssets: state.allAssets.map((item) =>
                    item.id === action.payload.id
                        ? { ...item, [input]: action.payload[input], edit: true }
                        : item
                ),
                loading: false,
            };
        case "SET_REGION":
            return {
                ...state,
                region: action.payload,
            };
        case "SET_POSITION":
            return {
                ...state,
                position: action.payload,
            };
        case "SET_BRAND":
            return {
                ...state,
                brand: action.payload,
            };
        case "SET_OPERATOR":
            return {
                ...state,
                operator: action.payload,
            };
        case "SET_ALL_ASSETS":
            return {
                ...state,
                allAssets: action.payload,
                loading: false,
            };
        case "UPDATE_STATUS_TO_APPROVED":
            // Find the asset to update and update its status
            const updatedAsset = state.unapprovalList.find(
                (item) => item.id === action.payload.id
            );
            if (updatedAsset) {
                updatedAsset.status = action.payload.status;
            }
            return {
                ...state,
                // Add updated asset to the approval list
                approvalList: [...state.approvalList, updatedAsset],
                // Remove the asset from unapproval list
                unapprovalList: state.unapprovalList.filter(
                    (item) => item.id !== action.payload.id
                ),
                allAssets: state.allAssets.map((item) =>
                    item.id === action.payload.id
                        ? { ...item, status: action.payload.status }
                        : item
                ),
            };
        case "SET_SAVE":
            console.log("Received UPDATE_SAVE action:", action.payload);
            return {
                ...state,
                dismissSave: action.payload,
            };
        case "SET_LOADING":
            return {
                ...state,
                loading: action.payload,
            };
        case "SET_SAVED_SUCCESS":
            return {
                ...state,
                savedSuccess: action.payload,
            };
        case "SET_ERROR":
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default approvalReducer;
