import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Badge,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";

import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { MainButton } from "../../../components/button/MainButton";
import { TablePagination2 } from "../../../components/tablePagination/TablePagination2";
import { RenderCommentsBox } from "../../../components/renderCommentsBox/RenderCommentsBox";

import buildings from "../../../assets/images/icons/buildings.svg";
import threeDots from "../../../assets/images/icons/threeDots.svg";
import remove from "../../../assets/images/icons/deleteRed.svg";
import undo from "../../../assets/images/icons/undo.svg";
import leftArrow3 from "../../../assets/images/icons/leftArrow3.svg";

export const CityBenchmarksTable = ({
  onDelModalOpen,
  cityData,
  setSelectedID,
  GetDataAPI,
  comments,
  activePos,
}) => {
  let { type } = useParams();
  const tableRef = useRef(null);
  const [isTableLoading, setIsTableLoading] = useState(false);
  let typeSmall = type.split("_").join(" ").toLowerCase();
  let [currPagData, setCurrPagData] = useState({
    page: 1,
    sortOrder: "DESC",
    sort: "lastModifiedAt",
  });
  const handleSort = async (column, sortDirection) => {
    tableRef?.current
      .querySelectorAll(".table_content .rdt_TableCol_Sortable")
      [column.id - 1]?.children[1]?.classList.add("active");
    let sortOrder = sortDirection.toUpperCase();
    let sort = column.sortField;
    handleOnPageChange(currPagData.page, sort, sortOrder);
    setCurrPagData({ ...currPagData, sort: sort, sortOrder: sortOrder });
  };
  const [dataArray, setDataArray] = useState([]);

  const columns = [
    {
      name: "VERSION",
      selector: (row) => row.versionNum,
      sortable: true,
      sortField: "versionNumber",
      // cell: (row) => <Box color={`#007BB5`}>{row.versionNum}</Box>,
    },
    {
      name: "LAST MODIFIED",
      selector: (row) => row.modDate,
      sortable: true,
      sortField: "lastModifiedAt",
    },
    {
      name: "MODIFIED BY",
      selector: (row) => row.modBy,
      sortable: true,
      sortField: "lastModifiedBy",
    },

    {
      name: "STATUS",
      selector: (row) => row.status,
      sortable: true,
      sortField: "status",
      // minWidth: "160px",
      // width: "160px",
      cell: (row) => (
        <Flex justifyContent="center" alignItems="center">
          <Badge
            data-tag="allowRowEvents"
            data-action="edit"
            bg={row.statusBg}
            color="#fff"
            fontSize="14px"
            fontWeight="300"
            px={3}
            py="1"
            textTransform="none"
            borderRadius="3px"
          >
            {row.status}
          </Badge>
        </Flex>
      ),
    },
    {
      name: "ACTION",
      selector: (row) => row.action,
      cell: (row) => (
        <Flex justifyContent="space-between" alignItems="center">
          <MainButton
            title="Preview"
            btnStyle="transparent"
            fontColor="dark"
            marginRight="5"
            navLink={true}
            path={
              row.isDraftVersion || row.isInitialDraftVersion
                ? `/CityBenchmarks/${type}/${row.parentVersionNumber}/${row.idCustom}`
                : `/CityBenchmarks/${type}/${row.versionNumberInt}`
            }
            fSize="sm_x2"
            //path="/EditBenchMark"
          />
          {(row.status === "Draft" || row.status === "Obsolete") &&
            !row.isInitialDraftVersion && (
              <Menu autoSelect={false}>
                <MenuButton
                  bgColor="transparent"
                  px={1}
                  ml={1}
                  minWidth="auto"
                  as={Button}
                  _hover={{ backgroundColor: "transparent" }}
                >
                  <img src={threeDots} alt="dots icon" />
                </MenuButton>
                <MenuList px="5px" py="15px" minW="150px" w={"150px"}>
                  <Text fontSize="12px" color="#6F767E" px="10px" mb="7px">
                    More actions
                  </Text>
                  <MenuItem
                    alignItems="center"
                    onClick={() => {
                      setSelectedID(
                        `draft-${row.parentVersionNumber}-${row.idCustom}`
                      );
                      onDelModalOpen();
                    }}
                  >
                    <img src={remove} alt="delete icon" />
                    <Text color="#E0403F" ml="5px">
                      Delete
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          {row.status === "Archived" && (
            <Menu autoSelect={false}>
              <MenuButton
                bgColor="transparent"
                px={1}
                ml={1}
                minWidth="auto"
                as={Button}
                _hover={{ backgroundColor: "transparent" }}
              >
                <img src={threeDots} alt="dots icon" />
              </MenuButton>
              <MenuList px="5px" py="15px" minW="150px" w={"150px"}>
                <Text fontSize="12px" color="#6F767E" px="10px" mb="7px">
                  More actions
                </Text>
                <NavLink
                  to={`/CityBenchmarks/${type}/${row.versionNumberInt}`}
                  state={{ from: "Restore" }}
                >
                  <MenuItem alignItems="center">
                    <Box display="flex">
                      <img src={undo} alt="undo icon" />
                      <Text color="#003865" ml="5px">
                        Restore
                      </Text>
                    </Box>
                  </MenuItem>
                </NavLink>
              </MenuList>
            </Menu>
          )}
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    let data = [];
    if (cityData?.pageRows?.length > 0 && !!cityData?.pageRows) {
      cityData.pageRows.forEach((item) => {
        let statusBg;
        if (item.status === "Production") {
          statusBg = "#006B44";
        } else if (item.status === "Archived") {
          statusBg = "#A9A9A9";
        } else if (item.status === "Obsolete") {
          statusBg = "#007BB5";
        } else {
          statusBg = "#007BB5";
        }
        if (!!item.lastModifiedAt === false) return 0;
        let date = dateFnsParseISO(item.lastModifiedAt);

        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.id}`,
          isInitialDraftVersion: item.status === "InitialDraft",
          isDraftVersion: item.status === "Draft",
          versionNum:
            item.status === "Draft" ||
            item.status === "Obsolete" ||
            item.status === "InitialDraft"
              ? `Version ${item.parentVersionNumber}`
              : `Version ${item.versionNumber}`,
          parentVersionNumber: item.parentVersionNumber,
          versionNumberInt: item.versionNumber || 0,
          modDate: dateFnsFormat(date, "dd.MM.yyyy; HH:mm"),
          modBy: `${item.lastModifiedBy}`,
          status: `${
            item.status === "InitialDraft" ? "Initial Draft" : item.status
          }`,
          icon: buildings,
          bgCircle: "rgba(174, 159, 206, 0.35)",
          statusBg: statusBg,
        };
        // if (item.status == "Production") {
        //   data.unshift(dataObj);
        // } else {
        data.push(dataObj);
        //}
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
      sortOrder: "DESC",
      sort: "lastModifiedAt",
    });
  }, []);

  const handleOnPageChange = (page, sort = "lastModifiedAt", sortOrder) => {
    GetDataAPI(type, page, sort, sortOrder)
      .then((res) => {
        //setComments(res.data);
        //return res;
        let data = [];

        if (res.data.pageRows.length > 0) {
          res.data.pageRows.forEach((item) => {
            let statusBg;
            if (item.status === "Production") {
              statusBg = "#006B44";
            } else if (item.status === "Archived") {
              statusBg = "#A9A9A9";
            } else if (item.status === "Obsolete") {
              statusBg = "#007BB5";
            } else {
              statusBg = "#007BB5";
            }

            let date = dateFnsParseISO(item.lastModifiedAt);
            let dataObj = {
              id: `${item.id + Math.floor(Math.random() * Date.now())}`,
              idCustom: `${item.id}`,
              isInitialDraftVersion: item.status === "InitialDraft",
              isDraftVersion: item.status === "Draft",
              versionNum:
                item.status === "Draft" ||
                item.status === "Obsolete" ||
                item.status === "InitialDraft"
                  ? `Version ${item.parentVersionNumber}`
                  : `Version ${item.versionNumber}`,
              parentVersionNumber: item.parentVersionNumber,
              versionNumberInt: item.versionNumber || 0,
              modDate: dateFnsFormat(date, "dd.MM.yyyy; HH:mm"),
              modBy: `${item.lastModifiedBy}`,
              status: `${
                item.status === "InitialDraft" ? "Initial Draft" : item.status
              }`,
              icon: buildings,
              bgCircle: "rgba(174, 159, 206, 0.35)",
              statusBg: statusBg,
            };

            data.push(dataObj);
          });

          setDataArray(data);
          setIsTableLoading(false);
        }
      })
      .catch((err) => {
        setIsTableLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Box pb={4}>
        <MainButton
          title="Back"
          btnStyle="transparent"
          fontColor="dark"
          marginRight="1"
          navLink={true}
          icon={leftArrow3}
          path={"/CityBenchmarksType"}
        />
      </Box>
      <MainTitle title={`City benchmarks - ${activePos}`} />

      <HStack width="100%">
        <Tabs variant="unstyled" width="100%">
          <TabList w="95%" gap="3" mb={1}>
            <Tab
              _selected={{
                bg: "#F1EFE0",
                borderBottom: "2px",
                borderBottomColor: "#EBC03F !important",
                backgroundColor: "#F1EFE0",
              }}
              className="main_tabs"
              border="1px"
            >
              Version History
            </Tab>
            <Tab
              _selected={{
                bg: "#F1EFE0",
                borderBottom: "2px",
                borderBottomColor: "#EBC03F !important",
                backgroundColor: "#F1EFE0",
              }}
              className="main_tabs"
              border="1px"
            >
              Comments History
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel w="98%" px="0" ref={tableRef}>
              <TablePagination2
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title=""
                hasDelete={true}
                totalRows={cityData.totalRows}
                pageSize={cityData.pageSize}
                handleOnPageChange={handleOnPageChange}
                onSort={handleSort}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
                isTableLoading={isTableLoading}
                setIsTableLoading={setIsTableLoading}
              />
            </TabPanel>
            <TabPanel paddingX={0} marginX={0}>
              <MainBox>
                <RenderCommentsBox comments={comments} />
              </MainBox>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </>
  );
};
