import React, { useState, useEffect, useCallback } from "react";
import { Box, Image, useDisclosure } from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import editRow from "../../assets/images/icons/comment.svg";
import { GetProductionMasterDataWithIsActive, GetRegionComment, SetRegionComment } from "../../api/MasterDataAPI";
import { CommentModal } from "../../components/commentModal/commentModal";

export const CommentsTable = () => {
  const pages = {
    overview: "Overview",
    top_line_kpis: "Top Line KPIs",
    profitability: "Profitability",
    development_cost: "Development Cost",
    branded_residences: "Branded Residences",
    returns: "Returns",
    rollout_assets: "Rollout Assets",
    rollout_staffing: "Rollout Staffing",
    rollout_capex: "Rollout Capex",
    rollout_operator: "Rollout Operator",
    visitor_numbers: "Visitor Numbers",
    ceo_performance: "CEO Performance",
    ceo_rollout: "CEO Rollout",
    ceo_visitor_assumption: "CEO Visitor Assumption",
    ceo_asset_positioning: "CEO Asset Positioning",
    investment_overview: "Investment Overview",
  };
  
  const [commentData, setComment] = useState("");
  const [regionData, setRegionData] = useState({label:'', region: "", pageName: "" });
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    GetProductionMasterDataWithIsActive().then((response) => {
      setData(response.data[0].lookups);
    }).catch((error) => {
      console.log("error", error);
    });
  }, []);

  const openCommentModal = useCallback((row, pageName) => {
    GetRegionComment(row.value, pageName).then((comment) => {
      setComment(comment);
      setRegionData({ label:row.label, region: row.value, pageName });
      onOpen();
    }).catch((error) => {
      console.error("error", error);
    });
  }, [onOpen]);

  const saveComment = useCallback((comment) => {
    SetRegionComment({
      regionValue: regionData.region,
      pageName: regionData.pageName,
      comment,
    }).then((response) => {
      console.log("response", response);
    }).catch((error) => {
      console.error("error", error);
    });
  }, [regionData]);
 
  let columns = Object.keys(pages).map(key => ({
    name: pages[key],
    selector: key,
    width: "170px",
    cell: (row) => (
      <Box height="100%">
        <Box display="flex" alignItems="center" height="100%">
          <Box display="flex" alignItems="center" className="customClasssss" gap="15px">
            <Image
              cursor="pointer"
              className="action-button update"
              data-action="update"
              onClick={() => openCommentModal(row, key)}
              src={editRow}
            />
          </Box>
        </Box>
      </Box>
    )
  }));
  columns.unshift({
    name: "Region",
    selector: "label",
    width: "200px"
  });

  return (
    <Box className="table_content" pt={3} pb={2} mb={5} overflowX="auto">
      <DataTable
        columns={columns}
        data={data}
        sortServer
        defaultSortAsc={false}
      />
      {isOpen && (
        <CommentModal
          isOpen={isOpen}
          onClose={onClose}
          saveComment={saveComment}
          comment={commentData}
          pageTitle={pages[regionData.pageName]}
          region={regionData.label}
        />
      )}
    </Box>
  );
};
