import React, { useState, useEffect, useRef } from "react";

import { Formik, Form } from "formik";
import {
  Box,
  Text,
  useDisclosure,
  Spacer,
  Badge,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";

import {
  GetResortBenchmarksByIdAPI,
  AddResortBenchmarksAPI,
  GetResortBenchmarksDraftByIdAPI,
} from "../../../api/BenchmarksAPI";
import { GetProductionPositioningWithIsActive } from "../../../api/MasterDataAPI";

import history from "../../../history";

import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";

import { activeMenuItem } from "../../../utils/General";
import { ToastAlert } from "../../../components/toastAlert/ToastAlert";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";
import { ResortBenchmarksStep1 } from "./ResortBenchmarksStep1";
import { ResortBenchmarksStep2 } from "./ResortBenchmarksStep2";
import { validationResortBenchmarksForm } from "./validationResortBenchmarksForm";

import checked from "../../../assets/images/icons/checked.svg";
import infoCircle from "../../../assets/images/icons/infoCircle.svg";

export const ResortBenchmarks = () => {
  let location = useLocation();
  const [isRestore, setIsRestore] = useState(false);
  const activeMenu = activeMenuItem();
  let { type, id, draftId } = useParams();
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();
  const {
    isOpen: isDraftOpen,
    onOpen: onDraftOpen,
    onClose: onDraftClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const accordRef = useRef(null);
  const toast = useToast();
  const [initFormVal, setInitFormVal] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [resortBenchmarks, setResortBenchmarks] = useState([]);
  const [page, setPage] = useState(0);
  const [validateStep, setValidateStep] = useState(false);
  const [modelWarningOpen, isModelWarningOpen] = useState(false);
  const [activePos, setActivePos] = useState("");

  const steps = [
    { title: "Departmental expenses" },
    { title: "Undistributed Expenses" },
  ];

  let count = 0;
  let date;
  let lastMod;
  let submitModalTitle = `Resort benchmarks - ${activePos} is successfully created.`;
  if (!!resortBenchmarks.lastModifiedAt) {
    date = dateFnsParseISO(resortBenchmarks.lastModifiedAt);
    lastMod = dateFnsFormat(date, "dd.MM.yyyy; HH:mm");
  }

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };
  const closeToast = () => {
    toast.closeAll();
  };

  //OPEN ACCORDION THA HAVE ERROR
  const handleAccordErrors = (errors) => {
    let copyErr = { ...errors };
    if (copyErr?.comment) {
      delete copyErr.comment;
    }
    if (Object.keys(copyErr)?.length > 0) {
      let mainObjKey = Object.keys(copyErr)[0];
      let subObjKey = copyErr[`${mainObjKey}`];
      let accordClassName = Object?.keys(subObjKey)[0];

      accordRef.current
        .querySelectorAll(`.chakra-accordion__button`)
        .forEach((el, ind) => {
          if (el.classList.contains(`${accordClassName}`)) {
            if (el.ariaExpanded === "false") {
              el.click();
            }
          }
        });
    }
  };

  const handleOnSubmit = (formikValue, isRestore) => {
    if (!!id) {
      let resortBenchmarksParse = resortBenchmarks.formFields;
      let commentsLogic =
        resortBenchmarksParse.comments === formikValue.comments
          ? ""
          : formikValue.comments;

      const submitValues = {
        ...formikValue,
        isDraft: false,
        isRestore: !!isRestore,
        parentVersionNumber:
          resortBenchmarks.versionNumber ||
          resortBenchmarks.parentVersionNumber,
        comments: commentsLogic,
      };

      AddResortBenchmarksAPI(type, submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      const submitValues = {
        ...formikValue,
        isDraft: false,
        parentVersionNumber: -1,
      };

      AddResortBenchmarksAPI(type, submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };

  const handleSaveDraft = (formikValue) => {
    if (!!id) {
      let resortBenchmarksParse = resortBenchmarks.formFields;
      let commentsLogic =
        resortBenchmarksParse.comments === formikValue.comments
          ? ""
          : formikValue.comments;

      const submitValues = {
        ...formikValue,
        isDraft: true,
        versionNumber: !!draftId ? Number(draftId) : 0,
        parentVersionNumber: Number(id),
        comments: commentsLogic,
      };

      AddResortBenchmarksAPI(type, submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            onDraftClose();
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res.data;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      const submitValues = {
        ...formikValue,
        isDraft: true,
      };

      AddResortBenchmarksAPI(type, submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            onDraftClose();
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };

  useEffect(() => {
    let formField;

    GetProductionPositioningWithIsActive()
      .then((res) => {
        if (res.data.length > 0) {
          const filterPos = res.data[0].lookups.filter((el) =>
            el.associatedWith.includes("Resort benchmarks")
          );

          const findActive = filterPos.find((el) => el.value === type);

          setActivePos(findActive.label);
        }
      })
      .catch((err) => {
        setErrorMessage("Something went wrong");
        onErrorOpen();
      });

    if (!!id && !!draftId) {
      setErrorMessage("");
      GetResortBenchmarksDraftByIdAPI(type, id, draftId)
        .then((res) => {
          formField = res.data.formFields;

          setInitFormVal({
            departmentalExpenses: {
              rooms: {
                payroll: formField.departmentalExpenses.rooms.payroll,
                other: formField.departmentalExpenses.rooms.other,
              },
              foodAndBeverage: {
                payroll: formField.departmentalExpenses.foodAndBeverage.payroll,
                costOfSales:
                  formField.departmentalExpenses.foodAndBeverage.costOfSales,
                other: formField.departmentalExpenses.foodAndBeverage.other,
              },
              recreation: {
                payroll: formField.departmentalExpenses.recreation.payroll,
                other: formField.departmentalExpenses.recreation.other,
              },
              spa: {
                payroll: formField.departmentalExpenses.spa.payroll,
                costOfSales: formField.departmentalExpenses.spa.costOfSales,
                other: formField.departmentalExpenses.spa.other,
              },
              otherProfitDepartments: {
                payroll:
                  formField.departmentalExpenses.otherProfitDepartments.payroll,
                other:
                  formField.departmentalExpenses.otherProfitDepartments.other,
              },
              retail: {
                payroll: formField.departmentalExpenses.retail.payroll,
                costOfSales: formField.departmentalExpenses.retail.costOfSales,
                other: formField.departmentalExpenses.retail.other,
              },
              other1: {
                payroll: formField.departmentalExpenses.other1.payroll,
                other: formField.departmentalExpenses.other1.other,
              },
              other2: {
                payroll: formField.departmentalExpenses.other2.payroll,
                other: formField.departmentalExpenses.other2.other,
              },
            },
            undistributedExpenses: {
              adminGeneral: {
                payroll: formField.undistributedExpenses.adminGeneral.payroll,
                other: formField.undistributedExpenses.adminGeneral.other,
              },
              informationTelecom: {
                payroll:
                  formField.undistributedExpenses.informationTelecom.payroll,
                other: formField.undistributedExpenses.informationTelecom.other,
              },
              salesMarketing: {
                payroll: formField.undistributedExpenses.salesMarketing.payroll,
                other: formField.undistributedExpenses.salesMarketing.other,
              },
              propertyOperations: {
                payroll:
                  formField.undistributedExpenses.propertyOperations.payroll,
                other: formField.undistributedExpenses.propertyOperations.other,
              },
              utilities: {
                otherPerKey:
                  formField.undistributedExpenses.utilities.otherPerKey,
              },
              fixedCharges: {
                income: formField.undistributedExpenses.fixedCharges.income,
                operationalInsurance:
                  formField.undistributedExpenses.fixedCharges
                    .operationalInsurance,
                propertyAndOtherTaxes:
                  formField.undistributedExpenses.fixedCharges
                    .propertyAndOtherTaxes,
              },
            },

            comments: res.data.comments,
          });

          setResortBenchmarks(res.data);
        })
        .catch((err) => {
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    } else if (!!id) {
      setErrorMessage("");
      GetResortBenchmarksByIdAPI(type, id)
        .then((res) => {
          formField = res.data.formFields;

          setInitFormVal({
            departmentalExpenses: {
              rooms: {
                payroll: formField.departmentalExpenses.rooms.payroll,
                other: formField.departmentalExpenses.rooms.other,
              },
              foodAndBeverage: {
                payroll: formField.departmentalExpenses.foodAndBeverage.payroll,
                costOfSales:
                  formField.departmentalExpenses.foodAndBeverage.costOfSales,
                other: formField.departmentalExpenses.foodAndBeverage.other,
              },
              recreation: {
                payroll: formField.departmentalExpenses.recreation.payroll,
                other: formField.departmentalExpenses.recreation.other,
              },
              spa: {
                payroll: formField.departmentalExpenses.spa.payroll,
                costOfSales: formField.departmentalExpenses.spa.costOfSales,
                other: formField.departmentalExpenses.spa.other,
              },
              otherProfitDepartments: {
                payroll:
                  formField.departmentalExpenses.otherProfitDepartments.payroll,
                other:
                  formField.departmentalExpenses.otherProfitDepartments.other,
              },
              retail: {
                payroll: formField.departmentalExpenses.retail.payroll,
                costOfSales: formField.departmentalExpenses.retail.costOfSales,
                other: formField.departmentalExpenses.retail.other,
              },
              other1: {
                payroll: formField.departmentalExpenses.other1.payroll,
                other: formField.departmentalExpenses.other1.other,
              },
              other2: {
                payroll: formField.departmentalExpenses.other2.payroll,
                other: formField.departmentalExpenses.other2.other,
              },
            },
            undistributedExpenses: {
              adminGeneral: {
                payroll: formField.undistributedExpenses.adminGeneral.payroll,
                other: formField.undistributedExpenses.adminGeneral.other,
              },
              informationTelecom: {
                payroll:
                  formField.undistributedExpenses.informationTelecom.payroll,
                other: formField.undistributedExpenses.informationTelecom.other,
              },
              salesMarketing: {
                payroll: formField.undistributedExpenses.salesMarketing.payroll,
                other: formField.undistributedExpenses.salesMarketing.other,
              },
              propertyOperations: {
                payroll:
                  formField.undistributedExpenses.propertyOperations.payroll,
                other: formField.undistributedExpenses.propertyOperations.other,
              },
              utilities: {
                otherPerKey:
                  formField.undistributedExpenses.utilities.otherPerKey,
              },
              fixedCharges: {
                income: formField.undistributedExpenses.fixedCharges.income,
                operationalInsurance:
                  formField.undistributedExpenses.fixedCharges
                    .operationalInsurance,
                propertyAndOtherTaxes:
                  formField.undistributedExpenses.fixedCharges
                    .propertyAndOtherTaxes,
              },
            },

            comments: res.data.comments,
          });

          setResortBenchmarks(res.data);
        })
        .catch((err) => {
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    } else {
      let generalArr = {
        low: "",
        high: "",
        average: "",
      };
      const storedExcel = localStorage.getItem("parsedExcel-Benchmarks");
      if (storedExcel) {
        if (count === 0) {
          toast({
            position: "top-right",
            isClosable: true,
            render: () => (
              <ToastAlert
                title="The inputs has been successfully uploaded into the form"
                icon={checked}
                closeToast={closeToast}
              />
            ),
          });
          count = count + 1;
        }
        const formJson = JSON.parse(storedExcel);
        setInitFormVal({
          ...formJson,
          comments: "",
        });
      } else {
        setInitFormVal({
          departmentalExpenses: {
            rooms: {
              payroll: generalArr,
              other: generalArr,
            },
            foodAndBeverage: {
              payroll: generalArr,
              costOfSales: generalArr,
              other: generalArr,
            },
            recreation: {
              payroll: generalArr,
              other: generalArr,
            },
            spa: {
              payroll: generalArr,
              costOfSales: generalArr,
              other: generalArr,
            },
            otherProfitDepartments: {
              payroll: generalArr,
              other: generalArr,
            },
            retail: {
              payroll: generalArr,
              costOfSales: generalArr,
              other: generalArr,
            },
            other1: {
              payroll: generalArr,
              other: generalArr,
            },
            other2: {
              payroll: generalArr,
              other: generalArr,
            },
          },
          undistributedExpenses: {
            adminGeneral: {
              payroll: generalArr,
              other: generalArr,
            },
            informationTelecom: {
              payroll: generalArr,
              other: generalArr,
            },
            salesMarketing: {
              payroll: generalArr,
              other: generalArr,
            },
            propertyOperations: {
              payroll: generalArr,
              other: generalArr,
            },
            utilities: {
              otherPerKey: generalArr,
            },
            fixedCharges: {
              income: generalArr,
              operationalInsurance: generalArr,
              propertyAndOtherTaxes: generalArr,
            },
          },

          comments: "",
        });
      }
    }
    if (location?.state?.from === "Restore") {
      setIsRestore(true);
    }
    const clearUploadedExcel = setTimeout(() => {
      localStorage.removeItem("parsedExcel-Benchmarks");
    }, 500);
    return () => {
      clearTimeout(clearUploadedExcel);
    };
  }, []);

  useEffect(() => {
    console.log("modelWarningOpen", modelWarningOpen);
    if (modelWarningOpen) {
      toast({
        position: "top-right",
        isClosable: true,
        render: () => (
          <ToastAlert
            title="You must fill all the fields."
            icon={infoCircle}
            closeToast={closeToast}
            type="Warning"
          />
        ),
      });
    }
    isModelWarningOpen(false);
  }, [modelWarningOpen]);

  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`Are you sure you want to discard the changes?`)) {
          unblock();

          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  return (
    <div>
      {id === undefined ? (
        <MainTitle title={`Create Resort benchmarks - ${activePos}`} />
      ) : (
        <MainTitle title={`Edit Resort benchmarks - ${activePos}`} />
      )}

      <MainBox>
        <Box className="table_content" pt={6} pb={6}>
          <Box display="flex">
            <Text className="title_md" display="flex" mb={1} component="div">
              STEP
            </Text>
            <Text ml={1}>
              {page + 1}/{steps.length}
            </Text>
            {id !== undefined && (
              <HStack w="100%" px="8">
                <Badge bg="rgba(0, 123, 181, 0.2)" className="masterDataBadge">
                  Version Number:{" "}
                  {resortBenchmarks.status === "Draft" ||
                  resortBenchmarks.status === "InitialDraft"
                    ? resortBenchmarks.parentVersionNumber
                    : resortBenchmarks.versionNumber}
                </Badge>
                <Badge bg="rgba(241, 136, 37, 0.3)" className="masterDataBadge">
                  Last Modified By: {resortBenchmarks.lastModifiedBy}, {lastMod}
                </Badge>

                <Spacer />
              </HStack>
            )}
          </Box>

          <Box maxW="100%" mt="3">
            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validationSchema={validationResortBenchmarksForm[page]}
              validate={(values) => {
                if (disableSave) {
                  setDisableSave(false);
                }
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  onSubmitOpen();
                }, 400);
              }}
            >
              {({
                isSubmitting,
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                dirty,
              }) => (
                <>
                  <Form
                    className="form_wrapper justify_content_start"
                    ref={accordRef}
                  >
                    {/* {page !== 2 && <Stepper steps={steps} activeStep={page} />} */}
                    {page === 0 && (
                      <ResortBenchmarksStep1
                        nextPage={nextPage}
                        errors={errors}
                        touched={touched}
                        isRequired
                        onChange={setFieldValue}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        onCancelOpen={onCancelOpen}
                        onDraftOpen={onDraftOpen}
                        formikValues={values}
                        setDisableSave={setDisableSave}
                        disableSave={disableSave}
                        setDismissSave={setDismissSave}
                        isRestore={isRestore}
                        validateStep={validateStep}
                        setValidateStep={setValidateStep}
                        isModelWarningOpen={isModelWarningOpen}
                        modelWarningOpen={modelWarningOpen}
                        handleAccordErrors={handleAccordErrors}
                      />
                    )}
                    {page === 1 && (
                      <ResortBenchmarksStep2
                        nextPage={nextPage}
                        prevPage={prevPage}
                        errors={errors}
                        touched={touched}
                        isRequired
                        onChange={setFieldValue}
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        onCancelOpen={onCancelOpen}
                        onDraftOpen={onDraftOpen}
                        formikValues={values}
                        onSubmitOpen={onSubmitOpen}
                        handleOnSubmit={handleOnSubmit}
                        disableSave={disableSave}
                        setDismissSave={setDismissSave}
                        resortBenchmarks={resortBenchmarks}
                        dirty={dirty}
                        isRestore={isRestore}
                        validateStep={validateStep}
                        setValidateStep={setValidateStep}
                        isModelWarningOpen={isModelWarningOpen}
                        modelWarningOpen={modelWarningOpen}
                        handleAccordErrors={handleAccordErrors}
                      />
                    )}

                    <ModalAlert
                      isOpen={isDraftOpen}
                      onClose={onDraftClose}
                      title="Are you sure you want to save this as draft?"
                      icon={infoCircle}
                      confirmationModal={true}
                      hasDesc={false}
                      haveSaveDraft={false}
                      path={`/ResortBenchmarksHistory`}
                      pathDraft={`/ResortBenchmarksHistory`}
                      onPressSaveDraft={() => handleSaveDraft(values)}
                    />
                    <ModalAlert
                      isOpen={isCancelOpen}
                      onClose={onCancelClose}
                      title="Are you sure you want to discard the changes?"
                      icon={infoCircle}
                      hasDesc={false}
                      confirmationModal={true}
                      path={`/ResortBenchmarksHistory/${type}`}
                      onPressSaveDraft={() => handleSaveDraft(values)}
                      setDismissSave={() => setDismissSave(true)}
                    />
                  </Form>
                </>
              )}
            </Formik>
          </Box>
        </Box>
      </MainBox>

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
      <ModalAlert
        title={submitModalTitle}
        path={`/ResortBenchmarksHistory/${type}`}
        isOpen={isSubmitOpen}
        onClose={onSubmitClose}
        btnWidthFull={true}
        icon={checked}
        hasDesc={false}
        disableClose={true}
      />
    </div>
  );
};
