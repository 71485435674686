import React from "react";

import {
  Table,

  Tbody,

  Tr,
  Td,
  Text,

  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";


export const Calculation = ({
  assetData,
  isOpen,
  onClose,

}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Calculations</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer>
            <Table variant="unstyled" fontSize="11px">

              <Tbody>
                <Tr>
                  <Td>Occupancy Rate</Td>
                  <Td>{parseFloat(assetData.occupancyRate || 0).toFixed(2)} <b>%</b> </Td>
                </Tr>
                <Tr>
                  <Td>Average Daily Rate</Td>
                  <Td> {Math.ceil(assetData.averageDailyRate || 0).toLocaleString('en')} <b>SAR</b> </Td>
                </Tr>
                <Tr>
                  <Td>RevPAR</Td>
                  <Td> {Math.ceil(assetData.revPAR || 0).toLocaleString('en')} <b>SAR</b> </Td>
                </Tr>
                <Tr>
                  <Td>Total Revenue</Td>
                  <Td> {Math.ceil(assetData.totalRevenue || 0).toLocaleString('en')} <b>SAR</b> </Td>
                </Tr>

                <Tr>
                  <Td>Departmental Profit</Td>
                  <Td>{parseFloat(assetData.departmentalProfit.percentage || 0).toFixed(2)}  <b>%</b></Td>
                  <Td> {Math.ceil(assetData.departmentalProfit.value || 0).toLocaleString('en')} <b>SAR</b> </Td>
                </Tr>

                <Tr>
                  <Td>Gross Operating Profit</Td>
                  <Td>{parseFloat(assetData.grossOperatingProfit.percentage || 0).toFixed(2)}  <b>%</b></Td>
                  <Td> {Math.ceil(assetData.grossOperatingProfit.value || 0).toLocaleString('en')} <b>SAR</b> </Td>
                </Tr>

                <Tr>
                  <Td>EBITDA</Td>
                  <Td>{parseFloat(assetData.EBITDA.percentage || 0).toFixed(2)}  <b>%</b></Td>
                  <Td> {Math.ceil(assetData.EBITDA.value || 0).toLocaleString('en')} <b>SAR</b> </Td>
                </Tr>

                <Tr>
                  <Td>Total Fees To Operator</Td>
                  <Td>{parseFloat(assetData.totalFeesToOperator.percentage || 0).toFixed(2)}  <b>%</b></Td>
                  <Td> {Math.ceil(assetData.totalFeesToOperator.value || 0).toLocaleString('en')} <b>SAR</b> </Td>
                </Tr>


                <Tr>
                  <Td>Development Cost</Td>
                  <Td> {Math.ceil(assetData.developmentCost || 0).toLocaleString('en')} <b>SAR</b> </Td>
                </Tr>

                <Tr>
                  <Td>Levered IRR</Td>
                  <Td>{parseFloat(assetData.leveredIRR || 0).toFixed(2)}  <b>%</b></Td>
                </Tr>

                <Tr>
                  <Td>Unlevered IRR</Td>
                  <Td>{parseFloat(assetData.unleveredIRR || 0).toFixed(2)}  <b>%</b></Td>
                </Tr>

              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button className="title_sm" backgroundColor='#ebc03f' mr={3} onClick={onClose}>
            <Text fontWeight="400" fontSize="15px">close</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
}