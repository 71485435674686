import { Box, Text, Badge, Flex, WrapItem, Avatar } from "@chakra-ui/react";

export const CommentBox = ({ date, name, commentDesc, versionNum, icon }) => {
  return (
    <Box
      bg="white"
      py={5}
      maxW="100%"
      w="100%"
      borderRadius="5px"
      className="comment_box"
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" flex="1" alignItems="center">
          <WrapItem mr="12px">
            <Avatar
              name={name?.charAt(0)}
              src="/"
              key="user_1"
              size="sm"
              background="#bdc6d1"            
            />
          </WrapItem>
          {/* <Box
            borderRadius={50}
            bg="rgba(174, 159, 206, 0.35)"
            width="30px"
            height="30px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr="12px"
          >
            <img src={icon} width="100%" height="100%" alt="icon" />
          </Box> */}
          <Flex flexDirection="column">
            <Text className="title_sm">{name}</Text>
            <Text wordBreak={"break-word"} className="title_sm">{commentDesc}</Text>
          </Flex>
        </Box>
        <Flex
          bg="transparent                                                           "
          color="#FCFCFC"
          px="0px"
          pr="15px"
          fontWeight="400"
          alignItems="center"
        >
          <Text className="hint_sm">{date}</Text>
          <Box ml={3}>
            <Badge bg="rgba(0, 123, 181, 0.2)" className="masterDataBadge">
              Version {versionNum}
            </Badge>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
