import { SET_REGION_DROPDOWN_ITEMS, SET_POSITION_DROPDOWN_ITEMS } from '../actions/actionTypes';

const initialState = {
    region: [],
    position: []
};

const dropdownReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REGION_DROPDOWN_ITEMS:
            return {
                ...state,
                region: action.payload
            };
        case SET_POSITION_DROPDOWN_ITEMS:
            return {
                ...state,
                position: action.payload
            };
        default:
            return state;
    }
};

export default dropdownReducer;
