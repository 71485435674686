import axios from 'axios';
import config from '../config';
import Cookies from "js-cookie";

const AxiosClient = axios.create({
    baseURL: config.api.baseURL,
    withCredentials: true,
})

AxiosClient.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error?.response?.status === 401) {
        Cookies.remove("user_info");
        window.location.reload(false);
    } else {
        return Promise.reject(error);
    }
})

export default AxiosClient;
