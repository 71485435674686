import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Flex,
  Divider,
  GridItem,
  SimpleGrid,
  Text,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Button,
  useEditableControls,
  EditableInput,
  Editable,
  EditablePreview,
  Input,
} from "@chakra-ui/react";
import { assetListStep5calcWeightedAvgRate } from "../../utils/Formula";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { InputFieldCalc } from "../../components/inputField/InputFieldCalc";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { FieldArray } from "formik";
import { isDecimalNumber } from "../../utils/General";

import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import percent from "../../assets/images/icons/filled.svg";
import editRow from "../../assets/images/icons/editRow.svg";
import removeRow from "../../assets/images/icons/removeRow.svg";
import pen from "../../assets/images/icons/penBlue.svg";
import info from "../../assets/images/icons/Subtract.svg";

import { CalculationButton } from "../../components/calculationModal/calculationButton";

export const AssetsListForm5 = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  initFormVal,
  setInitFormVal,
  opStartDate,
  unsetStepWarning,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  step,
  setStepWarning,
  stepperUpdate,
  setStepperUpdate,
  validateStep,
  setValidateStep,
  modelWarningOpen,
  isModelWarningOpen,
  closeModalPath,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
  user,
}) => {
  // console.log("formik values tep 5", formikValues);
  const dataFetchedRef = useRef(false);
  const accordRef = useRef(null);
  let arrTitle = [
    "Food & Beverage Outlets - POR",
    "Meeting & Events Facilities",
    "Banquet - POR",
    "Recreation - POR",
    "Spa - POR",
    "Other Profit Departments - POR",
    "Retail - POR",
    "Miscellaneous Income",
    "Rental",
    formikValues?.departmentalExpenses?.other1.otherOneLabel,
    formikValues?.departmentalExpenses?.other2.otherTwoLabel,
  ];

  let form5Data = [];

  let emptyform5Data = [];
  let otherRevKeys = [
    "foodAndBeverage",
    "meetingEvents",
    "banquet",
    "recreation",
    "spa",
    "otherProfitDepartments",
    "retail",
    "miscellaneous",
    "rental",
    "other1",
    "other2",
  ];
  otherRevKeys.forEach((el) => {
    formikValues.otherRevenuesPerformance[el].forEach((item) => {
      form5Data.push(item);
    });
  });

  formikValues?.performaceAssitList2?.forEach((item) => {
    form5Data.push(item.year[0]);
    form5Data.push(item.year[1]);
    form5Data.push(item.year[2]);
    form5Data.push(item.year[3]);
    form5Data.push(item.year[4]);
    form5Data.push(item.year[5]);
  });
  form5Data.forEach((item, ind) => {
    /* && Number.isInteger(item) */
    if (item !== "" && item >= "0") {
      emptyform5Data.push(item);
    }
  });

  let isDisabledForm = false;
  //console.log(emptyform5Data, form5Data);
  if (emptyform5Data.length !== form5Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  let currDate;
  currDate = new Date(formikValues?.startDateOpTime).getFullYear();
  currDate = currDate - 1;
  let currDate2;
  currDate2 = new Date(formikValues?.startDateOpTime).getFullYear();
  currDate2 = currDate2 - 1;

  let calcFields = [
    "weightedADR1",
    "weightedADR2",
    "weightedADR3",
    "weightedADR4",
    "weightedADR5",
    "weightedADR6",
  ];

  const handleAccordErrors = () => {
    let openHigherAccord = true;

    form5Data.forEach((el, index) => {
      if (openHigherAccord) {
        if (el === "") {
          // console.log("index", index);
          if (index >= 66) {
            handleOpenAccord(0);
            openHigherAccord = false;
          }
          if (index < 66) {
            handleOpenAccord(1);
            openHigherAccord = false;
          }
        }
      }
    });
  };

  const handleOpenAccord = (accordIndex) => {
    if (
      accordRef.current.querySelectorAll(
        ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
      )[accordIndex].ariaExpanded === "false"
    ) {
      accordRef.current
        .querySelectorAll(
          ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
        )
        [accordIndex].click();
    }
  };
  const resetDate = () => {
    currDate = new Date(formikValues?.startDateOpTime).getFullYear() - 1;
  };

  const EditableControls = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <Box display="flex" justifyContent="space-around" width="80px">
        <button {...getSubmitButtonProps()}>
          <img src={editRow} alt="edit" width="20px" />
        </button>
        <button {...getCancelButtonProps()}>
          <img src={removeRow} alt="edit" width="20px" />
        </button>
      </Box>
    ) : (
      <Box marginLeft="10px">
        <button {...getEditButtonProps()} size="md">
          <img src={pen} width="13px" alt="edit icon" />
        </button>
      </Box>
    );
  };

  const onEditModelName = (event, labelName, name) => {
    let defultData;
    if (event !== "") {
      setFieldValue(labelName, event);

      if (setIsFormDirty && !isFormDirty) {
        setIsFormDirty(true);
      }
    } else {
      switch (name) {
        case "other1":
          defultData = "Other 1";
          break;
        case "other2":
          defultData = "Other 2";
          break;
      }
      setFieldValue(labelName, defultData);
    }
  };
  const handleEditChange = (newValue, labelName) => {
    setFieldValue(labelName, newValue);
  };

  const renderOtherRevenuesPerformance = (name, i) => {
    let renameActive = false;
    let key;
    switch (name) {
      case "other1":
        renameActive = true;
        key = "otherOneLabel";
        break;
      case "other2":
        renameActive = true;
        key = "otherTwoLabel";

        break;
    }

    return (
      <>
        <Box py={1} px={2}>
          <Text pb={3} textTransform="uppercase" fontSize="12px">
            {renameActive ? (
              <Editable
                value={arrTitle[i]}
                onChange={(value) => {
                  handleEditChange(
                    value,
                    `departmentalExpenses.${name}.${key}`
                  );
                }}
                fontSize="12px"
                // isPreviewFocusable={false}
                width="100%"
                display="flex"
                alignItems="center"
                className="title_md"
                onSubmit={(value) => {
                  onEditModelName(
                    value,
                    `departmentalExpenses.${name}.${key}`,
                    name
                  );
                }}
              >
                <EditablePreview />
                {/* Here is the custom input */}
                <Input as={EditableInput} borderRadius={0} textAlign="left" />
                <EditableControls />
              </Editable>
            ) : (
              <Flex justifyContent="space-between">
                {" "}
                <Text>{arrTitle[i]}</Text>
                {arrTitle[i] == "Food & Beverage Outlets - POR" ? (
                  <Flex>
                                                            <img src={info} alt="tooltip icon" />

                  <Text fontWeight="bold">

                    Detailed FnB Inputs in next page replace this input if
                    switched on
                  </Text>
                  </Flex>
                ) : (
                  <></>
                )}
              </Flex>
            )}
          </Text>
          <FieldArray
            name={`${name}`}
            render={(arrayHelper) => (
              <Box display="flex" width="90%" gap="2.5%">
                {formikValues.otherRevenuesPerformance[name] !== undefined &&
                  formikValues.otherRevenuesPerformance[name].map(
                    (_, index) => {
                      currDate = currDate + 1;
                      return (
                        <Box
                          width={["100%", "100%", "18%"]}
                          key={`${name}_${index}`}
                        >
                          <InputFieldValidation
                            label={`Year ${currDate}`}
                            placeholder="0"
                            inputType="number"
                            inputId={`otherRevenuesPerformance.${name}.${index}`}
                            inputName={`otherRevenuesPerformance.${name}.${index}`}
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            isRequired
                            onChange={setFieldValue}
                            values={
                              formikValues?.otherRevenuesPerformance[name][
                                index
                              ]
                            }
                            labelCenter={true}
                            uppercase={false}
                            directVal={true}
                            // smallIcon={percent}
                            isDisabled={isRestore}
                            validateField={validateStep}
                            smallLeftIcon={
                              name === "meetingEvents" ? "SQM" : "SAR"
                            }
                            smallerIcon={
                              name === "meetingEvents" ? "SQM" : "SAR"
                            }
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      );
                    }
                  )}
                {resetDate()}
              </Box>
            )}
          />
        </Box>
      </>
    );
  };

  const calcOccupancyIncField5 = (
    val = -1,
    index,
    perfAssetYearIndex,
    fieldName
  ) => {
    let newInitObj = assetListStep5calcWeightedAvgRate(
      val,
      index,
      perfAssetYearIndex,
      fieldName,
      formikValues
    );
    if (newInitObj != "0") {
      //console.log("intiob", newInitObj, { ...formikValues, newInitObj });
      setInitFormVal({ ...newInitObj });
    }
  };

  const handleCalcOcc = (name, val) => {
    //console.log("fffffas", formikValues);
    //console.log("fffffaaassss", name, val);
    if (`${name}`.includes("performaceAssitList2.") && val !== "") {
      let perfAssetIndex = `${name}`.split(".")[1];
      let perfAssetYearIndex = `${name}`.split(".")[3];

      calcOccupancyIncField5(
        val,
        perfAssetIndex,
        perfAssetYearIndex,
        "performaceAssitList2"
      );
    }
  };

  const handleFormula = (formikValues) => {
    //console.log("-----enter handle formula------");
    if (emptyform5Data.length === form5Data.length) {
      //console.log("-----all the field for calculation are entered------");
      let newSumArr1 = [];
      let newSumArr2 = [];
      let newSumArr3 = [];
      let newSumArr4 = [];
      let newSumArr5 = [];
      let newSumArr6 = [];
      let devidedBy = [];
      formikValues.roomType.forEach((item, ind) => {
        devidedBy.push(item.numKeys * item.occRate);
        newSumArr1.push(
          item.numKeys *
            item.occRate *
            formikValues.performaceAssitList2[ind].year[0]
        );
        newSumArr2.push(
          item.numKeys *
            item.occRate *
            formikValues.performaceAssitList2[ind].year[1]
        );
        newSumArr3.push(
          item.numKeys *
            item.occRate *
            formikValues.performaceAssitList2[ind].year[2]
        );
        newSumArr4.push(
          item.numKeys *
            item.occRate *
            formikValues.performaceAssitList2[ind].year[3]
        );
        newSumArr5.push(
          item.numKeys *
            item.occRate *
            formikValues.performaceAssitList2[ind].year[4]
        );
        newSumArr6.push(
          item.numKeys *
            item.occRate *
            formikValues.performaceAssitList2[ind].year[5]
        );
      });

      let sumCalc1 = 0;
      let sumCalc2 = 0;
      let sumCalc3 = 0;
      let sumCalc4 = 0;
      let sumCalc5 = 0;
      let sumCalc6 = 0;
      let devideBy = 0;
      newSumArr1.map((el) => (sumCalc1 = el + sumCalc1));
      newSumArr2.map((el) => (sumCalc2 = el + sumCalc2));
      newSumArr3.map((el) => (sumCalc3 = el + sumCalc3));
      newSumArr4.map((el) => (sumCalc4 = el + sumCalc4));
      newSumArr5.map((el) => (sumCalc5 = el + sumCalc5));
      newSumArr6.map((el) => (sumCalc6 = el + sumCalc6));
      devidedBy.map((el) => (devideBy = el + devideBy));
      sumCalc1 = (sumCalc1 / devideBy).toFixed(3);
      sumCalc2 = (sumCalc2 / devideBy).toFixed(3);
      sumCalc3 = (sumCalc3 / devideBy).toFixed(3);
      sumCalc4 = (sumCalc4 / devideBy).toFixed(3);
      sumCalc5 = (sumCalc5 / devideBy).toFixed(3);
      sumCalc6 = (sumCalc6 / devideBy).toFixed(3);

      setInitFormVal({
        ...formikValues,
        weightedADR1: sumCalc1,
        weightedADR2: sumCalc2,
        weightedADR3: sumCalc3,
        weightedADR4: sumCalc4,
        weightedADR5: sumCalc5,
        weightedADR6: sumCalc6,
      });
    }
  };

  useEffect(() => {
    if (
      !!formikValues?.performaceAssitList2 === false ||
      formikValues?.performaceAssitList2?.length == 0
    ) {
      let newInitData = [];
      formikValues.roomType.map((el) => {
        newInitData = [
          ...newInitData,
          {
            type: el.type,
            id: el.type,
            year: el?.year?.length > 0 ? el.year : Array(6).fill(0),
          },
        ];
      });

      setInitFormVal({
        ...formikValues,
        performaceAssitList2: newInitData,
      });
    } else {
      if (
        formikValues?.roomType?.length !=
        formikValues?.performaceAssitList2?.length
      ) {
        let { performaceAssitList2, roomType } = formikValues;

        let newInitData = [];
        formikValues.roomType.map((el, ind) => {
          newInitData = [
            ...newInitData,
            {
              type: performaceAssitList2[ind]?.type
                ? performaceAssitList2[ind].type
                : roomType[ind].type,
              id: performaceAssitList2[ind]?.type
                ? performaceAssitList2[ind].type
                : roomType[ind].type,
              year: performaceAssitList2[ind]?.year
                ? performaceAssitList2[ind].year
                : Array(6).fill(0),
            },
          ];
        });

        setInitFormVal({
          ...formikValues,
          performaceAssitList2: newInitData,
        });
      } else {
        handleFormula(formikValues);
      }
    }
  }, []);

  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);

  let otherRevenuesTypes = [
    "banquet",
    "foodAndBeverage",
    "meetingEvents",
    "miscellaneous",
    "other1",
    "other2",
    "recreation",
    "rental",
    "retail",
    "spa",
    "otherProfitDepartments",
  ];
  useEffect(() => {
    if (step.warning) {
      if (!isNaN(opStartDate)) {
        var difference =
          new Date(formikValues?.startDateOpTime).getFullYear() - opStartDate;
        //console.log("difference");
        if (
          difference !== 0 &&
          formikValues.otherRevenuesPerformance !== undefined
        ) {
          otherRevenuesTypes.forEach((element) => {
            setFieldValue(
              `otherRevenuesPerformance.${element}`,
              formikValues?.otherRevenuesPerformance[element].fill("")
            );
          });
        }
        if (
          difference !== 0 &&
          formikValues.performaceAssitList2 !== undefined
        ) {
          formikValues?.performaceAssitList2?.forEach((element, index) =>
            setFieldValue(
              `performaceAssitList2.${index}.year`,
              formikValues.performaceAssitList2[index].year.fill("")
            )
          );
        }
      }
    }
  }, []);

  return (
    <>
      <Box
        alignItems="flex-start"
        justifyContent="space-between"
        display="flex"
        width="100%"
        mb={5}
      >
        <MainTitle title="Performance Asset List 2" />
        <CalculationButton formikValue={formikValues} />
      </Box>
      <Accordion
        width="100%"
        allowToggle
        color="black"
        defaultIndex={[0]}
        ref={accordRef}
      >
        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  Average Daily Rate
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={6} border="1px" borderColor="#E6E6E6">
            <Box
              maxW={["100%", "100%", "100%"]}
              width={["100%", "100%", "100%"]}
              alignSelf="flex-start"
            >
              <Text
                className="title_md"
                display="flex"
                mb={5}
                mt={3}
                component="div"
              >
                {/* Weighted Average Daily Rate */}
                WEIGHTED AVERAGE DAILY RATE (UNINFLATED)
              </Text>
              <SimpleGrid
                columns={{ sm: 4, md: 4 }}
                spacingX="15px"
                width="84%"
              >
                <GridItem colSpan={4}>
                  <SimpleGrid
                    columns={{ sm: 6, md: 6 }}
                    spacing="15px"
                    width="100%"
                  >
                    {calcFields.map((item, ind) => {
                      currDate2 = currDate2 + 1;
                      let calcValue = isDecimalNumber(formikValues[item])
                        ? parseFloat(formikValues[item])?.toFixed(2)
                        : formikValues[item];
                      // let calcValue = 0;
                      return (
                        <GridItem key={`calcFields_${ind}`}>
                          <Box textAlign="center">
                            <Text mb="-10px !important" className="title_sm">
                              Year {currDate2}
                            </Text>
                            <Box>
                              <InputFieldValidation
                                hideLabel={true}
                                placeholder="0 SAR"
                                inputType="number"
                                inputId={item}
                                inputName={item}
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                isRequired
                                onChange={setFieldValue}
                                values={calcValue}
                                // values={formikValues}
                                labelCenter={true}
                                errorHeight0={true}
                                uppercase={false}
                                noBg={true}
                                isDisabled={true}
                                validateField={validateStep}
                                directVal={true}
                              />
                            </Box>
                          </Box>
                        </GridItem>
                      );
                    })}
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>

              <Divider orientation="horizontal" mt="20px" mb="30px" />
            </Box>
            <Box
              maxW={["100%", "100%", "100%"]}
              width={["100%", "100%", "100%"]}
              alignSelf="flex-start"
            >
              <FieldArray
                name="performaceAssitList2"
                render={(arrayHelper) => (
                  <>
                    {formikValues.performaceAssitList2 !== undefined &&
                      formikValues.performaceAssitList2.map(
                        (roomType, index) => {
                          currDate =
                            new Date(
                              formikValues?.startDateOpTime
                            ).getFullYear() - 1;
                          return (
                            <Box key={`perfAssit_${index}`}>
                              <Text
                                className="title_sm"
                                display="flex"
                                mb={5}
                                mt={3}
                                component="div"
                                letterSpacing="0.06em"
                                fontSize="12px"
                                textTransform="uppercase"
                              >
                                {dropdownObj?.roomTypesAssociatedWith?.map(
                                  (el) => {
                                    if (
                                      el.value ===
                                      formikValues.roomType[index]?.type
                                    )
                                      return `${el.associatedWith[0]
                                        .split("_")
                                        .join(" ")} - ${el.label}`;
                                  }
                                )}

                                {/* {formikValues.roomType[index]?.type
                                  .split("_")
                                  .join(" ")} */}
                              </Text>
                              <Box pb={4} pt={6}>
                                <>
                                  <GridItem colSpan={2} width="85%">
                                    <SimpleGrid
                                      columns={{ sm: 2, md: 6 }}
                                      spacingX="15px"
                                      width="100%"
                                    >
                                      {roomType.year.map((_, ind) => {
                                        currDate = currDate + 1;
                                        return (
                                          <GridItem
                                            colSpan={1}
                                            key={`roomType_${ind}`}
                                          >
                                            <InputFieldCalc
                                              label={`Year ${currDate}`}
                                              placeholder="0"
                                              inputType="number"
                                              inputId={`performaceAssitList2.${index}.year.${ind}`}
                                              inputName={`performaceAssitList2.${index}.year.${ind}`}
                                              errors={errors}
                                              setIsFormDirty={setIsFormDirty}
                                              isFormDirty={isFormDirty}
                                              touched={touched}
                                              isRequired
                                              onChange={setFieldValue}
                                              values={
                                                formikValues
                                                  ?.performaceAssitList2[index]
                                                  ?.year[ind]
                                              }
                                              labelCenter={true}
                                              errorHeight0={true}
                                              uppercase={false}
                                              calculatedOcc={true}
                                              onChangeCalc={handleCalcOcc}
                                              smallLeftIcon="SAR"
                                              smallerIcon={true}
                                              isDisabled={isRestore}
                                              validateField={validateStep}
                                              directVal={true}
                                              isThousand={true}
                                              setFieldTouched={setFieldTouched}
                                              onBlur={handleBlur}
                                            />
                                          </GridItem>
                                        );
                                      })}
                                    </SimpleGrid>
                                  </GridItem>
                                </>
                                {resetDate()}
                              </Box>
                            </Box>
                          );
                        }
                      )}
                  </>
                )}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  Other Revenues
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={6} border="1px" borderColor="#E6E6E6">
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              position="relative"
            >
              <Box maxW="65%" width="65%" alignSelf="flex-start"></Box>
            </Box>
            {formikValues?.otherRevenuesPerformance?.foodAndBeverage &&
              renderOtherRevenuesPerformance("foodAndBeverage", 0)}
            {formikValues?.otherRevenuesPerformance?.meetingEvents &&
              renderOtherRevenuesPerformance("meetingEvents", 1)}
            {formikValues?.otherRevenuesPerformance?.banquet &&
              renderOtherRevenuesPerformance("banquet", 2)}
            {formikValues?.otherRevenuesPerformance?.recreation &&
              renderOtherRevenuesPerformance("recreation", 3)}
            {formikValues?.otherRevenuesPerformance?.spa &&
              renderOtherRevenuesPerformance("spa", 4)}
            {formikValues?.otherRevenuesPerformance?.otherProfitDepartments &&
              renderOtherRevenuesPerformance("otherProfitDepartments", 5)}
            {formikValues?.otherRevenuesPerformance?.retail &&
              renderOtherRevenuesPerformance("retail", 6)}
            {formikValues?.otherRevenuesPerformance?.miscellaneous &&
              renderOtherRevenuesPerformance("miscellaneous", 7)}
            {formikValues?.otherRevenuesPerformance?.rental &&
              renderOtherRevenuesPerformance("rental", 8)}
            {formikValues?.otherRevenuesPerformance?.other1 &&
              renderOtherRevenuesPerformance("other1", 9)}
            {formikValues?.otherRevenuesPerformance?.other2 &&
              renderOtherRevenuesPerformance("other2", 10)}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex
        justifyContent={user == "manager" ? "end" : "space-between"}
        alignItems="center"
        width="100%"
      >
        {user !== "manager" ? (
          <MainButton
            title="Save Draft"
            btnStyle="transparent"
            disabledBtn={isRestore}
            fontColor="dark"
            onPress={() => {
              setDismissSave(false);
              onDraftOpen();
            }}
          />
        ) : (
          <></>
        )}
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {/* <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm ? true : false}
              onPress={() => {
                nextPage();
                if (percentage < 55) {
                  setPercentage(55);
                }
                unsetStepWarning(page);
                setStepCompleted(page);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  handleAccordErrors();
                  setValidateStep(true);

                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  nextPage();
                  if (percentage < 55) {
                    setPercentage(55);
                  }
                  unsetStepWarning(page);
                  setStepCompleted(page);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
