import * as Yup from "yup";

export const assetModellingValidation = [
  Yup.object().shape({
    averageDailyRate: Yup.array().of(
      Yup.object().shape({
        rates: Yup.array().of(
          Yup.number().min(0, "Required").required("Required")
        ),
      })
    ),

    developmentCostPerKey_SAR: Yup.number()
      .min(0, "Required")
      // .max(100, "This should be less than 100")
      .required("Required"),

    percentageOfBaseCost: Yup.number()
      .max(100, "This should be less than 100")
      .min(0, "Required")
      .required("Required"),

    economiesOfScaleApplied: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),

    NEOMDevelopmentFeeCost: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),

      reservationContributionRevenueType: Yup.string().required("Required"),
      reservationContributionLabel: Yup.string().required("Required"),
      reservationContribution: Yup.array()
        .of(
          Yup.object().shape({
            fixedCharge: Yup.number().typeError('This must be a number')
              .min(0, "Required")
              .required("Required"),
            percentage: Yup.number()
              .min(0, "Required").typeError('This must be a number')
              .max(100, "This should be less than 100")
              .required("Required")
          })
        ).required("Required"),

    GFAEfficiency: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),

    contingencyCost: Yup.number()
      .max(100, "This should be less than 100")
      .min(0, "Required")
      .required("Required"),

    baseFee: Yup.array().of(
      Yup.number()
        .min(0, "Required")
        .max(100, "This should be less than 100")
        .required("Required")
    ),

    preOpeningCost: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),

    equity: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),

    annualInterestRate: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),

    ebidtaMultiple: Yup.number().min(0, "Required").required("Required"),

    numberOfKeys: Yup.number().min(0, "Required").required("Required"),

    // occupancyIncreaseRate: Yup.number()
    //   .min(0, "Required")
    //   .max(100, "This should be less than 100")
    //   .required("Required"),

    staffToKeyRatio: Yup.array().of(
      Yup.number()
        .min(0, "This should be more than or equal 0")
        .required("Required")
    ),


    staffHousingCostPerStaff:  Yup.array().of(
      Yup.number()
        .min(0, "This should be more than or equal 0")
        .required("Required")
    ),

    // incentiveFee: Yup.array().of(
    //   Yup.number()
    //     .min(0, "Required")
    //     .max(100, "This should be less than 100")
    //     .required("Required")
    // ),
    incentiveFee: Yup.array()
      .of(
        Yup.object().shape({
          ifGOPLessThanPercent: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")

            .required("Required"),
          feePercentOfGOP: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")

            .required("Required"),
        })
      )
      .test({
        name: "incentiveFee",
        exclusive: false,
        params: {},
        message: "   ",
        test: function (values) {
          console.log("ifGOPLessThanPercent values", values);
          let countGreaterDesc = 0;
          values.forEach((inactive, ind) => {
            if (values.length - 2 >= ind) {
              //console.log(inactive);
              console.log(values[ind + 1].ifGOPLessThanPercent);
              console.log(inactive.ifGOPLessThanPercent);
              if (
                values[ind + 1].ifGOPLessThanPercent >=
                inactive.ifGOPLessThanPercent
              ) {
                countGreaterDesc++;
              }
            }
          });
          console.log("countGreaterDesc", countGreaterDesc);
          if (countGreaterDesc === 5) {
            return values;
          } else {
            return false;
          }
        },
      }),

      percentageCap: Yup.number()
      .min(0, "This should be greater than or equal 0")
      .max(100, "This should be less than 100")
      .required("Required"),

    franchiseFee: Yup.array()
      .of(
        Yup.object().shape({
          fixedCharge: Yup.number().typeError('This must be a number')
            .min(0, "Required")
            .required("Required"),
          percentage: Yup.number()
            .min(0, "Required").typeError('This must be a number')
            .max(100, "This should be less than 100")
            .required("Required")
        })
      ).required(),

    includeFranchiseFee: Yup.string().required(),
    roomRate: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required("Required"),
        id: Yup.string().required("Required"),
        year: Yup.array().of(
          Yup.number()
            .min(0, "Required")
            .required("Required")
        ),
      })
    ),
    occupancyIncreaseRate: Yup.array().of(
      Yup.number()
        .min(0, "Required")
        .max(100, "This should be less than 100")
        .required("Required")
    ),

    otherExpensesRoom: Yup.object().shape({
      value: Yup.number().min(0, "Required").required("Required"),
      fixed: Yup.number()
        .max(100, "This should be less than 100")
        .min(0, "Required")
        .required("Required"),
    }),
  }),
];
