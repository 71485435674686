import React, { useRef, useEffect } from "react";

import {
  Box,
  Text,
  Flex,
  Divider,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { DatePickerField } from "../../components/datePickerField/DatePickerField";
import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import percent from "../../assets/images/icons/filled.svg";
import calendar from "../../assets/images/icons/calendar.svg";

export const AssetsListForm2Proponent = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  devStartDate,
  setStepWarning,
  unsetStepWarning,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  step,
  setStepperUpdate,
  stepperUpdate,
  validateStep,
  setValidateStep,
  modelWarningOpen,
  isModelWarningOpen,
  nextStep,
  user,
  setFieldTouched,
}) => {
  // console.log("STEPXXXX", step);
  let form2Data = [];
  const dataFetchedRef = useRef(false);

  const opStartDate = new Date(formikValues?.startDateOpTime);

  form2Data.push(formikValues.bDownConsCost); //string
  form2Data.push(formikValues.bDownFfeOse); //string
  form2Data.push(formikValues.bDownSoftCost); //string
  form2Data.push(formikValues.bDownPreOpenTM); //string
  form2Data.push(formikValues.bDownNeomDevFee); //string
  form2Data.push(formikValues.bDownContCost); //string
  form2Data.push(formikValues.bDownIniWorkCapital); //string
  form2Data.push(formikValues.baseCost); //string
  form2Data.push(formikValues.refurbishmentCost); //string

  form2Data.push(formikValues.devCostPerKey); //string
  form2Data.push(formikValues.landCost); //string
  form2Data.push(formikValues.infraCost); //string
  form2Data.push(formikValues.parkingCost); //string
  //form2Data.push(formikValues.parkingDevDuration); //string
  form2Data.push(formikValues.bDownTsaFee); //string
  form2Data.push(formikValues.bDownDepTsaFee); //string
  //form2Data.push(formikValues.bDownITbudget); //string
  //form2Data.push(formikValues.bDownDepITbudget); //string
  form2Data.push(formikValues.refurbishmentYear); //string

  let emptyform2Data = [];
  form2Data.forEach((item, ind) => {
    if (ind <= 8) {
      if (ind === 8) {
        if (!isNaN(item)) {
          emptyform2Data.push(item);
        }
      } else {
        if (item !== "" && item >= 0 && item <= 100) {
          emptyform2Data.push(item);
        }
      }
    } else {
      if (ind === 15) {
        if (!isNaN(item)) {
          emptyform2Data.push(item);
        }
      } else {
        if (item !== "" && item >= 0) {
          emptyform2Data.push(item);
        }
      }
    }
  });

  let isDisabledForm = false; 
  if (emptyform2Data.length !== form2Data.length) {
    isDisabledForm = true;
  }
 
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  useEffect(() => {
    if (isDisabledForm) {
      nextStep.clicked = false;
      nextStep.completed = false;
      setStepWarning(page);
      setStepperUpdate(true);
    } else if (!isDisabledForm) {
      nextStep.clicked = true;
      nextStep.completed = true;
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);
  // console.log("nextStep", nextStep);
  return (
    <>
      <Box alignItems="flex-start" width="100%" mb={5}>
        <MainTitle title="Development Cost Breakdown" />
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Box
          maxW={["100%", "100%", "65%"]}
          width={["100%", "100%", "65%"]}
          alignSelf="flex-start"
        >
          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            General
          </Text>

          <InputFieldValidation /* (UNINFLATED) */
            label="Development Cost per Key "
            inputType="number"
            inputId="devCostPerKey"
            inputName="devCostPerKey"
            errors={errors}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            leftIcon={true}
            leftIconText="SAR"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            validateField={validateStep}
          />

          <InputFieldValidation
            // label="Land Cost - Freehold"
            label="Land Cost - Development Absorption"
            inputType="number"
            inputId="landCost"
            inputName="landCost"
            errors={errors}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            leftIcon={true}
            leftIconText="SAR"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            validateField={validateStep}
          />

          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={6}
            component="div"
          >
            Infrastructure
          </Text>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
              <GridItem colSpan={4}>
                <SimpleGrid
                  columns={{ sm: 4, md: 4 }}
                  spacing="15px"
                  width="100%"
                  className="gridRowGap0"
                >
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="Cost"
                      inputType="number"
                      inputId="infraCost"
                      inputName="infraCost"
                      errors={errors}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      leftIcon={true}
                      leftIconText="SAR"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      validateField={validateStep}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          </Box>

          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            Parking
          </Text>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
              <GridItem colSpan={4}>
                <SimpleGrid
                  columns={{ sm: 4, md: 4 }}
                  spacing="15px"
                  width="100%"
                  className="gridRowGap0"
                >
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="Cost"
                      inputType="number"
                      inputId="parkingCost"
                      inputName="parkingCost"
                      errors={errors}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      leftIcon={true}
                      leftIconText="SAR"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                      validateField={validateStep}
                    />
                  </GridItem>
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          </Box>

          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            Breakdown&nbsp; <Text fontSize={"10px"}> (Benchmark Data)</Text>
          </Text>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
              <GridItem colSpan={4}>
                <SimpleGrid
                  columns={{ sm: 4, md: 4 }}
                  spacing="15px"
                  width="100%"
                  className="gridRowGap0"
                >
                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="Construction Cost"
                      inputType="number"
                      inputId="bDownConsCost"
                      inputName="bDownConsCost"
                      errors={errors}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                      validateField={validateStep}
                    />
                  </GridItem>

                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="FF&E & OS&E"
                      inputType="number"
                      inputId="bDownFfeOse"
                      inputName="bDownFfeOse"
                      errors={errors}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                      validateField={validateStep}
                    />
                  </GridItem>

                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="Soft Costs (Professional Fees)"
                      inputType="number"
                      inputId="bDownSoftCost"
                      inputName="bDownSoftCost"
                      errors={errors}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                      validateField={validateStep}
                    />
                  </GridItem>

                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="Pre-Opening Training & Marketing"
                      inputType="number"
                      inputId="bDownPreOpenTM"
                      inputName="bDownPreOpenTM"
                      errors={errors}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                      validateField={validateStep}
                    />
                  </GridItem>

                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="TSA Fee  "
                      inputType="number"
                      inputId="bDownTsaFee"
                      inputName="bDownTsaFee"
                      errors={errors}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={isRestore}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                      validateField={validateStep}
                    />
                  </GridItem>

                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="NEOM Hotel Development Fee"
                      inputType="number"
                      inputId="bDownNeomDevFee"
                      inputName="bDownNeomDevFee"
                      errors={errors}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                      validateField={validateStep}
                    />
                  </GridItem>

                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="Contingency Cost"
                      inputType="number"
                      inputId="bDownContCost"
                      inputName="bDownContCost"
                      errors={errors}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                      validateField={validateStep}
                    />
                  </GridItem>

                  <GridItem colSpan={4}>
                    <InputFieldValidation
                      label="Initial Working Capital (% of Year 2 Revenue)"
                      inputType="number"
                      inputId="bDownIniWorkCapital"
                      inputName="bDownIniWorkCapital"
                      errors={errors}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      icon={percent}
                      isDisabled={isRestore}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                      validateField={validateStep}
                    />
                  </GridItem>
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          </Box>
          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            IT BUDGET
          </Text>
          <InputFieldValidation
            label="% of Base Cost"
            inputType="number"
            inputId="baseCost"
            inputName="baseCost"
            errors={errors}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            validateField={validateStep}
          />

          <InputFieldValidation
            label="Refurbishment/Demolition Cost (% of Total Development Cost) (Optional)"
            inputType="number"
            inputId="refurbishmentCost"
            inputName="refurbishmentCost"
            errors={errors}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            onChange={setFieldValue}
            values={formikValues}
            icon={percent}
            isDisabled={isRestore}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            validateField={validateStep}
          />
          <Box maxW="65%" width="65%" alignSelf="flex-start">
            <DatePickerField
              label={`Year of Refurbishment/Demolition (Optional)
              `}
              inputType="date"
              inputId="refurbishmentYear"
              inputName="refurbishmentYear"
              errors={errors}
              touched={touched}
              isRequired
              //values={`${formikValues.refurbishmentYear}`}
              values={formikValues.refurbishmentYear}
              onChange={setFieldValue}
              onBlur={handleBlur}
              minDate={new Date(opStartDate.toLocaleString().split(",")[0])}
              maxDate={new Date(`01/${formikValues?.operationTimeEndDate}`)}
              // minDate={new Date("01/01/2022")}
              // maxDate={new Date("01/01/2050")}
              // hintText="Select a year between 2022 - 2050"
              icon={calendar}
              isDisable={isRestore}
              validateField={validateStep}
              //isDisable={true}
            />
          </Box>
        </Box>
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          btnStyle="transparent"
          fontColor="dark"
          disabledBtn={isRestore || user === "proponent"}
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/AssetsHistory`}
            />
          )}

          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />

            {/*  <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm ? true : false}
              onPress={() => {
                nextPage();
                unsetStepWarning(page);
                setStepCompleted(page);
                if (percentage < 28) {
                  setPercentage(28);
                }
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  nextPage();
                  unsetStepWarning(page);
                  setStepCompleted(page);
                  if (percentage < 66) {
                    setPercentage(100);
                  }
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
