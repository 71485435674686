import { Box, Text } from "@chakra-ui/react";

export const NotificationBox = ({
  date,
  title,
  icon,
  url,
  actionLabel,
  isUnread,
  notifId,
  handleNotificationClicked,
  handleNotificationArchived,
  isCleared,
  isArchived
}) => {
  actionLabel = !!actionLabel ? actionLabel : "Preview";
  return (
    <Box
      bg={isUnread ? "white" : "#E2E8F0"}
      py={5}
      px={25}
      mb={5}
      maxW="100%"
      w="100%"
      borderRadius="5px"
      boxShadow="0px 4px 14px rgba(0, 0, 0, 0.05)"
    >
      <Text className="hint_sm" mb="8px">
        {date}
      </Text>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" width="100%">
          <Box
            borderRadius={50}
            bg="rgba(174, 159, 206, 0.35)"
            width="30px"
            height="30px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr="12px"
          >
            <img src={icon} width="16px" alt="icon" />
          </Box>
          <Text className="title_sm">{title}</Text>
        </Box>
        <Box          
          cursor="pointer"
        >
          <Box
            bg="transparent                                                           "
            color="#007bb5"
            px="0px"
            pr="15px"
            className="title_sm"
            fontWeight="400"
            justifyContent="space-between"
            display="flex"
            gap="15px"
          >
            <Text onClick={() => handleNotificationClicked(notifId, url)}  color="#007BB5" className="title_sm">{actionLabel}</Text>
              {
                !isArchived ? <Text onClick={() => handleNotificationArchived(notifId)} color="#007BB5" className="title_sm">Archive</Text>
                : null
              }

          </Box>
        </Box>
      </Box>
    </Box>
  );
};
