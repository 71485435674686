// // MainComponent.js
import { React, useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";

import { useSelector, useDispatch } from "react-redux";
import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const BarChartComponent = (params) => {
  const compsetCalc = useSelector((state) => state.calculation.compset);
  const myAssetsCalc = useSelector((state) => state.calculation.myAssets);
  const myAssetsKey = useSelector((state) => state.calculation.myAssetsKey);
  const compsetCalcsKey = useSelector(
    (state) => state.calculation.compsetCalcsKey
  );
  const isAggregation = useSelector((state) => state.calculation.isAggregation);
  const myAssetsAggregate = useSelector(
    (state) => state.calculation.myAssetsAggregate
  );
  const compsetAggregate = useSelector(
    (state) => state.calculation.compsetAggregate
  );

  const orangeDgree = {
    1: "#E9967A",
    2: "#E25822",
    3: "#FF7F50",
    4: "#FF3800",

  };

  const blueDgree = {
    1: "#7CB9E8",
    2: "#007FFF",
    3: "#0000FF",
    4: "#00BFFF",
  };

  let capex = [];
  let irr = [];
  let data = [];
  let labels = [
    "Year 1",
    "Year 2",
    "Year 3",
    "Year 4",
    "Year 5",
    "Year 6",
    "Year 7",
  ];

  let unitMap = {
    'irr': '%',
    'capex': 'SAR',
    'capexPerKey': 'SAR',
    'adrPerYear': 'SAR',
    'revparPerYear': 'SAR',
    'occupancyPerYear': '%',
    'gopPerYear': '%',
    'ebitdaPerYear': '%'
  }
  const unit = unitMap[params.value]
  let backgroundColor = [];
  let labels2 = [];
  let assetsName = [];
  let capexPerKey = [];
  if (isAggregation) {
    assetsName.push("My Assets");

    if (Object.keys(compsetAggregate).length !== 0)
      assetsName.push("Compset Assets");

    backgroundColor.push(orangeDgree[1]);
    backgroundColor.push(blueDgree[1]);

    if (params.value == "irr") {
      irr.push(myAssetsAggregate["irrPercent"]);
      if (Object.keys(compsetAggregate).length !== 0) {
        irr.push(compsetAggregate["irrPercent"]);
        labels2.push({
          text: "My Assets", // Adjust label if needed
          fillStyle: orangeDgree[1], // Adjust color if needed
          strokeStyle: orangeDgree[1], // Adjust color if needed
          pointStyle: "circle",
        });
        labels2.push({
          text: "Compset Assets", // Adjust label if needed
          fillStyle: blueDgree[1], // Adjust color if needed
          strokeStyle: blueDgree[1], // Adjust color if needed
          pointStyle: "circle",
        });
      }

    } else if (params.value == "capex") {
      capex.push(myAssetsAggregate["capex"]);
      capex.push(compsetAggregate["capex"]);
      labels2.push({
        text: "My Assets", // Adjust label if needed
        fillStyle: orangeDgree[1], // Adjust color if needed
        strokeStyle: orangeDgree[1], // Adjust color if needed
        pointStyle: "circle",
      });
      if (Object.keys(compsetAggregate).length !== 0)
        labels2.push({
          text: "Compset Assets", // Adjust label if needed
          fillStyle: blueDgree[1], // Adjust color if needed
          strokeStyle: blueDgree[1], // Adjust color if needed
          pointStyle: "circle",
        });
    }
    else if (params.value == 'capexPerKey') {
      capexPerKey.push(myAssetsAggregate["capexPerKey"]);
      capexPerKey.push(compsetAggregate["capexPerKey"]);
      labels2.push({
        text: "My Assets", // Adjust label if needed
        fillStyle: orangeDgree[1], // Adjust color if needed
        strokeStyle: orangeDgree[1], // Adjust color if needed
        pointStyle: "circle",
      });
      if (Object.keys(compsetAggregate).length !== 0)
        labels2.push({
          text: "Compset Assets", // Adjust label if needed
          fillStyle: blueDgree[1], // Adjust color if needed
          strokeStyle: blueDgree[1], // Adjust color if needed
          pointStyle: "circle",
        });
    } else {
      data.push({
        label: "My Assets",
        data: myAssetsAggregate[params.value],
        backgroundColor: orangeDgree[1],
      });
      if (Object.keys(compsetAggregate).length !== 0)
        data.push({
          label: "Compset Assets",
          data: compsetAggregate[params.value],
          backgroundColor: blueDgree[1],
        });
    }
  } else {
    myAssetsKey.map((asset, ind) => {
      assetsName.push(asset.replace(/_/g, " "));
      backgroundColor.push(orangeDgree[ind + 1]);
      if (params.value == "irr") {
        irr.push(myAssetsCalc[asset]["irr"] * 100);
        labels2.push({
          text: asset.replace(/_/g, " "), // Adjust label if needed
          fillStyle: orangeDgree[ind + 1], // Adjust color if needed
          strokeStyle: orangeDgree[ind + 1], // Adjust color if needed
          pointStyle: "circle",
        });
      } else if (params.value == "capex") {
        capex.push(myAssetsCalc[asset].calculationValues["capex"]);
        labels2.push({
          text: asset.replace(/_/g, " "), // Adjust label if needed
          fillStyle: orangeDgree[ind + 1], // Adjust color if needed
          strokeStyle: orangeDgree[ind + 1], // Adjust color if needed
          pointStyle: "circle",
        });
      }
      else if (params.value == "capexPerKey") {
        capexPerKey.push(myAssetsCalc[asset].calculationValues["capexPerKey"]);
        labels2.push({
          text: asset.replace(/_/g, " "), // Adjust label if needed
          fillStyle: orangeDgree[ind + 1], // Adjust color if needed
          strokeStyle: orangeDgree[ind + 1], // Adjust color if needed
          pointStyle: "circle",
        });

      } else {
        data.push({
          label: asset.replace(/_/g, " "),
          data: myAssetsCalc[asset].calculationValues[params.value].slice(0, 7),
          backgroundColor: orangeDgree[ind + 1],
        });
      }
    });

    compsetCalcsKey.map((asset, ind) => {
      assetsName.push(asset.replace(/_/g, " "));
      backgroundColor.push(blueDgree[ind + 1]);
      if (params.value == "irr") {
        irr.push(compsetCalc[asset]["irr"] * 100);
        labels2.push({
          text: asset.replace(/_/g, " "), // Adjust label if needed
          fillStyle: blueDgree[ind + 1], // Adjust color if needed
          strokeStyle: blueDgree[ind + 1], // Adjust color if needed
          pointStyle: "circle",
        });
      } else if (params.value == "capex") {
        labels2.push({
          text: asset.replace(/_/g, " "), // Adjust label if needed
          fillStyle: blueDgree[ind + 1], // Adjust color if needed
          strokeStyle: blueDgree[ind + 1], // Adjust color if needed
          pointStyle: "circle",
        });
        capex.push(compsetCalc[asset].calculationValues["capex"]);
      } else if (params.value == "capexPerKey") {
        capexPerKey.push(compsetCalc[asset].calculationValues["capexPerKey"]);
        labels2.push({
          text: asset.replace(/_/g, " "), // Adjust label if needed
          fillStyle: blueDgree[ind + 1], // Adjust color if needed
          strokeStyle: blueDgree[ind + 1], // Adjust color if needed
          pointStyle: "circle",
        });
      }
      else {
        data.push({
          label: asset.replace(/_/g, " "),
          data: compsetCalc[asset].calculationValues[params.value],
          backgroundColor: blueDgree[ind + 1],
        });
      }
    });
  }
  let options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: params.title,
        font: {
          size: 20, // Adjust the font size as needed
        },
        color: "black",
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
          callback: function (value, index, values) {
            // Use the formatting function for each tick label
            return formatNumberToReadable(value);
          }
        }

      }
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          generateLabels: function (chart) {
            return labels2;
          },
        },
      },
      title: {
        display: true,
        text: params.title,
        font: {
          size: 20, // Adjust the font size as needed
        },
        color: "black",
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
          callback: function (value, index, values) {
            // Use the formatting function for each tick label
            return formatNumberToReadable(value);
          }
        }

      }
    }
  };
  const dataChart = {
    labels: assetsName,
    datasets: [
      {
        data: params.value == "irr" ? irr : params.value == 'capex' ? capex : capexPerKey,
        backgroundColor,

        borderWidth: 1,
      },
    ],
  };

  const dataChart2 = {
    labels,
    datasets: data,
  };

  const formatNumberToReadable = (num) => {
    if (num >= 1e9) {
      return `${(num / 1e9).toFixed(0)}B ${unit}`;
    } else if (num >= 1e6) {
      return `${(num / 1e6).toFixed(0)}M ${unit}`;
    } else if (num >= 1e3) {
      return `${(num / 1e3).toFixed(0)}K ${unit}`;
    } else {
      return `${num} ${unit}`;
    }
  }
  return (
    <Box position="relative" mt={params.value == "irr" || params.value == "capex" || params.value == 'capexPerKey' ? '35px' : ''}>
      {params.value == "irr" || params.value == "capex" || params.value == 'capexPerKey' ? (
        <Bar id="test" data={dataChart} options={options} />
      ) : (
        <Bar id="test" data={dataChart2} options={options2} />
      )}
    </Box>
  );
};

export default BarChartComponent;
