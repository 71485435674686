// FileModal.js
import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Stack
} from '@chakra-ui/react';
import { AddFolder, UploadFile } from "../../api/MarketReportApi";
import { useParams } from "react-router-dom";

const FileModal = ({ isOpen, onClose, handleCreate }) => {
  const [files, setFiles] = useState(null);
  const [folderName, setFolderName] = useState('');
  const { id } = useParams();
  const [folderId, setFolderId] = useState(id);
  const [selectedOption, setSelectedOption] = useState(folderId ? 'file' : 'folder');

  const handleFileChange = (event) => {
    setFiles(event.target.files);

  };

  const handleCreateFolder = async () => {
    try {
      const payload = { folderName: folderName };
      if (folderId) {
        payload.folderId = folderId;
      }
  
      const data = await AddFolder(payload);
      handleCreate('folder', data.marketReportFolder);
      onClose();
    } catch (error) {
      handleCreate(null, null, true);
      onClose();
      console.error('Error creating folder:', error);
    }
  };
  
  const handleUpload = () => { 

    try {
      const formData = new FormData();
      if (folderId) {
        formData.append('folderId', folderId);
      }
      if (files) {
        Array.from(files).forEach((file, index) => {
          formData.append(`files`, file);
        });
      }
      
      UploadFile(formData)
        .then((data) => {
          handleCreate('file', data.marketReports)

          onClose();

        })
        .catch((err) => {
          handleCreate(null, null, true)

          onClose();
          console.error(err);
        });
     
    } catch (error) {
      console.error('Error creating folder:', error);
    }
    onClose();
  };

  return (


    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Folder {folderId ? 'or Upload File' : ''}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RadioGroup defaultValue="file" onChange={(value) => setSelectedOption(value)}>
            <Stack spacing={4}>
              {folderId && (<><Radio value="file">Upload File</Radio><Radio value="folder">Create Folder</Radio></>)}


            </Stack>
          </RadioGroup>

          {selectedOption === 'file' && folderId && (
            <FormControl mt={4}>
              <FormLabel>Upload File</FormLabel>
              <Input

                accept=".pdf,.xls, .xlsx"  multiple// Adjust based on accepted file types
                type="file" onChange={handleFileChange} />
            </FormControl>
          )}

          {selectedOption === 'folder' && (
            <FormControl mt={4}>
              <FormLabel>Create Folder</FormLabel>
              <Input type="text" placeholder="Folder Name" value={folderName} onChange={(e) => setFolderName(e.target.value)} />
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleCreateFolder} isDisabled={selectedOption !== 'folder' || folderName == ''}>
            Create Folder
          </Button>
          {folderId && <Button colorScheme="teal" onClick={handleUpload} isDisabled={selectedOption !== 'file' || files == null}>
            Upload File
          </Button>}

        </ModalFooter>
      </ModalContent>
    </Modal>


  );
};

export default FileModal;
