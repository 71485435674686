import React, { useState, useEffect } from "react";
import {
  HStack,
  Box,
  useDisclosure,
  Tooltip,
  Badge,
  Center,
  Spinner,
} from "@chakra-ui/react";

import { NavLink } from "react-router-dom";

import { GetProductionPositioningWithIsActive } from "../../../api/MasterDataAPI";

import { LinkBox } from "../../../components/linkBox/LinkBox";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";

import rightArrow from "../../../assets/images/icons/rightArrow.svg";
import infoCircle from "../../../assets/images/icons/infoCircle.svg";
import info from "../../../assets/images/icons/Subtract.svg";

export const CityBenchmarksType = () => {
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const [prodPos, setProdPos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    GetProductionPositioningWithIsActive()
      .then((res) => {
        if (res.data[0]?.lookups?.length === 0 || res?.data?.length === 0) {
          setIsLoading(true);
        }
        if (res.data.length > 0) {
          const filterPos = res.data[0].lookups.filter((el) =>
            el.associatedWith.includes("City benchmarks")
          );

          setProdPos(filterPos);

          if (filterPos.length === 0) {
            setIsLoading(true);
          }
        }
      })
      .catch((err) => {
        setErrorMessage("Something went wrong");
        onErrorOpen();
      });
  }, []);

  const renderKpis = () => {
    return prodPos.map((el, ind) => {
      return (
        <Box width="48%" className="m_0" key={ind}>
          <LinkBox
            title={el.label}
            icon={rightArrow}
            path={`/CityBenchmarksHistory/${el.value}`}
          />
        </Box>
      );
    });
  };

  return (
    <div>
      <HStack>
        <MainTitle title={"City benchmarks"} />
      </HStack>

      {prodPos.length > 0 ? (
        <HStack flexWrap="wrap" columnGap="20px">
          {renderKpis()}
        </HStack>
      ) : (
        <>
          {isLoading ? (
            <Badge bg="#EBC03F26" className="masterDataBadge" width="100%">
              <HStack p="1">
                <Tooltip
                  aria-label="A tooltip"
                  bg="#f4f4ed"
                  color="#1A1D1F"
                  borderRadius="5px"
                  border="1px solid #f4f4ed"
                  hasArrow
                  placement="right"
                  px={5}
                  py={2}
                >
                  <img src={info} alt="tooltip icon" />
                </Tooltip>
                <Box fontSize={{ lg: 12, "2xl": 15 }}>
                  Ensure that you create a&nbsp;
                  <NavLink
                    style={{ color: "#007BB5", textDecoration: "underline" }}
                    to={`/MasterDataVersions/Positioning`}
                  >
                    Positioning
                  </NavLink>
                  &nbsp;to add City Benchmarks.
                </Box>
              </HStack>
            </Badge>
          ) : (
            <Center>
              <Box paddingTop={28} paddingBottom={12}>
                <Spinner size={"xl"} />
              </Box>
            </Center>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
    </div>
  );
};
