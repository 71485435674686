// dataActions.js
import axiosClient from "../../api/index";
import { SET_MY_ASSETS, SET_COMPSET, FETCH_DATA_FAILURE, SET_COMPSET_ASSETS_SELECTED, SET_MY_ASSETS_SELECTED } from './actionTypes';



export const setMyAssetsSelected = (assetId, selected) => ({
  type: SET_MY_ASSETS_SELECTED,
  payload: { assetId, selected },
});

export const setCompsetAssetsSelected = (assetId, selected) => ({
  type: SET_COMPSET_ASSETS_SELECTED,
  payload: { assetId, selected },
});
export const fetchData = () => async (dispatch) => {
    const URL = `/v1/compare`;
    try {
        const response = await axiosClient.get(URL);
        dispatch(setCompset(response.data.data.compset));
        dispatch(setMyAssets(response.data.data.myAssets));
    } catch (error) {
        dispatch(fetchDataFailure(error.message));
    }
};

export const submitSelectedAssets = (myAssets, compset) => async (dispatch) => {
    try {
        // Send selected asset IDs to the API
        const response = await axiosClient.post('/v1/compare/calculate', { myAssets, compset});
        // Dispatch any necessary actions based on the API response
    } catch (error) {
        console.error('Error submitting selected assets:', error);
    }
};

export const setCompset = (compset) => ({
    type: SET_COMPSET,
    payload: compset
});

export const setMyAssets = (myAssets) => ({
    type: SET_MY_ASSETS,
    payload: myAssets
});

export const fetchDataFailure = (error) => ({
    type: FETCH_DATA_FAILURE,
    payload: error
});
