import React, { memo } from "react";

import { Box, Center, Text } from "@chakra-ui/react";
import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";

import { CommentBox } from "../commentBox/CommentBox";
import user from "../../assets/images/icons/placeholder.svg";

export const RenderCommentsBox = memo(function RenderCommentsBox({
  comments,
  assetComments = false,
}) {
  const renderNoCommentsBox = () => {
    return (
      <Center>
        <Text className="title_sm" display="flex" component="div" py={10}>
          No comments yet
        </Text>
      </Center>
    );
  };
  console.log("comments", comments);
  if (comments.length > 0) {
    const renderComments = comments
      .filter((item) => {
        return (
          (!!item?.comments || !!item?.comment) &&
          (item.status === "Production" ||
            item.status === "Archived" ||
            item.status === "InitialDraft" ||
            item.status === "Open" ||
            item.status === "InReview" ||
            assetComments)
        );
      })
      .map((item, index) => {
        let commentDate = dateFnsParseISO(item.lastModifiedAt);
        return (
          <Box key={index}>
            <CommentBox
              name={`${item.lastModifiedBy}`}
              commentDesc={`${item?.comments ? item?.comments : item?.comment}`}
              date={dateFnsFormat(commentDate, "dd.MM.yyyy; HH:mm")}
              versionNum={`${
                item?.versionNumber || item?.parentVersionNumber || item?.id
              }`}
              icon={user}
            />
          </Box>
        );
      });

    if (renderComments.length > 0) {
      return renderComments;
    } else {
      return renderNoCommentsBox();
    }
  } else {
    return renderNoCommentsBox();
  }
});
