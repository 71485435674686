import React, { useEffect, useRef } from "react";

import {
  Box, Flex, Divider, Text, SimpleGrid, useEditableControls,
  EditableInput,
  Editable,
  EditablePreview,
  Input,
} from "@chakra-ui/react";
import { FieldArray } from "formik";
import { assetListStep6HandleCalc } from "../../utils/Formula";

import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { InputFieldCalc } from "../../components/inputField/InputFieldCalc";

import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import { nullifyYearsField } from "../../utils/General";
import { isDecimalNumber } from "../../utils/General";
import percent from "../../assets/images/icons/filled.svg";
import { CalculationButton } from "../../components/calculationModal/calculationButton"
import editRow from "../../assets/images/icons/editRow.svg";
import removeRow from "../../assets/images/icons/removeRow.svg";
import pen from "../../assets/images/icons/penBlue.svg";

export const AssetsListForm6Package = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  initFormVal,
  setInitFormVal,
  opStartDate,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  unsetStepWarning,
  step,
  setStepWarning,
  setStepperUpdate,
  stepperUpdate,
  validateStep,
  setValidateStep,
  modelWarningOpen,
  isModelWarningOpen,
  closeModalPath,
  isFormDirty,
  setIsFormDirty,
  setFieldTouched,
  user
}) => {
  const dataFetchedRef = useRef(false);

  let arrTitle = [
    "Meeting & Events Facilities",
    "Banquet",
    "Recreation",
    "Spa",
    "Other Profit Departments",
    "Retail",
    "Miscellaneous Income",
    "Rental",
    formikValues?.departmentalExpenses?.other1.otherOneLabel,
    /* "Other 2", */
    // "Packages",
  ];

  let form6Data = [];

  let emptyform6Data = [];
  let otherRevKeys = [
    "banquet",
    "meetingEvents",
    "miscellaneous",
    "other1",
    /* "other2", */
    // "packages",
    "otherProfitDepartments",
    "recreation",
    "rental",
    "retail",
    "spa",
  ];

  otherRevKeys.forEach((el) => {
    formikValues.otherRevenueBreakdown[el].forEach((item) => {
      form6Data.push(item);
    });
  });

  form6Data.forEach((item, ind) => {
    if (item !== "" && item >= "0") {
      emptyform6Data.push(item);
    }
  });

  let isDisabledForm = false;
  //console.log(emptyform6Data, form6Data);
  if (emptyform6Data.length !== form6Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  const handleCalcOcc = (name, val) => {
    //isDisabledCalculate = true;

    //console.log("fffffas", formikValues);
    //console.log("fffffaaassss", name, val);

    let newInitObj = assetListStep6HandleCalc(name, val, formikValues);
    if (newInitObj != "0") {
      //console.log("intiob", newInitObj, { ...formikValues, ...newInitObj });
      setInitFormVal({ ...formikValues, ...newInitObj });
    }
  };
  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);

  // useEffect(() => {
  //   var difference =
  //     new Date(formikValues?.startDateOpTime).getFullYear() - opStartDate;
  //   if (difference !== 0) {
  //     nullifyYearsField(
  //       step,
  //       opStartDate,
  //       formikValues,
  //       setFieldValue,
  //       formikValues?.startDateOpTime,
  //       [
  //         "banquet",
  //         "meetingEvents",
  //         "miscellaneous",
  //         "other1",
  //         "other2",
  //         "recreation",
  //         "rental",
  //         "retail",
  //         "spa",
  //         "packages",
  //         "otherProfitDepartments",
  //       ],
  //       true
  //     );
  //   }
  // }, []);
  //console.log("Formik", formikValues);
  const EditableControls = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <Box display="flex" justifyContent="space-around" width="80px">
        <button {...getSubmitButtonProps()}>
          <img src={editRow} alt="edit" width="20px" />
        </button>
        <button {...getCancelButtonProps()}>
          <img src={removeRow} alt="edit" width="20px" />
        </button>
      </Box>
    ) : (
      <Box marginLeft="10px">

        <button {...getEditButtonProps()} size='md'>
          <img src={pen} width="13px" alt="edit icon" />
        </button>

      </Box>
    );
  };

  const onEditModelName = (event, labelName, name) => {
    let defultData
    if (event !== ""){
      setFieldValue(labelName, event)

      if (setIsFormDirty && !isFormDirty) {
        setIsFormDirty(true);
      }

    }
    else {
      switch (name) {
        case "otherOnePoter1erVisitor":
          defultData = "Other 1"
          break;
      }
      setFieldValue(labelName, defultData)
    }
  };
  const handleEditChange = (newValue, labelName) => {
    setFieldValue(labelName, newValue)
  };
  const renderOtherRevenueBreakdown = (name, i, nameCalc) => {
    //currDate = new Date(formikValues?.assetBaseYear).getFullYear();
    //currDate = currDate - 1;
    let currDate = new Date(formikValues?.startDateOpTime).getFullYear() - 1;
    //.log(name, currDate);
    let renameActive = false
    let key
    let otherName
    if (name == 'other1') {
      renameActive = true
      key = "otherOneLabel"
      otherName= 'other1'   
     }
    return (
      <>
        <Box py={1} px={2}>
          <Text pb={3} textTransform="uppercase" fontSize="12px">
          {renameActive ? (<Editable
              value={arrTitle[i]}
              onChange={(value) => {
                handleEditChange(value, `departmentalExpenses.${otherName}.${key}`)
              }}


              fontSize="12px"
              // isPreviewFocusable={false}
              width="100%"
              display="flex"
              alignItems="center"
              className="title_md"
              onSubmit={(value) => {
                onEditModelName(value, `departmentalExpenses.${otherName}.${key}`, name)
              }}
            >
              <EditablePreview />
              {/* Here is the custom input */}
              <Input as={EditableInput} borderRadius={0} textAlign="left" />
              <EditableControls />
            </Editable>) : arrTitle[i]}
          </Text>
          <SimpleGrid></SimpleGrid>
          <FieldArray
            name={`${name}`}
            render={(arrayHelper) => (
              <Box display="flex" width="100%" gap="2.5%">
                {formikValues.otherRevenueBreakdown[name] !== undefined &&
                  formikValues.otherRevenueBreakdown[name].map((_, index) => {
                    currDate = currDate + 1;
                    let calcValue = isDecimalNumber(
                      formikValues?.otherRevenueBreakdown[nameCalc][index]
                    )
                      ? parseFloat(
                        formikValues?.otherRevenueBreakdown[nameCalc][index]
                      )?.toFixed(2)
                      : formikValues?.otherRevenueBreakdown[nameCalc][index];

                    return (
                      <Box
                        width={["100%", "100%", "18%"]}
                        key={`${name}_${index}`}
                      >
                        <InputFieldCalc
                          label={`Year ${currDate}`}
                          placeholder="0"
                          inputType="number"
                          inputId={`otherRevenueBreakdown.${name}.${index}`}
                          inputName={`otherRevenueBreakdown.${name}.${index}`}
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={
                            formikValues?.otherRevenueBreakdown[name][index] ==
                              0
                              ? "0"
                              : formikValues?.otherRevenueBreakdown[name][index]
                          }
                          labelCenter={true}
                          uppercase={false}
                          currencyConvertor={name === "meetingEvents" || name === "other1" ? false : true}
                          /* currVal={
                            formikValues?.otherRevenueBreakdown[nameCalc][index]
                          }  */
                          calculatedOcc={true}
                          onChangeCalc={handleCalcOcc}
                          currConvVal={calcValue}
                          directVal={true}
                          smallerIcon={name === "meetingEvents" || name === "other1" ? true : false}
                          smallLeftIcon={name === "meetingEvents" ? "SQM" : name === "other1" ? "SAR" : ""}
                          smallIcon={name !== "meetingEvents" && name !== "other1" ? percent : undefined}
                          isDisabled={isRestore}
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          onBlur={handleBlur}
                        />
                      </Box>
                    );
                  })}
                {/* {resetDate()} */}
              </Box>
            )}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <Box alignItems="flex-start" justifyContent="space-between" display="flex" width="100%">
        <MainTitle title="Other revenue breakdown" />
        <CalculationButton formikValue={formikValues} />

      </Box>

      <Box
        maxW={["100%", "100%", "100%"]}
        width={["100%", "100%", "100%"]}
        alignSelf="flex-start"
      >
        <Text
          className="title_lg"
          textTransform="uppercase"
          display="flex"
          mb={5}
          mt={3}
          component="div"
        >
          % of other
        </Text>

        <Box>
          {formikValues?.otherRevenueBreakdown?.meetingEvents &&
            renderOtherRevenueBreakdown(
              "meetingEvents",
              0,
              "meetingEventsAmounts"
            )}
          {formikValues?.otherRevenueBreakdown?.banquet &&
            renderOtherRevenueBreakdown("banquet", 1, "banquetAmounts")}
          {formikValues?.otherRevenueBreakdown?.recreation &&
            renderOtherRevenueBreakdown("recreation", 2, "recreationAmounts")}
          {formikValues?.otherRevenueBreakdown?.spa &&
            renderOtherRevenueBreakdown("spa", 3, "spaAmounts")}
          {formikValues?.otherRevenueBreakdown?.otherProfitDepartments &&
            renderOtherRevenueBreakdown(
              "otherProfitDepartments",
              4,
              "otherProfitDepartmentsAmounts"
            )}
          {formikValues?.otherRevenueBreakdown?.retail &&
            renderOtherRevenueBreakdown("retail", 5, "retailAmounts")}
          {formikValues?.otherRevenueBreakdown?.miscellaneous &&
            renderOtherRevenueBreakdown(
              "miscellaneous",
              6,
              "miscellaneousAmounts"
            )}
          {formikValues?.otherRevenueBreakdown?.rental &&
            renderOtherRevenueBreakdown("rental", 7, "rentalAmounts")}

          {formikValues?.otherRevenueBreakdown?.other1 &&
            renderOtherRevenueBreakdown("other1", 8, "other1Amounts")}
          {/* {formikValues?.otherRevenueBreakdown?.other2 &&
            renderOtherRevenueBreakdown("other2", 9, "other2Amounts")} */}
          {/* {formikValues?.otherRevenueBreakdown?.packages &&
            renderOtherRevenueBreakdown("packages", 9, "packagesAmounts")} */}
        </Box>

        <Divider orientation="horizontal" mt="30px" mb="30px" />
      </Box>

      <Flex justifyContent={user == "manager" ? "end" : "space-between"} alignItems="center" width="100%">
        {
          user !== "manager" ?
            <MainButton
              title="Save Draft"
              btnStyle="transparent"
              disabledBtn={isRestore}
              fontColor="dark"
              onPress={() => {
                setDismissSave(false);
                onDraftOpen();
              }}
            /> : <></>
        }
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              disabledBtn={isRestore}
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {/*  <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm ? true : false}
              onPress={() => {
                nextPage();
                if (percentage < 64) {
                  setPercentage(64);
                }
                setStepCompleted(page);
                unsetStepWarning(page);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  nextPage();
                  if (percentage < 64) {
                    setPercentage(64);
                  }
                  setStepCompleted(page);
                  unsetStepWarning(page);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
