import React, { useState } from "react";
import config from "../config";
import { Box, Button } from "@chakra-ui/react";

import { MainBox } from "../components/mainBox/MainBox";

import logo from "../assets/images/icons/NEOM_LOGO3.svg";
import NeomText from "../assets/images/icons/Group.svg";
import logoLG from "../assets/images/icons/NEOM FutureLoop 2.png";
import windowsIcon from "../assets/images/icons/window.svg";
export const Login = ({ setLoggedIn }) => {
  const [loading, setLoading] = useState(false);

  const authAPI = async () => {
    window.location.href = config.api.loginBaseURL;
  };

  return (
    <div className="login_page">
      {!loading ? (
        <MainBox boxSize="md" maxWidth="500px" borderWidth="5">
          <Box
            w="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderBottomWidth={1}
            borderBottomColor="#EFEFEF"
            borderBottomStyle="solid"
            pb={17}
            mb={10}
          >
            <img src={logo} alt="logo" width="70px" />
            <Box display="flex" alignItems="center" pl={0} pe={10}>
              <div className="devider_vertical"></div>
            </Box>
            <div className="login_title_head">Asset Tracker</div>
          </Box>
          <Box
            w="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            pb={17}
            mb={10}
          >
            <div className="login_title">Login</div>
            <div className="login_desc">Asset data on your finger tips</div>
          </Box>
          <Box w="100%" pt={7} mb={7}>
            <Button
              type="submit"
              width="100%"
              className="btn_submit"
              onClick={authAPI}
            >
              <img src={windowsIcon} alt="azure login" />
              Login with your NEOM account
            </Button>
          </Box>
        </MainBox>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <img src={logoLG} alt="large logo" />
          <Box mt={12}>
            <img src={NeomText} alt="neom text" />
          </Box>

          <Box mt={20}>
            <div className="loading_text">Opening NEOM Asset Tracker ...</div>
          </Box>
        </Box>
      )}
    </div>
  );
};
