import { useState, useEffect, useCallback, useRef, useMemo } from "react";

import { GetHomeData, UpdateViewdAction } from "../api/DashboardAPI";
import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import {
  Box,
  HStack,
  Text,
  Center,
  Tabs,
  TabList,
  Tab,
} from "@chakra-ui/react";

import { activeMenuItem } from "../utils/General";
import { SummaryBox } from "../components/summaryBox/SummaryBox";
import { MainTitle } from "../components/mainTitle/MainTitle";
import { MainBox } from "../components/mainBox/MainBox";
import { TablePagination } from "../components/tablePagination/TablePagination";
import { stringFormat } from "../utils/General";
import history from "../history";

import document from "../assets/images/icons/Document color.svg";
import benchmark from "../assets/images/icons/benchmark color.svg";
import note from "../assets/images/icons/note color.svg";
import key from "../assets/images/icons/key2.svg";
import noAction from "../assets/images/icons/noAction.svg";
import assumption from "../assets/images/icons/assump.svg";
import buildings from "../assets/images/icons/building3.svg";
import masterData from "../assets/images/icons/Master Data.svg";
import { InfoCard } from "../components/infoCard/InfoCard";

export const DashboardHome = () => {
  const renderRef = useRef(false);
  const activeMenu = activeMenuItem();
  const [isLoading, setIsLoading] = useState(true);
  const [pendingData, setPendingData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("_USD");
  let user;
  if (Cookies.get("user_info")) {
    user = jwt_decode(Cookies.get("user_info"));
  }

  const columns = [
    {
      name: "NAME",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <Box className="" display="flex" alignItems="center">
          <Box
            borderRadius={50}
            bg={row.bgCircle}
            width="30px"
            height="30px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr="5px"
          >
            <img src={row.icon} width="16px" alt="icon" />
          </Box>
          {row.name}
        </Box>
      ),
    },
    {
      name: "LAST MODIFIED",
      selector: (row) => row.modDate,
      sortable: true,
    },
    {
      name: "MODIFIED BY",
      selector: (row) => row.modBy,
      sortable: true,
    },

    {
      name: "ACTION",
      selector: (row) => row.action,
      cell: (row) => {
        let url = row.url;
        let notifUrlId;
        let notifUrl;
        let newNotifUrl;

        if (`${url}`.includes("Standard") || `${url}`.includes("Package")) {
          newNotifUrl = `/AssetList${url}`;
        } else {
          notifUrlId = `${url}`?.split("/")?.pop();
          notifUrl = `${url}`?.split("/")[1];

          if (
            `${url}`.includes("roomType") ||
            `${url}`.includes("positioning") ||
            `${url}`.includes("brand")
          ) {
            newNotifUrl = `/MasterDataCustom/${stringFormat(
              notifUrl
            )}/${notifUrlId}`;
          } else {
            newNotifUrl = `/MasterData/${stringFormat(notifUrl)}/${notifUrlId}`;
          }
        }
        return (
          <Box
            onClick={() => handleNotificationClicked(row.idCustom, newNotifUrl)}
          >
            <button
              data-tag="allowRowEvents"
              data-action="edit"
              style={{
                color: `${row.actionColor}`,
                textDecoration: "underline",
              }}
            >
              {row.action}
            </button>
          </Box>
        );
      },
    },
  ];

  const handleNotificationClicked = (id, url) => {
    UpdateViewdAction(id)
      .then((res) => {
        history.push(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let data = [];
  if (pendingData?.pendingActions?.length > 0) {
    pendingData.pendingActions.forEach((item) => {
      let iconLogic;
      let iconBg;

      if (item.name.includes("General")) {
        iconLogic = assumption;
        iconBg = "#F5DBC2";
      } else if (item.name.includes("Benchmarks")) {
        iconLogic = benchmark;
        iconBg = "rgba(0, 123, 181, 0.2)";
      } else if (
        `${item.actionUrl}`.includes("Standard") ||
        `${item.actionUrl}`.includes("Package")
      ) {
        iconLogic = buildings;
        iconBg = "rgba(174, 159, 206, 0.35)";
      } else {
        iconLogic = masterData;
        iconBg = "#F5DBC2";
      }
      let date = dateFnsParseISO(item.lastModifiedAt);
      date = dateFnsFormat(date, "dd.MM.yyyy; HH:mm");

      let dataObj = {
        name: `${item.name}`,
        id: `${item.id + Math.floor(Math.random() * Date.now())}`,
        idCustom: `${item.id}`,
        modDate: `${date}`,
        modBy: `${item.lastModifiedBy}`,
        action: `${item.actionLabel}`,
        url: `${item.actionUrl}`,
        icon: iconLogic,
        bgCircle: iconBg,
        actionColor: "#007BB5",
      };

      data.push(dataObj);
    });
  }

  const getHomeData = useCallback(() => {
    GetHomeData()
      .then((res) => {
        setPendingData(res.data);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log("pendingData", pendingData);
  useEffect(() => {
    if (renderRef.current) return;
    renderRef.current = true;
    getHomeData();
  }, []);
  const isCEOView = useMemo(() => {
    return user.roles.includes("ceo");
  }, [user]);

  const isVisitorView = useMemo(() => {
    return user.roles.includes("visitor");
  }, [user]);

  const isInvestorView = useMemo(() => {
    return user.roles.includes("investor");
  }, [user]);

  const handleCurrencyChange = useCallback((currencyIndex) => {
    setSelectedCurrency(currencyIndex === 0 ? "_USD" : "_SAR");
  }, []);
  return (
    <div>
      <HStack justifyContent={"space-between"} alignItems={"baseline"}>
        <MainTitle title={"Summary - Year 2030"} />
        {isCEOView || isVisitorView || isInvestorView ? (
          <Tabs
            variant={"soft-rounded"}
            color={"gold-darker-color"}
            pb={8}
            defaultIndex={0}
            bg={"gold-darker-color"}
            onChange={handleCurrencyChange}
          >
            <TabList className={"homepage-kpi-tablist"}>
              <Tab
                className={"homepage-kpi-tab"}
                fontWeight={400}
                fontSize={14}
              >
                USD
              </Tab>
              <Tab
                className={"homepage-kpi-tab"}
                fontWeight={400}
                fontSize={14}
              >
                SAR
              </Tab>
            </TabList>
          </Tabs>
        ) : null}
      </HStack>
      <HStack
        spacing="auto"
        width="100%"
        flexDirection="row"
        flexWrap="wrap"
        gap={4}
        pb={8}
      >
        {!isCEOView && !isInvestorView && !isVisitorView ? (
          <SummaryBox
            title="Open Items"
            number={pendingData?.kpis?.openItems}
            boxBg="rgba(235, 192, 63, 0.5)"
            circleBg="#EBC03F"
            icon={document}
            isWhole={true}
            isLoading={isLoading}
          />
        ) : null}
        <SummaryBox
          title="Total Keys"
          number={pendingData?.kpis?.totalKeys}
          boxBg="#FCFCFC"
          circleBg="rgba(235, 192, 63, 0.25)"
          icon={key}
          border={true}
          isWhole={true}
          isLoading={isLoading}
        />
        <SummaryBox
          // title="OCCUPANCY RATE"
          title="Number of Hotels"
          number={pendingData?.kpis?.numberOfHotels}
          boxBg="#FCFCFC"
          circleBg="rgba(174, 159, 206, 0.35)"
          // circleBg="rgba(0, 123, 181, 0.2)"
          icon={buildings}
          border={true}
          // isPercent={true}
          isWhole={true}
          isLoading={isLoading}
        />
        <SummaryBox
          title={isCEOView && isVisitorView && isInvestorView ? "supply-based visitors" : "VISITOR NUMBERS"}
          number={pendingData?.kpis?.visitorNumber}
          boxBg="#FCFCFC"
          circleBg="rgba(235, 192, 63, 0.25)"
          icon={note}
          border={true}
          isWhole={true}
          isLoading={isLoading}
        />
        {isCEOView || isVisitorView || isInvestorView ? (
          <>
            <SummaryBox
              title="Total CAPEX"
              number={pendingData?.kpis?.["totalCapex" + selectedCurrency]}
              boxBg="#FCFCFC"
              circleBg="rgba(235, 192, 63, 0.5)"
              icon={document}
              border={true}
              isWhole={true}
              isLoading={isLoading}
            />
            <SummaryBox
              title="Occupancy"
              number={pendingData?.kpis?.occupancy}
              boxBg="#FCFCFC"
              circleBg="rgba(235, 192, 63, 0.5)"
              icon={document}
              border={true}
              isPercent={true}
              isLoading={isLoading}
            />
            <SummaryBox
              title="ADR"
              number={pendingData?.kpis?.["ADR" + selectedCurrency]}
              boxBg="#FCFCFC"
              circleBg="rgba(235, 192, 63, 0.5)"
              icon={document}
              border={true}
              isWhole={true}
              isLoading={isLoading}
            />

            <SummaryBox
              title="RevPAR"
              number={pendingData?.kpis?.["RevPAR" + selectedCurrency]}
              boxBg="#FCFCFC"
              circleBg="rgba(235, 192, 63, 0.5)"
              icon={document}
              border={true}
              isWhole={true}
              isLoading={isLoading}
            />
            <SummaryBox
              title="Annual Revenue"
              number={pendingData?.kpis?.["totalRevenue" + selectedCurrency]}
              boxBg="#FCFCFC"
              circleBg="rgba(235, 192, 63, 0.5)"
              icon={document}
              border={true}
              isWhole={true}
              isLoading={isLoading}
            />
          </>
        ) : null}
      </HStack>
      {isCEOView || isVisitorView || isInvestorView ? (
        <InfoCard activeRegions={pendingData?.kpis?.activeRegionLabels} />
      ) : null}
      {(user?.roles[0]?.toLowerCase() === "admin" ||
        user?.roles[0]?.toLowerCase() === "proponent") && (
          <>
            <MainTitle title="My pending actions" />

            {isLoading || pendingData?.pendingActions?.length <= 0 ? (
              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={noAction} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">No actions required</Text>
                    </Box>
                  </Center>
                </Box>
              </MainBox>
            ) : (
              <TablePagination
                data={data}
                columns={columns}
                isSearchable={false}
                title=""
              />
            )}
          </>
        )}
    </div>
  );
};
