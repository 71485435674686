// MainComponent.js
import { React, useState, useEffect } from 'react';

import { GetFolder, EditFolder, DeleteFolder } from "../../api/MarketReportApi";
import {
    Box, Text, Flex, useEditableControls,
    EditableInput,
    Editable,
    EditablePreview,
    Input, Menu,
    MenuButton,
    MenuList,
    MenuItem, Button, Image,
    useDisclosure,
    Center,
    Spinner
} from '@chakra-ui/react';
import folderIcon from "../../assets/images/icons/folder.svg"
import history from "../../history";
import threeDots from "../../assets/images/icons/threeDots.svg";
import remove from "../../assets/images/icons/delete2.svg";
import pen from "../../assets/images/icons/penBlue.svg";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import editRow from "../../assets/images/icons/editRow.svg";
import removeRow from "../../assets/images/icons/removeRow.svg";
import checked from "../../assets/images/icons/checked.svg";
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import doc from "../../assets/images/icons/doc.svg";
import FileModal from './createModal';
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import plus from "../../assets/images/icons/plus.svg";
import { MainBox } from "../../components/mainBox/MainBox";

const MasterFolders = () => {

    const [folders, setFolders] = useState([]);
    const [title, setTitle] = useState('');
    const [user, setUser] = useState();
    const [isModalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const whitelistedEmails = [
        'calogero.granatella@neom.com',
        'anjum.khan@neom.com',
        'dania.hamdan@neom.com',
        'muhannad.alomari@neom.com'
    ];
    const {
        isOpen: isRequestSucsessOpen,
        onOpen: onRequestSucsessOpen,
        onClose: onRequestSucsessClose,
    } = useDisclosure();

    const {
        isOpen: isErrorOpen,
        onOpen: onErrorOpen,
        onClose: onErrorClose,
    } = useDisclosure();

    // Fetch folders data on component mount
    useEffect(() => {
        const fetchFolders = async () => {
            try {
                // Fetch folder data
                const data = await GetFolder();

                // Set the folders state and update loading state
                setFolders(data);
                setIsLoading(false);
            } catch (error) {
                // Handle errors by updating loading state, displaying an error modal, and logging the error
                setIsLoading(false);
                onErrorOpen();
                console.error('Error fetching folder data:', error);
            }
        };

        // Call the fetchFolders function on component mount
        fetchFolders();
    }, []);

    // Decode and set user information from cookies on component mount
    useEffect(() => {
        // Check if user_info cookie exists and user state is not set
        if (Cookies.get("user_info") && !user) {
            try {
                // Retrieve token from cookie and decode it
                let token = Cookies.get("user_info");
                let tokenDecode = jwt_decode(token);

                // Set user state with decoded token information
                setUser(tokenDecode);
            } catch (error) {
                // Handle decoding errors by logging the error
                console.error('Error decoding user token:', error);
            }
        }
    }, [user]);  // Add the 'user' dependency to prevent unnecessary re-execution when 'user' changes



    const handleToggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    const handleFolderClick = (folderId, name) => {
        history.push(`/MarketReports/${folderId}/${name}`);
    };


    const EditableControls = (data) => {
        const folder = data.folder

        const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
            getEditButtonProps,
        } = useEditableControls();

        return isEditing ? (
            <Box display="flex" justifyContent="space-around" width="80px">
                <button {...getSubmitButtonProps()}>
                    <Image src={editRow} alt="edit" width="20px" />
                </button>
                <button {...getCancelButtonProps()}>
                    <Image src={removeRow} alt="edit" width="20px" />
                </button>
            </Box>
        ) : (
            <Box flex="1" display="flex" justifyContent="flex-end">
                <Menu autoSelect={false}>
                    <MenuButton
                        bgColor="transparent"
                        as={Button}
                        px={1}
                        ml={1}
                        minWidth="auto"
                        _hover={{ backgroundColor: "transparent" }}
                    >
                        <img src={threeDots} alt="dots icon" />
                    </MenuButton>
                    <MenuList >
                        <Text fontSize="12px" color="#6F767E" px="10px" mb="7px" textAlign='left'>
                            More actions
                        </Text>

                        <MenuItem alignItems="center">
                            <Box
                                display="flex"
                                onClick={() => {
                                    deleteItem(folder.id)
                                }}
                            >
                                <img src={remove} width="17px" alt="edit icon" />
                                <Text
                                    color="#003865"
                                    ml="5px"
                                    fontSize="12px"
                                    fontWeight="400"
                                >
                                    Remove Document
                                </Text>
                            </Box>
                        </MenuItem>


                        <MenuItem alignItems="center">
                            <button {...getEditButtonProps()}>
                                <Box display="flex">
                                    <img src={pen} width="13px" alt="edit icon" />
                                    <Text
                                        color="#003865"
                                        ml="5px"
                                        fontSize="12px"
                                        fontWeight="400"
                                    >
                                        Edit Document Name
                                    </Text>
                                </Box>
                            </button>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Box>

        );
    };

    const onEditModelName = (id, event, lastName) => {
        if (event !== "" && event !== lastName)
            EditFolder(id, { folderName: event })
                .then((response) => {
                    setTitle('Folder name has been changed successfully')
                    onRequestSucsessOpen()

                    const newArr = folders.map(folder => {
                        if (folder.id == id) {
                            folder.folderName = event;
                        }
                        return folder;
                    });

                    setFolders(newArr);
                })
                .catch((err) => {
                    const newArr = folders.map(folder => {
                        if (folder.id == id) {
                            folder.folderName = lastName;
                        }
                        return folder;
                    });
                    setFolders(newArr);
                    onErrorOpen()
                    console.error(err);

                });




    };


    const deleteItem = async (id, type) => {
        try {

            await DeleteFolder(id);
            const updatedFolders = folders.filter(folder => folder.id !== id);
            setFolders(updatedFolders);
            setTitle('Folder has been deleted successfully');
            onRequestSucsessOpen();

        } catch (error) {
            // Handle errors during deletion
            onErrorOpen();
            console.error('Error deleting item:', error);
        }
    };

    // Example usage:
    // deleteItem(folderId, 'folder');
    // deleteItem(fileId, 'file');


    const handleCreate = (isFolder, data, err) => {
        if (err) {
            onErrorOpen();
            return;
        }

        // If the user is creating a folder
        setFolders(prevFolders => [...prevFolders, data]);
        setTitle('Folder has been created successfully');
        onRequestSucsessOpen();
    };

    const listfolders = () => {
        return (<Flex flexWrap="wrap" gap={6}>
            {folders.map((folder) => (
                <Box key={folder.id} p={4} cursor="pointer" width="222px"> {/* Set a fixed width, adjust as needed */}
                    <Flex direction="column" alignItems="center" textAlign="center">
                        <img
                            src={folderIcon}
                            alt="report icon"
                            onClick={() => handleFolderClick(folder.id, folder.folderName)}
                        />
                        {user && whitelistedEmails.includes(user.email) ? (
                            <Editable
                                defaultValue={
                                    folder.folderName.length > 20
                                        ? `${folder.folderName.slice(0, 20)}...`
                                        : folder.folderName
                                }
                                // fontSize="12px"
                                width="222px"
                                display="flex"
                                flexDirection="row" // Change this line to "row"
                                alignItems="center"
                                justifyContent="center"
                                onSubmit={(value) => {
                                    onEditModelName(folder.id, value, folder.folderName);
                                }}
                            >
                                <EditablePreview />
                                <Flex alignItems="center">
                                    <Input fontSize='12px' as={EditableInput} borderRadius={0} textAlign="left" />
                                    <EditableControls folder={folder} type="folder" />
                                </Flex>
                            </Editable>

                        ) : (
                            <Text>{folder.folderName.length > 20
                                ? `${folder.folderName.slice(0, 20)}...`
                                : folder.folderName}</Text>
                        )}
                    </Flex>
                </Box>
            ))}
        </Flex>)
    }

    const folderCheck = (folder,) => {
        if (folders.length === 0)
            return (< Center flexDirection="column">
                <img src={doc} alt="icon" />
                <Box mt={3} mb={5}>
                    <Text className="title_sm">No document created yet</Text>
                </Box>
            </Center>)
        else {
            return listfolders();

        }
    }

    return (
        <>


            <Box display="flex" justifyContent="space-between">
                <MainTitle title={`Market Reports`} />
                {
                    user && whitelistedEmails.includes(user.email) && (


                        <Box textAlign='right' >
                            <MainButton
                                title="Create New Folder"
                                icon={plus}
                                btnHeight="48px"
                                onPress={() => { handleToggleModal() }
                                }
                            />
                        </Box>
                    )}
            </Box>

            <MainBox>
                {
                    isLoading ? (<Box width="100%" paddingY={10}>
                        <Center>
                            <Spinner />
                        </Center>
                    </Box>) : folderCheck()
                }
                {isModalOpen && (<FileModal isOpen={isModalOpen} onClose={handleToggleModal} handleCreate={handleCreate} />)}

                {isRequestSucsessOpen && (<ModalAlert
                    isOpen={isRequestSucsessOpen}
                    onClose={onRequestSucsessClose}
                    title={title}
                    icon={checked}
                    hasDesc={false}
                    confirmationModal={false}
                />)}

                {isErrorOpen && (<ModalAlert
                    isOpen={isErrorOpen}
                    onClose={onErrorClose}
                    title='Something went wrong'
                    icon={infoCircle}
                    hasDesc={false}
                    confirmationModal={false}
                />)}
            </MainBox>

        </>

    );
};

export default MasterFolders;
