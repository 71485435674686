import { React, useState, useEffect, useCallback } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Input,
  Flex,
  Box,
  Text,
  Tooltip,
  IconButton,
  Image,
  Switch,
  useDisclosure,
  Badge,
} from "@chakra-ui/react";
import { MainButton } from "../../components/button/MainButton";
import DataTable from "react-data-table-component";
import Preview from "./preview";
import { GetVersions, DownloadFile } from "../../api/AssetListAPI";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { useParams } from "react-router-dom";
// import { MainButton } from "../../components/button/MainButton";
import leftArrow3 from "../../assets/images/icons/leftArrow3.svg";
import {  } from "../../api/AssetListAPI";
import download from "../../assets/images/icons/downloadFile.svg";
import downloadReport from "../../assets/images/icons/downloadReport.svg";
import { EditDataModal } from "./addNew";
export const AssetTable = ({ projectName }) => {
  const [assetData, setAssetData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let { id, name } = useParams();
  const [rowData, setRowData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();

  const columns = [
    {
      name: "VERSION",
      selector: (row) => `Version ${row.versionNumber}`,
      sortable: true,
      sortField: "versionNumber",
      // cell: (row) => <Box color={`#007BB5`}>{row.versionNum}</Box>,
    },
    {
      name: "LAST MODIFIED",
      selector: (row) => row.lastModifiedBy,
      sortable: true,
      sortField: "lastModifiedBy",
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      sortable: true,
      sortField: "status",
      //   minWidth: "160px",
      //   width: "160px",
      cell: (row) => (
        <Flex justifyContent="center" alignItems="center">
          <Badge
            data-tag="allowRowEvents"
            data-action="edit"
            bg={
              row.status == "Production"
                ? "#006B44"
                : row.status == "In Planning"
                ? "#FFC000"
                : row.status == "Fin. Pending"
                ? "#462ebc"
                : "#A9A9A9"
            }
            color="#fff"
            fontSize="14px"
            fontWeight="300"
            px={3}
            py="1"
            textTransform="none"
            borderRadius="3px"
          >
            {row.status}
          </Badge>
        </Flex>
      ),
    },

    {
      name: "ACTION",
      selector: (row) => row.action,
      cell: (row) => {
        return (
          <Flex justifyContent="space-between" alignItems="center">
            <MainButton
              title="Preview"
              btnStyle="transparent"
              fontColor="dark"
              marginRight="5"
              onPress={() => {
                setRowData(row);
                onOpen();
              }}
              fSize="sm_x2"
            />

            {row.status !== "Archived" && row.status !== "Production" && (
              <MainButton
                title="/ Edit"
                btnStyle="transparent"
                fontColor="dark"
                marginRight="5"
                onPress={() => {
                  setRowData(row);

                  onOpenEdit();
                }}
                fSize="sm_x2"
              />
            )}
          </Flex>
        );
      },
    },
    {
      name: "Downloads",
      selector: (row) => row.id,
      cell: (row) => {
        return (
          row.status == "Production" && (
            <Flex gap="5px" justifyContent="space-between" alignItems="center">
              <Tooltip label="Download input file" hasArrow>
                <Box
                  cursor="pointer"
                  onClick={() => {
                    handleDownloadInput(row.id);
                  }}
                  as="img"
                  src={download}
                  alt="download input file"
                />
              </Tooltip>
              <Tooltip label="Download output report" hasArrow>
                <Box
                  cursor="pointer"
                  onClick={() => {
                    handleDownloadReport(row.id);
                  }}
                  as="img"
                  src={downloadReport}
                  alt="download output report"
                />
              </Tooltip>
            </Flex>
          )
        );
      },
    },
  ];
  const handleDownloadInput = useCallback((versionId) => {
    DownloadFile(versionId,'downloadInput')
      .then((response) => {
        try {
          const fileData = Uint8Array.from(response.data.fileBuffer.data);
          const content = new Blob([fileData.buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const encodedUri = window.URL.createObjectURL(content);
          const link = document.createElement("a");
          const filename = response.data.filename;
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", filename);

          link.click();
        } catch {
          throw new Error();
        }
      })
      .catch((e) => {
        // setIsErrorModalShown(true);
        console.log("Error downloading", e);
      })
      .finally(() => {
        // setIsLoaderShown(false);
      });
  });

  const handleDownloadReport = useCallback((versionId) => {
    // setIsLoaderShown(true);
    DownloadFile(versionId,"downloadReport")
      .then((response) => {
        try {
          const fileData = Uint8Array.from(response.data.fileBuffer.data);
          const content = new Blob([fileData.buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const encodedUri = window.URL.createObjectURL(content);
          const link = document.createElement("a");
          const filename = response.data.filename;
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", filename);

          link.click();
        } catch {
          throw new Error();
        }
      })
      .catch((e) => {
        // setIsErrorModalShown(true);
        console.log("Error downloading", e);
      })
      .finally(() => {
        // setIsLoaderShown(false);
      });
  }, []);

  const loadTableData = useCallback(() => {
    GetVersions(id)
      .then((res) => {
        setAssetData(res.data);
        // setIsLoading(false);
        // console.log("GET ASSET", res.data);
        //return res;
        //return res.data.pageRows[0].projectNameValue;
      })
      .catch((error) => {
        console.log("Error in fetching asset data", error);
        // onErrorOpen();
        // setErrorMessage("Error in fetching asset data");
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);
  console.log("datatatatat", assetData);
  return (
    <>
      <MainButton
        title="Back"
        btnStyle="transparent"
        fontColor="dark"
        marginRight="1"
        navLink={true}
        icon={leftArrow3}
        path={"/ListOfAssets"}
      />
      <MainTitle title={`${name}`} />

      <MainBox>
        <Box className="table_content" pt={3} pb={2} mb={5}>
          <DataTable
            columns={columns}
            data={assetData}
            //   onSort={true}
            defaultSortAsc={false}
          />
        </Box>
      </MainBox>
      {isOpen && <Preview isOpen={isOpen} onClose={onClose} data={rowData} />}

      {isOpenEdit && (
        <EditDataModal
          data={rowData}
          isNew={false}
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
        />
      )}
    </>
  );
};

export default AssetTable;
