import React, { useState, memo } from "react";

import { Box, Center, Text, Textarea, Flex } from "@chakra-ui/react";
import { MainButton } from "../button/MainButton";
import { CommentBox } from "../commentBox/CommentBox";
import user from "../../assets/images/icons/placeholder.svg";

export const RenderDescriptionBox = memo(function RenderDescriptionBox({
    description,
    isDisabled,
    handleSetDescription
}) {
    const [descriptionState, setDescriptionState] = useState(description);


    const handleInputChange = (e) => {

        setDescriptionState(e.target.value);
    }

    return (
        <Center>
            <Box
                maxW={["100%", "100%", "100%"]}
                width={["100%", "100%", "100%"]}
                alignSelf="flex-start"
            >
                <Textarea 
                    placeholder='Add description...' 
                    value={descriptionState}
                    isDisabled={isDisabled}
                    onChange={handleInputChange} // Enabled onChange event
                    size='sm' 
                />
            </Box>
            <Flex
                justifyContent="end"
                alignItems="center"
                width="100%"
            >
                <MainButton
                    title="Submit"
                    reverseRow={true}
                    onPress={() => {
                        handleSetDescription(descriptionState); // Uncommented this line to log description
                    }}
                />
            </Flex>
        </Center>
    );
});
