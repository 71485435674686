import React, { useState, useEffect } from "react";

import { AssetsListForm1 } from "./AssetsListForm1";
import { AssetsListForm2 } from "./AssetsListForm2";
import { AssetsListForm3 } from "./AssetsListForm3";
import { AssetsListForm4 } from "./AssetsListForm4";
import { AssetsListForm5 } from "./AssetsListForm5";
import { AssetsListForm6 } from "./AssetsListForm6";
import { AssetsListForm7 } from "./AssetsListForm7";
import { AssetsListForm8 } from "./AssetsListForm8";
import { AssetsListForm9 } from "./AssetsListForm9";
import { AssetsListForm10 } from "./AssetsListForm10";
import { AssetsListForm11 } from "./AssetsListForm11";
import { AssetsListForm2Proponent } from "./AssetListForm2Proponent";

import { AssetsListForm4Package } from "./AssetsListForm4Package";
import { AssetsListForm5Package } from "./AssetsListForm5Package";
import { AssetsListForm6Package } from "./AssetsListForm6Package";
import { AssetsListForm6External } from "./AssetsListForm6External";
import {AssetsListForm5FNB} from "./AssetsListForm5FNB";

export const AssetsListFormSteps = ({
  errors,
  touched,
  handleBlur,
  values,
  setFieldValue,
  handleChange,
  type,
  page,
  dropdownObj,
  nextPage,
  prevPage,
  onCancelOpen,
  onSubmitOpen,
  validateErrors,
  setValidateErrors,
  setPercentage,
  setStepCompleted,
  percentage,
  dismissSave,
  setDismissSave,
  setInitFormVal,
  initFormVal,
  handleOnSubmit,
  devStartDate,
  setDevStartDate,
  opStartDate,
  setOpStartDate,
  setStepWarning,
  setWarningIcon,
  warningIcon,
  unsetStepWarning,
  isPreview,
  steps,
  handleSaveDraft,
  onDraftOpen,
  disableSave,
  isRestore,
  handleMissingPages,
  missingPages,
  onMissingOpen,
  performanceAssetList2,
  brandedPerformance,
  packagePerformance,
  unitMixChanged,
  setUnitMixChanged,
  setPackageRooms,
  packageRooms,
  operatorLookup,
  setAssociatedDropdown,
  setBenchmarkDropdown,
  benchmarkDropdown,
  associatedDropdown,
  cityOptions,
  resortOptions,
  servicedOptions,
  brandDropdown,
  setBrandDropdown,
  occIncRate,
  setOccIncRate,
  assetData,
  isDisabledCalc,
  setIsDisabledCalc,
  // firstInitFormVal,
  // assetBaseYear,
  isDisabledTypes,
  setIsDisabledTypes, // assetBaseYear,
  // setAssetBaseYear,
  setStepperUpdate,
  stepperUpdate,
  reassign,
  validateStep,
  setValidateStep,
  onClickedFormulaOpen,
  user,
  id,
  handleProponentOnSubmit,
  unsetPageClicked,
  undoUnsetPageClicked,
  setUpdateDisableNextStep,
  updateDisableNextStep,
  modelWarningOpen,
  isModelWarningOpen,
  onClickedKeysOpen,
  setValidateKeys,
  validateKeys,
  closeModalPath,
  setSubmitLoading,
  isSubmitLoading,
  getUsers,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
  occIncRateGeneral,
  setOccIncRateGeneral,
  setIsCalculateClicked,
  isCalculateClicked,
}) => {
  const renderSteps = (
    errors,
    touched,
    handleBlur,
    values,
    setFieldValue,
    handleChange
  ) => {
    if (user === "admin" || user === "manager") {
      if (type === "Standard") {
        return (
          <>
            {page === 0 && (
              <AssetsListForm1
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                onCancelOpen={onCancelOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                devStartDate={devStartDate}
                setDevStartDate={setDevStartDate}
                opStartDate={opStartDate}
                setOpStartDate={setOpStartDate}
                warningIcon={warningIcon}
                setWarningIcon={setWarningIcon}
                setStepWarning={setStepWarning}
                isPreview={isPreview}
                operatorLookup={operatorLookup}
                setBrandDropdown={setBrandDropdown}
                brandDropdown={brandDropdown}
                unsetStepWarning={unsetStepWarning}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                user={user}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
              // assetBaseYear={assetBaseYear}
              // setAssetBaseYear={setAssetBaseYear}
              />
            )}
            {page === 1 && (
              <AssetsListForm2
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                devStartDate={devStartDate}
                setStepWarning={setStepWarning}
                unsetStepWarning={unsetStepWarning}
                setDevStartDate={setDevStartDate}
                step={steps[1]}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                id={id}
                user={user}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
              />
            )}
            {page === 2 && (
              <AssetsListForm3
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                setStepperUpdate={setStepperUpdate}
                setStepWarning={setStepWarning}
                unsetStepWarning={unsetStepWarning}
                stepperUpdate={stepperUpdate}
                id={id}
                user={user}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                setInitFormVal={setInitFormVal}
                closeModalPath={closeModalPath}
              />
            )}
            {page === 3 && (
              <AssetsListForm4
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                initFormVal={initFormVal}
                setInitFormVal={setInitFormVal}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                step={steps[3]}
                setStepWarning={setStepWarning}
                performanceRoomTypeLength={performanceAssetList2}
                nextStep={steps[4]}
                occIncRate={occIncRate}
                setOccIncRate={setOccIncRate}
                isDisabledCalc={isDisabledCalc}
                setIsDisabledCalc={setIsDisabledCalc}
                setIsDisabledTypes={setIsDisabledTypes}
                isDisabledTypes={isDisabledTypes}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                onClickedFormulaOpen={onClickedFormulaOpen}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                onClickedKeysOpen={onClickedKeysOpen}
                validateKeys={validateKeys}
                setValidateKeys={setValidateKeys}
                closeModalPath={closeModalPath}
                assetData={assetData}
                occIncRateGeneral={occIncRateGeneral}
                setOccIncRateGeneral={setOccIncRateGeneral}
                setIsCalculateClicked={setIsCalculateClicked}
                isCalculateClicked={isCalculateClicked}
                user={user}
              />
            )}
            {page === 4 && (
              <AssetsListForm5
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                initFormVal={initFormVal}
                setInitFormVal={setInitFormVal}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                setStepWarning={setStepWarning}
                step={steps[4]}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                user={user}
                unsetPageClicked={unsetPageClicked}
                undoUnsetPageClicked={undoUnsetPageClicked}
                setUpdateDisableNextStep={setUpdateDisableNextStep}
                updateDisableNextStep={updateDisableNextStep}
                type={type}               
                nextStep={steps[5]}
              />
            )}
            {page === 5 && (
              <AssetsListForm5FNB
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                initFormVal={initFormVal}
                setInitFormVal={setInitFormVal}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                setStepWarning={setStepWarning}
                step={steps[5]}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                user={user}
              />
            )}
            {page === 6 && (
              <AssetsListForm6External
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                initFormVal={initFormVal}
                setInitFormVal={setInitFormVal}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                setStepWarning={setStepWarning}
                step={steps[6]}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                user={user}
              />
            )}
            {page === 7 && (
              <AssetsListForm6
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                initFormVal={initFormVal}
                setInitFormVal={setInitFormVal}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                setBenchmarkDropdown={setBenchmarkDropdown}
                benchmarkDropdown={benchmarkDropdown}
                setAssociatedDropdown={setAssociatedDropdown}
                associatedDropdown={associatedDropdown}
                cityOptions={cityOptions}
                resortOptions={resortOptions}
                servicedOptions={servicedOptions}
                setStepperUpdate={setStepperUpdate}
                setStepWarning={setStepWarning}
                unsetStepWarning={unsetStepWarning}
                stepperUpdate={stepperUpdate}
                id={id}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                assetData={assetData}
                user={user}
              />
            )}

            {page === 8 && (
              <AssetsListForm7
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                setStepperUpdate={setStepperUpdate}
                setStepWarning={setStepWarning}
                unsetStepWarning={unsetStepWarning}
                stepperUpdate={stepperUpdate}
                id={id}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                user={user}
              />
            )}
            {page === 9 && (
              <AssetsListForm8
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                step={steps[9]}
                setStepperUpdate={setStepperUpdate}
                setStepWarning={setStepWarning}
                stepperUpdate={stepperUpdate}
                id={id}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                user={user}
              />
            )}
            {page === 10 && (
              <AssetsListForm9
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                setInitFormVal={setInitFormVal}
                initFormVal={initFormVal}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                step={steps[10]}
                setStepWarning={setStepWarning}
                brandedPerformanceLength={brandedPerformance}
                setUnitMixChanged={setUnitMixChanged}
                nextStep={steps[11]}
                setIsDisabledTypes={setIsDisabledTypes}
                isDisabledTypes={isDisabledTypes}
                type={type}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                unsetPageClicked={unsetPageClicked}
                undoUnsetPageClicked={undoUnsetPageClicked}
                setUpdateDisableNextStep={setUpdateDisableNextStep}
                updateDisableNextStep={updateDisableNextStep}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                user={user}
              />
            )}
            {page === 11 && (
              <AssetsListForm10
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                setInitFormVal={setInitFormVal}
                initFormVal={initFormVal}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                step={steps[11]}
                unitMixChanged={unitMixChanged}
                setStepperUpdate={setStepperUpdate}
                setStepWarning={setStepWarning}
                stepperUpdate={stepperUpdate}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                user={user}
              />
            )}
            {page === 12 && (
              <AssetsListForm11
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                handleOnSubmit={handleOnSubmit}
                steps={steps}
                handleMissingPages={handleMissingPages}
                missingPages={missingPages}
                onMissingOpen={onMissingOpen}
                assetData={assetData}
                initFormVal={initFormVal}
                //firstInitFormVal={firstInitFormVal}
                setStepperUpdate={setStepperUpdate}
                setStepWarning={setStepWarning}
                unsetStepWarning={unsetStepWarning}
                reassign={reassign}
                user={user}
                handleProponentOnSubmit={handleProponentOnSubmit}
                updateDisableNextStep={updateDisableNextStep}
                prevStep={steps[11]}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                setSubmitLoading={setSubmitLoading}
                isSubmitLoading={isSubmitLoading}
                getUsers={getUsers}
                setInitFormVal={setInitFormVal}
              />
            )}
          </>
        );
      } else {
        return (
          <>
            {page === 0 && (
              <AssetsListForm1
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                onCancelOpen={onCancelOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                devStartDate={devStartDate}
                setDevStartDate={setDevStartDate}
                opStartDate={opStartDate}
                setOpStartDate={setOpStartDate}
                warningIcon={warningIcon}
                setWarningIcon={setWarningIcon}
                setStepWarning={setStepWarning}
                operatorLookup={operatorLookup}
                setBrandDropdown={setBrandDropdown}
                brandDropdown={brandDropdown}
                unsetStepWarning={unsetStepWarning}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                user={user}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                setSubmitLoading={setSubmitLoading}
              // assetBaseYear={assetBaseYear}
              // setAssetBaseYear={setAssetBaseYear}
              />
            )}
            {page === 1 && (
              <AssetsListForm2
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                devStartDate={devStartDate}
                unsetStepWarning={unsetStepWarning}
                setStepWarning={setStepWarning}
                step={steps[1]}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                id={id}
                user={user}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
              // setDevStartDate={setDevStartDate}
              />
            )}
            {page === 2 && (
              <AssetsListForm3
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                setStepperUpdate={setStepperUpdate}
                setStepWarning={setStepWarning}
                unsetStepWarning={unsetStepWarning}
                stepperUpdate={stepperUpdate}
                id={id}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                setInitFormVal={setInitFormVal}
                closeModalPath={closeModalPath}
                user={user}
              />
            )}
            {page === 3 && (
              <AssetsListForm4Package
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                initFormVal={initFormVal}
                setInitFormVal={setInitFormVal}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                step={steps[3]}
                packagePerformance={packagePerformance}
                setStepWarning={setStepWarning}
                setPackageRooms={setPackageRooms}
                nextStep={steps[4]}
                occIncRate={occIncRate}
                setOccIncRate={setOccIncRate}
                isDisabledCalc={isDisabledCalc}
                setIsDisabledCalc={setIsDisabledCalc}
                setIsDisabledTypes={setIsDisabledTypes}
                isDisabledTypes={isDisabledTypes}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                onClickedFormulaOpen={onClickedFormulaOpen}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                validateKeys={validateKeys}
                setValidateKeys={setValidateKeys}
                onClickedKeysOpen={onClickedKeysOpen}
                closeModalPath={closeModalPath}
                assetData={assetData}
                occIncRateGeneral={occIncRateGeneral}
                setOccIncRateGeneral={setOccIncRateGeneral}
                setIsCalculateClicked={setIsCalculateClicked}
                isCalculateClicked={isCalculateClicked}
                user={user}
              // setOpStartDate={setOpStartDate}
              />
            )}

            {page === 4 && (
              <AssetsListForm5Package
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                initFormVal={initFormVal}
                setInitFormVal={setInitFormVal}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                setStepWarning={setStepWarning}
                step={steps[4]}
                packageRooms={packageRooms}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                user={user}
              // setOpStartDate={setOpStartDate}
              />
            )}

            {page === 5 && (
              <AssetsListForm6External
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                initFormVal={initFormVal}
                setInitFormVal={setInitFormVal}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                setStepWarning={setStepWarning}
                step={steps[5]}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                user={user}
              />
            )}
            {page === 6 && (
              <AssetsListForm6Package
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                initFormVal={initFormVal}
                setInitFormVal={setInitFormVal}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                setStepWarning={setStepWarning}
                step={steps[6]}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                user={user}
              />
            )}

            {page === 7 && (
              <AssetsListForm6
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                setBenchmarkDropdown={setBenchmarkDropdown}
                benchmarkDropdown={benchmarkDropdown}
                setAssociatedDropdown={setAssociatedDropdown}
                associatedDropdown={associatedDropdown}
                cityOptions={cityOptions}
                resortOptions={resortOptions}
                servicedOptions={servicedOptions}
                setStepperUpdate={setStepperUpdate}
                setStepWarning={setStepWarning}
                unsetStepWarning={unsetStepWarning}
                stepperUpdate={stepperUpdate}
                setInitFormVal={setInitFormVal}
                id={id}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                // setOpStartDate={setOpStartDate}
                assetData={assetData}
                user={user}
              />
            )}
            {page === 8 && (
              <AssetsListForm7
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                setStepperUpdate={setStepperUpdate}
                setStepWarning={setStepWarning}
                unsetStepWarning={unsetStepWarning}
                stepperUpdate={stepperUpdate}
                id={id}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                user={user}
              />
            )}
            {page === 9 && (
              <AssetsListForm8
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                setStepWarning={setStepWarning}
                step={steps[9]}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                id={id}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
              // setOpStartDate={setOpStartDate}
              user={user}
              />
            )}
            {page === 10 && (
              <AssetsListForm9
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                setInitFormVal={setInitFormVal}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                step={steps[10]}
                setStepWarning={setStepWarning}
                brandedPerformanceLength={brandedPerformance}
                setUnitMixChanged={setUnitMixChanged}
                nextStep={steps[11]}
                setIsDisabledTypes={setIsDisabledTypes}
                isDisabledTypes={isDisabledTypes}
                type={type}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                unsetPageClicked={unsetPageClicked}
                undoUnsetPageClicked={undoUnsetPageClicked}
                setUpdateDisableNextStep={setUpdateDisableNextStep}
                updateDisableNextStep={updateDisableNextStep}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                user={user}
              // setOpStartDate={setOpStartDate}
              />
            )}
            {page === 11 && (
              <AssetsListForm10
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                setInitFormVal={setInitFormVal}
                initFormVal={initFormVal}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                opStartDate={opStartDate}
                unsetStepWarning={unsetStepWarning}
                setOpStartDate={setOpStartDate}
                step={steps[11]}
                unitMixChanged={unitMixChanged}
                setStepperUpdate={setStepperUpdate}
                setStepWarning={setStepWarning}
                stepperUpdate={stepperUpdate}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                user={user}
              />
            )}
            {page === 12 && (
              <AssetsListForm11
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore || user === "manager"}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                handleOnSubmit={handleOnSubmit}
                steps={steps}
                handleMissingPages={handleMissingPages}
                missingPages={missingPages}
                onMissingOpen={onMissingOpen}
                assetData={assetData}
                initFormVal={initFormVal}
                //firstInitFormVal={firstInitFormVal}
                setStepperUpdate={setStepperUpdate}
                setStepWarning={setStepWarning}
                unsetStepWarning={unsetStepWarning}
                reassign={reassign}
                user={user}
                handleProponentOnSubmit={handleProponentOnSubmit}
                updateDisableNextStep={updateDisableNextStep}
                prevStep={steps[11]}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                setSubmitLoading={setSubmitLoading}
                isSubmitLoading={isSubmitLoading}
                getUsers={getUsers}
                setInitFormVal={setInitFormVal}
              />
            )}
          </>
        );
      }
    } else if (user === "proponent") {
      if (type === "Standard") {
        return (
          <>
            {page === 0 && (
              <AssetsListForm1
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                onCancelOpen={onCancelOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                devStartDate={devStartDate}
                setDevStartDate={setDevStartDate}
                opStartDate={opStartDate}
                setOpStartDate={setOpStartDate}
                warningIcon={warningIcon}
                setWarningIcon={setWarningIcon}
                setStepWarning={setStepWarning}
                isPreview={isPreview}
                operatorLookup={operatorLookup}
                setBrandDropdown={setBrandDropdown}
                brandDropdown={brandDropdown}
                unsetStepWarning={unsetStepWarning}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                // assetBaseYear={assetBaseYear}
                // setAssetBaseYear={setAssetBaseYear}
                user={user}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
              />
            )}
            {page === 1 && (
              <AssetsListForm2Proponent
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                devStartDate={devStartDate}
                setStepWarning={setStepWarning}
                unsetStepWarning={unsetStepWarning}
                setDevStartDate={setDevStartDate}
                step={steps[1]}
                nextStep={steps[2]}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                user={user}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
              />
            )}
            {page === 2 && (
              <AssetsListForm11
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                handleOnSubmit={handleOnSubmit}
                steps={steps}
                handleMissingPages={handleMissingPages}
                missingPages={missingPages}
                onMissingOpen={onMissingOpen}
                assetData={assetData}
                initFormVal={initFormVal}
                //firstInitFormVal={firstInitFormVal}
                setStepperUpdate={setStepperUpdate}
                setStepWarning={setStepWarning}
                unsetStepWarning={unsetStepWarning}
                reassign={reassign}
                user={user}
                handleProponentOnSubmit={handleProponentOnSubmit}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                setSubmitLoading={setSubmitLoading}
                isSubmitLoading={isSubmitLoading}
                setInitFormVal={setInitFormVal}
              />
            )}
          </>
        );
      } else {
        return (
          <>
            {page === 0 && (
              <AssetsListForm1
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                onCancelOpen={onCancelOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                devStartDate={devStartDate}
                setDevStartDate={setDevStartDate}
                opStartDate={opStartDate}
                setOpStartDate={setOpStartDate}
                warningIcon={warningIcon}
                setWarningIcon={setWarningIcon}
                setStepWarning={setStepWarning}
                operatorLookup={operatorLookup}
                setBrandDropdown={setBrandDropdown}
                brandDropdown={brandDropdown}
                unsetStepWarning={unsetStepWarning}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                user={user}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
              // assetBaseYear={assetBaseYear}
              // setAssetBaseYear={setAssetBaseYear}
              />
            )}
            {page === 1 && (
              <AssetsListForm2Proponent
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                devStartDate={devStartDate}
                unsetStepWarning={unsetStepWarning}
                setStepWarning={setStepWarning}
                step={steps[1]}
                setStepperUpdate={setStepperUpdate}
                stepperUpdate={stepperUpdate}
                user={user}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                nextStep={steps[2]}
                closeModalPath={closeModalPath}

              // setDevStartDate={setDevStartDate}
              />
            )}
            {page === 2 && (
              <AssetsListForm11
                dropdownObj={dropdownObj}
                nextPage={nextPage}
                prevPage={prevPage}
                onCancelOpen={onCancelOpen}
                onOpenModal={onSubmitOpen}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                validateErrors={validateErrors}
                setValidateErrors={setValidateErrors}
                formikValues={values}
                setPercentage={setPercentage}
                setStepCompleted={setStepCompleted}
                page={page}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
                setFieldTouched={setFieldTouched}
                validateStep={validateStep}
                setValidateStep={setValidateStep}
                handleSaveDraft={handleSaveDraft}
                onDraftOpen={onDraftOpen}
                isRestore={isRestore}
                disableSave={disableSave}
                percentage={percentage}
                dismissSave={dismissSave}
                setDismissSave={setDismissSave}
                handleOnSubmit={handleOnSubmit}
                steps={steps}
                handleMissingPages={handleMissingPages}
                missingPages={missingPages}
                onMissingOpen={onMissingOpen}
                assetData={assetData}
                initFormVal={initFormVal}
                //firstInitFormVal={firstInitFormVal}
                setStepperUpdate={setStepperUpdate}
                setStepWarning={setStepWarning}
                unsetStepWarning={unsetStepWarning}
                reassign={reassign}
                user={user}
                handleProponentOnSubmit={handleProponentOnSubmit}
                isModelWarningOpen={isModelWarningOpen}
                modelWarningOpen={modelWarningOpen}
                closeModalPath={closeModalPath}
                setSubmitLoading={setSubmitLoading}
                isSubmitLoading={isSubmitLoading}
              />
            )}
          </>
        );
      }
    }
  };
  //console.log("validation", page, validationAssetsFormPackage[page]);
  return (
    <>
      {renderSteps(
        errors,
        touched,
        handleBlur,
        values,
        setFieldValue,
        handleChange
      )}
    </>
  );
};
