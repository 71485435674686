import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { Box, HStack, Image, Switch, Text, useToast } from "@chakra-ui/react";
import { ToastAlert } from "../toastAlert/ToastAlert";
import edit from "../../assets/images/icons/editPen.svg";
import removeMain from "../../assets/images/icons/removeMain.svg";
import editRow from "../../assets/images/icons/editRow.svg";
import removeRow from "../../assets/images/icons/removeRow.svg";
import infoCircle from "../../assets/images/icons/infosm.svg";

import Select, { components } from "react-select";
import arrow from "../../assets/images/icons/Cheveron.svg";
import iconArrow from "../../assets/images/icons/Cheveron.svg";
import errIcon from "../../assets/images/icons/errsm.svg";
import styles from "../forms.module.scss";

import {
  assetCategoryCols,
  contractDataCols,
  operatorCols,
  packageTypeCols,
  projectStageCols,
  regionDataCols,
  roomCategoryCols,
} from "../../pages/masterdata/AllMasterData";
import { projectStatusCols } from "../../pages/masterdata/AllMasterData";

let oldVal = "";
let rowNum = 0;
let cellVal = "";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#EFEFEF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input
        type="checkbox"
        checked={isSelected}
        //defaultChecked={isSelected}
        style={{
          width: "16px",
          marginRight: "10px",
          paddingLeft: "30px",
          backgroundColor: "red",
        }}
      />
      {children}
    </components.Option>
  );
};

export const MasterDataTable = ({
  data,
  setDisableSave,
  setData,
  title,
  isDraggable,
  type,
  //currentDate,
  disablePagination,
  onEditRecord,
  onRemoveRecord,
  onReorderRecords,
  newToken,
  dropdown,
  isMulti,
  isReadOnly,
}) => {
  const toast = useToast();
  const gridRef = useRef();
  const gridRefWrapper = useRef();
  const [tableData, setTableData] = useState(data);
  const [showTable, setShowTable] = useState(false);
  /* const [isDropdownEmpty, setIsDropdownEmpty] = useState(); */
  const [errorMessage, setErrorMessage] = useState(
    type === "Positioning"
      ? "Associated Benchmark Report is required."
      : type === "Brand"
      ? "Operator is required."
      : type === "Room type"
      ? "Room Category is required."
      : "required"
  );
  const [columnDefs, setColumnDefs] = useState([]);
  // const [toggleMenu, setToggleMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  let typeSingular =
    type[`${type}`.length - 1].toLowerCase() === "s"
      ? type.slice(0, -1).toLowerCase()
      : type.toLowerCase();
  const benchmarkOptions = [
    { value: "City_benchmarks", label: "City benchmarks" },
    { value: "Resort_benchmarks", label: "Resort benchmarks" },
    { value: "Serviced_Apartments", label: "Serviced Apartments" },
  ];

  // console.log("setSelectedF", selectedValue);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#efefef" : "#efefef",
      boxShadow: "none",
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#efefef" : "#efefef",
      },
    }),
    option: (provided, state) => ({
      ...provided,

      background: state.isSelected ? "#EFEFEF" : "#FFFFFF",
      color: "black",
      minHeight: "20px",
      padding: "0px 5px",
      fontSize: "11px",
      margin: "0px",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        background: "#EFEFEF",
      },
    }),
  };

  //pagination
  // const [pages, setPages] = useState([]);
  // const [disableNext, setDisableNext] = useState(false);
  // const [disablePrev, setDisablePrev] = useState(false);
  // const [pageToShow, setPageToShow] = useState(1);
  // const [leftLeftSide, setLeftLeftSide] = useState(0);
  // const [leftRightSide, setLeftRightSide] = useState(3);
  // const temp = [];
  // const [isTextDisabled, setIsTextDisabled] = useState(true);
  // let pageSize = 10;
  // let totalRows = Math.ceil(tableData.length / pageSize);
  // console.log("sadsadd", cellVal);
  if (
    cellVal === "category" ||
    cellVal === "brand" ||
    cellVal === "roomType" ||
    cellVal === "regionName"
  ) {
    if (
      gridRefWrapper.current?.querySelector(
        ".ag-root-wrapper-body.ag-layout-normal"
      )
    ) {
      // console.log(
      //   "positioning",
      //   gridRefWrapper.current?.querySelector(
      //     ".ag-root-wrapper-body.ag-layout-normal"
      //   )
      // );
      if (
        gridRefWrapper.current.querySelector(
          ".ag-root-wrapper-body.ag-layout-normal"
        ).clientHeight > 90
      ) {
        let agConHeight = parseFloat(
          gridRefWrapper.current.querySelector(".ag-center-cols-container")
            .clientHeight
        );
        // console.log("access2", agConHeight, agConHeight + 200);
        gridRefWrapper.current.querySelector(
          ".ag-root-wrapper-body.ag-layout-normal"
        ).style.height = `${agConHeight + 200}px`;
      }
    }
  } /* else {
    if (
      gridRefWrapper.current?.querySelector(
        ".ag-root-wrapper-body.ag-layout-normal"
      )
    ) {
      if (
        gridRefWrapper.current.querySelector(
          ".ag-root-wrapper-body.ag-layout-normal"
        ).clientHeight < 220
      ) {
        gridRefWrapper.current.querySelector(
          ".ag-root-wrapper-body.ag-layout-normal"
        ).style.height = `${
          gridRefWrapper.current.querySelector(".ag-center-cols-container")
            .clientHeight * 3
        }px`;
      }
    }
  } */
  let mainTable = gridRefWrapper?.current?.querySelector(
    ".ag-center-cols-container"
  )?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement
    ?.parentElement?.parentElement?.parentElement?.parentElement;
  // console.log("mainTable", mainTable);
  /* if (
    mainTable?.classList?.contains("masterData_table") &&
    mainTable?.clientHeight > 400
  ) {
    mainTable.classList.add("active");
  } else  */
  if (
    mainTable?.classList?.contains("masterData_table_custom") /* &&
    mainTable?.clientHeight > 400 */
  ) {
    mainTable.classList.add("active");
  }
  // const handlePageChange = (event, page) => {
  //   setPageToShow(page);
  // };

  // const handleSave = () => {
  //   setDisableSave(false);
  // };
  // const handleNext = () => {
  //   if (pageToShow !== totalRows) setPageToShow(pageToShow + 1);
  // };
  // const handlePrev = () => {
  //   if (pageToShow !== 1) setPageToShow(pageToShow - 1);
  // };

  const selectRef = useRef();
  // const handleFocus = () => {
  //   selectRef.current.focus();
  // };
  const closeToast = () => {
    toast.closeAll();
  };
  const onCellClicked = (params) => {
    const handleClickOutside = (event) => {
      if (
        event.target.classList.contains("customBtn") === false &&
        event.target.classList.contains("ag-input-field-input") === false &&
        !!event.target?.src?.includes("Cheveron") === false &&
        !!event.target?.class?.includes("multiValue") === false &&
        !!event.target?.class?.includes("option") === false &&
        !!event.target?.id?.includes("option") === false &&
        !!event.target?.ariaLabel?.includes("Remove") === false &&
        !!event.target?.class?.includes("Input") === false &&
        !!event.target?.classList[0]?.includes("Input") === false &&
        !!event.target?.classList[0]?.includes("MenuList") === false &&
        !!event.target?.classList[0]?.includes("ValueContainer") === false &&
        !!event.target?.classList[0]?.includes("multiValue") === false &&
        !!event.target?.type?.includes("checkbox") === false &&
        !!event.target?.classList[0]?.includes("MenuList") === false &&
        !!event.target?.classList?.contains("cancel") === false &&
        !!event.target?.classList[0]?.includes("control") === false
      ) {
        if (event.target.classList.contains("update") === false) {
          undo();
          onRowEditingStopped(params);
          params.api.stopEditing(true);
          document.removeEventListener("click", handleClickOutside);
          setTimeout(() => {
            document.querySelector(".customBtn")?.click();
          }, 100);
        } else {
          setTimeout(() => {
            document.querySelector(".customBtn")?.click();
          }, 100);
          document.removeEventListener("click", handleClickOutside);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Handle click event for action cells

    if (params.column.colId === "isActive") {
      let newData = [];

      data.forEach((item, index) => {
        newData.push(item);
      });

      const updateRow = data.filter((obj) => {
        return newData;
      });
      gridRef.current.api.applyTransaction({
        update: updateRow,
      });

      setData(updateRow);
    }

    if (
      params.column.colId === "action" &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;
      let fieldEdit;

      if (action === "edit") {
        /* undo();
         */

        setTimeout(() => {
          onBtStartEditing(params.rowIndex, undefined);
          //onRowEditingStopped(params);
          onRowEditingStarted(params);
        }, 10);
      }

      if (action === "delete") {
        params.api.stopEditing(true);
        setDisableSave(false);

        let newData = [];

        data.forEach((item, index) => {
          newData.push(item);
        });

        const filtered = data.filter((obj) => {
          return newData[params.rowIndex] !== obj;
        });

        const filterDeleted = data.filter((obj) => {
          return newData[params.rowIndex] === obj;
        });
        gridRef.current.api.applyTransaction({
          remove: filterDeleted,
        });
        onRemoveRecord(filterDeleted[0]);
        setTimeout(() => {
          setData(filtered);
        }, 400);
      }

      if (action === "update") {
        // let format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        let format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

        if (format.test(params.data[cellVal])) {
          gridRef.current.api.forEachNode((rowNode, index) => {
            gridRef.current.props.rowData[rowNum][cellVal] = oldVal;
            if (rowNum === rowNode.rowIndex) {
              rowNode.data[`${cellVal}`] = oldVal;
            }
          });

          onBtStartEditing(params.rowIndex, undefined);
          onRowEditingStarted(params);
          toast({
            position: "top-right",
            isClosable: true,
            duration: 5000,
            render: () => (
              <ToastAlert
                title="You shouldn't have any special character."
                icon={infoCircle}
                closeToast={closeToast}
                type="Warning"
              />
            ),
          });
        } else {
          let namesArray = [];
          if (type == "Positioning") {
            namesArray = params.data.associatedBenchmarkReport;
          }
          if (type == "Room type") {
            namesArray = params.data.roomCategory;
          }
          if (type == "Brand") {
            namesArray = params.data.operator;
          }
          if (type == "Regions") {
            namesArray = params.data.GISRegions;
          }

          if (selectedValue) {
            if (type == "Brand" || type == "Room type") {
              namesArray = selectedValue.label;
            } else {
              namesArray = selectedValue.map((item) => item.label);
            }
          }
          if (type == "Room type") {
            params.data.roomCategory = namesArray;
          }

          if (type == "Brand") {
            params.data.operator = namesArray;
          }
          // console.log(
          //   "Array.isArray(selectedValue)",
          //   Array.isArray(selectedValue)
          // );

          // console.log("type", type);
          if (
            Array.isArray(selectedValue) &&
            selectedValue.length == 0 &&
            type !== "Positioning" &&
            type !== "Regions"
          ) {
            namesArray = [];
            setSelectedValue(undefined);
            toast({
              position: "top-right",
              isClosable: true,
              duration: 5000,
              render: () => (
                <ToastAlert
                  title="Associated Benchmark Report is required, you must select at least one item."
                  icon={errIcon}
                  closeToast={closeToast}
                  type="Error"
                />
              ),
            });
          } else {
            params.data.lastModifiedAt = "-";
            params.data.lastModifiedBy = `${newToken.displayName}`;
            if (type == "Positioning") {
              params.data.associatedBenchmarkReport = namesArray;
            } else if (type == "Regions") {
              // console.log(
              //   "params.data",
              //   params?.data?.GISRegions,
              //   dropdown,
              //   namesArray
              // );
              const filterData = dropdown.filter(
                (el) =>
                  namesArray?.includes(`${el?.label}`) ||
                  namesArray?.includes(`${el?.value}`)
              );
              let mappedFilterData = filterData.map((el) => el.value);
              // console.log("filterData", mappedFilterData);
              // params.data.GISRegions = namesArray;
              params.data.GISRegions = mappedFilterData;
              // params.data.isActive = namesArray;
            }
            // console.log("params.data", params.data);
            onEditRecord(params.data);

            let newData = [];

            data.forEach((item, index) => {
              newData.push(item);
            });
            const tempData = [...tableData];
            tempData.splice(params.rowIndex, 1);
            const found = tempData.find(
              (el) => el[cellVal] === newData[params.rowIndex][cellVal]
            );
            if (found === undefined) {
              closeToast();
              setDisableSave(false);
              onRowEditingStopped(params);
              params.api.stopEditing(false);
              namesArray = [];
              setSelectedValue("");
            } else {
              //found another one with the same name
              toast({
                position: "top-right",
                isClosable: true,
                render: () => (
                  <ToastAlert
                    title={`Another ${typeSingular} with the same name exists.`}
                    icon={infoCircle}
                    closeToast={closeToast}
                    type="Warning"
                  />
                ),
              });

              gridRef.current.api.forEachNode((rowNode, index) => {
                gridRef.current.props.rowData[rowNum][cellVal] = oldVal;
                if (rowNum === rowNode.rowIndex) {
                  rowNode.data[`${cellVal}`] = oldVal;
                }
              });

              onBtStartEditing(params.rowIndex, undefined);
              onRowEditingStarted(params);
            }
            gridRef.current.api.forEachNode((rowNode, index) => {
              if (
                gridRef.current.props.rowData[rowNum][cellVal].trim() === ""
              ) {
                gridRef.current.props.rowData[rowNum][cellVal] = oldVal;
                if (rowNum === rowNode.rowIndex) {
                  rowNode.data[`${cellVal}`] = oldVal;
                }
              }
            });
            const updateRow = data.filter((obj) => {
              return newData[params.rowIndex] === obj;
            });

            gridRef.current.api.applyTransaction({
              useCallback: updateRow,
            });
            setSelectedValue("");
            namesArray = [];
          }
        }
      }

      if (action === "cancel") {
        gridRef.current.api.forEachNode((rowNode, index) => {
          gridRef.current.props.rowData[rowNum][cellVal] = oldVal;

          if (rowNum === rowNode.rowIndex) {
            rowNode.data[`${cellVal}`] = oldVal;
          }
        });
        params.api.refreshCells({
          columns: [cellVal],
          rowNodes: [params.node],
          force: true,
        });
        undo();
        params.api.stopEditing(true);
      }
    }
  };

  // <Switch
  //         disabled={isDisabled}
  //         className="chakra-switch2"
  //         // marginLeft="30px"
  //         isChecked={params.node.data.isActive == "Inactive" ? false : true}
  //         onChange={() => {
  //           params.node.data.isActive === "Inactive"
  //             ? (params.node.data.isActive = "active")
  //             : (params.node.data.isActive = "Incancelactive");
  //           setDisableSave(false);
  //           onEditRecord(params.node.data);
  //         }}
  //         size={"md"}
  //       ></Switch>
  const activeCellRenderer = (isDisabled) => (params) => {
    // console.log("activeCell", params.node.data.isActive);
    if (params.node.data.isActive === "active") {
      return (
        <Switch
          disabled={isDisabled}
          className="chakra-switch2"
          marginLeft={isReadOnly ? "30px" : "0px"}
          isChecked={params.node.data.isActive == "Inactive" ? false : true}
          onChange={() => {
            params.node.data.isActive === "Inactive"
              ? (params.node.data.isActive = "active")
              : (params.node.data.isActive = "Inactive");
            setDisableSave(false);
            onEditRecord(params.node.data);
          }}
          size={"md"}
        ></Switch>
        // <Switch
        //   disabled={isDisabled}
        //   className="chakra-switch2"
        //   isChecked={params.node.data.isActive == "Inactive" ? false : true}
        //   onChange={() => {
        //     params.node.data.isActive === "Inactive"
        //       ? (params.node.data.isActive = "active")
        //       : (params.node.data.isActive = "Incancelactive");
        //     setDisableSave(false);
        //     onEditRecord(params.node.data);
        //   }}
        //   size={"md"}
        // ></Switch>
      );
    } else {
      return (
        <Switch
          disabled={isDisabled}
          className="chakra-switch2"
          isChecked={params.node.data.isActive == "Inactive" ? false : true}
          onChange={() => {
            params.node.data.isActive === "Inactive"
              ? (params.node.data.isActive = "active")
              : (params.node.data.isActive = "Inactive");
            setDisableSave(false);
            onEditRecord(params.node.data);
          }}
          size={"md"}
        ></Switch>
      );
    }
  };

  const associatedCellRenderer = (params, isCurr) => {
    let editingCells = params.api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
      return cell.rowIndex === params.node.rowIndex;
    });

    const selectedFromApi = params?.value?.map((data) => {
      return {
        value: data
          ?.replace(/\s*,\s*/g, ",")
          ?.split(" ")
          ?.join("_")
          ?.replace(",", ", "),
        label: data,
      };
    });

    let inputProps;
    let dropdownVal;

    if (params?.value) {
      if (benchmarkOptions.length > 0) {
        dropdownVal = benchmarkOptions.find(
          (benchmarkOptions) => benchmarkOptions.value === params.value
        );
      }

      inputProps = {
        value: dropdownVal,
      };
    }

    if (!isCurrentRowEditing) {
      return (
        <>
          <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {params.value.join(",")}
          </Box>
        </>
      );
    } else {
      return (
        <div className="AADD select_undisabled">
          <Box
            className={`${styles.form_control_select_table} ${styles.form_control_select_table_focus}  ADD`}
          >
            <Select
              components={{
                ...(isMulti && { Option: InputOption }),
                DropdownIndicator: () => {
                  return iconArrow ? (
                    <img src={arrow} style={{ marginRight: "5px" }} />
                  ) : null;
                },
              }}
              defaultValue={selectedFromApi}
              isMulti={isMulti}
              isSearchable={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              name="associatedBenchmarks"
              isDisabled={false}
              isClearable={false}
              onChange={setSelectedValue}
              options={benchmarkOptions}
              openMenuOnFocus={true}
              ref={selectRef}
              {...inputProps}
              key={`my_unique_sele${params?.valueFormatted
                ?.split(" ")
                ?.join("_")}`}
              styles={customStyles}
            />
          </Box>
        </div>
      );
    }
  };

  // console.log("setSelectedF",selectedValue)
  const gisRegionsCellRenderer = (params, isCurr) => {
    // return <Text>ad</Text>;
    // console.log("params,", params);
    let editingCells = params.api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
      return cell.rowIndex === params.node.rowIndex;
    });

    const selectedFromApi = params?.value?.map((data) => {
      let findInd = dropdown.findIndex((el, ind) => {
        return data == el.value;
      });
      // if (findInd != -1) {
      //   console.log("findInd", dropdown[findInd]);
      //   return {
      //     value: data
      //       ?.replace(/\s*,\s*/g, ",")
      //       ?.split(" ")
      //       ?.join("_")
      //       ?.replace(",", ", "),
      //     label: dropdown[findInd].label,
      //     // label: data,
      //   };
      // } else {
      return {
        value: data
          ?.replace(/\s*,\s*/g, ",")
          ?.split(" ")
          ?.join("_")
          ?.replace(",", ", "),
        label: dropdown[findInd]?.label,
        // label: data,
      };
      // }
    });

    let inputProps;
    let dropdownVal;

    if (params?.value) {
      if (benchmarkOptions.length > 0) {
        dropdownVal = benchmarkOptions.find(
          (benchmarkOptions) => benchmarkOptions.value === params.value
        );
      }

      inputProps = {
        value: dropdownVal,
      };
    }

    let dropdownFilter = dropdown.filter((el, ind) =>
      params.value.includes(el.value)
    );
    // if (dropdownFilter.length == 0) {
    //   dropdownFilter = dropdown.filter((el, ind) =>
    //     params.value.includes(el.label)
    //   );
    // }
    // console.log("params.value", params.value, dropdown);
    let formatDropdownFilter = dropdownFilter.map((el) => el.label);
    // console.log("dropdownFilter", dropdownFilter);
    // console.log("params region", params);
    if (!isCurrentRowEditing) {
      return (
        <>
          <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {formatDropdownFilter.join(", ")}
            {/* {params.value.join(",")} */}
          </Box>
        </>
      );
    } else {
      return (
        <div className="AADD select_undisabled">
          <Box
            className={`${styles.form_control_select_table} ${styles.form_control_select_table_focus}  ADD`}
          >
            <Select
              components={{
                ...(isMulti && { Option: InputOption }),
                DropdownIndicator: () => {
                  return iconArrow ? (
                    <img src={arrow} style={{ marginRight: "5px" }} />
                  ) : null;
                },
              }}
              // defaultValue={dropdownFilter}
              defaultValue={selectedFromApi}
              isMulti={isMulti}
              isSearchable={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              name="associatedBenchmarks"
              isDisabled={false}
              isClearable={false}
              onChange={setSelectedValue}
              options={dropdown}
              // options={benchmarkOptions}
              openMenuOnFocus={true}
              ref={selectRef}
              {...inputProps}
              key={`my_unique_sele${params?.valueFormatted
                ?.split(" ")
                ?.join("_")}`}
              styles={customStyles}
            />
          </Box>
        </div>
      );
    }
  };

  const brandCellRenderer = (params) => {
    let editingCells = params.api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
      return cell.rowIndex === params.node.rowIndex;
    });

    let selectedFromApi;
    if (Array.isArray(params.value)) {
      selectedFromApi = params.value.map((data) => {
        return {
          value: data
            .replace(/\s*,\s*/g, ",")
            .split(" ")
            .join("_")
            .replace(",", ", "),
          label: data.replace("_", " "),
        };
      });
    } else {
      selectedFromApi = {
        value: params.value
          .replace(/\s*,\s*/g, ",")
          .split(" ")
          .join("_")
          .replace(",", ", "),
        label: params.value.replace("_", " "),
      };
    }

    let inputProps;
    let dropdownVal;

    if (params.value) {
      if (dropdown.length > 0) {
        dropdownVal = dropdown.find(
          (dropdown) => dropdown.value === params.value
        );
      }

      inputProps = {
        value: dropdownVal,
      };
    }

    if (!isCurrentRowEditing) {
      return (
        <Box
          className={`${styles.form_control_select_table}  ADD select_disabled`}
        >
          <Select
            components={{
              ...(isMulti && { Option: InputOption }),
              DropdownIndicator: () => {
                return iconArrow ? (
                  <img src={arrow} style={{ marginRight: "5px" }} />
                ) : null;
              },
            }}
            isSearchable={false}
            defaultValue={selectedFromApi}
            isMulti={isMulti}
            name="brand"
            isDisabled={true}
            onChange={setSelectedValue}
            options={dropdown}
            openMenuOnFocus={true}
            ref={selectRef}
            {...inputProps}
            key={`my_unique_sele${params?.valueFormatted
              ?.split(" ")
              ?.join("_")}`}
            styles={customStyles}
          />
        </Box>
      );
    } else {
      return (
        <div className="AADD select_undisabled">
          <Box
            className={`${styles.form_control_select_table} ${styles.form_control_select_table_focus}  ADD`}
          >
            <Select
              components={{
                ...(isMulti && { Option: InputOption }),
                DropdownIndicator: () => {
                  return iconArrow ? (
                    <img src={arrow} style={{ marginRight: "5px" }} />
                  ) : null;
                },
              }}
              defaultValue={selectedFromApi}
              isMulti={isMulti}
              isSearchable={false}
              name="brand"
              isDisabled={false}
              onChange={setSelectedValue}
              options={dropdown}
              openMenuOnFocus={true}
              ref={selectRef}
              {...inputProps}
              key={`my_unique_sele${params?.valueFormatted
                ?.split(" ")
                ?.join("_")}`}
              styles={customStyles}
            />
          </Box>
        </div>
      );
    }
  };
  const roomCatCellRenderer = (params) => {
    let editingCells = params.api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
      return cell.rowIndex === params.node.rowIndex;
    });

    let selectedFromApi;
    if (Array.isArray(params.value)) {
      selectedFromApi = params.value.map((data) => {
        return {
          value: data
            .replace(/\s*,\s*/g, ",")
            .split(" ")
            .join("_")
            .replace(",", ", "),
          label: data.replace("_", " "),
        };
      });
    } else {
      selectedFromApi = {
        value: params.value
          .replace(/\s*,\s*/g, ",")
          .split(" ")
          .join("_")
          .replace(",", ", "),
        label: params.value.replace("_", " "),
      };
    }

    let inputProps;
    let dropdownVal;

    if (params.value) {
      if (dropdown.length > 0) {
        dropdownVal = dropdown.find(
          (dropdown) => dropdown.value === params.value
        );
      }

      inputProps = {
        value: dropdownVal,
      };
    }

    if (!isCurrentRowEditing) {
      return (
        <Box
          className={`${styles.form_control_select_table}  ADD select_disabled`}
        >
          <Select
            components={{
              ...(isMulti && { Option: InputOption }),
              DropdownIndicator: () => {
                return iconArrow ? (
                  <img src={arrow} style={{ marginRight: "5px" }} />
                ) : null;
              },
            }}
            defaultValue={selectedFromApi}
            isMulti={isMulti}
            isSearchable={false}
            name="roomCategory"
            isDisabled={true}
            onChange={setSelectedValue}
            onMenuClose={() => setDisableSave(false)}
            options={dropdown}
            openMenuOnFocus={true}
            ref={selectRef}
            {...inputProps}
            key={`my_unique_sele${params?.valueFormatted
              ?.split(" ")
              ?.join("_")}`}
            styles={customStyles}
          />
        </Box>
      );
    } else {
      return (
        <div className="AADD select_undisabled">
          <Box
            className={`${styles.form_control_select_table} ${styles.form_control_select_table_focus}  ADD`}
          >
            <Select
              components={{
                ...(isMulti && { Option: InputOption }),
                DropdownIndicator: () => {
                  return iconArrow ? (
                    <img src={arrow} style={{ marginRight: "5px" }} />
                  ) : null;
                },
              }}
              defaultValue={selectedFromApi}
              isMulti={isMulti}
              isSearchable={false}
              name="roomCategory"
              isDisabled={false}
              onChange={setSelectedValue}
              onMenuClose={() => setDisableSave(false)}
              options={dropdown}
              openMenuOnFocus={true}
              ref={selectRef}
              {...inputProps}
              key={`my_unique_sele${params?.valueFormatted
                ?.split(" ")
                ?.join("_")}`}
              styles={customStyles}
            />
          </Box>
        </div>
      );
    }
  };

  const actionCellRenderer = (params) => {
    let editingCells = params.api.getEditingCells();

    // checks if the rowIndex matches in at least one of the editing cells
    let isCurrentRowEditing = editingCells.some((cell) => {
      return cell.rowIndex === params.node.rowIndex;
    });

    if (isCurrentRowEditing) {
      return (
        <Box height="100%">
          <Box display="flex" alignItems="center" height="100%">
            <Box
              display="flex"
              alignItems="center"
              className="customClasssss"
              gap="15px"
            >
              <Image
                cursor={"pointer"}
                className="action-button update"
                data-action="update"
                onClick={() => {}}
                src={editRow}
              ></Image>
              <Image
                cursor={"pointer"}
                className="action-button cancel"
                data-action="cancel"
                onClick={() => {
                  onRowEditingStopped(params);
                }}
                src={removeRow}
              ></Image>
            </Box>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box height="100%">
          <Box display="flex" alignItems="center" height="100%">
            <Box display="flex" alignItems="center" className="customClasssss">
              {params.value.length === 1 && params.value[0] === "edit" && (
                <>
                  <Image
                    cursor={"pointer"}
                    className="action-button edit"
                    data-action="edit"
                    marginLeft={3}
                    /* onClick={() => {
                       
                    }} */
                    src={edit}
                  ></Image>
                </>
              )}
              {params.value.length === 2 && params.value[1] === "delete" && (
                <HStack gap={2}>
                  <Image
                    cursor={"pointer"}
                    className="action-button edit"
                    data-action="edit"
                    onClick={() => {
                      onBtStartEditing(params.rowIndex, undefined);
                      setDisableSave(false);
                      onRowEditingStarted(params);
                    }}
                    src={edit}
                  ></Image>
                  <Image
                    cursor={"pointer"}
                    className="action-button delete"
                    data-action="delete"
                    width="20px"
                    src={removeMain}
                  ></Image>
                </HStack>
              )}
            </Box>
          </Box>
        </Box>
      );
    }
  };

  const onRowEditingStarted = (params) => {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true,
    });
    switch (type) {
      case "Positioning":
        params.api.refreshCells({
          columns: ["associatedBenchmarkReport"],
          rowNodes: [params.node],
          force: true,
        });
        break;
      case "Room type":
        params.api.refreshCells({
          columns: ["roomCategory"],
          rowNodes: [params.node],
          force: true,
        });
        break;
      case "Brand":
        params.api.refreshCells({
          columns: ["operator"],
          rowNodes: [params.node],
          force: true,
        });
      // case "Regions":
      //   params.api.refreshCells({
      //     columns: ["GISRegions"],
      //     rowNodes: [params.node],
      //     force: true,
      //   });
      //   break;
    }
  };
  const onRowEditingStopped = (params) => {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true,
    });
    switch (type) {
      case "Positioning":
        params.api.refreshCells({
          columns: ["associatedBenchmarkReport"],
          rowNodes: [params.node],
          force: true,
        });
        break;
      case "Room type":
        params.api.refreshCells({
          columns: ["roomCategory"],
          rowNodes: [params.node],
          force: true,
        });
        break;
      case "Brand":
        params.api.refreshCells({
          columns: ["operator"],
          rowNodes: [params.node],
          force: true,
        });
        break;
      // case "Regions":
      //   params.api.refreshCells({
      //     columns: ["GISRegions"],
      //     rowNodes: [params.node],
      //     force: true,
      //   });
      //   break;
    }
  };

  const onBtStartEditing = useCallback((rowInd, key, char, pinned) => {
    gridRef.current.api.forEachNode((rowNode, index) => {
      if (rowInd === rowNode.rowIndex) {
        rowNum = rowInd;
        oldVal = rowNode.data[`${cellVal}`];
      }
    });
    gridRef.current.api.setFocusedCell(rowInd, cellVal, pinned);
    switch (type) {
      case "Positioning":
        gridRef.current.api.setFocusedCell(
          rowInd,
          "associatedBenchmarkReport",
          pinned
        );
        break;

      case "Room type":
        gridRef.current.api.setFocusedCell(rowInd, "roomCategory", pinned);
        break;
    }
    gridRef.current.api.startEditingCell({
      rowIndex: rowInd,
      colKey: cellVal,
      rowPinned: pinned,
      keyPress: key,
      charPress: char,
    });
  }, []);

  const undo = useCallback(() => {
    if (gridRef?.current?.api?.getCurrentUndoSize() == 0) {
      gridRef.current.api.undoCellEditing();
    }
  }, []);

  function suppressEnter(params) {
    var KEY_ENTER = 13;
    var event = params.event;
    var key = event.which;
    var suppress = key === KEY_ENTER;
    return suppress;
  }
  const onRowDragEnd = (e) => {
    let newData = [];
    gridRef.current.api.forEachNode((rowNode, index) => {
      newData.push(rowNode.data);
    });

    let newArr = tableData.filter((object1) => {
      return !newData.some((object2) => {
        if (object1["category"]) {
          return object1.category === object2.category;
        }
        if (object1["regionName"]) {
          return object1.regionName === object2.regionName;
        }
      });
    });

    // if (pageToShow == 1) {
    newData = [...newData, ...newArr];
    // } else {
    //   newData = [...newArr, ...newData];
    // }
    onReorderRecords(newData);
    setData(newData);
    setTableData(newData);
    setDisableSave(false);
  };

  const defaultColDef = useMemo(() => {
    return {
      width: 200,
      flex: 1,
      lockPinned: true,
    };
  }, []);

  // useEffect(() => {
  //   for (var i = 0; i < totalRows; i++) {
  //     temp[i] = totalRows - i;
  //   }
  //   const reversed = temp.slice().reverse();

  //   setPages(reversed);

  //   if (totalRows - 1 <= leftRightSide) {
  //     setIsTextDisabled(false);
  //   }
  // }, [tableData]);

  useEffect(() => {
    switch (type) {
      case "Project status": {
        cellVal = "projectStatus";
        let newColumnDefs = [...projectStatusCols];
        Object.assign(newColumnDefs[0], {
          suppressKeyboardEvent: (params) => {
            return suppressEnter(params);
          },
        });
        if (!isReadOnly) {
          newColumnDefs.push({
            headerName: "ACTION",
            field: "action",
            lockPosition: "null",
            cellClass: "locked-col",
            colId: "action",
            cellRenderer: actionCellRenderer,
            maxWidth: 150,
          });
        }
        setColumnDefs(newColumnDefs);
        break;
      }
      case "Package type": {
        cellVal = "packageType";
        let newColumnDefs = [...packageTypeCols];
        Object.assign(newColumnDefs[0], {
          suppressKeyboardEvent: (params) => {
            return suppressEnter(params);
          },
        });
        if (!isReadOnly) {
          newColumnDefs.push({
            headerName: "ACTION",
            field: "action",
            lockPosition: "null",
            cellClass: "locked-col",
            colId: "action",
            cellRenderer: actionCellRenderer,
            maxWidth: 150,
          });
        }

        setColumnDefs(newColumnDefs);
        break;
      }
      case "Room type": {
        cellVal = "roomType";
        let newColumnDefs = [
          {
            headerName: "ROOM TYPE",
            field: "roomType",
            lockPosition: "left",
            cellClass: "locked-col",
            editable: true,
            sortable: true,
            suppressKeyboardEvent: (params) => {
              return suppressEnter(params);
            },
          },
          {
            headerName: "ROOM CATEGORY",
            field: "roomCategory",
            lockPosition: "left",
            cellRenderer: roomCatCellRenderer,
            cellClass: "locked-col",
            editable: true,
            sortable: true,
            suppressKeyboardEvent: (params) => {
              return suppressEnter(params);
            },
          },
          {
            headerName: "LAST MODIFIED",
            field: "lastModifiedAt",
            lockPosition: "null",
            cellClass: "locked-col",
            sortable: true,
          },
          {
            headerName: "MODIFIED BY",
            field: "lastModifiedBy",
            lockPosition: "null",
            cellClass: "locked-col",
            sortable: true,
          },
        ];

        if (!isReadOnly) {
          newColumnDefs.push({
            headerName: "ACTION",
            field: "action",
            lockPosition: "null",
            cellClass: "locked-col",
            colId: "action",
            cellRenderer: actionCellRenderer,
            maxWidth: 150,
          });
        }

        setColumnDefs(newColumnDefs);
        break;
      }
      case "Operator": {
        cellVal = "operator";
        let newColumnDefs = [...operatorCols];
        Object.assign(newColumnDefs[0], {
          suppressKeyboardEvent: (params) => {
            return suppressEnter(params);
          },
        });
        if (!isReadOnly) {
          newColumnDefs.push({
            headerName: "ACTION",
            field: "action",
            lockPosition: "null",
            cellClass: "locked-col",
            colId: "action",
            cellRenderer: actionCellRenderer,
            maxWidth: 150,
          });
        }
        setColumnDefs(newColumnDefs);
        break;
      }
      case "Room category": {
        cellVal = "roomCategory";
        let newColumnDefs = [...roomCategoryCols];
        Object.assign(newColumnDefs[0], {
          suppressKeyboardEvent: (params) => {
            return suppressEnter(params);
          },
        });
        if (!isReadOnly) {
          newColumnDefs.push({
            headerName: "ACTION",
            field: "action",
            lockPosition: "null",
            cellClass: "locked-col",
            colId: "action",
            cellRenderer: actionCellRenderer,
            maxWidth: 150,
          });
        }
        setColumnDefs(newColumnDefs);
        break;
      }
      case "Asset category": {
        cellVal = "assetCategory";
        let newColumnDefs = [...assetCategoryCols];
        // dont allow enter in input field
        Object.assign(newColumnDefs[0], {
          suppressKeyboardEvent: (params) => {
            return suppressEnter(params);
          },
        });
        if (!isReadOnly) {
          newColumnDefs.push({
            headerName: "ACTION",
            field: "action",
            lockPosition: "null",
            cellClass: "locked-col",
            colId: "action",
            cellRenderer: actionCellRenderer,
            maxWidth: 150,
          });
        }
        setColumnDefs(newColumnDefs);
        break;
      }
      case "Positioning": {
        cellVal = "category";
        let newColumnDefs = [
          {
            headerName: "POSITONING NAME",
            field: "category",
            lockPosition: "left",
            cellClass: "locked-col",
            editable: true,
            //suppressCellSelection: true,
            suppressKeyboardEvent: (params) => {
              return suppressEnter(params);
            },
          },
          {
            headerName: "ASSOCIATED BENCHMARK REPORT",
            field: "associatedBenchmarkReport",
            lockPosition: "left",
            cellClass: "locked-col",
            cellRenderer: associatedCellRenderer,
            //minWidth: 220,
            minWidth: 300,
            //minWidth: 350,
            editable: true,
            suppressKeyboardEvent: (params) => {
              return suppressEnter(params);
            },
          },
          {
            headerName: "LAST MODIFIED",
            field: "lastModifiedAt",
            lockPosition: "null",
            cellClass: "locked-col",
          },
          {
            headerName: "MODIFIED BY",
            field: "lastModifiedBy",
            lockPosition: "null",
            cellClass: "locked-col",
          },
        ];
        if (!isReadOnly) {
          newColumnDefs.push(
            {
              headerName: "ACTION",
              field: "action",
              lockPosition: "null",
              cellClass: "locked-col",
              colId: "action",
              maxWidth: 150,
              cellRenderer: actionCellRenderer,
            },
            {
              headerName: "",
              maxWidth: 50,
              rowDrag: isDraggable ? true : false,
            }
          );
        }
        setColumnDefs(newColumnDefs);
        break;
      }
      case "Regions": {
        cellVal = "regionName";
        let newColumnDefs = [
          {
            headerName: "REGION NAME",
            field: "regionName",
            lockPosition: "left",
            cellClass: "locked-col",
            editable: true,
            minWidth: 280,
            maxWidth: 380,
          },
          // {
          //   headerName: "GIS REGIONS",
          //   field: "GISRegions",
          //   lockPosition: "left",
          //   cellClass: "locked-col",
          //   cellRenderer: gisRegionsCellRenderer,
          //   //minWidth: 220,
          //   minWidth: 230,
          //   //minWidth: 350,
          //   editable: true,
          //   suppressKeyboardEvent: (params) => {
          //     return suppressEnter(params);
          //   },
          // },
          {
            headerName: "LAST MODIFIED",
            field: "lastModifiedAt",
            lockPosition: "null",
            cellClass: "locked-col",
            minWidth: 150,
          },
          {
            headerName: "MODIFIED BY",
            field: "lastModifiedBy",
            lockPosition: "null",
            cellClass: "locked-col",
            minWidth: 120,
            maxWidth: 250,
          },
          {
            headerName: "ACTIVE/INACTIVE",
            field: "isActive",
            lockPosition: "null",
            cellClass: "locked-col",
            rowClass: "activeRegion",
            colId: "isActive",
            cellRenderer: activeCellRenderer(isReadOnly),
            maxWidth: 310,
          },
        ];
        Object.assign(newColumnDefs[0], {
          suppressKeyboardEvent: (params) => {
            return suppressEnter(params);
          },
        });

        if (!isReadOnly) {
          newColumnDefs.push(
            {
              headerName: "ACTION",
              field: "action",
              lockPosition: "null",
              cellClass: "locked-col",
              colId: "action",
              cellRenderer: actionCellRenderer,
            },
            {
              headerName: "",
              maxWidth: 80,
              rowDrag: isDraggable ? true : false,
            }
          );
        }
        setColumnDefs(newColumnDefs);
        break;
      }
      case "Project stage": {
        cellVal = "projectStage";
        let newColumnDefs = [...projectStageCols];
        Object.assign(newColumnDefs[0], {
          suppressKeyboardEvent: (params) => {
            return suppressEnter(params);
          },
        });
        if (!isReadOnly) {
          newColumnDefs.push({
            headerName: "ACTION",
            field: "action",
            lockPosition: "null",
            cellClass: "locked-col",
            colId: "action",
            cellRenderer: actionCellRenderer,
            maxWidth: 150,
          });
        }
        setColumnDefs(newColumnDefs);
        break;
      }
      case "Brand": {
        cellVal = "brand";
        let newColumnDefs = [
          {
            headerName: "BRAND",
            field: "brand",
            lockPosition: "left",
            cellClass: "locked-col",
            editable: true,
            sortable: true,
            suppressKeyboardEvent: (params) => {
              return suppressEnter(params);
            },
          },
          {
            headerName: "OPERATOR",
            field: "operator",
            lockPosition: "left",
            cellClass: "locked-col",
            cellRenderer: brandCellRenderer,
            minWidth: 300,
            sortable: true,
            suppressKeyboardEvent: (params) => {
              return suppressEnter(params);
            },
          },
          {
            headerName: "LAST MODIFIED",
            field: "lastModifiedAt",
            lockPosition: "null",
            cellClass: "locked-col",
            sortable: true,
          },
          {
            headerName: "MODIFIED BY",
            field: "lastModifiedBy",
            lockPosition: "null",
            cellClass: "locked-col",
            sortable: true,
          },
        ];

        if (!isReadOnly) {
          newColumnDefs.push({
            headerName: "ACTION",
            field: "action",
            lockPosition: "null",
            cellClass: "locked-col",
            colId: "action",
            cellRenderer: actionCellRenderer,
            maxWidth: 150,
          });
        }

        setColumnDefs(newColumnDefs);
        break;
      }
      case "Contract": {
        cellVal = "contractType";
        let newColumnDefs = [...contractDataCols];
        Object.assign(newColumnDefs[0], {
          suppressKeyboardEvent: (params) => {
            return suppressEnter(params);
          },
        });
        if (!isReadOnly) {
          newColumnDefs.push({
            headerName: "ACTION",
            field: "action",
            lockPosition: "null",
            cellClass: "locked-col",
            colId: "action",
            cellRenderer: actionCellRenderer,
            maxWidth: 150,
          });
        }
        setColumnDefs(newColumnDefs);
        break;
      }
    }

    setTableData(data);
  }, [data]);

  const noRowsOverlayComponent = () => {
    return <Text fontSize={15}>No records to display at this moment</Text>;
  };

  // useEffect(() => {
  //   if (pageToShow === leftLeftSide + 1 && pageToShow !== 1) {
  //     setLeftLeftSide(leftLeftSide - 1);
  //     setLeftRightSide(leftRightSide - 1);

  //     if (totalRows - 1 === leftRightSide) {
  //       setIsTextDisabled(true);
  //     }
  //   }
  //   if (pageToShow === leftRightSide && pageToShow !== totalRows - 1) {
  //     setLeftRightSide(leftRightSide + 1);
  //     setLeftLeftSide(leftLeftSide + 1);
  //     if (totalRows - 1 === leftRightSide + 1) {
  //       setIsTextDisabled(false);
  //     }
  //   }

  //   if (pageToShow === 1) setDisablePrev(true);
  //   else setDisablePrev(false);

  //   if (pageToShow === totalRows) setDisableNext(true);
  //   else setDisableNext(false);

  //   if (totalRows === 0) {
  //     setDisableNext(true);
  //     setDisablePrev(true);
  //   }
  //   if (totalRows - leftRightSide > 1) {
  //     setIsTextDisabled(true);
  //   } else if (totalRows - leftRightSide <= 1) {
  //     setIsTextDisabled(false);
  //   }
  // }, [handleNext, handlePrev, tableData]);

  useEffect(() => {
    setTimeout(() => {
      setShowTable(true);
    }, 700);
  }, []);

  const rowClassRules = {
    // apply green to 2008
    "inactive-class": function (params) {
      return params.node.data.isActive === "Inactive";
    },

    // apply amber 2004
    "active-class": function (params) {
      return params.node.data.isActive === "active";
    },
  };

  let gridBoxClassName = `ag-theme-alpine ${showTable ? "show_table" : ""}`;
  gridBoxClassName += ` ${type == "Regions" && styles.masterRegion} `;
  return (
    <>
      <Box
        mt="5"
        mb="1"
        className={gridBoxClassName}
        // className={`ag-theme-alpine ${showTable ? "show_table" : ""}`}
        ref={gridRefWrapper}
      >
        <AgGridReact
          defaultColDef={defaultColDef}
          enableRangeSelection={true}
          enableFillHandle={true}
          rowDragManaged={true}
          tabToNextCell={false}
          onRowDragEnd={onRowDragEnd}
          animateRows={true}
          ref={gridRef}
          rowData={tableData}
          columnDefs={columnDefs}
          onRowEditingStopped={onRowEditingStopped}
          onRowEditingStarted={onRowEditingStarted}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={2}
          onCellClicked={onCellClicked}
          suppressScrollOnNewData={true}
          suppressClickEdit={true}
          rowClassRules={rowClassRules}
          onRowDragLeave={onRowDragEnd}
          noRowsOverlayComponent={noRowsOverlayComponent}
        />
      </Box>
      <div style={{ visibility: "hidden", height: 0 }} className="customBtn">
        click
      </div>
    </>
  );
};