import { isDecimalNumber } from "./General";
//ASSET LIST STEP 4 STANDARD
//HAVE TWO METHOD, ONE OF THEM FOR THE OCCUPANCY RATE CALCULATION AND THE SECOND ONE FOR THE OCCUPANCY RATE FIELD [5] THAT HAVE DIFFERENT FORMULA
export const assetListStep4calcOccupancyIncField5 = (
  val = -1,
  index,
  fieldName,
  formikValues
) => {
  let newRoomType = formikValues.roomType;

  if (val == -1) {
    let sumCalcArr = [];
    formikValues.roomType.forEach((el, ind) => {
      sumCalcArr.push(el.numKeys * el.occRate);
    });
    let sumCalc = 0;
    console.log("========1", formikValues.occupancyIncRateYear);
    sumCalc = sumCalcArr.map((val) => val + sumCalc);
    console.log("sumCacl", sumCalc);
    let newOccArr = formikValues.occupancyIncRateYear;
    newOccArr[4] = val;
    let calc6 = parseFloat(sumCalc) + formikValues.occupancyIncRateYear[5];
    let calc4 = parseFloat(sumCalc) - formikValues.occupancyIncRateYear[3];
    let calc3 =
      parseFloat(sumCalc) -
      formikValues.occupancyIncRateYear[3] -
      formikValues.occupancyIncRateYear[2];
    let calc2 =
      parseFloat(sumCalc) -
      formikValues.occupancyIncRateYear[3] -
      formikValues.occupancyIncRateYear[2] -
      formikValues.occupancyIncRateYear[1];
    let calc1 =
      parseFloat(sumCalc) -
      formikValues.occupancyIncRateYear[3] -
      formikValues.occupancyIncRateYear[2] -
      formikValues.occupancyIncRateYear[1] -
      formikValues.occupancyIncRateYear[0];
    return {
      ...formikValues,
      calc5: sumCalc,
      occupancyIncRateYear: newOccArr,
      ...(formikValues.calc6 !== "0" && {
        calc6: calc6,
      }),
      ...(formikValues.calc4 !== "0" && {
        calc4: calc4,
      }),
      ...(formikValues.calc3 !== "0" && {
        calc3: calc3,
      }),
      ...(formikValues.calc2 !== "0" && {
        calc2: calc2,
      }),
      ...(formikValues.calc1 !== "0" && {
        calc1: calc1,
      }),
    };
  } else {
    let sumCalcArr = [];

    let devidedByArr = [];
    formikValues.roomType.forEach((el, ind) => {
      if (index != ind) {
        devidedByArr.push(el.numKeys);
      } else if (fieldName === "numKeys" && index == ind) {
        devidedByArr.push(val);
      } else if (fieldName === "occRate" && index == ind) {
        devidedByArr.push(el.numKeys);
      } else if (fieldName === "occRate" && index != ind) {
        devidedByArr.push(el.numKeys);
      }
      if (index != ind) {
        if (el.numKeys != "" && el.occRate != "") {
          sumCalcArr.push(el.numKeys * el.occRate);
        }
      } else {
        if (fieldName == "numKeys") {
          if (el.occRate != "") {
            sumCalcArr.push(val * el.occRate);
            newRoomType[index].numKeys = val;
          }
        } else if (fieldName == "occRate") {
          if (el.numKeys != "") {
            sumCalcArr.push(el.numKeys * val);
            newRoomType[index].occRate = val;
          }
        }
        //formikValues.roomType[index][fieldName]
      }
    });
    let sumCalc = 0;
    let devCalc = 0;

    console.log("========1", formikValues.roomType, devidedByArr);
    sumCalcArr.forEach((val) => (sumCalc = val + sumCalc));
    console.log("sumCacl", sumCalc);
    if (devidedByArr.length > 0) {
      devidedByArr.forEach((val) => (devCalc = val + devCalc));
      sumCalc = sumCalc / devCalc;
      sumCalc = sumCalc;
      console.log("devCalc", sumCalc, devCalc);
    }
    let newOccArr = formikValues.occupancyIncRateYear;
    newOccArr[4] = val;
    let calc6 = parseFloat(sumCalc) + formikValues.occupancyIncRateYear[5];
    let calc4 = parseFloat(sumCalc) - formikValues.occupancyIncRateYear[3];
    let calc3 =
      parseFloat(sumCalc) -
      formikValues.occupancyIncRateYear[3] -
      formikValues.occupancyIncRateYear[2];
    let calc2 =
      parseFloat(sumCalc) -
      formikValues.occupancyIncRateYear[3] -
      formikValues.occupancyIncRateYear[2] -
      formikValues.occupancyIncRateYear[1];
    let calc1 =
      parseFloat(sumCalc) -
      formikValues.occupancyIncRateYear[3] -
      formikValues.occupancyIncRateYear[2] -
      formikValues.occupancyIncRateYear[1] -
      formikValues.occupancyIncRateYear[0];
    return {
      ...formikValues,
      calc5: sumCalc,
      occupancyIncRateYear: newOccArr,
      roomType: newRoomType,
      ...(formikValues.calc6 != "0" && {
        calc6: calc6,
      }),
      ...(formikValues.calc4 != "0" && {
        calc4: calc4,
      }),
      ...(formikValues.calc3 != "0" && {
        calc3: calc3,
      }),
      ...(formikValues.calc2 != "0" && {
        calc2: calc2,
      }),
      ...(formikValues.calc1 != "0" && {
        calc1: calc1,
      }),
    };
  }
};
export const assetListStep4HandleCalcOcc = (name, val, formikValues) => {
  if (name == "occupancyIncRateYear") {
    //occ 5
    let sumCalc5 = 0;
    sumCalc5 =
      parseFloat(formikValues.calc5) + formikValues.occupancyIncRateYear[5];
    sumCalc5 = sumCalc5;
    console.log("sumcalc5 filed 6 =", sumCalc5);

    //occ 3
    let sumCalc3 = 0;
    sumCalc3 =
      parseFloat(formikValues.calc5) - formikValues.occupancyIncRateYear[3];
    sumCalc3 = sumCalc3;

    //occ 2
    let sumCalc2 = 0;
    sumCalc2 = parseFloat(sumCalc3) - formikValues.occupancyIncRateYear[2];
    sumCalc2 = sumCalc2;

    //occ 1
    let sumCalc1 = 0;
    sumCalc1 = parseFloat(sumCalc2) - formikValues.occupancyIncRateYear[1];
    sumCalc1 = sumCalc1;

    //occ 0
    let sumCalc0 = 0;
    sumCalc0 = parseFloat(sumCalc1) - formikValues.occupancyIncRateYear[0];
    sumCalc0 = sumCalc0;

    return {
      //...formikValues,
      calc1: sumCalc0,
      calc2: sumCalc1,
      calc3: sumCalc2,
      calc4: sumCalc3,
      calc6: sumCalc5,
      //occupancyIncRateYear: newOccArr,
    };
  } else {
    return "notFound";
  }
};

//ASSET LIST STEP 4 PACKAGE
//HAVE TWO METHOD, ONE OF THEM FOR THE OCCUPANCY RATE CALCULATION AND THE SECOND ONE FOR THE OCCUPANCY RATE FIELD [5] THAT HAVE DIFFERENT FORMULA
export const assetListStep4PackagecalcOccupancyIncField5 = (
  val = -1,
  index,
  fieldName,
  formikValues
) => {
  console.log("enter assetListStep4PackagecalcOccupancyIncField5");
  let newPackageType = formikValues.packageType;

  if (val == -1) {
    let sumCalcArr = [];
    let sumCaclGuestArr = [];
    formikValues.packageType.forEach((el, ind) => {
      sumCalcArr.push(el.num * el.occRate);
      sumCaclGuestArr.push(el.num * el.occRate * el.gpor);
    });
    let sumCalc = 0;
    let sumCaclGuest = 0;
    console.log("========1", formikValues.occupancyIncRateYear);
    sumCalc = sumCalcArr.map((val) => val + sumCalc);
    sumCaclGuestArr.forEach((val) => (sumCaclGuest = val + sumCaclGuest));
    console.log("sumCacl", sumCalc);
    let newOccArr = formikValues.occupancyIncRateYear;
    newOccArr[4] = val;
    let calc6 = parseFloat(sumCalc) + formikValues.occupancyIncRateYear[5];
    let calc4 = parseFloat(sumCalc) - formikValues.occupancyIncRateYear[3];
    let calc3 =
      parseFloat(sumCalc) -
      formikValues.occupancyIncRateYear[3] -
      formikValues.occupancyIncRateYear[2];
    let calc2 =
      parseFloat(sumCalc) -
      formikValues.occupancyIncRateYear[3] -
      formikValues.occupancyIncRateYear[2] -
      formikValues.occupancyIncRateYear[1];
    let calc1 =
      parseFloat(sumCalc) -
      formikValues.occupancyIncRateYear[3] -
      formikValues.occupancyIncRateYear[2] -
      formikValues.occupancyIncRateYear[1] -
      formikValues.occupancyIncRateYear[0];

    console.log("sumCaclGuest =", sumCaclGuest);
    return {
      ...formikValues,
      calc5: sumCalc,
      // guestPerOccRoom: isDecimalNumber(sumCaclGuest)
      //   ? parseFloat(sumCaclGuest)?.toFixed(2)
      //   : sumCaclGuest,
      guestPerOccRoom: sumCaclGuest,
      occupancyIncRateYear: newOccArr,
      ...(formikValues.calc6 != "0" && {
        calc6: calc6,
      }),
      ...(formikValues.calc4 != "0" && {
        calc4: calc4,
      }),
      ...(formikValues.calc3 != "0" && {
        calc3: calc3,
      }),
      ...(formikValues.calc2 != "0" && {
        calc2: calc2,
      }),
      ...(formikValues.calc1 != "0" && {
        calc1: calc1,
      }),
    };
  } else {
    let sumCalcArr = [];
    let sumCalcGuestArr = [];

    let devidedByArr = [];
    let devidedByGuestArr = [];
    formikValues.packageType.forEach((el, ind) => {
      if (index != ind) {
        devidedByArr.push(el.num);
        devidedByGuestArr.push(el.num * el.occRate);
      } else if (fieldName === "num" && index == ind) {
        devidedByArr.push(val);
        devidedByGuestArr.push(val * el.occRate);
      } else if (fieldName === "occRate" && index == ind) {
        devidedByArr.push(el.num);
        devidedByGuestArr.push(el.num * val);
      } else if (fieldName === "occRate" && index != ind) {
        devidedByArr.push(el.num);
        devidedByGuestArr.push(el.num * el.occRate);
      } else if (fieldName === "gpor" && el.num != "" && el.occRate != "") {
        devidedByGuestArr.push(el.num * el.occRate);
      }
      if (index != ind) {
        if (el.num != "" && el.occRate != "") {
          sumCalcArr.push(el.num * el.occRate);
        }
        console.log(
          "gpod ind!=index ",
          el.num != "",
          el.occRate != "",
          el.gpor != "",
          el.num * el.occRate * el.gpor
        );
        if (el.num != "" && el.occRate != "" && el.gpor != "") {
          sumCalcGuestArr.push(el.num * el.occRate * el.gpor);
        }
      } else {
        if (fieldName == "num") {
          if (el.occRate != "") {
            sumCalcArr.push(val * el.occRate);
            newPackageType[index].num = val;
          }
          console.log(
            "gporCalc",
            el.occRate != "",
            el.gpor != "",
            val * el.occRate * el.gpor
          );
          if (el.occRate != "" && el.gpor != "") {
            sumCalcGuestArr.push(val * el.occRate * el.gpor);
          }
        } else if (fieldName == "occRate") {
          if (el.num != "") {
            sumCalcArr.push(el.num * val);
            newPackageType[index].occRate = val;
          }
          if (el.num != "" && el.gpor != "") {
            sumCalcGuestArr.push(val * el.num * el.gpor);
          }
        } else if (fieldName == "gpor") {
          newPackageType[index].gpor = val;
          if (el.num != "" && el.occRate != "") {
            sumCalcGuestArr.push(val * el.num * el.occRate);
          }
        }
        //formikValues.packageType[index][fieldName]
      }
    });
    let sumCalc = 0;
    let devCalc = 0;
    let sumCalcGuest = 0;
    let devCalcGuest = 0;

    //console.log("========1", formikValues.packageType, devidedByArr);
    sumCalcArr.forEach((val) => (sumCalc = val + sumCalc));
    sumCalcGuestArr.forEach((val) => (sumCalcGuest = val + sumCalcGuest));
    //console.log("sumCacl", sumCalc);
    if (devidedByArr.length > 0) {
      devidedByArr.forEach((val) => (devCalc = val + devCalc));
      sumCalc = sumCalc / devCalc;
      sumCalc = sumCalc;
      //console.log("devCalc", sumCalc, devCalc);
    }
    if (devidedByGuestArr.length > 0) {
      devidedByGuestArr.forEach((val) => (devCalcGuest = val + devCalcGuest));
      console.log("calc Guest", sumCalcGuest, devCalcGuest, sumCalcGuestArr);
      sumCalcGuest = sumCalcGuest / devCalcGuest;
      sumCalcGuest = sumCalcGuest;
      //console.log("devCalc", sumCalc, devCalc);
    }

    if (fieldName == "gpor") {
      console.log("calc Guest 2", sumCalcGuest, devCalcGuest);
      return {
        ...formikValues,
        // guestPerOccRoom: isDecimalNumber(sumCalcGuest)
        //   ? parseFloat(sumCalcGuest)?.toFixed(2)
        //   : sumCalcGuest,
        guestPerOccRoom: sumCalcGuest,
        packageType: newPackageType,
      };
    } else {
      let newOccArr = formikValues.occupancyIncRateYear;
      newOccArr[4] = val;
      console.log("calc5", sumCalc);
      let calc6 = parseFloat(sumCalc) + formikValues.occupancyIncRateYear[5];
      let calc4 = parseFloat(sumCalc) - formikValues.occupancyIncRateYear[3];
      let calc3 =
        parseFloat(sumCalc) -
        formikValues.occupancyIncRateYear[3] -
        formikValues.occupancyIncRateYear[2];
      let calc2 =
        parseFloat(sumCalc) -
        formikValues.occupancyIncRateYear[3] -
        formikValues.occupancyIncRateYear[2] -
        formikValues.occupancyIncRateYear[1];
      let calc1 =
        parseFloat(sumCalc) -
        formikValues.occupancyIncRateYear[3] -
        formikValues.occupancyIncRateYear[2] -
        formikValues.occupancyIncRateYear[1] -
        formikValues.occupancyIncRateYear[0];

      return {
        ...formikValues,
        calc5: sumCalc,
        guestPerOccRoom: sumCalcGuest,
        occupancyIncRateYear: newOccArr,
        packageType: newPackageType,
        ...(formikValues.calc6 != "0" && {
          calc6: calc6,
        }),
        ...(formikValues.calc4 != "0" && {
          calc4: calc4,
        }),
        ...(formikValues.calc3 != "0" && {
          calc3: calc3,
        }),
        ...(formikValues.calc2 != "0" && {
          calc2: calc2,
        }),
        ...(formikValues.calc1 != "0" && {
          calc1: calc1,
        }),
      };
    }
  }
  /* let newPackageType = formikValues.packageType;

  if (val == -1) {
    let sumCalcArr = [];
    formikValues.packageType.forEach((el, ind) => {
      sumCalcArr.push(el.num * el.occRate);
    });
    let sumCalc = 0;
    console.log("========1", formikValues.occupancyIncRateYear);
    sumCalc = sumCalcArr.map((val) => val + sumCalc);
    console.log("sumCacl", sumCalc);
    let newOccArr = formikValues.occupancyIncRateYear;
    newOccArr[4] = val;
    return {
      ...formikValues,
      calc5: sumCalc,
      occupancyIncRateYear: newOccArr,
    };
  } else {
    let sumCalcArr = [];
    let devidedByArr = [];
    formikValues.packageType.forEach((el, ind) => {
      if (index != ind) {
        devidedByArr.push(el.num);
      } else if (fieldName === "num" && index == ind) {
        devidedByArr.push(val);
      } else if (fieldName === "occRate" && index == ind) {
        devidedByArr.push(el.num);
      } else if (fieldName === "occRate" && index != ind) {
        devidedByArr.push(el.num);
      }
      if (index != ind) {
        if (el.num != "" && el.occRate != "") {
          sumCalcArr.push(el.num * el.occRate);
        }
      } else {
        if (fieldName == "num") {
          if (el.occRate != "") {
            sumCalcArr.push(val * el.occRate);
            newPackageType[index].num = val;
          }
        } else if (fieldName == "occRate") {
          if (el.num != "") {
            sumCalcArr.push(el.num * val);
            newPackageType[index].occRate = val;
          }
        }
        //formikValues.packageType[index][fieldName]
      }
    });
    let sumCalc = 0;
    let devCalc = 0;
    console.log("========1", formikValues.packageType);
    sumCalcArr.map((val) => (sumCalc = val + sumCalc));
    console.log("sumCacl", sumCalc);
    if (devidedByArr.length > 0) {
      devidedByArr.map((val) => (devCalc = val + devCalc));
      sumCalc = sumCalc / devCalc;
      console.log("devCalc", devCalc);
      sumCalc = sumCalc.toFixed(3);
    }
    console.log(devidedByArr);
    let newOccArr = formikValues.occupancyIncRateYear;
    console.log("vaaaaaaaaaaaal", val);
    newOccArr[4] = val;
    return {
      ...formikValues,
      calc5: sumCalc,
      occupancyIncRateYear: newOccArr,
      packageType: newPackageType,
    };
  } */
};

//ASSET LIST STEP 5 STANDARD
//HAVE ONE METHOD,  FOR THE WHIGHTED AVERAGE DAILY RATE CALUCULATION
let calcFields = [
  "weightedADR1",
  "weightedADR2",
  "weightedADR3",
  "weightedADR4",
  "weightedADR5",
  "weightedADR6",
];
export const assetListStep5calcWeightedAvgRate = (
  val = -1,
  index,
  perfAssetYearIndex,
  fieldName,
  formikValues
) => {
  let stop = false;
  let newPerformanceAsset = formikValues.performaceAssitList2;
  formikValues.performaceAssitList2.forEach((el, ind) => {
    // console.log("year", el.year[perfAssetYearIndex]);
    // console.log("val", val, index, ind, index != ind);
    if (index != ind) {
      //   console.log("enter the old enterd year");
      if (el.year[perfAssetYearIndex] == 0) {
        // console.log("el.year[perfAssetYearIndex] == 0");
        stop = true;
      }
    } else {
      //   console.log("enter the new enterd year");
      if (val == 0) {
        // console.log("val == 0");
        stop = true;
      }
    }
  });
  if (stop) {
    return 0;
  }
  //   console.log("Should not shown");
  let sumCalcArr = [];
  let devidedByArr = [];
  formikValues.roomType.forEach((el, ind) => {
    devidedByArr.push(el.numKeys * el.occRate);
    if (index != ind) {
      /* console.log(
        "step 2 not equal",
        formikValues.performaceAssitList2,
        formikValues.performaceAssitList2[ind].year,
        index,
        ind,
        perfAssetYearIndex
      ); */
      if (
        el.numKeys != "" &&
        el.occRate != "" &&
        formikValues.performaceAssitList2[ind].year[perfAssetYearIndex]
      ) {
        sumCalcArr.push(
          el.numKeys *
            el.occRate *
            formikValues.performaceAssitList2[ind].year[perfAssetYearIndex]
        );
        /* console.log(
          "formikValues.performaceAssitList2[index].year[ind]",
          formikValues.performaceAssitList2[ind].year[perfAssetYearIndex]
        ); */
      }
    } else {
      if (fieldName == "performaceAssitList2") {
        sumCalcArr.push(val * el.occRate * el.numKeys);
        newPerformanceAsset[index].year[perfAssetYearIndex] = val;
        /* console.log(
          "newPerformanceAsset[index].year[perfAssetYearIndex]",
          newPerformanceAsset[index].year[perfAssetYearIndex]
        ); */
      }
      //formikValues.roomType[index][fieldName]
    }
  });
  let sumCalc = 0;
  let devCalc = 0;

  //   console.log("========1", formikValues.performaceAssitList2);
  sumCalcArr.forEach((val) => (sumCalc = val + sumCalc));
  devidedByArr.forEach((val) => (devCalc = val + devCalc));
  //   console.log("sumCacl", sumCalc, devCalc, sumCalc / devCalc);
  sumCalc = sumCalc / devCalc;
  sumCalc = sumCalc;
  return {
    ...formikValues,
    [calcFields[perfAssetYearIndex]]: sumCalc,
    performaceAssitList2: newPerformanceAsset,
  };
};

//ASSET LIST STEP 5 STANDARD
//HAVE ONE METHOD,  FOR THE WHIGHTED AVERAGE DAILY RATE CALUCULATION
//whighted other calculation
let calcFieldsFB = ["fAndB1", "fAndB2", "fAndB3", "fAndB4", "fAndB5", "fAndB6"];
let calcFieldsOther = [
  "otherCalc1",
  "otherCalc2",
  "otherCalc3",
  "otherCalc4",
  "otherCalc5",
  "otherCalc6",
];
let calcFieldsRoomRate = [
  "roomRateCalc1",
  "roomRateCalc2",
  "roomRateCalc3",
  "roomRateCalc4",
  "roomRateCalc5",
  "roomRateCalc6",
];
let calcFieldsPackageRate = [
  "packageRateCalc1",
  "packageRateCalc2",
  "packageRateCalc3",
  "packageRateCalc4",
  "packageRateCalc5",
  "packageRateCalc6",
];
let calcWeightedADR = [
  "weightedADRCalc1",
  "weightedADRCalc2",
  "weightedADRCalc3",
  "weightedADRCalc4",
  "weightedADRCalc5",
  "weightedADRCalc6",
];

export const assetListStep5calcPerPerson = (
  val = -1,
  index,
  perfAssetYearIndex,
  fieldName,
  formikValues
) => {
  let perfAssetListProperty;
  if (fieldName === "foodAndBeverage") {
    perfAssetListProperty = [
      ...formikValues.performaceAssitList2.foodAndBeverage,
    ];
  } else if (fieldName === "other") {
    perfAssetListProperty = [...formikValues.performaceAssitList2.other];
  } else if (fieldName === "packageRate") {
    perfAssetListProperty = [...formikValues.performaceAssitList2.packageRate];
  } else if (fieldName === "roomRate") {
    perfAssetListProperty = [...formikValues.performaceAssitList2.roomRate];
  }

  // let stop = false;
  // perfAssetListProperty.forEach((el, ind) => {
  //   console.log("year", el.year[perfAssetYearIndex]);
  //   if (index != ind) {
  //       console.log("enter the old enterd year");
  //     if (el.year[perfAssetYearIndex] == 0) {
  //       stop = true;
  //     }
  //   } else {
  //       console.log("enter the new enterd year");
  //     if (val == 0) {
  //       stop = true;
  //     }
  //   }
  // });
  //
  // if (stop) {
  //   return 0;
  // }
  //   console.log("Should not shown");

  // let sumCalcArr = [];
  // let devidedByArr = [];
  // formikValues.packageType.forEach((el, ind) => {
  //   devidedByArr.push(el.num * el.occRate);
  //   if (index != ind) {
  //     if (
  //       el.num != "" &&
  //       el.occRate != "" &&
  //       perfAssetListProperty[ind].year[perfAssetYearIndex]
  //     ) {
  //       // console.log("==========444============", formikValues);
  //       sumCalcArr.push(
  //         el.num *
  //           el.occRate *
  //           perfAssetListProperty[ind].year[perfAssetYearIndex]
  //       );
  //     }
  //   } else {
  //     if (
  //       fieldName == "other" ||
  //       fieldName == "foodAndBeverage" ||
  //       fieldName == "roomRate" ||
  //       fieldName == "packageRate"
  //     ) {
  //       sumCalcArr.push(val * el.occRate * el.num);
  //
  //       //console.log("formik inside formila after", formikValues);
  //     }
  //   }
  // });
  //
  let sumCalc = 0;
  // let devCalc = 0;
  //
  // //   console.log("========1", perfAssetListProperty);
  // sumCalcArr.forEach((val) => (sumCalc = val + sumCalc));
  // devidedByArr.forEach((val) => (devCalc = val + devCalc));
  // //   console.log("sumCacl", sumCalc, devCalc, sumCalc / devCalc);
  // sumCalc = sumCalc / devCalc;
  // sumCalc = sumCalc;

  if (fieldName == "foodAndBeverage") {
    const weightedFnBRateForYear = formikValues.packageType.reduce((acc, packageType, packageTypeIndex) => {
      return acc + packageType.num * packageType.gpor * packageType.occRate * (perfAssetListProperty[packageTypeIndex].year[perfAssetYearIndex] || 0);
    }, 0) / formikValues.packageType.reduce((acc, packageType) => {
      return acc + packageType.num * packageType.gpor * packageType.occRate;
    }, 0);

    return {
      ...formikValues,
      [calcFieldsFB[perfAssetYearIndex]]: weightedFnBRateForYear,
    };
  } else if (fieldName == "other") {
    const weightedOtherRateForYear = formikValues.packageType.reduce((acc, packageType, packageTypeIndex) => {
      return acc + packageType.num * packageType.gpor * packageType.occRate * (perfAssetListProperty[packageTypeIndex].year[perfAssetYearIndex] || 0);
    }, 0) / formikValues.packageType.reduce((acc, packageType) => {
      return acc + packageType.num * packageType.gpor * packageType.occRate;
    }, 0);

    return {
      ...formikValues,
      [calcFieldsOther[perfAssetYearIndex]]: weightedOtherRateForYear,
    };
  } else if (fieldName == "packageRate") {
    const weightedPackageRateForYear = formikValues.packageType.reduce((acc, packageType, packageTypeIndex) => {
      return acc + packageType.num * packageType.gpor * packageType.occRate * (perfAssetListProperty[packageTypeIndex].year[perfAssetYearIndex] || 0);
    }, 0) / formikValues.packageType.reduce((acc, packageType) => {
      return acc + packageType.num * packageType.gpor * packageType.occRate;
    }, 0);
    return {
      ...formikValues,
      [calcFieldsPackageRate[perfAssetYearIndex]]: weightedPackageRateForYear,
    };
  } else if (fieldName == "roomRate") {
    const weightedRoomRateForYear = formikValues.packageType.reduce((acc, packageType, packageTypeIndex) => {
      return acc + packageType.num * packageType.gpor * packageType.occRate * (perfAssetListProperty[packageTypeIndex].year[perfAssetYearIndex] || 0);
    }, 0) / formikValues.packageType.reduce((acc, packageType) => {
      return acc + packageType.num * packageType.gpor * packageType.occRate;
    }, 0);
    return {
      ...formikValues,
      // [calcFieldsRoomRate[perfAssetYearIndex]]: sumCalc,
      [calcFieldsRoomRate[perfAssetYearIndex]]: weightedRoomRateForYear,
      [calcWeightedADR[perfAssetYearIndex]]:
        weightedRoomRateForYear * parseFloat(formikValues.guestPerOccRoom),
    };
  }
};

let calcTotalPackageRateSAR = [
  "totalPackageRateSARCalc1",
  "totalPackageRateSARCalc2",
  "totalPackageRateSARCalc3",
  "totalPackageRateSARCalc4",
  "totalPackageRateSARCalc5",
  "totalPackageRateSARCalc6",
];
let calcTotalPackageRateUSD = [
  "totalPackageRateUSDCalc1",
  "totalPackageRateUSDCalc2",
  "totalPackageRateUSDCalc3",
  "totalPackageRateUSDCalc4",
  "totalPackageRateUSDCalc5",
  "totalPackageRateUSDCalc6",
];
let calcTotalPackageRate7DaysSAR = [
  "totalPackageRate7DaysSARCalc1",
  "totalPackageRate7DaysSARCalc2",
  "totalPackageRate7DaysSARCalc3",
  "totalPackageRate7DaysSARCalc4",
  "totalPackageRate7DaysSARCalc5",
  "totalPackageRate7DaysSARCalc6",
];
let calcTotalPackageRate7DaysUSD = [
  "totalPackageRate7DaysUSDCalc1",
  "totalPackageRate7DaysUSDCalc2",
  "totalPackageRate7DaysUSDCalc3",
  "totalPackageRate7DaysUSDCalc4",
  "totalPackageRate7DaysUSDCalc5",
  "totalPackageRate7DaysUSDCalc6",
];

export const assetListStep5calcTotalPackageRate = (
  fieldName,
  index,
  formikValues
) => {
  if (fieldName === "calcTotalPackageRate") {
    // console.log("sssssssa", formikValues);
    let result = {};
    let { foodAndBeverage, other, packageRate, roomRate } =
      formikValues.performaceAssitList2;
    foodAndBeverage[0].year.forEach((item, ind) => {
      let sumCalc = (formikValues[calcFieldsRoomRate[ind]] || 0) +
          (formikValues[calcFieldsPackageRate[ind]] || 0) +
          (formikValues[calcFieldsOther[ind]] || 0) +
          (formikValues[calcFieldsFB[ind]] || 0);
      result = {
        ...result,
        [calcTotalPackageRateSAR[ind]]: sumCalc,
        [calcTotalPackageRateUSD[ind]]: sumCalc * 3.75,
        [calcTotalPackageRate7DaysSAR[ind]]: sumCalc * 7,
        [calcTotalPackageRate7DaysUSD[ind]]: sumCalc * 3.75 * 7,
      };
      // if (
      //   foodAndBeverage[0].year[ind] != 0 &&
      //   other[0].year[ind] != 0 &&
      //   packageRate[0].year[ind] != 0 &&
      //   roomRate[0].year[ind] != 0
      // ) {
      //   let foodBevYear1 = parseFloat(foodAndBeverage[0].year[ind]);
      //   let otherYear1 = parseFloat(other[0].year[ind]);
      //   let packageRateYear1 = parseFloat(packageRate[0].year[ind]);
      //   let roomRateYear1 = parseFloat(roomRate[0].year[ind]);
      //   let sumCalc =
      //     foodBevYear1 + otherYear1 + packageRateYear1 + roomRateYear1;
      //
      //   result = {
      //     ...result,
      //     [calcTotalPackageRateSAR[ind]]: sumCalc,
      //     [calcTotalPackageRateUSD[ind]]: sumCalc * 3.75,
      //     [calcTotalPackageRate7DaysSAR[ind]]: sumCalc * 7,
      //     [calcTotalPackageRate7DaysUSD[ind]]: sumCalc * 3.75 * 7,
      //   };
      // }
    });
    // console.log("result", result);
    return {
      ...formikValues,
      ...result,
    };
  }
};

//ASSET LIST STEP 6 PACKAGE
export const assetListStep6HandleCalc = (name, val, formikValues) => {
  let fieldIndex = `${name}`.split(".")[2];

  // if (`${name}`.includes("packages")) {
  //   console.log("enter packages");
  //   let calc = 0;
  //   if (fieldIndex == 0) {
  //     calc = formikValues.totalPackageRate7DaysSARCalc1;
  //   } else if (fieldIndex == 1) {
  //     calc = formikValues.totalPackageRate7DaysSARCalc2;
  //   } else if (fieldIndex == 2) {
  //     calc = formikValues.totalPackageRate7DaysSARCalc3;
  //   } else if (fieldIndex == 3) {
  //     calc = formikValues.totalPackageRate7DaysSARCalc4;
  //   } else if (fieldIndex == 4) {
  //     calc = formikValues.totalPackageRate7DaysSARCalc5;
  //   } else if (fieldIndex == 5) {
  //     calc = formikValues.totalPackageRate7DaysSARCalc6;
  //   }
  //   let otherBrArr = [...formikValues.otherRevenueBreakdown.packages];
  //   let otherBrAmountArr = [
  //     ...formikValues.otherRevenueBreakdown.packagesAmounts,
  //   ];
  //   otherBrAmountArr[fieldIndex] = calc;
  //   otherBrArr[fieldIndex] = val;
  //   console.log("packages", otherBrArr);

  //   return {
  //     otherRevenueBreakdown: {
  //       ...formikValues.otherRevenueBreakdown,

  //       packages: otherBrArr,

  //       packagesAmounts: otherBrAmountArr,
  //     },
  //   };
  // }
  if (`${name}`.includes("meetingEvents")) {
    console.log("enter meetingEvents");

    let otherBrArr = [...formikValues.otherRevenueBreakdown.meetingEvents];
    let otherBrAmountArr = [
      ...formikValues.otherRevenueBreakdown.meetingEventsAmounts,
    ];
    otherBrAmountArr[fieldIndex] = val;
    otherBrArr[fieldIndex] = val;
    console.log("meetingEvents", otherBrArr);

    return {
      otherRevenueBreakdown: {
        ...formikValues.otherRevenueBreakdown,

        meetingEvents: otherBrArr,

        meetingEventsAmounts: otherBrAmountArr,
      },
    };
  }
  if (
    `${name}`.includes("banquet") ||
    `${name}`.includes("recreation") ||
    `${name}`.includes("spa") ||
    `${name}`.includes("otherProfitDepartments") ||
    `${name}`.includes("retail") ||
    `${name}`.includes("miscellaneous") ||
    `${name}`.includes("rental") ||
    `${name}`.includes("other1")
  ) {
    //console.log("enter banquet,recreation");
    let calc = 0;
    if (fieldIndex == 0) {
      let calcFormula =
        formikValues.otherCalc1 * (val || 0) / 100;
      calc = calcFormula;
    } else if (fieldIndex == 1) {
      let calcFormula =
        formikValues.otherCalc2 * (val || 0) / 100;
      calc = calcFormula;
    } else if (fieldIndex == 2) {
      let calcFormula =
        formikValues.otherCalc3 * (val || 0) / 100;
      calc = calcFormula;
    } else if (fieldIndex == 3) {
      let calcFormula =
        formikValues.otherCalc4 * (val || 0) / 100;
      calc = calcFormula;
    } else if (fieldIndex == 4) {
      let calcFormula =
        formikValues.otherCalc5 * (val || 0) / 100;
      calc = calcFormula;
    } else if (fieldIndex == 5) {
      let calcFormula =
        formikValues.otherCalc6 * (val || 0) / 100;
      calc = calcFormula;
    }
    if (`${name}`.includes("banquet")) {
      let otherBrArr = [...formikValues.otherRevenueBreakdown.banquet];
      let otherBrAmountArr = [
        ...formikValues.otherRevenueBreakdown.banquetAmounts,
      ];
      otherBrAmountArr[fieldIndex] = calc;
      otherBrArr[fieldIndex] = val;

      return {
        otherRevenueBreakdown: {
          ...formikValues.otherRevenueBreakdown,
          banquet: otherBrArr,
          banquetAmounts: otherBrAmountArr,
        },
      };
    } else if (`${name}`.includes("recreation")) {
      let otherBrArr = [...formikValues.otherRevenueBreakdown.recreation];
      let otherBrAmountArr = [
        ...formikValues.otherRevenueBreakdown.recreationAmounts,
      ];
      otherBrAmountArr[fieldIndex] = calc;
      otherBrArr[fieldIndex] = val;

      return {
        otherRevenueBreakdown: {
          ...formikValues.otherRevenueBreakdown,
          recreation: otherBrArr,
          recreationAmounts: otherBrAmountArr,
        },
      };
    } else if (`${name}`.includes("spa")) {
      let otherBrArr = [...formikValues.otherRevenueBreakdown.spa];
      let otherBrAmountArr = [...formikValues.otherRevenueBreakdown.spaAmounts];
      otherBrAmountArr[fieldIndex] = calc;
      otherBrArr[fieldIndex] = val;

      return {
        otherRevenueBreakdown: {
          ...formikValues.otherRevenueBreakdown,
          spa: otherBrArr,
          spaAmounts: otherBrAmountArr,
        },
      };
    } else if (`${name}`.includes("otherProfitDepartments")) {
      let otherBrArr = [
        ...formikValues.otherRevenueBreakdown.otherProfitDepartments,
      ];
      let otherBrAmountArr = [
        ...formikValues.otherRevenueBreakdown.otherProfitDepartmentsAmounts,
      ];
      otherBrAmountArr[fieldIndex] = calc;
      otherBrArr[fieldIndex] = val;

      return {
        otherRevenueBreakdown: {
          ...formikValues.otherRevenueBreakdown,
          otherProfitDepartments: otherBrArr,
          otherProfitDepartmentsAmounts: otherBrAmountArr,
        },
      };
    } else if (`${name}`.includes("retail")) {
      let otherBrArr = [...formikValues.otherRevenueBreakdown.retail];
      let otherBrAmountArr = [
        ...formikValues.otherRevenueBreakdown.retailAmounts,
      ];
      otherBrAmountArr[fieldIndex] = calc;
      otherBrArr[fieldIndex] = val;

      return {
        otherRevenueBreakdown: {
          ...formikValues.otherRevenueBreakdown,
          retail: otherBrArr,
          retailAmounts: otherBrAmountArr,
        },
      };
    } else if (`${name}`.includes("miscellaneous")) {
      let otherBrArr = [...formikValues.otherRevenueBreakdown.miscellaneous];
      let otherBrAmountArr = [
        ...formikValues.otherRevenueBreakdown.miscellaneousAmounts,
      ];
      otherBrAmountArr[fieldIndex] = calc;
      otherBrArr[fieldIndex] = val;

      return {
        otherRevenueBreakdown: {
          ...formikValues.otherRevenueBreakdown,
          miscellaneous: otherBrArr,
          miscellaneousAmounts: otherBrAmountArr,
        },
      };
    } else if (`${name}`.includes("rental")) {
      let otherBrArr = [...formikValues.otherRevenueBreakdown.rental];
      let otherBrAmountArr = [
        ...formikValues.otherRevenueBreakdown.rentalAmounts,
      ];
      otherBrAmountArr[fieldIndex] = calc;
      otherBrArr[fieldIndex] = val;

      return {
        otherRevenueBreakdown: {
          ...formikValues.otherRevenueBreakdown,
          rental: otherBrArr,
          rentalAmounts: otherBrAmountArr,
        },
      };
    } else if (`${name}`.includes("other1")) {
      let otherBrArr = [...formikValues.otherRevenueBreakdown.other1];
      let otherBrAmountArr = [
        ...formikValues.otherRevenueBreakdown.other1Amounts,
      ];
      otherBrAmountArr[fieldIndex] = calc;
      otherBrArr[fieldIndex] = val;

      return {
        otherRevenueBreakdown: {
          ...formikValues.otherRevenueBreakdown,
          other1: otherBrArr,
          other1Amounts: otherBrAmountArr,
        },
      };
    }
  }
};

//ASSET LIST STEP 9 STANDARD AND PACKAGE
//HAVE TWO METHOD, ONE OF THEM FOR THE OCCUPANCY RATE CALCULATION AND THE SECOND ONE FOR THE OCCUPANCY RATE FIELD [5] THAT HAVE DIFFERENT FORMULA
export const assetListStep9calc = (
  val = -1,
  index,
  fieldName,
  formikValues
) => {
  if (fieldName === "totalSellable") {
    let newUnitMix = formikValues.unitMix;
    console.log("val", val);
    if (val == -1) {
      let sumCalcArr = [];
      let sumSalesPriceArr = [];
      let totalSellableArr = [];
      let conSales = true;
      formikValues.unitMix.forEach((el, ind) => {
        sumCalcArr.push(el.numKeys * el.totalSellable);
        if (el.numKeys != "" && el.totalSellable != "" && el.salesPrice != "") {
          sumSalesPriceArr.push(el.numKeys * el.totalSellable * el.salesPrice);
          totalSellableArr.push(el.totalSellable);
        } else {
          conSales = false;
        }
      });
      let sumCalc = 0;
      let sumSalesPrice = 0;
      let sumTotalSellable = 0;
      sumCalcArr.forEach((val) => (sumCalc = val + sumCalc));
      sumSalesPriceArr.forEach((val) => (sumSalesPrice = val + sumSalesPrice));
      totalSellableArr.forEach(
        (val) => (sumTotalSellable = val + sumTotalSellable)
      );
      sumSalesPrice = sumSalesPrice / sumTotalSellable;
      //sumCalc = sumCalc / (formikValues.Efficiency / 100);
      sumCalc = sumCalc;

      return {
        ...formikValues,
        calcTotalSellableGla: sumCalc,
        ...(formikValues.Efficiency !== "" && {
          calcTotalGfaBrandedRes: sumCalc / (formikValues.Efficiency / 100),
        }),
        ...(conSales && {
          calcTotalSalesPrice: sumSalesPrice,
        }),
      };
    } else {
      let sumCalcArr = [];
      let numKeysArr = [];

      let sumSalesPriceArr = [];
      let totalSellableArr = [];
      let conSales = true;

      formikValues.unitMix.forEach((el, ind) => {
        if (index != ind) {
          if (el.numKeys != "" && el.totalSellable != "") {
            sumCalcArr.push(el.numKeys * el.totalSellable);
            numKeysArr.push(el.numKeys);
            if (
              el.numKeys != "" &&
              el.totalSellable != "" &&
              el.salesPrice != ""
            ) {
              sumSalesPriceArr.push(
                el.numKeys * el.totalSellable * el.salesPrice
              );
            } else {
              conSales = false;
            }
            totalSellableArr.push(el.totalSellable);
          }
        } else {
          if (fieldName == "numKeys") {
            if (el.totalSellable != "") {
              sumCalcArr.push(val * el.totalSellable);
              numKeysArr.push(val);

              if (val != "" && el.totalSellable != "" && el.salesPrice != "") {
                sumSalesPriceArr.push(val * el.totalSellable * el.salesPrice);
              } else {
                conSales = false;
              }
              totalSellableArr.push(el.totalSellable);
              newUnitMix[index].numKeys = val;
            }
          } else if (fieldName == "totalSellable") {
            if (el.numKeys != "") {
              sumCalcArr.push(el.numKeys * val);
              numKeysArr.push(el.numKeys);
              if (el.numKeys != "" && val != "" && el.salesPrice != "") {
                sumSalesPriceArr.push(el.numKeys * val * el.salesPrice);
              } else {
                conSales = false;
              }
              totalSellableArr.push(val);
              newUnitMix[index].totalSellable = val;
            }
          }
        }
      });
      let sumCalcTotalSellable = 0;
      let sumCalc = 0;
      let sumNumOfKeysCalc = 0;
      let sumSalesPrice = 0;
      let sumTotalSellableUnit = 0;

      sumCalcArr.forEach((val) => (sumCalc = val + sumCalc));
      numKeysArr.forEach((val) => (sumNumOfKeysCalc = val + sumNumOfKeysCalc));

      sumSalesPriceArr.forEach((val) => (sumSalesPrice = val + sumSalesPrice));
      totalSellableArr.forEach(
        (val) => (sumTotalSellableUnit = val + sumTotalSellableUnit)
      );
      sumSalesPrice = sumSalesPrice / sumTotalSellableUnit;
      console.log("Sales form total sellable", conSales, sumSalesPrice);

      sumCalcTotalSellable = sumCalc;
      sumCalcTotalSellable = sumCalcTotalSellable;
      console.log("first", sumNumOfKeysCalc, numKeysArr);
      return {
        ...formikValues,
        unitMix: newUnitMix,
        calcTotalSellableGla: sumCalcTotalSellable,

        calcTotalNumberOfKeys: sumNumOfKeysCalc,
        ...(formikValues.Efficiency !== "" && {
          calcTotalGfaBrandedRes:
            sumCalcTotalSellable / (formikValues.Efficiency / 100),
        }),
        ...(conSales && {
          calcTotalSalesPrice: sumSalesPrice,
        }),
      };
    }
  }

  if (fieldName === "baseCost") {
    let newUnitMix = formikValues.unitMix;

    if (val == -1) {
    } else {
      let sumCalcArr = [];
      let numKeysArr = [];

      let devidedByArr = [];
      formikValues.unitMix.forEach((el, ind) => {
        if (index != ind) {
          devidedByArr.push(el.numKeys);
        } else if (fieldName === "numKeys" && index == ind) {
          devidedByArr.push(val);
        } else if (fieldName === "baseCost" && index == ind) {
          devidedByArr.push(el.numKeys);
        } else if (fieldName === "baseCost" && index != ind) {
          devidedByArr.push(el.numKeys);
        }
        if (index != ind) {
          if (el.numKeys != "" && el.baseCost != "") {
            sumCalcArr.push(el.numKeys * el.baseCost);
            numKeysArr.push(el.numKeys);
          }
        } else {
          if (fieldName == "numKeys") {
            if (el.baseCost != "") {
              sumCalcArr.push(val * el.baseCost);
              numKeysArr.push(val);
              newUnitMix[index].numKeys = val;
            }
          } else if (fieldName == "baseCost") {
            if (el.numKeys != "") {
              sumCalcArr.push(el.numKeys * val);
              numKeysArr.push(el.numKeys);
              newUnitMix[index].baseCost = val;
            }
          }
        }
      });
      let sumCalcTotalBaseCost = formikValues.unitMix.reduce((acc, unit) => {
        return acc + (unit.numKeys || 0) * (unit.baseCost || 0)
      }, 0) / formikValues.unitMix.reduce((acc, unit) => {
        return acc + (unit.numKeys || 0)
      }, 0);
      // let sumCalc = 0;
      // let sumNumOfKeysCalc = 0;
      //
      // sumCalcArr.forEach((val) => (sumCalc = val + sumCalc));
      //
      // numKeysArr.forEach((val) => (sumNumOfKeysCalc = val + sumNumOfKeysCalc));
      // sumCalcTotalBaseCost = sumCalc;
      // if (devidedByArr.length > 0) {
      //   sumCalcTotalBaseCost = sumCalc / devidedByArr.length;
      //   sumCalcTotalBaseCost = sumCalcTotalBaseCost;
      //
      //   console.log("devCalc", sumCalcTotalBaseCost, devidedByArr.length);
      // }
      //
      return {
        ...formikValues,
        unitMix: newUnitMix,
        calcTotalBaseCost: sumCalcTotalBaseCost,
      };
    }
  }

  if (fieldName === "salesPrice") {
    let newUnitMix = formikValues.unitMix;

    if (val == -1) {
      let sumCalcArr = [];
      let sumTotalSellableArr = [];
      formikValues.unitMix.forEach((el, ind) => {
        sumCalcArr.push(el.numKeys * el.salesPrice * el.totalSellable);
        sumTotalSellableArr.push(el.totalSellable);
      });
      let sumCalc = 0;
      let sumTotalSellableCalc = 0;
      sumCalcArr.forEach((val) => (sumCalc = val + sumCalc));
      sumTotalSellableArr.forEach(
        (val) => (sumTotalSellableCalc = val + sumTotalSellableCalc)
      );
      return {
        ...formikValues,
        calcTotalSalesPrice: sumCalc / sumTotalSellableCalc,
      };
    } else {
      let sumCalcArr = [];
      let numKeysArr = [];
      let totalSellableArr = [];

      let devidedByArr = [];
      formikValues.unitMix.forEach((el, ind) => {
        if (el.totalSellable !== "") {
          totalSellableArr.push(el.totalSellable);
        }
        /* if (index != ind) {
          devidedByArr.push(el.numKeys);
        } else if (fieldName === "numKeys" && index == ind) {
          devidedByArr.push(val);
        } else if (fieldName === "salesPrice" && index == ind) {
          devidedByArr.push(el.numKeys);
        } else if (fieldName === "salesPrice" && index != ind) {
          devidedByArr.push(el.numKeys);
        } */
        /* if (index != ind) {
          devidedByArr.push(el.numKeys);
        } else if (fieldName === "numKeys" && index == ind) {
          devidedByArr.push(val);
        } else if (fieldName === "salesPrice" && index == ind) {
          devidedByArr.push(el.numKeys);
        } else if (fieldName === "salesPrice" && index != ind) {
          devidedByArr.push(el.numKeys);
        } */
        if (index != ind) {
          if (el.numKeys != "" && el.salesPrice != "" && el.totalSellable) {
            sumCalcArr.push(el.numKeys * el.salesPrice * el.totalSellable);
            numKeysArr.push(el.numKeys);
          }
        } else {
          if (fieldName == "numKeys") {
            if (el.salesPrice != "") {
              sumCalcArr.push(val * el.salesPrice * el.totalSellable);
              numKeysArr.push(val);
              newUnitMix[index].numKeys = val;
            }
          } else if (fieldName == "salesPrice") {
            if (el.numKeys != "") {
              sumCalcArr.push(el.numKeys * el.totalSellable * val);
              numKeysArr.push(el.numKeys);
              newUnitMix[index].salesPrice = val;
            }
          }
        }
      });
      // let sumCalcTotalSalesPrice = 0;
      // let sumCalc = 0;
      // let sumNumOfKeysCalc = 0;
      // let sumTotalSellable = 0;
      //
      // sumCalcArr.forEach((val) => (sumCalc = val + sumCalc));
      // totalSellableArr.forEach(
      //   (val) => (sumTotalSellable = val + sumTotalSellable)
      // );
      //
      // numKeysArr.forEach((val) => (sumNumOfKeysCalc = val + sumNumOfKeysCalc));
      // sumCalcTotalSalesPrice = sumCalc;
      // console.log("sales", sumCalc, sumTotalSellable);
      // console.log("sales length", newUnitMix.length, totalSellableArr.length);
      // if (totalSellableArr.length > 0) {
      //   sumCalcTotalSalesPrice = sumCalc / sumTotalSellable;
      //
      //   sumCalcTotalSalesPrice = sumCalcTotalSalesPrice;
      //   console.log("devCalc", sumCalcTotalSalesPrice);
      // }
      /* if (devidedByArr.length > 0) {
        sumCalcTotalSalesPrice = sumCalc / devidedByArr.length;

        sumCalcTotalSalesPrice = isDecimalNumber(sumCalcTotalSalesPrice)
          ? sumCalcTotalSalesPrice.toFixed(2)
          : sumCalcTotalSalesPrice;
        console.log("devCalc", sumCalcTotalSalesPrice, devidedByArr.length);
      } */
      let sumCalcTotalSalesPrice = formikValues.unitMix.reduce((acc, unit) => {
        return acc + (unit.numKeys || 0) * (unit.salesPrice || 0)
      }, 0) / formikValues.unitMix.reduce((acc, unit) => {
        return acc + (unit.numKeys || 0)
      }, 0);
      return {
        ...formikValues,
        unitMix: newUnitMix,
        calcTotalSalesPrice: sumCalcTotalSalesPrice,
      };
    }
  }

  if (fieldName === "totalNumKeysCalc") {
    console.log("nummmmmmmmmm", val);
    let newUnitMix = formikValues.unitMix;
    // console.log("assetListStep9calc", val, index, fieldName, formikValues);

    let numKeysArr = [];
    let baseCostArr = [];
    let salesPriceArr = [];
    let totalSellableArr = [];

    formikValues.unitMix.forEach((el, ind) => {
      if (el.totalSellable !== "") {
        totalSellableArr.push(el.totalSellable);
      }
      if (index != ind) {
        if (el.numKeys != "") {
          numKeysArr.push(el.numKeys);
          baseCostArr.push(el.baseCost * el.numKeys);
          salesPriceArr.push(el.salesPrice * el.numKeys * el.totalSellable);
        }
      } else {
        if (fieldName == "totalNumKeysCalc") {
          numKeysArr.push(val);

          baseCostArr.push(el.baseCost * val);
          salesPriceArr.push(el.salesPrice * val * el.totalSellable);
          newUnitMix[index].numKeys = val;
        }
      }
    });
    let sumNumOfKeysCalc = 0;
    let sumBaseCostCalc = 0;
    let sumSalesPriceCalc = 0;
    let sumTotalSellableCalc = 0;
    let conBase = true;
    let conSales = true;

    numKeysArr.forEach((val) => (sumNumOfKeysCalc = val + sumNumOfKeysCalc));
    baseCostArr.forEach((val) => {
      if (val != "") {
        sumBaseCostCalc = val + sumBaseCostCalc;
      } else {
        conBase = false;
      }
    });
    salesPriceArr.forEach((val) => {
      if (val != "") {
        sumSalesPriceCalc = val + sumSalesPriceCalc;
      } else {
        conSales = false;
      }
    });
    /* console.log(
      "from num of keys",
      salesPriceArr.length,
      totalSellableArr.length
    ); */
    if (salesPriceArr.length === totalSellableArr.length) {
      totalSellableArr.forEach(
        (val) => (sumTotalSellableCalc = val + sumTotalSellableCalc)
      );
      sumSalesPriceCalc = sumSalesPriceCalc / sumTotalSellableCalc;
    } else {
      conSales = false;
    }
    //console.log("from num of keys", sumSalesPriceCalc);

    console.log(
      "sumCacl",
      sumNumOfKeysCalc,
      numKeysArr,
      baseCostArr,
      salesPriceArr
    );
    if (formikValues.unitMix.length > 0) {
      sumBaseCostCalc = sumBaseCostCalc / formikValues.unitMix.length;
      //sumBaseCostCalc = sumBaseCostCalc.toFixed(3);
      sumSalesPriceCalc = sumSalesPriceCalc / formikValues.unitMix.length;
      //sumSalesPriceCalc = sumSalesPriceCalc.toFixed(3);
      console.log("devCalc", sumBaseCostCalc, formikValues.unitMix.length);
    }

    return {
      ...formikValues,
      unitMix: newUnitMix,
      calcTotalNumberOfKeys: sumNumOfKeysCalc,
      ...(conSales && {
        calcTotalSalesPrice: sumSalesPriceCalc,
      }),
      ...(conBase && {
        calcTotalBaseCost: sumBaseCostCalc,
      }),
    };
  }
};
export const assetListStep9HandleCalc = (name, val, formikValues) => {
  console.log("vaaaaaaaaaaal", val, formikValues?.calcTotalSellableGla);
  if (name == "Efficiency") {
    if (
      !!formikValues?.calcTotalSellableGla &&
      formikValues?.calcTotalSellableGla > 0 &&
      val > 0
    ) {
      let calcTotalSellableGla =
        formikValues?.calcTotalSellableGla / (val / 100);
      calcTotalSellableGla = calcTotalSellableGla;
      return {
        //...formikValues,
        calcTotalGfaBrandedRes: calcTotalSellableGla,
        Efficiency: val,
        //occupancyIncRateYear: newOccArr,
      };
    } else {
      return 0;
    }
  }
};
