import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Text,
  GridItem,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Heading,
  Flex,
  Divider,
  Badge,
} from "@chakra-ui/react";
import { FieldArray } from "formik";

import { InputFieldAverage } from "../../../components/inputField/InputFieldAverage";
import { MainButton } from "../../../components/button/MainButton";
import { MainTitle } from "../../../components/mainTitle/MainTitle";

import rightArrow from "../../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../../assets/images/icons/Arrow-Left.svg";
import percent from "../../../assets/images/icons/filled.svg";

export const HotelKpisStep4 = ({
  nextPage,
  prevPage,
  errors,
  touched,
  onChange,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  onDraftOpen,
  formikValues,
  disableSave,
  setDismissSave,
  positioning,
  isRestore,
  validateStep,
  setValidateStep,
  isModelWarningOpen,
  handleAccordErrors,
  closeToast,
}) => {
  const [arrayKeys, setArrayKeys] = useState([]);
  const inputRef = useRef(null);

  let form4Data = [];

  if (
    formikValues?.positioningValues?.averageGFAAndKey &&
    formikValues?.positioningValues?.costPerKey_SAR &&
    formikValues?.positioningValues?.costPerSqMOfGFA_SAR &&
    formikValues?.positioningValues?.standardRoomSize
  ) {
    formikValues.positioningValues.standardRoomSize.forEach((item) => {
      form4Data.push(item);
    });
    formikValues.positioningValues.averageGFAAndKey.forEach((item) => {
      form4Data.push(item);
    });

    formikValues.positioningValues.costPerKey_SAR.forEach((item) => {
      form4Data.push(item);
    });
    formikValues.positioningValues.costPerSqMOfGFA_SAR.forEach((item) => {
      form4Data.push(item);
    });
  }
  let emptyform4Data = [];
  form4Data.forEach((item) => {
    if (
      item.low !== "" &&
      !isNaN(`${item.low}`) &&
      parseFloat(item.low) >= 0 &&
      item.high !== "" &&
      !isNaN(`${item.high}`) &&
      parseFloat(item.high) >= 0 &&
      item.average !== "" &&
      !isNaN(`${item.average}`) &&
      parseFloat(item.average) >= 0 &&
      parseFloat(item.low) <= parseFloat(item.high)
    ) {
      emptyform4Data.push(item);
    }
  });

  let isDisabledForm = false;

  if (emptyform4Data.length !== form4Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }
  /*if (formikValues?.form4) {
    formikValues?.form4.forEach((el) => {
      el.posFields.forEach((item) => {
        form4Data.push({
          parentName: item.parentName,
          fieldName: "low",
          value: item.low,
          title: item.title,
        });
        form4Data.push({
          parentName: item.parentName,
          fieldName: "high",
          value: item.high,
          title: item.title,
        });
      });
    });
  }

   let emptyform4Data = [];
  form4Data.forEach((item) => {
    if (item.value !== "" && !isNaN(`${item.value}`) && item.value >= 1) {
      emptyform4Data.push(item);
    }
  });

  let isDisabledForm = false;

  if (emptyform4Data.length !== form4Data.length) {
    isDisabledForm = true;
  } */
  let accordTitle = [
    "Average GFA / Key",
    "Cost per key",
    "Cost per SQM of GFA",
    "Standard Room Size",
  ];

  let arrKeys = [];
  if (formikValues?.positioningValues) {
    arrKeys = Object.keys(formikValues.positioningValues);
    if (JSON.stringify(arrayKeys) !== JSON.stringify(arrKeys)) {
      setArrayKeys(arrKeys);
    }
  }

  console.log("arrKeys", arrKeys);
  let renderAccrodionFields = (mData, pos, icon, name) => {
    let counterMas = 0;

    counterMas = counterMas + 1;
    if (counterMas <= mData.length) {
      //let counterComment = 0;

      //counterComment = counterComment + 1;
      return (
        <>
          <AccordionItem mb="4" borderBottom="none">
            <Heading
              borderLeft="1px"
              borderBottom="1px"
              borderRight="1px"
              borderColor="#E6E6E6"
            >
              <AccordionButton
                bg="#FCFCFC"
                className={arrayKeys[pos]}
                onClick={() => {
                  setTimeout(() => {
                    document
                      .querySelector(`.accordionList`)
                      .scrollIntoView({ block: "start" });
                  }, 500);
                }}
              >
                <Box flex="1" textAlign="left">
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                    textTransform="uppercase"
                  >
                    {accordTitle[pos]}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Heading>

            <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
              <FieldArray
                name={`${arrayKeys[pos]}`}
                render={(arrayHelper) => (
                  <>
                    {formikValues.positioningValues[`${arrayKeys[pos]}`] !==
                      undefined &&
                      formikValues.positioningValues[`${arrayKeys[pos]}`].map(
                        (formArr, index) => {
                          let posObj = positioning.find((el) => {
                            if (el?.id) {
                              return formArr.positioningId === el.id;
                            } else {
                              return formArr.positioningId === el.positioningId;
                            }
                          });
                          return (
                            <Box key={index}>
                              <Box
                                width="100%"
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Text
                                  fontSize="12px"
                                  color="black"
                                  fontWeight="400"
                                  letterSpacing="0.04em"
                                  textAlign="left"
                                  textTransform="uppercase"
                                  mb={4}
                                  mt={3}
                                >
                                  {posObj?.posName
                                    ? posObj.posName
                                    : `${formArr.positioningId}`
                                        .split("_")
                                        .join(" ")}
                                </Text>
                              </Box>

                              <GridItem colSpan={2}>
                                <SimpleGrid
                                  columns={{ sm: 3, "2xl": 3 }}
                                  spacing={{ sm: "25px", "2xl": "40px" }}
                                  width={{ sm: "50%", "2xl": "40%" }}
                                >
                                  <GridItem
                                    w={{
                                      sm: "170px",
                                      md: "170px",
                                      xl: "170px",
                                      "2xl": "180px",
                                    }}
                                  >
                                    <InputFieldAverage
                                      placeholder="0.00"
                                      label="Low"
                                      inputType="number"
                                      inputId={`positioningValues.${arrayKeys[pos]}.${index}.low`}
                                      inputName={`positioningValues.${arrayKeys[pos]}.${index}.low`}
                                      errors={errors}
                                      touched={touched}
                                      isRequired
                                      values={
                                        formikValues?.positioningValues[
                                          arrayKeys[pos]
                                        ][index]?.low
                                      }
                                      labelCenter={true}
                                      uppercase={false}
                                      onChange={onChange}
                                      inputRef={inputRef}
                                      // icon={!icon ? percent : ""}
                                      smallLeftIcon={
                                        icon &&
                                        (accordTitle[pos] ===
                                          "Cost per SQM of GFA" ||
                                          accordTitle[pos] === "Cost per key")
                                          ? "SAR"
                                          : "SQM"
                                      }
                                      disable={isRestore}
                                      validateField={validateStep}
                                      directVal={true}
                                    />
                                  </GridItem>
                                  <GridItem
                                    w={{
                                      sm: "170px",
                                      md: "170px",
                                      xl: "170px",
                                      "2xl": "180px",
                                    }}
                                  >
                                    <InputFieldAverage
                                      placeholder="0.00"
                                      label="High"
                                      inputType="number"
                                      inputId={`positioningValues.${arrayKeys[pos]}.${index}.high`}
                                      inputName={`positioningValues.${arrayKeys[pos]}.${index}.high`}
                                      errors={errors}
                                      touched={touched}
                                      isRequired
                                      values={
                                        formikValues?.positioningValues[
                                          arrayKeys[pos]
                                        ][index]?.high
                                      }
                                      labelCenter={true}
                                      uppercase={false}
                                      onChange={onChange}
                                      inputRef={inputRef}
                                      // icon={!icon ? percent : ""}
                                      smallLeftIcon={
                                        icon &&
                                        (accordTitle[pos] ===
                                          "Cost per SQM of GFA" ||
                                          accordTitle[pos] === "Cost per key")
                                          ? "SAR"
                                          : "SQM"
                                      }
                                      disable={isRestore}
                                      validateField={validateStep}
                                      directVal={true}
                                    />
                                  </GridItem>
                                  <GridItem
                                    w={{
                                      sm: "170px",
                                      md: "170px",
                                      lg: "170px",
                                      "2xl": "180px",
                                    }}
                                  >
                                    <InputFieldAverage
                                      placeholder="0.00"
                                      label="Average"
                                      inputType="number"
                                      inputId={`positioningValues.${arrayKeys[pos]}.${index}.average`}
                                      inputName={`positioningValues.${arrayKeys[pos]}.${index}.average`}
                                      errors={errors}
                                      touched={touched}
                                      isRequired
                                      values={
                                        formikValues?.positioningValues[
                                          arrayKeys[pos]
                                        ][index]?.average
                                      }
                                      labelCenter={true}
                                      uppercase={false}
                                      //
                                      onChange={onChange}
                                      inputRef={inputRef}
                                      disable={true}
                                      avg={true}
                                      // icon={!icon ? percent : ""}
                                      // smallLeftIcon={icon ? "SQM" : "SAR"}
                                      noBg={true}
                                      validateField={false}
                                      directVal={true}
                                    />
                                  </GridItem>
                                </SimpleGrid>
                                <Box>
                                  {parseFloat(
                                    formikValues?.positioningValues[
                                      arrayKeys[pos]
                                    ][index]?.low
                                  ) >
                                    parseFloat(
                                      formikValues?.positioningValues[
                                        arrayKeys[pos]
                                      ][index]?.high
                                    ) && (
                                    <Box
                                      color="#e14642"
                                      fontWeight="400"
                                      fontSize="10px"
                                      lineHeight="17px"
                                      letterSpacing="0.04em"
                                      marginTop="-5px"
                                    >
                                      Low value should be less than high value
                                    </Box>
                                  )}
                                </Box>
                              </GridItem>
                            </Box>
                          );
                        }
                      )}
                  </>
                )}
              />
            </AccordionPanel>
          </AccordionItem>
        </>
      );
    }
  };

  return (
    <>
      <Box alignItems="flex-start" width="100%" mb={5}>
        <MainTitle title="HOTEL KPIs" transform="unset" />

        <Badge
          fontSize="16px"
          borderRadius="5px"
          bg=" rgba(235, 192, 63, 0.15);"
          color="#1D1F22"
          colorScheme="blackAlpha"
          fontWeight="400"
          lineHeight="24px"
          letterSpacing="0.06em"
          padding="10px 24px"
          mb="3"
        >
          Positioning
        </Badge>
      </Box>

      <Accordion
        width="100%"
        allowToggle
        color="black"
        ref={inputRef}
        defaultIndex={[0]}
        className="accordionList"
      >
        {formikValues?.positioningValues?.standardRoomSize &&
          renderAccrodionFields(
            formikValues?.positioningValues?.standardRoomSize,
            3,
            "SQM",
            "standardRoomSize"
          )}
        {formikValues?.positioningValues?.averageGFAAndKey &&
          renderAccrodionFields(
            formikValues?.positioningValues?.averageGFAAndKey,
            0,
            "SQM",
            "averageGFAAndKey"
          )}
        {formikValues?.positioningValues?.costPerKey_SAR &&
          renderAccrodionFields(
            formikValues?.positioningValues?.costPerKey_SAR,
            1,
            "costPerKey_SAR"
          )}
        {formikValues?.positioningValues?.costPerSqMOfGFA_SAR &&
          renderAccrodionFields(
            formikValues?.positioningValues?.costPerSqMOfGFA_SAR,
            2,
            "costPerSqMOfGFA_SAR"
          )}
      </Accordion>

      <Divider orientation="horizontal" mt="30px" mb="30px" />

      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          btnStyle="transparent"
          disabledBtn={isRestore}
          fontColor="dark"
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/HotelKpisHistory`}
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
                //setValidateErrors(true);
              }}
            />
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  handleAccordErrors(errors);
                  setValidateStep(true);
                  handleAccordErrors();
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  nextPage();
                  closeToast();
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
            {/*  <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm}
              onPress={() => {
                nextPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
