import React, { useEffect, useState } from "react";

import { Box, Text, Flex, Divider } from "@chakra-ui/react";

import { InputFieldValidation } from "../../../components/inputField/InputFieldValidation";
import { MainButton } from "../../../components/button/MainButton";
import { MainTitle } from "../../../components/mainTitle/MainTitle";

import rightArrow from "../../../assets/images/icons/rightArrow2.svg";
import percent from "../../../assets/images/icons/filled.svg";

export const DevAndFinStep1 = ({
  nextPage,
  prevPage,
  errors,
  touched,
  onChange,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  onDraftOpen,
  formikValues,
  dirty,
  setDisableSave,
  disableSave,
  setDismissSave,
  isRestore,
  isModelWarningOpen,
  setValidateStep,
  validateStep,
  closeToast,
}) => {
  let form1Data = [];
  form1Data.push({
    fieldName: "infrastructureCost",
    value: formikValues.infrastructureCost,
  });
  form1Data.push({
    fieldName: "generalDepreciation1",
    value: formikValues.generalDepreciation1,
  });
  form1Data.push({ fieldName: "parkingCost", value: formikValues.parkingCost });
  form1Data.push({
    fieldName: "generalDepreciation2",
    value: formikValues.generalDepreciation2,
  });
  form1Data.push({
    fieldName: "landFreehold",
    value: formikValues.landFreehold,
  });
  form1Data.push({
    fieldName: "constructionCost",
    value: formikValues.constructionCost,
  });
  form1Data.push({
    fieldName: "breakDepreciation1",
    value: formikValues.breakDepreciation1,
  });
  form1Data.push({ fieldName: "ffeAndOse", value: formikValues.ffeAndOse });
  form1Data.push({
    fieldName: "breakDepreciation2",
    value: formikValues.breakDepreciation2,
  });
  form1Data.push({ fieldName: "softCosts", value: formikValues.softCosts });
  form1Data.push({
    fieldName: "breakDepreciation3",
    value: formikValues.breakDepreciation3,
  });
  form1Data.push({ fieldName: "preOpening", value: formikValues.preOpening });
  form1Data.push({
    fieldName: "breakDepreciation4",
    value: formikValues.breakDepreciation4,
  });
  form1Data.push({ fieldName: "tsaFee", value: formikValues.tsaFee });
  form1Data.push({ fieldName: "ITbudget", value: formikValues.ITbudget });
  form1Data.push({
    fieldName: "breakDepreciation5",
    value: formikValues.breakDepreciation5,
  });
  form1Data.push({
    fieldName: "neomHotelDevFee",
    value: formikValues.neomHotelDevFee,
  });
  form1Data.push({
    fieldName: "breakDepreciation6",
    value: formikValues.breakDepreciation6,
  });
  form1Data.push({
    fieldName: "contingencyCost",
    value: formikValues.contingencyCost,
  });
  form1Data.push({
    fieldName: "breakDepreciation7",
    value: formikValues.breakDepreciation7,
  });
  form1Data.push({
    fieldName: "breakDepreciation8",
    value: formikValues.breakDepreciation8,
  });
  form1Data.push({
    fieldName: "initialWorkingCapital",
    value: formikValues.initialWorkingCapital,
  });
  form1Data.push({
    fieldName: "assetManagementFee",
    value: formikValues.assetManagementFee,
  });
  let emptyform1Data = [];
  form1Data.forEach((item, ind) => {
    if (item.value !== "" && !isNaN(`${item.value}`) && item.value >= 0) {
      if (
        ind === 5 ||
        ind === 7 ||
        ind === 9 ||
        ind === 11 ||
        ind === 16 ||
        ind === 18 ||
        ind === 21
      ) {
        if (item.value <= 100) {
          emptyform1Data.push(item);
        }
      } else {
        emptyform1Data.push(item);
      }
    }
  });
  let isDisabledForm = false;

  if (emptyform1Data.length !== form1Data.length) {
    isDisabledForm = true;
  }
  // console.log("form", form1Data);
  /* if (emptyform1Data.length >= 4) {
    // the default value is 3
    if (disableSave) {
      setDisableSave(false);
    }
  } */
  return (
    <>
      <Box alignItems="flex-start" width="100%" mb={5}>
        <MainTitle title="Development cost breakdown" />
      </Box>

      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        width="100%"
        position="relative"
      >
        <Box maxW="45%" width="45%" alignSelf="flex-start">
          <Text className="title_md" mb={4}>
            General
          </Text>
          <Box display="flex" width="100%" gap="4%" flexWrap="wrap">
            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Infrastructure Cost"
                inputType="number"
                inputId="infrastructureCost"
                inputName="infrastructureCost"
                errors={errors}
                touched={touched}
                isRequired
                /*   icon={percent} */
                smallLeftIcon={"SAR"}
                inputPL={true}
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.infrastructureCost}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Depreciation (in Years)"
                inputType="number"
                inputId="generalDepreciation1"
                inputName="generalDepreciation1"
                errors={errors}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.generalDepreciation1}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Parking Cost"
                inputType="number"
                inputId="parkingCost"
                inputName="parkingCost"
                errors={errors}
                touched={touched}
                isRequired
                /*                 icon={percent}
                 */ minNum="0"
                smallLeftIcon={"SAR"}
                inputPL={true}
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.parkingCost}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Depreciation (in Years)"
                inputType="number"
                inputId="generalDepreciation2"
                inputName="generalDepreciation2"
                errors={errors}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.generalDepreciation2}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <InputFieldValidation
              label="Land Cost - Freehold"
              inputType="number"
              inputId="landFreehold"
              inputName="landFreehold"
              errors={errors}
              touched={touched}
              smallLeftIcon={"SAR"}
              inputPL={true}
              isRequired
              /*               icon={percent}
               */ minNum="0"
              placeholder="0.00"
              onChange={setFieldValue}
              values={formikValues.landFreehold}
              isDisabled={isRestore}
              validateField={validateStep}
              directVal={true}
            />
          </Box>
        </Box>

        <Box maxW="45%" width="45%" alignSelf="flex-start">
          <Text className="title_md" mb={4} mt={7}>
            Breakdown
          </Text>
          <Box display="flex" width="100%" gap="4%" flexWrap="wrap">
            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Construction Cost"
                inputType="number"
                inputId="constructionCost"
                inputName="constructionCost"
                errors={errors}
                touched={touched}
                isRequired
                icon={percent}
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.constructionCost}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Depreciation (in Years)"
                inputType="number"
                inputId="breakDepreciation1"
                inputName="breakDepreciation1"
                errors={errors}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.breakDepreciation1}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="FF&E & OS&E"
                inputType="number"
                inputId="ffeAndOse"
                inputName="ffeAndOse"
                errors={errors}
                touched={touched}
                isRequired
                icon={percent}
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.ffeAndOse}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Depreciation (in Years)"
                inputType="number"
                inputId="breakDepreciation2"
                inputName="breakDepreciation2"
                errors={errors}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.breakDepreciation2}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>
            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Soft Costs (Professional Fees)"
                inputType="number"
                inputId="softCosts"
                inputName="softCosts"
                errors={errors}
                touched={touched}
                isRequired
                icon={percent}
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.softCosts}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Depreciation (in Years)"
                inputType="number"
                inputId="breakDepreciation3"
                inputName="breakDepreciation3"
                errors={errors}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.breakDepreciation3}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>
            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Pre-Opening Training & Marketing"
                inputType="number"
                inputId="preOpening"
                inputName="preOpening"
                errors={errors}
                touched={touched}
                isRequired
                icon={percent}
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.preOpening}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Depreciation (in Years)"
                inputType="number"
                inputId="breakDepreciation4"
                inputName="breakDepreciation4"
                errors={errors}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.breakDepreciation4}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>
            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="TSA Fee"
                inputType="number"
                inputId="tsaFee"
                inputName="tsaFee"
                errors={errors}
                touched={touched}
                isRequired
                // icon={percent}
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.tsaFee}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Depreciation (in Years)"
                inputType="number"
                inputId="breakDepreciation5"
                inputName="breakDepreciation5"
                errors={errors}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.breakDepreciation5}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>
            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="IT Budget"
                inputType="number"
                inputId="ITbudget"
                inputName="ITbudget"
                errors={errors}
                touched={touched}
                isRequired
                // icon={percent}
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.ITbudget}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Depreciation (in Years)"
                inputType="number"
                inputId="breakDepreciation6"
                inputName="breakDepreciation6"
                errors={errors}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.breakDepreciation6}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>
            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="NEOM Hotel Development Fee"
                inputType="number"
                inputId="neomHotelDevFee"
                inputName="neomHotelDevFee"
                errors={errors}
                touched={touched}
                isRequired
                icon={percent}
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.neomHotelDevFee}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Depreciation (in Years)"
                inputType="number"
                inputId="breakDepreciation7"
                inputName="breakDepreciation7"
                errors={errors}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.breakDepreciation7}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>
            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Contingency Cost"
                inputType="number"
                inputId="contingencyCost"
                inputName="contingencyCost"
                errors={errors}
                touched={touched}
                isRequired
                icon={percent}
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.contingencyCost}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <Box width={["100%", "100%", "48%"]}>
              <InputFieldValidation
                label="Depreciation (in Years)"
                inputType="number"
                inputId="breakDepreciation8"
                inputName="breakDepreciation8"
                errors={errors}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0.00"
                onChange={setFieldValue}
                values={formikValues.breakDepreciation8}
                isDisabled={isRestore}
                validateField={validateStep}
                directVal={true}
              />
            </Box>

            <InputFieldValidation
              label="Initial Working Capital (% of Year 2 Revenue)"
              inputType="number"
              inputId="initialWorkingCapital"
              inputName="initialWorkingCapital"
              errors={errors}
              touched={touched}
              isRequired
              icon={percent}
              minNum="0"
              placeholder="0.00"
              onChange={setFieldValue}
              values={formikValues.initialWorkingCapital}
              isDisabled={isRestore}
              validateField={validateStep}
              directVal={true}
            />

            <InputFieldValidation
              label="Asset Management Fee (OpEx)"
              inputType="number"
              inputId="assetManagementFee"
              inputName="assetManagementFee"
              errors={errors}
              touched={touched}
              isRequired
              // icon={percent}
              smallLeftIcon={"SAR"}
              inputPL={true}
              minNum="0"
              placeholder="0"
              onChange={setFieldValue}
              values={formikValues.assetManagementFee}
              isDisabled={isRestore}
              validateField={validateStep}
              directVal={true}
            />
          </Box>
        </Box>

        {/* <Box maxW="50%" width="50%" alignSelf="flex-start">
          <DatePickerField
            label="Base Year"
            inputType="date"
            inputId="baseYear"
            inputName="baseYear"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues.baseYear}
            onChange={onChange}
            onBlur={handleBlur}
            minDate={new Date("01-01-2022")}
            maxDate={new Date("01-01-2050")}
            hintText="Select a year between 2022 - 2050"
            icon={calendar}
          />
        </Box> */}
      </Box>

      {/* <Box
        maxW={["100%", "50%", "50%"]}
        width={["100%", "50%", "50%"]}
        alignSelf="flex-start"
        mb={5}
      >
        <SelectListMulti
          dropdownItems={dropdownObj?.benchMarkLevel}
          label="Benchmark level chosen"
          inputId="benchMarkLevel"
          inputName="benchMarkLevel"
          placeholder="Select"
          errors={errors}
          touched={touched}
          isRequired 
          onChange={setFieldValue}
          
          onBlur={handleBlur}
          uppercase={true}
        />
      </Box>

      <Box alignItems="flex-start" width="100%" mb={5}>
        <Button
          fontSize="18px"
          borderRadius="5px"
          bg="#003865"
          colorScheme="blackAlpha"
          fontWeight="400"
          lineHeight="24px"
          letterSpacing="0.06em"
          color="white"
          mb="3"
          ml="1"
        >
          ADR - OTHER REVENUES - OPEX
        </Button>
      </Box> */}

      <Divider orientation="horizontal" mt="30px" mb="30px" />

      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          disabledBtn={isRestore}
          btnStyle="transparent"
          fontColor="dark"
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/DevAndFinHistory`}
            />
          )}

          {!isDisabledForm ? (
            <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={false}
              onPress={() => {
                closeToast();
                nextPage();
                setValidateStep(false);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
          ) : (
            <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={false}
              onPress={() => {
                setValidateStep(true);
                isModelWarningOpen(true);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
          )}
        </Box>
      </Flex>
    </>
  );
};
