import React from "react";
import history from "../../history";

import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import { MainButton } from "../button/MainButton";

export const ModalAlert = ({
  isOpen,
  onClose,
  title,
  desc,
  icon,
  hasDesc,
  confirmationModal = false,
  path,
  pathDraft,
  btnWidthFull,
  haveSaveDraft = true,
  isColorsSwitched,
  disableClose = false,
  isDelete = false,
  onPressSaveDraft,
  selectedID,
  renderRowsAfterDeletion,
  setDismissSave,
  isAssetModal = false,
  navLinkSubmit = false,
  assetVersionModal = false,
  selectAction = false,
  handleSelectModel,
  isProponent = false,
  isSubmitLoading = false,
  isDeleteAssest = false,
  DeleteAsset,
  isHideAssest = false,
  hiddenAsset
}) => {
  let modalProps;
  let hideCloseBtn = !disableClose || !confirmationModal ? false : true;

  if (!disableClose) {
    modalProps = {
      onClose: onClose,
    };
  }

  if (isAssetModal) {
    var lastItem = desc[desc.length - 1] || "";
    desc[desc.length - 1] = lastItem.replace(",", ".");
  }

  const handleDeleteItem = () => {
    if (isDeleteAssest) {
      DeleteAsset(selectedID.split("|")[1])
    } else if (isHideAssest) {
      hiddenAsset(selectedID.split("|")[1], selectedID?.startsWith("hide") ? "hide" : "unhide")
    } else {
      renderRowsAfterDeletion(selectedID);
      if (assetVersionModal) {
        history.push("/AssetsHistory");
      }
    }
    onClose();
  };

  const renderButtons = (confirmModal) => {
    return confirmModal ? (
      <>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          {haveSaveDraft ? (
            <SimpleGrid columns={{ sm: 2, md: 4 }} spacingX="15px" width="90%">
              <GridItem colSpan={2}>
                {selectedID &&
                  (Number(selectedID) >= 0 ||
                    selectedID?.startsWith("draft-") ||
                    selectedID?.startsWith("draft|") ||
                    selectedID?.startsWith("delete|") || selectedID?.startsWith("hide") || selectedID?.startsWith("unhide")) ? (
                  <MainButton
                    btnWidthFull={true}
                    title="Yes"
                    onPress={() => handleDeleteItem()}
                    btnHeight="45px"
                    btnStyle={isColorsSwitched ? "" : "transparent"}
                    border={true}
                    borderColor={isColorsSwitched ? "" : "gray"}
                  />
                ) : (
                  <NavLink to={path}>
                    {selectAction ? (
                      <MainButton
                        btnWidthFull={true}
                        title="Yes"
                        onPress={() => {
                          handleSelectModel();
                          //onClose();
                        }}
                        btnHeight="45px"
                        btnStyle={isColorsSwitched ? "" : "transparent"}
                        border={true}
                        borderColor={isColorsSwitched ? "" : "gray"}
                      />
                    ) : (
                      <MainButton
                        btnWidthFull={true}
                        title="Yes"
                        onPress={onClose}
                        btnHeight="45px"
                        btnStyle={isColorsSwitched ? "" : "transparent"}
                        border={true}
                        borderColor={isColorsSwitched ? "" : "gray"}
                      />
                    )}
                  </NavLink>
                )}
              </GridItem>
              <GridItem colSpan={2}>
                <MainButton
                  title="No"
                  btnStyle={isColorsSwitched ? "transparent" : ""}
                  borderColor={isColorsSwitched ? "gray" : ""}
                  border={isColorsSwitched ? true : false}
                  onPress={() => {
                    onClose();
                    if (setDismissSave) {
                      setDismissSave();
                    }
                  }}
                  btnHeight="45px"
                  btnWidthFull={true}
                />
              </GridItem>
              <GridItem colSpan={4} mt={4}>
                {isDelete == false && isHideAssest == false && (
                  <MainButton
                    title="Save draft"
                    fontColor="dark"
                    disabledBtn={isProponent}
                    btnStyle="transparent"
                    btnWidthFull={true}
                    onPress={onPressSaveDraft}
                  />
                )}
              </GridItem>
            </SimpleGrid>
          ) : (
            <>
              <SimpleGrid
                columns={{ sm: 4, md: 4 }}
                spacingX="15px"
                width="60%"
              >
                <GridItem colSpan={4}>
                  {/* <NavLink to={pathDraft}> */}
                  <MainButton
                    btnWidthFull={true}
                    title="Yes, save draft"
                    btnHeight="45px"
                    btnStyle={isColorsSwitched ? "transparent" : ""}
                    borderColor={isColorsSwitched ? "gray" : ""}
                    border={isColorsSwitched ? true : false}
                    onPress={onPressSaveDraft}
                  />
                  {/* </NavLink> */}
                </GridItem>
              </SimpleGrid>

              <Flex w="100%" justifyContent="center" mt={5}>
                <MainButton
                  title="Go back"
                  fontColor="dark"
                  btnStyle="transparent"
                  btnWidthFull={true}
                  onPress={onClose}
                />
              </Flex>
            </>
          )}
        </Flex>
      </>
    ) : (
      <>
        <Center width="100%">
          {navLinkSubmit ? (
            <MainButton
              onPress={onClose}
              title="Done"
              btnWidthFull={btnWidthFull}
            />
          ) : (
            <NavLink to={path}>
              <MainButton
                onPress={onClose}
                title="Done"
                btnWidthFull={btnWidthFull}
              />
            </NavLink>
          )}
        </Center>
      </>
    );
  };

  return (
    <div className={disableClose ? "modal_disableClose" : ""}>
      <Modal isOpen={isOpen} {...modalProps}>
        <ModalOverlay
          background="rgba(29, 31, 34, 0.5)"
          backdropFilter="blur(7px)"
        />

        <ModalContent
          py={12}
          px={4}
          alignSelf="center"
          maxWidth="520px"
          className="modal_content"
        >
          {isSubmitLoading ? (
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner size="md" />
              </Center>
            </Box>
          ) : (
            <Center flexDirection="column">
              <img
                className="info_icon"
                src={icon}
                width="72px"
                height="72px"
                alt="checked icon"
              />
              <ModalHeader
                textAlign="center"
                color="#1D1F22"
                fontSize="20px"
                fontWeight="400"
                letterSpacing="-0.02em"
                px={5}
                mt={2}
                className="modal_header"
              >
                {title}
              </ModalHeader>
            </Center>
          )}

          {hideCloseBtn && !isSubmitLoading && <ModalCloseButton />}
          {hasDesc && !isSubmitLoading && (
            <ModalBody textAlign="center">
              <Center>
                <Text fontSize="15px">{desc}</Text>
              </Center>
            </ModalBody>
          )}
          {!isSubmitLoading && (
            <ModalFooter>{renderButtons(confirmationModal)}</ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};
