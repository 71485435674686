import { models } from "powerbi-client";
import { useIsMobile } from "../../utils/hooks";
import React, { useEffect, useState } from "react";
import { GetEmbedToken } from "../../api/DashboardAPI";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export const DashboardAssetListSummary = () => {
    console.log("DashboardAssetList");
  let user;
  if (Cookies.get("user_info")) {
    user = jwt_decode(Cookies.get("user_info"));
  }

  const [accessToken, setAccessToken] = useState("");
  const dashboardParams = {
    desktop: {
      main: {
        id: process.env.REACT_APP_DASHBOARD_ASSETLIST_SUMMARY_ID,
        embedUrl: process.env.REACT_APP_DASHBOARD_ASSETLIST_SUMMARY,
        pageName: "ReportSection",
      },
    },
    mobile: {
      main: {
        id: "72fc9764-61ab-40b2-9020-9c40ae24ec9c",
        embedUrl:"https://app.powerbi.com/reportEmbed?reportId=7664d535-27d0-4a13-bf50-d69d577509b8&autoAuth=true&ctid=8288fdf2-0e8b-47a6-abbb-004395ecab56",
        pageName: "ReportSection",
      },
    },
  };

  const isMobile = useIsMobile();

  const screenSizeToUse = isMobile ? "mobile" : "desktop";

  const [reportLevelToUse, setReportLevel] = useState("main");
  const [isEmbedShown, setIsEmbedShown] = useState(
    !user.roles.includes("proponent")
  );

  useEffect(() => {
    setIsEmbedShown(false);
    setTimeout(() => {
      setIsEmbedShown(true);
    }, 250);
  }, [reportLevelToUse, screenSizeToUse]);

  return (
    <div className={"Embed-frame"}>
      
      {isEmbedShown ? (
        <iframe
          className={"Embed-container"}
          title="Embed"
          src={
            dashboardParams[screenSizeToUse][reportLevelToUse]?.embedUrl
          }
          frameBorder="0"
          allowFullScreen={false}
        />
      ) : null}
    </div>
  );
};
