import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import {
  Box,
  Text,
  Flex,
  Divider,
  SimpleGrid,
  GridItem,
  Button,
} from "@chakra-ui/react";
import { FieldArray } from "formik";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { InputFieldCalc } from "../../components/inputField/InputFieldCalc";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import {
  assetListStep4calcOccupancyIncField5,
  assetListStep4HandleCalcOcc,
} from "../../utils/Formula";
import { isDecimalNumber } from "../../utils/General";

import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import percent from "../../assets/images/icons/filled.svg";
import m2 from "../../assets/images/icons/m2.svg";
import plus from "../../assets/images/icons/plus2.svg";
import deleteIcon2 from "../../assets/images/icons/delete2.svg";
import { nullifyYearsField } from "../../utils/General";
import {CalculationButton} from"../../components/calculationModal/calculationButton"

export const AssetsListForm4 = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  formikValues,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  initFormVal,
  setInitFormVal,
  opStartDate,
  unsetStepWarning,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  step,
  setStepWarning,
  performanceRoomTypeLength = 1,
  nextStep,

  occIncRate,
  setOccIncRate,
  isDisabledCalc,
  isDisabledTypes,
  setIsDisabledCalc,
  setIsDisabledTypes,
  setStepperUpdate,
  stepperUpdate,
  validateStep,
  setValidateStep,
  onClickedFormulaOpen,
  onClickedKeysOpen,
  modelWarningOpen,
  isModelWarningOpen,
  validateKeys,
  setValidateKeys,
  closeModalPath,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
  assetData,
  occIncRateGeneral,
  setOccIncRateGeneral,
  setIsCalculateClicked,
  isCalculateClicked,
  user
}) => {
  // const [roomTypes, setRoomTypes] = useState();
  const [roomTypes, setRoomTypes] = useState([...dropdownObj.types]);
  // const [results, setResults] = useState([]);
  const [results, setResults] = useState([...dropdownObj.types]);
  const dataFetchedRef = useRef(false);
  let step1TotalKeys = formikValues?.numberOfKeys;

  const roomTypeGroup = { type: "", numKeys: "", size: "", occRate: "" };

  let occData = [];
  let form4Data = [];
  form4Data.push(formikValues.pricingInflator); //string
  formikValues?.occupancyIncRateYear?.forEach((item, ind) => {
    if (ind !== 4) {
      form4Data.push(item);
      occData.push(item);
    }
  });
  form4Data.push(formikValues.avgLenOfStay); //string
  form4Data.push(formikValues.guestPerOccRoom); //string
  //form4Data.push(formikValues.calcTotalNumbersKeys); //string

  let emptyformOccData = [];
  let emptyform4Data = [];
  formikValues?.roomType?.forEach((item) => {
    form4Data.push(item.type);
    form4Data.push(item.numKeys);
    form4Data.push(item.occRate);
    form4Data.push(item.size);
  });
  form4Data.forEach((item, ind) => {
    if (ind <= 5) {
      if (item !== "" && item >= "0" && item <= "100") {
        emptyform4Data.push(item);
      }
    } else if (ind === 9) {
      if (item % 1 === 0 && item !== "" && item >= "0") {
        emptyform4Data.push(item);
      }
    } else if (ind === 6 || ind === 7) {
      // let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

      if (item !== "" && parseFloat(item) >= "0" && parseFloat(item) < "10") {
        emptyform4Data.push(item);
      }
    } else {
      if (item !== "" && item >= "0") {
        emptyform4Data.push(item);
      }
    }
  });
  occData.forEach((item, ind) => {
    if (item !== "" && item >= "-100") {
      emptyformOccData.push(item);
    }

    if(item !== "" && item >= "-100" && item < 0){
      emptyform4Data.push(item)
    }

  });
  let isDisabledForm = false;
  
  if (emptyform4Data.length !== form4Data.length) {
    isDisabledForm = true;
  }
  if (
    JSON.stringify(emptyform4Data) == JSON.stringify(new Array(5).fill(0)) &&
    !isDisabledCalc
  ) {
    setIsDisabledCalc(true);
  }

  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  //const [befLastField, setBefLastField] = useS;
  const calcOccupancyIncField5 = (val = -1, index, fieldName) => {
    let newInitObj = assetListStep4calcOccupancyIncField5(
      val,
      index,
      fieldName,
      formikValues
    );
    if (newInitObj != "0") {
      //console.log("intiob", newInitObj, { ...formikValues, newInitObj });

      setInitFormVal({ ...newInitObj });
    }
  };

  const handleCalcOcc = (name, val) => {
    //isDisabledCalculate = true;
    if (!isDisabledCalc) {
      setIsDisabledCalc(true);
    }
    if (!isCalculateClicked) {
      setIsCalculateClicked(true);
    }
    if (occIncRate?.length > 0) {
      setOccIncRate([...occData]);
      setOccIncRateGeneral([...occData]);
      // setOccIncRate([...formikValues.occupancyIncRateYear]);
    }
    // console.log("fffffas", formikValues);
    // console.log("fffffaaassss", name, val);
    if (`${name}`.includes("roomType.")) {
      let roomTypeIndex = `${name}`.split(".")[1];

      //console.log("----here---");
      if (`${name}`.includes(".numKeys")) {
        if (formikValues.roomType[roomTypeIndex].occRate != "" && val !== "") {
          calcOccupancyIncField5(val, roomTypeIndex, "numKeys");
        }
      } else if (`${name}`.includes(".occRate")) {
        if (formikValues.roomType[roomTypeIndex].numKeys != "" && val !== "") {
          calcOccupancyIncField5(val, roomTypeIndex, "occRate");
        }
      }
    } else {
      if (!`${name}`.includes("occupancyIncRateYear.")) {
        let newInitObj = assetListStep4HandleCalcOcc(name, val, formikValues);
        if (newInitObj !== "roomType" || newInitObj != "notFound") {
          setInitFormVal({ ...formikValues, ...newInitObj });
        }
      }
    }
  };

  const handleTotalKeys = (e, e2) => {
    //DO NOT REMOVE
  };

  useEffect(() => {
    let total = 0;
    formikValues?.roomType?.forEach((el) => {
      total += el.numKeys;
    });
    if (total > step1TotalKeys) {
      setValidateKeys(true);
    } else {
      if (validateKeys) {
        setValidateKeys(false);
      }
    }
  }, [handleTotalKeys]);

  const handleNewlyAdded = () => {
    setStepWarning(page + 1);
  };
  const handleRemoveWarning = () => {
    if (
      formikValues?.roomType.length - 1 === performanceRoomTypeLength &&
      (nextStep.completed || nextStep.warning)
    ) {
      unsetStepWarning(page + 1);
      setStepCompleted(page + 1);
    }
  };

  useEffect(() => {
    let newRoomTypes2 = [].concat(
      roomTypes.filter((obj1) =>
        formikValues.roomType.every((obj2) => obj1.value !== obj2.type)
      )
    );
    setResults(newRoomTypes2);
  }, [formikValues.roomType]);

  useEffect(() => {
    // console.log("occIncRate", occIncRate);
    // console.log("occData.length", occData);
    // console.log("emptyformOccData.length", emptyformOccData);

    if (emptyformOccData.length !== occData.length) {
      if (isDisabledCalc) {
        setIsDisabledCalc(true);
      }
    } else {
      let cont = false;
      if (occIncRate?.length > 0) {
        cont = true;
        formikValues.occupancyIncRateYear.forEach((el, ind) => {
          if (ind !== 4) {
            if (el != occIncRate[ind]) {
              cont = false;
            }
          }
        });
      }
      // console.log(
      //   "isDisableCalc",
      //   formikValues.occupancyIncRateYear,
      //   occIncRate
      // );
      if (isDisabledCalc && !cont) {
        setIsDisabledCalc(false);
      }

      let newAssetData;
      if (assetData.status === "InitialDraft" || assetData.status === "Draft") {
        newAssetData = assetData?.formFields;
      } else {
        newAssetData = assetData;
      }

      // console.log("caluclate", assetData, isCalculateClicked, !!newAssetData);
      if (!!newAssetData && !isCalculateClicked) {
        let isEmptyArr = formikValues.occupancyIncRateYear.filter(
          (el, ind) => ind != 4
        );
        // let assetType = assetData.formFields
        // if(assetType)
        if (
          JSON.stringify(
            newAssetData.topLineAssumptions_Standard.occupancyIncreaseRate
          ) == JSON.stringify(formikValues.occupancyIncRateYear) &&
          isEmptyArr.length == 5
        ) {
          setIsDisabledCalc(true);
          // console.log("the same as Old one");
        }
        // console.log("isEmptyArr", isEmptyArr);
        // if(assetData.pricingInflator)
      } else if (occData?.length == 5 && isCalculateClicked) {
        // console.log("occIncRateGeneral", occIncRateGeneral);
        // let isEmptyArr = occIncRateGeneral.filter((el, ind) => el != "");
        // console.log("create", occData, occIncRateGeneral);
        if (JSON.stringify(occData) == JSON.stringify(occIncRateGeneral)) {
          setIsDisabledCalc(true);
          // console.log("the same as Old one for draft and create");
        }
        // console.log("isEmptyArr 2", isEmptyArr);
      }
    }
    // console.log("change occ rate");
  }, [formikValues.occupancyIncRateYear]);
  // console.log("isDisableCalc val", isDisabledCalc);
  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);

  let currDate = new Date(formikValues?.startDateOpTime).getFullYear();
  currDate = currDate - 1;
  let calcFields = ["calc1", "calc2", "calc3", "calc4", "calc5", "calc6"];
  const renderOccIncYearFields = () => {
    return (
      <FieldArray
        name="occupancyIncRateYear"
        render={(arrayHelper) => (
          <SimpleGrid columns={{ sm: 6, md: 6 }} spacing="15px" width="100%">
            {formikValues.occupancyIncRateYear !== undefined &&
              formikValues.occupancyIncRateYear.map((_, index) => {
                currDate = currDate + 1;

                return (
                  <GridItem key={`occupancyIncRateYear_${index}`}>
                    <InputFieldCalc
                      label={`Year ${currDate}`}
                      placeholder="0"
                      inputType="number"
                      inputId={`occupancyIncRateYear.${index}`}
                      inputName={`occupancyIncRateYear.${index}`}
                      errors={errors}
                      minNum="-100"
                      maxNum="100"
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      onChange={setFieldValue}
                      smallIcon={percent}
                      values={
                        calcFields[index] === "calc5"
                          ? " "
                          : formikValues?.occupancyIncRateYear[index] == 0
                          ? "0"
                          : formikValues?.occupancyIncRateYear[index]
                      }
                      labelCenter={true}
                      uppercase={false}
                      isDisabled={
                        calcFields[index] === "calc5" || isRestore
                          ? true
                          : false
                      }
                      noBg={calcFields[index] === "calc5" ? true : false}
                      currencyConvertor={true}
                      calculatedOcc={true}
                      onChangeCalc={handleCalcOcc}
                      currConvVal={
                        isDecimalNumber(formikValues[calcFields[index]])
                          ? parseFloat(formikValues[calcFields[index]]).toFixed(
                              2
                            )
                          : formikValues[calcFields[index]]
                      }
                      directVal={true}
                      validateField={validateStep}
                      seperatedFieldCalc={true}
                      isThousand={true}
                      setFieldTouched={setFieldTouched}
                      onBlur={handleBlur}
                    />
                  </GridItem>
                );
              })}
          </SimpleGrid>
        )}
      />
    );
  };

  // useEffect(() => {
  //   // console.log("opS", opStartDate);
  //   var difference =
  //     new Date(formikValues?.startDateOpTime).getFullYear() - opStartDate;
  //   if (difference !== 0) {
  //     nullifyYearsField(
  //       step,
  //       opStartDate,
  //       formikValues,
  //       setFieldValue,
  //       formikValues?.startDateOpTime,
  //       ["occupancyIncRateYear"]
  //     );
  //   }
  // }, []);

  return (
    <>
      <Box alignItems="flex-start" justifyContent="space-between" display="flex" width="100%" mb={5}>
        <MainTitle title="Performance Asset List 1" />
        <CalculationButton  formikValue={formikValues} />

      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Box
          maxW={["100%", "100%", "65%"]}
          width={["100%", "100%", "65%"]}
          alignSelf="flex-start"
        >
          <Text
            className="title_md"
            display="flex"
            mb={5}
            mt={3}
            component="div"
          >
            Top-Line Assumptions
          </Text>

          <InputFieldValidation
            label="Pricing Inflator (over inflation)"
            inputType="number"
            inputId="pricingInflator"
            inputName="pricingInflator"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            icon={percent}
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />

          <InputFieldValidation
            label="Average Length of Stay (in Days)"
            inputType="number"
            inputId="avgLenOfStay"
            inputName="avgLenOfStay"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            validateField={validateStep}
            // isThousand={true}
            onBlur={handleBlur}
          />

          <InputFieldValidation
            label="Guests per Occupied Room"
            inputType="number"
            inputId="guestPerOccRoom"
            inputName="guestPerOccRoom"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            minNum="0"
            placeholder="0"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={isRestore}
            validateField={validateStep}
            // isThousand={true}
            onBlur={handleBlur}
          />
        </Box>
        <Box
          maxW={["100%", "100%", "100%"]}
          width={["100%", "100%", "100%"]}
          alignSelf="flex-start"
          className="addfield_wrapper"
        >
          <FieldArray name="roomType">
            {({ push, remove }) => (
              <Box container spacing={2} sx={{ marginTop: 2, paddingX: 2 }}>
                <Box>
                  <Text
                    className="title_sm"
                    display="flex"
                    mb={5}
                    mt={3}
                    component="div"
                    letterSpacing="0.06em"
                    fontSize="12px"
                  >
                    ROOM TYPES
                  </Text>
                </Box>
                {formikValues?.roomType?.map((_, index) => {
                  let placeHolder = dropdownObj.types.filter((el) => {
                    return el.value === formikValues?.roomType[index].type;
                  });
                  // console.log("placeHolder", placeHolder[0]?.label);
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      pb={4}
                      className={index == 0 ? "addfieldSec" : ""}
                      key={`roomType_${index}`}
                    >
                      <SimpleGrid
                        columns={{ sm: 4, md: 4 }}
                        spacingX="15px"
                        width="100%"
                      >
                        <GridItem colSpan={4}>
                          <SimpleGrid
                            columns={{ sm: 4, md: 4 }}
                            spacing="15px"
                            width="95%"
                          >
                            <GridItem>
                              <SelectListMulti
                                key={`myUniqueKey_${formikValues?.roomType}`}
                                dropdownItems={results}
                                label="Type"
                                inputId={`roomType.${index}.type`}
                                inputName={`roomType.${index}.type`}
                                placeH={
                                  formikValues?.roomType[index].type === ""
                                    ? "Select"
                                    : placeHolder[0]
                                        ?.label /* formikValues?.roomType[index].type
                                        .split("_")
                                        .join(" ") */
                                  //   .charAt(0)
                                  //   .toUpperCase() +
                                  // formikValues?.roomType[index].type.slice(1)
                                }
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                isRequired
                                values={formikValues?.roomType[index].type}
                                onChange={setFieldValue}
                                onBlur={handleBlur}
                                uppercase={true}
                                // onChange2={(e, e2) => handleChange(e, e2)}
                                isDynamic={true}
                                hideSelected={false}
                                placeHolderValue={false}
                                isDisabled={isRestore}
                                validateField={validateStep}
                              />
                            </GridItem>
                            <GridItem>
                              <InputFieldCalc
                                label="Number of keys"
                                inputType="number"
                                inputId={`roomType.${index}.numKeys`}
                                inputName={`roomType.${index}.numKeys`}
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                isRequired
                                minNum="0"
                                placeholder="0"
                                onChange={setFieldValue}
                                values={formikValues?.roomType[index].numKeys}
                                calculatedOcc={true}
                                onChangeCalc={handleCalcOcc}
                                onChange2={(e, e2) => handleTotalKeys(e, e2)}
                                isDynamic={true}
                                directVal={true}
                                isDisabled={isRestore}
                                validateField={validateStep}
                                isThousand={true}
                                setFieldTouched={setFieldTouched}
                                onBlur={handleBlur}
                              />
                              <Box>
                                {formikValues?.roomType[index].numKeys % 1 !==
                                  0 && (
                                  <Box
                                    color="#e14642"
                                    fontWeight="400"
                                    fontSize="10px"
                                    lineHeight="17px"
                                    letterSpacing="0.04em"
                                    marginTop="-8px"
                                  >
                                    Should be Integer number
                                  </Box>
                                )}
                              </Box>
                            </GridItem>

                            <GridItem>
                              <InputFieldValidation
                                label="Size"
                                inputType="number"
                                inputId={`roomType.${index}.size`}
                                inputName={`roomType.${index}.size`}
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                isRequired
                                minNum="0"
                                placeholder="0"
                                icon={m2}
                                onChange={setFieldValue}
                                values={formikValues?.roomType[index].size}
                                directVal={true}
                                isDisabled={isRestore}
                                validateField={validateStep}
                                isThousand={true}
                                setFieldTouched={setFieldTouched}
                                onBlur={handleBlur}
                              />
                            </GridItem>
                            <GridItem className="lable_fixed_height">
                              <InputFieldCalc
                                label="Occupancy Rate - stabilized"
                                inputType="number"
                                inputId={`roomType.${index}.occRate`}
                                inputName={`roomType.${index}.occRate`}
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                isRequired
                                minNum="0"
                                placeholder="0"
                                icon={percent}
                                onChange={setFieldValue}
                                values={formikValues?.roomType[index].occRate}
                                calculatedOcc={true}
                                onChangeCalc={handleCalcOcc}
                                directVal={true}
                                isDisabled={isRestore}
                                validateField={validateStep}
                                isThousand={true}
                                setFieldTouched={setFieldTouched}
                                onBlur={handleBlur}
                              />
                            </GridItem>
                          </SimpleGrid>
                        </GridItem>
                      </SimpleGrid>
                      <Box>
                        {index > 0 && (
                          <Box>
                            <MainButton
                              title=""
                              icon={deleteIcon2}
                              btnStyle="transparent"
                              disabledBtn={isRestore}
                              fontColor="danger"
                              fSize="sm"
                              onPress={() => {
                                remove(index);
                                handleRemoveWarning();
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  );
                })}
                <Box>
                  <MainButton
                    title="Add room type"
                    btnStyle="transparent"
                    icon={plus}
                    fontColor="dark"
                    disabledBtn={
                      formikValues?.roomType?.length ==
                        dropdownObj.types.length || isRestore
                        ? true
                        : formikValues?.roomType?.length >= 10
                        ? true
                        : false
                    }
                    onPress={() => {
                      push(roomTypeGroup);
                      handleNewlyAdded();
                    }}
                  />
                  {/* <Button
                    variant="outlined"
                    onClick={() => push(roomTypeGroup)}
                  >
                    Add room type
                  </Button> */}
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
        <Box
          maxW={["100%", "100%", "80%"]}
          width={["100%", "100%", "80%"]}
          alignSelf="flex-start"
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            mt={4}
          >
            <Text
              fontSize="12px"
              color="black"
              fontWeight="400"
              letterSpacing="0.04em"
              textAlign="left"
              textTransform="uppercase"
              mt={3}
              mb={4}
            >
              Occupancy Ramp Up
            </Text>
            {renderOccIncYearFields()}

            <MainButton
              title="Calculate"
              disabledBtn={isDisabledCalc}
              onPress={() => {
                handleCalcOcc("occupancyIncRateYear", 0);
              }}
            />
          </Box>
        </Box>
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex justifyContent={user == "manager" ? "end" : "space-between"} alignItems="center" width="100%">
        {
          user !== "manager" ?
            <MainButton
              title="Save Draft"
              btnStyle="transparent"
              disabledBtn={isRestore || user === "proponent"}
              fontColor="dark"
              onPress={() => {
                setDismissSave(false);
                onDraftOpen();
              }}
            /> : <></>
        }
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              disabledBtn={!isDisabledCalc ? true : false}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {/* <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm || !isDisabledCalc ? true : false}
              onPress={() => {
                nextPage();
                unsetStepWarning(page);
                setStepCompleted(page);
                if (percentage < 46) {
                  setPercentage(46);
                }
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  if (!isDisabledCalc) {
                    onClickedFormulaOpen();
                  } else if (validateKeys) {
                    onClickedKeysOpen();
                  } else {
                    nextPage();
                    unsetStepWarning(page);
                    setStepCompleted(page);
                    if (percentage < 46) {
                      setPercentage(46);
                    }
                    document.getElementById("main_content").scrollTo(0, 0);
                  }
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
