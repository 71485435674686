import React, { useState, useEffect } from "react";
import { PostQuickCalculationById } from "../../api/AssetListAPI";

import {
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  useDisclosure,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { FieldArray } from "formik";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import { MainButton } from "../../components/button/MainButton";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import infoCircle from "../../assets/images/icons/infoCircle.svg";

export const QuickCalculationsTable = ({
  titleY,
  titleX,
  id,
  projectNameValue,
  type,
  setIsLoading,
}) => {
  const [chosenNumber, setChosenNumber] = useState();
  const [chosenMultiplier, setChosenMultiplier] = useState(10);
  const [chosenNumberRow, setChosenNumberRow] = useState();
  const [chosenMultiplierRow, setChosenMultiplierRow] = useState(10);
  const [errorMessage, setErrorMessage] = useState("");
  const [updatedValues, setUpdatedValues] = useState({});
  const [initFormVal, setInitFormVal] = useState({
    comment: "",
    conversinRateCol: "",
    conversinRateRow: "",
    localCurrencyCol: "X10",
    localCurrencyRow: "X10",
    annualInflationDev: "",
    baseYear: "",
    terminalVal: "",
    type: type,
    row0: [0, 0, 0, 0, 0],
    row1: [0, 0, 0, 0, 0],
    row2: [0, 0, 0, 0, 0],
    row3: [0, 0, 0, 0, 0],
    row4: [0, 0, 0, 0, 0],
  });
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  let arrKey = ["row0", "row1", "row2", "row3", "row4"];
  const renderFields = (formikValues, pos, touched, errors) => {
    return (
      <FieldArray
        name={`${arrKey[pos]}`}
        render={(arrayHelper) =>
          formikValues[arrKey[pos]] !== undefined &&
          formikValues[arrKey[pos]].map((_, index) => {
            return (
              <Td height="42px" colSpan={1} key={`${arrKey[pos]}_${index}`}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="#1D1F22"
                  fontSize="13px"
                >
                  <InputFieldValidation
                    inputType="number"
                    inputId={`${arrKey[pos]}.${index}`}
                    inputName={`${arrKey[pos]}.${index}`}
                    placeholder="0"
                    errors={errors}
                    touched={touched}
                    isRequired
                    avg={true}
                    noBg={true}
                    isDisabled={true}
                    noInputIcon={true}
                    labelCenter={true}
                    directVal={true}
                    values={formikValues[arrKey[pos]][index]}
                  />
                </Box>
              </Td>
            );
          })
        }
      />
    );
  };

  const onHandleCalc = () => {
    if (
      updatedValues?.yStart !== "" &&
      updatedValues?.xStart !== "" &&
      updatedValues?.xStep !== "" &&
      updatedValues?.yStep !== ""
    ) {
      const submitValues = {
          ...updatedValues,
          type,
          yStart: updatedValues.yStart || 0,
          xStart: updatedValues.xStart || 0,
          xStep: updatedValues.xStep || 10,
          yStep: updatedValues.yStep || 10,
      }
      // console.log("first", submitValues);
      setErrorMessage("");
      setIsLoading(true);
      PostQuickCalculationById(projectNameValue, id, submitValues)
        .then((res) => {
          console.log("reees", res.data);
          res.data = res.data.map((arr) => {
            return arr.map((el) => (el == 0 ? el : el.toFixed(5)));
          });
          setInitFormVal({
            ...initFormVal,
            row0: res.data[0],
            row1: res.data[1],
            row2: res.data[2],
            row3: res.data[3],
            row4: res.data[4],
            conversinRateRow: submitValues?.xStart,
            conversinRateCol: submitValues?.yStart,
            localCurrencyCol: "X" + chosenMultiplier,
            localCurrencyRow: "X" + chosenMultiplierRow,
          });
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage("Something went wrong");
          onErrorOpen();
        }).finally(() => {
            setIsLoading(false);
      });
    }
  };

  // useEffect(() => {
  //   const getData = setTimeout(() => {
  //     if (
  //       !!updatedValues?.yStart &&
  //       !!updatedValues?.xStart &&
  //       updatedValues?.xStep != "" &&
  //       updatedValues?.yStep != ""
  //     ) {
  //       //console.log("first", updatedValues);
  //       setErrorMessage("");
  //       PostQuickCalculationById(projectNameValue, id, updatedValues)
  //         .then((res) => {
  //           console.log("reees", res.data);
  //           res.data = res.data.map((arr) => {
  //             return arr.map((el) => (el == 0 ? el : el.toFixed(3)));
  //           });
  //           setInitFormVal({
  //             ...initFormVal,
  //             row0: res.data[0],
  //             row1: res.data[1],
  //             row2: res.data[2],
  //             row3: res.data[3],
  //             row4: res.data[4],
  //             conversinRateRow: updatedValues?.xStart,
  //             conversinRateCol: updatedValues?.yStart,
  //             localCurrencyCol: "X" + chosenMultiplier,
  //             localCurrencyRow: "X" + chosenMultiplierRow,
  //           });
  //         })

  //         .catch((err) => {
  //           // console.log(err);
  //           setErrorMessage("Something went wrong");
  //           onErrorOpen();
  //         });
  //     }
  //   }, 1000);

  //   return () => clearTimeout(getData);
  // }, [updatedValues]);
  // console.log("initForm", initFormVal);
  return (
    <>
      <Formik
        initialValues={initFormVal}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};

          setChosenNumber(values?.conversinRateCol);
          setChosenMultiplier(values?.localCurrencyCol.replace(/\D/g, ""));
          setChosenNumberRow(values?.conversinRateRow);
          setChosenMultiplierRow(values?.localCurrencyRow.replace(/\D/g, ""));

          if (
            !!projectNameValue &&
            !!id &&
            values.conversinRateCol !== "" &&
            values.conversinRateRow !== "" &&
            values.localCurrencyRow !== "" &&
            values.localCurrencyCol !== ""
          ) {
            let submitValues = {
              type: type,
              xStart: values.conversinRateRow,
              xStep: parseInt(values.localCurrencyRow.replace(/\D/g, "")),
              yStart: values.conversinRateCol,
              yStep: parseInt(values.localCurrencyCol.replace(/\D/g, "")),
            };

            setUpdatedValues(submitValues);
          }

          return errors;
        }}
      >
        {({ errors, touched, values, handleBlur, setFieldValue }) => (
          <Form className="form_wrapper">
            <TableContainer width="100%">
              <Table className="table_main calc_table" width="100%">
                <Tbody>
                  <Tr>
                    <Td height="42px" rowSpan={2} background="#6F767E">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#fff"
                        fontSize="13px"
                      >
                        {titleY}
                      </Box>
                    </Td>
                    <Td
                      height="42px"
                      colSpan={5}
                      background="rgba(111, 118, 126, 0.6)"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#fff"
                        fontSize="13px"
                      >
                        {titleX}
                      </Box>
                    </Td>
                  </Tr>
                  <Tr background="rgba(183, 187, 190, 0.2)">
                    <Td height="42px" rowSpan={1} colSpan={1}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#1D1F22"
                        fontSize="13px"
                      >
                        {/* {chosenNumberRow - chosenMultiplierRow * 2 >= 0 &&
                        chosenNumberRow + chosenMultiplierRow * 2 < 100000
                          ? chosenNumberRow - chosenMultiplierRow * 2
                          : 0} */}
                        {chosenNumberRow + chosenMultiplierRow * 2 < 100000
                          ? chosenNumberRow - chosenMultiplierRow * 2
                          : 0}
                      </Box>
                    </Td>
                    <Td height="42px" colSpan={1}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#1D1F22"
                        fontSize="13px"
                      >
                        {/* {chosenNumberRow - chosenMultiplierRow * 1 >= 0 &&
                        chosenNumberRow + chosenMultiplierRow * 1 < 100000
                          ? chosenNumberRow - chosenMultiplierRow * 1
                          : 0} */}
                        {chosenNumberRow + chosenMultiplierRow * 1 < 100000
                          ? chosenNumberRow - chosenMultiplierRow * 1
                          : 0}
                      </Box>
                    </Td>
                    <Td height="42px" colSpan={1}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#1D1F22"
                        fontSize="13px"
                      >
                        <SelectListMulti
                          label=""
                          placeholder="Select"
                          inputId="localCurrencyRow"
                          inputName="localCurrencyRow"
                          errors={errors}
                          touched={touched}
                          isRequired
                          values={values?.localCurrencyRow}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          uppercase={true}
                          defaultVal={{ value: "X10", label: "X10" }}
                          dropdownItems={[
                            { value: "X5", label: "X5" },
                            { value: "X10", label: "X10" },
                            { value: "X20", label: "X20" },
                            { value: "X25", label: "X25" },
                          ]}
                        />
                        <InputFieldValidation
                          inputType="number"
                          inputId="conversinRateRow"
                          inputName="conversinRateRow"
                          placeholder="0.00"
                          errors={errors}
                          touched={touched}
                          isRequired
                        />
                      </Box>
                    </Td>
                    <Td height="42px" colSpan={1}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#1D1F22"
                        fontSize="13px"
                      >
                        {/* {chosenNumberRow + chosenMultiplierRow * 1 >
                          chosenNumberRow &&
                        chosenNumberRow + chosenMultiplierRow * 1 < 100000
                          ? chosenNumberRow + chosenMultiplierRow * 1
                          : 0} */}
                        {chosenNumberRow + chosenMultiplierRow * 1 < 100000
                          ? chosenNumberRow + chosenMultiplierRow * 1
                          : 0}
                      </Box>
                    </Td>
                    <Td height="42px" colSpan={1}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#1D1F22"
                        fontSize="13px"
                      >
                        {/* {chosenNumberRow + chosenMultiplierRow * 2 >
                          chosenNumberRow &&
                        chosenNumberRow + chosenMultiplierRow * 1 < 100000
                          ? chosenNumberRow + chosenMultiplierRow * 2
                          : 0} */}
                        {chosenNumberRow + chosenMultiplierRow * 1 < 100000
                          ? chosenNumberRow + chosenMultiplierRow * 2
                          : 0}
                      </Box>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      height="42px"
                      colSpan={1}
                      background="rgba(183, 187, 190, 0.2)"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#1D1F22"
                        fontSize="13px"
                      >
                        {/* {chosenNumber - chosenMultiplier * 2 >= 0 &&
                        chosenNumber + chosenMultiplier * 2 < 100000
                          ? chosenNumber - chosenMultiplier * 2
                          : 0} */}
                        {chosenNumber + chosenMultiplier * 2 < 100000
                          ? chosenNumber - chosenMultiplier * 2
                          : 0}
                      </Box>
                    </Td>
                    {renderFields(values, 0, touched, errors)}
                  </Tr>
                  <Tr>
                    <Td
                      height="42px"
                      colSpan={1}
                      background="rgba(183, 187, 190, 0.2)"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#1D1F22"
                        fontSize="13px"
                      >
                        {/* {chosenNumber - chosenMultiplier * 1 >= 0 &&
                        chosenNumber + chosenMultiplier * 1 < 100000
                          ? chosenNumber - chosenMultiplier * 1
                          : 0} */}
                        {chosenNumber + chosenMultiplier * 1 < 100000
                          ? chosenNumber - chosenMultiplier * 1
                          : 0}
                      </Box>
                    </Td>
                    {renderFields(values, 1, touched, errors)}
                  </Tr>
                  <Tr>
                    <Td
                      height="42px"
                      colSpan={1}
                      background="rgba(183, 187, 190, 0.2)"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#1D1F22"
                        fontSize="13px"
                      >
                        <SelectListMulti
                          label=""
                          placeholder="Select"
                          inputId="localCurrencyCol"
                          inputName="localCurrencyCol"
                          errors={errors}
                          touched={touched}
                          isRequired
                          values={values?.localCurrencyCol}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          uppercase={true}
                          defaultVal={{ value: "X10", label: "X10" }}
                          dropdownItems={[
                            { value: "X5", label: "X5" },
                            { value: "X10", label: "X10" },
                            { value: "X20", label: "X20" },
                            { value: "X25", label: "X25" },
                          ]}
                        />
                        {/* left */}
                        <InputFieldValidation
                          inputType="number"
                          inputId="conversinRateCol"
                          inputName="conversinRateCol"
                          placeholder="0.00"
                          maxNum={100}
                          errors={errors}
                          touched={touched}
                          isRequired
                        />
                      </Box>
                    </Td>
                    {renderFields(values, 2, touched, errors)}
                  </Tr>
                  <Tr>
                    <Td
                      height="42px"
                      colSpan={1}
                      background="rgba(183, 187, 190, 0.2)"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#1D1F22"
                        fontSize="13px"
                      >
                        {chosenNumber + chosenMultiplier * 1 > chosenNumber &&
                        chosenNumber + chosenMultiplier * 1 < 100000
                          ? chosenNumber + chosenMultiplier * 1
                          : 0}
                      </Box>
                    </Td>
                    {renderFields(values, 3, touched, errors)}
                  </Tr>
                  <Tr>
                    <Td
                      height="42px"
                      colSpan={1}
                      background="rgba(183, 187, 190, 0.2)"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#1D1F22"
                        fontSize="13px"
                      >
                        {/* {chosenNumber + chosenMultiplier * 2 > chosenNumber &&
                        chosenNumber + chosenMultiplier * 2 < 100000
                          ? chosenNumber + chosenMultiplier * 2
                          : 0} */}
                        {chosenNumber + chosenMultiplier * 2 < 100000
                          ? chosenNumber + chosenMultiplier * 2
                          : 0}
                      </Box>
                    </Td>
                    {renderFields(values, 4, touched, errors)}
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="flex-end" mt={4} w="100%">
              <MainButton
                title="Calculate"
                reverseRow={true}
                // disabledBtn={checkedItems.length === 0}
                onPress={() => {
                  onHandleCalc();
                }}
              />
            </Box>
          </Form>
        )}
      </Formik>

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
    </>
  );
};
