import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";

import { ChakraProvider } from "@chakra-ui/react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import { parseToken } from "./utils/Auth";
import { Sidebar } from "./layouts/sidebar";
import { Main } from "./layouts/main";
import { Login } from "./pages/Login";
import { LoginError } from "./pages/LoginError";

import "./assets/styles/global.scss";

function App() {
  const renderRef = useRef(false);

  //const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState();
  //const [user, setUser] = useState(true);

  const handleUserAuth = (user) => {
    let userRole = user.roles?.[0]?.toLowerCase() || "none";
    if (userRole && userRole !== "none") {
      return (
        <ChakraProvider>
          <div className="neom_content">
            <Sidebar user={user} />
            <Main user={user} />
          </div>
        </ChakraProvider>
      );
    } else {
      return <LoginError />;
    }
  };

  useEffect(() => {
    if (renderRef.current) return;
    renderRef.current = true;
    //console.log("Cookies", Cookies.get("user_info"));
    if (Cookies.get("user_info")) {
      let token = Cookies.get("user_info");
      //console.log("token", token);
      setUser(jwt_decode(token));
      //let newToken = parseToken();
      //console.log("new", newToken);
    }
  }, []);
  /* useLayoutEffect(() => {
    console.log(Cookies);
    if (Cookies.get("user_info")) {
      let token = Cookies.get("user_info");
      setUser(jwt_decode(token));
      console.log(user);
    }
  }, []); */

  console.log("user==>", user);

  return user ? (
    handleUserAuth(user)
  ) : (
    <Login />
  ) /* setLoggedIn={setLoggedIn} */;
}

export default App;
