import React, { useEffect, useState, useRef } from "react";

import {
  Box,
  Flex,
  Divider,
  GridItem,
  SimpleGrid,
  Text,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Heading
} from "@chakra-ui/react";
import {
  assetListStep5calcPerPerson,
  assetListStep5calcTotalPackageRate,
} from "../../utils/Formula";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { InputFieldCalc } from "../../components/inputField/InputFieldCalc";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { FieldArray } from "formik";
import { isDecimalNumber } from "../../utils/General";

import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import percent from "../../assets/images/icons/filled.svg";
import infoFillCircle from '../../assets/images/icons/infoFillCircle.svg'
import {CalculationButton} from"../../components/calculationModal/calculationButton"

export const AssetsListForm5Package = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  initFormVal,
  setInitFormVal,
  opStartDate,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  unsetStepWarning,
  step,
  packageRooms,
  setStepWarning,
  setStepperUpdate,
  stepperUpdate,
  validateStep,
  setValidateStep,
  modelWarningOpen,
  isModelWarningOpen,
  closeModalPath,
  isFormDirty,
  setIsFormDirty,
  setFieldTouched,
  user
}) => {
  let multiplier = formikValues.packageType.length;
  // console.log("formik values tep 5", formikValues);
  const inputRef = useRef(null);
  const [nameVal, setNameVal] = useState({});
  const dataFetchedRef = useRef(false);
  const months = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" },
  ];
  let calcFields = ["fAndB1", "fAndB2", "fAndB3", "fAndB4", "fAndB5", "fAndB6"];
  let calcOtherFields = [
    "otherCalc1",
    "otherCalc2",
    "otherCalc3",
    "otherCalc4",
    "otherCalc5",
    "otherCalc6",
  ];
  let calcRoomRateFields = [
    "roomRateCalc1",
    "roomRateCalc2",
    "roomRateCalc3",
    "roomRateCalc4",
    "roomRateCalc5",
    "roomRateCalc6",
  ];
  let calcPackageRateFields = [
    "packageRateCalc1",
    "packageRateCalc2",
    "packageRateCalc3",
    "packageRateCalc4",
    "packageRateCalc5",
    "packageRateCalc6",
  ];
  let calcWeightedADR = [
    "weightedADRCalc1",
    "weightedADRCalc2",
    "weightedADRCalc3",
    "weightedADRCalc4",
    "weightedADRCalc5",
    "weightedADRCalc6",
  ];
  let calcTotalPackageRateSAR = [
    "totalPackageRateSARCalc1",
    "totalPackageRateSARCalc2",
    "totalPackageRateSARCalc3",
    "totalPackageRateSARCalc4",
    "totalPackageRateSARCalc5",
    "totalPackageRateSARCalc6",
  ];
  let calcTotalPackageRateUSD = [
    "totalPackageRateUSDCalc1",
    "totalPackageRateUSDCalc2",
    "totalPackageRateUSDCalc3",
    "totalPackageRateUSDCalc4",
    "totalPackageRateUSDCalc5",
    "totalPackageRateUSDCalc6",
  ];
  let calcTotalPackageRate7DaysSAR = [
    "totalPackageRate7DaysSARCalc1",
    "totalPackageRate7DaysSARCalc2",
    "totalPackageRate7DaysSARCalc3",
    "totalPackageRate7DaysSARCalc4",
    "totalPackageRate7DaysSARCalc5",
    "totalPackageRate7DaysSARCalc6",
  ];
  let calcTotalPackageRate7DaysUSD = [
    "totalPackageRate7DaysUSDCalc1",
    "totalPackageRate7DaysUSDCalc2",
    "totalPackageRate7DaysUSDCalc3",
    "totalPackageRate7DaysUSDCalc4",
    "totalPackageRate7DaysUSDCalc5",
    "totalPackageRate7DaysUSDCalc6",
  ];

  let form5Data = [];

  let emptyform5Data = [];
  let perfAssetKeys = ["foodAndBeverage", "other", "packageRate", "roomRate"];
  perfAssetKeys.forEach((el) => {
    if (!!formikValues?.performaceAssitList2?.foodAndBeverage) {
      formikValues?.performaceAssitList2[el].forEach((el) => {
        el?.year?.forEach((item) => {
          form5Data.push(item);
        });
      });
    }
  });

  form5Data.forEach((item, ind) => {
    if (item !== "" && item >= "0") {
      emptyform5Data.push(item);
    }
  });

  let isDisabledForm = false;
  //console.log(emptyform5Data, form5Data);
  if (emptyform5Data.length !== form5Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  const handleAccordErrors = () => {
    let openHigherAccord = true;

    form5Data.forEach((el, index) => {
      if (openHigherAccord) {
        if (el === "" || el > 100) {
          switch (true) {
            case index >= 18 * multiplier && index < 24 * multiplier:
              openHigherAccord = false;
              handleOpenAccord(0);
              break;
            case index >= 0 * multiplier && index < 6 * multiplier:
              openHigherAccord = false;
              handleOpenAccord(1);
              break;
            case index >= 6 * multiplier && index < 13 * multiplier:
              openHigherAccord = false;
              handleOpenAccord(2);
              break;
            case index >= 12 * multiplier && index < 25 * multiplier:
              openHigherAccord = false;
              handleOpenAccord(3);
              break;

            default:
              break;
          }
        }
      }
    });
  };

  const handleOpenAccord = (accordIndex) => {
    if (
      inputRef.current.querySelectorAll(
        ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
      )[accordIndex].ariaExpanded === "false"
    ) {
      inputRef.current
        .querySelectorAll(
          ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
        )
      [accordIndex].click();
    }
  };

  let currDate;
  currDate = new Date(formikValues?.startDateOpTime).getFullYear();
  currDate = currDate - 1;
  let currDate2,
    currDate3,
    currDate4,
    currDate5,
    currDate6,
    currDate7,
    currDate8,
    currDate9,
    currDate10;
  currDate2 = new Date(formikValues?.startDateOpTime).getFullYear();
  currDate2 = currDate2 - 1;
  currDate3 = currDate2;
  currDate4 = currDate2;
  currDate5 = currDate2;
  currDate6 = currDate2;
  currDate7 = currDate2;
  currDate8 = currDate2;
  currDate9 = currDate2;
  currDate10 = currDate2;

  const calcFieldsFunc = (val = -1, index, perfAssetYearIndex, fieldName) => {
    //console.log("formik", formikValues);
    let newInitObj = assetListStep5calcPerPerson(
      val,
      index,
      perfAssetYearIndex,
      fieldName,
      formikValues
    );
    if (newInitObj != "0") {
      /* console.log("intiob", newInitObj, formikValues, {
        ...formikValues,
        newInitObj,
      }); */
      setNameVal({
        name: "edits",
        val: 0,
      });
      setInitFormVal({ ...newInitObj });
    }
  };
  const calcTotalPackageRateFunc = (fieldName, index) => {
    //console.log("formik", formikValues);
    let newInitObj = assetListStep5calcTotalPackageRate(
      fieldName,
      index,
      formikValues
    );
    if (newInitObj != "0") {
      /* console.log("intiob", newInitObj, formikValues, {
        ...formikValues,
        newInitObj,
      }); */
      setNameVal({
        name: "",
        val: 0,
      });
      setInitFormVal({ ...newInitObj });
    }
  };

  useEffect(() => {
    /* console.log("new val", nameVal);

    console.log("fffffas", formikValues);
    console.log("fffffaaassss", nameVal.name, nameVal.val); */
    if (`${nameVal.name}` === "edits") {
      if (
        !!formikValues?.performaceAssitList2?.foodAndBeverage &&
        !!formikValues?.performaceAssitList2?.other &&
        !!formikValues?.performaceAssitList2?.packageRate &&
        !!formikValues?.performaceAssitList2?.roomRate
      ) {
        //console.log("asdasd");
        calcTotalPackageRateFunc("calcTotalPackageRate", 4);
      }
    } else {
      if (`${nameVal.name}`.includes("performaceAssitList2.")) {
        let perfAssetIndex = `${nameVal.name}`.split(".")[2];
        let perfAssetYearIndex = `${nameVal.name}`.split(".")[4];
        // console.log(
        //   "----hereVal---",
        //   nameVal.val,
        //   formikValues.packageType[perfAssetIndex].occRate,
        //   nameVal.name
        // );
        if (
          `${nameVal.name}`.includes("foodAndBeverage") &&
          nameVal.val !== ""
        ) {
          calcFieldsFunc(
            nameVal.val,
            perfAssetIndex,
            perfAssetYearIndex,
            "foodAndBeverage"
          );
        } else if (`${nameVal.name}`.includes("other") && nameVal.val !== "") {
          calcFieldsFunc(
            nameVal.val,
            perfAssetIndex,
            perfAssetYearIndex,
            "other"
          );
        } else if (
          `${nameVal.name}`.includes("packageRate") &&
          nameVal.val !== ""
        ) {
          calcFieldsFunc(
            nameVal.val,
            perfAssetIndex,
            perfAssetYearIndex,
            "packageRate"
          );
        } else if (
          `${nameVal.name}`.includes("roomRate") &&
          nameVal.val !== ""
        ) {
          calcFieldsFunc(
            nameVal.val,
            perfAssetIndex,
            perfAssetYearIndex,
            "roomRate"
          );
        }
      }
    }
  }, [formikValues]);

  useEffect(() => {
    var difference =
      new Date(formikValues?.startDateOpTime).getFullYear() - opStartDate;
    if (difference !== 0) {
      if (step.warning) {
        if (!isNaN(opStartDate)) {
          var difference =
            new Date(formikValues?.startDateOpTime).getFullYear() - opStartDate;
          // console.log("difference", difference);
          //UNCOMMENT WHEN PREVIEW IS WORKING
          if (difference !== 0) {
            formikValues?.performaceAssitList2?.foodAndBeverage.forEach(
              (element, index) =>
                setFieldValue(
                  `performaceAssitList2.foodAndBeverage.${index}.year`,
                  formikValues.performaceAssitList2.foodAndBeverage[
                    index
                  ].year.fill("")
                )
            );
            formikValues?.performaceAssitList2?.other.forEach(
              (element, index) =>
                setFieldValue(
                  `performaceAssitList2.other.${index}.year`,
                  formikValues.performaceAssitList2.other[index].year.fill("")
                )
            );
            formikValues?.performaceAssitList2?.packageRate.forEach(
              (element, index) =>
                setFieldValue(
                  `performaceAssitList2.packageRate.${index}.year`,
                  formikValues.performaceAssitList2.packageRate[
                    index
                  ].year.fill("")
                )
            );
            formikValues?.performaceAssitList2?.roomRate.forEach(
              (element, index) =>
                setFieldValue(
                  `performaceAssitList2.roomRate.${index}.year`,
                  formikValues.performaceAssitList2.roomRate[index].year.fill(
                    ""
                  )
                )
            );
          }
          //UNCOMMENT WHEN PREVIEW IS WORKING
        }
      }
    }
    if (
      !!formikValues?.performaceAssitList2 === false ||
      formikValues?.performaceAssitList2?.foodAndBeverage?.length == 0
    ) {
      let newInitData = [];
      formikValues.packageType.map((el) => {
        newInitData = [
          ...newInitData,
          {
            type: el.type,
            id: el.type,
            year: Array(6).fill(0),
          },
        ];
      });
      // console.log("---------------------");
      // console.log("--------working------");
      // console.log("---------------------");
      setInitFormVal({
        ...formikValues,
        performaceAssitList2: {
          roomRate: newInitData,
          foodAndBeverage: newInitData,
          other: newInitData,
          packageRate: newInitData,
        },
      });
    } else {
      if (
        formikValues?.packageType?.length !=
        formikValues?.performaceAssitList2?.roomRate?.length
      ) {
        let newOther = [];
        let newRoomRate = [];
        let newFoodAndBeverage = [];
        let newPackageRate = [];
        let { foodAndBeverage, other, packageRate, roomRate } =
          formikValues.performaceAssitList2;
        let { packageType } = formikValues;

        formikValues.packageType.map((el, ind) => {
          newOther = [
            ...newOther,
            {
              type: other[ind]?.type ? other[ind].type : packageType[ind].type,
              id: other[ind]?.type ? other[ind].type : packageType[ind].type,
              year: other[ind]?.year ? other[ind].year : Array(6).fill(0),
            },
          ];
          newRoomRate = [
            ...newRoomRate,
            {
              type: roomRate[ind]?.type
                ? roomRate[ind].type
                : packageType[ind].type,
              id: roomRate[ind]?.type
                ? roomRate[ind].type
                : packageType[ind].type,
              year: roomRate[ind]?.year ? roomRate[ind].year : Array(6).fill(0),
            },
          ];
          newFoodAndBeverage = [
            ...newFoodAndBeverage,
            {
              type: foodAndBeverage[ind]?.type
                ? foodAndBeverage[ind].type
                : packageType[ind].type,
              id: foodAndBeverage[ind]?.type
                ? foodAndBeverage[ind].type
                : packageType[ind].type,
              year: foodAndBeverage[ind]?.year
                ? foodAndBeverage[ind].year
                : Array(6).fill(0),
            },
          ];
          newPackageRate = [
            ...newPackageRate,
            {
              type: packageRate[ind]?.type
                ? packageRate[ind].type
                : packageType[ind].type,
              id: packageRate[ind]?.type
                ? packageRate[ind].type
                : packageType[ind].type,
              year: packageRate[ind]?.year
                ? packageRate[ind].year
                : Array(6).fill(0),
            },
          ];
        });
        // console.log("---------------------");
        // console.log("--------working------");
        // console.log("---------------------");
        setInitFormVal({
          ...formikValues,
          performaceAssitList2: {
            roomRate: newRoomRate,
            foodAndBeverage: newFoodAndBeverage,
            other: newOther,
            packageRate: newPackageRate,
          },
        });
      } else {
        calcTotalPackageRateFunc("calcTotalPackageRate", 4);
      }
    }
  }, []);
  console.log(formikValues);
  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);

  return (
    <>
      <Box alignItems="flex-start" justifyContent="space-between" display="flex" width="100%" mb={5}>
        <MainTitle title="Performance Asset List 2" />
        <CalculationButton  formikValue={formikValues} />

      </Box>

      <Accordion width="100%" allowToggle color="white" defaultIndex={[0]}>
        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom accordion_item_blue_custom"
          color="#fff"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
            color="#fff"
          >
            <AccordionButton bg="#003865">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                  color="#fff"
                >
                  Total rates summary
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={2} border="1px" borderColor="#E6E6E6">
            <Box
              maxW={["100%", "100%", "100%"]}
              width={["100%", "100%", "100%"]}
              alignSelf="flex-start"
            >
              <Text
                className="title_sm"
                display="flex"
                mb={5}
                mt={3}
                component="div"
                textTransform="uppercase"
              >
                Total package rate (SAR)
              </Text>
              <SimpleGrid
                columns={{ sm: 4, md: 4 }}
                spacingX="15px"
                width="100%"
              >
                <GridItem colSpan={4}>
                  <SimpleGrid
                    columns={{ sm: 6, md: 6 }}
                    spacing="15px"
                    width="84.5%"
                  >
                    {calcTotalPackageRateSAR.map((item, ind) => {
                      currDate5 = currDate5 + 1;
                      let calcValue = isDecimalNumber(formikValues[item])
                        ? parseFloat(formikValues[item])?.toFixed(2)
                        : formikValues[item];
                      let calcValueUSD = isDecimalNumber(
                        formikValues[`${calcTotalPackageRateUSD[ind]}`]
                      )
                        ? parseFloat(
                          formikValues[`${calcTotalPackageRateUSD[ind]}`]
                        )?.toFixed(2)
                        : formikValues[`${calcTotalPackageRateUSD[ind]}`];
                      return (
                        <GridItem key={`calcTotalPackageRate_${ind}`}>
                          <Box textAlign="center">
                            <Text
                              mb="-9px !important"
                              className="title_sm"
                              fontSize="12px"
                            >
                              Year {currDate5}
                            </Text>
                            <Box mb="-40px !important">
                              <InputFieldValidation
                                hideLabel={true}
                                placeholder="0 SAR"
                                inputType="number"
                                inputId={item}
                                inputName={item}
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                isRequired
                                onChange={setFieldValue}
                                values={calcValue}
                                labelCenter={true}
                                errorHeight0={true}
                                uppercase={false}
                                isDisabled={true}
                                noBg={true}
                                fs14={true}
                                textIcon="SAR"
                                directVal={true}
                              />
                            </Box>
                            <Box>
                              <InputFieldValidation
                                hideLabel={true}
                                placeholder="0 USD"
                                inputType="number"
                                inputId={calcTotalPackageRateUSD[ind]}
                                inputName={calcTotalPackageRateUSD[ind]}
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                isRequired
                                onChange={setFieldValue}
                                values={calcValueUSD}
                                labelCenter={true}
                                errorHeight0={true}
                                uppercase={false}
                                isDisabled={true}
                                noBg={true}
                                fs12={true}
                                textIcon="USD"
                                directVal={true}
                              />
                            </Box>
                          </Box>
                        </GridItem>
                      );
                    })}
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>

              <Divider orientation="horizontal" mt="20px" mb="30px" />
            </Box>
            <Box
              maxW={["100%", "100%", "100%"]}
              width={["100%", "100%", "100%"]}
              alignSelf="flex-start"
            >
              <Text
                className="title_sm"
                display="flex"
                mb={5}
                mt={3}
                component="div"
                textTransform="uppercase"
              >
                Total package rate (SAR, 7-DAYS)
              </Text>
              <SimpleGrid
                columns={{ sm: 4, md: 4 }}
                spacingX="15px"
                width="100%"
              >
                <GridItem colSpan={4}>
                  <SimpleGrid
                    columns={{ sm: 6, md: 6 }}
                    spacing="15px"
                    width="84.5%"
                  >
                    {calcTotalPackageRate7DaysSAR.map((item, ind) => {
                      currDate3 = currDate3 + 1;
                      let calcValue = isDecimalNumber(formikValues[item])
                        ? parseFloat(formikValues[item])?.toFixed(2)
                        : formikValues[item];
                      let calcValueUSD = isDecimalNumber(
                        formikValues[`${calcTotalPackageRate7DaysUSD[ind]}`]
                      )
                        ? parseFloat(
                          formikValues[`${calcTotalPackageRate7DaysUSD[ind]}`]
                        )?.toFixed(2)
                        : formikValues[`${calcTotalPackageRate7DaysUSD[ind]}`];
                      return (
                        <GridItem key={`calcTotalPR7DaysSar_${ind}`}>
                          <Box textAlign="center">
                            <Text
                              mb="-9px !important"
                              className="title_sm"
                              fontSize="12px"
                            >
                              Year {currDate3}
                            </Text>
                            <Box mb="-40px !important">
                              <InputFieldValidation
                                hideLabel={true}
                                placeholder="0 SAR"
                                inputType="number"
                                inputId={item}
                                inputName={item}
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                isRequired
                                onChange={setFieldValue}
                                values={calcValue}
                                labelCenter={true}
                                errorHeight0={true}
                                uppercase={false}
                                isDisabled={true}
                                noBg={true}
                                fs14={true}
                                textIcon="SAR"
                                directVal={true}
                              />
                            </Box>
                            <Box>
                              <InputFieldValidation
                                hideLabel={true}
                                placeholder="0 USD"
                                inputType="number"
                                inputId={calcTotalPackageRate7DaysUSD[ind]}
                                inputName={calcTotalPackageRate7DaysUSD[ind]}
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                isRequired
                                onChange={setFieldValue}
                                values={calcValueUSD}
                                labelCenter={true}
                                errorHeight0={true}
                                uppercase={false}
                                isDisabled={true}
                                noBg={true}
                                fs12={true}
                                textIcon="USD"
                                directVal={true}
                              />
                            </Box>
                          </Box>
                        </GridItem>
                      );
                    })}
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>

              <Divider orientation="horizontal" mt="20px" mb="30px" />
            </Box>
            <Box
              maxW={["100%", "100%", "100%"]}
              width={["100%", "100%", "100%"]}
              alignSelf="flex-start"
            >
              <Text
                className="title_sm"
                display="flex"
                mb={5}
                mt={3}
                component="div"
                textTransform="uppercase"
              >
                Weighted Average Daily Rate
              </Text>
              <SimpleGrid
                columns={{ sm: 4, md: 4 }}
                spacingX="15px"
                width="100%"
              >
                <GridItem colSpan={4}>
                  <SimpleGrid
                    columns={{ sm: 6, md: 6 }}
                    spacing="15px"
                    width="84.5%"
                  >
                    {calcWeightedADR.map((item, ind) => {
                      currDate4 = currDate4 + 1;
                      let calcValue = isDecimalNumber(formikValues[item])
                        ? parseFloat(formikValues[item])?.toFixed(2)
                        : formikValues[item];
                      return (
                        <GridItem key={`calcWeightedADR_${ind}`}>
                          <Box textAlign="center">
                            <Text
                              mb="-9px !important"
                              className="title_sm"
                              fontSize="12px"
                            >
                              Year {currDate4}
                            </Text>
                            <InputFieldValidation
                              hideLabel={true}
                              placeholder="0 SAR"
                              inputType="number"
                              inputId={item}
                              inputName={item}
                              errors={errors}
                              setIsFormDirty={setIsFormDirty}
                              isFormDirty={isFormDirty}
                              touched={touched}
                              isRequired
                              onChange={setFieldValue}
                              values={calcValue}
                              labelCenter={true}
                              errorHeight0={true}
                              uppercase={false}
                              isDisabled={true}
                              noBg={true}
                              fs14={true}
                              directVal={true}
                            />
                          </Box>
                        </GridItem>
                      );
                    })}
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>

              <Divider orientation="horizontal" mt="20px" mb="30px" />
            </Box>
            <Box
              maxW={["100%", "100%", "100%"]}
              width={["100%", "100%", "100%"]}
              alignSelf="flex-start"
            >
              <Text
                className="title_sm"
                display="flex"
                mb={5}
                mt={3}
                component="div"
                textTransform="uppercase"
              >
                Weighted Occupancy Rate
              </Text>
              <SimpleGrid
                columns={{ sm: 4, md: 4 }}
                spacingX="15px"
                width="100%"
              >
                <GridItem colSpan={4}>
                  <SimpleGrid
                    columns={{ sm: 6, md: 6 }}
                    spacing="15px"
                    width="84.5%"
                  >
                    {calcWeightedADR.map((item, ind) => {
                      currDate6 = currDate6 + 1;
                      let calcValue = isDecimalNumber(
                        formikValues[`calc${ind + 1}`]
                      )
                        ? parseFloat(formikValues[`calc${ind + 1}`])?.toFixed(2)
                        : formikValues[`calc${ind + 1}`];
                      return (
                        <GridItem key={`calcWADR2_${ind}`}>
                          <Box textAlign="center">
                            <Text
                              mb="-9px !important"
                              className="title_sm"
                              fontSize="12px"
                            >
                              Year {currDate6}
                            </Text>
                            <InputFieldValidation
                              hideLabel={true}
                              placeholder="0" // SAR
                              inputType="number"
                              inputId={`calcWeightedOccupancyRateYear${
                                ind + 1
                              }`}
                              inputName={`calcWeightedOccupancyRateYear${
                                ind + 1
                              }`}
                              errors={errors}
                              setIsFormDirty={setIsFormDirty}
                              isFormDirty={isFormDirty}
                              touched={touched}
                              isRequired
                              onChange={setFieldValue}
                              // values={
                              //   formikValues[`calc${ind + 1}`]
                              //     ? formikValues[`calc${ind + 1}`]
                              //     : 0
                              // }
                              values={calcValue ? calcValue : 0} //mean formikValues.calc1 ...
                              labelCenter={true}
                              errorHeight0={true}
                              uppercase={false}
                              isDisabled={true}
                              noBg={true}
                              fs14={true}
                              directVal={true}
                            />
                          </Box>
                        </GridItem>
                      );
                    })}
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/*Second Accordion */}
      <Accordion
        width="100%"
        allowToggle
        color="black"
        defaultIndex={[0]}
        ref={inputRef}
      >
        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box
                flex="1"
                textAlign="left"
                display="flex"
                flexDirection="column"
              >
                <Box display="flex" justifyContent='space-between'>
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                    textTransform="uppercase"
                  >
                    Room rate
                  </Text>
                  <Flex align="center">
                    <img src={infoFillCircle} />
                    <Text
                      p="1"
                      pb="1"
                      pt="1"
                      fontSize="10px !important"
                      fontWeight="400"
                      letterSpacing="0.06em"
                    >
                      Per Person Per Night
                    </Text>
                  </Flex>
                </Box>
                <Box pt={3} pb={0}>
                  <SimpleGrid
                    columns={{ sm: 4, md: 4 }}
                    spacingX="15px"
                    width="100%"
                  >
                    <GridItem colSpan={4}>
                      <SimpleGrid
                        columns={{ sm: 6, md: 6 }}
                        spacing="0px"
                        width="88%"
                      >
                        {calcRoomRateFields.map((item, ind) => {
                          currDate7 = currDate7 + 1;
                          let calcValue = isDecimalNumber(formikValues[item])
                            ? parseFloat(formikValues[item])?.toFixed(2)
                            : formikValues[item];
                          return (
                            <GridItem key={`calcRoomRate_${ind}`}>
                              <Box textAlign="center">
                                <Text
                                  mb="-15px !important"
                                  className="title_sm"
                                  fontSize="12px !important"
                                >
                                  Year {currDate7}
                                </Text>
                                <InputFieldValidation
                                  hideLabel={true}
                                  placeholder="0 SAR"
                                  inputType="number"
                                  inputId={item}
                                  inputName={item}
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={calcValue}
                                  labelCenter={true}
                                  errorHeight0={true}
                                  uppercase={false}
                                  isDisabled={true}
                                  noBg={true}
                                  fs15={true}
                                  directVal={true}
                                />
                              </Box>
                            </GridItem>
                          );
                        })}
                      </SimpleGrid>
                    </GridItem>
                  </SimpleGrid>
                </Box>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel
            pb={4}
            pt={4}
            border="1px"
            className="roomRate_accordion"
            borderColor="#E6E6E6"
          >
            <Box
              maxW={["100%", "100%", "100%"]}
              width={["100%", "100%", "100%"]}
              alignSelf="flex-start"
              marginLeft="1%"
            >
              <FieldArray
                name="roomRate"
                render={(arrayHelper) => (
                  <>
                    {formikValues?.performaceAssitList2?.roomRate !==
                      undefined &&
                      formikValues?.performaceAssitList2?.roomRate.map(
                        (packageType, index) => {
                          currDate = new Date(
                            formikValues?.startDateOpTime
                          ).getFullYear();
                          currDate = currDate - 1;
                          let subTitle = dropdownObj?.packageTypes?.find(
                            (el) =>
                              el.value === formikValues?.packageType[index]?.type
                          );

                          return (
                            <Box key={`perfAssitPackage_${index}`}>
                              <Text
                                className="title_sm item"
                                display="flex"
                                mb={3}
                                mt={1}
                                component="div"
                                letterSpacing="0.06em"
                                fontSize="12px"
                                textTransform="uppercase"
                              >
                                {subTitle.label}
                                {/* {formikValues?.packageType[index]?.type
                                  .split("_")
                                  .join(" ")} */}
                              </Text>

                              <Box pb={2} pt={2}>
                                <>
                                  <GridItem colSpan={2} width="85%">
                                    <SimpleGrid
                                      columns={{ sm: 2, md: 6 }}
                                      spacingX="15px"
                                      width="100%"
                                    >
                                      {packageType.year.map((_, ind) => {
                                        currDate = currDate + 1;
                                        return (
                                          <GridItem
                                            colSpan={1}
                                            key={`packageType_${ind}`}
                                          >
                                            <InputFieldCalc
                                              label={`Year ${currDate}`}
                                              placeholder="0"
                                              inputType="number"
                                              inputId={`performaceAssitList2.roomRate.${index}.year.${ind}`}
                                              inputName={`performaceAssitList2.roomRate.${index}.year.${ind}`}
                                              errors={errors}
                                              setIsFormDirty={setIsFormDirty}
                                              isFormDirty={isFormDirty}
                                              touched={touched}
                                              isRequired
                                              onChange={setFieldValue}
                                              values={
                                                formikValues
                                                  ?.performaceAssitList2
                                                  ?.roomRate[index]?.year[ind]
                                              }
                                              labelCenter={true}
                                              errorHeight0={true}
                                              uppercase={false}
                                              // smallIcon={percent}
                                              calculatedOcc={true}
                                              //onChangeCalc={handleCalcOcc}
                                              setNameVal={setNameVal}
                                              cancelFuncCalc={true}
                                              isDisabled={isRestore}
                                              validateField={validateStep}
                                              smallLeftIcon="SAR"
                                              smallerIcon={true}
                                              isThousand={true}
                                              setFieldTouched={setFieldTouched}
                                              onBlur={handleBlur}
                                              directVal={true}
                                            />
                                          </GridItem>
                                        );
                                      })}
                                    </SimpleGrid>
                                  </GridItem>
                                </>
                              </Box>
                            </Box>
                          );
                        }
                      )}
                  </>
                )}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>

        {/* Third Accordion */}
        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box
                flex="1"
                textAlign="left"
                display="flex"
                flexDirection="column"
              >
                <Box display="flex" justifyContent="space-between">
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                    textTransform="uppercase"
                  >
                    Food and beverages
                  </Text>
                  <Flex align="center">
                    <img src={infoFillCircle} />
                    <Text
                      p="1"
                      pb="1"
                      pt="1"
                      fontSize="10px !important"
                      fontWeight="400"
                      letterSpacing="0.04em"
                      textAlign="right">
                      Per Person Per Night
                    </Text>
                  </Flex>
                </Box>
                <Box pt={3} pb={0}>
                  <SimpleGrid
                    columns={{ sm: 4, md: 4 }}
                    spacingX="15px"
                    width="100%"
                  >
                    <GridItem colSpan={4}>
                      <SimpleGrid
                        columns={{ sm: 6, md: 6 }}
                        spacing="0px"
                        width="88%"
                      >
                        {calcFields.map((item, ind) => {
                          currDate8 = currDate8 + 1;
                          let calcValue = isDecimalNumber(formikValues[item])
                            ? parseFloat(formikValues[item])?.toFixed(2)
                            : formikValues[item];
                          return (
                            <GridItem key={`calcFields_${ind}`}>
                              <Box textAlign="center">
                                <Text
                                  mb="-15px !important"
                                  className="title_sm"
                                  fontSize="12px !important"
                                >
                                  Year {currDate8}
                                </Text>
                                <InputFieldValidation
                                  hideLabel={true}
                                  placeholder="0 SAR"
                                  inputType="number"
                                  inputId={item}
                                  inputName={item}
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={calcValue}
                                  labelCenter={true}
                                  errorHeight0={true}
                                  uppercase={false}
                                  isDisabled={true}
                                  noBg={true}
                                  fs15={true}
                                  directVal={true}
                                />
                              </Box>
                            </GridItem>
                          );
                        })}
                      </SimpleGrid>
                    </GridItem>
                  </SimpleGrid>
                </Box>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={4} border="1px" borderColor="#E6E6E6">
            <Box
              maxW={["100%", "100%", "100%"]}
              width={["100%", "100%", "100%"]}
              marginLeft="1%"
              alignSelf="flex-start"
            >
              <FieldArray
                name="foodAndBeverage"
                render={(arrayHelper) => (
                  <>
                    {formikValues?.performaceAssitList2?.foodAndBeverage !==
                      undefined &&
                      formikValues?.performaceAssitList2?.foodAndBeverage.map(
                        (packageType, index) => {
                          currDate = new Date(
                            formikValues?.startDateOpTime
                          ).getFullYear();
                          currDate = currDate - 1;
                          let subTitle = dropdownObj?.packageTypes?.find(
                            (el) =>
                              el.value === formikValues?.packageType[index]?.type
                          );
                          return (
                            <Box key={`calcFood&B_${index}`}>
                              <Text
                                className="title_sm"
                                display="flex"
                                mb={3}
                                mt={1}
                                component="div"
                                letterSpacing="0.06em"
                                fontSize="12px"
                                textTransform="uppercase"
                              >
                                {subTitle.label}
                                {/* {formikValues?.packageType[index]?.type
                                  .split("_")
                                  .join(" ")}{" "} */}
                              </Text>

                              <Box pb={2} pt={2}>
                                <>
                                  <GridItem colSpan={2} width="85%">
                                    <SimpleGrid
                                      columns={{ sm: 2, md: 6 }}
                                      spacingX="15px"
                                      width="100%"
                                    >
                                      {packageType.year.map((_, ind) => {
                                        currDate = currDate + 1;
                                        return (
                                          <GridItem
                                            colSpan={1}
                                            key={`packageType_${ind}`}
                                          >
                                            <InputFieldCalc
                                              label={`Year ${currDate}`}
                                              placeholder="0"
                                              inputType="number"
                                              inputId={`performaceAssitList2.foodAndBeverage.${index}.year.${ind}`}
                                              inputName={`performaceAssitList2.foodAndBeverage.${index}.year.${ind}`}
                                              errors={errors}
                                              setIsFormDirty={setIsFormDirty}
                                              isFormDirty={isFormDirty}
                                              touched={touched}
                                              isRequired
                                              onChange={setFieldValue}
                                              values={
                                                formikValues
                                                  ?.performaceAssitList2
                                                  .foodAndBeverage[index]?.year[
                                                ind
                                                ]
                                              }
                                              labelCenter={true}
                                              errorHeight0={true}
                                              uppercase={false}
                                              // smallIcon={percent}
                                              calculatedOcc={true}
                                              //onChangeCalc={handleCalcOcc}
                                              setNameVal={setNameVal}
                                              cancelFuncCalc={true}
                                              isDisabled={isRestore}
                                              validateField={validateStep}
                                              smallLeftIcon="SAR"
                                              smallerIcon={true}
                                              isThousand={true}
                                              setFieldTouched={setFieldTouched}
                                              onBlur={handleBlur}
                                              directVal={true}
                                            />
                                          </GridItem>
                                        );
                                      })}
                                    </SimpleGrid>
                                  </GridItem>
                                </>
                              </Box>
                            </Box>
                          );
                        }
                      )}
                  </>
                )}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>

        {/* Forth Accordion */}
        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box
                flex="1"
                textAlign="left"
                display="flex"
                flexDirection="column"
              >
                <Box display="flex" justifyContent='space-between'>
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                    textTransform="uppercase"
                  >
                    Other
                  </Text>
                  <Flex align="center">
                    <img src={infoFillCircle} />
                    <Text
                      p="1"
                      pb="1"
                      pt="1"
                      fontSize="10px !important"
                      fontWeight="400"
                      letterSpacing="0.06em"
                    >
                      Per Person Per Night
                    </Text>
                  </Flex>
                </Box>
                <Box pt={3} pb={0}>
                  <SimpleGrid
                    columns={{ sm: 4, md: 4 }}
                    spacingX="15px"
                    width="100%"
                  >
                    <GridItem colSpan={4}>
                      <SimpleGrid
                        columns={{ sm: 6, md: 6 }}
                        spacing="0px"
                        width="88%"
                      >
                        {calcOtherFields.map((item, ind) => {
                          currDate9 = currDate9 + 1;
                          let calcValue = isDecimalNumber(formikValues[item])
                            ? parseFloat(formikValues[item])?.toFixed(2)
                            : formikValues[item];
                          return (
                            <GridItem>
                              <Box textAlign="center">
                                <Text
                                  mb="-15px !important"
                                  className="title_sm"
                                  fontSize="12px !important"
                                >
                                  Year {currDate9}
                                </Text>
                                <InputFieldValidation
                                  hideLabel={true}
                                  placeholder="0 SAR"
                                  inputType="number"
                                  inputId={item}
                                  inputName={item}
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={calcValue}
                                  labelCenter={true}
                                  errorHeight0={true}
                                  uppercase={false}
                                  isDisabled={true}
                                  noBg={true}
                                  fs15={true}
                                  directVal={true}
                                />
                              </Box>
                            </GridItem>
                          );
                        })}
                      </SimpleGrid>
                    </GridItem>
                  </SimpleGrid>
                </Box>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={4} border="1px" borderColor="#E6E6E6">
            <Box
              maxW={["100%", "100%", "100%"]}
              width={["100%", "100%", "100%"]}
              alignSelf="flex-start"
              marginLeft="1%"
            >
              <FieldArray
                name="other"
                render={(arrayHelper) => (
                  <>
                    {formikValues?.performaceAssitList2?.other !== undefined &&
                      formikValues?.performaceAssitList2?.other.map(
                        (packageType, index) => {
                          currDate = new Date(
                            formikValues?.startDateOpTime
                          ).getFullYear();
                          currDate = currDate - 1;
                          let subTitle = dropdownObj?.packageTypes?.find(
                            (el) =>
                              el.value === formikValues?.packageType[index]?.type
                          );
                          return (
                            <Box key={`calcOther_${index}`}>
                              <Text
                                className="title_sm"
                                display="flex"
                                mb={3}
                                mt={1}
                                component="div"
                                letterSpacing="0.06em"
                                fontSize="12px"
                                textTransform="uppercase"
                              >
                                {subTitle.label}
                                {/* {formikValues?.packageType[index]?.type
                                  .split("_")
                                  .join(" ")}{" "} */}
                              </Text>

                              <Box pb={2} pt={2}>
                                <>
                                  <GridItem colSpan={2} width="85%">
                                    <SimpleGrid
                                      columns={{ sm: 2, md: 6 }}
                                      spacingX="15px"
                                      width="100%"
                                    >
                                      {packageType.year.map((_, ind) => {
                                        currDate = currDate + 1;
                                        return (
                                          <GridItem
                                            colSpan={1}
                                            key={`packageType_${ind}`}
                                          >
                                            <InputFieldCalc
                                              label={`Year ${currDate}`}
                                              placeholder="0"
                                              inputType="number"
                                              inputId={`performaceAssitList2.other.${index}.year.${ind}`}
                                              inputName={`performaceAssitList2.other.${index}.year.${ind}`}
                                              errors={errors}
                                              setIsFormDirty={setIsFormDirty}
                                              isFormDirty={isFormDirty}
                                              touched={touched}
                                              isRequired
                                              onChange={setFieldValue}
                                              values={
                                                formikValues
                                                  ?.performaceAssitList2?.other[
                                                  index
                                                ]?.year[ind]
                                              }
                                              labelCenter={true}
                                              errorHeight0={true}
                                              uppercase={false}
                                              // smallIcon={percent}
                                              calculatedOcc={true}
                                              //onChangeCalc={handleCalcOcc}
                                              setNameVal={setNameVal}
                                              cancelFuncCalc={true}
                                              isDisabled={isRestore}
                                              validateField={validateStep}
                                              smallLeftIcon="SAR"
                                              smallerIcon={true}
                                              isThousand={true}
                                              setFieldTouched={setFieldTouched}
                                              onBlur={handleBlur}
                                              directVal={true}
                                            />
                                          </GridItem>
                                        );
                                      })}
                                    </SimpleGrid>
                                  </GridItem>
                                </>
                              </Box>
                            </Box>
                          );
                        }
                      )}
                  </>
                )}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>
        {/* Fifth Accordion */}
        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box
                flex="1"
                textAlign="left"
                display="flex"
                flexDirection="column"
              >
                <Box display="flex" justifyContent="space-between">
                  <Text
                    p="1"
                    pb="1"
                    pt="1"
                    fontSize="15px"
                    fontWeight="400"
                    letterSpacing="0.04em"
                    textTransform="uppercase"
                  >
                    Package rate
                  </Text>
                  <Flex align="center">
                    <img src={infoFillCircle} />
                    <Text
                      p="1"
                      pb="1"
                      pt="1"
                      fontSize="10px !important"
                      fontWeight="400"
                      letterSpacing="0.06em"
                    >
                      Per Person Per Night
                    </Text>
                  </Flex>
                </Box>
                <Box pt={3} pb={0}>
                  <SimpleGrid
                    columns={{ sm: 4, md: 4 }}
                    spacingX="15px"
                    width="100%"
                  >
                    <GridItem colSpan={4}>
                      <SimpleGrid
                        columns={{ sm: 6, md: 6 }}
                        spacing="0px"
                        width="88%"
                      >
                        {calcPackageRateFields.map((item, ind) => {
                          currDate10 = currDate10 + 1;
                          let calcValue = isDecimalNumber(formikValues[item])
                            ? parseFloat(formikValues[item])?.toFixed(2)
                            : formikValues[item];
                          return (
                            <GridItem key={`calcPackageRate_${ind}`}>
                              <Box textAlign="center">
                                <Text
                                  mb="-15px !important"
                                  className="title_sm"
                                  fontSize="12px !important"
                                >
                                  Year {currDate10}
                                </Text>
                                <InputFieldValidation
                                  hideLabel={true}
                                  placeholder="0 SAR"
                                  inputType="number"
                                  inputId={item}
                                  inputName={item}
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={calcValue}
                                  labelCenter={true}
                                  errorHeight0={true}
                                  uppercase={false}
                                  isDisabled={true}
                                  noBg={true}
                                  fs15={true}
                                  directVal={true}
                                />
                              </Box>
                            </GridItem>
                          );
                        })}
                      </SimpleGrid>
                    </GridItem>
                  </SimpleGrid>
                </Box>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={4} border="1px" borderColor="#E6E6E6">
            <Box
              maxW={["100%", "100%", "100%"]}
              width={["100%", "100%", "100%"]}
              alignSelf="flex-start"
              marginLeft="1%"
            >
              <FieldArray
                name="packageRate"
                render={(arrayHelper) => (
                  <>
                    {formikValues?.performaceAssitList2?.packageRate !==
                      undefined &&
                      formikValues?.performaceAssitList2?.packageRate.map(
                        (packageType, index) => {
                          currDate = new Date(
                            formikValues?.startDateOpTime
                          ).getFullYear();
                          currDate = currDate - 1;
                          let subTitle = dropdownObj?.packageTypes?.find(
                            (el) =>
                              el.value === formikValues?.packageType[index]?.type
                          );
                          return (
                            <Box key={`calcPackageRateFields_${index}`}>
                              <Text
                                className="title_sm"
                                display="flex"
                                mb={3}
                                mt={1}
                                component="div"
                                letterSpacing="0.06em"
                                fontSize="12px"
                                textTransform="uppercase"
                              >
                                {subTitle.label}
                                {/* {formikValues?.packageType[index]?.type
                                  .split("_")
                                  .join(" ")} */}
                              </Text>

                              <Box pb={2} pt={2}>
                                <>
                                  <GridItem colSpan={2} width="85%">
                                    <SimpleGrid
                                      columns={{ sm: 2, md: 6 }}
                                      spacingX="15px"
                                      width="100%"
                                    >
                                      {packageType.year.map((_, ind) => {
                                        currDate = currDate + 1;
                                        return (
                                          <GridItem
                                            colSpan={1}
                                            key={`packageType_${ind}`}
                                          >
                                            <InputFieldCalc
                                              label={`Year ${currDate}`}
                                              placeholder="0"
                                              inputType="number"
                                              inputId={`performaceAssitList2.packageRate.${index}.year.${ind}`}
                                              inputName={`performaceAssitList2.packageRate.${index}.year.${ind}`}
                                              errors={errors}
                                              setIsFormDirty={setIsFormDirty}
                                              isFormDirty={isFormDirty}
                                              touched={touched}
                                              isRequired
                                              onChange={setFieldValue}
                                              values={
                                                formikValues
                                                  ?.performaceAssitList2
                                                  ?.packageRate[index]?.year[
                                                ind
                                                ]
                                              }
                                              labelCenter={true}
                                              errorHeight0={true}
                                              uppercase={false}
                                              // smallIcon={percent}
                                              calculatedOcc={true}
                                              //onChangeCalc={handleCalcOcc}
                                              setNameVal={setNameVal}
                                              cancelFuncCalc={true}
                                              isDisabled={isRestore}
                                              validateField={validateStep}
                                              smallLeftIcon="SAR"
                                              smallerIcon={true}
                                              isThousand={true}
                                              setFieldTouched={setFieldTouched}
                                              onBlur={handleBlur}
                                              directVal={true}
                                            />
                                          </GridItem>
                                        );
                                      })}
                                    </SimpleGrid>
                                  </GridItem>
                                </>
                              </Box>
                            </Box>
                          );
                        }
                      )}
                  </>
                )}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex justifyContent={user == "manager" ? "end" : "space-between"} alignItems="center" width="100%">
        {
          user !== "manager" ?
            <MainButton
              title="Save Draft"
              btnStyle="transparent"
              disabledBtn={isRestore}
              fontColor="dark"
              onPress={() => {
                setDismissSave(false);
                onDraftOpen();
              }}
            /> : <></>
        }
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {/* <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm ? true : false}
              onPress={() => {
                nextPage();
                if (percentage < 55) {
                  setPercentage(55);
                }
                setStepCompleted(page);
                unsetStepWarning(page);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  handleAccordErrors();
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  nextPage();
                  if (percentage < 55) {
                    setPercentage(55);
                  }
                  setStepCompleted(page);
                  unsetStepWarning(page);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
