import {
  Box,
  Divider,
  Text,
  GridItem,
  SimpleGrid,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Heading,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FieldArray } from "formik";
import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import percent from "../../assets/images/icons/filled.svg";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import { InputFieldCalc } from "../../components/inputField/InputFieldCalc";

export const GenerateModelFormBody = ({
  checkedItems,
  errors,
  touched,
  values,
  handleBlur,
  setFieldValue,
  devDate,
  opDate,
  setInitConf,
  packageTypeArr,
}) => {
  let currDate;
  let count = 0;
  const franchiseFeeLabel = values.franchiseFeeLabel;
  const reservationContributionLabel = values.reservationContributionLabel;
  const resetCount = () => {
    count = 0;
  };

  useEffect(() => {
    setInitConf({ ...values });
  }, [values]);

  const handleChangeRadio = (event, name) => {
    setFieldValue(name, event);
  };
  const renderFields = (errors, touched, values, handleBlur, setFieldValue) => {
    if (checkedItems.length > 0) {
      return checkedItems.map((item, ind) => {
        switch (item.toLowerCase()) {
          case "adr":
            return (
              <Accordion
                width="100%"
                allowToggle
                color="black"
                defaultIndex={[0]}
                key={`accord_${ind}`}
              >
                <AccordionItem
                  mb="4"
                  borderBottom="none"
                  className="accordion_item_custom"
                >
                  <Heading
                    borderLeft="1px"
                    borderBottom="1px"
                    borderRight="1px"
                    borderColor="#E6E6E6"
                  >
                    <AccordionButton bg="#FCFCFC">
                      <Box flex="1" textAlign="left">
                        <Text
                          p="1"
                          pb="1"
                          pt="1"
                          fontSize="15px"
                          fontWeight="400"
                          letterSpacing="0.04em"
                          textTransform="uppercase"
                        >
                          Average Daily Rate
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Heading>
                  <AccordionPanel
                    pb={4}
                    pt={6}
                    border="1px"
                    borderColor="#E6E6E6"
                  >
                    <Box
                      maxW={["100%", "100%", "100%"]}
                      width={["100%", "100%", "100%"]}
                      alignSelf="flex-start"
                    >
                      <Text
                        className="title_md"
                        display="flex"
                        mb={5}
                        mt={3}
                        component="div"
                      >
                        Total Daily Average Rate
                      </Text>
                      <SimpleGrid
                        columns={{ sm: 4, md: 4 }}
                        spacingX="15px"
                        width="100%"
                      >
                        <GridItem colSpan={4}>
                          <SimpleGrid
                            columns={{ sm: 6, md: 6 }}
                            spacing="15px"
                            width="85%"
                          >
                            <GridItem>
                              <Box textAlign="center">
                                <Text className="title_sm" fontSize="15px">
                                  Year {devDate}
                                </Text>
                                <InputFieldValidation
                                  hideLabel={true}
                                  placeholder="0 SAR"
                                  inputType="number"
                                  inputId={"weightedADR1"}
                                  inputName={"weightedADR1"}
                                  errors={errors}
                                  touched={touched}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={values?.weightedAverageDailyRate[0]}
                                  labelCenter={true}
                                  errorHeight0={true}
                                  uppercase={false}
                                  noBg={true}
                                  directVal={true}
                                  isDisabled={true}
                                />
                              </Box>
                            </GridItem>
                            <GridItem>
                              <Box textAlign="center">
                                <Text className="title_sm" fontSize="15px">
                                  Year {devDate + 1}
                                </Text>
                                <InputFieldValidation
                                  hideLabel={true}
                                  placeholder="0 SAR"
                                  inputType="number"
                                  inputId={"weightedADR2"}
                                  inputName={"weightedADR2"}
                                  errors={errors}
                                  touched={touched}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={values?.weightedAverageDailyRate[2]}
                                  labelCenter={true}
                                  errorHeight0={true}
                                  uppercase={false}
                                  noBg={true}
                                  directVal={true}
                                  isDisabled={true}
                                />
                              </Box>
                            </GridItem>
                            <GridItem>
                              <Box textAlign="center">
                                <Text className="title_sm" fontSize="15px">
                                  Year {devDate + 2}
                                </Text>
                                <InputFieldValidation
                                  hideLabel={true}
                                  placeholder="0 SAR"
                                  inputType="number"
                                  inputId={"weightedADR3"}
                                  inputName={"weightedADR3"}
                                  errors={errors}
                                  touched={touched}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={values?.weightedAverageDailyRate[2]}
                                  labelCenter={true}
                                  errorHeight0={true}
                                  uppercase={false}
                                  noBg={true}
                                  directVal={true}
                                  isDisabled={true}
                                />
                              </Box>
                            </GridItem>

                            <GridItem>
                              <Box textAlign="center">
                                <Text className="title_sm" fontSize="15px">
                                  Year {devDate + 3}
                                </Text>
                                <InputFieldValidation
                                  hideLabel={true}
                                  placeholder="0 SAR"
                                  inputType="number"
                                  inputId={"weightedADR4"}
                                  inputName={"weightedADR4"}
                                  errors={errors}
                                  touched={touched}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={values?.weightedAverageDailyRate[3]}
                                  labelCenter={true}
                                  errorHeight0={true}
                                  uppercase={false}
                                  noBg={true}
                                  directVal={true}
                                  isDisabled={true}
                                />
                              </Box>
                            </GridItem>
                            <GridItem>
                              <Box textAlign="center">
                                <Text className="title_sm" fontSize="15px">
                                  Year {devDate + 4}
                                </Text>
                                <InputFieldValidation
                                  hideLabel={true}
                                  placeholder="0 SAR"
                                  inputType="number"
                                  inputId={"weightedADR5"}
                                  inputName={"weightedADR5"}
                                  errors={errors}
                                  touched={touched}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={values?.weightedAverageDailyRate[4]}
                                  labelCenter={true}
                                  errorHeight0={true}
                                  uppercase={false}
                                  noBg={true}
                                  directVal={true}
                                  isDisabled={true}
                                />
                              </Box>
                            </GridItem>
                            <GridItem>
                              <Box textAlign="center">
                                <Text className="title_sm" fontSize="15px">
                                  Year {devDate + 5}
                                </Text>
                                <InputFieldValidation
                                  hideLabel={true}
                                  placeholder="0 SAR"
                                  inputType="number"
                                  inputId={"weightedADR6"}
                                  inputName={"weightedADR6"}
                                  errors={errors}
                                  touched={touched}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={values?.weightedAverageDailyRate[5]}
                                  labelCenter={true}
                                  errorHeight0={true}
                                  uppercase={false}
                                  noBg={true}
                                  directVal={true}
                                  isDisabled={true}
                                />
                              </Box>
                            </GridItem>
                          </SimpleGrid>
                        </GridItem>
                      </SimpleGrid>
                      <Box
                        maxW={["100%", "100%", "85%"]}
                        width={["100%", "100%", "85%"]}
                        alignSelf="flex-start"
                      >
                        <Divider
                          orientation="horizontal"
                          mt="20px"
                          mb="20    px"
                        />
                      </Box>
                    </Box>
                    <Box
                      maxW={["100%", "100%", "100%"]}
                      width={["100%", "100%", "100%"]}
                      alignSelf="flex-start"
                    >
                      <FieldArray
                        name="averageDailyRate"
                        render={(arrayHelper) => (
                          <>
                            {values?.averageDailyRate !== undefined &&
                              values?.averageDailyRate.map(
                                (roomType, index) => {
                                  if (index === 0) {
                                    currDate = opDate;
                                  } else {
                                    currDate = currDate + 1;
                                  }
                                  return (
                                    <>
                                      <Text
                                        className="title_sm"
                                        display="flex"
                                        mt={1}
                                        component="div"
                                        letterSpacing="0.06em"
                                        fontSize="12px"
                                        textTransform="uppercase"
                                      >
                                        {roomType?.roomTypeName
                                          .split("_")
                                          .join(" ")}
                                      </Text>
                                      <Box pb={4} pt={6}>
                                        <>
                                          <GridItem colSpan={2} width="85%">
                                            <SimpleGrid
                                              columns={{ sm: 2, md: 6 }}
                                              spacingX="15px"
                                              width="100%"
                                            >
                                              {roomType.rates.map((_, ind) => {
                                                currDate = currDate + 1;
                                                return (
                                                  <GridItem
                                                    colSpan={1}
                                                    key={`roomType_${ind}`}
                                                  >
                                                    <InputFieldValidation
                                                      label={`Year ${currDate}`}
                                                      placeholder="0"
                                                      inputType="number"
                                                      inputId={`averageDailyRate.${index}.rates.${ind}`}
                                                      inputName={`averageDailyRate.${index}.rates.${ind}`}
                                                      errors={errors}
                                                      touched={touched}
                                                      isRequired
                                                      onChange={setFieldValue}
                                                      values={
                                                        values?.averageDailyRate
                                                          ?.rates
                                                      }
                                                      labelCenter={true}
                                                      errorHeight0={true}
                                                      uppercase={false}
                                                    />
                                                  </GridItem>
                                                );
                                              })}
                                            </SimpleGrid>
                                          </GridItem>
                                        </>
                                      </Box>
                                    </>
                                  );
                                }
                              )}
                          </>
                        )}
                      />
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            );

          case "room rate":
            return (
              <>
                <Accordion
                  width="100%"
                  allowToggle
                  color="black"
                  defaultIndex={[0]}
                >
                  <AccordionItem
                    mb="4"
                    borderBottom="none"
                    className="accordion_item_custom"
                  >
                    <Heading
                      borderLeft="1px"
                      borderBottom="1px"
                      borderRight="1px"
                      borderColor="#E6E6E6"
                    >
                      <AccordionButton bg="#FCFCFC">
                        <Box
                          flex="1"
                          textAlign="left"
                          display="flex"
                          flexDirection="column"
                        >
                          <Box display="flex">
                            <Text
                              p="1"
                              pb="1"
                              pt="1"
                              fontSize="15px"
                              fontWeight="400"
                              letterSpacing="0.04em"
                              textTransform="uppercase"
                            >
                              Room rate
                            </Text>
                            <Text
                              p="1"
                              pb="1"
                              pt="1"
                              fontSize="12px"
                              fontWeight="400"
                              letterSpacing="0.06em"
                              textTransform="uppercase"
                            >
                              (Per Person) (Per Night)
                            </Text>
                          </Box>
                          <Box pt={3} pb={0}>
                            <SimpleGrid
                              columns={{ sm: 4, md: 4 }}
                              spacingX="15px"
                              width="100%"
                            >
                              <GridItem colSpan={4}>
                                <SimpleGrid
                                  columns={{ sm: 6, md: 6 }}
                                  spacing="0px"
                                  width="88%"
                                >
                                  {values.weightedRoomRate.map((item, ind) => {
                                    // currDate = currDate + 1;
                                    if (ind === 0) {
                                      currDate = opDate;
                                    } else {
                                      currDate = currDate + 1;
                                    }
                                    return (
                                      <GridItem>
                                        <Box textAlign="center">
                                          <Text
                                            mb="-15px !important"
                                            className="title_sm"
                                            fontSize="12px !important"
                                          >
                                            Year {currDate}
                                          </Text>
                                          <InputFieldValidation
                                            hideLabel={true}
                                            placeholder="0 SAR"
                                            inputType="number"
                                            inputId={item}
                                            inputName={item}
                                            errors={errors}
                                            touched={touched}
                                            isRequired
                                            onChange={setFieldValue}
                                            values={item}
                                            labelCenter={true}
                                            errorHeight0={true}
                                            uppercase={false}
                                            isDisabled={true}
                                            directVal={true}
                                            noBg={true}
                                            fs15={true}
                                          />
                                        </Box>
                                      </GridItem>
                                    );
                                  })}
                                </SimpleGrid>
                              </GridItem>
                            </SimpleGrid>
                          </Box>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </Heading>
                    <AccordionPanel
                      pb={4}
                      pt={4}
                      border="1px"
                      className="roomRate_accordion"
                      borderColor="#E6E6E6"
                    >
                      <Box
                        maxW={["100%", "100%", "100%"]}
                        width={["100%", "100%", "100%"]}
                        alignSelf="flex-start"
                        marginLeft="1%"
                      >
                        <FieldArray
                          name="roomRate"
                          render={(arrayHelper) => (
                            <>
                              {values?.roomRate !== undefined &&
                                values?.roomRate.map((packageType, index) => {
                                  // currDate = currDate - 1;
                                  if (ind === 0) {
                                    currDate = devDate - 1;
                                  } else {
                                    currDate = currDate + 1;
                                  }
                                  let subTitle = packageTypeArr?.find(
                                    (el, i) => el.value === packageType?.type
                                  );
                                  // console.log(
                                  //   "subTitle",
                                  //   subTitle,
                                  //   packageType,
                                  //   packageTypeArr
                                  // );

                                  return (
                                    <>
                                      <Text
                                        className="title_sm item"
                                        display="flex"
                                        mb={3}
                                        mt={1}
                                        component="div"
                                        letterSpacing="0.06em"
                                        fontSize="12px"
                                        textTransform="uppercase"
                                      >
                                        {subTitle?.label}
                                        {/* {packageType?.type.split("_").join(" ")} */}
                                      </Text>

                                      <Box pb={2} pt={2}>
                                        <>
                                          <GridItem colSpan={2} width="85%">
                                            <SimpleGrid
                                              columns={{ sm: 2, md: 6 }}
                                              spacingX="15px"
                                              width="100%"
                                            >
                                              {packageType.year.map(
                                                (_, ind) => {
                                                  currDate = currDate + 1;
                                                  return (
                                                    <GridItem
                                                      colSpan={1}
                                                      key={`packageType_${ind}`}
                                                    >
                                                      <InputFieldValidation
                                                        label={`Year ${currDate}`}
                                                        placeholder="0"
                                                        inputType="number"
                                                        inputId={`roomRate.${index}.year.${ind}`}
                                                        inputName={`roomRate.${index}.year.${ind}`}
                                                        errors={errors}
                                                        touched={touched}
                                                        isRequired
                                                        onChange={setFieldValue}
                                                        values={
                                                          values?.roomRate?.year
                                                        }
                                                        labelCenter={true}
                                                        errorHeight0={true}
                                                        uppercase={false}
                                                        // smallIcon={percent}
                                                        smallLeftIcon="SAR"
                                                        smallerIcon={true}
                                                      />
                                                    </GridItem>
                                                  );
                                                }
                                              )}
                                            </SimpleGrid>
                                          </GridItem>
                                        </>
                                      </Box>
                                    </>
                                  );
                                })}
                            </>
                          )}
                        />
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </>
            );

          case "base fee":
            return (
              <Box key={`baseFee_${ind}`}>
                <Text
                  fontSize="12px"
                  color="black"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textAlign="left"
                  textTransform="uppercase"
                  mt={3}
                  mb={3}
                >
                  BASE FEE
                </Text>
                <FieldArray
                  name="baseFee"
                  render={(arrayHelper) => (
                    <Box display="flex" width="100%" gap="2.5%">
                      {values?.baseFee !== undefined &&
                        values?.baseFee?.map((_, index) => {
                          if (index === 0) {
                            currDate = parseInt(devDate);
                          } else {
                            currDate = currDate + 1;
                          }
                          return (
                            <Box
                              width={["100%", "100%", "40%"]}
                              key={`baseFee.${index}`}
                              mb="2"
                            >
                              <InputFieldValidation
                                label={`Year ${currDate}`}
                                placeholder="0.00"
                                smallIcon={percent}
                                inputType="number"
                                inputId={`baseFee.${index}`}
                                inputName={`baseFee.${index}`}
                                errors={errors}
                                touched={touched}
                                isRequired
                                onChange={setFieldValue}
                                values={values?.baseFee[index]}
                                labelCenter={true}
                                errorHeight0={true}
                                uppercase={false}
                                directVal={true}
                              />
                            </Box>
                          );
                        })}
                    </Box>
                  )}
                />
              </Box>
            );
          /* case "expense level":
            return (
              <Box key={`expense_${ind}`}>
                <SelectListMulti
                  dropdownItems={[
                    { value: "Low", label: "Low " },
                    { value: "High", label: "High" },
                    { value: "Average", label: "Average" },
                  ]}
                  placeH="Select"
                  label="EXPENSE LEVEL"
                  inputId="expenseLevel"
                  inputName="expenseLevel"
                  placeholder="Select"
                  errors={errors}
                  touched={touched}
                  isRequired
                  values={values.expenseLevel}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  uppercase={true}
                />
              </Box>
            );
           */ case "cost per key":
            return (
              <Box key={`developmentCostPerKey_SAR_${ind}`}>
                <InputFieldValidation
                  label="Development Cost Per Keys"
                  placeholder="0"
                  inputType="number"
                  // icon={percent}
                  inputId="developmentCostPerKey_SAR"
                  inputName="developmentCostPerKey_SAR"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={values}
                />
              </Box>
            );
          case "contingency":
            return (
              <Box key={`contingencyCost_${ind}`}>
                <InputFieldValidation
                  label="contingency Cost"
                  placeholder="0.00"
                  inputType="number"
                  icon={percent}
                  inputId="contingencyCost"
                  inputName="contingencyCost"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={values}
                />
              </Box>
            );
          case "number of keys":
            return (
              <Box key={`numberOfKeys_${ind}`}>
                <InputFieldValidation
                  label="Number Of Keys"
                  placeholder="0"
                  inputType="number"
                  // icon={percent}
                  inputId="numberOfKeys"
                  inputName="numberOfKeys"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={values}
                />
              </Box>
            );
          case "it budget":
            return (
              <Box key={`percentageOfBaseCost_${ind}`}>
                <InputFieldValidation
                  label="IT BUDGET"
                  placeholder="0.00"
                  inputType="number"
                  icon={percent}
                  inputId="percentageOfBaseCost"
                  inputName="percentageOfBaseCost"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={values}
                />
              </Box>
            );
          case "economies of scale":
            return (
              <Box key={`economies_${ind}`}>
                <InputFieldValidation
                  label="Economies of Scale applied"
                  placeholder="0.00"
                  inputType="number"
                  icon={percent}
                  inputId="economiesOfScaleApplied"
                  inputName="economiesOfScaleApplied"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={values}
                />
              </Box>
            );
          case "occupancy (stabilized)":
            return (
              <Box key={`occupancyStabilized_${ind}`}>
                <FieldArray
                  name="occupancyIncreaseRate"
                  render={(arrayHelper) => (
                    <Box display="flex" width="100%" gap="2.5%">
                      {values?.occupancyIncreaseRate !== undefined &&
                        values?.occupancyIncreaseRate?.map((_, index) => {
                          count = count + 1;
                          return (
                            <Box
                              width={["100%", "100%", "40%"]}
                              key={`occupancyIncreaseRate.${index}`}
                              mb="2"
                            >
                              <InputFieldValidation
                                label={`OCCUPANCY STABILIZED ${count}`}
                                placeholder="0"
                                smallIcon={percent}
                                inputType="number"
                                inputId={`occupancyIncreaseRate.${index}`}
                                inputName={`occupancyIncreaseRate.${index}`}
                                errors={errors}
                                touched={touched}
                                isRequired
                                onChange={setFieldValue}
                                values={values?.occupancyIncreaseRate[index]}
                                errorHeight0={true}
                                uppercase={false}
                                directVal={true}
                              />
                            </Box>
                          );
                        })}
                      {resetCount()}
                    </Box>
                  )}
                />
              </Box>
            );
          case "developer fee in %":
            return (
              <Box key={`neomDevFee_${ind}`}>
                <InputFieldValidation
                  label={"NEOM Hotel Development Fee"}
                  inputType="number"
                  inputId="NEOMDevelopmentFeeCost"
                  inputName="NEOMDevelopmentFeeCost"
                  errors={errors}
                  touched={touched}
                  isRequired
                  minNum="0"
                  placeholder="0"
                  onChange={setFieldValue}
                  values={values}
                  icon={percent}
                />
              </Box>
            );
          case "pre-opening and marketing expenses in %":
            return (
              <Box key={`opneningMarketingExp_${ind}`}>
                <InputFieldValidation
                  label={`Pre-Opening Training & Marketing`}
                  inputType="number"
                  inputId="preOpeningCost"
                  inputName="preOpeningCost"
                  errors={errors}
                  touched={touched}
                  isRequired
                  minNum="0"
                  placeholder="0"
                  onChange={setFieldValue}
                  values={values}
                  icon={percent}
                />
              </Box>
            );
          case "equity %":
            return (
              <Box key={`equity_${ind}`}>
                <InputFieldValidation
                  label="Equity Financing"
                  inputType="number"
                  inputId="equity"
                  inputName="equity"
                  errors={errors}
                  touched={touched}
                  isRequired
                  minNum="0"
                  placeholder="0"
                  step="0.01"
                  onChange={setFieldValue}
                  values={values}
                  icon={percent}
                />
              </Box>
            );
          case "interest rate":
            return (
              <Box key={`interestRate_${ind}`}>
                <InputFieldValidation
                  label="Annual Interest Rate"
                  inputType="number"
                  inputId="annualInterestRate"
                  inputName="annualInterestRate"
                  errors={errors}
                  touched={touched}
                  isRequired
                  minNum="0"
                  placeholder="0"
                  step="0.01"
                  onChange={setFieldValue}
                  values={values}
                  icon={percent}
                />
              </Box>
            );
          case "ebitda multiple":
            return (
              <Box key={`ebtidaMultiple_${ind}`}>
                <InputFieldValidation
                  label="EBITDA Multiple"
                  inputType="number"
                  inputId="ebidtaMultiple"
                  inputName="ebidtaMultiple"
                  errors={errors}
                  touched={touched}
                  isRequired
                  minNum="0"
                  placeholder="0"
                  onChange={setFieldValue}
                  values={values}
                  isThousand={true}
                  onBlur={handleBlur}
                />
              </Box>
            );
          case "branded residence inclusion":
            return (
              <Box key={`brandedResirenceInclusion_${ind}`}>
                <Text
                  className="title_sm"
                  display="flex"
                  mb={2}
                  mt={3}
                  component="div"
                  letterSpacing="0.06em"
                  fontSize="12px"
                  textTransform="uppercase"
                >
                  Branded Residences inclusion{" "}
                </Text>

                <RadioGroup
                  onChange={(event) =>
                    setFieldValue("brandedResidenceInclusion", event)
                  }
                  value={values.brandedResidenceInclusion}
                  className="radio_group_custom"
                  mb={4}
                  name="brandedResidenceInclusion"
                >
                  <Stack direction="row">
                    <Radio
                      color="#003865 !important"
                      bg="none !important"
                      borderColor="#6F767E !important"
                      value="1"
                    >
                      Yes
                    </Radio>
                    <Radio
                      color="#003865 !important"
                      bg="none !important"
                      borderColor="#6F767E !important"
                      value="2"
                    >
                      No
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Box>
            );
          case "staff per key":
            return (
              <Box key={`staffToKeyRatio${ind}`}>
                <Text
                  fontSize="12px"
                  color="black"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textAlign="left"
                  textTransform="uppercase"
                  mt={3}
                  mb={3}
                >
                  STAFF PER KEY
                </Text>
                <FieldArray
                  name="staffToKeyRatio"
                  render={(arrayHelper) => (
                    <Box display="flex" width="100%" gap="2.5%">
                      {values?.staffToKeyRatio !== undefined &&
                        values?.staffToKeyRatio?.map((_, index) => {
                          currDate =
                            index === 0 ? parseInt(devDate) : ++currDate;

                          return (
                            <Box
                              width={["100%", "100%", "40%"]}
                              key={`staffToKeyRatio.${index}`}
                              mb="2"
                            >
                              <InputFieldValidation
                                label={`Year ${currDate}`}
                                placeholder="0"
                                inputType="number"
                                inputId={`staffToKeyRatio.${index}`}
                                inputName={`staffToKeyRatio.${index}`}
                                errors={errors}
                                touched={touched}
                                isRequired
                                onChange={setFieldValue}
                                values={values?.staffToKeyRatio[index]}
                                labelCenter={true}
                                errorHeight0={true}
                                uppercase={false}
                                directVal={true}
                              />
                            </Box>
                          );
                        })}
                    </Box>
                  )}
                />
              </Box>
            );
          case "staff cost per staff":
            return (
              <Box key={`staffHousingCostPerStaff${ind}`}>
                <Text
                  fontSize="12px"
                  color="black"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textAlign="left"
                  textTransform="uppercase"
                  mt={3}
                  mb={3}
                >
                  Staff Housing - Cost per Staff {"(Per Year in SAR)"}
                </Text>
                <FieldArray
                  name="staffHousingCostPerStaff"
                  render={(arrayHelper) => (
                    <Box display="flex" width="100%" gap="2.5%">
                      {values?.staffHousingCostPerStaff !== undefined &&
                        values?.staffHousingCostPerStaff?.map((_, index) => {
                          currDate =
                            index === 0 ? parseInt(devDate) : ++currDate;
                          return (
                            <Box
                              width={["100%", "100%", "40%"]}
                              key={`staffHousingCostPerStaff.${index}`}
                              mb="2"
                            >
                              <InputFieldValidation
                                label={`Year ${currDate}`}
                                placeholder="0"
                                inputType="number"
                                inputId={`staffHousingCostPerStaff.${index}`}
                                inputName={`staffHousingCostPerStaff.${index}`}
                                errors={errors}
                                touched={touched}
                                isRequired
                                onChange={setFieldValue}
                                values={values?.staffHousingCostPerStaff[index]}
                                labelCenter={true}
                                errorHeight0={true}
                                uppercase={false}
                                directVal={true}
                                smallLeftIcon={"SAR"}
                              />
                            </Box>
                          );
                        })}
                    </Box>
                  )}
                />
              </Box>
            );
          case reservationContributionLabel.toLowerCase():
            // return (
            //   <Box key={`reservationContribution_${ind}`}>
            //     <InputFieldValidation
            //       label="Reservation Contribution (as a % of Room Revenue)"
            //       inputType="number"
            //       inputId="reservationContribution"
            //       inputName="reservationContribution"
            //       errors={errors}
            //       touched={touched}
            //       isRequired
            //       minNum="0"
            //       placeholder="0"
            //       onChange={setFieldValue}
            //       values={values}
            //       icon={percent}
            //     />
            //   </Box>
            // );
            return (
              <Accordion
                width="100%"
                allowToggle
                color="black"
                defaultIndex={[0]}
                key={`accord_${ind}`}
              >
                <AccordionItem
                  mb="4"
                  borderBottom="none"
                  className="accordion_item_custom"
                >
                  <Heading
                    borderLeft="1px"
                    borderBottom="1px"
                    borderRight="1px"
                    borderColor="#E6E6E6"
                  >
                    <AccordionButton bg="#FCFCFC">
                      <Box flex="1" textAlign="left">
                        <Text
                          p="1"
                          pb="1"
                          pt="1"
                          fontSize="15px"
                          fontWeight="400"
                          letterSpacing="0.04em"
                          textTransform="uppercase"
                        >
                          {reservationContributionLabel}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Heading>
                  <AccordionPanel
                    pb={4}
                    pt={6}
                    border="1px"
                    borderColor="#E6E6E6"
                  >
                    <Box
                      maxW={["100%", "100%", "100%"]}
                      width={["100%", "100%", "100%"]}
                      alignSelf="flex-start"
                    >
                      <FieldArray
                        name="reservationContributionFixed"
                        render={(arrayHelper) => (
                          <>
                            {values?.reservationContribution !== undefined && (
                              <>
                                <Text
                                  className="title_sm"
                                  display="flex"
                                  mt={1}
                                  component="div"
                                  letterSpacing="0.06em"
                                  fontSize="12px"
                                  textTransform="uppercase"
                                >
                                  FIXED CHARGE
                                </Text>
                                <Box pb={4} pt={6}>
                                  <>
                                    <GridItem colSpan={2} width="85%">
                                      <SimpleGrid
                                        columns={{ sm: 2, md: 5 }}
                                        spacingX="15px"
                                        width="100%"
                                      >
                                        {values.reservationContribution.map(
                                          (_, index) => {
                                            currDate =
                                              index === 0
                                                ? parseInt(devDate)
                                                : ++currDate;
                                            return (
                                              <GridItem
                                                colSpan={1}
                                                key={`reservationContribution_fixed_${index}`}
                                              >
                                                <InputFieldValidation
                                                  label={`Year ${currDate}`}
                                                  placeholder="0"
                                                  inputType="number"
                                                  inputId={`reservationContribution.${index}.fixedCharge`}
                                                  inputName={`reservationContribution.${index}.fixedCharge`}
                                                  errors={errors}
                                                  touched={touched}
                                                  isRequired
                                                  onChange={setFieldValue}
                                                  values={
                                                    values
                                                      ?.reservationContribution
                                                      ?.fixedCharge
                                                  }
                                                  labelCenter={true}
                                                  errorHeight0={true}
                                                  uppercase={false}
                                                />
                                              </GridItem>
                                            );
                                          }
                                        )}
                                      </SimpleGrid>
                                    </GridItem>
                                  </>
                                </Box>
                              </>
                            )}
                          </>
                        )}
                      />
                    </Box>
                    <Box
                      maxW={["100%", "100%", "85%"]}
                      width={["100%", "100%", "85%"]}
                      alignSelf="flex-start"
                    >
                      <Divider
                        orientation="horizontal"
                        mt="20px"
                        mb="20    px"
                      />
                    </Box>
                    <Box
                      maxW={["100%", "100%", "100%"]}
                      width={["100%", "100%", "100%"]}
                      alignSelf="flex-start"
                    >
                      <FieldArray
                        name="reservationContributionPerc"
                        render={(arrayHelper) => (
                          <>
                            {values?.reservationContribution !== undefined && (
                              <>
                                <Text
                                  className="title_sm"
                                  display="flex"
                                  mt={1}
                                  component="div"
                                  letterSpacing="0.06em"
                                  fontSize="12px"
                                  textTransform="uppercase"
                                >
                                  {`PERCENTAGES of ( ${values.revenueType} Revenue )`}
                                </Text>
                                <Box pb={4} pt={6}>
                                  <>
                                    <GridItem colSpan={2} width="85%">
                                      <SimpleGrid
                                        columns={{ sm: 2, md: 5 }}
                                        spacingX="15px"
                                        width="100%"
                                      >
                                        {values.reservationContribution.map(
                                          (_, index) => {
                                            return (
                                              <GridItem
                                                colSpan={1}
                                                key={`reservationContribution_${index}`}
                                              >
                                                <InputFieldValidation
                                                  placeholder="0"
                                                  minNum="0"
                                                  maxNum="100"
                                                  inputType="number"
                                                  inputId={`reservationContribution.${index}.percentage`}
                                                  inputName={`reservationContribution.${index}.percentage`}
                                                  errors={errors}
                                                  touched={touched}
                                                  isRequired
                                                  onChange={setFieldValue}
                                                  values={
                                                    values
                                                      ?.reservationContribution
                                                      ?.percentage
                                                  }
                                                  labelCenter={true}
                                                  errorHeight0={true}
                                                  uppercase={false}
                                                  icon={percent}
                                                />
                                              </GridItem>
                                            );
                                          }
                                        )}
                                      </SimpleGrid>
                                    </GridItem>
                                  </>
                                </Box>
                              </>
                            )}
                          </>
                        )}
                      />
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              // <Box key={`reservationContribution_${ind}`}>
              //   <InputFieldValidation
              //     label="FRANCHISE FEE "
              //     inputType="number"
              //     inputId="reservationContribution"
              //     inputName="reservationContribution"
              //     errors={errors}
              //     touched={touched}
              //     isRequired
              //     minNum="0"
              //     placeholder="0"
              //     icon={percent}
              //     onChange={setFieldValue}
              //     values={values}
              //   />
              // </Box>
            );
          case "gfa":
            return (
              <Box key={`gfa_${ind}`}>
                <InputFieldCalc
                  label="GFA Efficiency"
                  inputType="number"
                  inputId="GFAEfficiency"
                  inputName="GFAEfficiency"
                  errors={errors}
                  touched={touched}
                  isRequired
                  minNum="0"
                  placeholder="0"
                  icon={percent}
                  onChange={setFieldValue}
                  values={values}
                  calculatedOcc={true}
                />
              </Box>
            );
          case "other expenses rooms":
            return (
              <Box key={`otherExpensesRooms_${ind}`}>
                {" "}
                <Text fontSize={"12px"} display="flex" mt={3} component="div">
                  OTHER EXPENSES
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Value"
                            uppercase={false}
                            inputType="number"
                            inputId={`otherExpensesRoom.value`}
                            inputName={`otherExpensesRoom.value`}
                            placeholder="0"
                            errors={errors}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            onChange={setFieldValue}
                            values={values?.otherExpensesRoom?.value}
                            minNum={0}
                            directVal={true}
                            smallLeftIcon={"SAR"}
                          />
                        </Box>
                      </GridItem>
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Fixed Percentage"
                            uppercase={false}
                            inputType="number"
                            inputId={`otherExpensesRoom.fixed`}
                            inputName={`otherExpensesRoom.fixed`}
                            placeholder="0"
                            errors={errors}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            onChange={setFieldValue}
                            values={values?.otherExpensesRoom?.fixed}
                            minNum={0}
                            directVal={true}
                            // smallIcon={percent}
                            icon={percent}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
            );
          case "incentive fee":
            return (
              <Box key={`incentiveFee_${ind}`}>
                <Text
                  className="title_sm"
                  display="flex"
                  mb={3}
                  mt={3}
                  component="div"
                  textTransform="uppercase"
                  letterSpacing="0.06em"
                  fontSize="12px"
                >
                  Incentive Fee (% of AGOP){" "}
                </Text>
                <FieldArray
                  name="incentiveFee"
                  render={(arrayHelper) =>
                    values.incentiveFee !== undefined &&
                    values.incentiveFee.map((_, index) => {
                      return (
                        <Box
                          display="flex"
                          width="100%"
                          gap="4%"
                          flexWrap="wrap"
                          key={`incentiveFee_${index}`}
                        >
                          <Box width={["100%", "100%", "48%"]}>
                            <InputFieldValidation
                              label="if GOP <"
                              placeholder="0"
                              inputType="number"
                              inputId={`incentiveFee.${index}.ifGOPLessThanPercent`}
                              inputName={`incentiveFee.${index}.ifGOPLessThanPercent`}
                              errors={errors}
                              touched={touched}
                              isRequired
                              onChange={setFieldValue}
                              values={
                                values?.incentiveFee[index].ifGOPLessThanPercent
                              }
                              //errorHeight0={true}
                              icon={percent}
                              labelVisibility={index === 0 ? true : false}
                              directVal={true}
                              errorShape={true}
                            />
                          </Box>
                          <Box width={["100%", "100%", "48%"]}>
                            <InputFieldValidation
                              label={`Incentive fee % of AGOP`}
                              placeholder="0"
                              icon={percent}
                              inputType="number"
                              inputId={`incentiveFee.${index}.feePercentOfGOP`}
                              inputName={`incentiveFee.${index}.feePercentOfGOP`}
                              errors={errors}
                              touched={touched}
                              isRequired
                              onChange={setFieldValue}
                              values={
                                values?.incentiveFee[index].feePercentOfGOP
                              }
                              //errorHeight0={true}
                              labelVisibility={index === 0 ? true : false}
                              directVal={true}
                            />
                          </Box>
                        </Box>
                      );
                    })
                  }
                />
              </Box>
            );
          case franchiseFeeLabel.toLowerCase():
            return (
              <Accordion
                width="100%"
                allowToggle
                color="black"
                defaultIndex={[0]}
                key={`accord_${ind}`}
              >
                <AccordionItem
                  mb="4"
                  borderBottom="none"
                  className="accordion_item_custom"
                >
                  <Heading
                    borderLeft="1px"
                    borderBottom="1px"
                    borderRight="1px"
                    borderColor="#E6E6E6"
                  >
                    <AccordionButton bg="#FCFCFC">
                      <Box flex="1" textAlign="left">
                        <Text
                          p="1"
                          pb="1"
                          pt="1"
                          fontSize="15px"
                          fontWeight="400"
                          letterSpacing="0.04em"
                          textTransform="uppercase"
                        >
                          {franchiseFeeLabel}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Heading>
                  <AccordionPanel
                    pb={4}
                    pt={6}
                    border="1px"
                    borderColor="#E6E6E6"
                  >
                    <Box
                      maxW={["100%", "100%", "100%"]}
                      width={["100%", "100%", "100%"]}
                      alignSelf="flex-start"
                    >
                      <FieldArray
                        name="franchiseFeeFixed"
                        render={(arrayHelper) => (
                          <>
                            {values?.franchiseFee !== undefined && (
                              <>
                                <Text
                                  className="title_sm"
                                  display="flex"
                                  mt={1}
                                  component="div"
                                  letterSpacing="0.06em"
                                  fontSize="12px"
                                  textTransform="uppercase"
                                >
                                  FIXED CHARGE
                                </Text>
                                <Box pb={4} pt={6}>
                                  <>
                                    <GridItem colSpan={2} width="85%">
                                      <SimpleGrid
                                        columns={{ sm: 2, md: 5 }}
                                        spacingX="15px"
                                        width="100%"
                                      >
                                        {values.franchiseFee.map((_, index) => {
                                          currDate =
                                            index === 0
                                              ? parseInt(devDate)
                                              : ++currDate;
                                          return (
                                            <GridItem
                                              colSpan={1}
                                              key={`franchiseFee_fixedCharge_${index}`}
                                            >
                                              <InputFieldValidation
                                                label={`Year ${currDate}`}
                                                placeholder="0"
                                                inputType="number"
                                                inputId={`franchiseFee.${index}.fixedCharge`}
                                                inputName={`franchiseFee.${index}.fixedCharge`}
                                                errors={errors}
                                                touched={touched}
                                                isRequired
                                                onChange={setFieldValue}
                                                values={
                                                  values?.franchiseFee
                                                    ?.fixedCharge
                                                }
                                                labelCenter={true}
                                                errorHeight0={true}
                                                uppercase={false}
                                              />
                                            </GridItem>
                                          );
                                        })}
                                      </SimpleGrid>
                                    </GridItem>
                                  </>
                                </Box>
                              </>
                            )}
                          </>
                        )}
                      />
                    </Box>
                    <Box
                      maxW={["100%", "100%", "85%"]}
                      width={["100%", "100%", "85%"]}
                      alignSelf="flex-start"
                    >
                      <Divider
                        orientation="horizontal"
                        mt="20px"
                        mb="20    px"
                      />
                    </Box>
                    <Box
                      maxW={["100%", "100%", "100%"]}
                      width={["100%", "100%", "100%"]}
                      alignSelf="flex-start"
                    >
                      <FieldArray
                        name="franchiseFeePerc"
                        render={(arrayHelper) => (
                          <>
                            {values?.franchiseFee !== undefined && (
                              <>
                                <Text
                                  className="title_sm"
                                  display="flex"
                                  mt={1}
                                  component="div"
                                  letterSpacing="0.06em"
                                  fontSize="12px"
                                  textTransform="uppercase"
                                >
                                  {`PERCENTAGES of ( ${values.revenueType} Revenue )`}
                                </Text>
                                <Box pb={4} pt={6}>
                                  <>
                                    <GridItem colSpan={2} width="85%">
                                      <SimpleGrid
                                        columns={{ sm: 2, md: 5 }}
                                        spacingX="15px"
                                        width="100%"
                                      >
                                        {values.franchiseFee.map((_, index) => {
                                          return (
                                            <GridItem
                                              colSpan={1}
                                              key={`franchiseFeePercentage_${index}`}
                                            >
                                              <InputFieldValidation
                                                placeholder="0"
                                                minNum="0"
                                                maxNum="100"
                                                inputType="number"
                                                inputId={`franchiseFee.${index}.percentage`}
                                                inputName={`franchiseFee.${index}.percentage`}
                                                errors={errors}
                                                touched={touched}
                                                isRequired
                                                onChange={setFieldValue}
                                                values={
                                                  values?.franchiseFee
                                                    ?.percentage
                                                }
                                                labelCenter={true}
                                                errorHeight0={true}
                                                uppercase={false}
                                                icon={percent}
                                              />
                                            </GridItem>
                                          );
                                        })}
                                      </SimpleGrid>
                                    </GridItem>
                                  </>
                                </Box>
                              </>
                            )}
                          </>
                        )}
                      />
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              // <Box key={`franchiseFee_${ind}`}>
              //   <InputFieldValidation
              //     label="FRANCHISE FEE "
              //     inputType="number"
              //     inputId="franchiseFee"
              //     inputName="franchiseFee"
              //     errors={errors}
              //     touched={touched}
              //     isRequired
              //     minNum="0"
              //     placeholder="0"
              //     icon={percent}
              //     onChange={setFieldValue}
              //     values={values}
              //   />
              // </Box>
            );
          case "fee cap":
            return (
              <Box key={`percentageCap_${ind}`}>
                <InputFieldValidation
                  label="Fee Cap"
                  inputType="number"
                  inputId="percentageCap"
                  inputName="percentageCap"
                  errors={errors}
                  touched={touched}
                  isRequired
                  minNum="0"
                  placeholder="0"
                  onChange={setFieldValue}
                  values={values}
                  icon={percent}
                />
              </Box>
            );
          case `Include ${franchiseFeeLabel} in calculations`.toLowerCase():
            return (
              <Box key={`includeFranchiseFee${ind}`}>
                <Text
                  className="title_sm"
                  display="flex"
                  mb={3}
                  mt={3}
                  component="div"
                  textTransform="uppercase"
                  letterSpacing="0.06em"
                  fontSize="12px"
                >
                  Include {franchiseFeeLabel} in calculations
                </Text>
                <RadioGroup
                  onChange={(event) =>
                    handleChangeRadio(event, "includeFranchiseFee")
                  }
                  value={`${values?.includeFranchiseFee}`}
                  className="radio_group_custom"
                  mb={4}
                  name="includeFranchiseFee"
                  defaultValue="yes"
                >
                  <Stack direction="row">
                    <Radio
                      color="#003865 !important"
                      bg="none !important"
                      borderColor="#6F767E !important"
                      value="yes"
                    >
                      Yes
                    </Radio>
                    <Radio
                      color="#003865 !important"
                      bg="none !important"
                      borderColor="#6F767E !important"
                      value="no"
                    >
                      No
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Box>
            );
          default:
            return <></>;
        }
      });
    }
  };

  return (
    <>{renderFields(errors, touched, values, handleBlur, setFieldValue)}</>
  );
};
