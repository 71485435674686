import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Tab,
  Tabs,
  TabList,
  Spinner,
  Center,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import leftArrow3 from "../../assets/images/icons/leftArrow3.svg";
import { GetProductionPositioningWithIsActive } from "../../api/MasterDataAPI";

export const AssetListAggregation = ({ projectName, back }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [keyData, setKeyData] = useState([]);
  const [keyDataPositioning, setKeyDataPositioning] = useState([]);
  const [assetDataPositioning, setAssetDataPositioning] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [positionListProduction, setPositionListProduction] = useState([]);
  const allAssets = useSelector((state) => state.approval.allAssets);
  const [isLoading, setIsLoading] = useState(true);
  const yearKey = currentTab === 0 ? "openingYear" : "unapprovedOpeningYear";
  const positioningKey =
    currentTab === 0 ? "positioning" : "unapprovedPositioning";
  const keysKey = currentTab === 0 ? "keys" : "unapprovedKeys";

  const allYears = allAssets.map((item) => item[yearKey]);
  const minYear = Math.min(...allYears);
  const maxYear = Math.max(...allYears);
  const years = Array.from(
    { length: maxYear - minYear + 1 },
    (_, i) => minYear + i
  );
  const generateColumns = (years) => {
    let columns = [
      {
        name: "Region",
        selector: (row) => row.region,
        sortable: true,
        minWidth: "250px",
        maxWidth: "350px",
        wrap: true,
      },
    ];
    years.forEach((year) => {
      columns.push({
        name: year.toString(),
        selector: (row) => formatNumber(row[year]),
        sortable: true,
        grow: 1,
      });
    });
    return columns;
  };
  const generateColumnsPositions = (years) => {
    let columns = [
      {
        name: "Positioning",
        selector: (row) => row.positioning,
        sortable: true,
        minWidth: "250px",
        maxWidth: "350px",
        wrap: true,
      },
    ];
    years.forEach((year) => {
      columns.push({
        name: year.toString(),
        selector: (row) => formatNumber(row[year]),
        sortable: true,
        grow: 1,
      });
    });
    return columns;
  };
  const keyColumns = generateColumns(years);
  const assetColumns = generateColumns(years);
  const keyColumnsPositioning = generateColumnsPositions(years);
  const assetColumnsPositioning = generateColumnsPositions(years);
  useEffect(() => {
    const data = allAssets;
    GetProductionPositioningWithIsActive()
      .then((res) => {
        if (res.data[0]?.lookups?.length > 0) {
          setPositionListProduction(res.data[0]?.lookups);
        }
      })
      .catch((err) => console.log(err));
    aggregateData(data);
  }, [allAssets, currentTab]);

  const aggregateData = (data) => {
    if (data && data.length > 0) {
      const allYears = data.map((item) => item[yearKey]);
      const minYear = Math.min(...allYears);
      const maxYear = Math.max(...allYears);
      const years = Array.from(
        { length: maxYear - minYear + 1 },
        (_, i) => minYear + i
      );

      const { keys, assets, keysPositioning, assetsPositioning } = calculateAggregates(data, years);
      setKeyData([...keys, calculateTotals(keys, years).totalRow]);
      setKeyDataPositioning([
        ...keysPositioning,
        calculateTotals(keys, years).totalRowPositioning,
      ]);

      setAssetData([...assets, calculateTotals(assets, years).totalRow]);
      setAssetDataPositioning([
        ...assetsPositioning,
        calculateTotals(assets, years).totalRowPositioning,
      ]);
    }
  };

  const formatNumber = (num) => {
    if (num === null || num === undefined || num === "-") return "-";
    return Math.round(num)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const calculateAggregates = (data, years) => {
    const regions = [...new Set(data.map((item) => item.region))];
    const positionings = [...new Set(data.map((item) => item[positioningKey]))];
    let keyResult = {};
    let assetResult = {};
    let keyResultPositioning = {};
    let assetResultPositioning = {};

    regions.forEach((region) => {
      keyResult[region] = {};
      assetResult[region] = {};
      years.forEach((year) => {
        keyResult[region][year] = 0;
        assetResult[region][year] = 0;
      });
    });

    data.forEach((asset) => {
      years.forEach((year) => {
        if (year >= asset[yearKey]) {
          keyResult[asset.region][year] += asset[keysKey];
          assetResult[asset.region][year] += 1;
        }
      });
    });

    let keyTable = [];
    let assetTable = [];
    let keyTablePositioning = [];
    let assetTablePositioning = [];

    positionings.forEach((positioning) => {
      keyResultPositioning[positioning] = {};
      assetResultPositioning[positioning] = {};
      years.forEach((year) => {
        keyResultPositioning[positioning][year] = 0;
        assetResultPositioning[positioning][year] = 0;
      });
    });

    data.forEach((asset) => {
      years.forEach((year) => {
        if (year >= asset[yearKey]) {
          keyResultPositioning[asset[positioningKey]][year] += asset[keysKey];
          assetResultPositioning[asset[positioningKey]][year] += 1;
        }
      });
    });

    regions.forEach((region) => {
      let keyRow = { region };
      let assetRow = { region };
      years.forEach((year) => {
        keyRow[year] = keyResult[region][year];
        assetRow[year] = assetResult[region][year];
      });
      keyTable.push(keyRow);
      assetTable.push(assetRow);
    });

    positionings.forEach((positioning) => {
      let keyRow = { positioning };
      let assetRow = { positioning };
      years.forEach((year) => {
        keyRow[year] = keyResultPositioning[positioning][year];
        assetRow[year] = assetResultPositioning[positioning][year];
      });
      if (currentTab !== 0) {
        let label = positionListProduction.find(
          (item) => item.value === keyRow.positioning
        );
        keyRow.positioning = label?.label;

        let labelName = positionListProduction.find(
          (item) => item.value === assetRow.positioning
        );
        assetRow.positioning = labelName?.label;

      }
      keyTablePositioning.push(keyRow);
      assetTablePositioning.push(assetRow);
    });
    setIsLoading(false);

    return {
      keys: keyTable,
      assets: assetTable,
      keysPositioning: keyTablePositioning,
      assetsPositioning: assetTablePositioning,
    };
  };

  const calculateTotals = (data, years) => {
    let totalRow = { region: "Total" };
    let totalRowPositioning = { positioning: "Total" };
    years.forEach((year) => {
      totalRow[year] = data.reduce((sum, row) => sum + row[year], 0);
      totalRowPositioning[year] = data.reduce((sum, row) => sum + row[year], 0);
    });
    return { totalRow, totalRowPositioning };
  };

  const handleTabChange = (index) => {
    setCurrentTab(index);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.region === "Total" || row.positioning === "Total",
      style: {
        fontWeight: "bold",
      },
    },
  ];

  return (
    <>
      <MainButton
        title="Back"
        btnStyle="transparent"
        fontColor="dark"
        marginRight="1"
        onPress={() => back(false)}
        icon={leftArrow3}
      />
      <Box mt="24px" display="flex" justifyContent="space-between">
        <MainTitle title="Aggregate View" />
        <Tabs
          variant={"soft-rounded"}
          color={"gold-darker-color"}
          pb={8}
          defaultIndex={0}
          bg={"gold-darker-color"}
          onChange={handleTabChange}
        >
          <TabList className={"homepage-kpi-tablist"}>
            <Tab className={"homepage-kpi-tab"} fontWeight={400} fontSize={14}>
              UPDATED KEYS
            </Tab>
            <Tab className={"homepage-kpi-tab"} fontWeight={400} fontSize={14}>
              CURRENT KEYS
            </Tab>
          </TabList>
        </Tabs>
      </Box>
      <MainBox>
        {isLoading ? (
          <Box width="100%" paddingY={10}>
            <Center>
              <Spinner />
            </Center>
          </Box>
        ) : (
          <>
            <Box
              className="table_content"
              pt={3}
              pb={2}
              mb={5}
              style={{ overflowX: "auto" }}
            >
              <Heading size="md">
                Region {currentTab == 0 ? "Keys" : "Updated Keys"}
              </Heading>
              <DataTable
                columns={keyColumns}
                data={keyData}
                defaultSortAsc={false}
                responsive={true}
                overflowY={false}
                conditionalRowStyles={conditionalRowStyles}
              />
            </Box>

            <Box
              className="table_content"
              pt={3}
              pb={2}
              mb={5}
              style={{ overflowX: "auto" }}
            >
              <Heading size="md">
                Positioning {currentTab == 0 ? "Keys" : "Updated Keys"}
              </Heading>
              <DataTable
                columns={keyColumnsPositioning}
                data={keyDataPositioning}
                defaultSortAsc={false}
                responsive={true}
                overflowY={false}
                conditionalRowStyles={conditionalRowStyles}
              />
            </Box>
            <Box
              className="table_content"
              pt={3}
              pb={2}
              mb={5}
              style={{ overflowX: "auto" }}
            >
              <Heading size="md">Region {currentTab == 0 ? "" : "Updated"} Assets</Heading>
              <DataTable
                columns={assetColumns}
                data={assetData}
                defaultSortAsc={false}
                responsive={true}
                overflowY={false}
                conditionalRowStyles={conditionalRowStyles}
              />
            </Box>
            <Box
              className="table_content"
              pt={3}
              pb={2}
              mb={5}
              style={{ overflowX: "auto" }}
            >
              <Heading size="md"> Positioning {currentTab == 0 ? "" : "Updated"} Assets</Heading>
              <DataTable
                columns={assetColumnsPositioning}
                data={assetDataPositioning}
                defaultSortAsc={false}
                responsive={true}
                overflowY={false}
                conditionalRowStyles={conditionalRowStyles}
              />
            </Box>
          </>
        )}
      </MainBox>
    </>
  );
};

export default AssetListAggregation;
