import React, { useState } from "react";
import { useParams } from "react-router-dom";

import calculate from "../../assets/images/icons/calculate.svg";
import { ModalAlert } from "../modalAlert/ModalAlert"
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import {

    useDisclosure,
    Box,
    Badge,
    HStack
} from "@chakra-ui/react";
import { MainButton } from "../button/MainButton";

import close from "../../assets/images/icons/UnionClose.svg";
import { Calculation } from "../../components/calculationModal/calculation";
import {
    calculateTableValues
} from "../../api/AssetListAPI";

import {
    assetListStandardFormFormikValues,
    assetListPackageFormFormikValues
} from "../../utils/AssetListObj";
export const CalculationButton = ({
    formikValue,
    isOpen,
    onClose,

}) => {
    let { id } = useParams();

    const [data, setData] = useState({})
    const {
        isOpen: isCalculationOpen,
        onOpen: onCalculationOpen,
        onClose: onCalculationClose,
    } = useDisclosure();

    const {
        isOpen: isErrorOpen,
        onOpen: onErrorOpen,
        onClose: onErrorClose,
    } = useDisclosure();

    const showCalculation = () => {
        let submitValues;
        if (formikValue.assetType === "Standard") {
            submitValues = assetListStandardFormFormikValues(
                formikValue
            );
        } else {
            submitValues = assetListPackageFormFormikValues(
                formikValue
            );
        }

        submitValues.versionNumber = Number(id)
        calculateTableValues(submitValues)

            .then((res) => {
                if (res.status == 200) {
                    setData(res.data.calculations)
                    onCalculationOpen()
                } else {
                    onErrorOpen();
                }

            })

            .catch((err) => {
                onErrorOpen();
            });

    }
    return (
        // <h1>test</h1>
        <Box>
            <Box float="right">
                <HStack
                    w="100%"
                      /* px="8" */ marginBottom={4}
                    marginTop={2}
                >
                    <Badge
                        bg="rgb(249 207 11)"
                        className="masterDataBadge"
                        cursor="pointer"
                        onClick={showCalculation}
                    >
                        <img src={calculate} alt="tooltip icon" />
                    </Badge>
                </HStack>
            </Box>

            {
                isCalculationOpen ? <Calculation
                    isOpen={isCalculationOpen}
                    onClose={onCalculationClose}
                    assetData={data}
                /> : <></>
            }

            {
                isErrorOpen ? <ModalAlert
                    isOpen={isErrorOpen}
                    onClose={onErrorClose}
                    title="Something went wrong"
                    icon={infoCircle}
                    hasDesc={false}
                    confirmationModal={false}
                /> : <></>
            }
        </Box>
    );
}