import React, { useRef, useState, useEffect } from "react";

import {
  Box,
  Flex,
  Divider,
  Text,
  HStack,
  Accordion,
  AccordionItem,
  Heading,
  AccordionButton,
  SimpleGrid,
  GridItem,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";

import percent from "../../assets/images/icons/filled.svg";
import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import {CalculationButton} from"../../components/calculationModal/calculationButton"

export const AssetsListForm7 = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  setStepWarning,
  unsetStepWarning,
  setStepperUpdate,
  stepperUpdate,
  validateStep,
  setValidateStep,
  id,
  modelWarningOpen,
  isModelWarningOpen,
  closeModalPath,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
  user
}) => {
  let accordTitle = [
    "Admin and General",
    "Information & Telecom Systems",
    "Sales & Marketing",
    "Property Operations & Maintenance",
    "Utilities ",
  ];
  //console.log("FORMIK7", formikValues);
  const [arrayKeys, setArrayKeys] = useState([]);
  const inputRef = useRef(null);
  let arrKeys = [];
  const dataFetchedRef = useRef(false);

  if (formikValues?.undistributedExpenses) {
    arrKeys = Object.keys(formikValues.undistributedExpenses);
    if (JSON.stringify(arrayKeys) !== JSON.stringify(arrKeys)) {
      setArrayKeys(arrKeys);
    }
  }

  let form7Data = [];
  let emptyform7Data = [];

  form7Data.push(formikValues.operationalInsurance); //string
  form7Data.push(formikValues.permitsAndLicenses); //string
  form7Data.push(formikValues.landLeaseFee); //string
  form7Data.push(formikValues.propertyAndOtherTaxes); //string
  form7Data.push(formikValues.assetManagementFee); //string
  form7Data.push(formikValues.otherFixedCharges2); //string
  form7Data.push(formikValues.fixedIncome); //string
  form7Data.push(formikValues.otherFixedCharges1); //string
  form7Data.push(formikValues.neomHotelsDevFee); //string
  //console.log("arrayj", arrayKeys);
  if (arrKeys.length > 0) {
    arrKeys.forEach((el) => {
      if (!!formikValues?.undistributedExpenses[el]?.other) {
        form7Data.push(formikValues.undistributedExpenses[el].other.value);
        form7Data.push(formikValues.undistributedExpenses[el].other.fixed);
      }
      if (!!formikValues?.undistributedExpenses[el]?.payroll) {
        form7Data.push(formikValues.undistributedExpenses[el].payroll.value);
        form7Data.push(formikValues.undistributedExpenses[el].payroll.fixed);
      }
      if (!!formikValues?.undistributedExpenses[el]?.otherPerKey) {
        form7Data.push(
          formikValues.undistributedExpenses[el].otherPerKey.value
        );
        form7Data.push(
          formikValues.undistributedExpenses[el].otherPerKey.fixed
        );
      }
      if (!!formikValues?.undistributedExpenses[el]?.creditCardCommissions) {
        form7Data.push(
          formikValues.undistributedExpenses[el].creditCardCommissions.value
        );
      }
      if (!!formikValues?.undistributedExpenses[el]?.payrollYearlyIncrement) {
        form7Data.push(
          formikValues.undistributedExpenses[el].payrollYearlyIncrement.year1
        );
        form7Data.push(
          formikValues.undistributedExpenses[el].payrollYearlyIncrement.year2
        );
        form7Data.push(
          formikValues.undistributedExpenses[el].payrollYearlyIncrement.year3
        );
      }
      if (
        !!formikValues?.undistributedExpenses[el]?.otherPayrollYearlyIncrement
      ) {
        form7Data.push(
          formikValues.undistributedExpenses[el].otherPayrollYearlyIncrement
            .year1
        );
        form7Data.push(
          formikValues.undistributedExpenses[el].otherPayrollYearlyIncrement
            .year2
        );
        form7Data.push(
          formikValues.undistributedExpenses[el].otherPayrollYearlyIncrement
            .year3
        );
      }
      if (
        !!formikValues?.undistributedExpenses[el]?.otherExpensesYearlyIncrement
      ) {
        form7Data.push(
          formikValues.undistributedExpenses[el].otherExpensesYearlyIncrement
            .year1
        );
        form7Data.push(
          formikValues.undistributedExpenses[el].otherExpensesYearlyIncrement
            .year2
        );
        form7Data.push(
          formikValues.undistributedExpenses[el].otherExpensesYearlyIncrement
            .year3
        );
      }
    });
  }

  form7Data.forEach((item, ind) => {
    if (ind <= 6) {
      if (item !== "" && item >= "0") {
        emptyform7Data.push(item);
      }
    } else {
      if (ind === 11 || ind === 19 || ind === 26 || ind === 36) {
        if (item !== "" && parseInt(item) >= 0) {
          emptyform7Data.push(item);
        }
      } else if (
        //Yearly Incremental accept more than 100
        ind == 44
      ) {
        if (item !== "" && parseInt(item) >= 0) {
          emptyform7Data.push(item);
        }
      } else if (
        //Yearly Incremental
        (ind >= 14 && ind <= 16) ||
        (ind >= 21 && ind <= 23) ||
        (ind >= 28 && ind <= 33) ||
        (ind >= 38 && ind <= 43) ||
        (ind >= 46 && ind <= 48)
      ) {
        if (item !== "" && parseInt(item) <= 100) {
          emptyform7Data.push(item);
        }
      } else if (item !== "" && parseInt(item) >= 0 && parseInt(item) <= 100) {
        emptyform7Data.push(item);
      }
    }
  });

  const handleAccordErrors = () => {
    let openHigherAccord = true;
    form7Data.forEach((el, index) => {
      if (openHigherAccord) {
        if (el === "" || el > 100) {
          switch (true) {
            case index <= 16:
              openHigherAccord = false;
              handleOpenAccord(0);
              break;
            case index >= 17 && index <= 23:
              openHigherAccord = false;
              handleOpenAccord(1);
              break;
            case index >= 24 && index <= 33:
              openHigherAccord = false;
              handleOpenAccord(2);
              break;
            case index >= 34 && index <= 43:
              openHigherAccord = false;
              handleOpenAccord(3);
              break;
            case index >= 44 && index <= 48:
              openHigherAccord = false;
              handleOpenAccord(4);
              break;
            default:
              break;
          }
        }
      }
    });
  };

  const handleOpenAccord = (accordIndex) => {
    if (
      inputRef.current.querySelectorAll(
        ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
      )[accordIndex].ariaExpanded === "false"
    ) {
      inputRef.current
        .querySelectorAll(
          ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
        )
        [accordIndex].click();
    }
  };

  let isDisabledForm = false;
  // console.log(emptyform7Data, form7Data);
  if (emptyform7Data.length !== form7Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  let renderAccrodionFields = (
    mData,
    pos,
    renderPayRoll,
    renderIncrements,
    renderOtherExpenses,
    renderOtherExpensesIncrements,
    renderCardComissions,
    renderOtherExpensesPerKey,
    renderOtherPayrollYearlyIncrement
  ) => {
    return (
      <>
        <AccordionItem mb="4" borderBottom="none">
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  {accordTitle[pos]}
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>

          <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
            <>
              <Box>
                <HStack>
                  {renderPayRoll && (
                    <Box
                      maxW={["100%", "100%", "40%"]}
                      width={["100%", "100%", "40%"]}
                      alignSelf="flex-start"
                    >
                      <Text
                        fontSize={"12px"}
                        display="flex"
                        mb="4 !important"
                        mt={3}
                        component="div"
                      >
                        PAYROLL
                      </Text>
                      <SimpleGrid
                        columns={{ sm: 2, md: 2 }}
                        spacingX="15px"
                        width="100%"
                      >
                        <GridItem colSpan={2}>
                          <SimpleGrid
                            columns={{ sm: 2, md: 2 }}
                            spacing="15px"
                            width="100%"
                          >
                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Value"
                                  labelInfo={
                                    id === undefined ? "Benchmark Data" : ""
                                  }
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.payroll.value`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.payroll.value`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.payroll?.value
                                  }
                                  minNum={0}
                                  directVal={true}
                                  // smallIcon={percent}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  // smallLeftIcon={"SAR"}
                                  smallLeftIcon="SAR"
                                  smallerIcon={true}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>

                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Fixed Percentage"
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.payroll.fixed`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.payroll.fixed`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["payroll"]?.fixed
                                  }
                                  minNum={0}
                                  directVal={true}
                                  smallIcon={percent}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>
                          </SimpleGrid>
                        </GridItem>
                      </SimpleGrid>
                    </Box>
                  )}
                  {renderIncrements && (
                    <Box
                      ml={`${renderPayRoll ? "7 !important" : "0 !important"}`}
                      maxW={["100%", "100%", "60%"]}
                      width={["100%", "100%", "60%"]}
                      alignSelf="flex-start"
                    >
                      <Text
                        fontSize={"12px"}
                        display="flex"
                        mb="4 !important"
                        mt={3}
                        component="div"
                        textTransform={"uppercase"}
                      >
                        Yearly Incremental
                      </Text>
                      <SimpleGrid
                        columns={{ sm: 3, md: 3 }}
                        spacingX="15px"
                        width="100%"
                      >
                        <GridItem colSpan={3}>
                          <SimpleGrid
                            columns={{ sm: 3, md: 3 }}
                            spacing="15px"
                            width="100%"
                          >
                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Year 1"
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.payrollYearlyIncrement.year1`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.payrollYearlyIncrement.year1`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["payrollYearlyIncrement"]?.year1
                                  }
                                  minNum={0}
                                  directVal={true}
                                  smallIcon={percent}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  acceptNegative={true}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>

                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Year 2"
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.payrollYearlyIncrement.year2`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.payrollYearlyIncrement.year2`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["payrollYearlyIncrement"]?.year2
                                  }
                                  minNum={0}
                                  directVal={true}
                                  smallIcon={percent}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  acceptNegative={true}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>
                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Year 3"
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.payrollYearlyIncrement.year3`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.payrollYearlyIncrement.year3`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["payrollYearlyIncrement"]?.year3
                                  }
                                  minNum={0}
                                  directVal={true}
                                  smallIcon={percent}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  acceptNegative={true}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>
                          </SimpleGrid>
                        </GridItem>
                      </SimpleGrid>
                    </Box>
                  )}
                </HStack>
                <HStack>
                  {renderOtherExpenses && (
                    <Box
                      maxW={["100%", "100%", "39%"]}
                      width={["100%", "100%", "39%"]}
                      alignSelf="flex-start"
                    >
                      <Text
                        fontSize={"12px"}
                        display="flex"
                        mb="4 !important"
                        mt={3}
                        component="div"
                      >
                        OTHER EXPENSES
                      </Text>
                      <SimpleGrid
                        columns={{ sm: 2, md: 2 }}
                        spacingX="15px"
                        width="100%"
                      >
                        <GridItem colSpan={2}>
                          <SimpleGrid
                            columns={{ sm: 2, md: 2 }}
                            spacing="15px"
                            width="100%"
                          >
                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Value"
                                  labelInfo={
                                    id === undefined ? "Benchmark Data" : ""
                                  }
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.other.value`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.other.value`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["other"]?.value
                                  }
                                  minNum={0}
                                  directVal={true}
                                  smallIcon={percent}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>
                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Fixed Percentage"
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.other.fixed`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.other.fixed`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["other"]?.fixed
                                  }
                                  minNum={0}
                                  directVal={true}
                                  smallIcon={percent}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>
                          </SimpleGrid>
                        </GridItem>
                      </SimpleGrid>
                    </Box>
                  )}
                  {renderOtherExpensesPerKey && (
                    <Box
                      maxW={["100%", "100%", "39%"]}
                      width={["100%", "100%", "39%"]}
                      alignSelf="flex-start"
                      ml={`${
                        renderOtherExpenses ? "7 !important" : "0 !important"
                      }`}
                    >
                      <Text
                        fontSize={"12px"}
                        display="flex"
                        mb="4 !important"
                        mt={3}
                        component="div"
                      >
                        OTHER EXPENSES
                      </Text>
                      <SimpleGrid
                        columns={{ sm: 2, md: 2 }}
                        spacingX="15px"
                        width="100%"
                      >
                        <GridItem colSpan={2}>
                          <SimpleGrid
                            columns={{ sm: 2, md: 2 }}
                            spacing="15px"
                            width="100%"
                          >
                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Value Per Key"
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.otherPerKey.value`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.otherPerKey.value`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["otherPerKey"]?.value
                                  }
                                  minNum={0}
                                  directVal={true}
                                  // smallIcon={percent}
                                  // leftIcon={true}
                                  /*leftIconText="SAR" */
                                  // smallLeftIcon={"SAR"}
                                  smallLeftIcon="SAR"
                                  smallerIcon={true}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>
                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Fixed Percentage"
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.otherPerKey.fixed`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.otherPerKey.fixed`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["otherPerKey"]?.fixed
                                  }
                                  minNum={0}
                                  directVal={true}
                                  smallIcon={percent}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>
                          </SimpleGrid>
                        </GridItem>
                      </SimpleGrid>
                    </Box>
                  )}
                  {renderOtherExpensesIncrements && (
                    <Box
                      ml="7 !important"
                      maxW={["100%", "100%", "60%"]}
                      width={["100%", "100%", "60%"]}
                      alignSelf="flex-start"
                    >
                      <Text
                        fontSize={"12px"}
                        display="flex"
                        mb="4 !important"
                        mt={3}
                        component="div"
                      >
                        OTHER EXPENSES INCREMENTS
                      </Text>
                      <SimpleGrid
                        columns={{ sm: 3, md: 3 }}
                        spacingX="15px"
                        width="100%"
                      >
                        <GridItem colSpan={3}>
                          <SimpleGrid
                            columns={{ sm: 3, md: 3 }}
                            spacing="15px"
                            width="100%"
                          >
                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Year 1"
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.otherExpensesYearlyIncrement.year1`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.otherExpensesYearlyIncrement.year1`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["otherExpensesYearlyIncrement"]?.year1
                                  }
                                  minNum={0}
                                  directVal={true}
                                  smallIcon={percent}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  acceptNegative={true}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>

                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Year 2"
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.otherExpensesYearlyIncrement.year2`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.otherExpensesYearlyIncrement.year2`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["otherExpensesYearlyIncrement"]?.year2
                                  }
                                  minNum={0}
                                  directVal={true}
                                  smallIcon={percent}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  acceptNegative={true}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>
                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Year 3"
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.otherExpensesYearlyIncrement.year3`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.otherExpensesYearlyIncrement.year3`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["otherExpensesYearlyIncrement"]?.year3
                                  }
                                  minNum={0}
                                  smallIcon={percent}
                                  directVal={true}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  acceptNegative={true}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>
                          </SimpleGrid>
                        </GridItem>
                      </SimpleGrid>
                    </Box>
                  )}
                  {renderOtherPayrollYearlyIncrement && (
                    <Box
                      ml="7 !important"
                      maxW={["100%", "100%", "60%"]}
                      width={["100%", "100%", "60%"]}
                      alignSelf="flex-start"
                    >
                      <Text
                        fontSize={"12px"}
                        display="flex"
                        mb="4 !important"
                        mt={3}
                        component="div"
                      >
                        OTHER EXPENSES INCREMENTS
                      </Text>
                      <SimpleGrid
                        columns={{ sm: 3, md: 3 }}
                        spacingX="15px"
                        width="100%"
                      >
                        <GridItem colSpan={3}>
                          <SimpleGrid
                            columns={{ sm: 3, md: 3 }}
                            spacing="15px"
                            width="100%"
                          >
                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Year 1"
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.otherPayrollYearlyIncrement.year1`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.otherPayrollYearlyIncrement.year1`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["otherPayrollYearlyIncrement"]?.year1
                                  }
                                  minNum={0}
                                  smallIcon={percent}
                                  directVal={true}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  acceptNegative={true}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>

                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Year 2"
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.otherPayrollYearlyIncrement.year2`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.otherPayrollYearlyIncrement.year2`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["otherPayrollYearlyIncrement"]?.year2
                                  }
                                  minNum={0}
                                  smallIcon={percent}
                                  directVal={true}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  acceptNegative={true}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>
                            <GridItem>
                              <Box textAlign="center">
                                <InputFieldValidation
                                  label="Year 3"
                                  uppercase={false}
                                  inputType="number"
                                  inputId={`undistributedExpenses.${arrayKeys[pos]}.otherPayrollYearlyIncrement.year3`}
                                  inputName={`undistributedExpenses.${arrayKeys[pos]}.otherPayrollYearlyIncrement.year3`}
                                  placeholder="0"
                                  errors={errors}
                                  setIsFormDirty={setIsFormDirty}
                                  isFormDirty={isFormDirty}
                                  touched={touched}
                                  labelCenter={true}
                                  isRequired
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.undistributedExpenses[
                                      arrayKeys[pos]
                                    ]?.["otherPayrollYearlyIncrement"]?.year3
                                  }
                                  minNum={0}
                                  directVal={true}
                                  smallIcon={percent}
                                  isDisabled={isRestore}
                                  validateField={validateStep}
                                  acceptNegative={true}
                                  isThousand={true}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Box>
                            </GridItem>
                          </SimpleGrid>
                        </GridItem>
                      </SimpleGrid>
                    </Box>
                  )}
                </HStack>
                {renderCardComissions && (
                  <Box
                    maxW={["100%", "100%", "39%"]}
                    width={["100%", "100%", "39%"]}
                    alignSelf="flex-start"
                  >
                    <Text
                      fontSize={"12px"}
                      display="flex"
                      mb="4 !important"
                      mt={3}
                      component="div"
                      textTransform="uppercase"
                    >
                      Credit Card Commissions
                    </Text>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacingX="15px"
                      width="100%"
                    >
                      <GridItem colSpan={2}>
                        <SimpleGrid
                          columns={{ sm: 2, md: 2 }}
                          spacing="15px"
                          width="100%"
                        >
                          <GridItem>
                            <Box textAlign="center">
                              <InputFieldValidation
                                label="Value"
                                uppercase={false}
                                inputType="number"
                                inputId={`undistributedExpenses.${arrayKeys[pos]}.creditCardCommissions.value`}
                                inputName={`undistributedExpenses.${arrayKeys[pos]}.creditCardCommissions.value`}
                                placeholder="0"
                                errors={errors}
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                touched={touched}
                                labelCenter={true}
                                isRequired
                                onChange={setFieldValue}
                                values={
                                  formikValues?.undistributedExpenses[
                                    arrayKeys[pos]
                                  ]?.["creditCardCommissions"]?.value
                                }
                                minNum={0}
                                directVal={true}
                                smallIcon={percent}
                                isDisabled={isRestore}
                                validateField={validateStep}
                                isThousand={true}
                                setFieldTouched={setFieldTouched}
                              />
                            </Box>
                          </GridItem>
                        </SimpleGrid>
                      </GridItem>
                    </SimpleGrid>
                  </Box>
                )}
              </Box>
            </>
          </AccordionPanel>
        </AccordionItem>
      </>
    );
  };

  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);

  return (
    <>
      <Box alignItems="flex-start" justifyContent="space-between" display="flex" width="100%">
        <MainTitle title="UNDISTRIBUTED EXPENSES AND CHARGES" />
        <CalculationButton  formikValue={formikValues} />

      </Box>
      <Box
        maxW={["100%", "100%", "50%"]}
        width={["100%", "100%", "50%"]}
        alignSelf="flex-start"
      >
        <Text className="title_md" display="flex" mb={3} mt={3} component="div">
          UNDISTRIBUTED EXPENSES AND CHARGES
        </Text>
      </Box>

      <Box
        maxW={["100%", "100%", "100%"]}
        width={["100%", "100%", "100%"]}
        alignSelf="flex-start"
      >
        <Accordion
          width="100%"
          allowToggle
          color="black"
          ref={inputRef}
          defaultIndex={[0]}
        >
          {formikValues?.undistributedExpenses?.adminGeneral &&
            renderAccrodionFields(
              formikValues?.undistributedExpenses?.adminGeneral,
              0,
              true,
              true,
              true,
              false,
              true,
              false,
              false
            )}
          {formikValues?.undistributedExpenses?.informationTelecom &&
            renderAccrodionFields(
              formikValues?.undistributedExpenses?.informationTelecom,
              1,
              true,
              true,
              true,
              false,
              false,
              false,
              false
            )}
          {formikValues?.undistributedExpenses?.salesMarketing &&
            renderAccrodionFields(
              formikValues?.undistributedExpenses?.salesMarketing,
              2,
              true,
              true,
              true,
              true,
              false,
              false,
              false
            )}

          {formikValues?.undistributedExpenses?.propertyOperations &&
            renderAccrodionFields(
              formikValues?.undistributedExpenses?.propertyOperations,
              3,
              true,
              true,
              true,
              false,
              false,
              false,
              true
            )}

          {formikValues?.undistributedExpenses?.utilities &&
            renderAccrodionFields(
              formikValues?.undistributedExpenses?.utilities,
              4,
              false,
              true,
              false,
              false,
              false,
              true
            )}
        </Accordion>
      </Box>

      <Box
        maxW={["100%", "100%", "50%"]}
        width={["100%", "100%", "50%"]}
        alignSelf="flex-start"
      >
        <Text className="title_md" display="flex" mb={5} mt={6} component="div">
          FIXED CHARGES
        </Text>
        <Box mb={5}>
          <InputFieldValidation
            label="INCOME"
            labelInfo={id === undefined ? "Benchmark Data" : ""}
            inputType="number"
            inputId="fixedIncome"
            inputName="fixedIncome"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            // smallIcon={percent}
            leftIcon={true}
            leftIconText="SAR"
            // smallLeftIcon="SAR"
            // smallerIcon={true}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />
        </Box>
        <Box mb={5}>
          <InputFieldValidation
            label="Operational Insurance - SAR per key"
            labelInfo="BENCHMARK DATA"
            inputType="number"
            inputId="operationalInsurance"
            inputName="operationalInsurance"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            leftIcon={true}
            leftIconText="SAR"
            // smallLeftIcon="SAR"
            // smallerIcon={true}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />
        </Box>
        <Box mb={5}>
          <InputFieldValidation
            label="Permits & Licenses - SAR per key"
            inputType="number"
            inputId="permitsAndLicenses"
            inputName="permitsAndLicenses"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            leftIcon={true}
            leftIconText="SAR"
            // smallLeftIcon="SAR"
            // smallerIcon={true}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />
        </Box>
        <Box mb={5}>
          <InputFieldValidation
            label="Land Lease Fee  - SAR per Sqm of land per annum"
            inputType="number"
            inputId="landLeaseFee"
            inputName="landLeaseFee"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            leftIcon={true}
            leftIconText="SAR"
            // smallLeftIcon="SAR"
            // smallerIcon={true}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />
        </Box>
        <Box mb={5}>
          <InputFieldValidation
            label="Other Fixed Charges"
            inputType="number"
            inputId="otherFixedCharges1"
            inputName="otherFixedCharges1"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            icon={percent}
            hintText={
              "To cover for land lease fee or additional development fee"
            }
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />
        </Box>
        <Box mb={5}>
          <InputFieldValidation
            label="Property & Other Taxes"
            labelInfo={id === undefined ? "Benchmark Data" : ""}
            inputType="number"
            inputId="propertyAndOtherTaxes"
            inputName="propertyAndOtherTaxes"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />
        </Box>
        <Box mb={5}>
          <InputFieldValidation
            label="NEOM Hotels Development Fee - Percentage of Development Cost"
            inputType="number"
            inputId="neomHotelsDevFee"
            inputName="neomHotelsDevFee"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            icon={percent}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
          />
        </Box>

        <Box mb={5}>
          <InputFieldValidation
            label="Asset Management Fee - SAR per Year"
            inputType="number"
            inputId="assetManagementFee"
            inputName="assetManagementFee"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            leftIcon={true}
            leftIconText="SAR"
            // smallLeftIcon="SAR"
            // smallerIcon={true}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />
        </Box>
        <Box mb={5}>
          <InputFieldValidation
            label="Other Fixed Charges "
            inputType="number"
            inputId="otherFixedCharges2"
            inputName="otherFixedCharges2"
            placeholder="0"
            errors={errors}
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            minNum={0}
            leftIcon={true}
            leftIconText="SAR"
            // smallLeftIcon="SAR"
            // smallerIcon={true}
            isDisabled={isRestore}
            validateField={validateStep}
            isThousand={true}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />
        </Box>
      </Box>
      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex justifyContent={user == "manager" ? "end" : "space-between"} alignItems="center" width="100%">
        {
          user !== "manager" ?
            <MainButton
              title="Save Draft"
              btnStyle="transparent"
              disabledBtn={isRestore}
              fontColor="dark"
              onPress={() => {
                setDismissSave(false);
                onDraftOpen();
              }}
            /> : <></>
        }
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {/* <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm ? true : false}
              onPress={() => {
                nextPage();
                if (percentage < 73) {
                  setPercentage(73);
                }
                setStepCompleted(page);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  setValidateStep(true);
                  isModelWarningOpen(true);
                  handleAccordErrors();
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  nextPage();
                  if (percentage < 73) {
                    setPercentage(73);
                  }
                  setStepCompleted(page);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
