// dataActions.js
import axiosClient from "../../api/index";
import { SET_COMPSET_TABLE, SET_MY_ASSETS_TABLE, FETCH_DATA_FAILURE, SET_AGGREGATION } from './actionTypes';


export const submitSelectedAssets = (myAssets, compset) => async (dispatch) => {
    try {
        // Send selected asset IDs to the API
        const response = await axiosClient.post('/v1/compare/calculate', { myAssets, compset});
        if(response.data.data.compset)
            dispatch(setCompset(response.data.data.compset, response.data.data.compsetAggregateIrr));
        if(response.data.data.myAssets)
            dispatch(setMyAssets(response.data.data.myAssets, response.data.data.myAssetsAggregateIrr));

        // Dispatch any necessary actions based on the API response
    } catch (error) {
        console.error('Error submitting selected assets:', error);
    }
};

export const setCompset = (compset,compsetAggregateIrr) => ({
    type: SET_COMPSET_TABLE,
    payload: {compset,compsetAggregateIrr}
});

export const setMyAssets = (myAssets,myAssetsAggregateIrr) => ({
    type: SET_MY_ASSETS_TABLE,
    payload: {myAssets,myAssetsAggregateIrr}
});

export const fetchDataFailure = (error) => ({
    type: FETCH_DATA_FAILURE,
    payload: error
});

export const setAggregation = (graphType) => ({
    type: SET_AGGREGATION,
    payload: graphType
});
